import React from 'react';
import {
  MenuItemLink,
  ReferenceField,
  useCreatePath,
  useLocaleState,
  useResourceContext,
  useTranslate
} from 'react-admin';
import {
  Box,
  CardContent,
  Divider,
  IconButton,
  ListItemText,
  Skeleton,
  Stack,
  styled,
  Typography
} from '@mui/material';
import ReactTimeAgo from 'react-time-ago';
import {
  Iconify,
  ProjectTypeVersionField,
  StateField
} from '@rc/admin/components';
import CustomPopover, {
  usePopover
} from '@minimal-theme/components/custom-popover';
import { AvatarField } from './field';
import { useProjectType } from '../hooks';

/**
 *
 * @param {object} props
 * @param {import('react-admin').Record} props.record
 * @param {boolean} props.isPlaceholder
 * @returns
 */
export const ProjectCardContent = props => {
  const { record, isPlaceholder } = props;
  const t = useTranslate();
  const popover = usePopover();
  const resource = useResourceContext();
  const createPath = useCreatePath();

  return (
    <StyledCardContent>
      {!isPlaceholder && (
        <IconButton
          onClick={e => {
            e.preventDefault();
            popover.onOpen(e);
          }}
          sx={{ position: 'absolute', top: 8, right: 8 }}
        >
          <Iconify icon='eva:more-vertical-fill' />
        </IconButton>
      )}
      <Stack sx={{ p: 3, pb: 2 }}>
        <Stack flexDirection='row' alignItems='center' gap={2} mb={0.5} mr={2}>
          {isPlaceholder ? (
            <Skeleton width={48} height={48} variant='rounded' />
          ) : (
            <AvatarField
              sx={{
                width: 48,
                height: 48
              }}
            />
          )}
          <Title {...props} />
        </Stack>
      </Stack>

      <Divider sx={{ borderStyle: 'dashed' }} />

      <Box
        rowGap={1.5}
        display='grid'
        gridTemplateColumns='repeat(2, 1fr)'
        sx={{ p: 3 }}
      >
        {[
          {
            key: 'projectType',
            label: <ProjectType {...props} />,
            icon: null
          },
          {
            key: 'updatedAt',
            label: <UpdatedAt {...props} />,
            icon: !isPlaceholder && (
              <Iconify
                width={16}
                icon='solar:clock-circle-bold'
                sx={{ flexShrink: 0 }}
              />
            )
          }
        ].map(item => (
          <Stack
            key={item.key}
            spacing={0.5}
            flexShrink={0}
            direction='row'
            alignItems='center'
            sx={{ color: 'text.disabled', minWidth: 0 }}
          >
            {item.icon}
            <Typography variant='caption' noWrap>
              {item.label}
            </Typography>
          </Stack>
        ))}
      </Box>

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow='right-top'
        sx={{ width: 140 }}
      >
        <MenuItemLink
          onClick={e => {
            e.stopPropagation();
            popover.onClose();
          }}
          to={createPath({ resource, type: 'edit', id: record?.id })}
        >
          <Iconify icon='solar:diagram-up-bold' />
          {t('resources.projects.tabs.details')}
        </MenuItemLink>
        <MenuItemLink
          onClick={e => {
            e.stopPropagation();
            popover.onClose();
          }}
          to={`${createPath({
            resource,
            type: 'edit',
            id: record?.id
          })}/edit`}
        >
          <Iconify icon='solar:pen-bold' />
          {t('ra.action.edit')}
        </MenuItemLink>
      </CustomPopover>
    </StyledCardContent>
  );
};

const Title = props => {
  const { record, isPlaceholder } = props;
  return (
    <ListItemText
      sx={{ textAlign: 'left' }}
      primary={isPlaceholder ? <Skeleton variant='text' /> : record?.name}
      secondary={<State {...props} />}
      primaryTypographyProps={{
        typography: 'subtitle1',
        noWrap: true
      }}
      secondaryTypographyProps={{
        mt: 1,
        component: 'span',
        typography: 'caption',
        color: 'text.disabled'
      }}
    />
  );
};

const State = ({ isPlaceholder }) =>
  isPlaceholder ? (
    <Skeleton variant='text' width={60} />
  ) : (
    <ReferenceField
      reference={'states'}
      source={'state'}
      link={false}
      label='resources.projects.fields.state'
    >
      <StateField
        variant={'caption'}
        source='id'
        sx={{ overflow: 'hidden' }}
        fontWeight={'400'}
      />
    </ReferenceField>
  );

const ProjectType = ({ record, isPlaceholder }) => {
  const { projectType } = useProjectType({
    project: record,
    enabled: !!record
  });

  if (isPlaceholder || !projectType) {
    return <Skeleton variant='text' width={60} />;
  }

  return (
    <Stack flexDirection='row' gap={1}>
      <BrandImage src={projectType.logo} />
      <ReferenceField
        source={'projectTypeVersion'}
        reference={'project_type_versions'}
        link={false}
      >
        <ProjectTypeVersionField
          source={'version'}
          variant='caption'
          hideVersionNumber
        />
      </ReferenceField>
    </Stack>
  );
};

const UpdatedAt = ({ record, isPlaceholder }) => {
  const [locale] = useLocaleState();
  const dateString = record.updatedAt || record.createdAt;

  return isPlaceholder ? (
    <Skeleton variant='text' width={60} />
  ) : dateString ? (
    <ReactTimeAgo date={new Date(dateString)} locale={locale} />
  ) : null;
};

const BrandImage = styled('img')(() => ({
  width: 'auto',
  height: 16
}));

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  padding: theme.spacing(0),
  '&:last-child': {
    paddingBottom: theme.spacing(0)
  }
}));

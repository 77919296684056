import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader, styled, useTheme } from '@mui/material';
import { fNumber } from '@minimal-theme/utils/format-number';
import Chart, { useChart } from '../../ui/Chart';
import { useProjectsStatusChart } from '@rc/admin/hooks';

const CHART_HEIGHT = 400;

const LEGEND_HEIGHT = 100;

export const ProjectsStatusChart = props => {
  const { title, subheader, ...rest } = props;
  const { chart } = useProjectsStatusChart();

  const theme = useTheme();

  const { colors, series, options } = chart;

  const chartSeries = series.map(i => i.value);

  const chartOptions = useChart({
    chart: {
      sparkline: {
        enabled: true
      }
    },
    colors,
    labels: series.map(i => i.label),
    stroke: { colors: [theme.palette.background.paper] },
    legend: {
      offsetY: 0,
      floating: true,
      position: 'bottom',
      horizontalAlign: 'center'
    },
    tooltip: {
      fillSeriesColor: false,
      y: {
        formatter: value => fNumber(value),
        title: {
          formatter: seriesName => `${seriesName}`
        }
      }
    },
    plotOptions: {
      pie: {
        donut: {
          size: '90%',
          labels: {
            value: {
              formatter: value => fNumber(value)
            },
            total: {
              formatter: w => {
                const sum = w.globals.seriesTotals.reduce((a, b) => a + b, 0);
                return fNumber(sum);
              }
            }
          }
        }
      }
    },
    ...options
  });

  return (
    <Card {...rest}>
      <CardHeader title={title} subheader={subheader} sx={{ mb: 5 }} />

      <StyledChart
        dir='ltr'
        type='donut'
        series={chartSeries}
        options={chartOptions}
        width='100%'
        height={280}
      />
    </Card>
  );
};

const StyledChart = styled(Chart)(({ theme }) => ({
  height: CHART_HEIGHT,
  '& .apexcharts-canvas, .apexcharts-inner, svg, foreignObject': {
    height: `100% !important`
  },
  '& .apexcharts-legend': {
    height: LEGEND_HEIGHT,
    borderTop: `dashed 1px ${theme.palette.divider}`,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`
  }
}));

ProjectsStatusChart.propTypes = {
  chart: PropTypes.object,
  subheader: PropTypes.string,
  title: PropTypes.string
};

ProjectsStatusChart.defaultProps = {};

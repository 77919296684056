import React from 'react';
import { useTranslate } from 'react-admin';
import { Grid, Typography, styled } from '@mui/material';
import { useIdParam, useUserCosts } from '@rc/admin/hooks';
import { UsageSummary, CostsSummary } from '@rc/admin/components';

export const UserCosts = () => {
  const t = useTranslate();
  const { error, isLoading, costs, summary, hasEnvironments } = useUserCosts();

  if (error || !hasEnvironments || (!summary && !costs)) {
    return null;
  }

  return (
    <StyledGrid container spacing={2}>
      {(summary || costs) && (
        <Grid item xs={12}>
          <Typography variant={'subtitle1'}>
            {t('components.usage_summary.title')}
          </Typography>
        </Grid>
      )}
      {summary && (
        <Grid item xs={12} md={6}>
          <UsageSummary isLoading={isLoading} summary={summary} />
        </Grid>
      )}
      {costs && (
        <Grid item xs={12} md={6}>
          <CostsSummary isLoading={isLoading} costs={costs} />
        </Grid>
      )}
    </StyledGrid>
  );
};

// const PREFIX = 'UserCosts';

// const classes = {};

const StyledGrid = styled(Grid)(({ theme }) => ({}));

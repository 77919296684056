import React from 'react';
import { Create, SaveButton, SimpleForm, Toolbar } from 'react-admin';
import { styled } from '@mui/material';
import { OneColumnFormStyles } from '@rc/admin/theme/styles';

export const AccessCodeCreate = () => {
  return (
    <StyledCreate>
      <SimpleForm
        redirect={'edit'}
        className={classes.form}
        toolbar={
          <Toolbar>
            <SaveButton alwaysEnable label='action.generate' autoFocus />
          </Toolbar>
        }
      >
        {'This form generates a new access code.'}
      </SimpleForm>
    </StyledCreate>
  );
};

const PREFIX = 'ComponentCreate';

const classes = {
  form: `${PREFIX}-form`
};

const StyledCreate = styled(Create)(({ theme }) => ({
  [`& .${classes.form}`]: {
    ...OneColumnFormStyles(theme)
  }
}));

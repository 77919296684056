import React from 'react';
import {
  BooleanField,
  Datagrid,
  FunctionField,
  List,
  ReferenceField,
  SimpleList,
  TextField,
  useTranslate
} from 'react-admin';
import { useMediaQuery } from '@mui/material';
import { ClusterField } from '@rc/admin/resources/product/components';
import { EditButton } from '@rc/admin/ra';

export const ProductList = () => {
  const t = useTranslate();
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

  return (
    <List>
      {isSmall ? (
        <SimpleList
          primaryText={<TextField source='name' />}
          secondaryText={
            <ReferenceField
              source={'defaultPrice[@id]'}
              reference={'prices'}
              link={false}
              label='resources.products.fields.price'
              emptyText={'n/a'}
            >
              <FunctionField
                render={record =>
                  new Intl.NumberFormat('hu-HU', {
                    style: 'currency',
                    currency: record.currency,
                    maximumFractionDigits: 9
                  }).format(record.amount)
                }
              />
            </ReferenceField>
          }
        />
      ) : (
        <Datagrid>
          <TextField source='name' />
          <ClusterField
            label={t('resources.clusters.name', { smart_count: 2 })}
          />
          <ReferenceField
            source={'defaultPrice[@id]'}
            reference={'prices'}
            link={false}
            label='resources.products.fields.price'
            emptyText={'n/a'}
          >
            <FunctionField
              render={record =>
                new Intl.NumberFormat('hu-HU', {
                  style: 'currency',
                  currency: record.currency,
                  maximumFractionDigits: 9
                }).format(record.amount)
              }
            />
          </ReferenceField>
          <BooleanField source='isActive' />
          <EditButton />
        </Datagrid>
      )}
    </List>
  );
};

export default ProductList;

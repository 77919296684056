import React from 'react';
import {
  FunctionField,
  Labeled,
  ReferenceField,
  TextField,
  useRecordContext
} from 'react-admin';
import { Box, Card, CardContent, Grid, Stack } from '@mui/material';
import { ExternalLink, Iconify, SectionTitle } from '@rc/admin/components';
import { EnvironmentAccessFields, EnvironmentCosts } from '../components';

export const EnvironmentDetails = () => {
  const record = useRecordContext();

  return (
    <Box>
      <Grid
        container
        spacing={2}
        direction={{
          md: 'row-reverse'
        }}
      >
        <Grid item xs={12} md={5} lg={4}>
          <Card sx={{ height: '100%' }}>
            <CardContent>
              <Grid container spacing={1}>
                <Grid item xs={12} xl={6}>
                  <Labeled>
                    <FunctionField
                      source='name'
                      render={record => (
                        <Stack
                          flexDirection='row'
                          columnGap={1}
                          alignItems='center'
                          width='100%'
                        >
                          <Iconify icon='fluent:branch-16-regular' />
                          {record.name}
                        </Stack>
                      )}
                    />
                  </Labeled>
                </Grid>
                <Grid item xs={12} xl={6}>
                  {record.targetRefName &&
                    record.name !== record.targetRefName && (
                      <Labeled>
                        <FunctionField
                          source='targetRefName'
                          render={record => (
                            <Stack
                              flexDirection='row'
                              columnGap={1}
                              alignItems='center'
                              width='100%'
                            >
                              {/* <Iconify icon='fluent:branch-16-regular' /> */}
                              {record.targetRefName}
                            </Stack>
                          )}
                        />
                      </Labeled>
                    )}
                </Grid>
                <Grid item xs={12} xl={6}>
                  <Labeled>
                    <ReferenceField
                      source={'provider'}
                      reference={'providers'}
                      link={false}
                    >
                      <TextField source={'name'} fullWidth />
                    </ReferenceField>
                  </Labeled>
                </Grid>
                <Grid item xs={12} xl={6}>
                  <Labeled>
                    <ReferenceField
                      source={'cluster'}
                      reference={'clusters'}
                      link={false}
                    >
                      <TextField source={'name'} fullWidth />
                    </ReferenceField>
                  </Labeled>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Labeled
                  label={'resources.environments.sections.domains'}
                  mt={2}
                >
                  <FunctionField
                    source='status'
                    render={record =>
                      record.environmentCustomDomain?.map(domain => {
                        return (
                          <ExternalLink
                            key={domain['@id']}
                            url={domain.hostname}
                          />
                        );
                      })
                    }
                  />
                </Labeled>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={7} lg={8}>
          <Card sx={{ height: '100%' }}>
            <CardContent>
              <SectionTitle
                title='resources.environments.sections.access.title'
                mb={2}
              />
              <EnvironmentAccessFields />
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <EnvironmentCosts />
        </Grid>
      </Grid>
    </Box>
  );
};

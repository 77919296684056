import React from 'react';
import { CreditCardOff } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import { useTranslate } from 'react-admin';

export const NoPaymentMethod = props => {
  const t = useTranslate();
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        textAlign: 'center',
        p: 3
      }}
    >
      <CreditCardOff sx={{ fontSize: 70 }} />
      <Typography>{t('pages.billing.method.empty')}</Typography>
      <Button
        sx={{ mt: 3 }}
        variant={'contained'}
        onClick={() => props.goTo(2)}
      >
        {t('pages.billing.method.action.subscribe')}
      </Button>
    </Box>
  );
};

export default NoPaymentMethod;

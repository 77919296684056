import React, { useCallback } from 'react';
import { useResourceContext } from 'react-admin';
import { WORKER_DATA_TYPE } from '@rc/admin/constants';
import { MercureSubscriberForEdit } from '@rc/admin/components';
import { useRCWorkerMessagesContext } from '@rc/admin/context';
import useUpdateCache from '@rc/admin/moduleOverrides/useUpdateCache';

/**
 *
 * @param {object} props
 * @param {any} props.component
 * @param {(data: import('react-admin').RaRecord) => void} props.onReceived
 * @returns
 */
export const RCWorkerMercureSubscriber = props => {
  const { onReceived: onReceivedProp, ...rest } = props;
  const [, { addMessage }] = useRCWorkerMessagesContext();
  const updateCache = useUpdateCache();
  const resource = useResourceContext();

  const onReceived = useCallback(
    document => {
      if (document['@type'] === WORKER_DATA_TYPE) {
        addMessage(document['@id'], document);
      } else {
        updateCache({ resource, id: document.id, data: document });

        if (onReceivedProp) {
          onReceivedProp(document);
        }
      }
    },
    [addMessage, onReceivedProp, resource, updateCache]
  );

  return <MercureSubscriberForEdit onReceived={onReceived} {...rest} />;
};

import { ProjectTypeVersionEdit } from './Edit';
import { ProjectTypeVersionCreate } from './Create';

export default {
  name: 'project_type_versions',
  edit: ProjectTypeVersionEdit,
  create: ProjectTypeVersionCreate,
  options: {
    showInMenu: false
  }
};

import React from 'react';
import {
  BooleanField,
  Datagrid,
  Edit,
  EditClasses,
  ReferenceArrayInput,
  SaveButton,
  SelectArrayInput,
  SimpleForm,
  SimpleList,
  TextField,
  TextInput,
  Toolbar,
  ToolbarClasses
} from 'react-admin';
import { EditButton } from '@rc/admin/ra';
import { styled, Card, useMediaQuery } from '@mui/material';
import {
  AsideReferenceList,
  Breadcrumbs,
  DeleteButton,
  EditTitle
} from '@rc/admin/components';
import { TwoColumnFormStyles } from '@rc/admin/theme/styles';
import { ProjectTypeComponentVersionInput } from './components';

export const ProjectTypeVersionEdit = () => {
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

  return (
    <StyledEdit
      title={<EditTitle source={'version'} />}
      redirect={false}
      mutationMode='pessimistic'
      actions={
        <Breadcrumbs
          parents={breadcrumbsParents}
          label={<BreadcrumbsLabel />}
        />
      }
      aside={
        <Card className={classes.asideCard}>
          <AsideReferenceList
            resource={'project_type_version_env_vars'}
            referenceSource={'projectTypeVersion'}
            title={
              'resources.project_type_versions.fields.project_type_version_env_vars'
            }
          >
            {isSmall ? (
              <SimpleList
                primaryText={() => <TextField source={'key'} />}
                secondaryText={() => <TextField source={'note'} />}
                tertiaryText={() => <BooleanField source={'isRequired'} />}
                style={{ width: '100%' }}
              />
            ) : (
              <Datagrid style={{ width: '100%' }}>
                <TextField source={'key'} />
                <TextField source={'note'} />
                <BooleanField source={'isRequired'} />
                <EditButton />
              </Datagrid>
            )}
          </AsideReferenceList>
        </Card>
      }
    >
      <SimpleForm
        defaultValues={{ isEnabled: true }}
        className={classes.form}
        toolbar={
          <Toolbar>
            <div className={ToolbarClasses.defaultToolbar}>
              <SaveButton />
              <DeleteButton
                redirect={(_, __, record) =>
                  `project_types/${encodeURIComponent(record?.projectType)}`
                }
              />
            </div>
          </Toolbar>
        }
      >
        <TextInput source={'version'} fullWidth />
        <ReferenceArrayInput
          source={'requiredComponent'}
          reference={'components'}
          label={'resources.project_type_versions.fields.requiredComponent'}
          helperText={
            'resources.project_type_versions.fields.requiredComponentHelper'
          }
        >
          <SelectArrayInput variant='filled' optionText={'name'} fullWidth />
        </ReferenceArrayInput>
        <ProjectTypeComponentVersionInput
          source={'componentVersions'}
          fullWidth
        />
      </SimpleForm>
    </StyledEdit>
  );
};

const breadcrumbsParents = [
  { reference: 'project_types', source: 'projectType' }
];

const BreadcrumbsLabel = props => {
  const { data } = props;
  return data['@type'] === 'ProjectTypeVersion' ? data.version : data.name;
};

const PREFIX = 'ProjectTypeVersionEdit';

const classes = {
  asideCard: `${PREFIX}-asideCard`,
  form: `${PREFIX}-form`
};

const StyledEdit = styled(Edit)(({ theme }) => ({
  [`& .${EditClasses.main}`]: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(3),
    '& > *': {
      width: '100%'
    }
  },

  [`& .${classes.asideCard}`]: {},

  [`& .${classes.form}`]: {
    ...TwoColumnFormStyles(theme)
  }
}));

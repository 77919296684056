import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Datagrid,
  DateField,
  EditButton,
  FunctionField,
  Pagination,
  ReferenceField,
  ResourceContextProvider,
  TextField,
  useGetList,
  useNotify,
  useRecordContext,
  useRefresh,
  useTranslate
} from 'react-admin';
import { CircularProgress, Stack, Typography } from '@mui/material';
import { useIsRole } from '@rc/admin/hooks';
import { ROLES } from '@rc/admin/constants';
import { Send as SendIcon } from '@mui/icons-material';
import useAxios from 'axios-hooks';
import { getIsInvitationExpired } from '@rc/admin/utils';
import { InvitationStateField } from '@rc/admin/resources/invitation/components';

const ROWS_PER_PAGE_OPTIONS = [5, 10, 25];

/**
 *
 * @param {object} props
 * @param {object} props.getListRef - ref to the getList object
 * @returns
 */
export const Invitations = props => {
  const { getListRef } = props;
  const { result: isAdmin } = useIsRole(ROLES.ADMIN);
  const t = useTranslate();
  const record = useRecordContext();

  const sort = { field: 'createdAt', order: 'DESC' };
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(ROWS_PER_PAGE_OPTIONS[0]);

  const { data, isLoading, total, refetch } = useGetList(
    'invitations',
    {
      pagination: { page, perPage },
      filter: { team: record?.['@id'] },
      sort
    },
    {
      enabled: !!record
    }
  );

  useEffect(() => {
    if (getListRef) {
      getListRef.current = { refetch };
    }
  }, [getListRef, refetch]);

  return (
    <ResourceContextProvider value='invitations'>
      <Datagrid
        bulkActionButtons={null}
        empty={
          <Typography variant='body2'>
            {t('resources.invitations.message.empty')}
          </Typography>
        }
        isLoading={isLoading}
        data={data || []}
        sort={sort}
        total={total}
        sx={{ width: '100%' }}
      >
        <FunctionField
          label='ra.auth.email'
          render={record => {
            if (record.email) {
              return record.email;
            }
            return (
              <ReferenceField source='user' reference='users'>
                <TextField source='email' />
              </ReferenceField>
            );
          }}
        />
        <DateField source='createdAt' />
        {/* Todo: Use the enum when ready */}
        <InvitationStateField source='state' />
        <FunctionField
          sx={{ float: 'right' }}
          render={record => {
            return (
              <Stack direction={'row'} gap={1}>
                {!getIsInvitationExpired(record) && !record.state && (
                  <ResendButton invitation={record} />
                )}
                {isAdmin && <EditButton sx={{ float: 'right' }} />}
              </Stack>
            );
          }}
        />
      </Datagrid>
      {total > ROWS_PER_PAGE_OPTIONS[0] && (
        <Pagination
          total={total}
          page={page}
          setPage={setPage}
          perPage={perPage}
          setPerPage={setPerPage}
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          isLoading={isLoading}
          sx={{ width: '100%' }}
        />
      )}
    </ResourceContextProvider>
  );
};

const ResendButton = props => {
  const { invitation } = props;
  const refresh = useRefresh();
  const notify = useNotify();

  const [{ loading: isLoading }, executeResend] = useAxios({
    url: `/api/invitations/${invitation.originId}`,
    method: 'PUT',
    data: invitation
  });

  const handleResend = useCallback(async () => {
    try {
      await executeResend({
        headers: {
          resend: true
        }
      });

      notify('resources.invitations.notification.resend_success', {
        type: 'info'
      });
      refresh();
    } catch (error) {
      notify('resources.invitations.notification.resend_failed', {
        type: 'error'
      });
    }
  }, [executeResend, notify, refresh]);

  return (
    <Button
      label='action.resend'
      size='small'
      color='info'
      onClick={handleResend}
      disabled={isLoading}
    >
      {!isLoading ? (
        <SendIcon fontSize='inherit' />
      ) : (
        <CircularProgress size={18} thickness={2} style={{ marginRight: 4 }} />
      )}
    </Button>
  );
};

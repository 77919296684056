import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useGetList, useRecordContext, useTranslate } from 'react-admin';
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Fade,
  Popper,
  Typography,
  styled
} from '@mui/material';
import { useEnvironmentProgressBar } from '@rc/admin/hooks';
import { StateField } from '../../state';
import { EnvironmentStatusProgressBar } from './ProgressBar';
import {
  MESSENGER_ACTION_AMQP_TYPES,
  PREFETCH_PAGINATION,
  ID_SORT
} from '@rc/admin/constants';
import { useIsDebugMode } from '@rc/admin/context';

const LOW_PRIORITY_ACTIONS = [
  MESSENGER_ACTION_AMQP_TYPES.IMPORT_DB,
  MESSENGER_ACTION_AMQP_TYPES.IMPORT_MEDIA,
  MESSENGER_ACTION_AMQP_TYPES.EXPORT_DB,
  MESSENGER_ACTION_AMQP_TYPES.EXPORT_MEDIA
];

const WIDTH = 256;

export const EnvironmentStatusProgressBarTrigger = props => {
  const t = useTranslate();
  const record = useRecordContext();
  const isDebugMode = useIsDebugMode();
  const progressBarInfo = record?.progressBarInfo;
  const { ongoingProgresses } = useEnvironmentProgressBar();

  const triggerRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const popperId = open ? 'progress-bar-popper' : undefined;

  const toggleProgressBarOpen = useCallback(() => {
    setAnchorEl(curr => (curr ? null : triggerRef.current));
  }, []);

  const [isRefreshed, setIsRefreshed] = useState(false);

  useEffect(() => {
    setIsRefreshed(true);
    return () => setTimeout(() => setIsRefreshed(false), 800);
  }, [progressBarInfo]);

  const {
    data: states,
    isLoading: isStatesLoading,
    isError: isStatesError
  } = useGetList('states', {
    pagination: PREFETCH_PAGINATION,
    sort: ID_SORT
  });

  if (!record || isStatesLoading || isStatesError) {
    return null;
  }

  const stateField = (
    <StateField
      className={ProgressBarTriggerClasses.stateField}
      source={'state'}
      margin='dense'
      variant='body2'
      record={states.find(state => state.id === record.state)}
      stateByInfra={isDebugMode ? record?.stateByInfra : undefined}
    />
  );

  if (!ongoingProgresses?.length) {
    return (
      <StyledBox isEmpty>
        <Card className={ProgressBarTriggerClasses.card}>
          <CardContent className={ProgressBarTriggerClasses.cardContent}>
            {stateField}
          </CardContent>
        </Card>
      </StyledBox>
    );
  }

  const createProgress = ongoingProgresses.find(
    progress => progress.actionType === MESSENGER_ACTION_AMQP_TYPES.CREATE
  );

  const updateProgress = ongoingProgresses.find(
    progress => progress.actionType === MESSENGER_ACTION_AMQP_TYPES.UPDATE
  );

  const lowPriorityProgresses = ongoingProgresses.filter(progress =>
    LOW_PRIORITY_ACTIONS.includes(progress.actionType)
  );

  return (
    <StyledBox isRefreshed={isRefreshed}>
      <Card
        ref={triggerRef}
        variant='outlined'
        className={ProgressBarTriggerClasses.card}
        onClick={toggleProgressBarOpen}
        aria-describedby={popperId}
        elevation={3}
      >
        <CardActionArea>
          <CardContent className={ProgressBarTriggerClasses.cardContent}>
            {/* <Typography variant='body2'>
              {createProgress
                ? `${t('components.environmentProgressBar.label.creating')}:`
                : updateProgress
                ? `${t('components.environmentProgressBar.label.updating')}:`
                : ''}
            </Typography> */}
            {stateField}
            {createProgress ? (
              <Typography
                variant='body2'
                color={theme => theme.palette.primary.main}
              >
                {t('components.environmentProgressBar.messages.steps_left', {
                  step: createProgress.itemProcessed + 1,
                  smart_count: createProgress.processCount
                })}
              </Typography>
            ) : updateProgress ? (
              <Typography
                variant='body2'
                color={theme => theme.palette.primary.main}
              >
                {t('components.environmentProgressBar.messages.steps_left', {
                  step: createProgress.itemProcessed + 1,
                  smart_count: createProgress.processCount
                })}
              </Typography>
            ) : (
              !!lowPriorityProgresses.length && (
                <Typography
                  variant='body2'
                  color={theme => theme.palette.primary.main}
                >
                  {t(
                    'components.environmentProgressBar.messages.ongoing_progresses',
                    { smart_count: lowPriorityProgresses.length }
                  )}
                </Typography>
              )
            )}
          </CardContent>
        </CardActionArea>
      </Card>
      <Popper
        id={popperId}
        open={open}
        anchorEl={anchorEl}
        placement={'bottom'}
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, -(anchorEl?.getBoundingClientRect().height + 1 || 0)]
            }
          }
        ]}
        disablePortal
        sx={theme => ({
          zIndex: theme.zIndex.appBar + 1,
          width: anchorEl?.getBoundingClientRect().width
        })}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={250}>
            {ongoingProgresses.length ? (
              <EnvironmentStatusProgressBar
                progressBarInfo={progressBarInfo}
                isRefreshed={isRefreshed}
                onClose={toggleProgressBarOpen}
                width={WIDTH}
              >
                {stateField}
              </EnvironmentStatusProgressBar>
            ) : (
              'No progress to show'
            )}
          </Fade>
        )}
      </Popper>
    </StyledBox>
  );
};

const PREFIX = 'EnvironmentStatusProgressBarTrigger';

export const ProgressBarTriggerClasses = {
  card: `${PREFIX}-card`,
  cardContent: `${PREFIX}-cardContent`,
  stateReferenceField: `${PREFIX}-stateReferenceField`,
  stateField: `${PREFIX}-stateField`,
  popperCard: `${PREFIX}-popperCard`,
  popperCardContent: `${PREFIX}-popperCardContent`,
  fade: `${PREFIX}-fade`,
  fadeEnter: `${PREFIX}-fade-enter`,
  fadeEnterActive: `${PREFIX}-fade-enter-active`,
  fadeExit: `${PREFIX}-fade-exit`,
  fadeExitActive: `${PREFIX}-fade-exit-active`
};

const StyledBox = styled(Box, {
  shouldForwardProp: prop => prop !== 'isRefreshed' || prop !== 'isEmpty'
})(({ theme, isRefreshed, isEmpty }) => ({
  [`& .${ProgressBarTriggerClasses.card}`]: {
    minWidth: WIDTH,
    margin: isEmpty
      ? theme.spacing(0)
      : `${theme.spacing(1)} ${theme.spacing(0)}`,
    background: isEmpty ? 'transparent' : theme.palette.background.paper,
    boxShadow: isRefreshed
      ? theme.shadows[6]
      : isEmpty
      ? theme.shadows[0]
      : theme.shadows[2],
    transition: 'box-shadow 0.3s ease-in-out, margin 0.3s ease-in-out',

    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(0)
    },

    [`& .${ProgressBarTriggerClasses.cardContent}`]: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      rowGap: theme.spacing(0.75),
      columnGap: theme.spacing(2),
      padding: isEmpty
        ? `${theme.spacing(1)} ${theme.spacing(2)} ${theme.spacing(
            1
          )} ${theme.spacing(0)}`
        : `${theme.spacing(1)} ${theme.spacing(2)}`,

      [theme.breakpoints.up('sm')]: {
        justifyContent: isEmpty ? 'flex-end' : 'space-between',
        padding: `${theme.spacing(1)} ${theme.spacing(2)}`
      }
    }
  },

  [`& .${ProgressBarTriggerClasses.stateReferenceField}`]: {
    display: 'flex'
  },

  [`& .${ProgressBarTriggerClasses.stateField}`]: {
    marginBottom: 0,
    color: theme.palette.mode === 'light' ? 'inherit' : theme.palette.grey[100]
  }
}));

import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useInput } from 'ra-core';
import { Box, styled } from '@mui/material';
import * as ReactColor from 'react-color';

/**
 *
 * @param {object} props
 * @param {import('@mui/material').SxProps} props.sx
 * @returns
 */
const ColorPicker = props => {
  const {
    onChange,
    options,
    picker,
    show,
    setShow,
    children,
    hasOverlay,
    ...rest
  } = props;

  const { field } = useInput({
    ...props
  });

  const handleChange = useCallback(
    ({ hex }, event) => {
      event.target.value = hex;
      field.onChange(event);
      if (onChange) {
        onChange(event);
      }
    },
    [field, onChange]
  );

  const Picker = ReactColor[`${picker}Picker`];

  if (!show) {
    return null;
  }

  return (
    <Root className={ColorPickerClasses.popup} {...rest}>
      {hasOverlay && (
        <div
          className={ColorPickerClasses.cover}
          onClick={() => setShow(false)}
        />
      )}
      {children}
      <Picker {...options} color={field.value} onChange={handleChange} />
    </Root>
  );
};

ColorPicker.propTypes = {
  label: PropTypes.string,
  options: PropTypes.object,
  source: PropTypes.string,
  helperText: PropTypes.string,
  className: PropTypes.string,
  picker: (props, propName, componentName) =>
    !ReactColor[`${props[propName]}Picker`] &&
    new Error(`Invalid prop \`${propName}\` supplied to \`${componentName}\`.`)
};

ColorPicker.defaultProps = {
  picker: 'Chrome',
  options: {
    disableAlpha: true
  },
  fullWidth: false,
  margin: 'dense',
  variant: 'filled',
  hasOverlay: true
};

const PREFIX = 'ColorInput';

export const ColorPickerClasses = {
  popup: `${PREFIX}-popup`,
  cover: `${PREFIX}-cover`
};

const Root = styled(Box)(() => ({
  [`&.${ColorPickerClasses.popup}`]: {
    position: 'absolute',
    zIndex: 2
  },

  [`& .${ColorPickerClasses.cover}`]: {
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0
  }
}));

export default ColorPicker;

import React, { useCallback, useLayoutEffect, useRef, useState } from 'react';
import { useSidebarState } from 'react-admin';
import {
  Stack,
  Box,
  styled,
  IconButton,
  Popper,
  useMediaQuery
} from '@mui/material';
import { Close as IconCancel, Help as IconHelp } from '@mui/icons-material';
import { TwoColumnFormStyles } from '@rc/admin/theme/styles';
import { HelpCardClasses } from '../input';
import clsx from 'clsx';

/**
 *
 * @typedef {object} HelpLayoutProps
 * @property {React.ReactNode} props.children
 * @property {React.ReactNode} props.helpers
 * @property {string} props.className
 * @property {boolean} props.isTooltip
 *
 * @param {import('@mui/material').StackProps & HelpLayoutProps} props
 * @returns
 */
export const HelpLayout = props => {
  const {
    children,
    helpers,
    className,
    isTooltip: isTooltipProp,
    ...rest
  } = props;
  const [isSidebarOpen] = useSidebarState();
  const isSmallContentWidth = useMediaQuery(theme =>
    theme.breakpoints.down(isSidebarOpen ? 'lg' : 'md')
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const triggerRef = useRef(null);

  const handleClick = useCallback(
    event => {
      setAnchorEl(anchorEl ? null : event.currentTarget);
    },
    [anchorEl]
  );

  const isOpen = Boolean(anchorEl);
  const id = isOpen ? 'help-popper' : undefined;

  const isTooltip =
    isTooltipProp !== undefined ? isTooltipProp : isSmallContentWidth;

  useLayoutEffect(() => {
    if (isTooltip) {
      triggerRef.current?.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerRef]);

  return (
    <Root
      className={clsx(
        className,
        HelpLayoutClasses.root,
        isTooltip && HelpLayoutClasses.withTooltip
      )}
      isSidebarOpen={isSidebarOpen}
      {...rest}
    >
      <Stack className={HelpLayoutClasses.content}>{children}</Stack>
      <Box className={HelpLayoutClasses.helper}>
        {isTooltip ? (
          <>
            <IconButton
              // ref={defaultOpen ? setAnchorEl : undefined}
              ref={triggerRef}
              aria-describedby={id}
              className={HelpLayoutClasses.trigger}
              onClick={handleClick}
            >
              {isOpen ? <IconCancel /> : <IconHelp />}
            </IconButton>
            <Popper
              sx={theme => ({
                zIndex: 1,
                maxWidth: '75vw',
                [theme.breakpoints.up('md')]: {
                  maxWidth: '30em'
                }
              })}
              id={id}
              open={isOpen}
              anchorEl={anchorEl}
              popperOptions={{
                placement: 'top-end'
              }}
            >
              {helpers}
            </Popper>
          </>
        ) : (
          helpers
        )}
      </Box>
    </Root>
  );
};

const PREFIX = 'HelpLayout';

export const HelpLayoutClasses = {
  root: `${PREFIX}-root`,
  withTooltip: `${PREFIX}-withTooltip`,
  content: `${PREFIX}-content`,
  helper: `${PREFIX}-helper`,
  trigger: `${PREFIX}-trigger`
};

const Root = styled(Stack, {
  shouldForwardProp: prop => prop !== 'isSidebarOpen'
})(({ theme, isSidebarOpen }) => ({
  width: '100%',
  flexDirection: 'column',
  columnGap: theme.spacing(6),
  rowGap: theme.spacing(3),

  [theme.breakpoints.up(isSidebarOpen ? 'lg' : 'md')]: {
    flexDirection: 'row'
  },

  [`& .${HelpLayoutClasses.content}`]: {
    ...TwoColumnFormStyles(theme)
  },

  [`& .${HelpLayoutClasses.helper}`]: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%',
    position: 'relative',

    [theme.breakpoints.up('md')]: {
      alignItems: 'center',
      justifyContent: 'center'
    },

    [`& .${HelpCardClasses.root}`]: {
      minHeight: 32,

      [theme.breakpoints.up(isSidebarOpen ? 'lg' : 'md')]: {
        maxWidth: '30em'
      }
    }
  },

  [`&.${HelpLayoutClasses.withTooltip}`]: {
    [theme.breakpoints.up(isSidebarOpen ? 'lg' : 'md')]: {
      display: 'grid',
      gridTemplateColumns: '1fr auto'
    },

    [`& .${HelpLayoutClasses.helper}`]: {
      alignItems: 'flex-end',
      justifyContent: 'flex-end',

      [theme.breakpoints.up('md')]: {
        alignItems: 'flex-end',
        justifyContent: 'flex-end'
      }
    }
  }
}));

import React from 'react';
import { UserList } from './List';
// import { UserShow } from './Show';
import { UserCreate } from './Create';
import { UserEdit } from './Edit';
import Iconify from '@rc/admin/components/ui/Iconify';

export default {
  name: 'users',
  list: UserList,
  create: UserCreate,
  edit: UserEdit,
  // show: UserShow,
  icon: () => <Iconify icon='solar:users-group-rounded-bold-duotone' />
};

import React from 'react';
import { Datagrid, List, TextField } from 'react-admin';

export const StateList = () => {
  return (
    <List perPage={1000}>
      <Datagrid bulkActionButtons={false}>
        <TextField source='name' />
        <TextField source='id' />
      </Datagrid>
    </List>
  );
};

import { ROLES, PERMISSIONS } from '@rc/admin/roles';

export const getHasPermission = (resourceName, action, permissions = []) => {
  return permissions.reduce((acc, curr) => {
    if (acc || curr === ROLES.ADMIN) {
      return true;
    }

    return (
      PERMISSIONS[curr] &&
      PERMISSIONS[curr].some(
        perm =>
          perm.resource === resourceName && matchActions(perm.actions, action)
      )
    );
  }, false);
};

const matchActions = (actions, action) => {
  if (!action) return true;

  if (typeof actions === 'string') {
    return actions === '*' || actions === action;
  } else if (Array.isArray(actions)) {
    return actions.includes(action);
  }

  return false;
};

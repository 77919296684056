import { ComponentVersionEnvVarEdit } from './Edit';
import { ComponentVersionEnvVarCreate } from './Create';

export default {
  name: 'component_version_env_vars',
  edit: ComponentVersionEnvVarEdit,
  create: ComponentVersionEnvVarCreate,
  options: {
    showInMenu: false
  }
};

import React from 'react';
import { useGetOne, useRecordContext } from 'react-admin';
import { Skeleton, Typography, styled } from '@mui/material';

/**
 *
 * @param {object} props
 * @param {string} props.source
 * @param {any} props.record
 * @param {string} props.className
 * @param {import('@mui/material').TypographyProps['variant']} props.variant
 * @returns
 */
export const ProjectTypeField = props => {
  const { record: recordProp, className, variant = 'body2' } = props;
  const record = useRecordContext() || recordProp;

  const { data: projectType, isLoading: isProjectTypeLoading } = useGetOne(
    'project_types',
    { id: record?.projectType },
    { enabled: !!record }
  );

  if (!record || isProjectTypeLoading) {
    return <Skeleton variant='text' />;
  }

  return (
    <Typography variant={variant} className={className}>
      <BrandImage src={projectType.logo} width={24} height={24} />
      {`${projectType?.name} ${record?.version}`}
    </Typography>
  );
};

const BrandImage = styled('img')(({ theme }) => ({
  width: 'auto',
  height: 24,
  marginRight: theme.spacing(1)
}));

import { useMemo } from 'react';

/**
 *
 * @param {object} props
 * @param {object?} props.project
 * @param {object?} props.team
 * @param {object?} props.organisation
 * @returns
 */
export const useInheritedEnvVars = props => {
  const { project, team, organisation } = props;

  const envVarsAggregated = useMemo(() => {
    const result = [];
    if (project) {
      result.push({ id: project.id, record: project, source: 'projectEnvVar' });
    }

    if (team) {
      result.push({ id: team.id, record: team, source: 'teamEnvVar' });
    }

    if (organisation) {
      result.push({
        id: organisation.id,
        record: organisation,
        source: 'organisationEnvVar'
      });
    }

    return result;
  }, [organisation, project, team]);

  return {
    envVarsAggregated
  };
};

export const getIsInheritedEnvVar = (key, inheritedEnvVars = []) => {
  return (
    key &&
    inheritedEnvVars.some(({ record, source: fieldSource }) =>
      record[fieldSource].some(({ key: fieldKey }) => fieldKey === key)
    )
  );
};

export const useInheritedEnvVar = (key, inheritedEnvVars) => {
  return useMemo(() => getIsInheritedEnvVar(key, inheritedEnvVars), [
    inheritedEnvVars,
    key
  ]);
};

import React from 'react';
import { Datagrid, List, SimpleList, TextField } from 'react-admin';
import { EditButton } from '@rc/admin/ra';
import { useMediaQuery } from '@mui/material';

export const ResourceTypeList = () => {
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

  return (
    <List>
      {isSmall ? (
        <SimpleList primaryText={<TextField source='name' />} />
      ) : (
        <Datagrid>
          <TextField source='name' />
          <EditButton />
        </Datagrid>
      )}
    </List>
  );
};

export default ResourceTypeList;

import React from 'react';
import { Card, styled, useMediaQuery } from '@mui/material';
import {
  AsideReferenceList,
  Breadcrumbs,
  DeleteButton,
  EditTitle,
  SectionTitle
} from '@rc/admin/components';
import { EditButton } from '@rc/admin/ra';
import { TwoColumnFormStyles } from '@rc/admin/theme/styles';
import {
  BooleanField,
  BooleanInput,
  Datagrid,
  Edit,
  EditClasses,
  SaveButton,
  SimpleForm,
  SimpleList,
  TextField,
  TextInput,
  Toolbar,
  ToolbarClasses
} from 'react-admin';
import { EnvVarValidationReferenceArrayInput } from '../envVarValidation/components';

export const ProjectTypeVersionEnvVarEdit = () => {
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

  return (
    <StyledEdit
      title={<EditTitle source={'key'} />}
      redirect={false}
      mutationMode='pessimistic'
      actions={
        <Breadcrumbs
          label={<BreadcrumbsLabel />}
          parents={breadcrumbsParents}
        />
      }
      aside={
        <Card className={classes.asideCard}>
          <AsideReferenceList
            resource={'project_type_version_env_var_examples'}
            referenceSource={'projectTypeVersionEnvVar'}
            title={'Examples'}
          >
            {isSmall ? (
              <SimpleList
                primaryText={() => <TextField source={'value'} />}
                secondaryText={() => <BooleanField source={'isDefault'} />}
                style={{ width: '100%' }}
              />
            ) : (
              <Datagrid style={{ width: '100%' }}>
                <TextField source={'value'} />
                <BooleanField source={'isDefault'} />
                <EditButton />
              </Datagrid>
            )}
          </AsideReferenceList>
        </Card>
      }
    >
      <SimpleForm
        defaultValues={{ isEnabled: true }}
        className={classes.form}
        toolbar={
          <Toolbar>
            <div className={ToolbarClasses.defaultToolbar}>
              <SaveButton />
              <DeleteButton
                redirect={(_, __, record) =>
                  `project_type_versions/${encodeURIComponent(
                    record?.projectTypeVersion
                  )}`
                }
              />
            </div>
          </Toolbar>
        }
      >
        <TextInput source={'key'} fullWidth />
        <TextInput source={'note'} fullWidth />
        <BooleanInput source={'isRequired'} fullWidth />
        <BooleanInput source={'isEncrypted'} fullWidth />
        <SectionTitle
          title={
            'resources.project_type_version_env_vars.sections.env_var_validations'
          }
          sx={theme => ({ marginBottom: theme.spacing(2) })}
        />
        <EnvVarValidationReferenceArrayInput
          source='envVarValidations'
          label='resources.project_type_version_env_vars.fields.envVarValidations'
        />
      </SimpleForm>
    </StyledEdit>
  );
};

const breadcrumbsParents = [
  { reference: 'project_type_versions', source: 'projectTypeVersion' },
  { reference: 'project_types', source: 'projectType' }
];

const BreadcrumbsLabel = props => {
  const { data } = props;

  switch (data['@type']) {
    case 'ProjectTypeVersionEnvVar':
      return data.key;
    case 'ProjectTypeVersion':
      return data.version;
    default:
      return data.name;
  }
};

const PREFIX = 'ProjectTypeVersionEnvVarEdit';

const classes = {
  asideCard: `${PREFIX}-asideCard`,
  form: `${PREFIX}-form`
};

const StyledEdit = styled(Edit)(({ theme }) => ({
  [`& .${EditClasses.main}`]: {
    display: 'grid',
    gridTemplateColumns: '5fr 3fr',
    columnGap: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr',
      gap: theme.spacing(3),
      '& > *': {
        width: '100%'
      }
    }
  },

  [`& .${classes.asideCard}`]: {
    height: '100%'
  },

  [`& .${classes.form}`]: {
    ...TwoColumnFormStyles(theme)
  }
}));

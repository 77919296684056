import React, { useCallback } from 'react';
import {
  useRecordContext,
  useRefresh,
  useTranslate,
  useNotify
} from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { useAxios } from '@rc/utils/axios';
import { Button, Stack } from '@mui/material';
import { SplitButton } from '@rc/admin/components';

const EXPORT_DATABASE_OPTION_STRIP_DATABASE =
  'resources.exported_datas.exclude_sensitive_data';

export const DataExportActions = () => {
  const t = useTranslate();
  const record = useRecordContext();
  const refresh = useRefresh();
  const { getValues } = useFormContext();
  const notify = useNotify();

  const [{ loading: isExportDatabaseLoading }, exportDatabase] = useAxios(
    {
      url: `/api/environments/${record?.originId}/export-database`,
      method: 'PUT',
      // Set data in order to prevent browsers removing Content-Type header (https://github.com/axios/axios/issues/2544)
      data: {}
    },
    { manual: true }
  );
  const [{ loading: isExportMediaLoading }, exportMedia] = useAxios(
    {
      url: `/api/environments/${record?.originId}/export-media`,
      method: 'PUT',
      // Set data in order to prevent browsers removing Content-Type header (https://github.com/axios/axios/issues/2544)
      data: {}
    },
    { manual: true }
  );

  const handleExportDatabase = useCallback(
    async isStripDatabase => {
      const formValues = getValues();
      try {
        await exportDatabase({ data: { ...formValues, isStripDatabase } });
      } catch (error) {
        notify(error?.message, { type: 'error' });
      }

      refresh();
    },
    [exportDatabase, getValues, notify, refresh]
  );

  const handleExportMedia = useCallback(async () => {
    try {
      await exportMedia({ data: {} });
    } catch (error) {
      notify(error?.message, { type: 'error' });
    }

    refresh();
  }, [exportMedia, notify, refresh]);

  const isDisabled = isExportDatabaseLoading || isExportMediaLoading;

  return (
    <Stack
      flexDirection={'row'}
      columnGap={1}
      sx={{ width: '100%', alignItems: 'center' }}
    >
      <SplitButton
        unselectable
        label={t('resources.exported_datas.strip_database_label')}
        variant='contained'
        options={[EXPORT_DATABASE_OPTION_STRIP_DATABASE]}
        defaultOption={''}
        onChanged={() => handleExportDatabase(true)}
        onClick={() => handleExportDatabase(false)}
        disabled={isDisabled}
        sx={theme => ({ marginRight: theme.spacing(1) })}
        getOptionLabel={t}
      >
        {t('resources.exported_datas.action.export_database')}
      </SplitButton>
      <Button
        type='button'
        variant='contained'
        color='primary'
        onClick={handleExportMedia}
        disabled={isDisabled}
      >
        {t('resources.exported_datas.action.export_media')}
      </Button>
    </Stack>
  );
};

export default DataExportActions;

import { useMemo } from 'react';
import { useTranslate } from 'react-admin';
import { useUserCosts } from '../domain';

export const useEnvironmentsUsageChart = () => {
  const t = useTranslate();

  const { costs, currency, isLoading: isLoadingCosts } = useUserCosts();

  const chart = useMemo(() => {
    if (!costs) {
      return {
        series: []
      };
    }

    return {
      series: costs.environments
        .map(({ sum, environment }) => {
          return {
            label: `${environment.project?.name} / ${environment.name}`,
            value: sum.amount
          };
        })
        .filter(serie => serie.value)
    };
  }, [costs]);

  return {
    chart,
    currency,
    isLoading: isLoadingCosts
  };
};

import { useGetOne } from 'react-admin';

/**
 * Hook that chains multiple useGetOne hooks together.
 *
 * @param {{resource: string, id: string, options: object}} arg1
 * @param  {...{resource: string, source: string, options: object}} args
 * @returns {Array.<import('ra-core').UseGetOneHookValue>}
 */
export const useNestedGetOne = definitions => {
  return definitions
    .filter(v => v)
    .reduce((queries, definition, index) => {
      const { resource, id: definitionId, source, options = {} } = definition;
      const previousQuery = queries[index - 1];
      const id = !previousQuery ? definitionId : previousQuery.data?.[source];

      return [
        ...queries,
        useGetOne(
          resource,
          { id },
          {
            ...options,
            enabled: !!id
          }
        )
      ];
    }, []);
};

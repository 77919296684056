import {
  CpuInputConfig,
  MemoryInputConfig,
  StorageInputConfig
} from '../ResourceInput';

export const CpuFieldConfig = {
  unit: CpuInputConfig.unit,
  label: CpuInputConfig.label
};

export const MemoryFieldConfig = {
  unit: MemoryInputConfig.unit,
  label: MemoryInputConfig.label
};

export const StorageFieldConfig = {
  unit: StorageInputConfig.unit,
  label: StorageInputConfig.label,
  format: StorageInputConfig.format
};

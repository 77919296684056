import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-admin';
import { useLogin, useNotify, useSafeSetState, useTranslate } from 'ra-core';
import { useForm } from 'react-hook-form';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import {
  Button,
  CardActions,
  CircularProgress,
  IconButton,
  InputAdornment,
  inputAdornmentClasses,
  styled,
  Typography,
  Link as MuiLink,
  Stack
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { FieldInput } from '@rc/admin/components';
import { useIntrospection } from '@api-platform/admin';
import { validateUsernameOrEmail } from '@rc/admin/utils/form';
import { BRAND_NAME, GRECAPTCHA_ENABLED } from '@rc/admin/constants';

const LoginForm = props => {
  const { redirectTo, children } = props;
  const login = useLogin();
  const t = useTranslate();
  const notify = useNotify();
  const introspect = useIntrospection();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [showPassword, setShowPassword] = useState(false);

  const [isLoading, setIsLoading] = useSafeSetState(false);
  const { control, handleSubmit } = useForm({
    defaultValues: {
      username: '',
      password: ''
    }
  });

  const onSubmit = async values => {
    setIsLoading(true);

    let recaptchaToken = null;
    if (GRECAPTCHA_ENABLED && !executeRecaptcha) {
      console.error('Error on captcha');
      return;
    } else if (executeRecaptcha) {
      recaptchaToken = await executeRecaptcha('login');
    }

    login(
      {
        ...values,
        token: recaptchaToken
      },
      redirectTo
    )
      .then(() => {
        introspect();
        setIsLoading(false);
      })
      .catch(error => {
        setIsLoading(false);
        notify(
          typeof error === 'string'
            ? error
            : typeof error === 'undefined' || !error.message
            ? 'ra.auth.sign_in_error'
            : error.message,
          {
            type: 'warning',
            messageArgs: {
              _:
                typeof error === 'string'
                  ? error
                  : error && error.message
                  ? error.message
                  : undefined
            }
          }
        );
      });
  };

  return (
    <>
      {children}

      <StyledForm
        className={classes.form}
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <Stack spacing={1} sx={{ mb: 2 }}>
          <Typography variant='h4'>
            {t('pages.login.title', { name: BRAND_NAME })}
          </Typography>

          <Stack direction='row' spacing={0.5}>
            <Typography variant='body2'>
              {t('pages.login.create_account')}
            </Typography>
            <MuiLink component={Link} to={'/register'} variant='subtitle2'>
              {t('pages.login.create_account_link')}
            </MuiLink>
          </Stack>
        </Stack>

        <Stack spacing={1}>
          <FieldInput
            control={control}
            name='username'
            label={t('ra.auth.username_or_email')}
            disabled={isLoading}
            autoFocus
            rules={{
              required: 'ra.validation.required',
              validate: validateUsernameOrEmail
            }}
          />

          <FieldInput
            control={control}
            name='password'
            type={showPassword ? 'text' : 'password'}
            autoComplete='current-password'
            label={t('ra.auth.password')}
            disabled={isLoading}
            rules={{
              required: 'ra.validation.required'
            }}
            InputProps={{
              sx: {
                [`& .${inputAdornmentClasses.positionEnd} button`]: {
                  marginRight: '-6px'
                }
              },
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={() => setShowPassword(!showPassword)}
                    onMouseDown={e => e.preventDefault()}
                    edge='end'
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />

          <MuiLink
            component={Link}
            to={'/forgot-password'}
            variant='body2'
            color='inherit'
            underline='always'
            sx={{ alignSelf: 'flex-end' }}
          >
            {t('pages.login.forgot_password')}
          </MuiLink>
        </Stack>
        <CardActions className={classes.actions}>
          <Button
            variant='contained'
            type='submit'
            color='primary'
            size='large'
            fullWidth
            disabled={isLoading}
            className={classes.button}
          >
            {isLoading && (
              <CircularProgress
                className={classes.icon}
                size={18}
                thickness={2}
              />
            )}
            {t('ra.auth.sign_in')}
          </Button>
        </CardActions>
      </StyledForm>
    </>
  );
};

const PREFIX = 'LoginForm';

const classes = {
  form: `${PREFIX}-form`,
  input: `${PREFIX}-input`,
  icon: `${PREFIX}-icon`,
  actions: `${PREFIX}-actions`
};

const StyledForm = styled('form')(({ theme }) => ({
  padding: '1em',

  [`& .${classes.actions}`]: {
    marginTop: theme.spacing(2.5),
    padding: 0
  },

  [`& .${classes.icon}`]: {
    marginRight: theme.spacing(1)
  }
}));

LoginForm.propTypes = {
  redirectTo: PropTypes.string
};

export default LoginForm;

import React, { useState, useCallback } from 'react';
import {
  Card,
  CardContent,
  Stack,
  styled,
  Typography,
  Toolbar as MuiToolbar
} from '@mui/material';
import _ from 'lodash';
import {
  AutocompleteInput,
  Edit,
  required,
  ResourceContextProvider,
  SaveButton,
  SimpleForm,
  TextInput,
  Title,
  Toolbar as RaToolbar,
  useGetIdentity,
  useRefresh,
  useNotify,
  useTranslate
} from 'react-admin';
import { TwoColumnFormStyles } from '@rc/admin/theme/styles';
import { currencies } from '@rc/admin/utils/form/currencies';
import { LoadingBox } from '@rc/admin/components';
import DeleteAccount from './DeleteAccount';
import PasswordHandle from '@rc/admin/routes/Profile/components/PasswordHandle';
import { validatePasswordStrength } from '@rc/admin/utils/form';

export const EditProfile = () => {
  const refresh = useRefresh();
  const notify = useNotify();
  const t = useTranslate();
  const { identity, isLoading: isIdentityLoading } = useGetIdentity();
  const [isLoading, setIsLoading] = useState(true);

  const onLoaded = useCallback(() => {
    setIsLoading(false);
  }, []);

  const onError = useCallback(() => {
    notify(t('notification.password_error'));
    // Refresh the user data after the submission no matter what is the response status.
    refresh();
  }, [refresh, notify, t]);

  const validate = values => {
    const errors = {};

    if (values.changePassword) {
      if (
        !values.changePasswordAgain ||
        values.changePasswordAgain !== values.changePassword
      ) {
        errors.changePassword = t('validation.pass_does_not_match');
      }

      if (undefined !== validatePasswordStrength(values.changePassword)) {
        errors.changePassword = t('validation.weak_password');
      }
    }

    return errors;
  };

  const transform = data => {
    if (_.isEmpty(data.changePassword)) {
      delete data.changePassword;
    }

    delete data.changePasswordAgain;

    return data;
  };

  if (isIdentityLoading) {
    return (
      <>
        <Title defaultTitle={t('page.account')} />
        <LoadingBox minHeight={200} />
      </>
    );
  }

  return (
    <ResourceContextProvider value={'users'}>
      <Root>
        <Edit
          title={t('page.account')}
          id={`/api/users/${identity?.id}`}
          actions={null}
          queryOptions={{ onSuccess: onLoaded }}
          mutationOptions={{ onError }}
          redirect={false}
          transform={transform}
        >
          <SimpleForm
            className={classes.form}
            toolbar={<Toolbar />}
            validate={validate}
            defaultValue={{
              changePassword: '',
              changePasswordAgain: ''
            }}
          >
            <Typography variant={'h5'} sx={theme => ({ mb: theme.spacing(2) })}>
              {t('pages.profile.edit.title')}
            </Typography>
            <TextInput
              source={'fullname'}
              isRequired
              validate={[required()]}
              fullWidth
            />
            <AutocompleteInput
              source={'currency'}
              choices={currencies}
              optionText={'label'}
              optionValue={'id'}
              defaultValue={'huf'}
              fullWidth
            />
            <PasswordHandle />
          </SimpleForm>
        </Edit>
        {!isLoading && (
          <Card>
            <CardContent sx={theme => ({ paddingBottom: theme.spacing(1) })}>
              <Typography
                variant={'h5'}
                sx={theme => ({ mb: theme.spacing(1) })}
              >
                {t('pages.profile.delete_account.title')}
              </Typography>
              <Typography variant={'body1'}>
                {t('pages.profile.delete_account.subtitle')}
              </Typography>
            </CardContent>
            <MuiToolbar>
              <DeleteAccount />
            </MuiToolbar>
          </Card>
        )}
      </Root>
    </ResourceContextProvider>
  );
};

const Toolbar = props => {
  return (
    <RaToolbar
      {...props}
      style={{ display: 'flex', justifyContent: 'space-between' }}
    >
      <SaveButton label='ra.action.save' />
    </RaToolbar>
  );
};

const PREFIX = 'DeleteAccount';

const classes = {
  form: `${PREFIX}-form`
};

const Root = styled(Stack)(({ theme }) => ({
  flexDirection: 'column',
  rowGap: theme.spacing(2),

  [`& .${classes.form}`]: {
    ...TwoColumnFormStyles(theme)
  }
}));

import React from 'react';
import { useRecordContext, useTranslate } from 'react-admin';
import clsx from 'clsx';
import { styled, Box, Typography } from '@mui/material';
import { STATE_IDS } from '@rc/admin/constants';
import { StateByInfraTooltip } from './StateByInfraTooltip';

const DOT_SIZE = 10;

/**
 *
 * @param {object} props
 * @param {"none"|"dense"|"normal"} props.margin
 * @param {string} props.className
 * @param {string} props.dotClassName
 * @param {import('@mui/material').SxProps<import('@mui/material').Theme>} props.sx
 * @param {import('@mui/material').TypographyProps['component']} props.textComponent
 * @param {import('@mui/material').TypographyProps['fontWeight']} props.fontWeight
 * @param {import('@mui/material').TypographyVariantsOptions} props.variant
 * @param {import('react-admin').Record} props.record
 * @param {string} props.stateByInfra
 * @returns
 */
export const StateField = props => {
  const {
    className,
    dotClassName,
    sx,
    margin,
    variant,
    fontWeight = '500',
    record,
    stateByInfra
  } = props;
  const { originId } = useRecordContext({ record });
  const t = useTranslate();

  return (
    <Root
      className={clsx(
        StateFieldClasses.root,
        StateFieldClasses[margin],
        className
      )}
      component='span'
      sx={sx}
    >
      <span
        className={clsx(
          StateFieldClasses.loadingDot,
          dotClassName,
          getClasses(originId)
        )}
      />
      <Typography
        color={'inherit'}
        component='span'
        variant={variant}
        fontWeight={fontWeight}
        noWrap
      >
        {t(`resources.states.names.${originId}`)}
      </Typography>
      {stateByInfra && <StateByInfraTooltip id={stateByInfra} />}
    </Root>
  );
};

const PREFIX = 'StateField';

export const StateFieldClasses = {
  root: `${PREFIX}-statusContainer`,
  none: `${PREFIX}-marginNone`,
  dense: `${PREFIX}-marginDense`,
  normal: `${PREFIX}-marginNormal`,
  loadingDot: `${PREFIX}-loadingDot`,
  flashing: `${PREFIX}-flashing`,
  loadingDotProgress: `${PREFIX}-loadingDotProgress`,
  loadingDotSuccess: `${PREFIX}-loadingDotSuccess`,
  loadingDotFailure: `${PREFIX}-loadingDotFailure`,
  loadingDotStopped: `${PREFIX}-loadingDotStopped`,
  loadingDotDeleted: `${PREFIX}-loadingDotDeleted`,
  tooltip: `${PREFIX}-tooltip`,
  fade: `${PREFIX}-fade`
};

const Root = styled(Box)(({ theme }) => ({
  [`&.${StateFieldClasses.root}`]: {
    display: 'flex',
    alignItems: 'center',
    gridGap: theme.spacing(1),
    [`&.${StateFieldClasses.dense}`]: {
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(0.5)
    },
    [`&.${StateFieldClasses.normal}`]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1)
    }
  },

  [`& .${StateFieldClasses.loadingDot}`]: {
    width: DOT_SIZE,
    height: DOT_SIZE,
    minWidth: DOT_SIZE,
    borderRadius: '50%'
  },

  '@keyframes dotBlink': {
    '0%': {
      opacity: 0.2
    },
    '50%': {
      opacity: 1
    },
    '100%': {
      opacity: 0.2
    }
  },

  [`& .${StateFieldClasses.flashing}`]: {
    animationName: 'dotBlink',
    animationDuration: '1000ms',
    animationIterationCount: 'infinite',
    animationFillMode: 'both'
  },

  [`& .${StateFieldClasses.loadingDotProgress}`]: {
    background: theme.palette.info.main
  },

  [`& .${StateFieldClasses.loadingDotSuccess}`]: {
    background: theme.palette.success.main
  },

  [`& .${StateFieldClasses.loadingDotFailure}`]: {
    background: theme.palette.error.main
  },

  [`& .${StateFieldClasses.loadingDotStopped}`]: {
    background: theme.palette.warning.main
  },

  [`& .${StateFieldClasses.loadingDotDeleted}`]: {
    background: theme.palette.grey[500]
  }
}));

const getClasses = originId => {
  /** @see \RewardCloud\Enum\StateEnum (src/Enum/StateEnum.php) */
  switch (Number(originId)) {
    case STATE_IDS.INIT_REQ:
    case STATE_IDS.INIT: // INIT
    case STATE_IDS.UPDATE_REQ:
    case STATE_IDS.UPDATE:
    case STATE_IDS.PROGRESSING:
    case STATE_IDS.STOP:
    case STATE_IDS.DEL_REQ:
    case STATE_IDS.DEL:
    case STATE_IDS.BUILD_REQ:
    case STATE_IDS.BUILD:
    case STATE_IDS.DEPLOY_REQ:
    case STATE_IDS.DEPLOY:
    case STATE_IDS.IMPORT_REQ:
    case STATE_IDS.IMPORT:
    case STATE_IDS.EXPORT_REQ:
    case STATE_IDS.EXPORT:
    case STATE_IDS.ACK:
    case STATE_IDS.PENDING:
    case STATE_IDS.CREATING:
    case STATE_IDS.CREATED:
    case STATE_IDS.INITIALIZED:
    case STATE_IDS.PROGRESSED:
    case STATE_IDS.TERMINATING:
    case STATE_IDS.IMPORT_FINISHED:
    case STATE_IDS.EXPORT_FINISHED:
    case STATE_IDS.DEPLOY_FINISHED:
    case STATE_IDS.BUILD_FINISHED:
    case STATE_IDS.BUILD_PENDING:
    case STATE_IDS.BUILD_CREATING:
    case STATE_IDS.BUILD_CREATED:
    case STATE_IDS.BUILD_READY:
    case STATE_IDS.DEPLOY_PENDING:
    case STATE_IDS.DEPLOY_CREATING:
    case STATE_IDS.DEPLOY_CREATED:
    case STATE_IDS.EXPORT_PENDING:
    case STATE_IDS.EXPORT_CREATING:
    case STATE_IDS.EXPORT_CREATED:
    case STATE_IDS.EXPORT_READY:
    case STATE_IDS.IMPORT_PENDING:
    case STATE_IDS.IMPORT_CREATING:
    case STATE_IDS.IMPORT_CREATED:
    case STATE_IDS.IMPORT_READY:
      return [StateFieldClasses.loadingDotProgress, StateFieldClasses.flashing];
    case STATE_IDS.RUN:
    case STATE_IDS.READY:
      return StateFieldClasses.loadingDotSuccess;
    case STATE_IDS.STOPPED:
      return StateFieldClasses.loadingDotStopped;
    case STATE_IDS.DELETED:
      return StateFieldClasses.loadingDotDeleted;
    case STATE_IDS.SERVER_ERROR:
    case STATE_IDS.CLIENT_ERROR:
      return StateFieldClasses.loadingDotFailure;
    default:
      return '';
  }
};

StateField.defaultProps = {
  margin: 'none'
};

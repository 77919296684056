import React from 'react';
import { useWatch } from 'react-hook-form';
import { isNumber } from 'lodash';
import { ResourceInput } from '@rc/admin/components';
import { useProjectContext } from '@rc/admin/context';
import { useProjectResourceLimit, useResourceTypes } from '@rc/admin/hooks';

export const EnvironmentResourceInput = props => {
  const { source, min: minProp, max: maxProp, ...rest } = props;
  const { getResourceType, isLoading: isResourceTypesLoading } =
    useResourceTypes();
  const resourceType = getResourceType(source);

  const { environmentMinValue, environmentMaxValue } =
    resourceType?.resourceTypeLimit || {};

  const { project, isLoading: isProjectLoading } = useProjectContext();
  const [environmentLevelValue, id] = useWatch({ name: [source, 'id'] });

  const min = isNumber(environmentMinValue) ? environmentMinValue : minProp;
  const max = isNumber(environmentMaxValue) ? environmentMaxValue : maxProp;

  const { isLoading: isLimitsLoading, free } = useProjectResourceLimit({
    source,
    id,
    project
  });

  const upperLimit =
    free <= 0
      ? 0
      : Math.min(
          max,
          /**
           * Set the upper limit to free resources available or to a greater value if it has been already saved,
           * even if it will produce an invalid form state.
           */
          Math.max(free, environmentLevelValue)
        );

  return (
    <ResourceInput
      source={source}
      min={min}
      max={max}
      upperLimit={upperLimit}
      isLoading={isProjectLoading || isResourceTypesLoading || isLimitsLoading}
      {...rest}
    />
  );
};

import React from 'react';

import { styled, LinearProgress, Box } from '@mui/material';
import clsx from 'clsx';

const PREFIX = 'LoadingField';

const classes = {
  root: `${PREFIX}-root`
};

const StyledBox = styled(Box)(({ _ }) => ({
  [`&.${classes.root}`]: {
    height: 48,
    paddingTop: 24
  }
}));

export const LoadingField = props => {
  const { className } = props;

  return (
    <StyledBox className={clsx(classes.root, className)}>
      <LinearProgress />
    </StyledBox>
  );
};

import React from 'react';
import { useTranslate } from 'ra-core';
import { styled, Typography } from '@mui/material';

export const IconLabel = props => {
  const { children, icon } = props;
  const t = useTranslate();

  return (
    <Root className={classes.root}>
      {icon}
      <Typography>{t(children)}</Typography>
    </Root>
  );
};

const PREFIX = 'IconLabel';

const classes = {
  root: `${PREFIX}-root`
};

const Root = styled('span')(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    columnGap: theme.spacing(1)
  }
}));

IconLabel.defaultProps = {
  icon: null
};

import React from 'react';
import { Box, styled } from '@mui/material';
import { Stack } from '@mui/system';
import { CredentialInputs } from './CredentialInputs';
import { GitTypeInput } from './GitTypeInput';
import { makeOptionallyNestedSource } from './utils';
import { useWatch } from 'react-hook-form';

const GitInputs = props => {
  const { source, canEditGitType } = props;

  return (
    <StyledStack className={classes.root}>
      {canEditGitType && (
        <Box sx={{ mb: 2 }}>
          <GitTypeInput
            source={makeOptionallyNestedSource(source, 'gitType')}
          />
        </Box>
      )}
      <CredentialInputs source={source} />
    </StyledStack>
  );
};

const PREFIX = 'Git';

const classes = {
  root: `${PREFIX}-root`
};

const StyledStack = styled(Stack)(({ theme }) => ({
  [`&.${classes.root}`]: {
    width: '100%',
    marginBottom: theme.spacing(1)
  }
}));

GitInputs.defaultProps = {
  source: '',
  canEditGitType: true
};

export default GitInputs;

import React, { useMemo, useContext, createContext } from 'react';
import { usePermissions, useSidebarState } from 'react-admin';
import { ROLES } from '@rc/admin/constants';
import { useMediaQuery } from '@mui/material';

/**
 * @type {React.Context<LayoutContext>}
 */
export const LayoutContext = createContext({});

/**
 * @param {Object} props
 * @param {React.ReactNode} props.children
 * @returns {React.ReactElement}
 */
export const LayoutContextProvider = props => {
  const [isSidebarOpen] = useSidebarState();
  const { permissions } = usePermissions();
  const isAdmin = permissions?.includes(ROLES.ADMIN);
  const isLarge = useMediaQuery(theme => theme.breakpoints.up('lg'));

  const memoizedValue = useMemo(() => {
    const isNavHorizontal = isLarge && !isAdmin;
    const isNavMini = isLarge && isAdmin && !isSidebarOpen;

    return {
      navType: isNavHorizontal ? 'horizontal' : isNavMini ? 'mini' : 'vertical'
    };
  }, [isAdmin, isLarge, isSidebarOpen]);

  return <LayoutContext.Provider {...props} value={memoizedValue} />;
};

/**
 *
 * @returns {LayoutContext}
 */
export const useLayoutContext = () => {
  const context = useContext(LayoutContext);

  if (!context)
    throw new Error('useLayoutContext must be use inside LayoutProvider');

  return context;
};

/**
 *
 * @typedef {Object} LayoutContext
 * @property {'horizontal' | 'mini' | 'vertical'} navType
 */

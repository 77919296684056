import React, {
  Children,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import PropTypes from 'prop-types';
import { useRecordContext } from 'react-admin';
import {
  Box,
  IconButton,
  Tooltip,
  styled,
  typographyClasses
} from '@mui/material';
import { Check, ContentCopy } from '@mui/icons-material';
import { useDecodedRecordField } from '@rc/admin/hooks';

/**
 * @param {import('react').PropsWithChildren<{
 * disableClick: boolean
 * }> & import('@mui/material').TooltipProps} props
 * @returns {import('react').ReactElement}
 */
export const WithCopyToClipboard = props => {
  const { children, disableClick, ...rest } = props;
  const record = useRecordContext();
  const { source, noWrap, decodeBase64 } = Children.only(children)?.props || {};
  const value = useDecodedRecordField({ record, source, decodeBase64 });
  const anchorElRef = useRef(null);

  const { isCopiedToClipboard, copyToClipboard, reset } = useCopyToClipboard(
    value
  );

  const Icon = isCopiedToClipboard ? Check : ContentCopy;

  return (
    <StyledTooltip
      title={isCopiedToClipboard ? 'Copied to clipboard' : null}
      PopperProps={{
        ...DEFAULT_POPPER_PROPS,
        anchorEl: anchorElRef.current
      }}
      placement='bottom'
      onMouseLeave={!disableClick ? reset : undefined}
      isCopied={isCopiedToClipboard}
      noWrap={noWrap}
      {...rest}
    >
      <Box
        className={classes.container}
        onClick={!disableClick ? copyToClipboard : undefined}
      >
        {children}
        <Box className={classes.iconContainer}>
          <IconButton
            ref={anchorElRef}
            className={classes.iconButton}
            size='small'
            color='default'
            aria-label='Copy to clipboard'
            onClick={disableClick ? copyToClipboard : undefined}
            onMouseLeave={disableClick ? reset : undefined}
          >
            <Icon fontSize={'inherit'} />
          </IconButton>
        </Box>
      </Box>
    </StyledTooltip>
  );
};

const PREFIX = 'WithCopyToClipboard';

const classes = {
  container: `${PREFIX}-container`,
  iconContainer: `${PREFIX}-iconContainer`,
  iconButton: `${PREFIX}-iconButton`
};

const StyledTooltip = styled(Tooltip, {
  shouldForwardProp: prop => !['isCopied', 'noWrap'].includes(prop)
})(({ theme, isCopied, noWrap }) => ({
  cursor: 'pointer',
  position: 'relative',

  [`& .${classes.iconContainer}`]: {
    position: 'absolute',
    top: '50%',
    right: noWrap ? 0 : -32,
    transform: 'translateY(-50%)'
  },

  [`& .${classes.iconButton}`]: {
    opacity: 0,
    marginLeft: theme.spacing(0.5),
    padding: theme.spacing(0.75),
    fontSize: 16,
    background: noWrap ? `#111111` : undefined
  },

  [`&:hover`]: {
    [`& .${classes.iconButton}`]: {
      opacity: 1,
      background: noWrap ? `#111111` : undefined
    },

    [`& .${typographyClasses.root}`]: {
      opacity: isCopied ? 1 : 0.9
    }
  }
}));

WithCopyToClipboard.propTypes = {
  disableClick: PropTypes.bool
};

WithCopyToClipboard.defaultProps = {
  disableClick: false
};

const DEFAULT_POPPER_PROPS = {
  modifiers: [
    {
      name: 'offset',
      options: {
        offset: [0, -8]
      }
    }
  ]
};

const useCopyToClipboard = value => {
  const [isCopiedToClipboard, setIsCopiedToClipboard] = useState(false);

  const copyToClipboard = useCallback(() => {
    window.navigator.clipboard.writeText(value);
    setIsCopiedToClipboard(true);
  }, [value]);

  const reset = useCallback(() => setIsCopiedToClipboard(false), []);

  useEffect(() => {
    if (isCopiedToClipboard) {
      const id = setTimeout(() => {
        setIsCopiedToClipboard(false);
      }, 2500);
      return () => {
        window.clearTimeout(id);
      };
    }
  }, [isCopiedToClipboard]);

  return {
    isCopiedToClipboard,
    reset,
    copyToClipboard
  };
};

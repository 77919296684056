import React from 'react';
import { Box } from '@mui/material';
import { FileField, FileInput, required, useTranslate } from 'react-admin';

export const DTAwsFile = () => {
  const t = useTranslate();

  return (
    <Box width={'100%'}>
      <FileInput
        source={'dataTransferFile'}
        label={'resources.imported_datas.fields.dataTransferFile'}
        placeholder={`${t('misc.id_est')} database-backup-sql.tar.gz`}
        validate={[required()]}
      >
        <FileField source='src' title='title' fullWidth />
      </FileInput>
    </Box>
  );
};

export default DTAwsFile;

import { defaultTheme as raTheme } from 'react-admin';
import getMinimalTheme from './MinimalTheme';
import componentStyleOverrides from './compStyleOverride';

raTheme.sidebar.width = 300;
raTheme.sidebar.closedWidth = 20;
const appBar = { height: 64 };

const defaultTheme = {
  ...raTheme,
  appBar
};

const minimalThemeConfig = {
  themeColorPresets: 'blue',
  themeContrast: 'bold',
  themeDirection: 'ltr',
  themeLayout: 'horizontal',
  themeStretch: true
};

/* LIGHT THEME */

/** @type {import('@mui/material').Theme} */
const light = Object.assign(
  { ...defaultTheme },
  getMinimalTheme({
    ...minimalThemeConfig,
    themeMode: 'light'
  })
);
light.components = componentStyleOverrides(light);

/* DARK THEME */

/** @type {import('@mui/material').Theme} */
const dark = Object.assign(
  { ...defaultTheme },
  getMinimalTheme({
    ...minimalThemeConfig,
    themeMode: 'dark'
  })
);
dark.components = componentStyleOverrides(dark);

/* EXPORTS */

export const lightTheme = light;
export const darkTheme = dark;

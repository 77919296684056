import React, { useCallback, useMemo } from 'react';
import { useGetList, useChoicesContext, useChoices } from 'ra-core';
import { useTheme } from '@mui/material';
import { getSourceUtil } from '@rc/admin/utils/form';

export const GROUP_DATA_VALUE_PREFIX = '__grouped_choices__group-';

/**
 * @typedef useGroupedChoicesProps
 * @property {string} groupReference Resource name of the parent that makes the group.
 * @property {string} optionRequired Resource name of the parent that makes the group.
 * @property {Array<string>} requiredGroups Array of resource ids that are required as a group.
 * @property {boolean} requiredFirst Sort required groups to the beginning of the list.
 * @property {boolean} allowMultiple Allow multiple choices.
 * @property {string} itemSource Source on the group item.
 *
 * @param {(import('react-admin').SelectInputProps | import('react-admin').SelectArrayInputProps) & useGroupedChoicesProps} props
 * @returns
 */
export const useGroupedChoices = props => {
  const {
    groupReference,
    optionText: optionTextProp,
    optionValue: optionValueProp,
    optionRequired = 'isRequired',
    requiredGroups,
    requiredFirst,
    validate: validateProp = [],
    isLoading: isLoadingProp = false,
    isFetching: isFetchingProp = false,
    allowMultiple,
    itemSource: itemSourceProp,
    ...rest
  } = props;
  const { getChoiceText, getChoiceValue } = useChoices(props);
  const theme = useTheme();

  const {
    source,
    resource,
    allChoices: referenceData,
    selectedChoices,
    isLoading: isReferenceLoading,
    isFetching: isReferenceFetching
  } = useChoicesContext();

  const itemSource = itemSourceProp || getSourceUtil(source).item;

  const {
    data: parentData,
    isLoading: isParentLoading,
    isFetching: isParentFetching
  } = useGetList(groupReference);

  const choices = useMemo(() => {
    if (!parentData || !referenceData || !referenceData.length) {
      return [];
    }

    let groups = parentData.map(parent => ({
      ...parent,
      [GROUP_IDENTIFIER_KEY]: true,
      [CUSTOM_TEXT_KEY]: getChoiceText(parent).toUpperCase(),
      [optionRequired]:
        parent[optionRequired] || requiredGroups?.some(id => id === parent.id),
      [optionValueProp]: createGroupValue(parent, optionValueProp),
      // MUI prop to disable option
      disabled: true
    }));

    if (requiredFirst) {
      groups = groups.sort((a, b) =>
        a[optionRequired] && !b[optionRequired] ? -1 : 1
      );
    }

    return groups.reduce((acc, parent) => {
      const isSelectedChoiceOfGroup = allowMultiple
        ? selectedChoices.find(choice =>
            parent[itemSource].some(id => id === getChoiceValue(choice))
          )
        : false;

      return acc.concat(
        parent,
        parent[itemSource]
          .map(referenceId => {
            const reference = referenceData.find(
              ({ id }) => id === referenceId
            );

            return {
              ...reference,
              [CUSTOM_TEXT_KEY]: `${getChoiceText(parent)} ${getChoiceText(
                reference
              )}`,
              // Disable selecting multiple options from one group
              disabled:
                isSelectedChoiceOfGroup &&
                getChoiceValue(isSelectedChoiceOfGroup) !==
                  getChoiceValue(reference)
            };
          })
          // Sort alphabetically
          .sort((a, b) => (getChoiceText(a) <= getChoiceText(b) ? -1 : 1))
      );
    }, []);
  }, [
    parentData,
    referenceData,
    requiredFirst,
    getChoiceText,
    optionRequired,
    requiredGroups,
    optionValueProp,
    allowMultiple,
    selectedChoices,
    itemSource,
    getChoiceValue
  ]);

  const optionText = choice => {
    const label =
      choice[CUSTOM_TEXT_KEY] ||
      (typeof optionTextProp === 'function'
        ? optionTextProp(choice)
        : optionTextProp);

    if (choice[optionRequired]) {
      return (
        <span style={{ color: theme.palette.error.main }}>
          {label}
          <span>{' *'}</span>
        </span>
      );
    } else {
      return label;
    }
  };

  // Validate required
  const validate = useCallback(
    (value = []) => {
      if (!isParentLoading) {
        // Validate if there are selected values from every required groups
        if (Array.isArray(value)) {
          const addedGroups = value.map(id =>
            parentData?.find(data => data[itemSource].includes(id))
          );

          const missingGroups = choices.filter(
            ({
              [optionValueProp]: optionValue,
              [GROUP_IDENTIFIER_KEY]: isGroup,
              [optionRequired]: isRequired
            }) => {
              const id = extractGroupValue(optionValue);
              return (
                isGroup &&
                isRequired &&
                !addedGroups.some(group => group.id === id)
              );
            }
          );

          if (missingGroups.length) {
            return {
              message: 'validation.missing_required_option',
              args: {
                smart_count: missingGroups.length,
                resource,
                list: missingGroups
                  .map(group => getChoiceText(group))
                  .join(', ')
              }
            };
          }
        }
      }

      return undefined;
    },
    [
      isParentLoading,
      choices,
      parentData,
      itemSource,
      optionValueProp,
      optionRequired,
      resource,
      getChoiceText
    ]
  );

  return {
    source,
    resource,
    choices,
    isLoading: isLoadingProp || isParentLoading || isReferenceLoading,
    isFetching: isFetchingProp || isParentFetching || isReferenceFetching,
    optionText,
    validate: [...validateProp, validate],
    ...rest
  };
};

const createGroupValue = (choice, optionValue) =>
  '__grouped_choices__group-' + choice[optionValue];

const extractGroupValue = value => value.split('-').pop();

const CUSTOM_TEXT_KEY = '__GroupedSelectInput_customLabel';
const GROUP_IDENTIFIER_KEY = '__GroupedSelectInput_group';

/**
 *
 * @param {*} param0
 * @returns
 */
export const sanitizeGroupedInputProps = ({
  groupReference,
  optionRequired,
  requiredGroups,
  requiredFirst,
  allowMultiple,
  itemSource,
  ...rest
}) => rest;

import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { Icon } from '@iconify/react';

import Box from '@mui/material/Box';

// ----------------------------------------------------------------------

/**
 * @typedef {object} IconifyProps
 * @property {string} icon
 * @property {number} [width]
 * @property {import('@mui/material').SxProps} [sx]
 * @property {import('@mui/material').BoxProps} [other]
 * @property {string | 'inherit' | 'action' | 'disabled' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning'} [color]
 * @property {MuiFontSize} [fontSize]
 * @property {React.Ref<any>} [ref]
 *
 * @param {IconifyProps} props
 * @returns
 *
 * @type React.ForwardRefRenderFunction<React.ReactElement, IconifyProps>
 */
const Iconify = forwardRef((props, ref) => {
  const { icon, width: widthProp = 20, sx, color, fontSize, ...other } = props;

  return (
    <Box
      ref={ref}
      component={Icon}
      className='MuiSvgIcon-root MuiIcon-root component-iconify'
      icon={icon}
      sx={theme => {
        const width = resolveFontSize(theme)(fontSize) || widthProp;
        return { width, height: width, ...sx };
      }}
      color={theme => theme.palette[color]?.main || color}
      {...other}
    />
  );
});

/**
 * @typedef {'inherit' | 'large' | 'medium' | 'small'} MuiFontSize
 *
 * @param {MuiFontSize} fontSize
 * @returns
 */
function resolveFontSize (theme) {
  return function (fontSize) {
    if (isEmpty(fontSize)) return;

    return {
      inherit: 'inherit',
      small: theme.typography.pxToRem(20),
      medium: theme.typography.pxToRem(24),
      large: theme.typography.pxToRem(36)
    }[fontSize];
  };
}

Iconify.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  sx: PropTypes.object,
  width: PropTypes.number
};

export default Iconify;

import { PREFETCH_PAGINATION, ID_SORT } from '@rc/admin/constants';
import { useMemo } from 'react';
import { useGetList, useTranslate } from 'react-admin';

export const useProjectsStatusChart = () => {
  const t = useTranslate();
  const { data: projects, isLoading: isLoadingProjects } = useGetList(
    'projects',
    PREFETCH_PAGINATION,
    ID_SORT
  );

  const { data: states, isLoading: isLoadingStates } = useGetList('states', {
    pagination: PREFETCH_PAGINATION,
    sort: ID_SORT
  });

  const chart = useMemo(() => {
    return {
      series: (states || [])
        .map(state => ({
          label: t(`resources.states.names.${state.originId}`),
          value: projects?.filter(project => project.state === state.id).length
        }))
        .filter(serie => serie.value)
    };
  }, [projects, states, t]);

  return {
    chart,
    isLoading: isLoadingProjects || isLoadingStates
  };
};

import React from 'react';
import { SaveButton, useTranslate } from 'react-admin';
import {
  Button,
  styled,
  Toolbar as MuiToolbar,
  Stack,
  Box
} from '@mui/material';
import { useFormStepperContext } from './context';
import { useFormContext } from 'react-hook-form';

const WizardFormToolbar = props => {
  const { disableSave, children, ...rest } = props;
  const { handleSubmit, formState } = useFormContext();
  const t = useTranslate();
  const [
    { steps, activeStep, hasPrevious, hasNext },
    { previous, next }
  ] = useFormStepperContext();

  if (!steps[activeStep] || steps[activeStep].showToolbar === false) {
    return null;
  }

  const hasChildren = React.Children.count(children) > 0;

  return (
    <StyledToolbar {...rest} hasChildren={hasChildren}>
      {hasChildren && <Box>{children}</Box>}
      <Stack direction='row' spacing={2}>
        {hasPrevious && (
          <Button
            type={'button'}
            color={'primary'}
            variant={'outlined'}
            onClick={previous}
          >
            {t('action.previous')}
          </Button>
        )}
        {hasNext ? (
          <Button
            id={'next-button'}
            type={'button'}
            color={'primary'}
            variant={'contained'}
            onClick={e => handleSubmit(next)(e)}
          >
            {t('action.next')}
          </Button>
        ) : (
          <SaveButton
            id={'save-button'}
            label={t('ra.action.save')}
            alwaysEnable={!disableSave && !formState.isSubmitting}
            disabled={disableSave || formState.isSubmitting}
          />
        )}
      </Stack>
    </StyledToolbar>
  );
};

const StyledToolbar = styled(MuiToolbar, {
  shouldForwardProp: prop => prop !== 'hasChildren'
})(({ theme, hasChildren }) => ({
  justifyContent: hasChildren ? 'space-between' : 'flex-end',
  gap: theme.spacing(2),
  backgroundColor:
    theme.palette.mode === 'light'
      ? theme.palette.grey[100]
      : theme.palette.grey[900]
}));

export default WizardFormToolbar;

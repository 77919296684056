import React from 'react';
import {
  Datagrid,
  Edit,
  EditClasses,
  SimpleForm,
  SimpleList,
  TextField,
  TextInput,
  useTranslate
} from 'react-admin';
import { EditButton } from '@rc/admin/ra';
import { styled, Card, useMediaQuery } from '@mui/material';
import {
  AsideReferenceList,
  Breadcrumbs,
  EditTitle
} from '@rc/admin/components';
import { OneColumnFormStyles } from '@rc/admin/theme/styles';
import { VERSION_SORT } from '@rc/admin/constants';

export const ComponentEdit = () => {
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const t = useTranslate();

  return (
    <StyledEdit
      title={<EditTitle />}
      actions={<Breadcrumbs />}
      aside={
        <Card className={classes.asideCard}>
          <AsideReferenceList
            title={t('resources.component_versions.name', { smart_count: 2 })}
            resource={'component_versions'}
            referenceSource={'component'}
            sort={VERSION_SORT}
          >
            {isSmall ? (
              <SimpleList
                primaryText={() => <TextField source={'version'} />}
                style={{ width: '100%' }}
              />
            ) : (
              <Datagrid style={{ width: '100%' }}>
                <TextField source={'version'} />
                <EditButton />
              </Datagrid>
            )}
          </AsideReferenceList>
        </Card>
      }
      redirect={false}
    >
      <SimpleForm className={classes.form}>
        <TextInput source={'name'} fullWidth />
      </SimpleForm>
    </StyledEdit>
  );
};

const PREFIX = 'ComponentEdit';

const classes = {
  asideCard: `${PREFIX}-asideCard`,
  form: `${PREFIX}-form`
};

const StyledEdit = styled(Edit)(({ theme }) => ({
  [`& .${EditClasses.main}`]: {
    display: 'grid',
    gridTemplateColumns: '5fr 3fr',
    columnGap: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr',
      gap: theme.spacing(3),
      '& > *': {
        width: '100%'
      }
    }
  },

  [`& .${classes.asideCard}`]: {
    height: '100%'
  },

  [`& .${classes.form}`]: {
    ...OneColumnFormStyles(theme)
  }
}));

import React from 'react';
import { useTranslate } from 'react-admin';
import { Help as IconHelp } from '@mui/icons-material';
import { Card, CardContent, Stack, Typography, styled } from '@mui/material';

/**
 *
 * @typedef HelpBoxProps
 * @property {string} title
 * @property {React.ReactNode} icon
 * @property {boolean} isTooltip
 *
 * @param {HelpBoxProps & import('@mui/material').CardProps} props
 * @returns
 */
export const HelpCard = props => {
  const { title, icon, children, className, defaultOpen, isTooltip, ...rest } =
    props;
  const t = useTranslate();

  const content =
    typeof children === 'string' ? (
      <Typography dangerouslySetInnerHTML={{ __html: t(children) }} />
    ) : (
      children
    );

  return (
    <StyledCard variant='outlined' className={HelpCardClasses.card} {...rest}>
      <Stack direction='row' className={HelpCardClasses.cardHeader}>
        {icon}
        <Typography>{t(title)}</Typography>
      </Stack>

      <CardContent className={HelpCardClasses.content}>{content}</CardContent>
    </StyledCard>
  );
};

const PREFIX = 'HelpCard';

export const HelpCardClasses = {
  root: `${PREFIX}-root`,
  card: `${PREFIX}-card`,
  cardHeader: `${PREFIX}-cardHeader`,
  content: `${PREFIX}-content`
};

const StyledCard = styled(Card)(({ theme }) => ({
  maxWidth: 480,

  [`& .${HelpCardClasses.cardHeader}`]: {
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: theme.spacing(1),
    padding: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
    fontWeight: theme.typography.fontWeightMedium,
    backgroundColor:
      theme.palette.mode === 'light'
        ? theme.palette.grey[100]
        : theme.palette.grey[900]
  }
}));

HelpCard.defaultProps = {
  icon: <IconHelp />,
  defaultOpen: true
};

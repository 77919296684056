export function sanitizeValues (data, fields) {
  fields.forEach(key => (data[key] = null));
}

export const formatEmbeddedReferenceInput = value => {
  if (value !== null && typeof value === 'object') {
    return value['@id'];
  }

  return value;
};

export const parseEmbeddedReferenceInput = (data = []) => value => {
  if (typeof value === 'string') {
    return data.find(({ id }) => id === value);
  }

  return value;
};

export const getFieldValue = (values, source) => {
  return source.split('.').reduce((acc, curr) => {
    if (!acc) return undefined;

    // Replace key reference if exists
    const mainKey = curr.replace(/\[.*/, '');
    const result = acc[mainKey];
    const matchArrayKey = curr.match(/[0-9]+/);

    if (matchArrayKey) {
      return result[Number(matchArrayKey[0])] || result;
    } else {
      return result;
    }
  }, Object.assign({}, values));
};

export const convertFileToBase64 = file => {
  if (null === file || undefined === file) {
    return null;
  }

  return new Promise((resolve, reject) => {
    const reader = new window.FileReader();
    reader.readAsDataURL(file.rawFile);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });
};

/**
 * Extracts the member, index and source if the original source prop includes a member string.
 *
 * @example
 * // returns { member: "user", index: 0, item: "name"}
 * getSourceUtil("users.0.name")
 * @param {string} source
 * @returns {{item: string, index: number, member: string}}
 */
export const getSourceUtil = source => {
  const parts = source.split('.');

  return {
    item: parts.pop(),
    index: Number(parts.pop()),
    member: parts.pop()
  };
};

/**
 * Creates ArrayInput compatible source string.
 *
 * @example
 * // returns "users.2.name"
 * createSourceUtil("users", 2, "name")
 * @param {string} member
 * @param {number|string} index
 * @param {string} item
 * @returns
 */
export const createSourceUtil = (member, index = null, item = null) => {
  let result = member;
  if (index !== null) {
    result += `.${index}`;
  }

  if (item !== null) {
    result += `.${item}`;
  }

  return result;
};

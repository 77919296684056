import React from 'react';
import {
  ChoicesContextProvider,
  SelectInput,
  useChoicesContext,
  useGetOne
} from 'react-admin';
import { useWatch } from 'react-hook-form';

export const ProjectTypeVersionSelectInput = props => {
  const { allChoices: projectTypeVersions, ...choicesContext } =
    useChoicesContext();

  const projectTypeVersion = useWatch({ name: 'projectTypeVersion' });

  const projectTypeId = projectTypeVersions.find(
    ({ id }) => id === projectTypeVersion
  )?.projectType;

  const newChoices = projectTypeVersions.filter(
    version => version.projectType === projectTypeId
  );

  const { data: projectType } = useGetOne(
    'project_types',
    { id: projectTypeId },
    { enabled: !!projectTypeId }
  );
  const label = projectType ? `${projectType.name} Version` : '';

  return (
    <ChoicesContextProvider
      value={{
        allChoices: newChoices,
        availableChoices: newChoices,
        ...choicesContext
      }}
    >
      <SelectInput
        {...props}
        label={label}
        optionText={choice => choice?.version}
        sx={{ margin: 0 }}
      />
    </ChoicesContextProvider>
  );
};

import React from 'react';
import { CloudDownload, Sync, SyncProblem } from '@mui/icons-material';
import { Chip, LinearProgress } from '@mui/material';
import { useRecordContext, useTranslate } from 'react-admin';
import { LoadingButton } from '@mui/lab';
import _ from 'lodash';

/**
 *
 * @param {import('@mui/material').ChipProps} props
 * @param {import('@rc/admin/types/progressbar.types').ProgressDetails} props.progress
 * @returns
 */
export const DownloadButton = props => {
  const { progress } = props;
  const record = useRecordContext();
  const t = useTranslate();

  if (!record) {
    return <LinearProgress {...props} />;
  } else if (_.isString(record.url) && 'error' === record.url) {
    return (
      <Chip
        label='Error'
        size='small'
        color={'error'}
        icon={<SyncProblem />}
        {...props}
      />
    );
  } else if (_.isUndefined(record.url) || _.isNil(record.url)) {
    const currentState = progress?.lastProcessInfo.state;

    return (
      <Chip
        label={
          currentState
            ? t(`resources.states.names.${currentState}`)
            : t('misc.loading', { dots: '...' })
        }
        size='small'
        color={'info'}
        icon={
          <Sync
            sx={{
              animation: 'spinForever 1500ms linear infinite',
              '@keyframes spinForever': {
                '0%': {
                  transform: 'rotate(360deg)'
                },
                '100%': {
                  transform: 'rotate(0deg)'
                }
              }
            }}
          />
        }
        {...props}
      />
    );
  }

  return (
    <LoadingButton
      variant={'contained'}
      startIcon={<CloudDownload />}
      size={'small'}
      href={record.url}
      loading={!record.url}
      target={'_blank'}
      {...props}
    >
      {t('action.download')}
    </LoadingButton>
  );
};

export default DownloadButton;

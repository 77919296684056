import React from 'react';
import { icon, library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fab } from '@fortawesome/free-brands-svg-icons';

library.add(fab);

export const CardBrand = props => {
  return (
    <FontAwesomeIcon
      scale={5}
      icon={icon({ prefix: 'fab', iconName: `cc-${props.cardBrand}` })}
    />
  );
};

export default CardBrand;

import React, { useCallback, useEffect, useState } from 'react';
import axios from '@rc/utils/axios';
import { CircularProgress, Stack } from '@mui/material';
import { SubscriptionContext } from './SubscriptionContext';
import { SubscriptionForm } from './SubscriptionForm';

export const Subscription = props => {
  const { goTo } = props;
  const [clientSecret, setClientSecret] = useState(null);
  const fetchSubInfo = async userId =>
    await axios.get(`/api/users/${userId}/finance/subscribe-info`);

  useEffect(() => {
    const { id: userId } = JSON.parse(window.localStorage.getItem('user'));
    fetchSubInfo(userId).then(r => setClientSecret(r.data.clientSecret));
  }, []);

  const onSuccess = useCallback(() => {
    goTo(1);
  }, [goTo]);

  if (!clientSecret) {
    return (
      <Stack
        sx={{ alignItems: 'center', justifyContent: 'center', minHeight: 200 }}
      >
        <CircularProgress />
      </Stack>
    );
  }

  return (
    <SubscriptionContext clientSecret={clientSecret}>
      <SubscriptionForm onSuccess={onSuccess} />
    </SubscriptionContext>
  );
};

export default Subscription;

import React, { Children } from 'react';
import PropTypes from 'prop-types';
import { Box, Step, StepLabel, Stepper } from '@mui/material';
import { useFormStepperContext } from './context';

export const FormStepper = props => {
  const { children } = props;
  const [{ activeStep }] = useFormStepperContext();

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {Children.map(children, child => (
          <Step key={child.props.label}>
            <StepLabel>{child.props.label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {Children.toArray(children).slice(activeStep, activeStep + 1)}
    </Box>
  );
};

/**
 *
 * @param {object} props
 * @param {string} props.label
 * @returns
 */
export const FormStep = props => {
  const { children } = props;

  return <Box sx={{ width: '100%' }}>{children}</Box>;
};

FormStep.propTypes = {
  label: PropTypes.string.isRequired
};

import React from 'react';
import { Typography, Box } from '@mui/material';
import { PasswordInput, useTranslate } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { PasswordStrengthMeter } from '@rc/admin/components';

const PasswordHandle = () => {
  const t = useTranslate();
  const { watch } = useFormContext();

  return (
    <Box>
      <Typography variant={'h5'} sx={theme => ({ mb: theme.spacing(2) })}>
        {t('pages.profile.edit.change_password.title')}
      </Typography>
      <PasswordInput
        autoComplete='on'
        source={'currentPassword'}
        label={t('pages.profile.edit.change_password.fields.currentPassword')}
        fullWidth
      />
      <PasswordInput
        autoComplete='off'
        source={'changePassword'}
        label={t('pages.profile.edit.change_password.fields.changePassword')}
        fullWidth
      />
      <PasswordInput
        autoComplete='off'
        source={'changePasswordAgain'}
        label={t(
          'pages.profile.edit.change_password.fields.changePasswordAgain'
        )}
        fullWidth
      />
      <PasswordStrengthMeter password={watch('changePassword') ?? ''} />
    </Box>
  );
};

export default PasswordHandle;

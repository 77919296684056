import React from 'react';
import { EnvVarTypeList } from './List';
import { EnvVarTypeEdit } from './Edit';
import { EnvVarTypeCreate } from './Create';
import Iconify from '@rc/admin/components/ui/Iconify';

export default {
  name: 'env_var_types',
  list: EnvVarTypeList,
  edit: EnvVarTypeEdit,
  create: EnvVarTypeCreate,
  icon: () => <Iconify icon='solar:text-field-bold-duotone' />
};

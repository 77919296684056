import React from 'react';
import { TeamList } from './List';
import { TeamShow } from './Show';
import { TeamCreate } from './Create';
import { TeamEdit } from './Edit/Edit';
import Iconify from '@rc/admin/components/ui/Iconify';

export default {
  name: 'teams',
  list: TeamList,
  create: TeamCreate,
  edit: TeamEdit,
  show: TeamShow,
  icon: () => <Iconify icon='solar:users-group-two-rounded-bold-duotone' />
};

import { useAxios } from '@rc/utils/axios';
import { useCallback } from 'react';
import { useNotify, useTranslate } from 'react-admin';

export const useUsernameExistanceValidation = () => {
  const t = useTranslate();
  const notify = useNotify();

  const [, checkUsername] = useAxios('/api/users/check-username', {
    manual: true
  });

  const checkUsernameExistence = useCallback(
    async username => {
      let result = false;

      await checkUsername({
        params: { username }
      })
        .then(r => (result = r.data))
        .catch(e =>
          notify(t('pages.register.notification.error'), {
            type: 'error',
            undoable: false
          })
        );

      return !result.isNotExists
        ? 'validation.username_already_registered'
        : undefined;
    },
    [checkUsername, notify, t]
  );

  return checkUsernameExistence;
};

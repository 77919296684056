import React from 'react';
import { useGetOne, useResourceContext } from 'react-admin';
import { useWatch } from 'react-hook-form';

/**
 * @type {{project: import('react-admin').RaRecord, isLoading: boolean, isError: boolean}}
 */
const ProjectContext = React.createContext();

export const ProjectContextProvider = props => {
  const resource = useResourceContext();
  const projectId = useWatch({
    name: resource === 'environments' ? 'project' : 'templateProject'
  });

  const { data: project, isLoading, isError } = useGetOne(
    resource === 'environments' ? 'projects' : 'template_projects',
    { id: projectId },
    {
      enabled: !!projectId
    }
  );

  return (
    <ProjectContext.Provider
      value={{ project, isLoading, isError }}
      {...props}
    />
  );
};

export const useProjectContext = () => React.useContext(ProjectContext);

import { WithCopyToClipboard } from '@rc/admin/components';
import React from 'react';
import {
  Datagrid,
  DateField,
  DeleteButton,
  List,
  TextField
} from 'react-admin';

export const AccessCodeList = () => {
  return (
    <List
      sort={{
        field: 'createdAt',
        order: 'DESC'
      }}
      perPage={25}
      exporter={false}
    >
      <Datagrid width={'100vw'}>
        <WithCopyToClipboard sx={{ width: 'min-content' }}>
          <TextField source='accessCode' />
        </WithCopyToClipboard>
        <DateField source='usedAt' />
        <DateField source='createdAt' />
        <DeleteButton variant='outlined' sx={{ float: 'right' }} />
      </Datagrid>
    </List>
  );
};

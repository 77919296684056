import { Skeleton, Typography } from '@mui/material';
import React from 'react';
import { useLocaleState, useRecordContext } from 'react-admin';
import ReactTimeAgo from 'react-time-ago';
import { Iconify } from '../ui';

/**
 * @typedef {object} TimeAgoFieldProps
 * @property {string} className
 * @property {string} source
 * @property {import('react-admin').Record} record
 * @property {boolean} isPlaceholder
 * @property {string} label
 *
 * @param {TimeAgoFieldProps & import('@mui/material').TypographyProps} props
 */
export const TimeAgoField = props => {
  const { isPlaceholder, className, source, sx, ...rest } = props;
  const [locale] = useLocaleState();
  const record = useRecordContext(props);

  const dateString = record?.[source];

  return isPlaceholder || !record ? (
    <Skeleton className={className} variant='text' width={60} />
  ) : dateString ? (
    <Typography
      sx={theme => ({
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        columnGap: theme.spacing(0.5),
        ...(typeof sx === 'function' ? sx(theme) : sx)
      })}
      {...rest}
    >
      <Iconify
        width={16}
        icon='solar:clock-circle-bold'
        sx={{ flexShrink: 0 }}
      />
      <ReactTimeAgo
        className={className}
        date={new Date(dateString)}
        locale={locale}
      />
    </Typography>
  ) : null;
};

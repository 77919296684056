import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton, Snackbar as MuiSnackbar } from '@mui/material';
import { Close } from '@mui/icons-material';

const Snackbar = props => {
  const { message, type, ...rest } = props;

  const [isOpen, setIsOpen] = useState(!!message);

  useEffect(() => {
    setIsOpen(!!message);
  }, [message]);

  return (
    <MuiSnackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isOpen}
      onClose={() => setIsOpen(setIsOpen(!isOpen))}
      message={message}
      severity={type}
      autoHideDuration={5000}
      action={
        <IconButton
          aria-label='close'
          color='inherit'
          sx={{ p: 0.5 }}
          onClick={() => setIsOpen(!isOpen)}
        >
          <Close />
        </IconButton>
      }
      unselectable={'off'}
      {...rest}
    />
  );
};

Snackbar.propTypes = {
  message: PropTypes.node,
  type: PropTypes.string
};

export default Snackbar;

import React from 'react';
import {
  Create,
  ReferenceInput,
  required,
  SimpleForm,
  TextInput,
  useRedirect,
  useRefresh,
  useResourceContext,
  useTranslate,
  useUnique
} from 'react-admin';
import { Grid, styled } from '@mui/material';
import { TwoColumnFormStyles } from '@rc/admin/theme/styles';
import { Breadcrumbs, FullPageCircularProgress } from '@rc/admin/components';
import { useOrgContext } from '@rc/admin/context';
import { OrganisationInput } from './components';
import { useWatch } from 'react-hook-form';

export const TeamCreate = () => {
  const refresh = useRefresh();
  const redirect = useRedirect();
  const t = useTranslate();
  const resource = useResourceContext();
  const { organisationId: defaultOrganisationId } = useOrgContext();

  if (!defaultOrganisationId) {
    return <FullPageCircularProgress />;
  }

  return (
    <StyledCreate
      actions={<Breadcrumbs showCurrent={false} disableLast={false} />}
      mutationOptions={{
        onSuccess: data => {
          // Refresh the switchTeam component
          refresh();
          redirect('edit', resource, data.id, data);
        }
      }}
    >
      <SimpleForm
        className={classes.form}
        defaultValues={{
          organisation: defaultOrganisationId
        }}
        mode='all'
      >
        <Grid container>
          <Grid item xs={12}>
            <ReferenceInput source='organisation' reference='organisations'>
              <OrganisationInput
                label={t('resources.organisations.name', { smart_count: 1 })}
                fullWidth
                isRequired
                validate={[required()]}
              />
            </ReferenceInput>
          </Grid>
          <Grid item xs={12}>
            <NameInput fullWidth />
          </Grid>
        </Grid>
      </SimpleForm>
    </StyledCreate>
  );
};

/**
 *
 * @param {import('react-admin').TextInputProps} props
 */
const NameInput = props => {
  const unique = useUnique();
  const organisationId = useWatch({ name: 'organisation' });

  return (
    <TextInput
      {...props}
      source={'name'}
      validate={[
        unique({
          filter: {
            organisation: organisationId
          }
        }),
        required()
      ]}
      fullWidth
    />
  );
};

const PREFIX = 'TeamCreate';

const classes = {
  form: `${PREFIX}-form`
};

const StyledCreate = styled(Create)(({ theme }) => ({
  [`& .${classes.form}`]: {
    ...TwoColumnFormStyles(theme)
  }
}));

import React from 'react';
import { Typography } from '@mui/material';
import { useTranslate } from 'react-admin';

/**
 * @typedef {object} SectionTitleProps
 * @property {1 | 2} level - The level of the title (defaults to 1)
 * @property {string} title - The title to translate
 *
 * @param {import('@mui/material').TypographyProps & SectionTitleProps} props
 * @returns
 */
export const SectionTitle = props => {
  const { level, children, variant, title, ...rest } = props;
  const t = useTranslate();
  return (
    <Typography
      component={'h6'}
      variant={level === 1 ? 'subtitle1' : 'subtitle2'}
      fontWeight={theme => theme.typography.fontWeightBold}
      gutterBottom
      mb={1}
      {...rest}
    >
      {title ? t(title) : children}
    </Typography>
  );
};

SectionTitle.defaultProps = {
  level: 1
};

import {COPY_ENVIRONMENT_SOURCE} from '@rc/admin/resources/environment/constants';
import {cloneDeep} from 'lodash';
import {useCallback, useState} from 'react';
import {useDataProvider} from 'react-admin';
import {useFormContext} from 'react-hook-form';

/**
 *
 * Copies the resources from the selected environment to the current environment.
 *
 * @param {React.MutableRefObject<string>} lastCopiedFromRef
 * @returns
 */
export const useCopyEnvironmentComponent = lastCopiedFromRef => {
  const {getValues, setValue} = useFormContext();
  const {getOne} = useDataProvider();
  const [isCopying, setIsCopying] = useState(false);
  const copyResourcesFromEnvironment = useCallback(async () => {
    const {
      [COPY_ENVIRONMENT_SOURCE]: {
        sourceId: copySourceId,
        types: copyTypes
      } = {}
    } = getValues();
    if (copyTypes?.some(type => type === 'resource')) {
      if (lastCopiedFromRef.current === copySourceId) {
        return;
      }
      setIsCopying(true);
      const {data: environmentToCopyFrom} = await getOne('environments', {
        id: copySourceId
      });
      const {environmentComponent} = environmentToCopyFrom;
      const newEnvironmentComponent = cloneDeep(environmentComponent);
      newEnvironmentComponent.forEach(component => {
        delete component['@id'];
        delete component['@type'];
      });
      setValue('environmentComponent', newEnvironmentComponent);
      lastCopiedFromRef.current = copySourceId;
      setIsCopying(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getValues, setValue]);
  return {
    isCopying,
    copyResourcesFromEnvironment
  };
};

// ----------------------------------------------------------------------

export function treeView (theme) {
  return {
    MuiTreeItem: {
      styleOverrides: {
        label: {
          ...theme.typography.body2
        },
        iconContainer: {
          width: 'auto'
        }
      }
    }
  };
}

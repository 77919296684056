import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Box, Card, Stack, styled } from '@mui/material';
import { GRECAPTCHA_SITEKEY } from '@rc/admin/constants';
import { ToggleThemeButton } from '@rc/admin/layout';
import RegisterForm from './Form';
import { getColorfulBackgroundStyles } from '@rc/admin/theme/styles';
import { bgBlur } from '@minimal-theme/theme/css';
import Logo from '@rc/admin/theme/MinimalTheme/components/logo';
import { useSetDefaultTeam } from '@rc/admin/hooks';

import '@stylesheet/register.scss';

const Register = props => {
  useSetDefaultTeam();

  return (
    <Root {...props}>
      <Box
        position={'absolute'}
        sx={theme => ({ top: theme.spacing(2), right: theme.spacing(2) })}
      >
        <ToggleThemeButton />
      </Box>
      <GoogleReCaptchaProvider
        reCaptchaKey={GRECAPTCHA_SITEKEY}
        scriptProps={{
          async: true,
          defer: true
        }}
      >
        <Card className={RegisterClasses.card}>
          <Stack alignItems='center' width={'100%'}>
            <Logo sx={{ width: 64 }} />
          </Stack>
          <RegisterForm />
        </Card>
      </GoogleReCaptchaProvider>
    </Root>
  );
};

const PREFIX = 'Register';

export const RegisterClasses = {
  card: `${PREFIX}-card`
};

const Root = styled('div', {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
  alignItems: 'center',
  justifyContent: 'flex-start',
  paddingTop: '4.5em',
  paddingBottom: '3em',
  ...getColorfulBackgroundStyles(theme),

  [`& .${RegisterClasses.card}`]: {
    padding: theme.spacing(1),
    maxWidth: 380,
    width: `calc(100% - ${theme.spacing(4)})`,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    ...bgBlur({
      color: theme.palette.background.paper,
      opacity: theme.palette.mode === 'light' ? 0.25 : 0.25
    })
  }
}));

export default Register;

import React from 'react';
import { Datagrid, List, SimpleList, TextField } from 'react-admin';
import { EditButton } from '@rc/admin/ra';
import { useMediaQuery } from '@mui/material';

export const ComponentList = () => {
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

  return (
    <List perPage={25} exporter={false}>
      {isSmall ? (
        <SimpleList primaryText={() => <TextField source='name' />} />
      ) : (
        <Datagrid width={'100vw'}>
          <TextField source='name' />t
          <EditButton />
        </Datagrid>
      )}
    </List>
  );
};

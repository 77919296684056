import * as React from 'react';
import {
  isValidElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import clsx from 'clsx';
import {
  Autocomplete,
  Chip,
  createFilterOptions,
  TextField
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  FieldTitle,
  useChoicesContext,
  useInput,
  useSuggestions,
  useTimeout,
  useTranslate,
  warning,
  useGetRecordRepresentation
} from 'ra-core';
import {
  useSupportCreateSuggestion,
  InputHelperText,
  sanitizeInputRestProps
} from 'react-admin';

const defaultFilterOptions = createFilterOptions();

/**
 * @typedef {import('react-admin').RaRecord} OptionType
 * @typedef {boolean | undefined} Multiple
 * @typedef {boolean | undefined} DisableClearable
 * @typedef {boolean | undefined} SupportCreate
 *
 * @param {import('react-admin').AutocompleteInputProps<OptionType, Multiple, DisableClearable, SupportCreate>} props
 * @returns
 */
export const CreatableAutocompleteInput = props => {
  const {
    choices: choicesProp,
    className,
    clearText = 'ra.action.clear_input_value',
    closeText = 'ra.action.close',
    create,
    createLabel,
    createItemLabel,
    createValue,
    debounce: debounceDelay = 250,
    defaultValue = '',
    emptyText,
    field: fieldOverride,
    format,
    helperText,
    id: idOverride,
    inputText,
    isFetching: isFetchingProp,
    isLoading: isLoadingProp,
    isRequired: isRequiredOverride,
    label,
    limitChoicesToValue,
    matchSuggestion,
    margin,
    fieldState: fieldStateOverride,
    filterToQuery = DefaultFilterToQuery,
    formState: formStateOverride,
    multiple = false,
    noOptionsText,
    onBlur,
    onChange,
    onCreate,
    openText = 'ra.action.open',
    optionText = 'name',
    optionValue = 'id',
    parse,
    resource: resourceProp,
    shouldRenderSuggestions,
    setFilter,
    size,
    source: sourceProp,
    suggestionLimit = Infinity,
    TextFieldProps,
    translateChoice,
    validate,
    variant,
    /**
     * CUSTOM: Extract freeSolo value
     */
    freeSolo,
    ...rest
  } = props;

  const {
    allChoices,
    isLoading,
    resource,
    source,
    setFilters,
    isFromReference
  } = useChoicesContext({
    choices: choicesProp,
    isFetching: isFetchingProp,
    isLoading: isLoadingProp,
    resource: resourceProp,
    source: sourceProp
  });

  const translate = useTranslate();
  const {
    id,
    field,
    isRequired,
    fieldState: { error, invalid, isTouched },
    formState: { isSubmitted }
  } = useInput({
    defaultValue,
    format,
    id: idOverride,
    field: fieldOverride,
    fieldState: fieldStateOverride,
    formState: formStateOverride,
    onBlur,
    onChange,
    parse,
    resource,
    source,
    validate,
    ...rest
  });

  /**
   * CUSTOM: Hold a reference for the input element's value to be able to access it no matter if a choice exists in downshift
   */
  const newInputValueRef = useRef(field.value);

  const selectedChoice = useSelectedChoice(field.value, {
    choices: allChoices,
    multiple,
    optionValue
  });

  useEffect(() => {
    // eslint-disable-next-line eqeqeq
    if (isValidElement(optionText) && inputText == undefined) {
      throw new Error(`
If you provided a React element for the optionText prop, you must also provide the inputText prop (used for the text input)`);
    }
    // eslint-disable-next-line eqeqeq
    if (isValidElement(optionText) && matchSuggestion == undefined) {
      throw new Error(`
If you provided a React element for the optionText prop, you must also provide the matchSuggestion prop (used to match the user input with a choice)`);
    }
  }, [optionText, inputText, matchSuggestion]);

  useEffect(() => {
    warning(
      /* eslint-disable eqeqeq */
      shouldRenderSuggestions != undefined && noOptionsText == undefined,
      `When providing a shouldRenderSuggestions function, we recommend you also provide the noOptionsText prop and set it to a text explaining users why no options are displayed. It supports translation keys.`
    );
    /* eslint-enable eqeqeq */
  }, [shouldRenderSuggestions, noOptionsText]);

  const getRecordRepresentation = useGetRecordRepresentation(resource);

  const { getChoiceText, getChoiceValue, getSuggestions } = useSuggestions({
    choices: allChoices,
    emptyText,
    limitChoicesToValue,
    matchSuggestion,
    optionText:
      optionText ?? (isFromReference ? getRecordRepresentation : undefined),
    optionValue,
    selectedItem: selectedChoice,
    suggestionLimit,
    translateChoice
  });

  const [filterValue, setFilterValue] = useState('');

  const handleChange = newValue => {
    if (multiple) {
      if (Array.isArray(newValue)) {
        field.onChange(newValue.map(getChoiceValue));
      } else {
        field.onChange([...(field.value ?? []), getChoiceValue(newValue)]);
      }
    } else {
      field.onChange(getChoiceValue(newValue) || '');
    }
  };

  // eslint-disable-next-line
  const debouncedSetFilter = useCallback(
    debounce(filter => {
      if (setFilter) {
        return setFilter(filter);
      }

      if (choicesProp) {
        return;
      }

      setFilters(filterToQuery(filter), undefined, true);
    }, debounceDelay),
    [debounceDelay, setFilters, setFilter]
  );

  // We must reset the filter every time the value changes to ensure we
  // display at least some choices even if the input has a value.
  // Otherwise, it would only display the currently selected one and the user
  // would have to first clear the input before seeing any other choices
  /**
   * CUSTOM: Not needed.
   */
  // const currentValue = useRef(field.value);
  // useEffect(() => {
  //   if (!isEqual(currentValue.current, field.value)) {
  //     currentValue.current = field.value;
  //     debouncedSetFilter('');
  //   }
  // }, [field.value]); // eslint-disable-line

  const {
    getCreateItem,
    handleChange: handleChangeWithCreateSupport,
    createElement,
    createId
  } = useSupportCreateSuggestion({
    create,
    createLabel,
    createItemLabel,
    createValue,
    handleChange,
    filter: filterValue,
    onCreate,
    optionText
  });

  const getOptionLabel = useCallback(
    (option, isListItem = false) => {
      // eslint-disable-next-line eqeqeq
      if (option == undefined) {
        return '';
      }

      // Value selected with enter, right from the input
      if (typeof option === 'string') {
        return option;
      }

      if (option?.id === createId) {
        return option?.name;
      }

      if (!isListItem && inputText !== undefined) {
        return inputText(option);
      }

      return getChoiceText(option);
    },
    [getChoiceText, inputText, createId]
  );

  useEffect(() => {
    if (!multiple) {
      const optionLabel = getOptionLabel(selectedChoice);
      if (typeof optionLabel === 'string') {
        setFilterValue(optionLabel);
      } else {
        throw new Error(
          'When optionText returns a React element, you must also provide the inputText prop'
        );
      }
    }
  }, [getOptionLabel, multiple, selectedChoice]);

  const handleInputChange = (event, newInputValue, reason) => {
    if (!doesQueryMatchSelection(newInputValue, event?.type)) {
      setFilterValue(newInputValue);
      debouncedSetFilter(newInputValue);

      /**
       * CUSTOM: Set input value as component value immediately
       */
      newInputValueRef.current = newInputValue;
      field.onChange(newInputValueRef.current);

      /**
       * CUSTOM: When field value has been reset after blurred, reset the previous value
       */
    } else if (freeSolo && reason === 'reset' && newInputValue === '') {
      field.onChange(newInputValueRef.current);
    }
  };

  const doesQueryMatchSelection = useCallback(
    (filter, eventType) => {
      let selectedItemTexts = [];

      if (multiple) {
        selectedItemTexts = selectedChoice.map(item => getOptionLabel(item));
      } else {
        selectedItemTexts = [getOptionLabel(selectedChoice)];
      }

      return eventType && eventType === 'change'
        ? selectedItemTexts.includes(filter) && selectedChoice
        : selectedItemTexts.includes(filter);
    },
    [getOptionLabel, multiple, selectedChoice]
  );
  const doesQueryMatchSuggestion = useCallback(
    filter => {
      // eslint-disable-next-line no-extra-boolean-cast
      const hasOption = !!allChoices
        ? allChoices.some(choice => getOptionLabel(choice) === filter)
        : false;

      return doesQueryMatchSelection(filter) || hasOption;
    },
    [allChoices, getOptionLabel, doesQueryMatchSelection]
  );

  const filterOptions = (options, params) => {
    let filteredOptions =
      isFromReference || // When used inside a reference, AutocompleteInput shouldn't do the filtering as it's done by the reference input
      matchSuggestion || // When using element as optionText (and matchSuggestion), options are filtered by getSuggestions, so they shouldn't be filtered here
      limitChoicesToValue // When limiting choices to values (why? it's legacy!), options are also filtered by getSuggestions, so they shouldn't be filtered here
        ? options
        : defaultFilterOptions(options, params); // Otherwise we let MUI's Autocomplete do the filtering

    // add create option if necessary
    const { inputValue } = params;
    if (
      (onCreate || create) &&
      inputValue !== '' &&
      !doesQueryMatchSuggestion(filterValue)
    ) {
      filteredOptions = filteredOptions.concat(getCreateItem(inputValue));
    }

    return filteredOptions;
  };

  const handleAutocompleteChange = (event, newValue, reason) => {
    handleChangeWithCreateSupport(newValue != null ? newValue : '');
  };

  const oneSecondHasPassed = useTimeout(1000, filterValue);

  // To avoid displaying an empty list of choices while a search is in progress,
  // we store the last choices in a ref. We'll display those last choices until
  // a second has passed.
  const currentChoices = useRef(allChoices);

  useEffect(() => {
    if (allChoices && (allChoices.length > 0 || oneSecondHasPassed)) {
      currentChoices.current = allChoices;
    }
  }, [allChoices, oneSecondHasPassed]);

  const suggestions = useMemo(() => {
    if (matchSuggestion || limitChoicesToValue) {
      return getSuggestions(filterValue);
    }
    return allChoices?.slice(0, suggestionLimit) || [];
  }, [
    allChoices,
    filterValue,
    getSuggestions,
    limitChoicesToValue,
    matchSuggestion,
    suggestionLimit
  ]);

  const isOptionEqualToValue = (option, value) => {
    // eslint-disable-next-line eqeqeq
    return getChoiceValue(option) == getChoiceValue(value);
  };

  return (
    <>
      <StyledAutocomplete
        blurOnSelect
        className={clsx('ra-input', `ra-input-${source}`, className)}
        clearText={translate(clearText, { _: clearText })}
        closeText={translate(closeText, { _: closeText })}
        openOnFocus
        openText={translate(openText, { _: openText })}
        id={id}
        isOptionEqualToValue={isOptionEqualToValue}
        filterSelectedOptions
        renderInput={params => (
          <TextField
            name={field.name}
            label={
              <FieldTitle
                label={label}
                source={source}
                resource={resourceProp}
                isRequired={
                  typeof isRequiredOverride !== 'undefined'
                    ? isRequiredOverride
                    : isRequired
                }
              />
            }
            error={(isTouched || isSubmitted) && invalid}
            helperText={
              <InputHelperText
                touched={isTouched || isSubmitted}
                error={error?.message}
                helperText={helperText}
              />
            }
            margin={margin}
            variant={variant}
            className={AutocompleteInputClasses.textField}
            {...TextFieldProps}
            {...params}
            size={size}
            /**
             * CUSTOM: Preserve custom input value entered, otherwise it is being reset.
             */
            inputProps={
              freeSolo
                ? {
                    ...(TextFieldProps?.inputProps || {}),
                    ...(params?.inputProps || {}),
                    value: newInputValueRef.current
                  }
                : {
                    ...(TextFieldProps?.inputProps || {}),
                    ...(params?.inputProps || {})
                  }
            }
          />
        )}
        multiple={multiple}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            // eslint-disable-next-line react/jsx-key
            <Chip
              label={
                isValidElement(optionText)
                  ? inputText(option)
                  : getChoiceText(option)
              }
              sx={{
                '.MuiSvgIcon-root': {
                  // FIXME: Workaround to allow choices deletion
                  // Maybe related to storybook and mui using different versions of emotion
                  zIndex: 100
                }
              }}
              size='small'
              {...getTagProps({ index })}
            />
          ))
        }
        noOptionsText={
          typeof noOptionsText === 'string'
            ? translate(noOptionsText, { _: noOptionsText })
            : noOptionsText
        }
        selectOnFocus
        clearOnBlur
        {...sanitizeInputRestProps(rest)}
        /**
         * Set freeSolo from props
         */
        freeSolo={freeSolo || !!create || !!onCreate}
        handleHomeEndKeys={!!create || !!onCreate}
        filterOptions={filterOptions}
        options={
          shouldRenderSuggestions == undefined || // eslint-disable-line eqeqeq
          shouldRenderSuggestions(filterValue)
            ? suggestions
            : []
        }
        getOptionLabel={getOptionLabel}
        inputValue={filterValue}
        loading={
          isLoading &&
          (!allChoices || allChoices.length === 0) &&
          oneSecondHasPassed
        }
        value={selectedChoice}
        onChange={handleAutocompleteChange}
        onBlur={field.onBlur}
        onInputChange={handleInputChange}
        renderOption={(props, record) => {
          props.key = getChoiceValue(record);
          return <li {...props}>{getOptionLabel(record, true)}</li>;
        }}
      />
      {createElement}
    </>
  );
};

const PREFIX = 'RcAutocompleteInput';

export const AutocompleteInputClasses = {
  textField: `${PREFIX}-textField`
};

const StyledAutocomplete = styled(Autocomplete, {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root
})(({ theme }) => ({}));

/**
 * Returns the selected choice (or choices if multiple) by matching the input value with the choices.
 */
const useSelectedChoice = (value, { choices, multiple, optionValue }) => {
  const selectedChoiceRef = useRef(
    getSelectedItems(choices, value, optionValue, multiple)
  );
  const [selectedChoice, setSelectedChoice] = useState(() =>
    getSelectedItems(choices, value, optionValue, multiple)
  );

  // As the selected choices are objects, we want to ensure we pass the same
  // reference to the Autocomplete as it would reset its filter value otherwise.
  useEffect(() => {
    const newSelectedItems = getSelectedItems(
      choices,
      value,
      optionValue,
      multiple
    );

    if (!isEqual(selectedChoiceRef.current, newSelectedItems)) {
      selectedChoiceRef.current = newSelectedItems;
      setSelectedChoice(newSelectedItems);
    }
  }, [choices, value, multiple, optionValue]);
  return selectedChoice || null;
};

const getSelectedItems = (
  choices = [],
  value,
  optionValue = 'id',
  multiple
) => {
  if (multiple) {
    return (value || [])
      .map(item => choices.find(choice => item === get(choice, optionValue)))
      .filter(item => !!item);
  }
  return choices.find(choice => get(choice, optionValue) === value) || '';
};

const DefaultFilterToQuery = searchText => ({ q: searchText });

CreatableAutocompleteInput.defaultProps = {
  freeSolo: true
};

import { ROLES } from './constants';

export { ROLES } from './constants';

export const PERMISSIONS = {
  [ROLES.USER]: [
    { resource: 'projects', actions: '*' },
    { resource: 'environments', actions: '*' },
    { resource: 'billing', actions: '*' },
    { resource: 'report', actions: '*' },
    { resource: 'settings', actions: '*' },
    { resource: 'limits', actions: '*' },
    { resource: 'teams', actions: '*' },
    { resource: 'organisations', actions: '*' }
  ]
  // ROLE_ADMIN has permission to everything
};

import { useAxios } from '@rc/utils/axios';
import { useRecordContext } from 'react-admin';

import { useRCWorkerMessageRecord } from '@rc/admin/context';

export const useEnvironmentAccess = () => {
  const record = useRecordContext();
  const workerData = useRCWorkerMessageRecord();

  const environmentAccessId = record?.environmentAccess?.split('/').pop();

  const [{ data, loading: isLoading }] = useAxios(
    `/api/environment_accesses/${environmentAccessId}/all-data`,
    {
      manual: !environmentAccessId
    }
  );

  return {
    isLoading,
    value: workerData?.environmentAccess || data
  };
};

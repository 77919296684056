import React, { useCallback } from 'react';
import { TextInput } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { createAvatarText } from '@rc/admin/utils/common';

/**
 *
 * @param {import('react-admin').TextInputProps} props
 * @returns
 */
export const NameInput = props => {
  const {
    setValue,
    getValues,
    formState: { dirtyFields }
  } = useFormContext();

  /**
   * Automatically generate "code" field value from the given name.
   * Example:
   * (1): Sample Project -> SA
   * (2): Sample -> S
   */
  const handleChange = useCallback(
    event => {
      const value = event.target.value;
      const values = getValues();

      if (!dirtyFields.code || !values.code || values.code.length < 2) {
        const newCode = createAvatarText(value);
        setValue('code', newCode.toUpperCase(), { shouldValidate: true });
      }
    },
    [dirtyFields, getValues, setValue]
  );

  return <TextInput onChange={handleChange} {...props} />;
};

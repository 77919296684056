import React from 'react';
import { Datagrid, EditButton, List, TextField } from 'react-admin';

export const RoleGroupList = () => {
  return (
    <List>
      <Datagrid>
        <TextField source='name' />
        <EditButton sx={{ float: 'right' }} />
      </Datagrid>
    </List>
  );
};

export const CREATE_METHOD_TYPES = {
  FROM_TEMPLATE: 'template',
  FROM_SCRATCH: 'scratch'
};

export const cryptoKeyConnectionTestedSource = '__cryptoKeyConnectionTested';

export const transformData = data => {
  const result = {
    ...data,
    projectEnvVar: data.projectEnvVar.filter(envVar => envVar),
    git: data.git['@id']
  };

  delete result.__provider;
  delete result.__cluster;
  delete result.__environments;
  delete result[cryptoKeyConnectionTestedSource];

  return result;
};

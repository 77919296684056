import { EnvironmentEdit } from './Edit';
import { EnvironmentCreate } from './Create';
import EnvironmentIcon from './Icon';
import { EnvironmentList } from './List';

export default {
  name: 'environments',
  edit: EnvironmentEdit,
  create: EnvironmentCreate,
  list: EnvironmentList,
  icon: EnvironmentIcon,
  options: {
    showInMenu: false
  }
};

import React from 'react';
import { CloudDownload } from '@mui/icons-material';
import { Button } from '@mui/material';
import { useRecordContext, useTranslate } from 'react-admin';

export const DownloadButton = () => {
  const t = useTranslate();
  const record = useRecordContext();

  if ('paid' !== record.status) {
    return null;
  }

  return (
    <Button
      variant={'contained'}
      startIcon={<CloudDownload />}
      size={'small'}
      disabled={'paid' !== record.status}
      href={record.invoice_pdf}
      target={'_blank'}
    >
      {t('action.download')}
    </Button>
  );
};

export default DownloadButton;

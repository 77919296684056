import React, { Children, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-admin';
import { CardContent } from '@mui/material';
import { FormStepperContextProvider, useFormStepperContext } from './context';
import { FormStepper } from './FormStepper';
import WizardFormToolbar from './Toolbar';

/**
 *
 * @param {import('react-admin').SimpleFormProps} props
 * @returns
 */
const WizardForm = props => {
  const { children, className, sx, formRef, ...rest } = props;

  const steps = useMemo(
    () => Children.map(children, mapChildProps).filter(v => v),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <FormStepperContextProvider steps={steps}>
      <WizardFormView {...rest} formRef={formRef}>
        {children}
      </WizardFormView>
    </FormStepperContextProvider>
  );
};

const mapChildProps = child => {
  return child?.props || null;
};

const WizardFormView = props => {
  const {
    children,
    toolbar = DefaultToolbar,
    component: Component = DefaultComponent,
    disableSave,
    onSubmit: onSubmitProp,
    ...rest
  } = props;
  const [{ activeStep, hasNext, steps }] = useFormStepperContext();
  const activeFormStepComponentChild = Children.toArray(children)[activeStep];

  const preventedOnSubmitOnNext =
    hasNext && !steps[activeStep].forceSubmit ? () => {} : onSubmitProp;

  return (
    <Form {...rest} onSubmit={preventedOnSubmitOnNext}>
      <FormStepper steps={steps} />
      <Component
        className={WizardFormClasses.step(activeStep + 1)}
        {...sanitizeRestProps(rest)}
      >
        {activeFormStepComponentChild}
      </Component>
      {toolbar !== false
        ? React.cloneElement(toolbar, {
            disableSave
          })
        : null}
    </Form>
  );
};

const DefaultComponent = ({ children, sx, className }) => {
  return (
    <CardContent sx={sx} className={className}>
      {children}
    </CardContent>
  );
};

const DefaultToolbar = <WizardFormToolbar />;

const PREFIX = 'WizardForm';

export const WizardFormClasses = {
  step: n => `${PREFIX}-step-${n}`
};

const sanitizeRestProps = ({
  children,
  className,
  component,
  defaultValues,
  onSubmit,
  record,
  resource,
  reValidateMode,
  sx,
  toolbar,
  validate,
  resolver,
  warnWhenUnsavedChanges,
  ...props
}) => props;

WizardForm.propTypes = {
  children: PropTypes.node,
  defaultValues: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  // @ts-ignore
  record: PropTypes.object,
  redirect: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.func
  ]),
  toolbar: PropTypes.oneOfType([PropTypes.element, PropTypes.oneOf([false])]),
  validate: PropTypes.func,
  disableSave: PropTypes.bool
};

export default WizardForm;

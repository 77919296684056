import React from 'react';
import Iconify from '@rc/admin/components/ui/Iconify';
import { TemplateProjectCreate } from './Create';
import { TemplateProjectEdit } from './Edit';
import { TemplateProjectList } from './List';

export default {
  name: 'template_projects',
  create: TemplateProjectCreate,
  edit: TemplateProjectEdit,
  list: TemplateProjectList,
  icon: () => <Iconify icon='solar:documents-bold-duotone' />,
  options: {
    label: 'Project Templates'
  }
};

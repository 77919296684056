import React, { useCallback } from 'react';
import {
  EditContextProvider,
  ResourceContextProvider,
  useEditController,
  useNotify,
  useRefresh
} from 'react-admin';
import { useSideDialogContext } from '../../ui/SideDialog';

/**
 *
 * @param {object} props
 * @param {string} props.resource
 * @returns
 */
export const EditDialogBase = props => {
  const { children } = props;

  const controllerProps = useEditDialogController(props);

  const body = (
    <EditContextProvider value={controllerProps}>
      {children}
    </EditContextProvider>
  );

  return (
    <ResourceContextProvider value={props.resource}>
      {body}
    </ResourceContextProvider>
  );
};

/**
 *
 * @param {import('react-admin').EditControllerProps} props
 * @returns
 */
const useEditDialogController = props => {
  const { mutationOptions } = props;
  const [, { close }] = useSideDialogContext();
  const refresh = useRefresh();
  const notify = useNotify();

  const onSuccess = useCallback(
    (data, variables, context) => {
      if (mutationOptions?.onSuccess) {
        mutationOptions.onSuccess(data, variables, context);
      }
      close();
      notify('ra.notification.updated', { messageArgs: { smart_count: 1 } });
      refresh();
    },
    [close, mutationOptions, notify, refresh]
  );

  const controllerProps = useEditController({
    ...props,
    mutationMode: props.mutationMode || 'pessimistic',
    redirect: props.redirect || false,
    mutationOptions: {
      ...(mutationOptions || {}),
      onSuccess
    }
  });

  return controllerProps;
};

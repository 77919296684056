import { useGetIdentity, useGetOne } from 'react-admin';

export const useUser = () => {
  const { identity, isLoading: isIdentityLoading } = useGetIdentity();
  const { data: user, isLoading: isUserLoading } = useGetOne(
    'users',
    {
      id: `/api/users/${identity?.id}`
    },
    {
      enabled: !!identity?.id
    }
  );

  return {
    user,
    isLoading: isIdentityLoading || isUserLoading
  };
};

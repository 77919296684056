import React from 'react';
import { useGetList, useTranslate } from 'react-admin';
import { useWatch } from 'react-hook-form';
import { Functions } from '@mui/icons-material';
import { Divider, Stack, Typography } from '@mui/material';
import {
  CpuIcon,
  CpuInputConfig,
  MemoryIcon,
  MemoryInputConfig,
  StorageIcon,
  StorageInputConfig
} from '@rc/admin/components';
import { Row } from '@rc/admin/components/domain/costs/Row';
import {
  mergePrices,
  multiplyPrice,
  usePriceFormatter
} from '@rc/admin/utils/domain/costs';

/**
 *
 * @param {object} props
 * @param {string} props.source
 * @returns
 */
export const EstimatedResourceCosts = props => {
  const { source } = props;
  const region = useWatch({ name: 'cluster' });

  const { data: products, isLoading: isProductsLoading } = useGetList(
    'products',
    { filter: { region } }
  );

  const cpuPrice = multiplyPrice(
    useAggregatedUsage(source, 'cpu'),
    products?.find(product => product.name === 'cpu')?.defaultPrice
  );

  const memoryPrice = multiplyPrice(
    useAggregatedUsage(source, 'memory'),
    products?.find(product => product.name === 'memory')?.defaultPrice
  );

  const storagePrice = multiplyPrice(
    useAggregatedUsage(source, 'storage'),
    products?.find(product => product.name === 'storage')?.defaultPrice
  );

  const sumPrice = mergePrices(cpuPrice, memoryPrice, storagePrice);

  return (
    <Stack flexDirection={'column'} rowGap={theme => theme.spacing(2)}>
      <EstimatedResourceCost
        label={'resources.resource_types.names.cpu'}
        icon={<CpuIcon />}
        price={cpuPrice}
        unit={CpuInputConfig.unit}
        isLoading={isProductsLoading}
      />
      <EstimatedResourceCost
        label={'resources.resource_types.names.memory'}
        icon={<MemoryIcon />}
        price={memoryPrice}
        unit={MemoryInputConfig.unit}
        isLoading={isProductsLoading}
      />
      <EstimatedResourceCost
        label={'resources.resource_types.names.storage'}
        icon={<StorageIcon />}
        price={storagePrice}
        unit={StorageInputConfig.unit}
        format={StorageInputConfig.parse}
        isLoading={isProductsLoading}
      />
      <Divider light />
      <Row
        label={'misc.sum'}
        icon={<Functions />}
        price={sumPrice}
        labelContainerProps={{ xs: 5 }}
        priceContainerProps={{ xs: 7 }}
        isLoading={isProductsLoading}
      />
    </Stack>
  );
};

/**
 *
 * @param {object} props
 * @param {string} props.source
 * @param {string} props.resourceTypeName
 * @param {import('react').ReactNode} props.icon
 * @param {string} props.label
 * @param {boolean} props.isLoading
 * @returns
 */
const EstimatedResourceCost = props => {
  const { icon, label, price, unit, format = v => v, isLoading } = props;
  const t = useTranslate();
  const formatter = usePriceFormatter(price?.currency);

  return (
    <Stack rowGap={theme => theme.spacing(1)}>
      <Row
        label={t(label)}
        icon={icon}
        price={price}
        isLoading={isLoading}
        labelContainerProps={{ xs: 5 }}
        priceContainerProps={{
          minHeight: 40,
          xs: 7,
          children: price?.defaultPrice && (
            <Typography
              component={'span'}
              variant={'body2'}
              color={'text.secondary'}
              sx={{ display: 'block' }}
            >
              {`1 ${unit} = ${formatter.format(
                format(price?.defaultPrice?.amount)
              )}`}
            </Typography>
          )
        }}
      />
    </Stack>
  );
};

const useAggregatedUsage = (source, resourceTypeName) => {
  const environmentComponents = useWatch({
    name: source
  });

  const used = environmentComponents.reduce(
    (acc, { [resourceTypeName]: resource }) => (acc += resource || 0),
    0
  );

  return used;
};

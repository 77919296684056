import React from 'react';
import _ from 'lodash';
import {
  Datagrid,
  FunctionField,
  ListContextProvider,
  useList,
  useTranslate
} from 'react-admin';
import { CreatedAt, DownloadButton, PayButton, Status } from './components';
import { Box, Typography } from '@mui/material';
import { AllInbox } from '@mui/icons-material';

export const InvoiceList = props => {
  const t = useTranslate();
  const listContext = useList({
    data: props.requests,
    isLoading: _.isNil(props.requests)
  });

  return (
    <ListContextProvider value={listContext}>
      <Datagrid bulkActionButtons={null} empty={<Empty />}>
        <FunctionField
          label={t('pages.billing.invoices.fields.total')}
          render={record =>
            new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: record.currency
            }).format(record.total / 100)
          }
        />
        <FunctionField
          label={t('pages.billing.invoices.fields.remaining')}
          render={record =>
            new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: record.currency
            }).format(record.amount_remaining / 100)
          }
        />
        <Status label={t('pages.billing.invoices.fields.status')} />
        <CreatedAt label={t('pages.billing.invoices.fields.issuedAt')} />
        <PayButton
          methods={props.methods}
          setList={props.setList}
          goTo={props.goTo}
        />
        <DownloadButton />
      </Datagrid>
    </ListContextProvider>
  );
};

const Empty = () => {
  const t = useTranslate();
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '100%',
        textAlign: 'center'
      }}
    >
      <AllInbox fontSize={'large'} />
      <Typography>{t('pages.billing.invoices.empty')}</Typography>
    </Box>
  );
};

export default InvoiceList;

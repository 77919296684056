import React from 'react';
import _ from 'lodash';
import { Box } from '@mui/material';
import { TextInput } from 'react-admin';

export const DTHttp = () => (
  <Box width={'100%'}>
    <TextInput
      source={'dataTransfer.source'}
      label={'resources.imported_datas.fields.dataTransfer.source'}
      placeholder={'https://example.com/example.tar.gz'}
      validate={validateHttpUrl}
      required
      fullWidth
    />
  </Box>
);

const validateHttpUrl = (value, allValues) => {
  if (_.isEmpty(value)) {
    return undefined;
  }

  // eslint-disable-next-line
  return /^(https?:\/\/(?:[a-z0-9](?:[a-z0-9-\@\:]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9])(:?\d*)\/?([a-z_\/0-9\-#.]*)\??([a-z_\/0-9\-#=&]*)/i.test(
    value
  )
    ? undefined
    : 'Please enter a valid url';
};

export default DTHttp;

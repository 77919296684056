import React, { isValidElement } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  RecordContextProvider,
  sanitizeListRestProps,
  useListContext,
  useResourceContext,
  useCreatePath,
  useResourceDefinition
} from 'ra-core';
import { styled } from '@mui/material/styles';
import { Box, Card, Grid } from '@mui/material';
import { LoadingBox } from '@rc/admin/components';
import clsx from 'clsx';

/**
 *
 * @param {object} props
 * @param {any} props.render
 * @param {boolean} props.enablePlaceholders
 * @param {import('@mui/material').CardProps} props.cardProps
 * @param {number} props.placeholdersCount
 * @param {(record: import('react-admin').RaRecord) => import('@mui/material').SxProps} props.cardStyles
 * @param {import('@mui/material').GridSize} props.itemGridSizes
 * @returns
 */
export const CardList = props => {
  const {
    className,
    hasBulkActions,
    linkType,
    render,
    placeholdersCount,
    enablePlaceholders,
    cardProps,
    empty,
    itemProps,
    cardStyles,
    itemGridSizes,
    children,
    ...rest
  } = props;
  const { data, isLoading, total } = useListContext(props);
  const resource = useResourceContext(props);

  const isEmpty = !((total == null && data?.length > 0) || total > 0);

  if (isLoading === true && !enablePlaceholders) {
    return <LoadingBox minHeight={200} />;
  } else if (!isLoading && isEmpty && empty) {
    return empty;
  }

  const items = isLoading
    ? Array(placeholdersCount).fill({ type: 'placeholder' })
    : data;

  const shouldRender = isLoading || !isEmpty;

  return shouldRender ? (
    <Root
      container
      spacing={2}
      className={clsx(className, CardListClasses.root)}
      {...sanitizeListRestProps(rest)}
    >
      {items.map((record, rowIndex) => {
        return (
          <RecordContextProvider key={rowIndex} value={record}>
            <Grid item {...itemGridSizes} zeroMinWidth {...itemProps}>
              <LinkOrNot
                linkType={isLoading ? false : linkType}
                resource={resource}
                id={record.id}
                record={record}
                sx={{ height: '100%' }}
              >
                <Card
                  className={CardListClasses.card}
                  {...cardProps}
                  sx={cardStyles(record)}
                >
                  {isValidElement(render)
                    ? render
                    : render(record, record.id, isLoading && isEmpty)}
                </Card>
              </LinkOrNot>
            </Grid>
          </RecordContextProvider>
        );
      })}
      {React.Children.map(children, child => (
        <Grid item {...itemGridSizes} zeroMinWidth {...itemProps}>
          {child}
        </Grid>
      ))}
    </Root>
  ) : null;
};

CardList.propTypes = {
  className: PropTypes.string,
  linkType: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.func
  ])
};

const LinkOrNot = props => {
  const {
    classes: classesOverride,
    linkType,
    resource,
    id,
    children,
    record,
    ...rest
  } = props;
  const createPath = useCreatePath();
  const { hasShow } = useResourceDefinition({ resource });
  const type = typeof linkType === 'function' ? linkType(record, id) : linkType;
  const path =
    type !== false &&
    createPath({ resource, id, type: type || (hasShow ? 'show' : 'edit') });

  return type === false ? (
    <Box {...rest}>{children}</Box>
  ) : path ? (
    <StyledLink to={path} {...rest}>
      {children}
    </StyledLink>
  ) : (
    <Box {...rest}>{children}</Box>
  );
};

const PREFIX = 'CardList';

export const CardListClasses = {
  root: `${PREFIX}-root`,
  card: `${PREFIX}-card`
};

const Root = styled(Grid, {
  name: PREFIX,
  overridesResolver: (_, styles) => styles.root
})(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  paddingBottom: theme.spacing(2),
  [`& .${CardListClasses.card}`]: {
    width: '100%',
    height: '100%',
    '&:hover': {
      boxShadow: theme.customShadows.z12
    }
  }
}));

const StyledLink = styled(Link)(() => ({
  cursor: 'pointer',
  textDecoration: 'none'
}));

CardList.defaultProps = {
  enablePlaceholders: false,
  placeholdersCount: 4,
  itemProps: {},
  cardProps: {},
  cardStyles: () => {},
  itemGridSizes: {
    xs: 12,
    sm: 6,
    lg: 4,
    xl: 3
  }
};

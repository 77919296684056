import React from 'react';
import * as Sentry from '@sentry/react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType
} from 'react-router';

function init () {
  if (!process.env.SENTRY_DSN_FRONTEND || !process.env.SENTRY_ENV) {
    return;
  }

  Sentry.init({
    environment: process.env.SENTRY_ENV,
    release: process.env.APP_VERSION,
    dsn: process.env.SENTRY_DSN_FRONTEND,
    integrations: [
      new Sentry.BrowserTracing({
        // See docs for support of different versions of variation of react router
        // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        )
      }),
      new Sentry.Replay()
    ],

    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      'localhost',
      /^https:\/\/reward-cloud\.local/,
      /^https:\/\/staging\.rewardcloud\.itg\.cloud/,
      /^https:\/\/dev\.rewardcloud\.itg\.cloud/,
      /^https:\/\/console\.reward\.sh/
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0
  });
}

export default {
  init
};

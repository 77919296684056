import React, { useMemo } from 'react';
import {
  DatagridBody,
  List,
  useListContext,
  useResourceContext
} from 'react-admin';
import { useMercureSubscription } from '@api-platform/admin';
import { Card } from '@mui/material';

/**
 *
 * @param {import('react-admin').ListProps} props
 * @returns
 */
export const ListWithMercure = props => {
  return <List component={MercureSubscriberForList} {...props} />;
};

export const DataGridBodyWithMercure = props => {
  return <MercureSubscriberForList {...props} component={DatagridBody} />;
};

export const MercureSubscriberForList = props => {
  const { component: Component = Card, ...rest } = props;

  const resource = useResourceContext(props);
  const { data } = useListContext();

  /**
   * Memoize to prevent re-subscribing on every re-render.
   */
  const ids = useMemo(() => data?.map(record => record.id), [data]);

  useMercureSubscription(resource, ids);

  return <Component {...rest} />;
};

import React, { useCallback, useState } from 'react';
import {
  maxLength,
  required,
  TextInput,
  useGetList,
  useRecordContext,
  useResourceContext,
  useTranslate,
} from 'react-admin';
import { useFormContext, useWatch } from 'react-hook-form';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
} from '@mui/material';
import { SectionHelper } from '@rc/admin/components';
import { useIsTemplateResource } from '@rc/admin/hooks';
import { useValidateName } from '../../hooks';
import { ENVIRONMENT_NAME_MAX_LENGTH } from '../../constants';

export const EnvironmentNameInputs = () => {
  const t = useTranslate();
  const resource = useResourceContext();
  const isTemplate = useIsTemplateResource();
  const { trigger, setValue, getValues, formState } = useFormContext();
  const record = useRecordContext();

  const { validateAsyncDebounce } = useValidateName({
    debounceSettings: { leading: false },
  });

  const [id, project, templateProject] = useWatch({
    name: ['id', 'project', 'templateProject'],
  });

  const isEdit = !!id;

  const { data: siblingEnvironments, isLoading: isSiblingEnvironmentsLoading } =
    useGetList(resource, {
      filter: isTemplate ? { templateProject } : { project },
    });

  const isDefaultEnvironment =
    !isSiblingEnvironmentsLoading &&
    (!siblingEnvironments?.length ||
      (siblingEnvironments?.length === 1 && siblingEnvironments[0]?.id === id));

  const [isDifferentTargetRefNameAndName, setisDifferentTargetRefNameAndName] =
    useState(() => {
      const { ['name']: name, ['targetRefName']: targetRefName } = getValues();
      return name !== targetRefName;
    });

  const handleNameChange = useCallback(
    (event) => {
      if (isTemplate) return;

      trigger('name');

      if (!isDifferentTargetRefNameAndName) {
        setValue('targetRefName', event.target.value, {
          shouldTouch: true,
          shouldValidate: true,
          shouldDirty: true,
        });
      }
    },
    [isDifferentTargetRefNameAndName, isTemplate, setValue, trigger]
  );

  const handleCheckboxChange = useCallback(
    (event) => {
      setisDifferentTargetRefNameAndName(event.target.checked);

      if (!event.target.checked) {
        const { ['name']: name } = getValues();
        setValue('targetRefName', name, {
          shouldTouch: true,
          shouldValidate: true,
          shouldDirty: true,
        });
      } else {
        const fallbackTargetRefName =
          isEdit && record
            ? record['targetRefName'] || record['name']
            : formState.defaultValues?.['targetRefName'];

        setValue('targetRefName', fallbackTargetRefName, {
          shouldTouch: true,
          shouldValidate: true,
          shouldDirty: true,
        });
      }
    },
    [formState.defaultValues, getValues, isEdit, record, setValue]
  );

  return (
    <>
      <SectionHelper mb={1}>
        {t(
          isDefaultEnvironment
            ? 'resources.environments.helpers.name_first_creation'
            : 'resources.environments.helpers.name'
        )}
      </SectionHelper>
      <Grid container spacing={1.5}>
        {(!isEdit || isTemplate) && (
          <Grid item xs={12} sm={6} mb={isTemplate ? 2 : undefined}>
            <TextInput
              source={'name'}
              label={'resources.environments.fields.name'}
              id="environment-name"
              autoComplete="off"
              validateOnChange
              validate={[
                required(),
                validateAsyncDebounce,
                maxLength(ENVIRONMENT_NAME_MAX_LENGTH),
                validateDnsSubdomainName,
              ]}
              onChange={handleNameChange}
              format={(v) =>
                v?.toLowerCase().slice(0, ENVIRONMENT_NAME_MAX_LENGTH - 1)
              }
              parse={(v) =>
                v?.toLowerCase().slice(0, ENVIRONMENT_NAME_MAX_LENGTH - 1)
              }
              helperText={false}
              sx={(theme) => ({
                '& .MuiFormHelperText-root': {
                  marginLeft: theme.spacing(0.25),
                },
              })}
              fullWidth
            />
          </Grid>
        )}
        {!isTemplate && (
          <>
            <Grid item xs={12}>
              <FormControl>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isDifferentTargetRefNameAndName}
                        onChange={handleCheckboxChange}
                        id={'environment-isDifferentTargetRefNameAndName'}
                        required={false}
                      />
                    }
                    label={t(
                      'resources.environments.fields.isDifferentTargetRefNameAndName'
                    )}
                  />
                </FormGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextInput
                fullWidth
                label={'resources.environments.fields.targetRefName'}
                id="environment-targetRefName"
                source={'targetRefName'}
                validate={isDifferentTargetRefNameAndName ? [required()] : []}
                autoComplete="off"
                format={(v) =>
                  isDifferentTargetRefNameAndName ? v : getValues('name')
                }
                InputProps={{
                  disabled: !isDifferentTargetRefNameAndName,
                }}
                sx={(theme) => ({
                  '& .MuiFormHelperText-root': {
                    marginLeft: theme.spacing(0.25),
                  },
                })}
              />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

/**
 * Based on https://kubernetes.io/docs/concepts/overview/working-with-objects/names/#dns-subdomain-names
 *
 * @param {string} val
 */
function validateDnsSubdomainName(val) {
  const regex = new RegExp(
    `^[a-z0-9]([a-z0-9.-]{0,${ENVIRONMENT_NAME_MAX_LENGTH - 2}}[a-z0-9])?$`
  );
  const result = regex.test(val);

  if (!result) {
    return 'resources.environments.validation.invalid_dns_subdomain_name';
  }
}

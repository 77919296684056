import React from 'react';
import {
  BooleanField,
  BooleanInput,
  Datagrid,
  Edit,
  EditClasses,
  SimpleForm,
  SimpleList,
  TextField,
  TextInput
} from 'react-admin';
import { EditButton } from '@rc/admin/ra';
import { styled, Card, useMediaQuery } from '@mui/material';
import {
  AsideReferenceList,
  Breadcrumbs,
  EditTitle
} from '@rc/admin/components';
import { OneColumnFormStyles } from '@rc/admin/theme/styles';

export const ProviderEdit = () => {
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

  return (
    <StyledEdit
      title={<EditTitle />}
      redirect={false}
      actions={<Breadcrumbs />}
      aside={
        <Card className={classes.asideCard}>
          <AsideReferenceList
            resource={'clusters'}
            referenceSource={'provider'}
          >
            {isSmall ? (
              <SimpleList
                primaryText={() => <TextField source={'name'} />}
                secondaryText={() => <BooleanField source={'isDefault'} />}
                style={{ width: '100%' }}
              />
            ) : (
              <Datagrid style={{ width: '100%' }}>
                <TextField source={'name'} />
                <BooleanField source={'isDefault'} />
                <EditButton />
              </Datagrid>
            )}
          </AsideReferenceList>
        </Card>
      }
    >
      <SimpleForm defaultValues={{ isEnabled: true }} className={classes.form}>
        <TextInput source={'name'} fullWidth />
        <BooleanInput source={'isDefault'} fullWidth />
      </SimpleForm>
    </StyledEdit>
  );
};

const PREFIX = 'ProviderEdit';

const classes = {
  asideCard: `${PREFIX}-asideCard`,
  form: `${PREFIX}-form`
};

const StyledEdit = styled(Edit)(({ theme }) => ({
  [`& .${EditClasses.main}`]: {
    display: 'grid',
    gridTemplateColumns: '5fr 3fr',
    columnGap: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr',
      gap: theme.spacing(3),
      '& > *': {
        width: '100%'
      }
    }
  },

  [`& .${classes.asideCard}`]: {
    height: '100%'
  },

  [`& .${classes.form}`]: {
    ...OneColumnFormStyles(theme)
  }
}));

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { IconButtonWithTooltip, useTranslate } from 'react-admin';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { Box, Chip, Typography, styled } from '@mui/material';
import { LoadingBox } from '@rc/admin/components';
// import testLogs from '@rc/test/test-logs';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList } from 'react-window';
import { useWindowSize } from '@rc/admin/hooks';

const HEIGHT = 480;
const SCROLL_STATES = {
  FIX_TO_BOTTOM: 'fix-to-bottom',
  FIX_TO_TOP: 'fix-to-top',
  DYNAMIC: 'dynamic',
};

/**
 *
 * @param {object} props
 * @param {string[]} props.logs
 * @param {boolean} props.isLoading
 * @param {boolean} props.isRunning
 * @returns
 */
export const LogsDisplay = (props) => {
  const { isLoading, logs, isRunning, filters } = props;

  const t = useTranslate();
  const paperRef = useRef();

  /**
   * @type {import('react').MutableRefObject<VariableSizeList>}
   * */
  const listRef = useRef();

  const [scrollState, setScrollState] = useState(SCROLL_STATES.DYNAMIC);
  const shouldHandleScrollEventRef = useRef(false);

  const forceScrollTop = useCallback((index) => {
    shouldHandleScrollEventRef.current = false;
    listRef.current.scrollToItem(index);
  }, []);

  const changeScrollState = useCallback(
    (newScrollState) => {
      setScrollState((curr) => {
        if (curr === newScrollState) {
          return SCROLL_STATES.DYNAMIC;
        } else if (newScrollState === SCROLL_STATES.FIX_TO_TOP) {
          forceScrollTop(0);
        } else if (newScrollState === SCROLL_STATES.FIX_TO_BOTTOM) {
          forceScrollTop(logs.length - 1);
        }

        return newScrollState;
      });
    },
    [forceScrollTop, logs.length]
  );

  useEffect(() => {
    if (scrollState === SCROLL_STATES.FIX_TO_BOTTOM) {
      forceScrollTop(logs.length - 1);
    }
  }, [forceScrollTop, logs.length, scrollState]);

  useWindowSize(() => listRef.current?.resetAfterIndex(0));

  const Row = ({ index, style }) => {
    const relatedFilter = filters.find(
      (filter) =>
        filter.component ===
        logs[index].source?.toLowerCase().split(' ').join('-')
    );

    return (
      <div key={index} className={classes.logLine} style={style}>
        <Typography component="span" className={classes.logTimestamp}>
          {logs[index].timestamp && logs[index].timestamp}
        </Typography>
        <Typography component="span" className={classes.logLineText}>
          {logs[index].source && (
            <Chip
              // component={'span'}
              className={classes.logSource}
              label={logs[index].source}
              size="small"
              sx={(theme) => ({
                borderRadius: theme.shape.borderRadius,
                backgroundColor: relatedFilter?.color,
                color: theme.palette.getContrastText(relatedFilter?.color),
                pointerEvents: 'none',
              })}
            />
          )}
          {logs[index].message || null}
        </Typography>
      </div>
    );
  };

  return (
    <>
      <StyledBox id="logs-display" elevation={2} ref={paperRef}>
        <Box className={classes.actions}>
          <IconButtonWithTooltip
            className={
              scrollState === SCROLL_STATES.FIX_TO_BOTTOM
                ? classes.activeButton
                : ''
            }
            disabled={scrollState === SCROLL_STATES.FIX_TO_BOTTOM}
            label={'resources.environments.sections.logs.scroll_to_bottom'}
            onClick={() => changeScrollState(SCROLL_STATES.FIX_TO_BOTTOM)}
            size="small"
          >
            <ArrowDownward color={'inherit'} />
          </IconButtonWithTooltip>
          <IconButtonWithTooltip
            className={
              scrollState === SCROLL_STATES.FIX_TO_TOP
                ? classes.activeButton
                : ''
            }
            disabled={scrollState === SCROLL_STATES.FIX_TO_TOP}
            label={'resources.environments.sections.logs.scroll_to_top'}
            onClick={() => changeScrollState(SCROLL_STATES.FIX_TO_TOP)}
            size="small"
          >
            <ArrowUpward color={'inherit'} />
          </IconButtonWithTooltip>
        </Box>

        {isLoading && <LoadingBox minHeight={HEIGHT - 50} />}
        {!isRunning && !isLoading && !logs.length && (
          <Typography component="span" className={classes.logTimestamp} mt={1}>
            {t('resources.environments.sections.logs.stream_not_running')}
          </Typography>
        )}
        {isRunning && !logs.length && (
          <Typography component="span" className={classes.logTimestamp} mt={1}>
            {t('resources.environments.sections.logs.stream_running')}
          </Typography>
        )}
        <AutoSizer>
          {({ height, width }) => (
            <VariableSizeList
              width={width}
              height={height}
              itemCount={logs.length}
              itemSize={getItemSize(paperRef.current, logs)}
              overscanCount={5}
              ref={listRef}
              onScroll={() => {
                if (shouldHandleScrollEventRef.current) {
                  setScrollState(SCROLL_STATES.DYNAMIC);
                }

                shouldHandleScrollEventRef.current = true;
              }}
            >
              {Row}
            </VariableSizeList>
          )}
        </AutoSizer>
      </StyledBox>
    </>
  );
};

const getItemSize = (container, logs) => {
  return (index) => {
    const horizontalSpacing = 74;
    const lineHeight = 28;
    const charWidth = 10;

    const width = container.clientWidth - horizontalSpacing;

    if (!logs.length || !logs[index]) {
      return lineHeight;
    }

    return (
      Math.ceil(logs[index].raw.length / (width / charWidth)) * lineHeight +
      // First item has a margin top
      (index === 0 ? 32 : 0)
    );
  };
};

const PREFIX = 'LogsDisplay';

const classes = {
  actions: `${PREFIX}-actions`,
  activeButton: `${PREFIX}-activeButton`,
  logLine: `${PREFIX}-logLine`,
  logTimestamp: `${PREFIX}-logTimestamp`,
  logSource: `${PREFIX}-logSource`,
  logLineText: `${PREFIX}-logLineText`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  width: '100%',
  height: HEIGHT,
  paddingLeft: theme.spacing(2),
  background:
    theme.palette.mode === 'light' ? '#F9F9F9' : `rgba(145, 158, 171, 0.03)`,
  position: 'relative',
  borderRadius: `${theme.shape.borderRadius}px 0 0 0`,

  [`& .${classes.actions}`]: {
    zIndex: 1,
    position: 'sticky',
    float: 'right',
    top: 0,
    opacity: 0.75,
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(1),
    '& button:not(:last-child)': {
      marginRight: theme.spacing(1),
    },
    [`& button`]: {
      opacity: 1,
      color:
        theme.palette.mode === 'light'
          ? theme.palette.common.black
          : theme.palette.common.white,
    },
    [`& .${classes.activeButton}`]: {
      opacity: 0.25,
    },
  },

  [`& .${classes.logLine}`]: {
    margin: `${theme.spacing(0.5)} 0`,
    paddingRight: theme.spacing(2),
    '& *': {
      fontFamily:
        'Consolas, Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace, serif',
    },
    ':first-of-type': {
      marginTop: theme.spacing(4),
    },
  },

  [`& .${classes.logTimestamp}`]: {
    paddingRight: '1em',
    color: theme.palette.grey[500],
    fontWeight: theme.typography.fontWeightMedium,
    textAlign: 'right',
  },

  [`& .${classes.logSource}`]: {
    marginRight: '1em',
  },

  [`& .${classes.logLineText}`]: {
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-all',
    color:
      theme.palette.mode === 'light'
        ? theme.palette.common.black
        : theme.palette.getContrastText(theme.palette.primary.main),
    lineHeight: '1.75em',
    fontWeight: theme.typography.fontWeightMedium,
  },
}));

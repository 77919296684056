import { useEffect, useRef } from 'react';
import { useDataProvider } from 'react-admin';
import useUpdateCache from '@rc/admin/moduleOverrides/useUpdateCache';
import { fetchMercureSecureJwt } from '@rc/admin/utils/mercure-jwt';

export default function useMercureSubscription (
  resource,
  idOrIds,
  /**
   * CUSTOM: Pass custom handler
   */
  options
) {
  const { handleReceived, onReceived } = options || {};

  const dataProvider = useDataProvider();
  const updateCache = useUpdateCache();

  const hasShownNoSubscribeWarning = useRef(false);

  useEffect(() => {
    if (!idOrIds || !resource) {
      return undefined;
    }
    const ids = Array.isArray(idOrIds)
      ? idOrIds.map(id => id.toString())
      : [idOrIds.toString()];

    if (
      !hasShownNoSubscribeWarning.current &&
      (dataProvider.subscribe === undefined ||
        dataProvider.unsubscribe === undefined)
    ) {
      // eslint-disable-next-line no-console
      console.warn(
        'subscribe and/or unsubscribe methods were not set in the data provider, Mercure realtime update functionalities will not work. Please use a compatible data provider.'
      );
      hasShownNoSubscribeWarning.current = true;
      return undefined;
    }

    /**
     * CUSTOM: Refetch jwt token if id is not found between topics
     */
    const subscribe = async () => {
      const topicsFromLocalStorage = JSON.parse(
        window.localStorage.getItem('topics') || []
      );

      const topics = Array.isArray(topicsFromLocalStorage)
        ? topicsFromLocalStorage
        : [];

      if (ids.some(id => !topics.includes(`${window.location.origin}${id}`))) {
        await fetchMercureSecureJwt();
      }

      dataProvider.subscribe(ids, document => {
        /**
         * Call custom handler 'onReceived'
         */
        if (typeof handleReceived === 'function') {
          handleReceived(document);
        } else {
          updateCache({ resource, id: document.id, data: document });
        }

        if (typeof onReceived === 'function') {
          onReceived(document);
        }
      });
    };

    subscribe();

    return () => {
      if (resource) {
        dataProvider.unsubscribe(resource, ids);
      }
    };
  }, [
    idOrIds,
    resource,
    dataProvider,
    updateCache,
    onReceived,
    handleReceived
  ]);
}

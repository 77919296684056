import React from 'react';
import {
  ArrayInput,
  SelectInput,
  SimpleFormIterator,
  SimpleFormIteratorClasses,
  TextInput,
  IconButtonWithTooltip,
  required,
  useSimpleFormIterator,
  useSimpleFormIteratorItem,
  useTranslate
} from 'react-admin';
import { FormControl, TextField, styled } from '@mui/material';
import {
  RemoveCircleOutline as CloseIcon,
  AddCircleOutline as AddIcon
} from '@mui/icons-material';
import { useWatch, useFormContext } from 'react-hook-form';
import { MAGE_RUN_TYPES } from '@rc/admin/constants';
import { useProjectContext } from '@rc/admin/context';
import { useProjectType } from '@rc/admin/resources/project/hooks';

/**
 * @param {object} props
 * @param {string} props.source
 * @returns {JSX.Element}
 */
export const MagentoHostMappingsFields = props => {
  const { source } = props;
  const { project } = useProjectContext();
  const { projectType } = useProjectType({ project });

  const [magentoHostMappings, environmentCustomDomains] = useWatch({
    name: [source, 'environmentCustomDomain']
  });

  if (!projectType || !projectType.codeName.includes('magento')) {
    return null;
  }

  const freeHostnames = environmentCustomDomains?.some(
    domain =>
      !magentoHostMappings?.some(
        mapping => mapping.hostname === domain.hostname
      )
  );

  return (
    <StyledArrayInput
      source={source}
      label='resources.environments.sections.magentoHostMappings'
      helperText={false}
    >
      <SimpleFormIterator
        fullWidth
        disableClear
        disableReordering
        disableRemove
        disableAdd={!freeHostnames}
        addButton={<AddItemButton />}
      >
        <HostnameSelectInput
          source='hostname'
          validate={[required(), validateHostname, unique(source)]}
          fullWidth
        />
        <SelectInput
          source='mageRunType'
          choices={MAGE_RUN_TYPES.map(mageRunType => ({
            id: mageRunType,
            name: mageRunType
          }))}
          defaultValue={MAGE_RUN_TYPES[0]}
          validate={[required()]}
          fullWidth
        />
        <TextInput source='mageRunCode' validate={[required()]} fullWidth />
        <RemoveItemButton />
      </SimpleFormIterator>
    </StyledArrayInput>
  );
};

const RemoveItemButton = props => {
  const { source } = useSimpleFormIterator();
  const { index, remove } = useSimpleFormIteratorItem();
  const currentHostname = useWatch({
    name: `${source}[${index}].hostname`
  });

  if (currentHostname === 'default') {
    return null;
  }

  return (
    <IconButtonWithTooltip
      label='ra.action.remove'
      size='small'
      onClick={() => remove()}
      color='warning'
      {...props}
    >
      <CloseIcon fontSize='small' />
    </IconButtonWithTooltip>
  );
};

export const AddItemButton = props => {
  const { add, source } = useSimpleFormIterator();

  const [customDomains, magentoHostMappings] = useWatch({
    name: ['environmentCustomDomain', source]
  });

  if (
    customDomains?.find(({ hostname }) => !hostname) ||
    magentoHostMappings?.find(({ hostname }) => !hostname)
  ) {
    return null;
  }

  return (
    <IconButtonWithTooltip
      label='ra.action.add'
      size='small'
      onClick={() => add()}
      color='primary'
      {...props}
    >
      <AddIcon fontSize='small' />
    </IconButtonWithTooltip>
  );
};

/**
 *
 * @param {import('react-admin').TextInputProps | import('react-admin').SelectInputProps} props
 * @returns
 */
const HostnameSelectInput = props => {
  const { source } = useSimpleFormIterator();
  const { index } = useSimpleFormIteratorItem();
  const {
    formState: { errors }
  } = useFormContext();
  const t = useTranslate();

  const [
    magentoHostMappings,
    currentHostname,
    environmentCustomDomains = []
  ] = useWatch({
    name: [source, `${source}[${index}].hostname`, 'environmentCustomDomain']
  });

  if (currentHostname === 'default') {
    const error = errors?.[source]?.[index]?.hostname?.message;

    return (
      <FormControl fullWidth>
        <TextField
          label={t(
            `resources.environments.fields.environmentMagentoHostMapping.hostname`
          )}
          value={currentHostname}
          disabled
          helperText={error || '\u200B'}
        />
      </FormControl>
    );
  }

  const choices = environmentCustomDomains
    ?.filter(domain =>
      magentoHostMappings?.every(
        (mapping, i) => mapping.hostname !== domain.hostname || i === index
      )
    )
    .map(domain => ({
      id: domain.hostname,
      name: domain.hostname
    }));

  return <SelectInput {...props} choices={choices} />;
};

const StyledArrayInput = styled(ArrayInput)(({ theme }) => ({
  [`& .${SimpleFormIteratorClasses.line}:not(:first-child)`]: {
    marginTop: theme.spacing(2)
  }
}));

/**
 *
 * @param {string} source
 * @returns
 */
function unique (source) {
  return (value, allValues, input) => {
    const [field, index] = input.source.split('.').reverse();

    const hasDuplicates = allValues[source]?.some(
      (item, i, array) =>
        // Skip validation if the current item has an @id
        !array[Number(index)]?.['@id'] &&
        i !== Number(index) &&
        item[field] === value
    );

    if (hasDuplicates) {
      return 'resources.environments.validation.domain_already_taken';
    }
  };
}

/**
 *
 * @param {string} value
 * @returns
 */
const validateHostname = value => {
  if (value && !value.match(/^[a-z0-9]+([\-\.]{1}[a-z0-9]+)*$/)) {
    return 'resources.environments.validation.invalid_hostname';
  }

  return undefined;
};

// import React from 'react';
import { ProjectCreate } from './Create';
import { ProjectEdit } from './Edit';
import { ProjectList } from './List';
import React from 'react';
import Iconify from '@rc/admin/components/ui/Iconify';
// import { Avatar } from '@rc/admin/components';

// const ProjectIcon = props => {
//   const {
//     item: { name, code, color },
//     ...rest
//   } = props;

//   return (
//     <Avatar
//       code={code || name.substr(0, 2).toUpperCase()}
//       color={color}
//       {...rest}
//     />
//   );
// };

export default {
  name: 'projects',
  create: ProjectCreate,
  edit: ProjectEdit,
  list: ProjectList,
  icon: () => <Iconify icon='solar:folder-bold-duotone' />,
  options: {
    // shouldGetList: true,
    // shouldGetListIcon: ProjectIcon
  }
};

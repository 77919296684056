// import { useEffect } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Drawer from '@mui/material/Drawer';

// import { usePathname } from '../../routes/hooks';

import { useMockedUser } from '../../hooks/use-mocked-user';

import Logo from '../../components/logo';
import Scrollbar from '../../components/scrollbar';
import { NavSectionVertical } from '../../components/nav-section';

import { NAV } from '../config-layout';
// import NavUpgrade from '../common/nav-upgrade';
import { useNavData } from './config-navigation';
import NavToggleButton from '../common/nav-toggle-button';
import { useMediaQuery } from '@mui/material';
import { useSidebarState } from 'react-admin';

// ----------------------------------------------------------------------

export default function NavVertical () {
  const { user } = useMockedUser();

  const [isSidebarOpen, setIsSidebarOpen] = useSidebarState();

  // const pathname = usePathname();

  const lgUp = useMediaQuery(theme => theme.breakpoints.up('lg'));

  const navData = useNavData();

  // useEffect(() => {
  //   if (isSidebarOpen) {
  //     setIsSidebarOpen(false);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column'
        }
      }}
    >
      <Logo sx={{ mt: 3, ml: 4, mb: 1 }} />

      <NavSectionVertical
        data={navData}
        slotProps={{
          currentRole: user?.role
        }}
      />

      <Box sx={{ flexGrow: 1 }} />

      {/* <NavUpgrade /> */}
    </Scrollbar>
  );

  return (
    <Box
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.W_VERTICAL }
      }}
    >
      <NavToggleButton isVertical />

      {lgUp ? (
        <Stack
          sx={{
            height: 1,
            position: 'fixed',
            width: NAV.W_VERTICAL,
            borderRight: theme => `dashed 1px ${theme.palette.divider}`,
            zIndex: theme => theme.zIndex.appBar - 1
          }}
        >
          {renderContent}
        </Stack>
      ) : (
        <Drawer
          open={isSidebarOpen}
          onClose={() => setIsSidebarOpen(false)}
          PaperProps={{
            sx: {
              width: NAV.W_VERTICAL
            }
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}

NavVertical.propTypes = {};

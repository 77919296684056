import React from 'react';
import { TextInput } from 'react-admin';
import { useIsCreated, useIsSubdomain } from './utils';

/**
 *
 * @param {import('react-admin').TextInputProps} props
 * @returns
 */
export const CertificateTextInput = props => {
  if (useIsCreated() || useIsSubdomain()) {
    return null;
  }

  return <TextInput {...props} />;
};

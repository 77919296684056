import { Avatar, Chip, Stack, styled } from '@mui/material';
import {
  RecordContextProvider,
  useGetResourceLabel,
  useTranslate
} from 'react-admin';
import React from 'react';
import { RichTextField } from '@rc/admin/components';

/**
 *
 * @param {object} props
 * @param {object} props.templateProject
 * @param {object} props.projectType
 * @returns
 */
export const ProjectTemplateDetails = props => {
  const { templateProject, projectType } = props;
  const t = useTranslate();
  const getResourceLabel = useGetResourceLabel();

  return (
    <Root width='100%' gap={0.25} alignItems={'center'} sx={{ my: 2 }}>
      <Chip
        avatar={
          <Avatar
            className={classes.avatar}
            alt={t('misc.selected', {
              name: getResourceLabel('template_projects', { smart_count: 1 })
            })}
            src={projectType.logo}
          />
        }
        label={templateProject?.name}
        variant='outlined'
      />
      <RecordContextProvider value={templateProject}>
        <RichTextField source={'description'} />
      </RecordContextProvider>
    </Root>
  );
};

const PREFIX = 'ProjectTemplateDetails';

const classes = {
  avatar: `${PREFIX}-avatar`
};

const Root = styled(Stack)(({ theme }) => ({
  [`& .${classes.avatar}`]: {
    '.MuiAvatar-img': {
      backgroundColor: theme.palette.common.white
    }
  }
}));

import { Typography, styled } from '@mui/material';
import React from 'react';
import { useTranslate } from 'react-admin';
import { useWatch } from 'react-hook-form';
import { createRegExpFromPlainString, validateRegExp } from '../../utils';

export const RegExpWatchField = props => {
  const { source } = props;
  const t = useTranslate();
  const value = useWatch({ name: source });
  const result = getRegExp(value);

  return (
    <StyledTypography component='span' variant='body2'>
      {`${t('resources.env_var_validations.fields.js_regexp_result')}: `}
      <Typography component='code' color={result.message ? 'error' : undefined}>
        {result.message ? t(result.message) : result.toString()}
      </Typography>
    </StyledTypography>
  );
};

const getRegExp = value => {
  if (!value) return '';
  try {
    if (validateRegExp(value)) {
      throw new Error('');
    }
    return createRegExpFromPlainString(value);
  } catch (e) {
    return {
      message: 'resources.env_var_validations.validation.invalid_regexp'
    };
  }
};

const StyledTypography = styled(Typography)(({ theme }) => ({
  '& code': {
    display: 'inline-block',
    padding: '0px 5px',
    backgroundColor: 'rgba(102, 178, 255, 0.15)',
    borderRadius: '5px',
    direction: 'ltr'
  }
}));

import React, { useMemo } from 'react';
import { RecordContextProvider, useTranslate } from 'react-admin';
import { useProjectContext } from '@rc/admin/context';
import { useTeam } from '@rc/admin/hooks';
import { Box, Stack, styled } from '@mui/material';
import { EnvVarFields } from '@rc/admin/components/domain/envVar';
import { useWatch } from 'react-hook-form';

export const InheritedEnvVarFields = props => {
  const { source } = props;
  const t = useTranslate();
  const [id, envVars] = useWatch({ name: ['id', source] });
  const { project, isLoading: isProjectLoading } = useProjectContext();
  const { team, organisation, isLoading: isTeamLoading } = useTeam({
    teamId: project?.team
  });

  const isLoading = isProjectLoading || isTeamLoading;

  const envVarsAggregated = useMemo(() => {
    const { projectEnvVar: projectEnvVars, id: projectId } = project;
    const { teamEnvVar: teamEnvVars, id: teamId } = team;
    const {
      organisationEnvVar: organisationEnvVars,
      id: organisationId
    } = organisation;

    return [
      { id, envVars },
      { id: projectId, envVars: projectEnvVars },
      { id: teamId, envVars: teamEnvVars },
      { id: organisationId, envVars: organisationEnvVars }
    ];
  }, [envVars, id, organisation, project, team]);

  if (isLoading) {
    return t('misc.loading', { dots: '...' });
  }

  return (
    <Root>
      <Box className={classes.item}>
        <RecordContextProvider value={project}>
          <EnvVarFields
            envVars={envVarsAggregated}
            source={'projectEnvVar'}
            label={t('resources.env_vars.label.inherited_env_vars', {
              entity: `project`
            })}
            fullWidth
            emptyLabel={t('resources.env_vars.label.no_inherited_env_vars', {
              entity: `project`
            })}
          />
        </RecordContextProvider>
      </Box>
      <Box className={classes.item}>
        <RecordContextProvider value={team}>
          <EnvVarFields
            envVars={envVarsAggregated}
            source={'teamEnvVar'}
            label={t('resources.env_vars.label.inherited_env_vars', {
              entity: `team`
            })}
            fullWidth
            emptyLabel={t('resources.env_vars.label.no_inherited_env_vars', {
              entity: `team`
            })}
          />
        </RecordContextProvider>
      </Box>
      <Box className={classes.item}>
        <RecordContextProvider value={organisation}>
          <EnvVarFields
            envVars={envVarsAggregated}
            source={'organisationEnvVar'}
            label={t('resources.env_vars.label.inherited_env_vars', {
              entity: `organisation`
            })}
            fullWidth
            emptyLabel={t('resources.env_vars.label.no_inherited_env_vars', {
              entity: `organisation`
            })}
          />
        </RecordContextProvider>
      </Box>
    </Root>
  );
};

const PREFIX = 'InheritedEnvVarFields';

const classes = {
  item: `${PREFIX}-item`
};

const Root = styled(Stack)(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(1),

  [`& > :first-child`]: {
    marginBottom: theme.spacing(3)
  },

  [`& .${classes.item}`]: {
    marginTop: theme.spacing(1.5)
  }
}));

import { useCallback, useEffect } from 'react';
import { useGetList, useTranslate } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { useRequiredComponents } from '.';
import { PREFETCH_PAGINATION, VERSION_SORT } from '@rc/admin/constants';

export const useValidateEnvironmentComponent = (props) => {
  const { source } = props;
  const { setError, clearErrors, watch } = useFormContext();
  const translate = useTranslate();

  const {
    requiredComponents: requiredComponentIds,
    isLoading: isRequiredComponentsLoading,
  } = useRequiredComponents();

  const { data: componentVersions, isLoading: isComponentVersionsLoading } =
    useGetList('component_versions', {
      pagination: PREFETCH_PAGINATION,
      sort: VERSION_SORT,
    });

  const { data: componentsData, isLoading: isComponentsLoading } =
    useGetList('components');

  const isLoading =
    isComponentVersionsLoading ||
    isComponentsLoading ||
    isRequiredComponentsLoading;

  const validateFn = useCallback(
    (environmentComponents) => {
      if (!isLoading) {
        const selectedComponents = environmentComponents
          .filter((envComponent) => !!envComponent?.componentVersion)
          .map((envComponent) => {
            const { componentVersion: componentVersionId } = envComponent;
            const componentVersion = componentVersions.find(
              (componentVersion) => componentVersion.id === componentVersionId
            );
            return componentVersion?.component;
          });

        const missingComponents = requiredComponentIds.filter(
          (componentId) => !selectedComponents.some((id) => id === componentId)
        );

        if (missingComponents.length) {
          const missingComponentsNames = missingComponents.map(
            (componentId) => {
              const missingComponent = componentsData.find(
                (component) => component.id === componentId
              );
              return missingComponent?.name;
            }
          );

          return {
            type: 'validate',
            message: translate(
              'resources.components.validation.missing_required',
              {
                smart_count: missingComponents.length,
                list: missingComponentsNames.filter((v) => v).join(', '),
              }
            ),
          };
        }
      }
    },
    [
      isLoading,
      requiredComponentIds,
      componentVersions,
      translate,
      componentsData,
    ]
  );

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      const shouldValidate =
        name === source || new RegExp(`${source}.[0-9]*.`).test(name);

      if (shouldValidate) {
        const error = validateFn(value[source]);
        if (error) {
          setError(source, error);
        } else {
          clearErrors(source);
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [clearErrors, setError, source, validateFn, watch]);
};

import React, { useCallback, useEffect, useRef } from 'react';
import { useTranslate } from 'react-admin';
import clsx from 'clsx';
import {
  Step,
  StepLabel,
  Stepper,
  styled,
  stepConnectorClasses,
  stepLabelClasses,
  Box,
  Stack,
  IconButton
} from '@mui/material';
import { ProgressBarStepIcon } from './StepIcon';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';

/**
 *
 * @param {Object} props
 * @param {import('@rc/admin/types/progressbar.types').ProgressDetails} props.details
 * @param {import('@rc/admin/types/progressbar.types').OngoingProcessInfo[]} props.visibleProgresses
 * @returns
 */
export const EnvironmentProgressBarStepper = props => {
  const { details, visibleProgresses } = props;
  const t = useTranslate();
  const stepperRef = useRef(null);
  const highlightedIndexRef = useRef(null);

  const handleHighlightStep = useCallback(
    index => {
      highlightedIndexRef.current = Math.min(index, details.processCount - 3);
      const steps = stepperRef.current?.querySelectorAll('.MuiStep-root');

      stepperRef.current?.scrollTo({
        left: 0,
        top: steps[Math.max(index - 1, 0)]?.offsetTop - 4,
        behavior: 'smooth'
      });
    },
    [details.processCount]
  );

  const onUpClicked = useCallback(() => {
    handleHighlightStep(Math.max(highlightedIndexRef.current - 1, 1));
  }, [handleHighlightStep]);

  const onDownClicked = useCallback(() => {
    handleHighlightStep(
      Math.min(highlightedIndexRef.current + 1, details.processCount - 1)
    );
  }, [handleHighlightStep, details.processCount]);

  useEffect(() => {
    handleHighlightStep(details.itemProcessed - 1);
  }, [details.itemProcessed, handleHighlightStep]);

  return (
    <StyledBox>
      <Stack className={ProgressBarStepperClasses.arrows}>
        <IconButton size='small'>
          <KeyboardArrowUp onClick={onUpClicked} />
        </IconButton>
        <IconButton size='small'>
          <KeyboardArrowDown onClick={onDownClicked} />
        </IconButton>
      </Stack>
      <Stepper
        ref={stepperRef}
        className={ProgressBarStepperClasses.stepper}
        orientation='vertical'
        activeStep={Math.max(details.itemProcessed - 1, 0)}
      >
        {details.progressList.map((progress, index, progressList) => {
          const isVisible = visibleProgresses.includes(progress);
          {
            /* const isActive =
                    index !== 0 && index !== progressList.length - 1; */
          }

          return (
            <Step
              key={`step-${progress.state}`}
              alternativeLabel
              className={clsx(
                !isVisible && ProgressBarStepperClasses.hiddenStep,
                (!isVisible ||
                  visibleProgresses[visibleProgresses.length - 1] ===
                    progress) &&
                  ProgressBarStepperClasses.hiddenConnector
              )}
            >
              <StepLabel StepIconComponent={ProgressBarStepIcon}>
                {t(`resources.states.names.${progress.state}`)}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </StyledBox>
  );
};

const ProgressBarStepperClasses = {
  stepper: 'ProgressBarStepper-stepper',
  hiddenStep: 'ProgressBarStepper-hiddenStep',
  hiddenConnector: 'ProgressBarStepper-hiddenConnector',
  arrows: 'ProgressBarStepper-arrows'
};

const StyledBox = styled(Box)(({ theme }) => ({
  position: 'relative',

  ['&:not(:hover)']: {
    [`& .${ProgressBarStepperClasses.arrows}`]: {
      opacity: 0.5
    }
  },

  [`& .${ProgressBarStepperClasses.arrows}`]: {
    position: 'absolute',
    top: 0,
    bottom: 8,
    right: 4,
    justifyContent: 'space-between',
    transition: 'opacity 0.3s'
  },

  [`& .${ProgressBarStepperClasses.stepper}`]: {
    marginBottom: theme.spacing(1.5),
    maxHeight: 90,
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },

  [`& .${ProgressBarStepperClasses.hiddenStep}, & .${ProgressBarStepperClasses.hiddenConnector} + .${stepConnectorClasses.root}`]:
    {
      // display: 'none'
    },

  [`& .${stepLabelClasses.root}`]: {
    padding: `${theme.spacing(0)} 0`,
    cursor: 'default',

    [`& .${stepLabelClasses.label}`]: {
      [`&.${stepLabelClasses.active}`]: {
        color: theme.palette.primary.main,
        fontWeight: theme.typography.fontWeightMedium
      },
      [`&.${stepLabelClasses.completed}`]: {
        color: 'inherit'
      }
    }
  },

  [`& .${stepConnectorClasses.root}`]: {
    marginLeft: '8px',
    cursor: 'default',
    [`& .${stepConnectorClasses.line}`]: {
      minHeight: '8px'
    }
  }
}));

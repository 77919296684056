import React from 'react';
import { useTranslate } from 'react-admin';
import {
  CircularProgress,
  Grid,
  Skeleton,
  styled,
  Tooltip,
  Typography
} from '@mui/material';
import { usePriceFormatter } from '@rc/admin/utils/domain/costs';
import { Iconify } from '../../ui';

/**
 *
 * @param {object} props
 * @param {import('react').ReactNode} props.icon
 * @param {string} props.label
 * @param {string} props.title
 * @param {{amount: number, currency: string}} props.price
 * @param {number} props.usage
 * @param {string} props.unit
 * @param {string} props.tooltipText
 * @param {object} props.labelContainerProps
 * @param {object} props.priceContainerProps
 * @param {boolean} props.isLoading
 * @returns {import('react').ReactNode}
 */
export const Row = props => {
  const {
    icon,
    label,
    title,
    price = 0,
    usage = 0,
    unit,
    tooltipText,
    labelContainerProps,
    priceContainerProps,
    isLoading
  } = props;
  const t = useTranslate();

  return (
    <StyledGrid container>
      <LabelGridItem
        {...labelContainerProps}
        title={title || ''}
        label={label && t(label)}
        icon={icon}
      />
      {!isLoading ? (
        <PriceGridItem
          {...priceContainerProps}
          price={price}
          usage={usage}
          unit={unit}
          tooltipText={tooltipText}
          isLoading={isLoading}
        />
      ) : (
        <Skeleton width={32} />
      )}
    </StyledGrid>
  );
};

const LabelGridItem = props => {
  const { label, title, icon, ...rest } = props;

  return (
    <Grid
      className={classes.label}
      item
      xs={6}
      title={title || label}
      {...rest}
    >
      {icon}
      {label && <Typography variant='body2'>{label}</Typography>}
    </Grid>
  );
};

const PriceGridItem = props => {
  const {
    children = null,
    price,
    unit,
    usage,
    tooltipText,
    isLoading,
    ...rest
  } = props;
  const formatter = usePriceFormatter(price?.currency);
  const value = price ? price.amount || 0 : usage;

  return (
    <Grid className={classes.value} item xs={6} {...rest}>
      {isLoading ? (
        <CircularProgress size={16} />
      ) : (
        <Typography variant='body2' className={classes.valueText}>
          <span>
            {value === 0 && !unit ? '-' : formatter.format(value)}
            <span className={classes.unitText}>{unit ? ` ${unit}` : ''}</span>
          </span>
          {children}
        </Typography>
      )}
      {tooltipText && (
        <Tooltip
          className={classes.tooltip}
          title={tooltipText}
          placement='right'
        >
          <Iconify icon='solar:info-circle-outline' color='primary' />
        </Tooltip>
      )}
    </Grid>
  );
};

const PREFIX = 'CostsRow';

const classes = {
  label: `${PREFIX}-label`,
  value: `${PREFIX}-value`,
  valueText: `${PREFIX}-valueText`,
  unitText: `${PREFIX}-unitText`,
  tooltip: `${PREFIX}-tooltip`
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  flexDirection: 'row',
  flexWrap: 'nowrap',

  [`& .${classes.label}`]: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: theme.spacing(1)
  },

  [`& .${classes.value}`]: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: theme.spacing(1),
    overflow: 'hidden'
  },

  [`& .${classes.valueText}`]: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },

  [`& .${classes.unitText}`]: {
    fontSize: theme.typography.fontSize,
    marginLeft: theme.spacing(0.25)
  },

  [`& .${classes.tooltip}`]: {
    marginLeft: theme.spacing(1)
  }
}));

Row.defaultProps = {
  labelContainerProps: {},
  priceContainerProps: {}
};

import { Typography } from '@mui/material';
import React from 'react';

/**
 *
 * @param {import('@mui/material').TypographyProps} props
 * @returns
 */
export const SectionHelper = props => {
  return (
    <Typography
      variant='body2'
      color={theme =>
        theme.palette.grey[theme.palette.mode === 'light' ? 600 : 300]
      }
      {...props}
    />
  );
};

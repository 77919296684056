import React, { useMemo } from 'react';
import { useLocation } from 'react-router';
import {
  Create,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  ReferenceField,
  TextField,
  ReferenceArrayInput,
  SelectArrayInput
} from 'react-admin';
import { styled } from '@mui/material';
import { OneColumnFormStyles } from '@rc/admin/theme/styles';
import { ProjectTypeComponentVersionInput } from './components';

export const ProjectTypeVersionCreate = () => {
  const { search } = useLocation();

  const initialProjectTypeId = useMemo(
    () => new URLSearchParams(search).get('projectType'),
    [search]
  );

  return (
    <StyledCreate>
      <SimpleForm
        defaultValues={{ isEnabled: true, projectType: initialProjectTypeId }}
        className={classes.form}
      >
        {!initialProjectTypeId ? (
          <ReferenceInput source={'projectType'} reference={'project_types'}>
            <SelectInput optionText={'name'} fullWidth />
          </ReferenceInput>
        ) : (
          <ReferenceField
            source={'projectType'}
            reference={'project_types'}
            record={{ projectType: initialProjectTypeId }}
          >
            <TextField source={'name'} fullWidth />
          </ReferenceField>
        )}
        <TextInput source={'version'} fullWidth />
        <ReferenceArrayInput
          source={'requiredComponent'}
          reference={'components'}
          label={'resources.project_type_versions.fields.requiredComponent'}
          helperText={
            'resources.project_type_versions.fields.requiredComponentHelper'
          }
        >
          <SelectArrayInput optionText={'name'} fullWidth />
        </ReferenceArrayInput>
        <ProjectTypeComponentVersionInput
          source={'componentVersions'}
          fullWidth
        />
      </SimpleForm>
    </StyledCreate>
  );
};

const PREFIX = 'ProjectTypeVersionCreate';

const classes = {
  form: `${PREFIX}-form`
};

const StyledCreate = styled(Create)(({ theme }) => ({
  [`& .${classes.form}`]: {
    ...OneColumnFormStyles(theme)
  }
}));

import { useMemo } from 'react';
import { useGetOrganisationName } from '@rc/admin/hooks/domain';

export const useOrganisationsWithName = props => {
  const { organisations } = props;
  const getOrganisationName = useGetOrganisationName();

  const data = useMemo(() => {
    return organisations?.map(organisation => {
      const organisationName = getOrganisationName(organisation);

      return {
        ...organisation,
        customName: organisationName
      };
    });
  }, [getOrganisationName, organisations]);

  return { data, isLoading: !data };
};

import React, { useRef, useLayoutEffect } from 'react';
import {
  styled,
  Portal,
  Slide,
  ClickAwayListener,
  Container
} from '@mui/material';
import clsx from 'clsx';

export const SidebarSubMenu = props => {
  const { isOpen, children, onClickAway } = props;

  const container = useRef();

  useLayoutEffect(() => {
    container.current = document.getElementsByTagName('main')[0];
  }, []);

  if (!container.current) {
    return null;
  }

  return (
    <Portal container={container.current}>
      <Slide direction='right' in={isOpen} mountOnEnter unmountOnExit>
        <Root
          classes={{
            root: clsx(classes.root, isOpen ? classes.open : classes.closed)
          }}
        >
          <ClickAwayListener onClickAway={onClickAway}>
            <div className={classes.content}>{children}</div>
          </ClickAwayListener>
        </Root>
      </Slide>
    </Portal>
  );
};

const PREFIX = 'SidebarSubMenu';

const classes = {
  root: `${PREFIX}-root`,
  open: `${PREFIX}-open`,
  closed: `${PREFIX}-closed`,
  content: `${PREFIX}-content`
};

const Root = styled(Container)(({ theme }) => ({
  position: 'fixed',
  top: theme.spacing(11), // AppBar height
  left: `calc(${theme.sidebar.width}px + ${theme.spacing(0)})`,
  width: `calc(${theme.sidebar.width}px + ${theme.spacing(2)})`,
  padding: `${theme.spacing(3)} ${theme.spacing(2)}`,
  height: '100%',
  zIndex: 2,
  boxShadow: theme.shadows[3],
  backgroundColor: theme.palette.secondary.dark,

  [`&.${classes.open}`]: {
    '& $content': {
      transition: 'opacity 0.1s ease-in-out'
    }
  },

  [`&.${classes.closed}`]: {
    '& $content': {
      opacity: 0,
      transition: 'opacity 0.1s ease-in-out'
    }
  },

  [`& .${classes.content}`]: {}
}));

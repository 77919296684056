import React from 'react';
import { Edit, EditClasses, useResourceContext } from 'react-admin';
import { Card } from '@mui/material';
import { useIdParam } from '@rc/admin/hooks/useIdParam';
import useMercureSubscription from '@rc/admin/moduleOverrides/useMercureSubscription';

/**
 *
 * @param {import('react-admin').EditProps} props
 *
 */
export const EditWithMercure = props => {
  return <Edit {...props} component={MercureSubscriberForEdit} />;
};

/**
 *
 * @param {object} props
 * @param {any} props.component
 * @param {(data: import('react-admin').RaRecord) => void} props.onReceived
 * @returns
 */
export const MercureSubscriberForEdit = props => {
  const { component: Component = Card, onReceived, ...rest } = props;
  const resource = useResourceContext();
  const { id } = useIdParam();

  useMercureSubscription(resource, id, { onReceived });

  return <Component className={EditClasses.card} {...rest} />;
};

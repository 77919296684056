import React from 'react';
import {
  ReferenceField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField
} from 'react-admin';
import { Breadcrumbs, EditTitle } from '@rc/admin/components';

export const TeamShow = () => (
  <Show actions={<Breadcrumbs />} title={<EditTitle />}>
    <TabbedShowLayout>
      <Tab label='resources.teams.tabs.basic'>
        <TextField source={'name'} />
        <ReferenceField source='organisation' reference='organisations'>
          <TextField source={'name'} />
        </ReferenceField>
      </Tab>
    </TabbedShowLayout>
  </Show>
);

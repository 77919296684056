import React, { useMemo } from 'react';
import { useTheme } from '@mui/material';
import {
  RichTextField as RaRichTextField,
  RecordContextProvider,
  useRecordContext
} from 'react-admin';
import { get } from 'lodash';

/**
 * @param {import('react-admin').RichTextFieldProps} props
 * @returns
 */
export const RichTextField = props => {
  const { source } = props;
  const record = useRecordContext(props);
  const theme = useTheme();

  const modifiedRecord = useMemo(() => {
    const value = get(record, source)?.toString();

    let convertedValue = rgbToHex(value);

    convertedValue.match(/#[0-9a-fA-F]{6}/g)?.forEach(color => {
      let invertedColor = color;
      if (theme.palette.mode === 'light' && isColorTooLight(color)) {
        invertedColor = invertColor(color);
      } else if (theme.palette.mode === 'dark' && isColorTooDark(color)) {
        invertedColor = invertColor(color);
      }

      convertedValue = convertedValue.replace(color, invertedColor);
    });

    return Object.assign({}, record, { [source]: convertedValue });
  }, [record, source, theme.palette.mode]);

  return (
    <RecordContextProvider value={modifiedRecord}>
      <RaRichTextField {...props} />
    </RecordContextProvider>
  );
};

function rgbToHex (string) {
  return string.replace(
    /rgb\(\d+,\s*\d+,\s*\d+\)/g,
    rgbString =>
      '#' +
      rgbString
        .match(/\b(\d+)\b/g)
        .map(digit =>
          parseInt(digit)
            .toString(16)
            .padStart(2, '0')
            .toUpperCase()
        )
        .join('')
  );
}

const hexToRgb = hex => {
  if (hex.startsWith('#')) {
    hex = hex.slice(1);
  }
  if (hex.length === 3) {
    hex = hex
      .split('')
      .map(char => char + char)
      .join('');
  }
  const bigint = parseInt(hex, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  return { r, g, b };
};

const getLuminance = (r, g, b) => {
  const a = [r, g, b].map(value => {
    value /= 255;
    return value <= 0.03928
      ? value / 12.92
      : Math.pow((value + 0.055) / 1.055, 2.4);
  });
  return 0.2126 * a[0] + 0.7152 * a[1] + 0.0722 * a[2];
};

const isColorTooLight = hex => {
  const { r, g, b } = hexToRgb(hex);
  const luminance = getLuminance(r, g, b);
  return luminance > 0.8; // Adjust the threshold as needed
};

const isColorTooDark = hex => {
  const { r, g, b } = hexToRgb(hex);
  const luminance = getLuminance(r, g, b);
  return luminance < 0.2; // Adjust the threshold as needed
};

const invertColor = hex => {
  const { r, g, b } = hexToRgb(hex);
  const invertedR = (255 - r).toString(16).padStart(2, '0');
  const invertedG = (255 - g).toString(16).padStart(2, '0');
  const invertedB = (255 - b).toString(16).padStart(2, '0');
  return `#${invertedR}${invertedG}${invertedB}`;
};

import React from 'react';
import {
  Logout,
  useUserMenu,
  UserMenu as RaUserMenu,
  useTheme,
  useGetIdentity,
  useTranslate,
  useGetOne
} from 'react-admin';
import { Link } from 'react-router-dom';
import DarkMode from '@mui/icons-material/Brightness4';
import LightMode from '@mui/icons-material/Brightness7';
import {
  styled,
  ListItemIcon,
  ListItemText,
  Divider,
  Typography,
  Box,
  Stack,
  MenuItem,
  typographyClasses
} from '@mui/material';
import { Avatar, Iconify } from '@rc/admin/components';
import { createAvatarText } from '@rc/admin/utils';
import { useDebugModeContext } from '@rc/admin/context';

/**
 *
 * @param {import('@mui/material').ListItemButtonProps} props
 * @returns
 */
const UserMenuItem = props => {
  const { to, label, icon: Icon, ...rest } = props;
  const { onClose } = useUserMenu();

  return (
    <MenuItem
      {...rest}
      onClick={() => {
        onClose();
      }}
      component={Link}
      to={to}
      sx={theme => ({ paddingLeft: theme.spacing(1) })}
    >
      {Icon && (
        <ListItemIcon>
          <Icon />
        </ListItemIcon>
      )}
      <ListItemText
        sx={theme => ({
          [`& .${typographyClasses.root}`]: {
            fontWeight: theme.typography.fontWeightRegular
          }
        })}
      >
        {label}
      </ListItemText>
    </MenuItem>
  );
};

export const ToggleThemeMenu = props => {
  const t = useTranslate();
  const [theme, setTheme] = useTheme();
  const isLightTheme = theme === 'light';

  const handleToggleTheme = () => {
    setTheme(isLightTheme ? 'dark' : 'light');
  };

  return (
    <MenuItem {...props} onClick={handleToggleTheme}>
      <ListItemIcon className={classes.listItemIcon}>
        {isLightTheme ? <DarkMode /> : <LightMode />}
      </ListItemIcon>
      <ListItemText
        sx={theme => ({
          [`& .${typographyClasses.root}`]: {
            fontWeight: theme.typography.fontWeightRegular
          }
        })}
      >
        {t('components.userMenu.switch_theme', {
          theme: isLightTheme ? 'dark' : 'light'
        })}
      </ListItemText>
    </MenuItem>
  );
};

const ToggleDebugMode = props => {
  const [isDebugMode, toggleDebugMode] = useDebugModeContext();

  return (
    <MenuItem {...props} onClick={toggleDebugMode}>
      <ListItemIcon className={classes.listItemIcon}>
        <Iconify
          icon={
            isDebugMode
              ? 'solar:bug-minimalistic-bold-duotone'
              : 'solar:bug-minimalistic-line-duotone'
          }
        />
      </ListItemIcon>
      <ListItemText
        sx={theme => ({
          [`& .${typographyClasses.root}`]: {
            fontWeight: theme.typography.fontWeightRegular
          }
        })}
      >{`Turn ${isDebugMode ? 'off' : 'on'} debug mode`}</ListItemText>
    </MenuItem>
  );
};

const UserMenu = props => {
  const { identity } = useGetIdentity();

  const { data: user } = useGetOne(
    'users',
    { id: `/api/users/${identity?.id}` },
    { enabled: !!identity }
  );

  if (!identity) {
    return null;
  }

  const label = user?.username || user?.fullname;

  return (
    <>
      <StyledRaUserMenu
        {...props}
        className={classes.root}
        icon={
          <Avatar
            code={label ? createAvatarText(label) : '  '}
            sx={{ width: 34, height: 34 }}
          />
        }
      >
        <Box id='user-menu' sx={{ p: 0 }}>
          <Box sx={{ p: 2, pb: 1.5 }}>
            <Typography variant='subtitle2' noWrap>
              {label}
            </Typography>

            <Typography variant='body2' sx={{ color: 'text.secondary' }} noWrap>
              {user?.email}
            </Typography>
          </Box>

          <Divider sx={{ borderStyle: 'dashed' }} />

          <Stack sx={{ p: 1 }}>
            <UserMenuItem
              id='profile-menu-item'
              to={'/account'}
              label={'Account settings'}
              // icon={ProfileIcon}
            />
            {/* <UserMenuItem
              id='billing-menu-item'
              to={'/account/billing'}
              label={t('page.billing')}
              // icon={BillingIcon}
            /> */}
          </Stack>
          <Divider sx={{ borderStyle: 'dashed' }} />
          <Stack sx={{ p: 1 }}>
            <ToggleThemeMenu />
            <ToggleDebugMode />
            <Logout
              sx={theme => ({
                [`& .${typographyClasses.root}`]: {
                  fontWeight: theme.typography.fontWeightRegular
                }
              })}
            />
          </Stack>
        </Box>
      </StyledRaUserMenu>
    </>
  );
};

const PREFIX = 'UserMenu';

const classes = {
  root: `${PREFIX}-root`,
  listItemIcon: `${PREFIX}-listItemIcon`
};

// const BUTTON_COLOR = 'primary';

const StyledRaUserMenu = styled(RaUserMenu)(({ theme }) => ({
  // [`&.${classes.root}`]: {
  //   alignItems: 'center',
  //   borderRadius: '27px',
  //   transition: 'all .2s ease-in-out',
  //   // borderColor: theme.palette[BUTTON_COLOR].light,
  //   // backgroundColor: theme.palette[BUTTON_COLOR].light,
  //   // '&[aria-controls="menu-list-grow"], &:hover': {
  //   //   borderColor: theme.palette[BUTTON_COLOR].main,
  //   //   background: `${theme.palette[BUTTON_COLOR].main}!important`,
  //   //   color: theme.palette[BUTTON_COLOR].light,
  //   //   '& svg': {
  //   //     color: theme.palette[BUTTON_COLOR].light
  //   //   }
  //   // },
  //   '& > .MuiIconButton-root': {
  //     // padding: theme.spacing(1),
  //     padding: 0
  //     // '&:hover': {
  //     //   color: theme.palette[BUTTON_COLOR].light
  //     // }
  //   }
  // },
  // [`& .${classes.listItemIcon}`]: {
  //   minWidth: 36
  // }
}));

export default UserMenu;

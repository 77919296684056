import React from 'react';
import { InvitationList } from './List';
import { InvitationCreate } from './Create';
import { InvitationEdit } from './Edit';
import { Iconify } from '@rc/admin/components';

export default {
  name: 'invitations',
  list: InvitationList,
  create: InvitationCreate,
  edit: InvitationEdit,
  icon: () => <Iconify icon='solar:user-plus-rounded-bold-duotone' />
};

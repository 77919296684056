import React from 'react';
import PropTypes from 'prop-types';
import {
  useGetResourceLabel,
  useResourceContext,
  useTranslate
} from 'react-admin';
import { styled, Box, Typography } from '@mui/material';
import { Inbox } from '@mui/icons-material';
import clsx from 'clsx';

export const Empty = props => {
  const { className } = props;
  const resource = useResourceContext();
  const getResourceLabel = useGetResourceLabel();
  const t = useTranslate();

  return (
    <StyledBox className={clsx(EmptyClasses.root, className)}>
      <Inbox className={EmptyClasses.icon} />
      <Typography variant='body2'>
        {t('ra.page.empty', { name: getResourceLabel(resource, 2) })}
      </Typography>
    </StyledBox>
  );
};

const PREFIX = 'Empty';

export const EmptyClasses = {
  root: `${PREFIX}-root`,
  icon: `${PREFIX}-icon`
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${EmptyClasses.root}`]: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(2)
  },

  [`& .${EmptyClasses.icon}`]: {
    width: '3em',
    height: '3em'
  }
}));

Empty.defaultProps = {
  className: ''
};

Empty.propTypes = {
  className: PropTypes.string
};

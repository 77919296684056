import { useEffect, useState } from 'react';

const getWindowSize = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return { width, height };
};

/**
 * Get the current window size
 * @param {function} callback
 * @returns {object}
 */
export const useWindowSize = callback => {
  const [windowSize, setWindowSize] = useState(getWindowSize);

  useEffect(() => {
    const handleResize = () => {
      const newSize = getWindowSize();
      setWindowSize(newSize);
      callback?.(newSize);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  return windowSize;
};

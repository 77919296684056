import React from 'react';
import get from 'lodash/get';
import { Typography } from '@mui/material';
import { useRecordContext, sanitizeFieldRestProps } from 'react-admin';

const ResourceField = props => {
  const { className, source, unit, format, ...rest } = props;

  const record = useRecordContext(props);
  const value = get(record, source);

  return (
    <Typography
      component='span'
      variant='body2'
      className={className}
      {...sanitizeFieldRestProps(rest)}
    >
      <span>{format(value)}</span>
      {unit && <span>{' ' + unit}</span>}
    </Typography>
  );
};

ResourceField.defaultProps = {
  format: v => v
};

export default ResourceField;

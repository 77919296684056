import '@stylesheet/password-strength.scss';

import React, { useState } from 'react';
import _ from 'lodash';
import zxcvbn from 'zxcvbn';
import { useTranslate } from 'react-admin';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  linearProgressClasses,
  styled,
  Typography,
  useTheme
} from '@mui/material';
import { CheckCircle } from '@mui/icons-material';

/**
 *
 * @param {import('@mui/material').BoxProps & { password: string }} props
 * @returns
 */
export const PasswordStrengthMeter = props => {
  const { password, ...rest } = props;
  const t = useTranslate();
  const [isOpened, setIsOpened] = useState(false);
  const theme = useTheme();

  const testedResult = zxcvbn(password);
  const { percentage, label } = flatData(testedResult);

  const color = getColorVariant(label);

  return (
    <Box className='password-strength-meter' {...rest}>
      <StyledLinearProgress
        variant='determinate'
        value={percentage}
        color={color}
      />
      <Typography
        variant='subtitle2'
        gutterBottom
        component='div'
        color={theme.palette[color]?.dark}
        fontWeight={600}
        mt={0.5}
      >
        {!!password && label}
        {/* <IconButton
          aria-label='delete'
          size='small'
          onClick={() => setIsOpened(!isOpened)}
        >
          <Info fontSize={16} />
        </IconButton> */}
      </Typography>

      <Dialog open={isOpened} onClose={() => setIsOpened(!isOpened)}>
        <DialogTitle>
          {t('pages.register.password_strength.helper')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText component='div'>
            <Typography
              sx={{ mt: 2 }}
              variant='subtitle2'
              gutterBottom
              component='div'
            >
              {_.isEmpty(testedResult.feedback.suggestions) ? (
                <Typography
                  sx={{ textAlign: 'center' }}
                  variant='body2'
                  component='div'
                  gutterBottom
                >
                  <CheckCircle fontSize={'large'} />
                  <br />
                  {t('pages.register.password_strength.empty')}
                </Typography>
              ) : (
                testedResult.feedback.suggestions.map(suggestion => (
                  <div key={_.random(1.1, 10.0)}>
                    {suggestion}
                    <br />
                  </div>
                ))
              )}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsOpened(!isOpened)} autoFocus>
            {t('action.ok')}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

/**
 *
 * @param {string} label - Weak, Fair, Good, Strong, Unacceptable
 * @returns {string} - Color variant
 */
function getColorVariant (label) {
  switch (label) {
    case 'Fair':
      return 'warning';
    case 'Good':
      return 'info';
    case 'Strong':
      return 'success';
    case 'Weak':
      return 'error';
    default:
      return undefined;
  }
}

const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800]
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5
  }
}));

/**
 *
 * @param {any} testedResult
 * @returns {
 *  percentage: number,
 *  label: string,
 * }
 */
const flatData = testedResult => {
  switch (testedResult.score) {
    case 1:
      return {
        percentage: 25,
        label: 'Weak'
      };
    case 2:
      return {
        percentage: 50,
        label: 'Fair'
      };
    case 3:
      return {
        percentage: 75,
        label: 'Good'
      };
    case 4:
      return {
        percentage: 100,
        label: 'Strong'
      };
    default:
      return {
        percentage: 0,
        label: 'Unacceptable'
      };
  }
};

import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Link,
  ListContextProvider,
  useGetList,
  useSidebarState,
  useTranslate
} from 'react-admin';
import {
  Box,
  CardContent,
  Stack,
  Typography,
  cardClasses,
  cardContentClasses,
  styled
  // useTheme
} from '@mui/material';
import { useUser } from '@rc/admin/hooks';
import {
  ProjectTypeCardList,
  ProjectTypeCardListClasses
} from '@rc/admin/resources/project/components';
import { NAME_SORT, PREFETCH_PAGINATION } from '@rc/admin/constants';
// import { FeatureTour, WelcomeTour } from './WelcomeTour.js';

export const Welcome = () => {
  const { user } = useUser();
  const t = useTranslate();
  // const theme = useTheme();
  const navigate = useNavigate();
  const [isSidebarOpen] = useSidebarState();

  const sort = { field: 'name', order: 'ASC' };
  const { data: projectTypes, isLoading, isFetching, total } = useGetList(
    'project_types',
    {
      sort: NAME_SORT,
      pagination: PREFETCH_PAGINATION
    }
  );

  return (
    <StyledCardContent className={classes.content}>
      {/* <FeatureTour
        tour={WelcomeTour}
        timeout={1000}
        shouldShow={theme.breakpoints.up('md')}
      /> */}
      <Typography className={classes.title} variant='h1' mb={1}>
        {t('pages.welcome.welcome') + ' '}
        <Typography
          component={'span'}
          variant='h1'
          sx={{
            display: 'inline',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            backgroundImage:
              '-webkit-linear-gradient(227deg, #7950f2 0%, #228be6 100%)'
          }}
        >
          {user?.username}
          {'.'}
        </Typography>
      </Typography>
      <Typography className={classes.subtitle1} variant='h1' component={'p'}>
        {t('pages.welcome.subtitle')}
      </Typography>
      <Typography variant='h5' fontWeight={500} mt={5} mb={2}>
        {t('pages.welcome.subtitle2')}
      </Typography>

      <Box
        id='welcome-create-project-from-template'
        className={classes.projectTypes}
      >
        <ListContextProvider
          value={{
            data: projectTypes,
            isLoading: isLoading,
            isFetching: isFetching,
            total: total,
            resource: 'project_types',
            sort,
            page: 1
          }}
        >
          <ProjectTypeCardList
            id={'project-type-version'}
            source='project_type'
            hideVersionSelector
            itemGridSizes={{
              xs: 12,
              sm: isSidebarOpen ? 12 : 6,
              md: isSidebarOpen ? 6 : 4,
              lg: 4
            }}
            onSelectedChanged={({ id }) =>
              navigate('/projects/create/from-template', {
                state: { projectTypeId: id }
              })
            }
          />
        </ListContextProvider>
      </Box>

      <Stack
        id='welcome-create-project-from-scratch'
        flexDirection='row'
        alignItems='center'
        gap={0.75}
        mt={2}
      >
        <Typography variant='h5' fontWeight={500}>
          {t('pages.welcome.subitle_from_scratch')}{' '}
          <Link
            variant='inherit'
            color='primary'
            fontWeight={600}
            to={`/projects/create/from-scratch`}
          >
            {t('pages.welcome.button_from_scratch')}
          </Link>
        </Typography>
      </Stack>
    </StyledCardContent>
  );
};

const PREFIX = 'Welcome';

const classes = {
  content: `${PREFIX}-content`,
  title: `${PREFIX}-title`,
  subtitle1: `${PREFIX}-subtitle1`,
  projectTypes: `${PREFIX}-projectTypes`
};

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  padding: `${theme.spacing(1)} ${theme.spacing(0)}`,

  [`&.${classes.content}`]: {
    width: '100%',
    minHeight: '80vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyItems: 'center',

    [theme.breakpoints.up('md')]: {
      padding: `${theme.spacing(4)} ${theme.spacing(4)}`
    }
  },

  [`& .${classes.title}`]: {
    textAlign: 'center',
    [`&, & span`]: {
      fontSize: theme.typography.h2.fontSize,
      [theme.breakpoints.up('sm')]: {
        fontSize: theme.typography.h1.fontSize
      }
    }
  },

  [`& .${classes.subtitle1}`]: {
    textAlign: 'center',
    fontSize: theme.typography.h3.fontSize,
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.h1.fontSize
    }
  },

  [`& .${classes.projectTypes}`]: {
    maxWidth: 1280,
    width: '100%',
    padding: `${theme.spacing(0)} ${theme.spacing(4)}`,

    [`& .${cardClasses.root}`]: {
      border: `2px solid transparent`,
      [`&:hover`]: {
        border: `2px solid ${
          theme.palette.mode === 'light'
            ? 'transparent'
            : theme.palette.text.primary
        }`
      }
    },

    [`& .${cardContentClasses.root}`]: {
      filter: 'none !important'
    }
  },

  [`& .${ProjectTypeCardListClasses.cardContent}`]: {
    boxShadow: theme.shadows[20]
  }
}));

export const validateRegExp = value => {
  if (/^\/.*\/[A-Za-z]*$/.test(value)) return undefined;
  return 'resources.env_var_validations.validation.invalid_regexp_format';
};

export const REGEXP_DOCS_URL = 'https://www.php.net/manual/en/book.pcre.php';

export const createRegExpFromPlainString = value => {
  const pattern = value.replace(/^\/|(\/([A-Za-z]+)?$)/g, '');
  const flags = value.match(/\/[A-Za-z]+$/g)?.[0]?.replace('/', '');

  return new RegExp(pattern, flags || undefined);
};

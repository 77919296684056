import { ComponentVersionEnvVarExampleEdit } from './Edit';
import { ComponentVersionEnvVarExampleCreate } from './Create';

export default {
  name: 'component_version_env_var_examples',
  edit: ComponentVersionEnvVarExampleEdit,
  create: ComponentVersionEnvVarExampleCreate,
  options: {
    showInMenu: false
  }
};

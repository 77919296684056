import { useMemo } from 'react';
import { useGetList } from 'react-admin';
import { useIsTemplateResource } from '@rc/admin/hooks';
import { RESOURCE_SOURCES } from '@rc/admin/constants';

/**
 *
 * @param {object} props
 * @param {string} props.source
 * @param {string | null} props.id
 * @param {import('react-admin').RaRecord | null} props.project
 * @returns {{
 * 		loaded: boolean,
 * 		limit: number | undefined,
 * 		used: number | undefined,
 * 		free: number | undefined
 * 	}}
 */
export const useProjectResourceLimit = props => {
  const { source, id, project } = props;
  const isTemplate = useIsTemplateResource();

  const { data: environments, isLoading: isEnvironmentsLoading } = useGetList(
    isTemplate ? 'template_environments' : 'environments',
    { filter: { [isTemplate ? 'templateProject' : 'project']: project?.id } },
    { enabled: !!project }
  );

  const loaded = project && !isEnvironmentsLoading;

  const used = useMemo(
    () =>
      environments
        ? environments
            .filter(environment => !id || environment.id !== id)
            .reduce((acc, environment) => (acc += environment[source]), 0)
        : undefined,
    [environments, id, source]
  );

  const limit = loaded ? project?.[source] : undefined;
  const free = loaded ? limit - used : 0;

  return {
    loaded,
    limit,
    used,
    free
  };
};

/**
 *
 * @param {object} props
 * @param {string | null} props.id
 * @param {import('react-admin').RaRecord | null} props.project
 * @returns {
 * 	{
 * 		isLoading: boolean,
 * 		cpu: {
 * 			loaded: boolean,
 * 			limit: number | undefined,
 * 			used: number | undefined,
 * 			free: number | undefined
 * 		},
 * 		memory: {
 * 			loaded: boolean,
 * 			limit: number | undefined,
 * 			used: number | undefined,
 * 			free: number | undefined
 * 		},
 * 		storage: {
 * 			loaded: boolean,
 * 			limit: number | undefined,
 * 			used: number | undefined,
 * 			free: number | undefined
 * 		}
 * 	}
 * }
 */
export const useProjectResourceLimits = props => {
  const { project, id } = props;

  return RESOURCE_SOURCES.reduce(
    (result, source) => {
      const limit = useProjectResourceLimit({ source, id, project });
      result[source] = limit;

      if (!result.isLoading && limit.isLoading) {
        result.isLoading = true;
      }

      return result;
    },
    { isLoading: false }
  );
};

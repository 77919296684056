export const decodeBase64 = value => {
  if (!value) return '';

  try {
    return window.atob(value);
  } catch (e) {
    return value;
  }
};

export const encodeBase64 = value => {
  if (!value) return null;

  try {
    return window.btoa(value);
  } catch (e) {
    return value;
  }
};

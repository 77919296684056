import React from 'react';
import { useRecordContext } from 'react-admin';
import { Tooltip, Typography } from '@mui/material';
import { formatDistance } from 'date-fns';

export const CreatedAt = () => {
  const record = useRecordContext();
  const createdAt = new Date(record.created * 1e3);

  return (
    <Tooltip
      title={createdAt.toLocaleDateString('en-US', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      })}
    >
      <Typography>
        {formatDistance(createdAt, new Date(), { addSuffix: true })}
      </Typography>
    </Tooltip>
  );
};

export default CreatedAt;

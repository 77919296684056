import { styled } from '@mui/material';
import { OneColumnFormStyles } from '@rc/admin/theme/styles';
import React from 'react';
import { Create, SimpleForm, TextInput } from 'react-admin';

export const ComponentCreate = () => {
  return (
    <StyledCreate>
      <SimpleForm redirect={'edit'} className={classes.form}>
        <TextInput source={'name'} fullWidth />
      </SimpleForm>
    </StyledCreate>
  );
};

const PREFIX = 'ComponentCreate';

const classes = {
  form: `${PREFIX}-form`
};

const StyledCreate = styled(Create)(({ theme }) => ({
  [`& .${classes.form}`]: {
    ...OneColumnFormStyles(theme)
  }
}));

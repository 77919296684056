import React, { createContext, useContext, useMemo, useState } from 'react';

const FormStepperContext = createContext();

export const FormStepperContextProvider = props => {
  const { steps, children } = props;
  const stepsLength = steps.length;
  const [activeStep, setActiveStep] = useState(0);
  const [data, setData] = useState(null);

  const value = useMemo(
    () => [
      {
        steps,
        activeStep,
        hasPrevious: activeStep > 0,
        hasNext: activeStep < stepsLength - 1,
        data
      },
      {
        next: data => {
          setActiveStep(curr => curr + 1);
          setData(data);
        },
        previous: data => {
          setActiveStep(curr => curr - 1);
          setData(data);
        },
        goTo: (step, data) => {
          setActiveStep(step);
          setData(data);
        }
      }
    ],
    [steps, activeStep, stepsLength, data]
  );

  return (
    <FormStepperContext.Provider value={value}>
      {children}
    </FormStepperContext.Provider>
  );
};

/**
 *
 * @returns {import('./types').FormStepperContext}
 */
export const useFormStepperContext = () => useContext(FormStepperContext);

import React, { createContext, useCallback, useContext, useState } from 'react';

/**
 * @typedef {{organisationId: string, teamId: string}} DebugModeContextState
 *
 *
 * @type {import('react').Context<[DebugModeContextState, DebugModeContextState => void]>}
 */
const DebugModeContext = createContext();

export const DebugModeContextProvider = props => {
  const { children } = props;

  const [isDebugMode, setIsDebugMode] = useState(() => {
    const storedDebugMode = window.localStorage.getItem('debug_mode');
    return storedDebugMode !== null ? JSON.parse(storedDebugMode) : false;
  });

  const toggleDebugMode = useCallback(() => {
    const newDebugMode = !isDebugMode;
    window.localStorage.setItem('debug_mode', JSON.stringify(newDebugMode));
    setIsDebugMode(newDebugMode);
  }, [isDebugMode]);

  return (
    <DebugModeContext.Provider value={[isDebugMode, toggleDebugMode]}>
      {children}
    </DebugModeContext.Provider>
  );
};

export const useDebugModeContext = () => useContext(DebugModeContext);
export const useIsDebugMode = () => useDebugModeContext()[0];

import { styled } from '@mui/material';
import React from 'react';
import { ShowButton as RaShowButton } from 'react-admin';

/**
 *
 * @param {import('react-admin').ShowButtonProps} props
 * @returns {import('react').ReactElement<RaShowButton>}
 */
export const ShowButton = props => {
  return <StyledShowButton variant='outlined' icon={null} {...props} />;
};

const StyledShowButton = styled(RaShowButton)(() => ({
  float: 'right',
  minWidth: 48
}));

import React, { useCallback, useEffect } from 'react';
import {
  AutocompleteInput,
  Create,
  ReferenceInput,
  SimpleForm,
  TextInput,
  email,
  required,
  useTranslate
} from 'react-admin';
import { Grid, styled } from '@mui/material';
import { TwoColumnFormStyles } from '@rc/admin/theme/styles';
import { Breadcrumbs, SectionHelper } from '@rc/admin/components';
import { useGetOrganisationName } from '@rc/admin/hooks';
import { useFormContext, useWatch } from 'react-hook-form';

export const InvitationCreate = () => {
  const t = useTranslate();
  const getOrganisationName = useGetOrganisationName();

  return (
    <StyledCreate
      actions={<Breadcrumbs disableLast={false} />}
      transform={({ organisation, ...data }) => data}
    >
      <SimpleForm redirect={false}>
        <SectionHelper mb={2}>
          {t('resources.invitations.helper.user')}
        </SectionHelper>
        <Grid
          container
          rowSpacing={0.5}
          columnSpacing={1}
          className={classes.container}
        >
          <Grid item xs={12} sm={6}>
            <ReferenceInput source='user' reference='users'>
              <InvitationUserAutocompleteInput />
            </ReferenceInput>
          </Grid>
          <Grid item xs={12} sm={6}>
            <InvitationEmailInput />
          </Grid>
          <Grid item xs={12}>
            <ReferenceInput source='organisation' reference='organisations'>
              <AutocompleteInput
                size='small'
                optionText={getOrganisationName}
                autoComplete='off'
                validate={[required()]}
                fullWidth
              />
            </ReferenceInput>
          </Grid>
          <Grid item xs={12}>
            <InvitationTeamInput />
          </Grid>
        </Grid>
      </SimpleForm>
    </StyledCreate>
  );
};

const InvitationUserAutocompleteInput = props => {
  const { setValue } = useFormContext();

  const onChange = useCallback(
    (_, user) => {
      console.log({ data: user });
      setValue('email', user ? user?.email : null);
    },
    [setValue]
  );

  return (
    <AutocompleteInput
      size='small'
      optionText={'email'}
      autoComplete='off'
      fullWidth
      onChange={onChange}
    />
  );
};

const InvitationEmailInput = props => {
  const user = useWatch({ name: 'user' });

  return (
    <TextInput
      {...props}
      source={'email'}
      fullWidth
      InputProps={{
        disabled: !!user
      }}
      validate={[required(), email()]}
    />
  );
};

const InvitationTeamInput = props => {
  const organisationId = useWatch({ name: 'organisation' });
  const { setValue } = useFormContext();

  useEffect(() => {
    if (!organisationId) {
      setValue('team', null);
    }
  }, [organisationId, setValue]);

  return (
    <ReferenceInput
      source='team'
      reference='teams'
      filter={{
        organisation: organisationId
      }}
    >
      <AutocompleteInput
        size='small'
        optionText={'name'}
        autoComplete='off'
        validate={[required()]}
        fullWidth
        disabled={!organisationId}
      />
    </ReferenceInput>
  );
};

const PREFIX = 'InvitationCreate';

const classes = {
  container: `${PREFIX}-container`
};

const StyledCreate = styled(Create)(({ theme }) => ({
  [`& .${classes.container}`]: {
    ...TwoColumnFormStyles(theme)
  }
}));

import { useGetList } from 'react-admin';

/**
 *
 * @param {object} props
 * @param {string} props.projectTypeVersion
 * @param {string} props.componentVersion
 * @returns
 */
export const useComponentResourceLimits = props => {
  const { projectTypeVersion, componentVersion } = props;

  const { data: componentResourceLimits, isLoading } = useGetList(
    'component_resource_limits',
    {
      filter: {
        projectTypeVersion,
        componentVersion
      }
    },
    { enabled: !!projectTypeVersion && !!componentVersion }
  );

  return {
    componentResourceLimits,
    isLoading
  };
};

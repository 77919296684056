import { useEffect } from 'react';
import { useDataProvider } from 'react-admin';
import { PREFETCH_PAGINATION, ID_SORT } from '../constants';

/**
 *
 * @param {string[]} resources
 * @returns
 */
export const usePrefetchList = resources => {
  const { getList } = useDataProvider();

  useEffect(() => {
    resources.forEach(resource => {
      getList(resource, {
        // Default useGetList params
        pagination: PREFETCH_PAGINATION,
        sort: ID_SORT
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getList]);

  return null;
};

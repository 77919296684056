import React from 'react';
import {
  Datagrid,
  DateField,
  EditButton,
  FunctionField,
  List,
  ReferenceField,
  TextField
} from 'react-admin';
import {
  InvitationOrganisationField,
  InvitationStateField
} from './components';

export const InvitationList = () => {
  return (
    <List>
      <Datagrid>
        <FunctionField
          source='user'
          render={record => {
            if (record.user) {
              return (
                <ReferenceField source='user' reference='users'>
                  <TextField source='email' />
                </ReferenceField>
              );
            }

            return <TextField source='email' />;
          }}
        />
        <InvitationOrganisationField source='organisation' />
        <ReferenceField source='team' reference='teams'>
          <TextField source='name' />
        </ReferenceField>
        <DateField source='createdAt' />
        <DateField source='expiredAt' />
        <InvitationStateField source='state' />
        <EditButton sx={{ float: 'right' }} />
      </Datagrid>
    </List>
  );
};

import { useResourceContext } from 'react-admin';

/**
 *
 * @param {object} props
 * @param {object} props.resource
 * @returns
 */
export const useIsTemplateResource = (props = {}) => {
  const { resource: resourceProp } = props;
  const resource = useResourceContext();

  return /^template_/.test(resourceProp || resource || '');
};

import React, { useMemo } from 'react';
import {
  Labeled,
  RecordContextProvider,
  TextField,
  useRecordContext,
  useTranslate
} from 'react-admin';
import { Box, Grid, Typography } from '@mui/material';
import {
  PossibleFieldOrInput,
  SectionTitle,
  WithCopyToClipboard
} from '@rc/admin/components';
import { useIsTemplateResource } from '@rc/admin/hooks';

import { useRCWorkerMessageRecord } from '@rc/admin/context';

/**
 *
 * @param {import('@mui/material').BoxProps & { title: string }} props
 *
 */
export const ProjectAccessFields = props => {
  const { title, ...rest } = props;
  const record = useRecordContext();
  const isTemplate = useIsTemplateResource();
  const workerData = useRCWorkerMessageRecord();
  const t = useTranslate();

  const recordValue = useMemo(() => {
    if (workerData && record) {
      return {
        ...record,
        projectAccess: workerData.projectAccess,
        state: workerData.state
      };
    }

    return record;
  }, [record, workerData]);

  const noProjectAccessMessage =
    !recordValue?.projectAccess ||
    (!recordValue.projectAccess.projectAccessGit &&
      !recordValue.projectAccess.projectAccessRegistry) ? (
      <Grid item xs={12} zeroMinWidth>
        <Typography variant={'body2'} sx={{ opacity: 0.75 }}>
          {t('resources.projects.message.no_project_access')}
        </Typography>
      </Grid>
    ) : null;

  return (
    <RecordContextProvider value={recordValue}>
      <Box width={'100%'} {...rest}>
        {!isTemplate && (
          <Grid container wrap='wrap' spacing={1} mb={2}>
            <Grid item xs={12}>
              <SectionTitle>{title}</SectionTitle>
            </Grid>

            {noProjectAccessMessage}

            <Grid item xs={12} zeroMinWidth>
              <PossibleFieldOrInput
                source={'projectAccess.projectAccessGit.url'}
              >
                <Labeled
                  label={
                    'resources.projects.fields.projectAccess.projectAccessGit.url'
                  }
                >
                  <WithCopyToClipboard>
                    <TextField
                      component={'p'}
                      source={'projectAccess.projectAccessGit.url'}
                    />
                  </WithCopyToClipboard>
                </Labeled>
              </PossibleFieldOrInput>
            </Grid>
            <Grid item xs={12} zeroMinWidth>
              <PossibleFieldOrInput
                source={'projectAccess.projectAccessRegistry.url'}
              >
                <Labeled
                  label={
                    'resources.projects.fields.projectAccess.projectAccessRegistry.url'
                  }
                >
                  <WithCopyToClipboard>
                    <TextField
                      component={'p'}
                      source={'projectAccess.projectAccessRegistry.url'}
                    />
                  </WithCopyToClipboard>
                </Labeled>
              </PossibleFieldOrInput>
            </Grid>
          </Grid>
        )}
      </Box>
    </RecordContextProvider>
  );
};

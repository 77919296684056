import React, { useRef } from 'react';
import {
  Edit,
  FormTab,
  Labeled,
  ReferenceField,
  SaveButton,
  TabbedForm,
  TextField,
  Toolbar as RaToolbar,
  useRecordContext,
  useTranslate,
  DeleteButton,
  useUnique,
  TextInput,
  required,
  useEditContext,
  useResourceContext,
  useNotify,
  useUnselect,
  useRedirect,
  useRefresh
} from 'react-admin';
import { Box, Grid, Stack, styled } from '@mui/material';
import {
  Breadcrumbs,
  EditTitle,
  LoadingField,
  OrganisationField,
  SectionHelper,
  SectionTitle
} from '@rc/admin/components';
import { OneColumnFormStyles } from '@rc/admin/theme/styles';
import { Invitations, CreateInvitationDialog } from './Invitation';
import { TeamMembers } from './Members';
import { TeamEnvVarInputs } from '../components';
import { transform } from './utils';
import { useIsRole, useUserTeamRoleGroup } from '@rc/admin/hooks';
import { ROLES, ROLE_GROUPS } from '@rc/admin/constants';

export const TeamEdit = () => {
  const t = useTranslate();
  const invitationsGetListRef = useRef(null);
  const usersGetListRef = useRef(null);
  const { result: isAdmin } = useIsRole(ROLES.ADMIN);

  return (
    <StyledEdit
      title={<Title isAdmin={isAdmin} />}
      redirect={false}
      actions={<Breadcrumbs />}
      transform={transform}
      mutationOptions={{
        onSuccess: () => {
          usersGetListRef.current?.refetch();
          invitationsGetListRef.current?.refetch();
        }
      }}
      mutationMode='pessimistic'
    >
      <TabbedForm toolbar={<Toolbar />} mode='all'>
        <FormTab
          label='resources.teams.tabs.general'
          contentClassName={classes.basic}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <NameInput fullWidth helperText={false} />
            </Grid>
            <Grid item xs={12}>
              <Labeled>
                <ReferenceField
                  reference='users'
                  source={'defaultUser'}
                  link={!isAdmin ? false : undefined}
                >
                  <TextField source='fullname' fullWidth />
                </ReferenceField>
              </Labeled>
            </Grid>
            <Grid item xs={12}>
              <ReferenceField source='organisation' reference='organisations'>
                <Labeled
                  label={t('resources.organisations.name', { smart_count: 1 })}
                >
                  <OrganisationField />
                </Labeled>
              </ReferenceField>
            </Grid>
          </Grid>
        </FormTab>
        <FormTab label='resources.teams.tabs.members'>
          <Stack width={'100%'} gap={4}>
            <Stack>
              <SectionTitle
                title={'resources.teams.sections.pending_invitations'}
              />
              <Invitations getListRef={invitationsGetListRef} />
            </Stack>
            <Stack>
              <SectionTitle title={'resources.teams.sections.team_members'} />
              <TeamMembers getListRef={usersGetListRef} />
            </Stack>
          </Stack>
        </FormTab>
        <FormTab label='Configuration'>
          <SectionTitle mb={0.5}>
            {t('resources.teams.sections.envVars.title')}
          </SectionTitle>
          <SectionHelper mb={1}>
            {t('resources.teams.sections.envVars.subtitle')}
          </SectionHelper>
          <TeamEnvVarInputs
            source='teamEnvVar'
            label={'resources.teams.fields.teamEnvVar'}
            fullWidth
          />
        </FormTab>
      </TabbedForm>
    </StyledEdit>
  );
};

const Title = props => {
  const { isAdmin } = props;
  const record = useRecordContext();
  const { isLoading } = useEditContext();
  const { roleGroup: userRoleGroup } = useUserTeamRoleGroup();
  const isAdminOrTeamAdmin =
    isAdmin || userRoleGroup === ROLE_GROUPS.TEAM_ADMIN;

  if (isLoading) {
    return null;
  }

  return (
    <Stack
      width={'100%'}
      flexDirection={'row'}
      justifyContent={'space-between'}
      alignContent={'center'}
    >
      <EditTitle />
      {isAdminOrTeamAdmin && (
        <Box>
          <CreateInvitationDialog
            teamId={record?.id}
            existingUserIds={record?.user}
          />
        </Box>
      )}
    </Stack>
  );
};

/**
 *
 * @param {import('react-admin').TextInputProps} props
 * @returns
 */
const NameInput = props => {
  const record = useRecordContext();
  const unique = useUnique();

  if (!record) {
    return <LoadingField />;
  }

  return (
    <TextInput
      {...props}
      source={'name'}
      validate={[
        unique({
          filter: {
            organisation: record.organisation
          }
        }),
        required()
      ]}
      fullWidth
      helperText={false}
    />
  );
};

const Toolbar = props => {
  const resource = useResourceContext();
  const record = useRecordContext();
  const notify = useNotify();
  const unselect = useUnselect(resource);
  const redirect = useRedirect();
  const refresh = useRefresh();

  return (
    <RaToolbar
      {...props}
      style={{ display: 'flex', justifyContent: 'space-between' }}
    >
      <SaveButton label='ra.action.save' redirect='edit' />
      {record && !record.isDefault && (
        <DeleteButton
          mutationMode='pessimistic'
          variant='text'
          mutationOptions={{
            onSuccess: () => {
              notify('ra.notification.deleted', {
                type: 'info',
                messageArgs: { smart_count: 1 }
              });
              unselect([record.id]);
              refresh();
              redirect('list', resource);
            }
          }}
        />
      )}
    </RaToolbar>
  );
};

const PREFIX = 'TeamEdit';

const classes = {
  basic: `${PREFIX}-basic`
};

const StyledEdit = styled(Edit)(({ theme }) => ({
  [`& .${classes.basic}`]: {
    ...OneColumnFormStyles(theme)
  }
}));

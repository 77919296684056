import React from 'react';
import { Title, useTranslate } from 'react-admin';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import {
  Card,
  CardContent,
  cardContentClasses,
  darken,
  lighten,
  Stack,
  styled,
  Typography
} from '@mui/material';

export const TypeSelectScreen = () => {
  const t = useTranslate();

  return (
    <Root>
      <Title
        defaultTitle={t('ra.page.create', {
          name: t('resources.projects.name', { smart_count: 1 })
        })}
      />
      <div className={TypeSelectScreenClasses.main}>
        <Card className={TypeSelectScreenClasses.card}>
          <CardContent sx={{ mt: 4 }}>
            <Typography variant='h4' align='center' mb={4}>
              {t('resources.projects.sections.create_method.title')}
            </Typography>
            <Stack
              direction={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
              width='100%'
              maxWidth={480}
              columnGap={4}
              sx={{ my: 6 }}
            >
              <SelectButton
                to={`/projects/create/from-template`}
                text={t(
                  'resources.projects.sections.create_method.from_template'
                )}
                isFull
              />
              <SelectButton
                to={`/projects/create/from-scratch`}
                text={t(
                  'resources.projects.sections.create_method.from_scratch'
                )}
              />
            </Stack>
          </CardContent>
        </Card>
      </div>
    </Root>
  );
};

const SelectButton = props => {
  const { isFull, text, ...rest } = props;
  return (
    <Link
      className={clsx(
        TypeSelectScreenClasses.button,
        isFull ? TypeSelectScreenClasses.buttonFull : ''
      )}
      {...rest}
    >
      <Stack alignItems={'center'} rowGap={4}>
        <div className={TypeSelectScreenClasses.buttonIcon}>
          <div className={TypeSelectScreenClasses.buttonIconInner}>
            {isFull && (
              <Stack
                rowGap={1}
                sx={{
                  padding: '16px 30px 0 12px',
                  '& hr': { width: '100%' }
                }}
              >
                <hr />
                <hr />
                <hr />
              </Stack>
            )}
          </div>
        </div>
        <Typography variant='h6' component={'span'} align='center'>
          {text}
        </Typography>
      </Stack>
    </Link>
  );
};

const PREFIX = 'RaEdit';

export const TypeSelectScreenClasses = {
  main: `${PREFIX}-main`,
  noActions: `${PREFIX}-noActions`,
  card: `${PREFIX}-card`,
  button: `${PREFIX}-button`,
  buttonFull: `${PREFIX}-buttonFull`,
  buttonIcon: `${PREFIX}-buttonIcon`,
  buttonIconInner: `${PREFIX}-buttonIconInner`
};

const Root = styled('div', {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root
})(({ theme }) => ({
  [`& .${TypeSelectScreenClasses.main}`]: {
    display: 'flex',
    alignItems: 'flex-start'
  },

  [`& .${TypeSelectScreenClasses.card}`]: {
    flex: '1 1 auto',

    [`& .${cardContentClasses.root}`]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    }
  },

  [`& .${TypeSelectScreenClasses.button}`]: {
    textDecoration: 'none',
    color: theme.palette.text.primary,
    [`& .${TypeSelectScreenClasses.buttonIcon}, & .${TypeSelectScreenClasses.buttonIconInner}`]:
      {
        border: `2px solid ${theme.palette.text.secondary}`,
        padding: theme.spacing(1),
        borderRadius: 24,
        height: 120,
        width: 120,
        [theme.breakpoints.up('md')]: {
          height: 160,
          width: 160
        }
      },
    [`& .${TypeSelectScreenClasses.buttonIcon}`]: {
      padding: '4px 5px'
    },
    '&:hover': {
      [`& .${TypeSelectScreenClasses.buttonIconInner}`]: {
        backgroundColor: lighten(theme.palette.background.default, 1),
        color: darken(theme.palette.background.default, 1),
        borderColor: darken(theme.palette.background.default, 1),
        opacity: 0.95
      }
    }
  }
}));

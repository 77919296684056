/**
 *
 * @param {string} value
 * @returns
 */
export function toCamelCase (value) {
  return value
    .split('-')
    .map((word, index) =>
      index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)
    )
    .join('');
}

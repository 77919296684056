import { getResourcePropsWithPermissions as withPermissions } from '@rc/admin/utils';

import accessCodeResource from './accessCode';
import clusterResource from './cluster';
import componentVersionEnvVarExampleResource from './componentVersionEnvVarExample';
import componentVersionEnvVarResource from './componentVersionEnvVar';
import componentVersionResourceLimitResource from './componentVersionResourceLimit';
import componentVersionResource from './componentVersion';
import componentResource from './component';
import envVarTypeResource from './envVarType';
import envVarValidationResource from './envVarValidation';
import environmentResource from './environment';
import invitationResource from './invitation';
import organisationResource from './organisation';
import productResource from './product';
import projectTypeVersionEnvVarExampleResource from './projectTypeVersionEnvVarExample';
import projectTypeVersionEnvVarResource from './projectTypeVersionEnvVar';
import projectTypeVersionResource from './projectTypeVersion';
import projectTypeResource from './projectType';
import projectResource from './project';
import providerResource from './provider';
import resourceTypeResource from './resourceType';
import roleGroupResource from './roleGroup';
import stateResource from './states';
import teamResource from './team';
import templateEnvironmentResource from './templateEnvironment';
import templateProjectResource from './templateProject';
import userResource from './user';

export const accessCodes = withPermissions(accessCodeResource);
export const clusters = withPermissions(clusterResource);
export const componentVersionEnvVarExamples = withPermissions(
  componentVersionEnvVarExampleResource
);
export const componentVersionEnvVars = withPermissions(
  componentVersionEnvVarResource
);
export const componentVersionResourceLimits = withPermissions(
  componentVersionResourceLimitResource
);
export const componentVersions = withPermissions(componentVersionResource);
export const components = withPermissions(componentResource);
export const credentialTypes = withPermissions({
  name: 'credential_types',
  options: { showInMenu: false }
});
export const dataTransferDataTypes = withPermissions({
  name: 'data_transfer_data_types',
  options: {
    showInMenu: false
  }
});
export const envVarTypes = withPermissions(envVarTypeResource);
export const envVarValidations = withPermissions(envVarValidationResource);
export const environmentComponents = withPermissions({
  name: 'environment_components',
  options: { showInMenu: false }
});
export const environmentCustomDomains = withPermissions({
  name: 'environment_custom_domains',
  options: { showInMenu: false }
});
export const environmentEnvVars = withPermissions({
  name: 'environment_env_vars',
  options: {
    showInMenu: false
  }
});
export const environments = withPermissions(environmentResource);
export const exportedDatas = withPermissions({
  name: 'exported_datas',
  options: {
    showInMenu: false
  }
});
export const gits = withPermissions({
  name: 'gits',
  options: { showInMenu: false }
});
export const gitTypes = withPermissions({
  name: 'git_types',
  options: { showInMenu: false }
});
export const importedDatas = withPermissions({
  name: 'imported_datas',
  options: {
    showInMenu: false
  }
});
export const invitations = withPermissions(invitationResource);
export const organisations = withPermissions(organisationResource);
export const product = withPermissions(productResource);
export const projectEnvVars = withPermissions({
  name: 'project_env_vars',
  options: {
    showInMenu: false
  }
});
export const projectTypeVersionEnvVarExamples = withPermissions(
  projectTypeVersionEnvVarExampleResource
);
export const projectTypeVersionEnvVars = withPermissions(
  projectTypeVersionEnvVarResource
);
export const projectTypeVersions = withPermissions(projectTypeVersionResource);
export const projectTypes = withPermissions(projectTypeResource);
export const projects = withPermissions(projectResource);
export const providers = withPermissions(providerResource);
export const resourceTypes = withPermissions(resourceTypeResource);
export const roleGroups = withPermissions(roleGroupResource);
export const states = withPermissions(stateResource);
export const teams = withPermissions(teamResource);
export const templateEnvironment = withPermissions(templateEnvironmentResource);
export const templateProject = withPermissions(templateProjectResource);
export const users = withPermissions(userResource);

import React from 'react';
import { Check } from '@mui/icons-material';
import { styled } from '@mui/material';

const PREFIX = 'ProgressBarStepIconRoot';

const classes = {
  completedIcon: `${PREFIX}-completedIcon`,
  circle: `${PREFIX}-circle`
};

export const ProgressBarStepIcon = props => {
  const { active, completed, isDots, className } = props;

  return (
    <ProgressBarStepIconRoot ownerState={{ active }} className={className}>
      {isDots ? (
        '...'
      ) : completed ? (
        <Check className={classes.completedIcon} />
      ) : (
        <div className={classes.circle} />
      )}
    </ProgressBarStepIconRoot>
  );
};

const ProgressBarStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
  display: 'flex',
  height: 22,
  alignItems: 'center',
  ...(ownerState.active && {
    color: theme.palette.primary.main
  }),
  [`& .${classes.completedIcon}`]: {
    color: theme.palette.primary.main,
    zIndex: 1,
    fontSize: 18
  },
  [`& .${classes.circle}`]: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
    margin: `0 4px`
  }
}));

import React, { useMemo } from 'react';
import {
  Card,
  CardContent,
  Divider,
  Stack,
  styled,
  Typography,
  useMediaQuery
} from '@mui/material';
import { useTranslate } from 'react-admin';
import { Functions } from '@mui/icons-material';
import { CpuIcon, MemoryIcon, StorageIcon } from '@rc/admin/components';
import { Row } from './Row';

/**
 *
 * @param {import('@mui/material').CardProps & { costs: object, isLoading: boolean }} props
 *
 */
export const CostsSummary = props => {
  const { costs, isLoading, ...rest } = props;
  const t = useTranslate();
  const isXL = useMediaQuery(`(min-width: 1920px)`);

  const labelContainerProps = useMemo(() => ({ xs: !isXL ? 6 : 2 }), [isXL]);
  const priceContainerProps = useMemo(() => ({ xs: !isXL ? 6 : 10 }), [isXL]);

  return (
    <StyledCard className={classes.root} {...rest}>
      <CardContent>
        <Typography variant={'subtitle1'} className={classes.cardTitle}>
          {t('components.costs_summary.title')}
        </Typography>

        <Stack className={classes.item}>
          <Row
            label={!isXL ? t('resources.resource_types.names.cpu') : ''}
            icon={<CpuIcon />}
            price={costs?.cpu}
            labelContainerProps={labelContainerProps}
            priceContainerProps={priceContainerProps}
            isLoading={isLoading}
          />
          <Row
            label={!isXL ? t('resources.resource_types.names.memory') : ''}
            icon={<MemoryIcon />}
            price={costs?.memory}
            labelContainerProps={labelContainerProps}
            priceContainerProps={priceContainerProps}
            isLoading={isLoading}
          />
          <Row
            label={!isXL ? t('resources.resource_types.names.storage') : ''}
            icon={<StorageIcon />}
            price={costs?.storage}
            labelContainerProps={labelContainerProps}
            priceContainerProps={priceContainerProps}
            isLoading={isLoading}
          />
          <Divider light />
          <Row
            icon={<Functions />}
            title={t('components.costs_summary.total')}
            price={costs?.sum}
            labelContainerProps={labelContainerProps}
            priceContainerProps={priceContainerProps}
            isLoading={isLoading}
          />
        </Stack>
      </CardContent>
    </StyledCard>
  );
};

const PREFIX = 'CostsSummary';

const classes = {
  root: `${PREFIX}-root`,
  cardTitle: `${PREFIX}-cardTitle`,
  item: `${PREFIX}-item`
};

const StyledCard = styled(Card)(({ theme }) => ({
  [`&.${classes.root}`]: {
    flex: 1,
    height: '100%'
  },

  [`& .${classes.cardTitle}`]: {
    marginBottom: theme.spacing(1.5)
  },

  [`& .${classes.item}`]: {
    gap: theme.spacing(1),
    [`@media (min-width: 1920px)`]: {
      flexDirection: 'row'
    }
  }
}));

import React, { Children, useCallback, forwardRef } from 'react';
import { useMediaQuery } from '@mui/material';
import { useSimpleFormIterator, useSimpleFormIteratorItem } from 'react-admin';

const TabbableFormControl = forwardRef((props, ref) => {
  const { children, back, forward, source } = props;
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const { add, remove, total } = useSimpleFormIterator();
  const { index } = useSimpleFormIteratorItem();
  const input = Children.only(children);

  const handleKeyDown = useCallback(
    index => e => {
      if (back) {
        if (!isSmall && e.key === 'Tab' && e.shiftKey) {
          if (
            index !== 0 &&
            index === total - 1 &&
            isFieldEmpty(e.target.value)
          ) {
            remove(index);
            e.stopPropagation();
          }
        }
      } else if (forward) {
        if (!isSmall && e.key === 'Tab' && !e.shiftKey) {
          if (index === total - 1) {
            add();
            e.stopPropagation();
          }
        }
      } else {
        const originalHandler = input.props.onKeyDown;
        if (originalHandler) {
          originalHandler(e);
        }
      }
    },
    [add, back, forward, input.props.onKeyDown, isSmall, remove, total]
  );

  const onKeyDown = handleKeyDown(index);

  return React.cloneElement(input, {
    ...input.props,
    source,
    index,
    id: source,
    ref,
    onKeyDown
  });
});

const isFieldEmpty = value =>
  !value || (typeof value === 'object' && JSON.stringify(value) === '{}');

TabbableFormControl.name = 'TabbableFormControl';

export default TabbableFormControl;

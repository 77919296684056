import React, { useMemo, useState } from 'react';
import { TextInput, useGetList, required } from 'react-admin';
import { useFormContext, useWatch } from 'react-hook-form';
import { debounce } from 'lodash';
import { useAxios } from '@rc/utils/axios';
import { createSourceUtil, getSourceUtil } from '@rc/admin/utils/form';
import { CreatableAutocompleteInput } from '@rc/admin/components';
import { useTabbableFormControlItem } from '@rc/admin/components/form/TabbableFormIterator/utils';
import { requiredSecure } from './utils';
import { useIsTemplateResource } from '@rc/admin/hooks';

export const EnvVarValueInput = props => {
  const {
    source,
    onKeyDown,
    envVarDefinitions,
    inheritedEnvVars,
    ...rest
  } = props;

  const { setValue } = useFormContext();

  const { member: memberSource, index, item: itemSource } = getSourceUtil(
    source
  );

  const [isEncrypted, id, key, value] = useWatch({
    name: [
      createSourceUtil(memberSource, index, 'isEncrypted'),
      createSourceUtil(memberSource, index, '@id'),
      createSourceUtil(memberSource, index, 'key'),
      createSourceUtil(memberSource, index, itemSource)
    ]
  });

  const isSecured = !!(
    isEncrypted &&
    // Has the value been saved or not modified since last submit
    id
  );

  const [, executeValidation] = useAxios(
    {
      url: '/api/env_var_validation',
      method: 'POST'
    },
    { manual: true }
  );

  const currentEnvVarDefinition = envVarDefinitions.find(
    envVar => envVar.key === key
  );

  const { isLoading, choices, defaultValue } = useEnvVarChoices({
    envVar: currentEnvVarDefinition,
    isSecured
  });

  // Once a choice has been selected, add a new row and focus it's "value" field
  const { handleKeyDown } = useTabbableFormControlItem(props);

  const [isEditMode, setIsEditMode] = useState(!isSecured);

  const validateEnvVar = useMemo(
    () =>
      debounce(
        async value => {
          if (value && currentEnvVarDefinition) {
            const { data } = await executeValidation({
              data: {
                word: value,
                iri: currentEnvVarDefinition['@id']
              }
            });

            if (!data.isValid) {
              return Array.isArray(data.messages)
                ? data.messages.join('\n')
                : data.messages;
            }
          }

          return undefined;
        },
        1000,
        {
          leading: false
        }
      ),
    [currentEnvVarDefinition, executeValidation]
  );

  const shouldRenderSecureInput = isSecured && !isEditMode;
  const isTemplateResource = useIsTemplateResource();

  const validate = [
    ...(isTemplateResource
      ? []
      : [shouldRenderSecureInput ? requiredSecure() : required()]),
    validateEnvVar
  ];

  if (shouldRenderSecureInput) {
    return (
      <TextInput
        {...rest}
        source={source}
        type='password'
        onFocus={() => setIsEditMode(true)}
        format={value =>
          value
            ? Array(value.length)
                .fill('*')
                .join('')
            : '********'
        }
        parse={() => value}
        validate={validate}
      />
    );
  }

  return (
    <CreatableAutocompleteInput
      isLoading={isLoading}
      isRequired={!isTemplateResource && !isSecured}
      source={source}
      choices={choices}
      optionText={'value'}
      optionValue={'value'}
      defaultValue={isSecured ? '' : defaultValue}
      onKeyDown={handleKeyDown}
      TextFieldProps={{
        autoFocus: isSecured,
        inputProps: {
          onKeyDown
        }
      }}
      {...rest}
      onBlur={() => {
        if (isSecured) {
          // Prevent the value being overwritten to empty string ("") when the input is blurred
          setValue(source, undefined);
        }

        setIsEditMode(false);
      }}
      validate={validate}
    />
  );
};

export const useEnvVarChoices = props => {
  const { envVar, isSecured } = props;

  const isProjectTypeVersionEnvVar = getIsProjectTypeVersionEnvVar(envVar);
  const isComponentTypeVersionEnvVar = getIsComponentTypeVersionEnvVar(envVar);

  const {
    data: projectTypeVersionEnvVarExamples,
    isLoading: isProjectTypeVersionEnvVarExamplesLoading
  } = useGetList(
    'project_type_version_env_var_examples',
    { filter: { projectTypeVersionEnvVar: envVar?.id } },
    { enabled: isProjectTypeVersionEnvVar }
  );

  const {
    data: componentVersionEnvVarExamples,
    isLoading: isComponentVersonEnvVarExamplesLoading
  } = useGetList(
    'component_version_env_var_examples',
    { filter: { componentVersionEnvVar: envVar?.id } },
    { enabled: isComponentTypeVersionEnvVar }
  );

  const isLoading =
    (isProjectTypeVersionEnvVar && isProjectTypeVersionEnvVarExamplesLoading) ||
    (isComponentTypeVersionEnvVar && isComponentVersonEnvVarExamplesLoading);

  const choices = useMemo(() => {
    if (isLoading) {
      return [];
    }

    return [
      ...(projectTypeVersionEnvVarExamples || []),
      ...(componentVersionEnvVarExamples || [])
    ].filter(
      (choice, i, array) =>
        (isSecured && !choice.value) ||
        (choice?.value &&
          // Filter duplicates
          array.findIndex(item => item && item.value === choice.value) === i)
    );
  }, [
    isLoading,
    projectTypeVersionEnvVarExamples,
    componentVersionEnvVarExamples,
    isSecured
  ]);

  const defaultValue = choices.find(choice => choice.isDefault)?.value || '';

  return {
    isLoading,
    choices,
    defaultValue
  };
};

const getIsProjectTypeVersionEnvVar = envVar =>
  envVar?.['@type'] === 'ProjectTypeVersionEnvVar';
const getIsComponentTypeVersionEnvVar = envVar =>
  envVar?.['@type'] === 'ComponentVersionEnvVar';

import React from 'react';
import _ from 'lodash';
import { TextField, useTheme } from '@mui/material';
import { Controller } from 'react-hook-form';
import { useTranslate } from 'react-admin';

/**
 *
 * @param {import('@mui/material').TextFieldProps & import('react-hook-form').ControllerProps} props
 * @param {string} props.component - input component
 * @returns
 */
const FieldInput = props => {
  const {
    name,
    control,
    rules,
    variant,
    required,
    helperText,
    disabled,
    component: Component = TextField,
    ...rest
  } = props;
  const translate = useTranslate();
  const theme = useTheme();

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { ref, ...field }, fieldState: { error } }) => (
        <Component
          inputRef={ref}
          {...field}
          id={name}
          error={!!error}
          helperText={error ? translate(error.message) : helperText}
          required={required === true}
          variant={
            !_.isEmpty(variant)
              ? variant
              : theme.components.MuiTextField?.defaultProps?.variant
          }
          {...rest}
          InputLabelProps={{
            shrink: true
          }}
        />
      )}
    />
  );
};

FieldInput.defaultProps = {
  fullWidth: true,
  margin: 'normal'
};

export default FieldInput;

import React from 'react';
import { Grid, styled } from '@mui/material';
import { useEnvironmentCosts, useIdParam } from '@rc/admin/hooks';
import { UsageSummary, CostsSummary } from '@rc/admin/components';

export const EnvironmentCosts = () => {
  const { originId } = useIdParam();
  const { error, isLoading, costs, summary } = useEnvironmentCosts({
    id: originId
  });

  if ((!isLoading && !summary && !costs) || error) {
    return null;
  }

  return (
    <StyledGrid container spacing={2}>
      <Grid item xs={12} md={6}>
        <UsageSummary isLoading={isLoading} summary={summary} />
      </Grid>
      <Grid item xs={12} md={6}>
        <CostsSummary isLoading={isLoading} costs={costs} />
      </Grid>
    </StyledGrid>
  );
};

const PREFIX = 'EnvironmentCosts';

const classes = {
  card: `${PREFIX}-card`,
  cardTitle: `${PREFIX}-cardTitle`,
  item: `${PREFIX}-item`
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.card}`]: {
    flex: 1,
    height: '100%'
  },

  [`& .${classes.cardTitle}`]: {
    marginBottom: theme.spacing(1.5)
  },

  [`& .${classes.item}`]: {
    gap: theme.spacing(1),
    [`@media (min-width: 1920px)`]: {
      flexDirection: 'row'
    }
  }
}));

import React from 'react';
import {
  Edit,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  NumberInput,
  useGetOne,
  required,
  useRecordContext,
  useTranslate,
  Toolbar,
  SaveButton,
  ToolbarClasses
} from 'react-admin';
import {
  Breadcrumbs,
  DeleteButton,
  GroupedSelectInput
} from '@rc/admin/components';
import {
  resourceLimitMinValueValidator,
  resourceLimitMaxValueValidator
} from './utils';
import styled from '@emotion/styled';
import { TwoColumnFormStyles } from '@rc/admin/theme/styles';
import { Grid } from '@mui/material';

export const ComponentVersionResourceLimitEdit = () => {
  const t = useTranslate();

  return (
    <StyledEdit
      title={<Title />}
      redirect={false}
      mutationMode='pessimistic'
      actions={
        <Breadcrumbs parents={breadcrumbParents} label={<BreadcrumbsLabel />} />
      }
    >
      <SimpleForm
        className={classes.form}
        toolbar={
          <Toolbar>
            <div className={ToolbarClasses.defaultToolbar}>
              <SaveButton />
              <DeleteButton
                redirect={record =>
                  `/component_versions/${encodeURIComponent(
                    record?.componentVersion
                  )}`
                }
              />
            </div>
          </Toolbar>
        }
      >
        <ReferenceInput
          source={'projectTypeVersion'}
          reference={'project_type_versions'}
        >
          <GroupedSelectInput
            groupReference={'project_types'}
            id={'project-type-version'}
            label={t('resources.project_types.name', {
              smart_count: 1
            })}
            optionText={choice => choice?.version || choice?.name}
            validate={[required()]}
            fullWidth
          />
        </ReferenceInput>

        <ReferenceInput source={'resourceType'} reference={'resource_types'}>
          <SelectInput
            optionText={'name'}
            validate={[required()]}
            fullWidth
            isRequired
          />
        </ReferenceInput>

        <Grid container wrap='wrap' spacing={1}>
          <Grid item xs={12} sm={6}>
            <NumberInput
              source={'minValue'}
              validate={[resourceLimitMinValueValidator, required()]}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <NumberInput
              source={'maxValue'}
              validate={[resourceLimitMaxValueValidator, required()]}
              fullWidth
            />
          </Grid>
        </Grid>
      </SimpleForm>
    </StyledEdit>
  );
};

const breadcrumbParents = [
  { reference: 'component_versions', source: 'componentVersion' },
  { reference: 'components', source: 'component' }
];

const BreadcrumbsLabel = ({ data }) => {
  const { data: resourceType, isLoadingResourceType } = useGetOne(
    'resource_types',
    { id: data.resourceType },
    { enabled: data['@type'] === 'ComponentResourceLimit' }
  );

  switch (data['@type']) {
    case 'ComponentVersion':
      return data.version;
    case 'ComponentResourceLimit':
      return !isLoadingResourceType && resourceType.name + ' limit';
    default:
      return data.name;
  }
};

const Title = () => {
  const record = useRecordContext();
  const { data: resourceType } = useGetOne(
    'resource_types',
    {
      id: record?.resourceType
    },
    { enabled: !!record?.resourceType }
  );

  if (!resourceType) {
    return null;
  }

  return <div>{`${resourceType.name} limit`}</div>;
};

const PREFIX = 'ComponentVersionResourceLimitEdit';

const classes = {
  form: `${PREFIX}-form`
};

const StyledEdit = styled(Edit)(({ theme }) => ({
  [`& .${classes.form}`]: {
    ...TwoColumnFormStyles(theme)
  }
}));

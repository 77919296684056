import { matchPath } from 'react-router-dom';

export const getResourcePath = (resourceName, id) =>
  `/${resourceName}${id ? '/' + encodeURIComponent(id) : ''}`;

export const getIsResourcePathActive = (pathname, resource, id) => {
  return matchPath(
    {
      path: pathname,
      exact: true
    },
    getResourcePath(resource, id)
  );
};

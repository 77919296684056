// import { darkScrollbar } from '@mui/material';
// import { GROUP_DATA_VALUE_PREFIX } from '@rc/admin/components';
import { circularProgressClasses, tabsClasses } from '@mui/material';
import {
  ArrayInputClasses,
  SaveButton
  // ConfirmClasses
} from 'react-admin';

// const INPUT_FIELD_WIDTH = 256;

/**
 *
 * @param {import("@mui/material").Theme} theme
 * @returns {import("@mui/material").Components<Omit<import("@mui/material")Theme, 'components'>>}
 */
export default function compStyleOverride (theme) {
  // const isLight = theme.palette.mode === 'light';

  return {
    ...theme.components,
    MuiScopedCssBaseline: {
      styleOverrides: {
        root: {
          [`& .tabbed-form .${tabsClasses.root}, & .RaShow-main > div > .${tabsClasses.root}`]:
            {
              paddingLeft: theme.spacing(2.5),
              paddingRight: theme.spacing(2.5)
            }
        }
      }
    },
    MuiTextField: {
      defaultProps: {
        variant: 'filled'
      }
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          // Has any class that includes RaSelectInput-root
          '&[class*="ra-input"]': {
            marginTop: 0,
            marginBottom: 0
          }
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          [`&.${ArrayInputClasses.label}`]: {
            top: `${theme.spacing(-0.75)} !important`
          }
        }
      }
    },
    MuiInputLabel: {
      defaultProps: {
        variant: 'filled'
      }
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          lineHeight: '21px'
        }
      },
      defaultProps: {
        variant: 'filled'
      }
    }
    // MuiButton: {
    //   styleOverrides: {
    //     root: {
    //       [`&[class*="RaSaveButton-root"] svg:not(.${circularProgressClasses.svg})`]:
    //         {
    //           display: 'none'
    //         }
    //     }
    //   }
    // }
    // MuiCssBaseline: {
    //   ...(theme.components.MuiCssBaseline || {}),
    //   styleOverrides: {
    //     ...(theme.components.MuiCssBaseline?.styleOverrides || {}),
    //     body: isLight ? {} : darkScrollbar()
    //   }
    // },
    // 'RaSidebar-docked': {
    //   background: theme.palette.background.paper
    // },
    // MuiCard: {
    //   defaultProps: {
    //     elevation: 1
    //   },
    //   styleOverrides: {
    //     root: {
    //       border: `1px solid ${theme.palette.primary.light}`
    //     }
    //   }
    // },
    // MuiAccordion: {
    //   defaultProps: {
    //     elevation: 0
    //   },
    //   styleOverrides: {
    //     root: {
    //       border: `1px solid ${theme.palette.primary.light}`,
    //       borderRadius: theme.shape.borderRadius
    //     }
    //   }
    // },
    // MuiLink: {
    //   styleOverrides: {
    //     root: {
    //       textDecoration: 'none',
    //       '&:hover.MuiTypography-root': {
    //         textDecoration: 'none'
    //       }
    //     }
    //   }
    // },
    // MuiButton: {
    //   ...(theme.components.MuiButton || {}),
    //   defaultProps: {
    //     ...(theme.components.MuiButton?.defaultProps || {}),
    //     variant: 'contained'
    //   },
    //   styleOverrides: {
    //     ...(theme.components.MuiButton?.styleOverrides || {}),
    //     root: {
    //       ...(theme.components.MuiButton?.styleOverrides?.root || {}),
    //       boxShadow: 'none',
    //       '&:hover': {
    //         boxShadow: 'none'
    //       },
    //       '.MuiCircularProgress-root': {
    //         color: 'inherit'
    //       },
    //       [`&.${ConfirmClasses.confirmPrimary}:not(:hover)`]: {
    //         color: `${isLight ? '#fff' : '#000'} !important`
    //       }
    //     },
    //     outlined: {
    //       padding: '5px 15px'
    //     },
    //     contained: {
    //       padding: '5px 15px'
    //       // '&.Mui-disabled': {
    //       //   color: theme.palette.action.disabled,
    //       //   backgroundColor: theme.palette.action.disabledBackground
    //       // }
    //     },
    //     containedPrimary: {
    //       '&:not(.Mui-disabled)': {
    //         border: `1px solid ${theme.palette.primary.main}`
    //       },
    //       '&:hover': {
    //         color: theme.palette.primary.main,
    //         backgroundColor: 'transparent'
    //       }
    //     },
    //     outlinedPrimary: {
    //       color: theme.palette.primary[200],
    //       borderColor: theme.palette.primary.light,
    //       '&:hover': {
    //         backgroundColor: 'transparent',
    //         color: theme.palette.primary.main,
    //         borderColor: theme.palette.primary.main
    //       }
    //     }
    //   }
    // },
    // MuiSlider: {
    //   styleOverrides: {
    //     ...theme.components.MuiSlider.styleOverrides,
    //     root: {
    //       width: INPUT_FIELD_WIDTH,
    //       height: 2,
    //       ...theme.components.MuiSlider.styleOverrides.root
    //     },
    //     thumb: {
    //       width: 12,
    //       height: 12
    //     }
    //   }
    // },
    // MuiMenu: {
    //   styleOverrides: {
    //     list: {
    //       paddingLeft: 8,
    //       paddingRight: 8
    //       // marginBottom: 4
    //     }
    //   }
    // },
    // MuiMenuItem: {
    //   ...theme.components.MuiMenuItem,
    //   styleOverrides: {
    //     ...theme.components.MuiMenuItem.styleOverrides,
    //     root: {
    //       ...(theme.components.MuiMenuItem.styleOverrides.root || {}),
    //       marginBottom: 3,
    //       [`&.Mui-disabled[data-value^=${GROUP_DATA_VALUE_PREFIX}]`]: {
    //         opacity: 0.8
    //       }
    //     }
    //   }
    // },
    // MuiTabs: {
    //   defaultProps: {
    //     textColor: 'primary',
    //     indicatorColor: 'primary'
    //   },
    //   styleOverrides: {
    //     root: {
    //       '@media screen and (max-width: 375px)': {
    //         maxWidth: `calc(100vw - 70px)`
    //       }
    //     },
    //     indicator: {
    //       // backgroundColor: theme.palette.primary.main
    //     }
    //   }
    // },
    // MuiToolbar: {
    //   styleOverrides: {
    //     root: {
    //       '&:not(.RaAppBar-toolbar)': {
    //         backgroundColor: `${theme.palette.background.default} !important`
    //       },
    //       '&[class*="RaListToolbar-root"]': {
    //         marginBottom: 8
    //       }
    //     }
    //   }
    // },
    // MuiDialog: {
    //   styleOverrides: {
    //     root: {
    //       '& .MuiBackdrop-root': {
    //         backgroundColor: isLight
    //           ? `rgba(0, 0, 0, 0.5)`
    //           : `rgba(255, 255, 255, 0.1)`
    //       }
    //     }
    //   }
    // },
    // MuiFormControl: {
    //   ...(theme.components.MuiFormControl || {}),
    //   styleOverrides: {
    //     ...(theme.components.MuiFormControl?.styleOverrides || {}),
    //     root: {
    //       ...(theme.components.MuiFormControl?.styleOverrides?.root || {}),
    //       '&[class*="RaResettableTextField-root-RaLoadingInput-root"] > label[data-shrink="false"]':
    //         {
    //           top: '0 !important'
    //         },
    //       '&[class*="RaSelectArrayInput-root"] > .MuiFormLabel-root': {
    //         top: '0 !important'
    //       },
    //       '&[class*="RaSelectArrayInput-root"] > .MuiFormLabel-root[data-shrink="false"]':
    //         {
    //           top: '-6px !important'
    //         }
    //     }
    //   }
    // },
    // MuiSwitch: {
    //   styleOverrides: {
    //     colorPrimary: {
    //       '&:not(.Mui-checked)': {
    //         color: theme.palette.primary.light
    //       }
    //     }
    //   }
    // },
    // MuiTooltip: {
    //   styleOverrides: {
    //     tooltip: {
    //       color: isLight ? theme.palette.common.black : theme.palette.grey[100],
    //       background: theme.palette.background.default
    //     }
    //   }
    // },
  };
}

import React from 'react';
import {
  SimpleForm,
  required,
  useTranslate,
  Toolbar as RaToolbar,
  ToolbarClasses,
  SaveButton,
  useNotify,
  email,
  TextInput,
  useGetMany
} from 'react-admin';
import { Add, Send as SendIcon } from '@mui/icons-material';
import { CreateDialog, Iconify } from '@rc/admin/components';
import { Stack } from '@mui/material';

/**
 *
 * @param {object} props
 * @param {string} props.teamId - The id of the team.
 * @param {string[]} props.existingUserIds - The ids of the users that are already in the team.
 * @returns
 */
export const CreateInvitationDialog = props => {
  const { teamId, existingUserIds } = props;
  const t = useTranslate();
  const notify = useNotify();

  const { data: existingUsers = [] } = useGetMany(
    'users',
    { ids: existingUserIds },
    { enabled: !!existingUserIds?.length }
  );

  return (
    <CreateDialog
      resource='invitations'
      title={
        <Stack direction='row' alignItems={'center'} spacing={1}>
          <Iconify icon='solar:user-plus-rounded-bold-duotone' />
          <span>{t('resources.teams.action.invite_user')}</span>
        </Stack>
      }
      color={'primary'}
      mutationOptions={{
        onSuccess: () => {
          notify('resources.invitations.notification.sent');
        }
      }}
    >
      <SimpleForm
        defaultValues={{
          team: teamId
        }}
        toolbar={
          <RaToolbar>
            <div className={ToolbarClasses.defaultToolbar}>
              <SaveButton label='action.send' icon={<SendIcon />} />
            </div>
          </RaToolbar>
        }
      >
        <TextInput
          source='email'
          label='resources.invitations.message.email_placeholder'
          validate={[
            required(),
            email(),
            value => {
              if (existingUsers.find(user => user.email === value)) {
                return 'resources.invitations.validation.already_in_team';
              }
            }
          ]}
          fullWidth
        />
      </SimpleForm>
    </CreateDialog>
  );
};

CreateInvitationDialog.defaultProps = {
  existingUserIds: []
};

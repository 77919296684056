import React from 'react';
import { ProjectEnvVarInputs } from '../../components';
import {
  HelpCard,
  HelpLayout,
  HelpLayoutClasses,
  Image,
  SectionHelper,
  SectionTitle
} from '@rc/admin/components';
import { styled, useMediaQuery } from '@mui/material';
import { useSidebarState, useTranslate } from 'react-admin';

export const ProjectCreateStepVariables = () => {
  const t = useTranslate();
  const [isSidebarOpen] = useSidebarState();
  const isSmallContentWidth = useMediaQuery(theme =>
    theme.breakpoints.down(isSidebarOpen ? 'xl' : 'lg')
  );

  return (
    <StyledHelpLayout
      helpers={
        <HelpCard title='resources.projects.helpers.envVars.title'>
          {t('resources.projects.helpers.envVars.content')}
          <Image
            src='/images/helper-envVars.png'
            alt='Variables helper image'
            width={1338}
            height={678}
          />
        </HelpCard>
      }
      isTooltip={isSmallContentWidth}
    >
      <SectionTitle mb={0.5}>
        {t('resources.projects.sections.envVars.title')}
      </SectionTitle>
      <SectionHelper mb={1}>
        {t('resources.projects.sections.envVars.subtitle')}
      </SectionHelper>
      <ProjectEnvVarInputs
        source={'projectEnvVar'}
        label={'resources.projects.fields.projectEnvVar'}
        fullWidth
      />
    </StyledHelpLayout>
  );
};

const StyledHelpLayout = styled(HelpLayout)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    minHeight: 400
  },

  [`& .${HelpLayoutClasses.content}`]: {
    maxWidth: '50em'
  }
}));

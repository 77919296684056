import { required } from 'react-admin';
import zxcvbn from 'zxcvbn';

const FULLNAME_PATTERN = /[A-Za-z ]+$/i;
const EMAIL_PATTERN = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
const USERNAME_PATTERN = /^[a-zA-Z0-9.-]+$/i;

export const validateEmail = value => {
  return !EMAIL_PATTERN.test(value) ? 'ra.validation.email' : undefined;
};

export const validateUsername = value => {
  return !USERNAME_PATTERN.test(value) ? 'validation.username' : undefined;
};

export const validateUsernameOrEmail = value => {
  return /@/.test(value) ? validateEmail(value) : validateUsername(value);
};

export const validatePasswordStrength = value =>
  zxcvbn(value).score < 2 ? 'validation.weak_password' : undefined;

export const validateFullname = value =>
  !FULLNAME_PATTERN.test(value) ? 'validation.letters_only' : undefined;

export const requiredIf = (field, message) => {
  return (value, allValues) => {
    const fieldValue = field.split('.').reduce((acc, curr) => {
      return acc?.[curr];
    }, allValues);

    if (!required(message)(fieldValue)) {
      return required(message)(value);
    }
  };
};

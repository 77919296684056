import { useAxios } from '@rc/utils/axios';
import { useCallback } from 'react';
import { useNotify, useTranslate } from 'react-admin';

export const useEmailExistanceValidation = () => {
  const t = useTranslate();
  const notify = useNotify();

  const [, checkEmail] = useAxios('/api/users/check-email', {
    manual: true
  });

  const checkEmailExistence = useCallback(
    async email => {
      let result = false;

      await checkEmail({
        params: { email }
      })
        .then(r => (result = r.data))
        .catch(e =>
          notify(t('pages.register.notification.error'), {
            type: 'error',
            undoable: false
          })
        );

      return !result.isNotExists
        ? 'validation.email_already_registered'
        : undefined;
    },
    [checkEmail, notify, t]
  );

  return checkEmailExistence;
};

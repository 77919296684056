import React, { useCallback, useEffect, useState } from 'react';
import { Title, useTranslate } from 'react-admin';
import axios from '@rc/utils/axios';
import { Box, Paper, Stack, styled, Tab, Tabs } from '@mui/material';
import PaymentMethodList from './methods/PaymentMethodList';
import { Subscription } from './subscription';
import { InvoiceList } from './requests';
import { TabPanel } from '@rc/admin/components';
import { useLocation, useNavigate } from 'react-router';

const Billing = () => {
  const t = useTranslate();
  const [paymentMethods, setPaymentMethods] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [requests, setRequests] = useState(null);

  const initialize = useCallback(() => {
    const fetchBillingInfo = async userId =>
      await axios.get(`/api/users/${userId}/finance/invoices`);

    const { id: userId } = JSON.parse(window.localStorage.getItem('user'));
    fetchBillingInfo(userId).then(r => {
      const { paymentMethods, customer, invoices } = r.data;

      setPaymentMethods(paymentMethods);
      setCustomer(customer);
      setRequests(invoices);
    });
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const onTabChange = useCallback(
    (oldIdx, newIdx) => {
      // Update methods list when switching to the tab
      if (
        oldIdx === TAB_INDICES.SUBSCRIPTION &&
        newIdx === TAB_INDICES.METHODS
      ) {
        initialize();
      }
    },
    [initialize]
  );

  const { tabIdx, switchTab } = useTabs({ onChange: onTabChange });

  const trustBadge = (
    <Box className={classes.stripeTrustBadge}>
      <img src='/stripe-badge-transparent.png' width={320} height='auto' />
    </Box>
  );

  return (
    <StyledPaper>
      <Title title={t('page.account')} />
      <Paper sx={{ p: 2 }} elevation={1}>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={tabIdx}
              onChange={(_, newValue) => {
                onTabChange(tabIdx, newValue);

                switchTab(newValue);
              }}
            >
              <Tab label={t('pages.billing.tabs.invoices')} />
              <Tab label={t('pages.billing.tabs.methods')} />
              <Tab label={t('pages.billing.tabs.subscription')} />
            </Tabs>
          </Box>
          <TabPanel value={tabIdx} index={TAB_INDICES.INVOICES}>
            <InvoiceList
              requests={requests}
              methods={paymentMethods}
              setList={setRequests}
              goTo={switchTab}
            />
          </TabPanel>
          <TabPanel value={tabIdx} index={TAB_INDICES.METHODS}>
            <PaymentMethodList
              list={paymentMethods}
              customer={customer}
              goTo={switchTab}
              onDelete={initialize}
            />
          </TabPanel>
          <TabPanel value={tabIdx} index={TAB_INDICES.SUBSCRIPTION}>
            <Subscription goTo={switchTab} />
          </TabPanel>
        </Box>
      </Paper>

      {trustBadge}
    </StyledPaper>
  );
};

const TAB_INDICES = {
  INVOICES: 0,
  METHODS: 1,
  SUBSCRIPTION: 2
};

const useTabs = ({ onChange }) => {
  const [tabIdx, setTabIdx] = useState(TAB_INDICES.INVOICES);
  const navigate = useNavigate();
  const location = useLocation();

  const switchTab = useCallback(
    idx => {
      navigate(`${location.pathname.replace(/\/?[0-9]\/?$/, '')}/${idx}`);
    },
    [location.pathname, navigate]
  );

  useEffect(() => {
    const { length, [length - 1]: idxFromLocation } = location.pathname.split(
      '/'
    );

    if (!idxFromLocation || Number.isNaN(Number(idxFromLocation))) {
      switchTab(0);
    } else {
      setTabIdx(Number(idxFromLocation));
      onChange(tabIdx, Number(idxFromLocation));
    }
  }, [location.pathname, onChange, switchTab, tabIdx]);

  return { tabIdx, switchTab };
};

const PREFIX = 'SubscriptionForm';

const classes = {
  stripeTrustBadge: `${PREFIX}-stripeTrustBadge`
};

const StyledPaper = styled(Stack)(({ theme }) => ({
  [`& .${classes.stripeTrustBadge}`]: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(3),

    [`& img`]: {
      [theme.breakpoints.up('md')]: {
        width: 400
      },
      maxWidth: '100%',
      backgroundColor:
        theme.palette.mode === 'dark'
          ? 'rgb(225, 226, 229, 0.75)'
          : 'transparent',
      border:
        theme.palette.mode === 'dark'
          ? 'none'
          : `1px solid ${theme.palette.grey[300]}`,
      borderRadius: theme.shape.borderRadius,
      opacity: 0.95

      // marginRight: theme.spacing(3)
    }
  }
}));

export default Billing;

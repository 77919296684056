import { Box } from '@mui/material';
import { required, TextInput } from 'react-admin';
import React from 'react';

export const DTAwsExternal = () => (
  <>
    <Box width={'100%'}>
      <TextInput
        source={'dataTransfer.awsSettings.region'}
        label={
          'resources.imported_datas.fields.dataTransfer.awsSettings.region'
        }
        placeholder={'eu-west-1'}
        validate={[required()]}
        fullWidth
      />
    </Box>
    <Box width={'100%'}>
      <TextInput
        source={'dataTransfer.awsSettings.endpointUrl'}
        label={
          'resources.imported_datas.fields.dataTransfer.awsSettings.endpointUrl'
        }
        placeholder={'s3.example-region.amazonaws.com'}
        validate={[required()]}
        fullWidth
      />
    </Box>
    <Box width={'100%'}>
      <TextInput
        source={'dataTransfer.awsSettings.accessKeyId'}
        label={
          'resources.imported_datas.fields.dataTransfer.awsSettings.accessKeyId'
        }
        validate={[required()]}
        fullWidth
      />
    </Box>
    <Box width={'100%'}>
      <TextInput
        source={'dataTransfer.awsSettings.secretAccessKey'}
        label={
          'resources.imported_datas.fields.dataTransfer.awsSettings.secretAccessKey'
        }
        validate={[required()]}
        fullWidth
      />
    </Box>
    <Box width={'100%'}>
      <TextInput
        source={'dataTransfer.awsSettings.bucket'}
        label={
          'resources.imported_datas.fields.dataTransfer.awsSettings.bucket'
        }
        validate={[required()]}
        fullWidth
      />
    </Box>
    <Box width={'100%'}>
      <TextInput
        source={'dataTransfer.awsSettings.key'}
        label={'resources.imported_datas.fields.dataTransfer.awsSettings.key'}
        placeholder={'/path/to/file.gzip'}
        validate={[required()]}
        fullWidth
      />
    </Box>
  </>
);

export default DTAwsExternal;

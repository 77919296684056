import { useRecordContext, useResourceContext } from 'react-admin';
import { useUser } from '../domain';

export const useUserTeamRoleGroup = () => {
  const resource = useResourceContext();
  const record = useRecordContext();
  const { user, isLoading } = useUser();

  const teamRole =
    resource === 'teams' && record && user
      ? user.teamRoles.find(({ team }) => team === record['@id'])
      : null;

  const roleGroupOriginId = teamRole?.roleGroup?.split('/').pop();

  return {
    isLoading: isLoading,
    roleGroup:
      roleGroupOriginId !== undefined ? Number(roleGroupOriginId) : null
  };
};

import React from 'react';
import { DeveloperBoard, Inventory, Memory } from '@mui/icons-material';
import { useTranslate } from 'react-admin';

export const CpuIcon = () => {
  const t = useTranslate();
  return (
    <DeveloperBoard titleAccess={t('resources.resource_types.names.cpu')} />
  );
};
export const MemoryIcon = () => {
  const t = useTranslate();
  return <Memory titleAccess={t('resources.resource_types.names.memory')} />;
};
export const StorageIcon = () => {
  const t = useTranslate();
  return (
    <Inventory titleAccess={t('resources.resource_types.names.storage')} />
  );
};

import React, { useCallback, useState } from 'react';
import {
  Button as RaButton,
  useInput,
  useNotify,
  useTranslate
} from 'react-admin';
import { useFormContext, useWatch } from 'react-hook-form';
import { Check, RepeatRounded } from '@mui/icons-material';
import { CircularProgress, Typography } from '@mui/material';
import { useAxios } from '@rc/utils/axios';
import { Iconify } from '../../ui';

/**
 *
 * @param {object} props
 * @param {string} props.source
 * @param {boolean} props.disabled
 * @param {function} props.createOrUpdateGit
 * @param {boolean} props.isCreateOrUpdateGitLoading
 * @returns
 */
export const ConnectionTest = props => {
  const { source, disabled, createOrUpdateGit, isCreateOrUpdateGitLoading } =
    props;
  const t = useTranslate();
  const notify = useNotify();
  const { setValue, clearErrors } = useFormContext();

  const validate = useCallback(
    value => {
      if (!value || !value.isSuccessful) {
        return t('components.git.validation.connection_is_not_tested');
      }
    },
    [t]
  );

  const { id, field, fieldState } = useInput({
    source,
    rules: {
      validate
    }
  });

  const connectionTestResult = useWatch({ name: source });
  const [isLoading, setIsLoading] = useState(false);

  const [{ loading: isConnectTestLoading }, connectSSH] = useAxios(
    {},
    { manual: true }
  );

  const onTestConnection = useCallback(async () => {
    setIsLoading(true);

    const errorMessage = 'components.git.validation.connection_test_failed';
    clearErrors(source);

    try {
      const { data: git } = await createOrUpdateGit();

      const { data } = await connectSSH({
        url: `/api/gits/${git.originId}/connect-test`
      });

      if (!data.isSuccessful) {
        throw new Error(errorMessage);
      } else {
        setValue(
          source,
          {
            isSuccessful: true
          },
          {
            shouldValidate: false,
            shouldDirty: true,
            shouldTouch: true
          }
        );
      }
    } catch (error) {
      if (error?.message !== errorMessage) {
        console.error(error);
      }
      setValue(
        source,
        {
          isSuccessful: false
        },
        {
          shouldValidate: false,
          shouldDirty: true,
          shouldTouch: true
        }
      );
      notify(errorMessage, { type: 'error' });
    } finally {
      setIsLoading(false);
    }
  }, [clearErrors, connectSSH, createOrUpdateGit, notify, setValue, source]);

  const isSuccessful = connectionTestResult?.isSuccessful === true;
  const isUnsuccessful = connectionTestResult?.isSuccessful === false;

  return (
    <>
      <input type='hidden' id={id} {...field} />
      <RaButton
        type='button'
        variant='contained'
        label={
          isSuccessful
            ? 'components.git.message.connection_tested'
            : 'components.git.action.test_connection'
        }
        disabled={
          disabled || isCreateOrUpdateGitLoading || isConnectTestLoading
        }
        onClick={onTestConnection}
        color={isSuccessful ? 'success' : isUnsuccessful ? 'error' : 'primary'}
        size='medium'
        endIcon={
          isConnectTestLoading || isLoading ? (
            <CircularProgress size={18} />
          ) : isSuccessful ? (
            <Iconify icon='solar:check-read-outline' />
          ) : isUnsuccessful ? (
            <Iconify icon='solar:refresh-bold' />
          ) : undefined
        }
        sx={theme => ({ marginTop: theme.spacing(0.5), textTransform: 'none' })}
      />
      {fieldState.error?.message && (
        <Typography variant='body2' color='error' mt={0.5}>
          {fieldState.error.message}
        </Typography>
      )}
    </>
  );
};

import React, { useMemo } from 'react';
// import { LoadingField } from '@rc/admin/components';
import { useGetList, useResourceContext, useChoicesContext } from 'ra-core';
import { SelectInput } from 'react-admin';

export const ProjectTypeVersionInput = props => {
  const resource = useResourceContext();
  const {
    allChoices: projectTypeVersions,
    isLoading: isLoadingProjectTypeVersions
  } = useChoicesContext();

  const { data: projectTypes, isLoading: isLoadingProjectTypes } = useGetList(
    'project_types'
  );

  const choices = useMemo(() => {
    if (!projectTypes) {
      return [];
    }

    return projectTypes.reduce((acc, projectType) => {
      acc.push({ ...projectType, disabled: true });

      acc = acc.concat(
        projectType.projectTypeVersion.map(projectTypeVersionId =>
          projectTypeVersions.find(({ id }) => id === projectTypeVersionId)
        )
      );
      return acc;
    }, []);
  }, [projectTypeVersions, projectTypes]);

  return (
    <SelectInput
      resource={resource}
      source={'projectTypeVersion'}
      choices={choices}
      isLoading={isLoadingProjectTypes || isLoadingProjectTypeVersions}
      isFetching={isLoadingProjectTypes || isLoadingProjectTypeVersions}
      optionText={choice => choice.version || choice.name}
      optionValue={'@id'}
      {...props}
    />
  );
};

import React, { useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Box,
  Grid,
  styled,
  accordionClasses
} from '@mui/material';
import {
  Labeled,
  RecordContextProvider,
  TextField,
  UrlField,
  useRecordContext,
  useTranslate
} from 'react-admin';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  LoadingBox,
  PasswordField,
  SectionTitle,
  WithCopyToClipboard
} from '@rc/admin/components';
import { useEnvironmentAccess } from '@rc/admin/hooks';

export const EnvironmentAccessFields = () => {
  const t = useTranslate();
  const { isLoading, value: environmentAccess } = useEnvironmentAccess();
  const record = useRecordContext();
  const [expanded, setExpanded] = useState('application');

  const handleChange = panel => (_, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  if (isLoading) {
    return <LoadingBox minHeight={48} size={24} />;
  } else if (!environmentAccess) {
    return (
      <Typography variant={'body2'} sx={{ opacity: 0.75 }}>
        {t('resources.environments.message.no_environment_access')}
      </Typography>
    );
  }

  const isHtaccess = record.isAllowHtaccessDevtoolsProtection;

  return (
    <RecordContextProvider value={environmentAccess}>
      {isHtaccess && (
        <Box sx={{ width: '100%', my: 1, px: 2 }}>
          <SectionTitle variant='h6'>
            {t(
              'resources.environments.sections.access.sections.devTools.title'
            )}
          </SectionTitle>
          {environmentAccess.devTools?.['@id'] && (
            <Grid container wrap='wrap' spacing={1}>
              <Grid item xs={12} sm={6}>
                <Labeled label={'resources.environments.fields.accessUsername'}>
                  <WithCopyToClipboard>
                    <TextField source={`devTools.username`} fullWidth />
                  </WithCopyToClipboard>
                </Labeled>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Labeled label={'resources.environments.fields.accessPassword'}>
                  <WithCopyToClipboard>
                    <PasswordField
                      source={`devTools.password`}
                      decodeBase64
                      fullWidth
                    />
                  </WithCopyToClipboard>
                </Labeled>
              </Grid>
            </Grid>
          )}
        </Box>
      )}

      {/* Backend */}
      {environmentAccess.backend?.['@id'] && (
        <StyledAccordion
          expanded={expanded === 'application'}
          onChange={handleChange('application')}
          sx={{ width: '100%' }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='applicationbh-content'
            id='applicationbh-header'
          >
            <Typography sx={{ width: '33%', flexShrink: 0 }}>
              {t(
                'resources.environments.sections.access.sections.application.title'
              )}
            </Typography>
            <Typography sx={{ color: 'text.secondary' }} />
          </AccordionSummary>
          <AccordionDetails>
            <Grid container wrap='wrap' spacing={1}>
              <Grid item xs={12}>
                <Labeled
                  label={'resources.environments.fields.accessFrontendUrl'}
                >
                  <WithCopyToClipboard disableClick>
                    <UrlField
                      source={`frontend.url`}
                      fullWidth
                      target={'_blank'}
                    />
                  </WithCopyToClipboard>
                </Labeled>
              </Grid>
              <Grid item xs={12}>
                <Labeled
                  label={'resources.environments.fields.accessBackendUrl'}
                >
                  <WithCopyToClipboard disableClick>
                    <UrlField
                      source={`backend.url`}
                      fullWidth
                      target={'_blank'}
                    />
                  </WithCopyToClipboard>
                </Labeled>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Labeled label={'resources.environments.fields.accessUsername'}>
                  <WithCopyToClipboard>
                    <TextField source={`backend.username`} fullWidth />
                  </WithCopyToClipboard>
                </Labeled>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Labeled label={'resources.environments.fields.accessPassword'}>
                  <WithCopyToClipboard>
                    <PasswordField
                      source={`backend.password`}
                      decodeBase64
                      fullWidth
                    />
                  </WithCopyToClipboard>
                </Labeled>
              </Grid>
            </Grid>
          </AccordionDetails>
        </StyledAccordion>
      )}

      {/* Mailbox */}
      {!record?.isAllowOutgoingEmails && environmentAccess.mailbox?.['@id'] && (
        <StyledAccordion
          expanded={expanded === 'mailbox'}
          onChange={handleChange('mailbox')}
          sx={{ width: '100%' }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='mailboxbh-content'
            id='mailboxbh-header'
          >
            <Typography sx={{ width: '33%', flexShrink: 0 }}>
              {'Mailbox'}
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>
              {'Access to Mailbox'}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container wrap='wrap' spacing={1}>
              <Grid item xs={12}>
                <Labeled label={'Url'}>
                  <UrlField
                    source={`mailbox.url`}
                    fullWidth
                    target={'_blank'}
                  />
                </Labeled>
              </Grid>
            </Grid>
          </AccordionDetails>
        </StyledAccordion>
      )}

      {/* {recordValue.devTools?.['@id'] && (
        <StyledAccordion
          expanded={expanded === 'devTools'}
          onChange={handleChange('devTools')}
          sx={{ width: '100%' }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='devToolsbh-content'
            id='devToolsbh-header'
          >
            <Typography sx={{ width: '33%', flexShrink: 0 }}>
              {'DevTools'}
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>
              {'Developer tools for your environment'}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container wrap='wrap' spacing={1}>
              <Grid item xs={12}>
                <Labeled label={'Url'}>
                  <UrlField
                    source={`devTools.url`}
                    fullWidth
                    target={'_blank'}
                  />
                </Labeled>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Labeled label={'resources.environments.fields.accessUsername'}>
                  <TextField source={`devTools.username`} fullWidth />
                </Labeled>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Labeled label={'Password'}>
                  <PasswordField source={`devTools.password`} decodeBase64 fullWidth />
                </Labeled>
              </Grid>
            </Grid>
          </AccordionDetails>
        </StyledAccordion>
      )} */}

      {/* Database */}
      {environmentAccess.database?.['@id'] && (
        <StyledAccordion
          expanded={expanded === 'phpmyadmin'}
          onChange={handleChange('phpmyadmin')}
          sx={{ width: '100%' }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='phpmyadminbh-content'
            id='phpmyadminbh-header'
          >
            <Typography sx={{ width: '33%', flexShrink: 0 }}>
              {t(
                'resources.environments.sections.access.sections.database.title'
              )}
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>
              {t(
                'resources.environments.sections.access.sections.database.subtitle'
              )}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container wrap='wrap' spacing={1}>
              <Grid item xs={12}>
                <Labeled label={'resources.environments.fields.accessUrl'}>
                  <WithCopyToClipboard disableClick>
                    <UrlField
                      source={`database.url`}
                      fullWidth
                      target={'_blank'}
                    />
                  </WithCopyToClipboard>
                </Labeled>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Labeled label={'resources.environments.fields.accessUsername'}>
                  <WithCopyToClipboard>
                    <TextField source={`database.username`} fullWidth />
                  </WithCopyToClipboard>
                </Labeled>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Labeled label={'resources.environments.fields.accessPassword'}>
                  <WithCopyToClipboard>
                    <PasswordField
                      source={`database.password`}
                      decodeBase64
                      fullWidth
                    />
                  </WithCopyToClipboard>
                </Labeled>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Labeled label={'resources.environments.fields.accessHost'}>
                  <WithCopyToClipboard>
                    <TextField source={`database.host`} fullWidth />
                  </WithCopyToClipboard>
                </Labeled>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Labeled label={'resources.environments.fields.accessPort'}>
                  <WithCopyToClipboard>
                    <TextField source={`database.port`} fullWidth />
                  </WithCopyToClipboard>
                </Labeled>
              </Grid>
              <Grid item xs={12}>
                <Labeled label={'resources.environments.fields.accessScheme'}>
                  <WithCopyToClipboard>
                    <TextField source={`database.scheme`} fullWidth />
                  </WithCopyToClipboard>
                </Labeled>
              </Grid>
            </Grid>
          </AccordionDetails>
        </StyledAccordion>
      )}

      {/* Webshell */}
      {environmentAccess.webshell?.['@id'] && (
        <StyledAccordion
          expanded={expanded === 'webshell'}
          onChange={handleChange('webshell')}
          sx={{ width: '100%' }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='webshellbh-content'
            id='webshellbh-header'
          >
            <Typography sx={{ width: '33%', flexShrink: 0 }}>
              {'Webshell'}
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>
              {'Access to Webshell'}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container wrap='wrap' spacing={1}>
              <Grid item xs={12}>
                <Labeled label={'resources.environments.fields.accessUrl'}>
                  <WithCopyToClipboard disableClick>
                    <UrlField
                      source={`webshell.url`}
                      fullWidth
                      target={'_blank'}
                    />
                  </WithCopyToClipboard>
                </Labeled>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Labeled label={'resources.environments.fields.accessUsername'}>
                  <WithCopyToClipboard>
                    <TextField source={`webshell.username`} fullWidth />
                  </WithCopyToClipboard>
                </Labeled>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Labeled label={'resources.environments.fields.accessPassword'}>
                  <WithCopyToClipboard>
                    <PasswordField
                      source={`webshell.password`}
                      decodeBase64
                      fullWidth
                    />
                  </WithCopyToClipboard>
                </Labeled>
              </Grid>
            </Grid>
          </AccordionDetails>
        </StyledAccordion>
      )}

      {/* Redis */}
      {environmentAccess.redis?.['@id'] && (
        <StyledAccordion
          expanded={expanded === 'redisadmin'}
          onChange={handleChange('redisadmin')}
          sx={{ width: '100%' }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='redisadminbh-content'
            id='redisadminbh-header'
          >
            <Typography sx={{ width: '33%', flexShrink: 0 }}>
              {t('resources.environments.sections.access.sections.redis.title')}
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>
              {t(
                'resources.environments.sections.access.sections.redis.subtitle'
              )}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container wrap='wrap' spacing={1}>
              <Grid item xs={12}>
                <Labeled label={'resources.environments.fields.accessUrl'}>
                  <WithCopyToClipboard disableClick>
                    <UrlField
                      source={`redis.url`}
                      fullWidth
                      target={'_blank'}
                    />
                  </WithCopyToClipboard>
                </Labeled>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Labeled label={'resources.environments.fields.accessHost'}>
                  <WithCopyToClipboard>
                    <TextField source={`redis.host`} fullWidth />
                  </WithCopyToClipboard>
                </Labeled>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Labeled label={'resources.environments.fields.accessPassword'}>
                  <WithCopyToClipboard>
                    <PasswordField
                      source={`redis.password`}
                      decodeBase64
                      fullWidth
                    />
                  </WithCopyToClipboard>
                </Labeled>
              </Grid>
              <Grid item xs={12}>
                <Labeled label={'resources.environments.fields.accessPort'}>
                  <WithCopyToClipboard>
                    <TextField source={`redis.port`} fullWidth />
                  </WithCopyToClipboard>
                </Labeled>
              </Grid>
            </Grid>
          </AccordionDetails>
        </StyledAccordion>
      )}

      {/* Rabbit */}
      {environmentAccess.rabbit?.['@id'] && (
        <StyledAccordion
          expanded={expanded === 'rabbitmq'}
          onChange={handleChange('rabbitmq')}
          sx={{ width: '100%' }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='rabbitmqbh-content'
            id='rabbitmqbh-header'
          >
            <Typography sx={{ width: '33%', flexShrink: 0 }}>
              {t(
                'resources.environments.sections.access.sections.rabbitmq.title'
              )}
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>
              {t(
                'resources.environments.sections.access.sections.rabbitmq.subtitle'
              )}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container wrap='wrap' spacing={1}>
              <Grid item xs={12}>
                <Labeled label={'resources.environments.fields.accessUrl'}>
                  <WithCopyToClipboard disableClick>
                    <UrlField
                      source={`rabbit.url`}
                      fullWidth
                      target={'_blank'}
                    />
                  </WithCopyToClipboard>
                </Labeled>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Labeled label={'resources.environments.fields.accessUsername'}>
                  <WithCopyToClipboard>
                    <TextField source={`rabbit.username`} fullWidth />
                  </WithCopyToClipboard>
                </Labeled>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Labeled label={'resources.environments.fields.accessPassword'}>
                  <WithCopyToClipboard>
                    <PasswordField
                      source={`rabbit.password`}
                      decodeBase64
                      fullWidth
                    />
                  </WithCopyToClipboard>
                </Labeled>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Labeled label={'resources.environments.fields.accessHost'}>
                  <WithCopyToClipboard>
                    <TextField source={`rabbit.host`} fullWidth />
                  </WithCopyToClipboard>
                </Labeled>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Labeled label={'resources.environments.fields.accessPort'}>
                  <WithCopyToClipboard>
                    <TextField source={`rabbit.port`} fullWidth />
                  </WithCopyToClipboard>
                </Labeled>
              </Grid>
            </Grid>
          </AccordionDetails>
        </StyledAccordion>
      )}
    </RecordContextProvider>
  );
};

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  width: '100%',
  borderWidth: '1px 0 0',
  borderRadius: 0,

  [`&.${accordionClasses.expanded}`]: {
    boxShadow: 'none',
    margin: 0
  }
}));

export default EnvironmentAccessFields;

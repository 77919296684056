import React from 'react';
import {
  ChoicesContextProvider,
  sanitizeInputRestProps,
  SelectInput,
  useChoicesContext
} from 'react-admin';
import {
  useGroupedChoices,
  sanitizeGroupedInputProps
} from '../useGroupedChoices';

/**
 * @typedef GroupedSelectInputProps
 * @property {string} groupReference Resource name of the parent that makes the group.
 *
 * @param {import('react-admin').SelectInputProps & import('../useGroupedChoices').useGroupedChoicesProps} props
 * @returns
 */
const GroupedSelectInput = props => {
  const { fullWidth, sx, label } = props;
  const {
    choices,
    isLoading,
    isFetching,
    optionText,
    validate
  } = useGroupedChoices(props);
  const choicesContext = useChoicesContext();

  return (
    <ChoicesContextProvider
      value={{
        ...choicesContext,
        allChoices: choices,
        availableChoices: choices,
        isLoading,
        isFetching
      }}
    >
      <SelectInput
        label={label}
        {...sanitizeInputRestProps(sanitizeGroupedInputProps(props))}
        sx={{ ...sx, width: fullWidth ? '100%' : sx?.width }}
        optionText={optionText}
        validate={validate}
      />
    </ChoicesContextProvider>
  );
};

GroupedSelectInput.defaultProps = {
  optionText: 'name',
  optionValue: 'id',
  ...SelectInput.defaultProps
};

export default GroupedSelectInput;

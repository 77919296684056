import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { useTheme } from '@mui/material';

const stripePromise = loadStripe(
  window.document.getElementById('stripe-pk').innerText
);

export const SubscriptionContext = props => {
  const theme = useTheme();

  /**
   * @type {import('@stripe/stripe-js').StripeElementsOptions}
   */
  const options = {
    // passing the client secret obtained in step 2
    clientSecret: props.clientSecret,
    // Fully customizable with appearance API.
    appearance: {
      theme: 'stripe',
      variables: {
        colorPrimary: theme.palette.primary[theme.palette.mode],
        colorText: theme.palette.text.primary,
        colorBackground: theme.palette.background.default
      }
    },
    // Enable the skeleton loader UI for the optimal loading experience.
    loader: 'auto'
  };

  return (
    <Elements stripe={stripePromise} options={options}>
      {props.children}
    </Elements>
  );
};

export default SubscriptionContext;

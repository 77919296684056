import React from 'react';
import { SelectInput, useChoicesContext, useGetList } from 'react-admin';
import { useGetOrganisationName } from '@rc/admin/hooks/domain';

export const OrganisationInput = props => {
  const { isLoading: isChoicesLoading } = useChoicesContext();
  const getOrganisationName = useGetOrganisationName();

  return (
    <SelectInput
      isLoading={isChoicesLoading}
      optionText={choice => getOrganisationName(choice)}
      {...props}
    />
  );
};

import React, { useMemo } from 'react';
import { useLocation } from 'react-router';
import {
  Create,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  ReferenceField,
  TextField
} from 'react-admin';
import { styled } from '@mui/material';
import { OneColumnFormStyles } from '@rc/admin/theme/styles';

export const ComponentVersionCreate = () => {
  const { search } = useLocation();

  const initialComponentId = useMemo(
    () => new URLSearchParams(search).get('component'),
    [search]
  );

  return (
    <StyledCreate>
      <SimpleForm
        defaultValues={{
          isEnabled: true,
          component: initialComponentId
        }}
        className={classes.form}
      >
        {!initialComponentId ? (
          <ReferenceInput source={'component'} reference={'components'}>
            <SelectInput optionText={'name'} />
          </ReferenceInput>
        ) : (
          <ReferenceField
            source={'component'}
            reference={'components'}
            record={{ component: initialComponentId }}
          >
            <TextField source={'name'} />
          </ReferenceField>
        )}
        <TextInput source={'version'} fullWidth />
      </SimpleForm>
    </StyledCreate>
  );
};

const PREFIX = 'ComponentVersionCreate';

const classes = {
  form: `${PREFIX}-form`
};

const StyledCreate = styled(Create)(({ theme }) => ({
  [`& .${classes.form}`]: {
    ...OneColumnFormStyles(theme)
  }
}));

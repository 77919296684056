import React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  BooleanInput,
  Toolbar,
  SaveButton,
  ToolbarClasses
} from 'react-admin';
import { Breadcrumbs, DeleteButton, EditTitle } from '@rc/admin/components';
import { styled } from '@mui/material';
import { OneColumnFormStyles } from '@rc/admin/theme/styles';

const redirect = (_, __, record) =>
  `component_version_env_vars/${encodeURIComponent(
    record?.componentVersionEnvVar
  )}`;

export const ComponentVersionEnvVarExampleEdit = () => {
  return (
    <StyledEdit
      title={<EditTitle source={'value'} />}
      actions={
        <Breadcrumbs
          label={<BreadcrumbsLabel />}
          parents={breadcrumbsParents}
        />
      }
      redirect={redirect}
      mutationMode='optimistic'
    >
      <SimpleForm
        redirect={'edit'}
        className={classes.form}
        toolbar={
          <Toolbar>
            <div className={ToolbarClasses.defaultToolbar}>
              <SaveButton />
              <DeleteButton redirect={redirect} />
            </div>
          </Toolbar>
        }
      >
        <TextInput source={'value'} fullWidth />
        <BooleanInput source={'isDefault'} fullWidth />
      </SimpleForm>
    </StyledEdit>
  );
};

const breadcrumbsParents = [
  {
    reference: 'component_version_env_vars',
    source: 'componentVersionEnvVar'
  },
  { reference: 'component_versions', source: 'componentVersion' },
  { reference: 'components', source: 'component' }
];

const BreadcrumbsLabel = props => {
  const { data } = props;

  switch (data['@type']) {
    case 'ComponentVersionEnvVarExample':
      return data.value;
    case 'ComponentVersionEnvVar':
      return data.key;
    case 'ComponentVersion':
      return data.version;
    default:
      return data.name;
  }
};

const PREFIX = 'ComponentVersionEnvVarExampleEdit';

const classes = {
  form: `${PREFIX}-form`
};

const StyledEdit = styled(Edit)(({ theme }) => ({
  [`& .${classes.form}`]: {
    ...OneColumnFormStyles(theme)
  }
}));

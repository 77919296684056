import React, { useCallback, useState } from 'react';
import { useTranslate } from 'react-admin';
import { useFormContext, useWatch } from 'react-hook-form';
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import { Iconify, ResourceInput } from '@rc/admin/components';
import { getSourceUtil } from '@rc/admin/utils';

const STORAGE_TYPE_OPTIONS = [
  {
    label: 'resources.environments.fields.storage_type.ephemeral',
    value: 'ephemeral'
  },
  {
    label: 'resources.environments.fields.storage_type.persistent',
    value: 'persistent'
  }
];

const ephemeralStorageLabel = STORAGE_TYPE_OPTIONS[0].label;
const ephemeralStorageType = STORAGE_TYPE_OPTIONS[0].value;
const persistentStorageType = STORAGE_TYPE_OPTIONS[1].value;

/**
 *
 * @param {import('@rc/admin/components/domain/resource/ResourceInput/ResourceInput').ResourceInputProps} props
 * @returns
 */
export const EnvironmentStorageResourceInput = props => {
  const { source, lowerLimit, resourceMin } = props;
  const t = useTranslate();
  const { member, index } = getSourceUtil(source);
  const [environmentComponentId, value] = useWatch({
    name: [`${member}[${index}].[@id]`, source]
  });
  const { setValue } = useFormContext();

  const isSaved = environmentComponentId !== undefined;

  // TODO: Get from admin settings by min === 0 or min > 0

  const hasMinValueOverridedByAdmin = !!resourceMin;

  const storageType =
    value >= lowerLimit || hasMinValueOverridedByAdmin
      ? persistentStorageType
      : ephemeralStorageType;

  const handleStorageTypeChange = useCallback(
    event => {
      const { value } = event.target;

      setValue(source, value === persistentStorageType ? lowerLimit : 0);
    },
    [lowerLimit, setValue, source]
  );

  if (
    hasMinValueOverridedByAdmin ||
    (isSaved && storageType === persistentStorageType)
  ) {
    return <ResourceInput {...props} />;
  }

  const shouldShowResourceInput = storageType === persistentStorageType;

  return (
    <Box width={'100%'}>
      <FormControl
        fullWidth
        sx={{
          mb: shouldShowResourceInput ? 1 : 2
        }}
      >
        <FormLabel
          id='storage-type-select-label'
          size='small'
          sx={theme => ({
            fontSize: theme.typography.caption.fontSize,
            '&.Mui-focused, &.Mui-hover': {
              color: theme.palette.text.secondary
            }
          })}
        >
          <Stack direction='row' alignItems='center'>
            <Box>{t('resources.resource_types.inputs.storage_type.label')}</Box>
            <Box sx={{ ml: 1 }}>
              <Tooltip
                title={
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t('resources.environments.helpers.storage_type')
                    }}
                  />
                }
                sx={{
                  padding: 0
                }}
              >
                <IconButton>
                  <Iconify icon='solar:info-circle-outline' width={16} />
                </IconButton>
              </Tooltip>
            </Box>
          </Stack>
        </FormLabel>
        {isSaved && storageType === ephemeralStorageType ? (
          <Typography variant='body2' mt={0.5}>
            {t(ephemeralStorageLabel)}
          </Typography>
        ) : (
          <RadioGroup
            row
            aria-labelledby='storage-type-select-label'
            value={storageType}
            onChange={handleStorageTypeChange}
          >
            {STORAGE_TYPE_OPTIONS.map(({ label, value }) => (
              <FormControlLabel
                key={value}
                value={value}
                control={<Radio />}
                label={t(label)}
              />
            ))}
          </RadioGroup>
        )}
      </FormControl>
      {shouldShowResourceInput && <ResourceInput {...props} />}
    </Box>
  );
};

import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useCostAnalyzerChart } from '@rc/admin/hooks';
import {
  Box,
  ButtonBase,
  Card,
  CardHeader,
  Chip,
  MenuItem,
  Stack,
  useTheme
} from '@mui/material';
import CustomPopover, {
  usePopover
} from '@minimal-theme/components/custom-popover';
import Chart, { useChart } from '../../ui/Chart';
import { Iconify } from '../../ui';

export const CostAnalyzerChart = props => {
  const { title, subheader, ...rest } = props;
  const { chart } = useCostAnalyzerChart({
    budget: 800
  });
  const theme = useTheme();

  const {
    colors = [
      [theme.palette.primary.light, theme.palette.primary.main],
      [theme.palette.warning.light, theme.palette.warning.main]
    ],
    categories,
    series,
    options
  } = chart;

  const popover = usePopover();

  const chartOptions = useChart({
    colors: colors.map(colr => colr[1]),
    fill: {
      type: 'gradient',
      gradient: {
        colorStops: colors.map(colr => [
          { offset: 0, color: colr[0], opacity: 1 },
          { offset: 100, color: colr[1], opacity: 1 }
        ])
      }
    },
    xaxis: {
      categories
    },
    ...options
  });

  return (
    <>
      <Card
        {...rest}
        sx={{
          height: '100%',
          position: 'relative',
          '&:hover': {
            '& #overlay': {
              display: 'none'
            }
          }
        }}
      >
        <CardHeader
          title={
            <Stack direction={'row'} spacing={2} alignItems={'center'}>
              {title}
              <Chip
                size='small'
                label='Coming soon'
                color='primary'
                sx={{ fontWeight: 600 }}
              />
            </Stack>
          }
          subheader={subheader}
        />

        <Box
          id='overlay'
          sx={{
            position: 'absolute',
            zIndex: 2,
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            opacity: 0.35,
            backgroundColor: '#acacac05',
            backdropFilter: 'blur(6px)'
          }}
        />

        {series.map(item => (
          <Box key={item.month} sx={{ mt: 3, mx: 3 }}>
            <Chart
              dir='ltr'
              type='line'
              series={item.data}
              options={chartOptions}
              width='100%'
              height={364}
            />
          </Box>
        ))}
      </Card>
    </>
  );
};

CostAnalyzerChart.propTypes = {
  chart: PropTypes.object,
  subheader: PropTypes.string,
  title: PropTypes.string
};

import React from 'react';
import { useLayoutContext } from '@rc/admin/context';
import NavMini from '@minimal-theme/layouts/dashboard/nav-mini';
import NavVertical from '@minimal-theme/layouts/dashboard/nav-vertical';

const Sidebar = () => {
  const { navType } = useLayoutContext();

  return navType === 'vertical' ? (
    <NavVertical />
  ) : navType === 'mini' ? (
    <NavMini />
  ) : null;
};

export default Sidebar;

import React from 'react'
import { Box, Stack, styled } from '@mui/material';
import {
  Breadcrumbs,
  LoadingBox,
  RedirectWithNotification,
  ReferenceInputWithDependency,
  SectionHelper,
  SectionTitle,
  // ErrorMessage,
  WizardForm,
  WizardFormClasses,
  WizardFormStep
} from '@rc/admin/components';
import {
  CLUSTER_REFERENCE_FIELD_DEPENDENCIES,
  STATE_IDS
} from '@rc/admin/constants';
import {
  // FeatureTour,
  ProjectContextProvider
} from '@rc/admin/context';
import { TwoColumnFormStyles } from '@rc/admin/theme/styles';
import { useMemo } from 'react';
import {
  BooleanInput,
  Create,
  CreateClasses,
  NotFound,
  ReferenceInput,
  SelectInput,
  required,
  useTranslate
} from 'react-admin';
import {
  AllowHtaccessDevtoolsProtectionField,
  CopyEnvironmentField,
  CustomDomainFields,
  MagentoHostMappingsFields,
  EnvironmentNameInputs
} from '../components';
import { COPY_ENVIRONMENT_SOURCE } from '../constants';
import { environmentCreateTransform } from '../utils';
import { EnvironmentCreateStepResources } from './EnvironmentCreateStepResources';
import { EnvironmentCreateStepVariables } from './EnvironmentCreateStepVariables';
import { useEnvironmentCreate } from '../hooks';
import { createIRI } from '@rc/admin/utils';
// import EnvironmentCreateDetailsTour from './CreateFeatureTour.js';

export const EnvironmentCreate = () => {
  const t = useTranslate();

  const {
    isLoading,
    isError,
    defaultValues,
    project,
    projectId,
    isCreateLoading,
    isImportLoading,
    isTemplate,
    environmentComponentLastCopiedFrom,
    handleSubmit,
    siblingEnvironments
  } = useEnvironmentCreate();

  // const canCopyFromEnvironment = !!project?.environment?.length;
  const canCopyFromEnvironment = !!(siblingEnvironments || []).filter(
    (environment) =>
      environment.state !== createIRI('states', STATE_IDS.DELETED)
  ).length;

  // const tourOptions = useMemo(
  //   () => ({ canCopyFromEnvironment }),
  //   [canCopyFromEnvironment]
  // );

  const breadcrumbsParents = useMemo(
    () => [
      {
        reference: isTemplate ? 'template_projects' : 'projects',
        source: isTemplate ? 'templateProject' : 'project'
      }
    ],
    [isTemplate]
  );

  if (!projectId) {
    return (
      <RedirectWithNotification
        to={'/'}
        message={'resources.environments.notification.project_not_selected'}
        options={{ type: 'info', undoable: false }}
      />
    );
  } else if (isLoading) {
    return <LoadingBox minHeight={200} />;
  } else if (isError) {
    return (
      <NotFound
        title={t('resources.environments.notification.failed_to_fetch_project')}
      />
    );
  }

  return (
    <StyledCreate
      actions={
        <Breadcrumbs
          data={{ [isTemplate ? 'templateProject' : 'project']: projectId }}
          parents={breadcrumbsParents}
          label={<BreadcrumbsLabel />}
        />
      }
      transform={environmentCreateTransform}
    >
      <WizardForm
        defaultValues={defaultValues}
        onSubmit={handleSubmit}
        disableSave={isCreateLoading || isImportLoading}
        mode='all'
      >
        <WizardFormStep label='resources.environments.tabs.details'>
          <ProjectContextProvider>
            {/* <FeatureTour
            tour={EnvironmentCreateDetailsTour}
            timeout={1500}
            options={tourOptions}
          /> */}
            <Stack>
              <Box>
                <SectionTitle mb={0.5}>
                  {t('resources.environments.sections.name')}
                </SectionTitle>
                <EnvironmentNameInputs />
              </Box>

              {!isTemplate && (
                <Stack>
                  <SectionTitle mb={0.5}>
                    {t('resources.environments.sections.infrastructure')}
                  </SectionTitle>
                  <SectionHelper mb={1}>
                    {t('resources.environments.helpers.infrastructure')}
                  </SectionHelper>
                  <Box
                    id='provider-fields'
                    display={{ xs: 'block', sm: 'flex' }}
                    columnGap={1}
                  >
                    <Box flex={1}>
                      <ReferenceInput
                        source={'provider'}
                        reference={'providers'}
                      >
                        <SelectInput
                          id='provider-name'
                          optionText={'name'}
                          validate={[required()]}
                          fullWidth
                          onFocus={() => {}}
                        />
                      </ReferenceInput>
                    </Box>
                    <Box flex={1}>
                      <ReferenceInputWithDependency
                        source={'cluster'}
                        reference={'clusters'}
                        dependencies={CLUSTER_REFERENCE_FIELD_DEPENDENCIES}
                      >
                        <SelectInput
                          optionText={'name'}
                          validate={[required()]}
                          fullWidth
                        />
                      </ReferenceInputWithDependency>
                    </Box>
                  </Box>

                  <SectionTitle level={2} mb={0.5}>
                    {t('resources.environments.sections.domains')}
                  </SectionTitle>
                  <SectionHelper mb={1}>
                    {t('resources.environments.helpers.domains')}
                  </SectionHelper>
                  <CustomDomainFields source='environmentCustomDomain' />
                  <SectionTitle level={2} mb={0.5}>
                    {t('resources.environments.sections.magentoSettings')}
                  </SectionTitle>
                  <MagentoHostMappingsFields source='environmentMagentoHostMapping' />
                </Stack>
              )}

              <SectionTitle mb={1}>
                {t('resources.environments.sections.database_and_media')}
              </SectionTitle>
              <Box id='init-sample-data-field' mb={1}>
                <BooleanInput
                  source={'isInitSampleData'}
                  label={t('resources.environments.fields.isInitSampleData')}
                  helperText={false}
                />
              </Box>
              <CopyEnvironmentField
                source={COPY_ENVIRONMENT_SOURCE}
                disabled={!canCopyFromEnvironment}
              />

              <SectionTitle mb={0.5}>
                {t('resources.environments.sections.advanced')}
              </SectionTitle>
              <Box id='is-allow-outgoing-emails-field' mt={0.5} mb={2}>
                <SectionHelper mb={1}>
                  {t(
                    'resources.environments.helpers.isAllowHtaccessDevtoolsProtection'
                  )}
                </SectionHelper>
                <AllowHtaccessDevtoolsProtectionField />
              </Box>
              <Box id='is-allow-outgoing-emails-field'>
                <SectionHelper mb={0.5}>
                  {t('resources.environments.helpers.isAllowOutgoingEmails')}
                </SectionHelper>
                <BooleanInput
                  source={'isAllowOutgoingEmails'}
                  label={t(
                    'resources.environments.fields.isAllowOutgoingEmails'
                  )}
                  helperText={false}
                />
              </Box>
            </Stack>
          </ProjectContextProvider>
        </WizardFormStep>
        <WizardFormStep label='resources.environments.tabs.resources'>
          <ProjectContextProvider>
            <EnvironmentCreateStepResources
              lastCopiedFromRef={environmentComponentLastCopiedFrom}
            />
          </ProjectContextProvider>
        </WizardFormStep>
        <WizardFormStep label='resources.environments.tabs.variables'>
          <ProjectContextProvider>
            <EnvironmentCreateStepVariables />
          </ProjectContextProvider>
        </WizardFormStep>
      </WizardForm>
    </StyledCreate>
  );
};

const BreadcrumbsLabel = props => {
  const { data } = props;
  return data['@type'] === 'Project' || data['@type'] === 'TemplateProject'
    ? data.name
    : 'Create Environment';
};

// const PREFIX = 'EnvironmentCreate';

// const classes = {
//   resources: `${PREFIX}-resources`
// };

const StyledCreate = styled(Create)(({ theme }) => ({
  [`& .${CreateClasses.card}`]: {
    overflow: 'visible',
    [`& .${WizardFormClasses.step(1)}`]: {
      ...TwoColumnFormStyles(theme)
    },
    [`& .${WizardFormClasses.step(2)}`]: {
      padding: `${theme.spacing(2)} ${theme.spacing(3)}`
    }
  }
}));

export default EnvironmentCreate;

import React from 'react';
import {
  BooleanInput,
  CheckboxGroupInput,
  maxLength,
  ReferenceInput,
  required,
  SaveButton,
  SelectInput,
  useGetList,
  useGetOne,
  useTranslate
} from 'react-admin';
import { useFormContext, useWatch } from 'react-hook-form';
import { RichTextInput } from 'ra-input-rich-text';
import { Button, Grid, Stack, styled, Typography } from '@mui/material';
import {
  SectionTitle,
  GitInputs,
  ReferenceInputWithDependency,
  useFormStepperContext,
  LoadingBox
} from '@rc/admin/components';
import { TwoColumnFormStyles } from '@rc/admin/theme/styles';
import { SHALLOW_CLUSTER_REFERENCE_FIELD_DEPENDENCIES } from '@rc/admin/constants';
import { useIsTemplateResource } from '@rc/admin/hooks';
import {
  AvatarInput,
  NameInput,
  ProjectTemplateDetails
} from '../../components';
import { CREATE_METHOD_TYPES } from '../utils';
import { useProjectType } from '../../hooks';
import { useProjectCreateSubmit } from '../Create';
import {
  TEMPLATE_PROJECT_DESCRIPTION_EDITOR_OPTIONS,
  TemplateProjectDescriptionToolbar,
  validateTemplateProjectDescription
} from '@rc/admin/resources/templateProject/utils';

export const ProjectCreateStepDetails = props => {
  const { method } = props;
  const t = useTranslate();
  const { getValues, handleSubmit } = useFormContext();
  const submit = useProjectCreateSubmit({ method });
  const [, { previous, next }] = useFormStepperContext();
  const templateIri = useWatch({ name: 'templateIri' });
  const isTemplate = useIsTemplateResource();

  const { data: templateProject, isLoading: isTemplateProjectLoading } =
    useGetOne(
      'template_projects',
      { id: templateIri },
      { enabled: method === CREATE_METHOD_TYPES.FROM_TEMPLATE }
    );

  const { projectType, isLoading: isTemplateProjectTypeLoading } =
    useProjectType({
      project: templateProject,
      enabled: method === CREATE_METHOD_TYPES.FROM_TEMPLATE
    });

  const {
    data: templateEnvironments,
    isLoading: isTemplateEnvironmentsLoading
  } = useGetList(
    'template_environments',
    { filter: { templateProject: templateIri } },
    { enabled: method === CREATE_METHOD_TYPES.FROM_TEMPLATE }
  );

  if (
    isTemplateProjectLoading ||
    isTemplateProjectTypeLoading ||
    isTemplateEnvironmentsLoading
  ) {
    return <LoadingBox />;
  }

  return (
    <Root method={method} sx={TwoColumnFormStyles} alignItems='flex-start'>
      {method === CREATE_METHOD_TYPES.FROM_TEMPLATE && (
        <ProjectTemplateDetails
          projectType={projectType}
          templateProject={templateProject}
        />
      )}
      <SectionTitle>
        {t('resources.projects.sections.identity.title')}
      </SectionTitle>
      <Grid container wrap='wrap' spacing={1}>
        <Grid item xs={12} display={'flex'} flexDirection={'row'} columnGap={2}>
          <NameInput
            autoFocus
            source={'name'}
            id={'project-name'}
            label={'resources.projects.fields.name'}
            validate={[required(), maxLength(255)]}
            fullWidth
          />
          <AvatarInput />
        </Grid>
        {isTemplate && (
          <Grid item xs={12}>
            <RichTextInput
              source='description'
              label={'resources.template_projects.fields.description'}
              editorOptions={TEMPLATE_PROJECT_DESCRIPTION_EDITOR_OPTIONS}
              toolbar={<TemplateProjectDescriptionToolbar />}
              validate={validateTemplateProjectDescription}
              fullWidth
            />
          </Grid>
        )}
      </Grid>

      {!isTemplate && method === CREATE_METHOD_TYPES.FROM_TEMPLATE && (
        <ProviderSection />
      )}

      {!isTemplate && method === CREATE_METHOD_TYPES.FROM_TEMPLATE && (
        <>
          <SectionTitle sx={{ mb: 0 }}>
            {t('resources.environments.name', { smart_count: 2 })}
          </SectionTitle>
          <Grid container>
            <Grid item xs={12}>
              <CheckboxGroupInput
                className={classes.checkboxGroup}
                source='__environments'
                label=''
                choices={
                  templateEnvironments?.map(te => ({
                    id: te.id,
                    name: te.name
                  })) || []
                }
                defaultValue={templateEnvironments?.map(te => te.id) || []}
              />
            </Grid>
          </Grid>
        </>
      )}

      {method === CREATE_METHOD_TYPES.FROM_SCRATCH && (
        <BooleanInput
          source={'isInitProjectSkeleton'}
          label={'resources.projects.fields.isInitProjectSkeleton'}
          fullWidth
          sx={theme => ({ marginBottom: theme.spacing(1) })}
          helperText={false}
        />
      )}

      {!isTemplate && <GitInputs source={'git'} />}

      {method === CREATE_METHOD_TYPES.FROM_TEMPLATE && (
        <Stack
          flexDirection={'row'}
          alignItems={'center'}
          gap={2}
          my={2}
          mx='auto'
        >
          <Button
            type={'button'}
            color={'primary'}
            variant={'outlined'}
            onClick={previous}
          >
            {t('action.back')}
          </Button>
          <SaveButton
            type='button'
            label={`${t('resources.projects.action.deploy')}!`}
            icon={<Typography mr={1}>{'🚀'}</Typography>}
            alwaysEnable
            onClick={event => {
              handleSubmit(async values => {
                const result = await submit(values);
                if (result) {
                  next({ project: result, form: getValues() });
                }
              })(event);
              event.defaultPrevented = true;
              event.stopPropagation();
            }}
          />
        </Stack>
      )}
    </Root>
  );
};

const ProviderSection = () => {
  const t = useTranslate();
  const { data: providers, isLoading: isProvidersLoading } =
    useGetList('providers');
  const defaultProvider = providers?.find(provider => provider.isDefault);
  const { data: clusters, isLoading: isClustersLoading } = useGetList(
    'clusters',
    { filter: { provider: defaultProvider?.id } },
    { enabled: !!defaultProvider }
  );
  const defaultCluster = clusters?.find(provider => provider.isDefault);

  return (
    <>
      <SectionTitle>{'Provider'}</SectionTitle>
      <Grid container>
        <Grid item xs={12}>
          <ReferenceInput source={'__provider'} reference={'providers'}>
            <SelectInput
              label={t('resources.providers.name', { smart_count: 1 })}
              optionText={'name'}
              validate={[required()]}
              isLoading={isProvidersLoading || isClustersLoading}
              defaultValue={defaultProvider?.id}
              fullWidth
            />
          </ReferenceInput>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <ReferenceInputWithDependency
            source={'__cluster'}
            reference={'clusters'}
            dependencies={SHALLOW_CLUSTER_REFERENCE_FIELD_DEPENDENCIES}
          >
            <SelectInput
              label={t('resources.clusters.name', { smart_count: 1 })}
              optionText={'name'}
              validate={[required()]}
              isLoading={isProvidersLoading || isClustersLoading}
              defaultValue={defaultCluster?.id}
              fullWidth
            />
          </ReferenceInputWithDependency>
        </Grid>
      </Grid>
    </>
  );
};

const PREFIX = 'ProjectCreateStepDetails';

const classes = {
  checkboxGroup: `${PREFIX}-checkboxGroup`
};

const Root = styled(Stack, { shouldForwardProp: prop => prop !== 'method' })(
  ({ theme, method }) => ({
    margin: method === CREATE_METHOD_TYPES.FROM_TEMPLATE ? '0 auto' : undefined,

    [`& .${classes.checkboxGroup}`]: {
      '& .MuiFormGroup-root': {
        flexDirection: 'column',
        mt: 1
      }
    }
  })
);

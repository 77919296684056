import React from 'react';
import {
  ArrayInput,
  AutocompleteInput,
  BooleanInput,
  Edit,
  FormTab,
  NumberInput,
  ReferenceInput,
  required,
  SelectInput,
  SimpleFormIterator,
  TabbedForm,
  TextInput,
  useTranslate
} from 'react-admin';
import _ from 'lodash';
import { currencies } from '@rc/admin/utils/form/currencies';
import { ProviderChoice } from '@rc/admin/resources/product/components';
import { EditTitle } from '@rc/admin/components';
import { useIdParam } from '@rc/admin/hooks';
import {
  OneColumnFormStyles,
  TwoColumnFormStyles
} from '@rc/admin/theme/styles';
import { styled } from '@mui/material';

/**
 * TODO It is necessary because, the defaultPrice have to be sended as a nested object
 *      BEWARE! With this methodic, if you do not send the updated price data in
 *      defaultPrice, you would update the nested data through it.
 */
const transform = values => {
  if (!_.isArray(values.prices)) return values;
  const priceId = values.defaultPrice['@id'] ?? null;
  return {
    ...values,
    defaultPrice: values.prices.find(price => price['@id'] === priceId) ?? null
  };
};

export const ProductEdit = () => {
  const t = useTranslate();
  const { id: productId } = useIdParam();

  return (
    <StyledEdit
      redirect={'edit'}
      mutationMode={'pessimistic'}
      title={<EditTitle />}
      transform={transform}
    >
      <TabbedForm
        defaultValues={{
          isActive: true
        }}
      >
        <FormTab
          label='resources.products.tabs.basic'
          contentClassName={classes.basic}
        >
          <TextInput
            source={'name'}
            helperText={t('resources.products.fields.name.helper')}
            required
            fullWidth
          />
          <TextInput
            source={'paymentId'}
            label={t('resources.products.fields.paymentId.label')}
            helperText={t('resources.products.fields.paymentId.helper')}
            required
            fullWidth
          />
          <TextInput source={'description'} multiline fullWidth />
          <BooleanInput source={'isActive'} options={null} />
          <ProviderChoice fullWidth />
          <ReferenceInput
            source={'defaultPrice[@id]'}
            reference={'prices'}
            filter={{ product: productId }}
          >
            <SelectInput
              label={t('resources.products.fields.defaultPrice.label')}
              helperText={t('resources.products.fields.defaultPrice.helper')}
              optionText={record => `${record.amount} ${record.currency}`}
              optionValue={'@id'}
              variant={'filled'}
              fullWidth
            />
          </ReferenceInput>
        </FormTab>
        <FormTab
          label='resources.products.tabs.prices'
          contentClassName={classes.prices}
        >
          <ArrayInput source={'prices'} validate={[required()]}>
            <SimpleFormIterator
              getItemLabel={index => ''}
              disableReordering
              disableRemove
              disableClear
              inline
            >
              <NumberInput source={'amount'} validate={[required()]} />
              <AutocompleteInput
                source={'currency'}
                choices={currencies}
                optionText={'label'}
                optionValue={'id'}
                defaultValue={'huf'}
                size='small'
              />
              <TextInput
                source={'paymentId'}
                label='resources.products.fields.paymentId.label'
                required
              />
              <BooleanInput
                source={'isActive'}
                options={null}
                defaultValue={true}
                sx={{ height: '100%', justifyContent: 'center' }}
              />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
      </TabbedForm>
    </StyledEdit>
  );
};

const PREFIX = 'ProductEdit';

const classes = {
  basic: `${PREFIX}-basic`,
  prices: `${PREFIX}-prices`
};

const StyledEdit = styled(Edit)(({ theme }) => ({
  [`& .${classes.basic}`]: {
    ...OneColumnFormStyles(theme)
  },

  [`& .${classes.prices}`]: {
    ...TwoColumnFormStyles(theme),
    maxWidth: 'unset',
    '& .MuiFormControl-root': {
      flex: 1
    }
  }
}));

export default ProductEdit;

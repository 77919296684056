import { useMemo } from 'react';
import { get } from 'lodash';
import { decodeBase64 } from '@rc/admin/utils';

export const useDecodedRecordField = props => {
  const { decodeBase64: shouldDecodeBase64, record, source } = props;

  const value = useMemo(() => {
    const sourceValue = get(record, source);

    if (!shouldDecodeBase64) {
      return sourceValue;
    }

    return decodeBase64(sourceValue);
  }, [shouldDecodeBase64, record, source]);

  return value;
};

import React, { Children } from 'react';
import clsx from 'clsx';
import { styled } from '@mui/material';
import {
  SimpleFormIterator,
  AddItemButton,
  RemoveItemButton,
  SimpleFormIteratorClasses
} from 'react-admin';
import TabbableFormControl from './TabbableFormControl';

/**
 *
 * @param {import('react-admin').SimpleFormIteratorProps} props
 * @returns
 */
const TabbableFormIterator = props => {
  const { children, addButton, removeButton, className, ...rest } = props;

  return (
    <StyledSimpleFormIterator
      {...rest}
      className={clsx(TabbableFormIteratorClasses.root, className)}
      getItemLabel={index => `${index + 1}.`}
      disableReordering
      addButton={addButton}
      removeButton={removeButton}
      disableClear
    >
      {Children.map(children, (child, index) => (
        <TabbableFormControl
          key={index}
          {...child.props}
          back={index === 0}
          forward={index === Children.count(children) - 1}
        >
          {child}
        </TabbableFormControl>
      ))}
    </StyledSimpleFormIterator>
  );
};

export const AddButton = props => (
  <AddItemButton variant={'outlined'} {...props} tabIndex={-1} />
);
export const RemoveButton = props => (
  <RemoveItemButton {...props} tabIndex={-1} variant='text' />
);

const PREFIX = 'TabbableFormIterator';

export const TabbableFormIteratorClasses = {
  root: `${PREFIX}-root`,
  form: `${PREFIX}-form`,
  index: `${PREFIX}-index`,
  line: `${PREFIX}-line`
};

/**
 * @type {(props: import('react-admin').SimpleFormIteratorProps) => any}
 */
const StyledSimpleFormIterator = styled(SimpleFormIterator)(({ theme }) => ({
  [`& .${TabbableFormIteratorClasses.root}`]: {
    marginTop: theme.spacing(3)

    // Hide input labels on 1+ index
    // [theme.breakpoints.up('md')]: {
    //   '& > li:not(:first-child) .MuiFormControl-root .MuiFormLabel-root, & > li:not(:first-child) .MuiFormControl-root legend': {
    //     display: 'none'
    //   }
    // }
  },

  [`& .${SimpleFormIteratorClasses.form}`]: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexDirection: 'row',
      columnGap: theme.spacing(1)
      // marginRight: theme.spacing(3)
    },
    '& .MuiTextField-root': {
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(0.5)
    }
  },

  [`& .${SimpleFormIteratorClasses.index}`]: {
    display: 'none'
  },

  [`& .${SimpleFormIteratorClasses.line}`]: {
    borderBottom: 'none',
    // Hide empty div
    '& > div:first-of-type': {
      display: 'none'
    },
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(2)
    }
  },

  [`& .${SimpleFormIteratorClasses.action}`]: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      minWidth: '4.625rem'
    },
    [theme.breakpoints.only('md')]: {
      marginLeft: theme.spacing(2)
    }
  }
}));

TabbableFormIterator.defaultProps = {
  removeButton: <RemoveButton />,
  addButton: <AddButton />
};

export default TabbableFormIterator;

import React, { useCallback } from 'react';
import { LabelWithValue, SliderInput } from '@rc/admin/components';

/**
 * @typedef {import('@rc/admin/components/input/SliderInput/SliderInput').SliderInputProps & { resourceMin: number; resourceMax: number; }} ResourceInputProps
 */

/**
 *
 * @param {ResourceInputProps} props
 * @returns
 */
const ResourceInput = props => {
  const {
    source,
    min,
    max,
    step,
    marks,
    lowerLimit = min,
    upperLimit = max,
    validate: validateProp,
    unit,
    label,
    tooltipText,
    format: formatProp,
    defaultValue: defaultValueProp,
    ...rest
  } = props;

  const format = useCallback(
    v => {
      if (formatProp) return formatProp(v);
      return typeof v === 'number' ? v : min;
    },
    [formatProp, min]
  );

  const validateLimit = useCallback(
    (value, allValues) => {
      if (value < lowerLimit) {
        return {
          type: 'validate',
          message: 'resources.resource_types.validation.lower_limit',
          args: {
            value: format(lowerLimit),
            unit
          }
        };
      } else if (value > upperLimit) {
        return {
          type: 'validate',
          message: 'resources.resource_types.validation.upper_limit'
        };
      } else if (value < min || value > max) {
        return {
          type: 'validate',
          message: 'resources.resource_types.validation.out_of_range'
        };
      } else if (typeof validateProp === 'function') {
        return validateProp(value, allValues);
      }
    },
    [format, lowerLimit, max, min, unit, upperLimit, validateProp]
  );

  const defaultValue = defaultValueProp || lowerLimit;

  return (
    <SliderInput
      source={source}
      min={min}
      max={max}
      step={step}
      marks={marks}
      format={format}
      defaultValue={defaultValue}
      lowerLimit={lowerLimit}
      upperLimit={upperLimit}
      validate={validateLimit}
      color={'secondary'}
      label={
        <LabelWithValue
          source={source}
          unit={unit}
          format={format}
          label={label}
          tooltipText={tooltipText}
          sx={theme => ({
            marginRight: theme.spacing(-1)
          })}
        />
      }
      {...rest}
    />
  );
};

export default ResourceInput;

import React, { useCallback } from 'react';
import { useRecordContext, Button } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import axios from '@rc/utils/axios';
import { decodeJwt } from '@rc/admin/utils/providers/auth-provider';
import { ADMIN_FALLBACK_STORAGE_ID, ROLES } from '@rc/admin/constants';
import { LinearProgress } from '@mui/material';
import { SupportAgent } from '@mui/icons-material';
import { useIsRole } from '@rc/admin/hooks/domain';

/**
 *
 * @param {import('@mui/material').ButtonProps} props
 * @returns
 */
export const AdministrateButton = props => {
  const record = useRecordContext();
  const navigate = useNavigate();
  const { result: isAdmin, isLoading } = useIsRole(ROLES.ADMIN);

  const fetchToken = useCallback(async () => {
    const response = await axios.get(
      `/api/users/${record.originId}/administrate`
    );

    return response.data;
  }, [record?.originId]);

  const administrate = useCallback(
    async e => {
      const { token, data } = await fetchToken();
      const decodedToken = decodeJwt(token);

      /** Create fallback for Admin user */
      window.localStorage.setItem(
        ADMIN_FALLBACK_STORAGE_ID,
        JSON.stringify({
          token: window.localStorage.getItem('auth'),
          data: JSON.parse(window.localStorage.getItem('user'))
        })
      );

      /** Auth the new user */
      window.localStorage.setItem('auth', token);
      window.localStorage.setItem(
        'permissions',
        JSON.stringify(decodedToken.roles)
      );
      window.localStorage.setItem('user', JSON.stringify(data));

      navigate('/');
      window.location.reload();
    },
    [fetchToken, navigate]
  );

  if (isLoading) {
    return <LinearProgress />;
  }

  if (!isAdmin) {
    return '';
  }

  return (
    <Button
      label='resources.users.action.administrate'
      startIcon={<SupportAgent />}
      onClick={administrate}
      {...props}
    />
  );
};

export default AdministrateButton;

export const REMOVE_TEAMS_FORM_KEY = '__remove_teams';

export const transform = values => {
  const newValues = { ...values };

  const teamsToRemove = newValues[REMOVE_TEAMS_FORM_KEY] || [];
  newValues.team = newValues.team.filter(id => !teamsToRemove.includes(id));
  delete newValues[REMOVE_TEAMS_FORM_KEY];

  return newValues;
};

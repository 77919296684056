import React from 'react';
import { styled } from '@mui/material';

const EnvironmentIcon = props => {
  return <Root className={classes.root}>{'ENV'}</Root>;
};

const PREFIX = 'EnvironmentIcon';

const classes = {
  root: `${PREFIX}-root`
};

const Root = styled('span')(({ theme }) => ({
  [`&.${classes.root}`]: {
    fontWeight: 600,
    opacity: 0.5,
    fontSize: '0.75rem'
  }
}));

export default EnvironmentIcon;

import React from 'react';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText
} from '@mui/material';
import { Controller } from 'react-hook-form';
import { useTranslate } from 'react-admin';

/**
 *
 * @param {import('@mui/material').CheckboxProps & import('react-hook-form').ControllerProps & { label: any, fullWidth: bool }} props
 * @returns
 */
const CheckboxFieldInput = props => {
  const {
    name,
    control,
    rules,
    variant,
    required,
    helperText,
    disabled,
    label,
    fullWidth,
    ...rest
  } = props;
  const translate = useTranslate();

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { ref, ...field }, fieldState: { error } }) => (
        <FormControl error={!!error} fullWidth={fullWidth}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  inputRef={ref}
                  {...field}
                  id={name}
                  required={required === true}
                  {...rest}
                />
              }
              label={label}
            />
          </FormGroup>
          {error && (
            <FormHelperText>
              {error ? translate(error.message) : helperText}
            </FormHelperText>
          )}{' '}
        </FormControl>
      )}
    />
  );
};

CheckboxFieldInput.defaultProps = {
  fullWidth: true,
  margin: 'normal'
};

export default CheckboxFieldInput;

export const REMOVE_USERS_FORM_KEY = '__remove_users';

export const transform = values => {
  const newValues = { ...values };

  const usersToRemove = newValues[REMOVE_USERS_FORM_KEY] || [];
  newValues.user = newValues.user.filter(id => !usersToRemove.includes(id));
  delete newValues[REMOVE_USERS_FORM_KEY];

  return newValues;
};

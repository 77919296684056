import { ProjectTypeVersionEnvVarEdit } from './Edit';
import { ProjectTypeVersionEnvVarCreate } from './Create';

export default {
  name: 'project_type_version_env_vars',
  edit: ProjectTypeVersionEnvVarEdit,
  create: ProjectTypeVersionEnvVarCreate,
  options: {
    showInMenu: false
  }
};

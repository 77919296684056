import { useCallback } from 'react';
import { useNotify } from 'react-admin';
import useUpdateCache from '@rc/admin/moduleOverrides/useUpdateCache';
import useMercureSubscription from '@rc/admin/moduleOverrides/useMercureSubscription';
import { useOrgContext } from '@rc/admin/context';

const RESOURCE_TOPIC_FOR_WORKER_UPDATES = 'teams';

export const useWorkerUpdates = () => {
  const { teamId } = useOrgContext();
  const notify = useNotify();
  const updateCache = useUpdateCache();

  const handleReceived = useCallback(
    document => {
      // Document is a custom worker message format { message: string, status: string, @type: string, @context: sting }
      if (document.message) {
        const { message } = extractData(document);
        if (message) {
          notify(message, {
            type: 'error'
          });
        }
      } else {
        // Otherwise it's an entity update
        updateCache({
          resource: RESOURCE_TOPIC_FOR_WORKER_UPDATES,
          id: document.id,
          data: document
        });
      }
    },
    [notify, updateCache]
  );

  useMercureSubscription(RESOURCE_TOPIC_FOR_WORKER_UPDATES, teamId, {
    handleReceived
  });
};

const extractData = document => {
  const {
    '@id': id,
    // ['@context']: context,
    '@type': type,
    status,
    message
  } = document;

  // Extract resource from id string '/api/%s/%d'
  const resource = id.split('/')[1];

  return {
    resource,
    id,
    type,
    status,
    message
  };
};

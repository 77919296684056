import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { EnvironmentCreate as Create } from './Create';
import { EnvironmentClone as Clone } from './Clone';

export const EnvironmentCreate = () => {
  return (
    <Routes>
      <Route
        path='/'
        element={<Create/>}
      />
      <Route
        path='/clone'
        element={<Clone />}
      />
    </Routes>
  );
};

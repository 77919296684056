import React from 'react';
import { useTranslate } from 'ra-core';
import { IconButton, Stack, Tooltip, styled } from '@mui/material';
import { useWatch } from 'react-hook-form';
import { Iconify } from '../ui';

/**
 * @typedef {object} LabelWithValueProps
 * @property {string} props.source
 * @property {string} props.unit
 * @property {function} props.format
 * @property {string} props.label
 * @property {string} props.tooltipText
 * @property {React.ReactNode} props.extra
 *
 * @param {LabelWithValueProps & import('@mui/material').StackProps} props
 * @returns
 */
export const LabelWithValue = props => {
  const { source, unit, format, label, tooltipText, extra, ...rest } = props;
  const value = useWatch({ name: source });
  const t = useTranslate();

  if (label === false || label === '') {
    return null;
  }

  const formattedLabel = label ? t(label) : createLabelFromSource(source);
  const formattedValue = `${format(value | 0)} ${unit}`;

  return (
    <StyledStack
      component='span'
      direction='column'
      fontSize={'inherit'}
      {...rest}
    >
      <Stack
        className={LabelWithValueClasses.label}
        component='span'
        direction='row'
        fontSize={'inherit'}
      >
        {`${formattedLabel}: ${formattedValue}`}
        {tooltipText && (
          <Tooltip
            className={LabelWithValueClasses.tooltip}
            title={t(tooltipText)}
          >
            <IconButton>
              <Iconify icon='solar:info-circle-outline' width={16} />
            </IconButton>
          </Tooltip>
        )}
      </Stack>
      {extra}
    </StyledStack>
  );
};

const PREFIX = 'LabelWithValue';

export const LabelWithValueClasses = {
  label: `${PREFIX}-label`,
  tooltip: `${PREFIX}-tooltip`,
  tooltipButton: `${PREFIX}-tooltipButton`
};

const StyledStack = styled(Stack)(({ theme }) => ({
  columnGap: theme.spacing(1),

  [`& .${LabelWithValueClasses.label}`]: {
    columnGap: theme.spacing(1),
    alignItems: 'baseline',
    justifyContent: 'space-between'
  },

  [`& .${LabelWithValueClasses.tooltip}`]: {
    alignSelf: 'flex-end',
    padding: 0
  }
}));

const createLabelFromSource = source =>
  source[0].toUpperCase() + source.slice(1);

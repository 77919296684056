import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  useCreatePath,
  useGetResourceLabel,
  useRecordContext,
  useResourceContext
} from 'react-admin';
import {
  styled,
  Breadcrumbs as MuiBreadcrumbs,
  Typography
} from '@mui/material';
import { useNestedGetOne } from '@rc/admin/hooks/useNestedGetOne';
import { createPortal } from 'react-dom';
import CustomBreadcrumbs from '@minimal-theme/components/custom-breadcrumbs';

/**
 *
 * @param {object} props
 * @param {array} props.parents
 * @param {object} props.data
 * @param {string} props.resource
 * @param {boolean} props.showCurrent
 * @param {boolean} props.disableLast
 * @param {React.ReactNode} props.label
 * @param {React.ReactNode} props.children
 * @returns
 */
const BreadcrumbsView = props => {
  const {
    data: record,
    resource,
    parents,
    showCurrent,
    disableLast,
    children
  } = props;
  const getResourceLabel = useGetResourceLabel();
  const createPath = useCreatePath();
  const hasParents = !!parents.length;

  const nestedGetOneParams = useMemo(() => {
    if (!hasParents) {
      return [];
    }

    return parents.map(({ reference, source }, index) => ({
      resource: reference,
      id: index === 0 ? record?.[source] : undefined,
      source
    }));
  }, [hasParents, parents, record]);

  const parentResults = useNestedGetOne(nestedGetOneParams);

  const isLoading =
    hasParents &&
    parentResults.some(({ isLoading, data }) => isLoading || !data);

  const container =
    typeof document !== 'undefined'
      ? document.getElementById('react-admin-breadcrumbs')
      : null;

  if (!container) return null;

  let breadcrumbsContent = null;

  if (children) {
    return createPortal(
      <StyledMuiBreadcrumbs className={classes.root}>
        {children}
      </StyledMuiBreadcrumbs>,
      container
    );
  } else if (isLoading || (hasParents && showCurrent && !record)) {
    breadcrumbsContent = <Placeholder />;
  } else {
    const rootResource = hasParents
      ? nestedGetOneParams[nestedGetOneParams.length - 1].resource
      : resource;

    breadcrumbsContent = (
      <CustomBreadcrumbs
        disableLast={disableLast}
        links={[
          {
            name: (
              <Typography variant='body2'>
                {getResourceLabel(rootResource, 2)}
              </Typography>
            ),
            href: `/${rootResource}`
          },
          ...parentResults.reverse().map(({ data }, index) => {
            const resource =
              nestedGetOneParams[nestedGetOneParams.length - 1 - index]
                ?.resource;

            return {
              href: createPath({
                type: 'edit',
                id: data.id,
                resource
              }),
              name: <LabelWrapper {...props} data={data} />
            };
          }),
          ...(showCurrent && record
            ? [
                {
                  href: createPath({
                    type: 'edit',
                    id: record.id,
                    resource
                  }),
                  name: <LabelWrapper {...props} />
                }
              ]
            : [])
        ]}
        sx={{
          mb: { xs: 2, md: 2 }
        }}
      />
    );
  }

  return createPortal(breadcrumbsContent, container);
};

const DefaultLabel = ({ data }) => data?.name || null;

const LabelWrapper = props => {
  const { data, label } = props;

  return (
    <Typography variant='body2'>
      {typeof label === 'string'
        ? label
        : React.cloneElement(label, {
            ...(label.props || {}),
            data
          })}
    </Typography>
  );
};

const Placeholder = () => (
  <div data-testid='rc-breadcrumbs-placeholder' className={classes.root} />
);

const Breadcrumbs = props => {
  const { data: dataProp, record: recordProp, ...rest } = props;
  const resource = useResourceContext();
  const record = useRecordContext({ record: recordProp });
  const data = dataProp || record;

  return <BreadcrumbsView data={data} resource={resource} {...rest} />;
};

const PREFIX = 'Breadcrumbs';

const classes = {
  root: `${PREFIX}-root`
};

const StyledMuiBreadcrumbs = styled(MuiBreadcrumbs)(() => ({
  [`&.${classes.root}`]: {
    minHeight: 24
  }
}));

Breadcrumbs.propTypes = {
  data: PropTypes.object,
  resource: PropTypes.string,
  parents: PropTypes.arrayOf(
    PropTypes.shape({
      reference: PropTypes.string,
      source: PropTypes.string
    })
  ),
  showCurrent: PropTypes.bool
};

Breadcrumbs.defaultProps = {
  label: <DefaultLabel />,
  parents: [],
  showCurrent: true
};

export default Breadcrumbs;

/* eslint-disable */

import React from 'react';
import {
  MenuItemLink,
  ReferenceField,
  TextField,
  useLocaleState,
  useCreatePath,
  useResourceContext,
  useTranslate
} from 'react-admin';
import {
  Typography,
  Stack,
  Skeleton,
  CardContent,
  styled,
  Box,
  Divider,
  IconButton,
  ListItemText
} from '@mui/material';
import ReactTimeAgo from 'react-time-ago';
import { useAxios } from '@rc/utils/axios';
import { ExternalLink, StateField, Iconify } from '@rc/admin/components';
import CustomPopover, {
  usePopover
} from '@minimal-theme/components/custom-popover';
import { useIsTemplateResource } from '@rc/admin/hooks';

/**
 *
 * @param {object} props
 * @param {import('react-admin').Record} props.record
 * @param {boolean} props.isPlaceholder
 * @returns
 */
export const EnvironmentCardContent = props => {
  const { record, isPlaceholder } = props;
  const t = useTranslate();
  const popover = usePopover();
  const resource = useResourceContext();
  const createPath = useCreatePath();
  const isTemplate = useIsTemplateResource();

  return (
    <StyledCardContent>
      {!isPlaceholder && (
        <IconButton
          onClick={e => {
            e.preventDefault();
            popover.onOpen(e);
          }}
          sx={{ position: 'absolute', top: 8, right: 8 }}
        >
          <Iconify icon='eva:more-vertical-fill' />
        </IconButton>
      )}
      <Stack sx={{ px: 3, pt: 2, pb: 1.75 }}>
        <Stack flexDirection='row' alignItems='center' gap={2} mb={0.5} mr={2}>
          <Title {...props} />
        </Stack>
      </Stack>

      <Divider sx={{ borderStyle: 'dashed' }} />

      <Box
        rowGap={1}
        display='grid'
        gridTemplateColumns='repeat(2, 1fr)'
        sx={{ px: 3, pt: 1.5, pb: 1.75 }}
      >
        {[
          ...(!isTemplate && record?.environmentAccess
            ? [
                {
                  key: 'environmentAccess',
                  label: <EnvironmentAccess {...props} />,
                  icon: null,
                  sx: { gridColumn: '1 / span 2' }
                }
              ]
            : []),
          {
            key: 'updated-at',
            label: <UpdatedAt {...props} />,
            icon: !isPlaceholder && (
              <Iconify
                width={16}
                icon='solar:clock-circle-bold'
                sx={{ flexShrink: 0 }}
              />
            )
          },
          {
            key: 'cluster',
            label: isPlaceholder ? (
              <Skeleton variant='text' width={120} />
            ) : (
              <ReferenceField
                source={'cluster'}
                reference={'clusters'}
                link={false}
              >
                <TextField source={'name'} />
              </ReferenceField>
            ),
            icon: !isPlaceholder && (
              <Iconify
                icon='solar:server-square-cloud-bold-duotone'
                width={16}
              />
            )
          }
        ].map(item => (
          <Stack
            key={item.key}
            spacing={0.5}
            flexShrink={0}
            direction='row'
            alignItems='center'
            sx={{ color: 'text.disabled', minWidth: 0, ...(item.sx || {}) }}
          >
            {item.icon}
            <Typography variant='caption' noWrap>
              {item.label}
            </Typography>
          </Stack>
        ))}
      </Box>

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow='right-top'
        sx={{ width: 140 }}
      >
        <MenuItemLink
          onClick={e => {
            e.stopPropagation();
            popover.onClose();
          }}
          to={createPath({ resource, type: 'edit', id: record?.id })}
        >
          <Iconify icon='solar:diagram-up-bold' />
          {t('resources.environments.tabs.details')}
        </MenuItemLink>
        <MenuItemLink
          onClick={e => {
            e.stopPropagation();
            popover.onClose();
          }}
          to={`${createPath({
            resource,
            type: 'edit',
            id: record?.id
          })}/logs`}
        >
          <Iconify icon='solar:document-text-bold' />
          {t('resources.environments.tabs.logs')}
        </MenuItemLink>
        <MenuItemLink
          onClick={e => {
            e.stopPropagation();
            popover.onClose();
          }}
          to={`${createPath({
            resource,
            type: 'edit',
            id: record?.id
          })}/edit`}
        >
          <Iconify icon='solar:pen-bold' />
          {t('ra.action.edit')}
        </MenuItemLink>
        <MenuItemLink
          onClick={e => {
            e.stopPropagation();
            popover.onClose();
          }}
          to={{
            pathname: `${createPath({
              resource,
              type: 'create'
            })}/clone`,
            search: `sourceId=${encodeURIComponent(record?.id)}`
          }}
        >
          <Iconify icon='solar:copy-bold-duotone' />
          {t('action.clone')}
        </MenuItemLink>
      </CustomPopover>
    </StyledCardContent>
  );
};

const Title = props => {
  const { record, isPlaceholder } = props;
  return (
    <ListItemText
      sx={{ textAlign: 'left' }}
      primary={
        isPlaceholder ? (
          <Skeleton variant='text' />
        ) : (
          <Stack flexDirection='row' columnGap={1} alignItems='center'>
            <Iconify icon='fluent:branch-16-regular' />
            {record?.name}
          </Stack>
        )
      }
      secondary={<State {...props} />}
      primaryTypographyProps={{
        typography: 'subtitle1',
        noWrap: true
      }}
      secondaryTypographyProps={{
        mt: 1,
        component: 'span',
        typography: 'caption',
        color: 'text.disabled'
      }}
    />
  );
};

const State = ({ isPlaceholder }) =>
  isPlaceholder ? (
    <Skeleton variant='text' width={60} />
  ) : (
    <ReferenceField
      reference={'states'}
      source={'state'}
      link={false}
      label='resources.environments.fields.state'
    >
      <StateField
        variant={'caption'}
        source='id'
        sx={{ overflow: 'hidden' }}
        fontWeight={'400'}
      />
    </ReferenceField>
  );

export const EnvironmentAccess = ({ record, isPlaceholder }) => {
  const environmentAccessId = record?.environmentAccess?.split('/').pop();
  const [{ data, loading: isLoading }] = useAxios(
    `/api/environment_accesses/${environmentAccessId}/all-data`,
    {
      manual: !record?.environmentAccess
    }
  );

  const frontendUrl = data?.frontend?.url;

  if (isLoading || isPlaceholder) {
    return <Skeleton variant='text' width={140} />;
  } else if (!frontendUrl) {
    return null;
  }

  return <ExternalLink url={frontendUrl} variant='body2' />;
};

const UpdatedAt = ({ record, isPlaceholder }) => {
  const [locale] = useLocaleState();
  const dateString = record.updatedAt || record.createdAt;

  return isPlaceholder ? (
    <Skeleton variant='text' width={60} />
  ) : dateString ? (
    <ReactTimeAgo date={new Date(dateString)} locale={locale} />
  ) : null;
};

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  padding: theme.spacing(0),
  '&:last-child': {
    paddingBottom: theme.spacing(0)
  }
}));

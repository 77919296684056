import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';

export const ColorField = ({ source, record = {}, className }) => (
  <div style={{ display: 'flex' }}>
    <div
      style={{
        width: '20px',
        height: '20px',
        background: get(record, source),
        marginRight: '5px'
      }}
    />
    <span className={className}>{get(record, source)}</span>
  </div>
);

ColorField.propTypes = {
  className: PropTypes.string,
  elStyle: PropTypes.object,
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired
};

ColorField.defaultProps = {};

import { useCallback } from 'react';
import { getSourceUtil } from '@rc/admin/utils/form';
import { useSimpleFormIterator } from 'react-admin';

export const useTabbableFormControlItem = props => {
  const { source } = props;
  const { index } = getSourceUtil(source);
  const { add, total } = useSimpleFormIterator();

  /**
   * Adds a new row and focuses it
   */
  const handleKeyDown = useCallback(
    event => {
      if (index === total - 1 && event.key === 'Enter') {
        add();
        setTimeout(() => {
          document
            .getElementById(
              source.replace(index, index + 1).replace('value', 'key')
            )
            .focus();
        }, 100);
      }
    },
    [index, total, add, source]
  );

  return { handleKeyDown };
};

import React from 'react';
import {
  BooleanField,
  BooleanInput,
  Datagrid,
  Edit,
  EditClasses,
  FunctionField,
  PasswordInput,
  ReferenceField,
  required,
  SimpleForm,
  SimpleList,
  TextField,
  TextInput
} from 'react-admin';
import { Card, Grid, styled, useMediaQuery } from '@mui/material';
import {
  AsideReferenceList,
  Breadcrumbs,
  EditTitle,
  SectionTitle
} from '@rc/admin/components';
import { EditButton } from '@rc/admin/ra';
import { TwoColumnFormStyles } from '@rc/admin/theme/styles';

const transform = values => {
  delete values.kubecostPass;
  return values;
};

export const ClusterEdit = () => {
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

  return (
    <StyledEdit
      title={<EditTitle source={'name'} />}
      redirect={false}
      actions={<Breadcrumbs parents={breadcrumbsParents} />}
      transform={transform}
      mutationMode={'pessimistic'}
      aside={
        <Card className={classes.asideCard}>
          <AsideReferenceList resource={'products'} referenceSource={'cluster'}>
            {isSmall ? (
              <SimpleList
                primaryText={() => <TextField source={'name'} />}
                secondaryText={() => (
                  <FunctionField
                    source='defaultPrice'
                    render={record =>
                      record.defaultPrice
                        ? `${record.defaultPrice.amount} ${record.defaultPrice.currency}`
                        : '-'
                    }
                  />
                )}
                style={{ width: '100%' }}
              />
            ) : (
              <Datagrid style={{ width: '100%' }}>
                <TextField source={'name'} />
                <FunctionField
                  source='defaultPrice'
                  render={record =>
                    record.defaultPrice
                      ? `${record.defaultPrice.amount} ${record.defaultPrice.currency}`
                      : '-'
                  }
                />
                <EditButton />
              </Datagrid>
            )}
          </AsideReferenceList>
        </Card>
      }
    >
      <SimpleForm defaultValues={{ isEnabled: true }} className={classes.form}>
        <BooleanInput source={'isDefault'} fullWidth />
        <Grid container wrap='wrap' spacing={1}>
          <Grid item xs={12} sm={6}>
            <TextInput
              source={'codeName'}
              label='resources.clusters.fields.codeName'
              id='cluster-display-name'
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              source={'name'}
              label='resources.clusters.fields.name'
              id='cluster-name'
              fullWidth
            />
          </Grid>
        </Grid>
        <SectionTitle title={'resources.clusters.sections.kubecost'} />
        <TextInput
          source={'kubecostUrl'}
          label={'resources.clusters.fields.kubecostUrl'}
          validate={[required()]}
          fullWidth
        />
        <Grid container wrap='wrap' spacing={1}>
          <Grid item xs={12} sm={6}>
            <TextInput
              source={'kubecostUser'}
              label={'resources.clusters.fields.kubecostUser'}
              validate={[required()]}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <PasswordInput
              source={'changeKubecostPass'}
              label={'resources.clusters.fields.changeKubecostPass'}
              fullWidth
            />
          </Grid>
        </Grid>
        <SectionTitle title={'resources.clusters.sections.cluster'} />
        <TextInput
          source={'clusterServer'}
          label='resources.clusters.fields.clusterServer'
          fullWidth
        />
        <TextInput
          source={'clusterCertificateAuthorityData'}
          label='resources.clusters.fields.clusterCertificateAuthorityData'
          fullWidth
        />
        <TextInput
          source={'oidcIssuerUrl'}
          label='resources.clusters.fields.oidcIssuerUrl'
          fullWidth
        />
        <TextInput
          source={'oidcClientID'}
          label='resources.clusters.fields.oidcClientID'
          fullWidth
        />
        <TextInput
          source={'oidcClientSecret'}
          label='resources.clusters.fields.oidcClientSecret'
          fullWidth
        />
      </SimpleForm>
    </StyledEdit>
  );
};

const PREFIX = 'ClusterEdit';

const classes = {
  form: `${PREFIX}-form`
};

const StyledEdit = styled(Edit)(({ theme }) => ({
  [`& .${EditClasses.main}`]: {
    display: 'grid',
    gridTemplateColumns: '5fr 3fr',
    columnGap: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr',
      gap: theme.spacing(3),
      '& > *': {
        width: '100%'
      }
    }
  },

  [`& .${classes.asideCard}`]: {
    height: '100%'
  },

  [`& .${classes.form}`]: {
    ...TwoColumnFormStyles(theme)
  }
}));

const breadcrumbsParents = [{ reference: 'providers', source: 'provider' }];

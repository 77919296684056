import React, { useEffect, useMemo, useContext, useState } from 'react';
import {
  ArrayInput,
  AutocompleteInputClasses,
  ReferenceInput,
  required,
  SelectInput,
  SimpleFormIteratorClasses,
  SimpleFormIteratorItemContext,
  useGetList,
  useSimpleFormIterator,
  useSimpleFormIteratorItem,
  useTranslate
} from 'react-admin';
import {
  styled,
  Box,
  CircularProgress,
  textFieldClasses,
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
  formHelperTextClasses
} from '@mui/material';
import { useFormContext, useWatch } from 'react-hook-form';
import {
  Iconify,
  AddButton as RcAddButton,
  RemoveButton as RcRemoveButton,
  TabbableFormIterator,
  ToggleIconInput,
  ToggleIconInputClasses
} from '@rc/admin/components';
import { EnvVarKeyInput } from './EnvVarKeyInput';
import { EnvVarValueInput } from './EnvVarValueInput';
import { useIsEnvVarRequired } from './utils';
import { createSourceUtil } from '@rc/admin/utils/form';
import {
  useDefaultEnvVarType,
  useValidateEnvVar
} from '@rc/admin/resources/environment/hooks';
import { EnvVarFieldsClasses } from './EnvVarFields';
import { getIsInheritedEnvVar, useInheritedEnvVar } from '@rc/admin/hooks';

/**
 * @typedef {import('react-admin').ArrayInputProps & object} EnvVarInputsProps
 * @property {string} source
 * @property {string} componentVersion
 * @property {string} projectTypeVersion
 * @property {(api: import('@rc/admin/components').FormIteratorApi) => void} setFormIteratorApi
 * @property {string} label
 * @property {any[]} inheritedEnvVars
 * @property {React.ReactNode} children
 *
 * @param {EnvVarInputsProps} props
 *
 */
export const EnvVarInputs = props => {
  const {
    children,
    source,
    componentVersion,
    projectTypeVersion,
    setFormIteratorApi,
    label,
    inheritedEnvVars,
    ...rest
  } = props;
  const t = useTranslate();

  const {
    envVarDefinitions,
    defaultEnvVarType,
    envVarsExamples,
    isLoading
  } = useEnvVarsData({
    projectTypeVersion,
    componentVersion
  });

  useValidateEnvVar({ envVars: envVarDefinitions, source, inheritedEnvVars });

  if (isLoading) {
    return (
      <Root className={classes.loading}>
        <CircularProgress />
      </Root>
    );
  }

  return (
    <Root>
      <ArrayInput source={source} {...rest} label=''>
        <TabbableFormIterator
          className={classes.formIterator}
          addButton={
            <AddButton
              source={source}
              envVars={envVarDefinitions}
              envVarsExamples={envVarsExamples}
              defaultEnvVarType={defaultEnvVarType}
              inheritedEnvVars={inheritedEnvVars}
              setFormIteratorApi={setFormIteratorApi}
            />
          }
          removeButton={
            <RemoveButton
              envVars={envVarDefinitions}
              source={source}
              inheritedEnvVars={inheritedEnvVars}
            />
          }
        >
          <EnvVarKeyInput
            source='key'
            label={t('components.envVar.fields.key')}
            size={'small'}
            envVarDefinitions={envVarDefinitions}
            fullWidth
          />
          <EnvVarValueInput
            source='value'
            label={t('components.envVar.fields.value')}
            size={'small'}
            envVarDefinitions={envVarDefinitions}
            inheritedEnvVars={inheritedEnvVars}
            fullWidth
          />
          <ReferenceInput
            source={'envVarType'}
            reference={'env_var_types'}
            defaultValue={defaultEnvVarType?.id}
          >
            <SelectInput
              optionText={'name'}
              label={t('components.envVar.fields.type')}
              size={'small'}
              fullWidth
              validate={[required()]}
            />
          </ReferenceInput>
          <ToggleIconInput
            source={'isEncrypted'}
            title={t('components.envVar.fields.isSecured')}
            defaultValue={false}
            fullWidth
            iconChecked={
              <Iconify icon='solar:lock-keyhole-bold-duotone' width={24} />
            }
            iconUnchecked={
              <Iconify
                icon='solar:lock-keyhole-unlocked-bold-duotone'
                width={24}
              />
            }
          />
        </TabbableFormIterator>
      </ArrayInput>
      {children}
    </Root>
  );
};

const AddButton = props => {
  const {
    envVars,
    envVarsExamples,
    source,
    defaultEnvVarType,
    setFormIteratorApi,
    inheritedEnvVars,
    ...rest
  } = props;
  const formIteratorApi = useSimpleFormIterator();
  const { add } = formIteratorApi;
  const { getValues } = useFormContext();

  useEffect(() => {
    if (typeof setFormIteratorApi === 'function') {
      setFormIteratorApi(formIteratorApi);
    }
  }, [formIteratorApi, setFormIteratorApi]);

  /**
   * Append all the required env vars as an item
   */
  useEffect(() => {
    const values = getValues(source);
    if (values && envVars) {
      const currentValues = values.filter(value => value);
      const newRequiredValues = [];

      envVars.forEach(envVar => {
        const { isRequired, key } = envVar;
        if (isRequired && !currentValues.some(value => value.key === key)) {
          newRequiredValues.push(envVar);
        }
      });

      if (newRequiredValues.length && defaultEnvVarType) {
        newRequiredValues.forEach(({ id, key }) => {
          const defaultValue = envVarsExamples.find(
            ({ componentVersionEnvVar, projectTypeVersionEnvVar, isDefault }) =>
              (componentVersionEnvVar === id ||
                projectTypeVersionEnvVar === id) &&
              isDefault
          )?.value;

          const isInherited = getIsInheritedEnvVar(key, inheritedEnvVars);

          if (!isInherited) {
            const newField = {
              key,
              value: defaultValue || '',
              envVarType: defaultEnvVarType.id,
              isEncrypted: false
            };

            add(newField);
          }
        });
      }
    }
    // Remove "add" method from deps beceause of too much re-renders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [source, envVars, defaultEnvVarType, getValues]);

  return <RcAddButton {...rest} onClick={() => add(null)} />;
};

const RemoveButton = props => {
  const { envVars, source, inheritedEnvVars, ...rest } = props;
  const { index } = useContext(SimpleFormIteratorItemContext);
  const { remove } = useSimpleFormIteratorItem();
  const t = useTranslate();

  const [dialogOpen, setDialogOpen] = useState(false);

  const value = useWatch({
    name: createSourceUtil(source, index)
  });

  const isInherited = useInheritedEnvVar(value?.key, inheritedEnvVars);
  const isRequired = useIsEnvVarRequired(value?.key, envVars);
  const isDisabled = !isInherited && isRequired;

  return (
    <>
      <RcRemoveButton
        {...rest}
        disabled={isDisabled}
        sx={{ visibility: isDisabled ? 'hidden' : 'visible' }}
        variant={'text'}
        onClick={() => (value['@id'] ? setDialogOpen(true) : remove())}
      />
      <Dialog open={dialogOpen}>
        <DialogTitle id='dialog-title' sx={{ minWidth: 320 }}>
          {t('ra.message.delete_title', {
            name: t('resources.env_vars.name', { smart_count: 1 }).toLowerCase()
          })}
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)}>
            {t('ra.action.cancel')}
          </Button>
          <Button variant='contained' color={'error'} onClick={() => remove()}>
            {t('ra.action.delete')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export const useEnvVarsData = props => {
  const {
    projectTypeVersion: projectTypeVersionProp,
    componentVersion: componentVersionProp
  } = props;

  const [projectTypeVersionField, componentVersionField] = useWatch({
    name: ['projectTypeVersion', 'componentVersion']
  });

  const projectTypeVersion = projectTypeVersionProp || projectTypeVersionField;
  const componentVersion = componentVersionProp || componentVersionField;

  const {
    data: projectTypeVersionEnvVars,
    isLoading: isProjectTypeVersionEnvVarsLoading
  } = useGetList(
    'project_type_version_env_vars',
    { filter: { projectTypeVersion } },
    { enabled: !!projectTypeVersion?.length }
  );

  const {
    data: componentVersionEnvVars,
    isLoading: isComponentVersionEnvVarsLoading
  } = useGetList(
    'component_version_env_vars',
    { filter: { componentVersion } },
    { enabled: !!componentVersion?.length }
  );

  const envVarDefinitions = useMemo(
    () => [
      ...(projectTypeVersionEnvVars || []),
      ...(componentVersionEnvVars || [])
    ],
    [projectTypeVersionEnvVars, componentVersionEnvVars]
  );

  const {
    data: projectTypeVersionEnvVarExamples,
    isLoading: isProjectTypeVersionEnvVarExamplesLoading
  } = useGetList(
    'project_type_version_env_var_examples',
    {
      filter: {
        projectTypeVersionEnvVar: projectTypeVersionEnvVars?.map(({ id }) => id)
      }
    },
    { enabled: !!componentVersionEnvVars }
  );

  const {
    data: componentVersionEnvVarExamples,
    isLoading: isComponentVersonEnvVarExamplesLoading
  } = useGetList(
    'component_version_env_var_examples',
    {
      filter: {
        componentVersionEnvVar: componentVersionEnvVars?.map(({ id }) => id)
      }
    },
    { enabled: !!componentVersionEnvVars }
  );

  const envVarsExamples = useMemo(
    () => [
      ...(projectTypeVersionEnvVarExamples || []),
      ...(componentVersionEnvVarExamples || [])
    ],
    [projectTypeVersionEnvVarExamples, componentVersionEnvVarExamples]
  );

  const {
    defaultEnvVarType,
    isLoading: isEnvVarTypesLoading
  } = useDefaultEnvVarType();

  const isLoading =
    (projectTypeVersion &&
      (isProjectTypeVersionEnvVarsLoading ||
        isProjectTypeVersionEnvVarExamplesLoading)) ||
    (componentVersion?.length &&
      (isComponentVersionEnvVarsLoading ||
        isComponentVersonEnvVarExamplesLoading)) ||
    isEnvVarTypesLoading;

  return {
    envVarDefinitions,
    defaultEnvVarType,
    envVarsExamples,
    isLoading
  };
};

const PREFIX = 'EnvVarInputs';

const classes = {
  formIterator: `${PREFIX}-formIterator`,
  loading: `${PREFIX}-loading`
};

const Root = styled(Box)(({ theme }) => ({
  alignSelf: 'flex-start',
  width: '100%',

  [`&.${classes.loading}`]: {
    display: 'flex',
    width: '100%',
    minHeight: 200,
    alignItems: 'center',
    justifyContent: 'center'
  },

  [`& .${classes.formIterator}, & .${EnvVarFieldsClasses.root}`]: {
    maxWidth: '50em',

    [`& .${AutocompleteInputClasses.textField}, & .${textFieldClasses.root}`]: {
      minWidth: theme.spacing(25)
    }
  },

  [`& .${SimpleFormIteratorClasses.action}`]: {
    minWidth: '5.625rem'
  },

  [`& .${formHelperTextClasses.root}:not(.${formHelperTextClasses.error})`]: {
    display: 'none'
  },

  [`& .${ToggleIconInputClasses.button}`]: {
    paddingTop: theme.spacing(2.25)
  }
}));

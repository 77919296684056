import React, { useState, useCallback, useEffect } from 'react';
import { useTranslate } from 'react-admin';
import { useWatch } from 'react-hook-form';
import { TextField } from '@mui/material';
import { RegExpWatchField } from '../field/RegExpWatchField';
import { createRegExpFromPlainString } from '../../utils';

export const RegExpTestInput = () => {
  const t = useTranslate();
  const [value, setValue] = useState('');
  const [error, setError] = useState(null);
  const regExpValue = useWatch({ name: 'regexp' });

  const validate = useCallback((value, regExp) => {
    if (!regExp) return setError(null);
    const rows = value ? value?.split('\n') : [];
    try {
      setError(
        rows.length &&
          rows.some(row => !createRegExpFromPlainString(regExp).test(row))
          ? true
          : null
      );
    } catch (e) {
      setError('resources.env_var_validations.validation.invalid_regexp');
    }
  }, []);

  useEffect(() => {
    validate(value, regExpValue);
  }, [regExpValue, validate, value]);

  return (
    <TextField
      value={value}
      onChange={event => {
        setValue(event.target?.value);
      }}
      label={t('resources.env_var_validations.fields.test')}
      error={!!error}
      color={error ? 'error' : 'success'}
      multiline
      fullWidth
      helperText={<RegExpWatchField source={'regexp'} />}
    />
  );
};

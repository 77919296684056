import React from 'react';
import { RecordContextProvider, useRecordContext } from 'react-admin';

/**
 * Removes unauthorized teams from the "team" field to prevent ReferenceArrayInput fetch them
 * producing error.
 *
 * @param {object} props
 * @param {array} props.authorizedData Array of data which are allowed to exist in the form.
 * @returns {RecordContextProvider}
 */
export const UnauthorizedRecordContextModifier = props => {
  const { authorizedData, source, ...rest } = props;
  const record = useRecordContext();

  const newValue =
    record && authorizedData
      ? {
          ...record,
          [source]: record[source]?.filter(itemId =>
            authorizedData.some(({ id }) => itemId === id)
          )
        }
      : null;

  return <RecordContextProvider {...rest} value={newValue} />;
};

/**
 * Appends unaothorized teams to the "team" field (which are removed in RecordContextModifier)
 * @param {import('react-admin').RaRecord} originalRecord
 * @param {array} authorizedData
 * @returns {any}
 */
export const transformUnauthorized = (
  originalRecord,
  field,
  authorizedData
) => data => {
  return {
    ...data,
    [field]: [
      ...originalRecord[field].filter(
        teamId => !authorizedData.some(team => team.id === teamId)
      ),
      ...data[field]
    ]
  };
};

import React from 'react';
import { useWatch } from 'react-hook-form';
import { GroupedSelectArrayInput } from '@rc/admin/components';

export const ComponentVersionArrayInput = props => {
  const [projectTypeVersion, requiredComponents] = useWatch({
    name: ['projectTypeVersion', 'requiredComponent']
  });

  return (
    <GroupedSelectArrayInput
      groupReference={'components'}
      optionText={choice => choice && (choice.version || choice.name)}
      requiredGroups={requiredComponents || []}
      requiredFirst={true}
      disabled={!projectTypeVersion}
      {...props}
    />
  );
};

export const createAvatarText = value => {
  if (!value) {
    return '';
  }

  const valueParts = value.split(' ');
  const code =
    valueParts.length > 1 && valueParts[1]
      ? `${valueParts[0][0]}${valueParts[1][0]}`
      : valueParts[0].slice(0, 2);

  return code.toUpperCase();
};

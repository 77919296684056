import React, { useCallback, useState } from 'react';
import { sanitizeFieldRestProps, useRecordContext } from 'react-admin';
import { IconButton, styled, Typography } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useDecodedRecordField } from '@rc/admin/hooks';

/**
 *
 * @param {object} props
 * @param {string} props.className
 * @param {string} props.source
 * @param {string} props.emptyText
 * @param {boolean} props.decodeBase64
 * @returns
 */
export const PasswordField = props => {
  const { className, source, emptyText, decodeBase64, ...rest } = props;
  const record = useRecordContext(props);
  const value = useDecodedRecordField({ record, source, decodeBase64 });

  const [isHidden, setIsHidden] = useState(true);

  const Icon = isHidden ? Visibility : VisibilityOff;

  const onToggleShowButtonClick = useCallback(e => {
    e.stopPropagation();
    setIsHidden(curr => !curr);
  }, []);

  return (
    <StyledTypography
      component='span'
      variant='body2'
      className={className}
      {...sanitizeFieldRestProps(rest)}
    >
      {value != null && typeof value !== 'string'
        ? JSON.stringify(value)
        : isHidden
        ? getHiddenValue(value)
        : value || emptyText}
      {value && (
        <IconButton
          size={'small'}
          className={classes.iconButton}
          aria-label='Show/hide password'
          onClick={onToggleShowButtonClick}
          sx={{ padding: 0.75, fontSize: 16 }}
        >
          <Icon fontSize={'inherit'} />
        </IconButton>
      )}
    </StyledTypography>
  );
};

const getHiddenValue = originalValue =>
  Array(originalValue?.length || 0)
    .fill('•')
    .join('');

const PREFIX = 'PasswordField';

const classes = {
  iconButton: `${PREFIX}-iconButton`
};

PasswordField.defaultProps = {
  decodeBase64: false
};

const StyledTypography = styled(Typography)(({ theme }) => ({
  [`& .${classes.iconButton}`]: {
    marginLeft: theme.spacing(0.5)
  }
}));

export default PasswordField;

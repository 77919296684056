import React from 'react';
import {
  BooleanInput,
  Create,
  SimpleForm,
  TextInput,
  required
} from 'react-admin';
import { Grid } from '@mui/material';
import {
  useEmailExistanceValidation,
  useUsernameExistanceValidation
} from '@rc/admin/hooks';

export const UserCreate = () => {
  const checkUsernameExistence = useUsernameExistanceValidation();
  const checkEmailExistence = useEmailExistanceValidation();

  return (
    <Create>
      <SimpleForm defaultValues={{ isEnabled: true }} reValidateMode='onBlur'>
        <Grid container spacing={0.5}>
          <Grid item xs={12}>
            <TextInput
              source={'username'}
              fullWidth
              validate={[required(), checkUsernameExistence]}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput source={'fullname'} fullWidth validate={[required()]} />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              source={'email'}
              fullWidth
              validate={[required(), checkEmailExistence]}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              label='resources.users.fields.password'
              source={'changePassword'}
              fullWidth
              validate={[required()]}
            />
          </Grid>
          <Grid item xs={12}>
            <BooleanInput source={'isEnabled'} fullWidth />
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
};

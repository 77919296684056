import React from 'react';
import {
  BooleanInput,
  Edit,
  FormTab,
  Labeled,
  PasswordInput,
  SelectArrayInput,
  TabbedForm,
  TextField,
  TextInput,
  Toolbar as RaToolbar,
  useUnique,
  SaveButton,
  ToolbarClasses,
  DeleteButton,
  useRecordContext,
  useTranslate,
  useNotify,
  useUnselect,
  useRedirect,
  useResourceContext,
  useRefresh
} from 'react-admin';
import { EditTitle } from '@rc/admin/components';
import { ROLES } from '@rc/admin/roles';
import { useIdParam } from '@rc/admin/hooks';
import { Tooltip } from '@mui/material';
import { useOrgContext } from '@rc/admin/context';

const choices = Object.values(ROLES).map(role => ({ id: role, name: role }));

export const UserEdit = () => {
  const unique = useUnique();
  const { originId } = useIdParam();

  return (
    <Edit redirect={false} title={<EditTitle />} mutationMode='pessimistic'>
      <TabbedForm defaultValues={{ isEnabled: true }} toolbar={<Toolbar />}>
        <FormTab label='resources.users.tabs.basic'>
          <Labeled sx={{ mb: 3 }}>
            <TextField source='username' />
          </Labeled>
          <TextInput
            source={'email'}
            type={'email'}
            fullWidth
            validate={[
              unique({
                filter: {
                  'id[not]': originId
                }
              })
            ]}
          />
          <TextInput source={'fullname'} fullWidth />
          <BooleanInput source={'isEnabled'} fullWidth />
        </FormTab>
        <FormTab label='resources.users.tabs.security'>
          <PasswordInput source={'changePassword'} fullWidth />
          <SelectArrayInput
            source={'roles'}
            label='resources.users.fields.roles'
            choices={choices}
            fullWidth
          />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

const Toolbar = props => {
  const resource = useResourceContext();
  const record = useRecordContext();
  const orgContext = useOrgContext();
  const t = useTranslate();
  const notify = useNotify();
  const unselect = useUnselect(resource);
  const redirect = useRedirect();
  const refresh = useRefresh();

  const deleteButton = (
    <DeleteButton
      disabled={!record.isDeletable}
      mutationMode='pessimistic'
      mutationOptions={{
        onSuccess: () => {
          notify('ra.notification.deleted', {
            type: 'info',
            messageArgs: { smart_count: 1 }
          });
          unselect([record.id]);
          redirect('list', resource);

          setTimeout(() => {
            if (
              record.id === orgContext.organisation?.defaultUser ||
              record.id === orgContext.team?.defaultUser
            ) {
              refresh();
            }
          }, 0);
        },
      }}
    />
  );

  return (
    <RaToolbar {...props}>
      <div className={ToolbarClasses.defaultToolbar}>
        <SaveButton />
        {!!record.isDeletable ? (
          deleteButton
        ) : (
          <Tooltip
            title={t(
              'resources.users.message.userCannotBeDeletedIsAdminOfTeamWithProjects'
            )}
          >
            <div>{deleteButton}</div>
          </Tooltip>
        )}
      </div>
    </RaToolbar>
  );
};

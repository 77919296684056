import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { useTheme } from '@mui/material/styles';

import { RouterLink } from '../../routes/components';

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  const theme = useTheme();

  const PRIMARY_LIGHT = theme.palette.primary.light;

  const PRIMARY_MAIN = theme.palette.primary.main;

  const PRIMARY_DARK = theme.palette.primary.dark;

  // OR using local (public folder)
  // -------------------------------------------------------
  // const logo = (
  //   <Box
  //     component="img"
  //     src="/logo/logo_single.svg" => your path
  //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
  //   />
  // );

  const logo = (
    <Box
      ref={ref}
      component='div'
      sx={{
        width: 40,
        display: 'inline-flex',
        ...sx
      }}
      {...other}
    >
      <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        style={{ width: '100%', height: 'auto' }}
      >
        <defs>
          <linearGradient id='BG1' x1='100%' x2='50%' y1='9.946%' y2='50%'>
            <stop offset='0%' stopColor={PRIMARY_DARK} />
            <stop offset='100%' stopColor={PRIMARY_MAIN} />
          </linearGradient>

          <linearGradient id='BG2' x1='50%' x2='50%' y1='0%' y2='100%'>
            <stop offset='0%' stopColor={PRIMARY_LIGHT} />
            <stop offset='100%' stopColor={PRIMARY_MAIN} />
          </linearGradient>

          <linearGradient id='BG3' x1='50%' x2='50%' y1='0%' y2='100%'>
            <stop offset='0%' stopColor={PRIMARY_LIGHT} />
            <stop offset='100%' stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>

        <path
          fill='url(#BG1)'
          opacity='0.75'
          fillRule='evenodd'
          clipRule='evenodd'
          d='M22 14.3529C22 17.4717 19.4416 20 16.2857 20H10.4995C9.55792 18.7465 9 17.1884 9 15.5C9 11.3579 12.3579 8 16.5 8C17.009 8 17.5062 8.05071 17.9868 8.14736C18.0649 8.42841 18.1216 8.71822 18.1551 9.01498C20.393 9.78024 22 11.8811 22 14.3529Z'
        />
        <path
          fill='url(#BG2)'
          d='M12.4762 4C9.32028 4 6.7619 6.52827 6.7619 9.64706C6.7619 10.3369 6.88706 10.9978 7.11616 11.6089C6.8475 11.5567 6.56983 11.5294 6.28571 11.5294C3.91878 11.5294 2 13.4256 2 15.7647C2 18.1038 3.91878 20 6.28571 20H10.4995C9.55792 18.7465 9 17.1884 9 15.5C9 11.3579 12.3579 8 16.5 8C17.009 8 17.5062 8.05071 17.9868 8.14736C17.9721 8.09441 17.9566 8.04178 17.9403 7.98948C17.2237 5.67956 15.0484 4 12.4762 4Z'
        />
      </svg>
    </Box>
  );

  if (disabledLink) {
    return logo;
  }

  return (
    <Link component={RouterLink} href='/' sx={{ display: 'contents' }}>
      {logo}
    </Link>
  );
});

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object
};

export default Logo;

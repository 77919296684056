import React from 'react';
import clsx from 'clsx';
import {
  FormControl,
  FormHelperText,
  ToggleButton,
  styled
} from '@mui/material';
import { InputHelperText, useInput } from 'react-admin';
import { Check, Close } from '@mui/icons-material';

/**
 * @typedef {Object} ToggleInputIconProps
 * @property {string} source
 * @property {string} defaultValue
 * @property {string} title
 * @property {React.ReactNode} iconChecked
 * @property {React.ReactNode} iconUnchecked
 *
 * @param {ToggleInputIconProps & import('@mui/material').ToggleButtonProps} props
 * @returns
 */
export const ToggleIconInput = props => {
  const {
    source,
    className,
    defaultValue,
    title,
    iconChecked,
    iconUnchecked,
    ...rest
  } = props;
  const {
    field,
    id,
    fieldState: { isTouched, error },
    formState: { isSubmitted }
  } = useInput({
    source,
    defaultValue,
    type: 'checkbox'
  });

  return (
    <StyledFormControl
      className={clsx(ToggleIconInputClasses.root, className)}
      {...rest}
    >
      <input type='hidden' id={id} {...field} />
      <ToggleButton
        className={ToggleIconInputClasses.button}
        title={title}
        disableTouchRipple
        selected={!!field.value}
        value={field.value}
        onChange={e => {
          field.onChange({
            ...e,
            target: {
              ...e.target,
              checked: !field.value ? true : false,
              value: 'on'
            }
          });
        }}
      >
        {!field.value ? iconUnchecked : iconChecked}
      </ToggleButton>
      <FormHelperText>
        <InputHelperText
          touched={isTouched || isSubmitted}
          error={error?.message}
        />
      </FormHelperText>
    </StyledFormControl>
  );
};

const PREFIX = 'ToggleIconInput';

export const ToggleIconInputClasses = {
  root: `${PREFIX}-root`,
  button: `${PREFIX}-button`
};

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  [`&.${ToggleIconInputClasses.root}`]: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center'
  },

  [`& .${ToggleIconInputClasses.button}`]: {
    border: 'none',
    '&:hover, &:focus, &:active': {
      backgroundColor: 'transparent !important'
    },
    '&:hover': {
      opacity: 0.75
    },
    [`&.Mui-selected`]: {
      backgroundColor: 'transparent !important',
      color: theme.palette.primary,
      boxShadow: 'none'
    }
  }
}));

ToggleIconInput.defaultProps = {
  iconChecked: <Check />,
  iconUnchecked: <Close />
};

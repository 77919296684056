import React, { useState, forwardRef, useCallback } from 'react';
import clsx from 'clsx';
import { NavLink, useLocation } from 'react-router-dom';
import {
  useGetList,
  MenuItemLink as RaMenuItemLink,
  useGetResourceLabel
} from 'react-admin';
import { styled, Typography } from '@mui/material';
import { ChevronRight } from '@mui/icons-material';
import { SidebarSubMenu } from '../SidebarSubMenu';
import { getIsResourcePathActive, getResourcePath } from '@rc/admin/utils';
import { MenuItemButton } from './MenuItemButton';

const NavLinkRef = forwardRef((props, ref) => (
  <StyledNavLink
    ref={ref}
    className={({ isActive }) =>
      clsx(classes.root, props.className, isActive ? classes.active : '')
    }
    {...props}
  />
));

export const SubMenuItemLink = forwardRef((props, ref) => {
  const { parentResource, resource, id, primaryText, leftIcon } = props;
  const childOptions = parentResource?.options?.child || {};

  const { pathname } = useLocation();
  const [open, setOpen] = useState(false);
  const getResourceLabel = useGetResourceLabel();

  const { data: resourcesData = [] } = useGetList(resource.name);
  const data = resourcesData.filter(item => item[childOptions.parentId] === id);

  const toggleOpen = useCallback(() => {
    setOpen(curr => !curr);
  }, []);

  const isActive =
    !!getIsResourcePathActive(pathname, resource.name, id) ||
    data.some(({ id: childId }) =>
      getIsResourcePathActive(pathname, resource.name, childId)
    );

  return (
    <>
      <StyledMenuItemButton
        ref={ref}
        className={clsx(classes.root, isActive ? classes.active : classes.root)}
        aria-expanded={open ? 'true' : undefined}
        onClick={toggleOpen}
        primaryText={primaryText}
        leftIcon={leftIcon}
        rightIcon={<ChevronRight />}
      />

      <SidebarSubMenu isOpen={open} onClickAway={() => setOpen(false)}>
        <RaMenuItemLink
          onClick={toggleOpen}
          component={NavLinkRef}
          to={{
            pathname: getResourcePath(parentResource.name, id),
            state: { _scrollToTop: true }
          }}
          primaryText={childOptions.parentTitle}
          leftIcon={
            childOptions.parentIcon ? <childOptions.parentIcon /> : null
          }
        />
        {!!data.length && (
          <StyledTypography variant={'subtitle2'} component={'div'}>
            {getResourceLabel(resource.name, 2)}
          </StyledTypography>
        )}
        {!!data.length &&
          data.map(({ name, id }) => (
            <RaMenuItemLink
              key={name}
              tabIndex={0}
              onClick={toggleOpen}
              component={NavLinkRef}
              to={{
                pathname: getResourcePath(resource.name, id),
                state: { _scrollToTop: true }
              }}
              primaryText={name}
              leftIcon={resource.icon ? <resource.icon /> : null}
            />
          ))}
      </SidebarSubMenu>
    </>
  );
});

const PREFIX = 'SubMenuItemLink';

const classes = {
  root: `${PREFIX}-root`,
  active: `${PREFIX}-active`,
  subTitle: `${PREFIX}-subTitle`
};

const StyledMenuItemButton = styled(MenuItemButton)(({ theme }) => ({
  [`&.${classes.root}`]: inheritRootStyles(theme),

  [`&.${classes.active}`]: inheritActiveStyles(theme)
}));

const StyledNavLink = styled(NavLink)(({ theme }) => ({
  [`&.${classes.root}`]: inheritRootStyles(theme),

  [`&.${classes.active}`]: inheritActiveStyles(theme)
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  opacity: 0.5,
  textTransform: 'uppercase',
  marginTop: theme.spacing(1.5),
  marginBottom: theme.spacing(1),
  marginLeft: theme.spacing(2)
}));

const inheritRootStyles = theme => ({
  color: theme.palette.text.primary,
  display: 'grid',
  gridTemplateColumns: `40px 140px 24px`,
  justifyContent: 'stretch'
});

const inheritActiveStyles = theme => ({
  ...(theme.components?.MuiListItemButton?.styleOverrides?.root[
    '&.Mui-selected'
  ] || {})
});

SubMenuItemLink.displayName = 'SubMenuItemLink';

import { useGetOne } from 'react-admin';

/**
 *
 * @param {object} props
 * @param {object} props.project
 * @param {boolean} props.enabled
 * @returns
 */
export const useProjectType = props => {
  const { project, templateProject, enabled } = props;

  const { data: projectTypeVersion, isLoading: isProjectTypeVersionLoading } =
    useGetOne(
      'project_type_versions',
      { id: project?.projectTypeVersion },
      { enabled: enabled && !!project }
    );

  const { data: projectType, isLoading: isProjectTypeLoading } = useGetOne(
    'project_types',
    { id: projectTypeVersion?.projectType },
    { enabled: enabled && !!projectTypeVersion }
  );

  return {
    isLoading: isProjectTypeVersionLoading || isProjectTypeLoading,
    projectType
  };
};

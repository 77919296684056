import React from 'react';
import { AccessCodeList } from './List';
import { AccessCodeCreate } from './Create';
import Iconify from '@rc/admin/components/ui/Iconify';

export default {
  name: 'access_codes',
  icon: () => <Iconify icon='solar:key-minimalistic-bold-duotone' />,
  list: AccessCodeList,
  create: AccessCodeCreate
};

import React from 'react';
import { ArrowBack, ArrowForward } from '@mui/icons-material';

export const TourArrow = props => {
  const { inverted, disabled } = props;
  const ArrowIcon = inverted ? ArrowForward : ArrowBack;

  return (
    <ArrowIcon
      fontSize='inherit'
      sx={theme => ({
        display: disabled ? 'none' : undefined,
        color: !disabled
          ? theme.palette.primary.main
          : theme.palette.text.disabled,
        fontSize: 32,
        padding: theme.spacing(0.75),
        borderRadius: '50%',
        ':hover': !disabled
          ? {
              backgroundColor:
                theme.palette.mode === 'light'
                  ? `rgba(0, 0, 0, 0.04)`
                  : `rgba(255, 255, 255, 0.08)`
            }
          : undefined
      })}
    />
  );
};

import React from 'react';
import { Alert, Grid, Typography, styled, Link } from '@mui/material';
import {
  Create,
  SimpleForm,
  TextInput,
  required,
  useTranslate
} from 'react-admin';
import { useLocation } from 'react-router-dom';
import { RegExpTestInput } from './components';
import { REGEXP_DOCS_URL, validateRegExp } from './utils';

export const EnvVarValidationCreate = () => {
  const t = useTranslate();
  const { state } = useLocation();

  return (
    <StyledCreate redirect={state?.redirectTo} mutationMode='pessimistic'>
      <SimpleForm className={classes.form}>
        <Grid container columnSpacing={3}>
          <Grid item xs={12} sm={6}>
            <TextInput source={'name'} fullWidth validate={[required()]} />
            <TextInput
              source={'regexp'}
              autoComplete='off'
              validate={[required(), validateRegExp]}
              fullWidth
              sx={theme => ({ marginBottom: theme.spacing(1) })}
            />
            <TextInput
              source={'validationMessage'}
              multiline
              fullWidth
              validate={[required()]}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <RegExpTestInput />
          </Grid>
          <Grid item xs={12}>
            <Alert severity='info'>
              <Typography mb={2}>
                {t('resources.env_var_validations.messages.usage_notes') + ' '}
                <Link href={REGEXP_DOCS_URL} target='_blank'>
                  {REGEXP_DOCS_URL}
                </Link>
              </Typography>
            </Alert>
          </Grid>
        </Grid>
      </SimpleForm>
    </StyledCreate>
  );
};

const PREFIX = 'EnvVarValidationCreate';

const classes = {
  form: `${PREFIX}-form`
};

const StyledCreate = styled(Create)(() => ({
  [`& .${classes.form}`]: {}
}));

import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import {
  AutocompleteArrayInput,
  ChoicesContextProvider,
  useChoicesContext,
  useRecordContext
} from 'react-admin';

export const TeamArrayInput = props => {
  const record = useRecordContext();
  const { allChoices = [], ...restChoicesContext } = useChoicesContext();
  const filteredAllChoices = allChoices.filter(
    ({ organisation }) => !organisation || organisation === record?.id
  );

  // TODO: Refactor when "getOptionDisabled" field works in AutocompleteInput
  const [defaultTeam, setDefaultTeam] = useState();

  useEffect(() => {
    setDefaultTeam(
      curr =>
        filteredAllChoices.find(
          choice => choice.isDefault && choice.organisation === record?.id
        ) || curr
    );
  }, [allChoices, filteredAllChoices, record]);

  return (
    <ChoicesContextProvider
      value={{
        allChoices: filteredAllChoices,
        ...restChoicesContext
      }}
    >
      <AutocompleteArrayInput
        {...props}
        optionText={record => `${record.name} (${record.originId})`}
        validate={validate}
        parse={values => {
          /**
           * Prevent deleting the default team.
           */
          return !defaultTeam || values?.find(value => value === defaultTeam.id)
            ? values
            : [defaultTeam.id].concat(values || []);
        }}
        fullWidth
      />
    </ChoicesContextProvider>
  );
};

const validate = (value = []) => {
  if (_.isEmpty(value)) {
    return {
      message: 'resources.organisations.validation.min_one_team',
      args: {}
    };
  }

  return undefined;
};

import { useIsTemplateResource } from '@rc/admin/hooks';
import { required } from 'react-admin';

export const useIsEnvVarRequired = (key, envVars) => {
  return !useIsTemplateResource() && isEnvVarRequired(key, envVars);
};

export const isEnvVarRequired = (key, envVars) => {
  return !!(
    key && envVars.some(envVar => envVar.key === key && envVar.isRequired)
  );
};

/**
 * 'Required' validation to secure inputs. Secure inputs get null value from the server, therefore it is acceptable.
 * @param {string} message
 * @returns {Function}
 */
export const requiredSecure = message => (value, values) => {
  if (value === null || value === '' || value === undefined) {
    return undefined;
  }

  return required(message)(value, values);
};

import { createSourceUtil, getSourceUtil } from '@rc/admin/utils/form';
import React, { useCallback, useMemo } from 'react';
import { InputHelperText, required, useTranslate } from 'react-admin';
import { useFormContext, useWatch } from 'react-hook-form';
import { useIsEnvVarRequired } from './utils';
import { CreatableAutocompleteInput } from '@rc/admin/components';
import { TextField } from '@mui/material';

const validateEnvVarKey = value => {
  return !/^[A-Z0-9_]+/.test(value)
    ? 'components.envVar.validation.invalid_key'
    : undefined;
};

export const EnvVarKeyInput = props => {
  const { onKeyDown, envVarDefinitions, source, ...rest } = props;
  const t = useTranslate();
  const { formState } = useFormContext();
  const { member: memberSource, index } = getSourceUtil(source);
  const [allEnvVars, currentEnvVar = null] = useWatch({
    name: [memberSource, createSourceUtil(memberSource, index)]
  });

  const choices = useMemo(() => {
    if (!allEnvVars) {
      return [];
    }

    return envVarDefinitions.filter(
      choice =>
        choice.key &&
        // Filter already selected
        !allEnvVars.some(
          (value, valueIndex) =>
            value && valueIndex !== index && value.key === choice.key
        )
    );
  }, [allEnvVars, envVarDefinitions, index]);

  // Once a choice has been selected, focus the "value" input field next to the current "key" input field
  const handleKeyDown = useCallback(
    event => {
      // Focus the "value" input in the same row
      if (event.key === 'Enter') {
        document.getElementById(source.replace('key', 'value')).focus();
      }
    },
    [source]
  );

  const isRequired = useIsEnvVarRequired(currentEnvVar?.key, envVarDefinitions);

  // Fix setting disabled to true clears the value
  if (isRequired) {
    const { errors, isSubmitted, touchedFields } = formState;
    const error = errors[memberSource]?.[index]?.[source];
    const isTouched = touchedFields[memberSource]?.[index]?.[source];

    return (
      <TextField
        disabled
        fullWidth
        label={t('components.envVar.fields.key')}
        value={currentEnvVar?.key}
        error={(isTouched || isSubmitted) && !!error}
        {...rest}
        helperText={
          <InputHelperText
            touched={isTouched || isSubmitted}
            error={error?.message}
          />
        }
        sx={theme => ({
          minWidth: theme.spacing(20)
        })}
      />
    );
  }

  return (
    <CreatableAutocompleteInput
      source={source}
      choices={choices}
      optionText={'key'}
      optionValue={'key'}
      onKeyDown={handleKeyDown}
      TextFieldProps={{
        InputProps: {
          onKeyDown
        }
      }}
      validate={[required(), validateEnvVarKey]}
      {...rest}
    />
  );
};

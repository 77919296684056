import React, { useCallback, useState } from 'react';
import _ from 'lodash';
import { useAxios } from '@rc/utils/axios';
import {
  Form,
  RecordContextProvider,
  ResourceContextProvider,
  Toolbar,
  useEditController,
  useNotify,
  useRefresh,
  useTranslate,
  LinearProgress
} from 'react-admin';
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Stack,
  styled,
  Typography
} from '@mui/material';
import { Upload as UploadIcon } from '@mui/icons-material';
import { SectionTitle } from '@rc/admin/components';
import { TwoColumnFormStyles } from '@rc/admin/theme/styles';
import { DataImportInputs, DataImportList } from '../components';
import { useFormRootPath } from './utils';
import { RCWorkerRecordContextProvider } from '@rc/admin/context';

export const EnvironmentImport = props => {
  const controllerProps = useEditController(props);
  const { resource, record } = controllerProps;
  const formRootPathname = useFormRootPath();
  const t = useTranslate();
  const notify = useNotify();
  const refresh = useRefresh();
  const [{ loading, error }, executeImport] = useAxios(
    {
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    },
    {
      manual: true
    }
  );
  const [progress, setProgress] = useState(null);
  const [isLengthComputable, setIsLengthComputable] = useState(true);

  const onSubmit = useCallback(
    async data => {
      setProgress(null);
      setIsLengthComputable(true);

      const { dataTransfer, dataTransferFile } = data;
      const importForm = new window.FormData();

      if (!_.isEmpty(dataTransfer)) {
        importForm.append('dataTransferSettings', JSON.stringify(dataTransfer));
      }

      if (dataTransferFile?.rawFile instanceof window.File) {
        importForm.append('dataTransferFile', dataTransferFile.rawFile);
        importForm.append('dataTransferFileName', dataTransferFile.title);
      }

      if (dataTransfer?.uploadProcess) {
        try {
          await executeImport({
            url: `${data['@id']}/${dataTransfer.uploadProcess}`,
            data: importForm,
            onUploadProgress: event => {
              if (dataTransferFile?.rawFile) {
                const { loaded, total, lengthComputable, type } = event;
                setIsLengthComputable(lengthComputable || type === 'loadend');
                if (lengthComputable) {
                  setProgress((loaded / total) * 100);
                }
              }
            }
          });

          notify('Import has been successfully processed', { type: 'success' });

          setTimeout(() => {
            setProgress(null);
          }, 1000);

          refresh();
        } catch (error) {
          setProgress(null);
          notify(error.message, { type: 'error' });
        }
      }
    },
    [notify, refresh, executeImport]
  );

  return (
    <ResourceContextProvider value={resource}>
      <RecordContextProvider value={record}>
        <Root
          sx={theme => ({ ...TwoColumnFormStyles, rowGap: theme.spacing(2) })}
        >
          <Form formRootPathname={formRootPathname} onSubmit={onSubmit}>
            <Card>
              <CardContent>
                <DataImportInputs />
                <SectionTitle sx={{ mt: 4 }}>{'Previous imports'}</SectionTitle>
                <RCWorkerRecordContextProvider>
                  <DataImportList />
                </RCWorkerRecordContextProvider>
              </CardContent>
              <Toolbar>
                <Stack
                  direction={'row'}
                  columnGap={3}
                  alignItems={'center'}
                  width='100%'
                >
                  <Button
                    type='submit'
                    disabled={loading}
                    className={classes.saveButton}
                    color='primary'
                    variant='contained'
                    startIcon={
                      loading ? (
                        <CircularProgress size={18} thickness={2} />
                      ) : null
                    }
                  >
                    {t('action.import')}
                  </Button>

                  {(loading || progress !== null) && (
                    <Stack
                      direction={'row'}
                      columnGap={1}
                      alignItems={'center'}
                      width='100%'
                    >
                      <LinearProgress
                        sx={{ width: '100%' }}
                        variant={
                          isLengthComputable ? 'determinate' : 'indeterminate'
                        }
                        color={loading ? 'info' : error ? 'error' : 'success'}
                        value={progress}
                      />
                      {isLengthComputable &&
                        progress !== null &&
                        !Number.isNaN(progress) && (
                          <Typography variant='body2' color='text.secondary'>
                            {`${progress?.toFixed(2)}%`}
                          </Typography>
                        )}
                    </Stack>
                  )}
                </Stack>
              </Toolbar>
            </Card>
          </Form>
        </Root>
      </RecordContextProvider>
    </ResourceContextProvider>
  );
};

const PREFIX = `EnvironmentImport`;

const classes = {
  saveButton: `${PREFIX}-saveButton`
};

const Root = styled(Stack)(({ theme }) => ({
  [`& .${classes.saveButton}`]: {
    position: 'relative',
    [`& .MuiSvgIcon-root, & .MuiIcon-root, & .MuiCircularProgress-root`]: {
      marginRight: theme.spacing(1)
    },
    [`& .MuiSvgIcon-root, & .MuiIcon-root`]: {
      fontSize: 18
    }
  }
}));

import React, { useCallback, useMemo } from 'react';
import { useInput, Labeled, InputHelperText, useTimeout } from 'react-admin';
import {
  Box,
  Slider,
  Typography,
  styled,
  CircularProgress,
  Stack
} from '@mui/material';
import clsx from 'clsx';
import { RestartAlt } from '@mui/icons-material';
import { useFormContext } from 'react-hook-form';

/**
 * @typedef {Object} CustomProps
 * @property {function} format
 * @property {function} parse
 * @property {number?} lowerLimit
 * @property {number?} upperLimit
 * @property {boolean} enableReset Enable reset button when value is larger then the upper limit. (Default: false)
 *
 * @typedef {import('@mui/material').SliderProps & CustomProps & import('react-admin').UseInputValue} SliderInputProps
 *
 * @param {SliderInputProps} props
 * @returns
 */
export const SliderInput = props => {
  const {
    classes: overrideClasses,
    label,
    isLoading,
    disabled: disabledProp,
    format,
    min,
    max,
    step,
    marks,
    lowerLimit = min,
    upperLimit = max,
    className,
    margin,
    variant,
    helperText,
    fullWidth,
    enableReset
  } = props;

  const {
    field: {
      name,
      onChange,
      // The formatted value
      value,
      ...restInput
    },
    fieldState: { isTouched, error },
    formState: { isSubmitted },
    isRequired
  } = useInput(props);

  const isResettable = enableReset && value > upperLimit;

  const isValidConfiguration = lowerLimit <= upperLimit;
  // const isImmovable =
  //   value === format(lowerLimit) && value === format(upperLimit);

  const disabled =
    disabledProp ||
    isLoading ||
    // isImmovable ||
    /**
     * Disable input if the initial configuration is invalid. If it became invalid
     * after user interaction, it is likely because the limits where changed due
     * another input component.
     */
    (!isValidConfiguration && !isTouched);

  const sliderProps = useMemo(() => {
    const initialProps = {
      // min: format(min),
      min: 0,
      max: format(max),
      step: format(step),
      marks,
      disabled
    };

    if (!isValidConfiguration) {
      return {
        ...initialProps,
        max: 0
      };
    }

    return {
      ...initialProps,
      marks: getMarks(
        initialProps,
        value,
        format(lowerLimit),
        format(upperLimit)
      )
    };
  }, [
    // min,
    max,
    step,
    marks,
    disabled,
    isValidConfiguration,
    value,
    format,
    lowerLimit,
    upperLimit
  ]);

  // TODO: Decide whether the limit should be blocked
  const handleChange = useCallback(
    (e, value) => {
      if (value >= format(lowerLimit) && value <= format(upperLimit)) {
        e.target.value = value;
        onChange(e);
      }
    },
    [format, lowerLimit, upperLimit, onChange]
  );

  return (
    <StyledBox
      className={clsx(overrideClasses.root, classes.root)}
      width={fullWidth ? '100%' : undefined}
    >
      <Labeled
        className={clsx(classes.control, className)}
        label={
          isLoading ? (
            <LoadingLabel label={label} />
          ) : isResettable ? (
            <ResetLabel {...props} />
          ) : (
            label
          )
        }
        margin={margin}
        variant={variant}
      >
        <Slider
          classes={{
            root: clsx(
              classes.slider,
              overrideClasses.slider,
              error && (isTouched || isSubmitted) ? classes.rootError : ''
            ),
            thumb: classes.thumb
          }}
          name={name}
          value={value}
          onChange={handleChange}
          required={isRequired}
          sx={{ width: fullWidth ? '100%' : undefined }}
          {...restInput}
          {...sliderProps}
        />
      </Labeled>
      <Typography
        className={classes.errorMessage}
        variant={'body2'}
        color={error ? 'error' : 'inherit'}
      >
        <InputHelperText
          touched={isTouched || isSubmitted}
          error={error?.message || error?.root?.message}
          helperText={helperText}
        />
      </Typography>
    </StyledBox>
  );
};

const LoadingLabel = props => {
  const { label } = props;
  const oneSecondHasPassed = useTimeout(1000);

  return (
    <Stack
      component='span'
      direction={'row'}
      spacing={1}
      justifyContent={'space-between'}
      alignItems={'center'}
    >
      {label}
      {oneSecondHasPassed ? (
        <CircularProgress color='inherit' size={12} />
      ) : (
        <span style={{ width: 20 }}>&nbsp;</span>
      )}
    </Stack>
  );
};

const ResetLabel = props => {
  const { label, source, min } = props;
  const { setValue } = useFormContext();

  const handleReset = useCallback(() => {
    setValue(source, min, { shouldValidate: false });
  }, [min, setValue, source]);

  return (
    <Stack
      component='span'
      direction={'row'}
      spacing={1}
      justifyContent={'space-between'}
      alignItems={'center'}
      fontSize={'inherit'}
    >
      {label}
      <RestartAlt
        color='inherit'
        fontSize={'small'}
        onClick={handleReset}
        sx={{ cursor: 'pointer' }}
      />
    </Stack>
  );
};

const getMarks = (options, value, lowerLimit, upperLimit) => {
  if (options.marks === false || Array.isArray(options.marks)) {
    return options.marks;
  }

  const marks = [];

  const firstMarkValue =
    options.disabled && value < lowerLimit ? value : lowerLimit;

  marks.push({
    label: (
      <Typography
        key={`min-${options.min + Math.random().toString()}`}
        variant={'body2'}
        color={'textSecondary'}
      >
        {firstMarkValue}
      </Typography>
    ),
    value: firstMarkValue
  });

  // Avoid duplicate marks
  marks.push({
    label: (
      <Typography
        key={`max-${upperLimit + Math.random().toString()}`}
        variant={'body2'}
        color={'textSecondary'}
      >
        {upperLimit}
      </Typography>
    ),
    value: upperLimit
  });

  return marks;
};

const PREFIX = 'SliderInput';

const classes = {
  root: `${PREFIX}-root`,
  thumb: `${PREFIX}-thumb`,
  rootError: `${PREFIX}-rootError`,
  thumbError: `${PREFIX}-thumbError`,
  control: `${PREFIX}-control`,
  errorMessage: `${PREFIX}-errorMessage`
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(0.5)
  },

  [`& .${classes.thumb}`]: {},

  [`& .${classes.rootError}`]: {
    color: theme.palette.error.main,
    [`& .${classes.thumb}`]: {
      color: theme.palette.error.main,
      boxShadow: '0px 0px 0px 14px rgb(244 67 54 / 16%) !important'
    }
  },

  [`& .${classes.control}`]: {
    marginBottom: 0
  },

  [`& .${classes.errorMessage}`]: {
    marginBottom: theme.spacing(1)
  }
}));

SliderInput.defaultProps = {
  classes: {},
  min: 0,
  max: 100,
  step: 1,
  marks: true,
  format: v => v
};

import { cloneDeep } from "lodash";

export function cloneRecord (record) {
    const cloned = cloneDeep(record);
    delete cloned.id;
    delete cloned['@id'];
    delete cloned['@type'];
    delete cloned['@context'];
    delete cloned['uuid'];

    return cloned;
  }
import React, { useCallback, useState } from 'react';
import { useGetIdentity, useTranslate, useNotify } from 'react-admin';
import {
  LinkAuthenticationElement,
  PaymentElement,
  useElements,
  useStripe,
  AddressElement
} from '@stripe/react-stripe-js';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Typography
} from '@mui/material';

export const SubscriptionForm = props => {
  const { onSuccess } = props;
  const t = useTranslate();
  const notify = useNotify();
  const { identity } = useGetIdentity();
  const fullname = identity?.fullName;
  const stripe = useStripe();
  const elements = useElements();
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const handleSubmit = useCallback(
    async event => {
      event.preventDefault();
      setIsLoading(true);
      setMessage(null);

      const { error, setupIntent } = await stripe.confirmSetup({
        elements,
        redirect: 'if_required'
      });

      if (setupIntent) {
        const isDone = handleConfirmSetup({ setupIntent, error });

        if (isDone) {
          setIsLoading(false);
        } else {
          const interval = window.setInterval(() => {
            stripe
              .retrieveSetupIntent(setupIntent.client_secret)
              .then(result => {
                if (handleConfirmSetup(result)) {
                  setIsLoading(false);
                  window.clearInterval(interval);
                }
              });
          }, 5000);
        }
      } else if (error) {
        setIsLoading(false);
        setMessage({
          type: 'error',
          text: getErrorMessage(error)
        });
      }

      function handleConfirmSetup ({ setupIntent, error }) {
        if (error) {
          setMessage({
            status: setupIntent.status,
            type: 'error',
            text: getErrorMessage(error)
          });
        } else {
          let isDone = false;
          switch (setupIntent.status) {
            case 'succeeded':
              isDone = true;
              notify('pages.billing.subscription.message.success', {
                type: 'success'
              });
              onSuccess();
              break;
            case 'processing':
              setMessage({
                status: setupIntent.status,
                text: 'pages.billing.subscription.message.processing'
              });
              break;
            case 'requires_payment_method':
              isDone = true;
              setMessage({
                status: setupIntent.status,
                type: 'error',
                text:
                  'pages.billing.subscription.message.requires_payment_method'
              });
              break;
            default:
              isDone = true;
              setMessage({
                status: setupIntent.status,
                type: 'error',
                text: 'pages.billing.subscription.message.default'
              });
              notify('pages.billing.subscription.message.default', {
                type: 'error'
              });
              break;
          }

          return isDone;
        }
      }
    },
    [elements, notify, onSuccess, stripe]
  );

  return (
    <form onSubmit={handleSubmit}>
      <Box sx={{ p: 2 }}>
        {isLoaded && (
          <SectionTitle>
            {t('pages.billing.subscription.fields.contact')}
          </SectionTitle>
        )}
        <LinkAuthenticationElement
          onLoaderStart={() => setIsLoaded(true)}
          options={{
            defaultValues: {
              email: 'admin@test.test'
            }
          }}
        />
      </Box>
      <Box sx={{ p: 2 }}>
        {isLoaded && (
          <SectionTitle>
            {t('pages.billing.subscription.fields.payment')}
          </SectionTitle>
        )}
        <PaymentElement
          options={{
            defaultValues: {
              billingDetails: {
                name: fullname
              }
            }
          }}
        />
      </Box>
      <Box sx={{ p: 2 }}>
        {isLoaded && (
          <SectionTitle>
            {t('pages.billing.subscription.fields.billing')}
          </SectionTitle>
        )}
        <AddressElement
          options={{
            mode: 'billing',
            display: {
              name: 'full'
            },
            defaultValues: {
              name: fullname
            }
          }}
        />
      </Box>
      {isLoaded && (
        <Box sx={{ p: 2 }}>
          <Button
            variant='contained'
            type={'submit'}
            disabled={isLoading}
            endIcon={isLoading && <CircularProgress size={12} />}
          >
            {t('action.submit')}
          </Button>
        </Box>
      )}
      {message?.type === 'error' && (
        <Box sx={{ p: 2 }}>
          <Alert severity='error'>{t(message.text)}</Alert>
        </Box>
      )}
    </form>
  );
};

const SectionTitle = props => (
  <Typography variant={'h4'} component={'div'} mb={1} {...props} />
);

const getErrorMessage = error => {
  return error.type === 'card_error' ||
    error.type === 'validation_error' ||
    error.type === 'invalid_request_error'
    ? error.code === 'incomplete_address'
      ? `pages.billing.subscription.message.${error.code}`
      : error.message
    : 'pages.billing.subscription.message.default';
};

export default SubscriptionForm;

import { useMemo } from 'react';
import { getProjectPrices } from '@rc/admin/utils/domain/costs';
import { useGetIdentity } from 'react-admin';
import { useAxios } from '@rc/utils/axios';
import data from '../../../../../data/test-data/user-costs.json';

export const useUserCosts = () => {
  const { identity, isLoading: isIdentityLoading } = useGetIdentity();

  const [{ loading: isCostsLoading, error }] = useAxios(
    `/api/users/${identity?.id}/finance/costs`,
    { manual: !identity }
  );

  const currency = useMemo(() => {
    return Object.keys(data).reduce((res1, key) => {
      return (
        res1 ||
        data[key]?.environment?.cluster?.products?.reduce(
          (res2, product) => res2 || product.defaultPrice?.currency,
          null
        )
      );
    }, null);
  }, []);

  const [costs, hasEnvironments] = useMemo(() => {
    if (!data) {
      return [null, false];
    }

    const environmentsData = Object.keys(data).reduce((acc, key) => {
      // Workaround to get environment costs without requesting the environments for uuid
      if (key !== 'summary' && data[key].costs && data[key].environment) {
        acc.push(data[key]);
      }
      return acc;
    }, []);

    return [getProjectPrices(environmentsData), !!environmentsData.length];
  }, []);

  return {
    error,
    isLoading: isIdentityLoading || isCostsLoading,
    currency,
    costs,
    summary: data?.summary,
    hasEnvironments
  };
};

import { useMemo } from 'react';
import { useAxios } from '@rc/utils/axios';
import { useRecordContext } from 'react-admin';
import { getEnvironmentPrices } from '@rc/admin/utils/domain/costs';

export const useEnvironmentCosts = props => {
  const { id } = props;
  const record = useRecordContext();

  const [{ data, loading: isLoading, error }] = useAxios(
    `/api/environments/${id}/costs`
  );

  const costs = useMemo(() => {
    if (!data || !record) {
      return null;
    }
    const products = data[record.uuid]?.environment?.cluster?.products;
    return getEnvironmentPrices(data.summary, products);
  }, [data, record]);

  return {
    error,
    isLoading,
    costs,
    summary: data?.summary
  };
};

import React from 'react';
import { ChoicesContextProvider, SelectInput } from 'react-admin';
import { STATE_CHOICES } from './stateMappings';

const choices = STATE_CHOICES.map(option => ({
  ...option,
  value: JSON.stringify(option.id)
}));

/**
 * @param {object} props
 * @param {string} props.source
 * @returns
 */
export const StateFilterSelectInput = props => {
  const { source } = props;

  return (
    <ChoicesContextProvider
      value={{
        allChoices: choices,
        availableChoices: choices,
        hasNextPage: false,
        hasPreviousPage: false,
        isFetching: false,
        isLoading: false,
        page: 1,
        perPage: 1000,
        sort: { field: 'name', order: 'ASC' },
        total: choices.length,
        error: false,
        source
      }}
    >
      <SelectInput
        format={format}
        parse={parse}
        emptyText={'misc.all'}
        optionText={'name'}
        optionValue={'value'}
      />
    </ChoicesContextProvider>
  );
};

/**
 *
 * @param {Number|Number[]} v
 * @returns
 */
function format (v) {
  const formatted = JSON.stringify(Array.isArray(v) ? v : [v]);
  return choices.find(choice => choice.value === formatted)?.value || '';
}

/**
 *
 * @param {string?} v
 * @returns
 */
function parse (v) {
  try {
    return JSON.parse(v);
  } catch (e) {
    return '';
  }
}

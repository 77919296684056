import React, { useCallback, useEffect, useState } from 'react';
import { RecordContextProvider, useTranslate } from 'react-admin';
import { styled, Box, LinearProgress, Typography } from '@mui/material';
import { UnfoldMoreOutlined as UnfoldMore } from '@mui/icons-material';
import { useOrgContext } from '@rc/admin/context';
import {
  DialogMenuItem,
  DialogMenuItemClasses
} from '@rc/admin/layout/MenuItem';
import { OrganisationField } from '../..';
import { Selector } from './Selector';

/**
 *
 * @param {object} props
 * @param {import('@mui/material').BoxProps} props.BoxProps
 */
export const SwitchTeam = props => {
  const t = useTranslate();

  const {
    organisation: selectedOrganisation,
    team: selectedTeam,
    organisationId: selectedOrganisationId,
    teamId: selectedTeamId,
    organisations,
    teams,
    isLoading,
    submit
  } = useOrgContext();

  const getOrgState = () => ({
    organisationId: selectedOrganisationId,
    teamId: selectedTeamId
  });

  const [currentOrg, setCurrentOrg] = useState(getOrgState);

  useEffect(() => {
    setCurrentOrg(getOrgState());
  }, [selectedOrganisation, selectedTeam]);

  const handleOrganisationChange = useCallback(
    nextOrganisation => {
      if (nextOrganisation !== null) {
        setCurrentOrg(curr => ({
          organisationId: nextOrganisation.id,
          teamId:
            nextOrganisation.team.find(
              teamId =>
                teamId === curr.teamId ||
                teamId === selectedTeam?.id ||
                // Select the organisation's default team
                teams?.some(team => team.id === teamId && team.isDefault)
            ) || nextOrganisation.team[0]
        }));
      }
    },
    [selectedTeam, teams]
  );

  const handleTeamChange = useCallback(nextTeam => {
    if (nextTeam !== null) {
      setCurrentOrg({
        organisationId: nextTeam.organisation,
        teamId: nextTeam.id
      });
    }
  }, []);

  const handleClose = useCallback(
    () =>
      setCurrentOrg({
        organisationId: selectedOrganisation?.id,
        teamId: selectedTeam?.id
      }),
    [selectedOrganisation, selectedTeam]
  );

  const handleAccept = useCallback(
    () => submit(currentOrg),
    [submit, currentOrg]
  );

  const titleAccess = t('components.switchTeam.access_title');

  return (
    <Root {...props} id='team-switcher'>
      <DialogMenuItem
        rightIcon={<UnfoldMore className={classes.rightIcon} />}
        primaryText={
          isLoading ? (
            <LinearProgress />
          ) : (
            <Box className={classes.label}>
              <Typography variant={'body1'} className={classes.organisation}>
                {selectedOrganisation && (
                  <RecordContextProvider value={selectedOrganisation}>
                    <OrganisationField label='' sx={{ fontSize: '0.75rem' }} />
                  </RecordContextProvider>
                )}
              </Typography>
              <Typography variant={'body2'} color={'textSecondary'}>
                {selectedTeam?.name}
              </Typography>
            </Box>
          )
        }
        title={titleAccess}
        dialogTitle={t('components.switchTeam.dialog_title')}
        onClose={handleClose}
        onAccept={handleAccept}
        canAccept={currentOrg.organisationId && currentOrg.teamId}
      >
        {!organisations || !teams ? null : (
          <Selector
            organisations={organisations}
            teams={teams}
            handleOrganisationChange={handleOrganisationChange}
            handleTeamChange={handleTeamChange}
            {...currentOrg}
          />
        )}
      </DialogMenuItem>
    </Root>
  );
};

const PREFIX = 'SwitchTeam';

const classes = {
  form: `${PREFIX}-form`,
  buttonGroupSubtitle: `${PREFIX}-buttonGroupSubtitle`,
  buttonGroup: `${PREFIX}-buttonGroup`,
  button: `${PREFIX}-button`,
  organisationButton: `${PREFIX}-organisationButton`,
  teamButton: `${PREFIX}-teamButton`,
  organisation: `${PREFIX}-organisation`,
  leftIcon: `${PREFIX}-leftIcon`,
  rightIcon: `${PREFIX}-rightIcon`
};

const Root = styled(Box)(({ theme }) => ({
  minWidth: 200,

  [`& .${classes.form}`]: {
    display: 'flex',
    flexWrap: 'wrap'
  },

  [`& .${classes.buttonGroupSubtitle}`]: {
    marginBottom: theme.spacing(1)
  },

  [`& .${classes.buttonGroup}`]: {
    marginBottom: theme.spacing(2)
  },

  [`& .${classes.button}`]: {
    '&.MuiButtonBase-root': {
      display: 'flex',
      columnGap: theme.spacing(1),
      justifyContent: 'space-between',
      minWidth: '170px'
    }
  },

  [`& .${classes.organisationButton}`]: {},

  [`& .${classes.teamButton}`]: {},

  [`& .${classes.organisation}`]: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    display: 'block'
  },

  [`& .${DialogMenuItemClasses.menuItem}`]: {
    marginBottom: 0,
    minHeight: 60
  },

  [`& .${classes.leftIcon}`]: {
    color: theme.palette.primary[500]
  },

  [`& .${classes.rightIcon}`]: {}
}));

SwitchTeam.defaultProps = {};

import { FormControl, InputAdornment, TextField } from '@mui/material';
import React from 'react';
import { TextInput, useTranslate } from 'react-admin';
import { useWatch } from 'react-hook-form';
import { useIsCreated, useIsSubdomain } from './utils';
import { useEnvironmentAccessFrontendHostname } from '@rc/admin/hooks';
import { LoadingField } from '@rc/admin/components';

/**
 *
 * @param {import('react-admin').TextInputProps} props
 * @returns
 */
export const HostnameInput = props => {
  const t = useTranslate();
  const isCreated = useIsCreated();
  const isSubdomain = useIsSubdomain();

  if (!isCreated && isSubdomain) {
    return (
      <SubdomainHostnameInput
        {...props}
        label={t(
          'resources.environments.fields.environmentCustomDomain.subdomain'
        )}
      />
    );
  }

  return (
    <NormalHostnameInput
      {...props}
      label={t(
        'resources.environments.fields.environmentCustomDomain.hostname'
      )}
    />
  );
};

const NormalHostnameInput = props => {
  const { source, label } = props;
  const value = useWatch({
    name: source
  });

  const isCreated = useIsCreated();

  if (isCreated) {
    return (
      <FormControl fullWidth>
        <TextField label={label} value={value} disabled />
      </FormControl>
    );
  }

  return <TextInput {...props} />;
};

/**
 *
 * @param {import('react-admin').TextInputProps} props
 * @returns
 */
const SubdomainHostnameInput = props => {
  const {
    isLoading,
    hostname: frontendHostname
  } = useEnvironmentAccessFrontendHostname();

  if (isLoading) {
    return <LoadingField />;
  }

  return (
    <TextInput
      {...props}
      InputProps={{
        endAdornment: (
          <InputAdornment
            position='end'
            sx={theme => ({
              fontSize: theme.typography.fontSize
            })}
          >
            {`.${frontendHostname}`}
          </InputAdornment>
        )
      }}
      format={v => v?.replace(new RegExp(`\.${frontendHostname}`, 'g'), '')}
      parse={v => (v ? `${v}.${frontendHostname}` : '')}
    />
  );
};

import React from 'react';
import { SideDialog, useSideDialogContext } from '@rc/admin/components';
import {
  useCreateContext,
  useGetResourceLabel,
  useTranslate
} from 'react-admin';

export const CreateDialogView = props => {
  const { title, children, ...rest } = props;
  const { resource, defaultTitle } = useCreateContext(props);
  const t = useTranslate();
  const getResourceLabel = useGetResourceLabel();
  const [{ isOpen }, { open, close }] = useSideDialogContext();

  return (
    <SideDialog
      title={title || `${t('ra.action.edit')} ${getResourceLabel(resource, 1)}`}
      dialogTitle={title || defaultTitle}
      {...rest}
      isOpen={isOpen}
      handleClose={close}
      handleOpen={open}
    >
      {children}
    </SideDialog>
  );
};

import React from 'react';
import { Create } from 'react-admin';
import { styled } from '@mui/material';
import { DEFAULT_PROJECT_COLOR } from '@rc/admin/constants';
import { WizardForm, WizardFormStep } from '@rc/admin/components';
import { transformData } from './utils';
import {
  ProjectCreateStepDetails,
  ProjectCreateStepResources,
  ProjectCreateStepStack,
  ProjectCreateStepVariables
} from '@rc/admin/resources/project/Create/steps';

export const TemplateProjectCreate = props => {
  return (
    <StyledCreate transform={transformData}>
      <WizardForm
        defaultValues={{
          isActive: true,
          color: DEFAULT_PROJECT_COLOR,
          projectEnvVar: [],
          isInitProjectSkeleton: true
        }}
      >
        <WizardFormStep label='resources.projects.tabs.details'>
          <ProjectCreateStepDetails {...props} />
        </WizardFormStep>
        <WizardFormStep label='resources.projects.tabs.stack'>
          <ProjectCreateStepStack {...props} />
        </WizardFormStep>
        <WizardFormStep label='resources.projects.tabs.resources'>
          <ProjectCreateStepResources {...props} />
        </WizardFormStep>
        <WizardFormStep label='resources.projects.tabs.variables'>
          <ProjectCreateStepVariables {...props} />
        </WizardFormStep>
      </WizardForm>
    </StyledCreate>
  );
};

TemplateProjectCreate.defaultProps = {
  method: 'scratch'
};

// const PREFIX = 'ProjectCreate';

// const classes = {};

const StyledCreate = styled(Create)(({ theme }) => ({
  [`& .ra-input-isInitProjectSkeleton .MuiFormHelperText-root`]: {
    height: 0
  }
}));

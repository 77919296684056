import React from 'react';
import _ from 'lodash';
import { useAxios } from '@rc/utils/axios';
import { required, SelectInput, useTranslate } from 'react-admin';
import { LinearProgress } from '@mui/material';

export const ProviderChoice = props => {
  const t = useTranslate();
  const [{ data: clusters, loading }] = useAxios({
    url: `/api/clusters/get-with-provider`,
    method: 'GET'
  });

  if (loading) {
    return <LinearProgress />;
  }

  return (
    <SelectInput
      source='cluster'
      choices={_.map(clusters['hydra:member'], cluster => ({
        id: cluster['@id'],
        name: `${cluster?.provider?.name ||
          t('resources.products.message.no_provider')} | ${cluster?.name}`
      }))}
      validate={required()}
      {...props}
    />
  );
};

export default ProviderChoice;

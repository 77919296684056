import { chipClasses, linkClasses } from '@mui/material';
import React from 'react';
import {
  ChipField,
  ReferenceArrayField,
  ReferenceArrayInput,
  SelectArrayInput,
  SingleFieldList,
  useRedirect
} from 'react-admin';
import { useLocation } from 'react-router';

/**
 * @param {object} props
 * @param {string} props.source
 * @param {string} props.label
 */
export const EnvVarValidationReferenceArrayInput = props => {
  const { source, label } = props;
  const location = useLocation();
  const redirect = useRedirect();

  return (
    <>
      <ReferenceArrayInput source={source} reference='env_var_validations'>
        <SelectArrayInput
          label={label}
          fullWidth
          optionText='name'
          onCreate={() => {
            redirect('create', 'env_var_validations', undefined, undefined, {
              redirectTo: location.pathname
            });
          }}
        />
      </ReferenceArrayInput>
      <ReferenceArrayField
        source='envVarValidations'
        reference='env_var_validations'
      >
        <SingleFieldList
          sx={theme => ({
            flexDirection: 'column',
            rowGap: theme.spacing(0.5),
            [`& .${linkClasses.root}`]: {
              textDecoration: 'unset'
            }
          })}
        >
          <ChipField
            source='name'
            sx={theme => ({
              background: 'none !important',
              color: theme.palette.primary.main,
              height: 'auto',
              [`& .${chipClasses.label}`]: {
                padding: '0px !important',
                fontWeight: theme.typography.fontWeightMedium,
                '&:hover': {
                  textDecoration: 'underline'
                }
              }
            })}
          />
        </SingleFieldList>
      </ReferenceArrayField>
    </>
  );
};

export const currencies = [
  { id: 'usd', label: 'USD' },
  { id: 'aed', label: 'AED' },
  { id: 'all', label: 'ALL' },
  { id: 'amd', label: 'AMD' },
  { id: 'ang', label: 'ANG' },
  { id: 'aud', label: 'AUD' },
  { id: 'awg', label: 'AWG' },
  { id: 'azn', label: 'AZN' },
  { id: 'bam', label: 'BAM' },
  { id: 'bbd', label: 'BBD' },
  { id: 'bdt', label: 'BDT' },
  { id: 'bgn', label: 'BGN' },
  { id: 'bif', label: 'BIF' },
  { id: 'bmd', label: 'BMD' },
  { id: 'bnd', label: 'BND' },
  { id: 'bsd', label: 'BSD' },
  { id: 'bwp', label: 'BWP' },
  { id: 'byn', label: 'BYN' },
  { id: 'bzd', label: 'BZD' },
  { id: 'cad', label: 'CAD' },
  { id: 'cdf', label: 'CDF' },
  { id: 'chf', label: 'CHF' },
  { id: 'cny', label: 'CNY' },
  { id: 'czk', label: 'CZK' },
  { id: 'dkk', label: 'DKK' },
  { id: 'dop', label: 'DOP' },
  { id: 'dzd', label: 'DZD' },
  { id: 'egp', label: 'EGP' },
  { id: 'etb', label: 'ETB' },
  { id: 'eur', label: 'EUR' },
  { id: 'fjd', label: 'FJD' },
  { id: 'gbp', label: 'GBP' },
  { id: 'gel', label: 'GEL' },
  { id: 'gip', label: 'GIP' },
  { id: 'gmd', label: 'GMD' },
  { id: 'gyd', label: 'GYD' },
  { id: 'hkd', label: 'HKD' },
  { id: 'hrk', label: 'HRK' },
  { id: 'htg', label: 'HTG' },
  { id: 'huf', label: 'HUF' },
  { id: 'idr', label: 'IDR' },
  { id: 'ils', label: 'ILS' },
  { id: 'isk', label: 'ISK' },
  { id: 'jmd', label: 'JMD' },
  { id: 'jpy', label: 'JPY' },
  { id: 'kes', label: 'KES' },
  { id: 'kgs', label: 'KGS' },
  { id: 'khr', label: 'KHR' },
  { id: 'kmf', label: 'KMF' },
  { id: 'krw', label: 'KRW' },
  { id: 'kyd', label: 'KYD' },
  { id: 'kzt', label: 'KZT' },
  { id: 'lbp', label: 'LBP' },
  { id: 'lkr', label: 'LKR' },
  { id: 'lrd', label: 'LRD' },
  { id: 'lsl', label: 'LSL' },
  { id: 'mad', label: 'MAD' },
  { id: 'mdl', label: 'MDL' },
  { id: 'mga', label: 'MGA' },
  { id: 'mkd', label: 'MKD' },
  { id: 'mmk', label: 'MMK' },
  { id: 'mnt', label: 'MNT' },
  { id: 'mop', label: 'MOP' },
  { id: 'mro', label: 'MRO' },
  { id: 'mvr', label: 'MVR' },
  { id: 'mwk', label: 'MWK' },
  { id: 'mxn', label: 'MXN' },
  { id: 'myr', label: 'MYR' },
  { id: 'mzn', label: 'MZN' },
  { id: 'nad', label: 'NAD' },
  { id: 'ngn', label: 'NGN' },
  { id: 'nok', label: 'NOK' },
  { id: 'npr', label: 'NPR' },
  { id: 'nzd', label: 'NZD' },
  { id: 'pgk', label: 'PGK' },
  { id: 'php', label: 'PHP' },
  { id: 'pkr', label: 'PKR' },
  { id: 'pln', label: 'PLN' },
  { id: 'qar', label: 'QAR' },
  { id: 'ron', label: 'RON' },
  { id: 'rsd', label: 'RSD' },
  { id: 'rub', label: 'RUB' },
  { id: 'rwf', label: 'RWF' },
  { id: 'sar', label: 'SAR' },
  { id: 'sbd', label: 'SBD' },
  { id: 'scr', label: 'SCR' },
  { id: 'sek', label: 'SEK' },
  { id: 'sgd', label: 'SGD' },
  { id: 'sll', label: 'SLL' },
  { id: 'sos', label: 'SOS' },
  { id: 'szl', label: 'SZL' },
  { id: 'thb', label: 'THB' },
  { id: 'tjs', label: 'TJS' },
  { id: 'top', label: 'TOP' },
  { id: 'try', label: 'TRY' },
  { id: 'ttd', label: 'TTD' },
  { id: 'twd', label: 'TWD' },
  { id: 'tzs', label: 'TZS' },
  { id: 'uah', label: 'UAH' },
  { id: 'ugx', label: 'UGX' },
  { id: 'uzs', label: 'UZS' },
  { id: 'vnd', label: 'VND' },
  { id: 'vuv', label: 'VUV' },
  { id: 'wst', label: 'WST' },
  { id: 'xaf', label: 'XAF' },
  { id: 'xcd', label: 'XCD' },
  { id: 'yer', label: 'YER' },
  { id: 'zar', label: 'ZAR' },
  { id: 'zmw', label: 'ZMW' }
];

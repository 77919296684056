import React from 'react';
import { ImageField, ImageInput, Labeled } from 'react-admin';
import { useWatch } from 'react-hook-form';
import { Box } from '@mui/material';

/**
 *
 * @param {import('react-admin').ImageInputProps} props
 * @returns
 */
export const Base64UploadInput = props => {
  const value = useWatch({ name: props.source });

  return (
    <Box
      width={'100%'}
      sx={{
        '& img': {
          width: '200px',
          height: '100px',
          objectFit: 'contain'
        }
      }}
    >
      <ImageInput {...props}>
        <ImageField source='src' />
      </ImageInput>

      {typeof value === 'string' && <img src={value} alt='Project type logo' />}
    </Box>
  );
};

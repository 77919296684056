import React from 'react';
import { Login as RaLogin, LoginClasses } from 'react-admin';
import { Box, Stack, styled } from '@mui/material';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { GRECAPTCHA_SITEKEY } from '@rc/admin/constants';
import { ToggleThemeButton } from '@rc/admin/layout';
import { bgBlur } from '@minimal-theme/theme/css';
import Logo from '@rc/admin/theme/MinimalTheme/components/logo';
import { getColorfulBackgroundStyles } from '@rc/admin/theme/styles';
import DefaultLoginForm from './Form';
import { useSetDefaultTeam } from '@rc/admin/hooks';

const Login = props => {
  useSetDefaultTeam();

  return (
    <>
      <Box
        position={'absolute'}
        sx={theme => ({ top: theme.spacing(2), right: theme.spacing(2) })}
      >
        <ToggleThemeButton />
      </Box>
      <StyledRaLogin {...props} className={classes.root}>
        <GoogleReCaptchaProvider
          reCaptchaKey={GRECAPTCHA_SITEKEY}
          scriptProps={{
            async: true,
            defer: true
          }}
        >
          <DefaultLoginForm>
            <Stack alignItems='center' width={'100%'}>
              <Logo sx={{ width: 64 }} />
            </Stack>
          </DefaultLoginForm>
        </GoogleReCaptchaProvider>
      </StyledRaLogin>
    </>
  );
};

const PREFIX = 'Login';

const classes = {
  root: `${PREFIX}-root`
};

const StyledRaLogin = styled(RaLogin)(({ theme }) => ({
  paddingTop: '4.5em',
  paddingBottom: '3em',
  ...getColorfulBackgroundStyles(theme),

  [`& .${LoginClasses.card}`]: {
    padding: theme.spacing(1),
    maxWidth: 380,
    width: `calc(100% - ${theme.spacing(4)})`,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginTop: 0,
    ...bgBlur({
      color: theme.palette.background.paper,
      opacity: theme.palette.mode === 'light' ? 0.25 : 0.25
    })
  },
  [`& .${LoginClasses.avatar}`]: {
    display: 'none'
  }
}));

export default Login;

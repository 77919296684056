import { useMemo } from 'react';
import { useLocaleState } from 'react-admin';

export const getProjectPrices = environmentsData => {
  const environmentPrices = environmentsData.map(({ costs, environment }) => ({
    environment,
    ...getEnvironmentPrices(costs, environment?.cluster?.products)
  }));

  const aggregatedPrices = environmentPrices.reduce(
    (acc, environmentPrice) => {
      return {
        cpu: mergePrices(acc.cpu, environmentPrice.cpu),
        memory: mergePrices(acc.memory, environmentPrice.memory),
        storage: mergePrices(acc.storage, environmentPrice.storage)
      };
    },
    {
      cpu: EMPTY_PRICE,
      memory: EMPTY_PRICE,
      storage: EMPTY_PRICE
    }
  );

  return {
    environments: environmentPrices,
    ...aggregatedPrices,
    sum: mergePrices(
      aggregatedPrices.cpu,
      aggregatedPrices.memory,
      aggregatedPrices.storage
    )
  };
};

export const getEnvironmentPrices = (summary, products) => {
  const cpuPrice = getPrice(
    products?.find(product => product.name === 'cpu'),
    summary
  );
  const memoryPrice = getPrice(
    products?.find(product => product.name === 'memory'),
    summary,
    // Convert from Byte to Megabyte
    value => value / 1024 ** 2
  );
  const storagePrice = getPrice(
    products?.find(product => product.name === 'storage'),
    summary,
    // Convert from Byte to Gigabyte
    value => value / 1024 ** 3
  );

  return {
    cpu: cpuPrice,
    memory: memoryPrice,
    storage: storagePrice,
    sum: mergePrices(cpuPrice, memoryPrice, storagePrice)
  };
};

export const getPrice = (product, summary, format = v => v) => {
  if (!product) {
    return EMPTY_PRICE;
  }

  let usageField;
  switch (product.name) {
    case 'cpu':
      usageField = 'cpuCoreHours';
      break;
    case 'memory':
      usageField = 'ramByteHours';
      break;

    case 'storage':
      usageField = 'pvByteHours';
      break;
  }

  return {
    amount: product
      ? format(summary[usageField]) * product.defaultPrice?.amount
      : 0,
    currency: product?.defaultPrice?.currency,
    defaultPrice: product?.defaultPrice ? product.defaultPrice?.amount : NaN
  };
};

/**
 *
 * @param {object} props
 * @param {string} props.currency
 * @returns
 */
export const usePriceFormatter = currency => {
  const [locale] = useLocaleState();

  const formatter = useMemo(
    () =>
      new Intl.NumberFormat(
        locale,
        currency
          ? {
              style: 'currency',
              currency: currency?.toUpperCase()
            }
          : undefined
      ),
    [currency, locale]
  );

  return formatter;
};

const EMPTY_PRICE = {
  amount: 0,
  currency: '',
  defaultPrice: 0
};

export const mergePrices = (...args) => {
  return args.reduce(
    (acc, price) => {
      return {
        amount: acc.amount + price.amount,
        currency: acc.currency || price.currency
      };
    },
    { amount: 0, currency: '' }
  );
};

export const multiplyPrice = (amount, price) => {
  if (!price) {
    return EMPTY_PRICE;
  }

  return {
    amount: amount * price.amount,
    currency: price.currency,
    defaultPrice: price
  };
};

import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  IconButton,
  Stack,
  Typography,
  styled
} from '@mui/material';
import React, { useMemo } from 'react';
import { MESSENGER_ACTION_AMQP_TYPES } from '@rc/admin/constants';
import { useTranslate } from 'react-admin';
import { toCamelCase } from '@rc/admin/utils';
import { Close } from '@mui/icons-material';
import { useEnvironmentProgressBar } from '@rc/admin/hooks';
import { EnvironmentProgressBarStepper } from './Stepper';

/**
 *
 * @param {object} props
 * @param {import('@rc/admin/types/progressbar.types').ProgressBar} props.progressBarInfo
 * @param {number} props.width
 * @param {boolean} props.isRefreshed
 * @param {function} props.onClose
 * @param {React.ReactNode} props.children
 * @returns
 */
export const EnvironmentStatusProgressBar = React.forwardRef((props, ref) => {
  const { progressBarInfo, onClose, isRefreshed, width, children, ...rest } =
    props;
  const t = useTranslate();

  const { ongoingProgresses: rawOngoingProgresses } =
    useEnvironmentProgressBar(progressBarInfo);

  const ongoingProgresses = useMemo(
    () =>
      rawOngoingProgresses.sort((a, b) => {
        if (!a.done && b.done) {
          return -1;
        } else if (a.done && !b.done) {
          return 1;
        } else if (!a.done && !b.done) {
          if (a.actionType === MESSENGER_ACTION_AMQP_TYPES.CREATE) {
            return -1;
          } else if (b.actionType === MESSENGER_ACTION_AMQP_TYPES.CREATE) {
            return 1;
          } else if (a.actionType === MESSENGER_ACTION_AMQP_TYPES.UPDATE) {
            return -1;
          } else if (b.actionType === MESSENGER_ACTION_AMQP_TYPES.UPDATE) {
            return 1;
          }
        }

        return 0;
      }),
    [rawOngoingProgresses]
  );

  if (!ongoingProgresses?.length) {
    return null;
  }

  return (
    <StyledCard
      ref={ref}
      className={ProgressBarClasses.root}
      elevation={3}
      isRefreshed={isRefreshed}
      width={width}
      {...rest}
    >
      <CardContent className={ProgressBarClasses.cardContent}>
        <Stack className={ProgressBarClasses.header}>
          {children}
          <IconButton size='small' onClick={onClose}>
            <Close />
          </IconButton>
        </Stack>
        <Stack className={ProgressBarClasses.content}>
          {ongoingProgresses?.map(({ actionType, uuid, ...details }) => {
            const visibleProgresses = details.progressList.slice(
              details.itemProcessed - 2,
              Math.min(details.itemProcessed + 1, details.processCount - 1)
            );

            return (
              <Stack key={`progress-${actionType}-${uuid}`}>
                <Stack className={ProgressBarClasses.progressHeader}>
                  <Typography variant='subtitle2'>
                    {t(`amqp.${toCamelCase(actionType)}`)}
                    {details.hasMultipleOfType && ` #${details.index + 1}`}
                  </Typography>
                  <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                    <CircularProgress
                      variant='determinate'
                      value={
                        (details.itemProcessed / details.processCount) * 100
                      }
                      size={40}
                    />
                    <Box
                      sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: 'absolute',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                    >
                      <Typography
                        variant='caption'
                        component='div'
                        color='text.secondary'
                      >
                        {t(
                          'components.environmentProgressBar.messages.steps_left_short',
                          {
                            step: details.itemProcessed,
                            smart_count: details.processCount
                          }
                        )}
                      </Typography>
                    </Box>
                  </Box>
                </Stack>
                <EnvironmentProgressBarStepper
                  details={details}
                  visibleProgresses={visibleProgresses}
                />
              </Stack>
            );
          })}
        </Stack>
      </CardContent>
    </StyledCard>
  );
});

const PREFIX = 'EnvironmentStatusProgressBar';

const ProgressBarClasses = {
  root: `${PREFIX}-root`,
  cardContent: `${PREFIX}-cardContent`,
  header: `${PREFIX}-header`,
  content: `${PREFIX}-content`,
  progressHeader: `${PREFIX}-progressHeader`
};

const StyledCard = styled(Card, {
  shouldForwardProp: prop => prop !== 'width'
})(({ theme, width, isRefreshed }) => ({
  minWidth: width,
  boxShadow: isRefreshed ? theme.shadows[6] : theme.shadows[2],
  transition: 'box-shadow 0.3s ease-in-out',

  [`& .${ProgressBarClasses.cardContent}`]: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: `${theme.spacing(1)} ${theme.spacing(0)}`
  },

  [`& .${ProgressBarClasses.header}`]: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: `0 0 ${theme.spacing(1.5)}`,
    padding: `0 ${theme.spacing(1)} ${theme.spacing(0.75)} ${theme.spacing(2)}`,
    borderBottom: `1px solid ${theme.palette.divider}`
  },

  [`& .${ProgressBarClasses.content}`]: {
    padding: `0 ${theme.spacing(1)} ${theme.spacing(0.75)} ${theme.spacing(2)}`,
    overflowY: 'auto',
    maxHeight: 340,

    [theme.breakpoints.up('sm')]: {
      maxHeight: 490
    }
  },

  [`& .${ProgressBarClasses.progressHeader}`]: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(0.75)
  }
}));

import { useGetOne } from 'react-admin';

/**
 * Returns team data and organisation data.
 * @param {object} props
 * @param {string?} props.teamId Team string id. If not provided, currently selected teamId will be used.
 * @returns
 */
export const useTeam = props => {
  const { teamId } = props;

  const {
    data: team,
    isLoading: isTeamLoading,
    isError: isTeamError
  } = useGetOne('teams', { id: teamId }, { enabled: !!teamId });

  const organisationId = team?.organisation;

  const {
    data: organisation,
    isLoading: isOrganisationLoading,
    isError: isOrganisationError
  } = useGetOne(
    'organisations',
    { id: organisationId },
    { enabled: !!organisationId }
  );

  const isLoading = isTeamLoading || isOrganisationLoading;
  const isError = isTeamError || isOrganisationError;

  return {
    team,
    organisation,
    isLoading,
    isError
  };
};

import '@stylesheet/admin.scss';
import '@stylesheet/lato-fonts.scss';

import React from 'react';
import * as ReactDOM from 'react-dom/client';
import { Route } from 'react-router-dom';
import { CustomRoutes, Resource } from 'react-admin';
import { OpenApiAdmin, openApiSchemaAnalyzer } from '@api-platform/admin';
import AuthProvider from '@rc/admin/utils/providers/auth-provider';
import getDataProvider from '@rc/admin/utils/providers/data-provider';
import i18nProvider from '@rc/admin/utils/providers/i18n-provider';
import { Layout, Notification } from '@rc/admin/layout';
import {
  accessCodes,
  clusters,
  componentVersionEnvVarExamples,
  componentVersionEnvVars,
  componentVersionResourceLimits,
  componentVersions,
  components,
  credentialTypes,
  dataTransferDataTypes,
  envVarTypes,
  envVarValidations,
  environmentComponents,
  environmentCustomDomains,
  environmentEnvVars,
  environments,
  exportedDatas,
  gits,
  gitTypes,
  importedDatas,
  invitations,
  organisations,
  product,
  projectEnvVars,
  projectTypeVersionEnvVarExamples,
  projectTypeVersionEnvVars,
  projectTypeVersions,
  projectTypes,
  projects,
  providers,
  resourceTypes,
  roleGroups,
  states,
  teams,
  templateEnvironment,
  templateProject,
  users
} from '@rc/admin/resources';
import {
  DashboardPage,
  ForgotPasswordPage,
  LoadingPage,
  LoginPage,
  ProfilePage,
  RegisterPage,
  ResetPasswordPage
} from '@rc/admin/routes';
import { darkTheme, lightTheme } from '@rc/admin/theme';
import '@rc/admin/utils/time-ago';
import sentry from './sentry';

sentry.init();

getDataProvider().then(ExtendedDataProvider =>
  ReactDOM.createRoot(document.getElementById('api-platform-admin')).render(
    <OpenApiAdmin
      entrypoint={document.getElementById('api-entrypoint').innerText}
      dataProvider={ExtendedDataProvider}
      schemaAnalyzer={openApiSchemaAnalyzer()}
      i18nProvider={i18nProvider}
      authProvider={AuthProvider}
      loginPage={LoginPage}
      theme={lightTheme}
      lightTheme={lightTheme}
      darkTheme={darkTheme}
      layout={Layout}
      disableTelemetry
      notification={Notification}
      dashboard={DashboardPage}
      loading={LoadingPage}
    >
      <Resource {...accessCodes} />
      <Resource {...clusters} />
      <Resource {...componentVersionEnvVarExamples} />
      <Resource {...componentVersionEnvVars} />
      <Resource {...componentVersionResourceLimits} />
      <Resource {...componentVersions} />
      <Resource {...components} />
      <Resource {...credentialTypes} />
      <Resource {...dataTransferDataTypes} />
      <Resource {...envVarTypes} />
      <Resource {...envVarValidations} />
      <Resource {...environmentComponents} />
      <Resource {...environmentCustomDomains} />
      <Resource {...environmentEnvVars} />
      <Resource {...environments} />
      <Resource {...exportedDatas} />
      <Resource {...gits} />
      <Resource {...gitTypes} />
      <Resource {...importedDatas} />
      <Resource {...invitations} />
      <Resource {...organisations} />
      <Resource {...product} />
      <Resource {...projectEnvVars} />
      <Resource {...projectTypeVersionEnvVarExamples} />
      <Resource {...projectTypeVersionEnvVars} />
      <Resource {...projectTypeVersions} />
      <Resource {...projectTypes} />
      <Resource {...projects} />
      <Resource {...providers} />
      <Resource {...resourceTypes} />
      <Resource {...roleGroups} />
      <Resource {...states} />
      <Resource {...teams} />
      <Resource {...templateEnvironment} />
      <Resource {...templateProject} />
      <Resource {...users} />
      <CustomRoutes>
        <Route path='/account/*' element={<ProfilePage />} />
      </CustomRoutes>
      <CustomRoutes noLayout>
        <Route exact path='/register' element={<RegisterPage />} />
        <Route exact path='/forgot-password' element={<ForgotPasswordPage />} />
        <Route exact path='/reset-password' element={<ResetPasswordPage />} />
      </CustomRoutes>
    </OpenApiAdmin>
  )
);

import React from 'react';
import {
  BooleanField,
  Datagrid,
  List,
  SimpleList,
  TextField
} from 'react-admin';
import { EditButton } from '@rc/admin/ra';
import { useMediaQuery } from '@mui/material';

export const ProviderList = () => {
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

  return (
    <List perPage={25} exporter={false}>
      {isSmall ? (
        <SimpleList
          primaryText={() => <TextField source='name' />}
          secondaryText={() => <BooleanField source={'isDefault'} />}
        />
      ) : (
        <Datagrid width={'100vw'}>
          <TextField source='name' />
          <BooleanField source={'isDefault'} />
          <EditButton />
        </Datagrid>
      )}
    </List>
  );
};

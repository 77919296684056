import React, { useMemo } from 'react';
import { useLocation } from 'react-router';
import {
  Create,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
  PasswordInput,
  BooleanField
} from 'react-admin';
import { styled } from '@mui/material';
import { TwoColumnFormStyles } from '@rc/admin/theme/styles';
import { SectionTitle } from '@rc/admin/components';

export const ClusterCreate = () => {
  const { search } = useLocation();

  const providerIdFromSearch = useMemo(
    () => new URLSearchParams(search).get('provider'),
    [search]
  );

  return (
    <StyledCreate>
      <SimpleForm
        defaultValues={{
          provider: providerIdFromSearch,
          changeKubecostPass: null
        }}
        className={classes.form}
      >
        <ReferenceInput source={'provider'} reference={'providers'}>
          <SelectInput optionText={'name'} fullWidth validate={[required()]} />
        </ReferenceInput>
        <BooleanField source={'isDefault'} />
        <TextInput
          source={'name'}
          label='resources.clusters.fields.name'
          fullWidth
        />
        <TextInput
          source={'codeName'}
          label='resources.clusters.fields.codeName'
          fullWidth
        />
        <SectionTitle title={'resources.clusters.sections.kubecost'} />
        <TextInput
          source={'kubecostUrl'}
          label={'resources.clusters.fields.kubecostUrl'}
          validate={[required()]}
          fullWidth
        />
        <TextInput
          source={'kubecostUser'}
          label={'resources.clusters.fields.kubecostUser'}
          validate={[required()]}
          fullWidth
        />
        <PasswordInput
          source={'changeKubecostPass'}
          label={'resources.clusters.fields.changeKubecostPass'}
          fullWidth
        />
        <SectionTitle title={'resources.clusters.sections.cluster'} />
        <TextInput
          source={'clusterServer'}
          label='resources.clusters.fields.clusterServer'
          fullWidth
        />
        <TextInput
          source={'clusterCertificateAuthorityData'}
          label='resources.clusters.fields.clusterCertificateAuthorityData'
          fullWidth
        />
        <TextInput
          source={'oidcIssuerUrl'}
          label='resources.clusters.fields.oidcIssuerUrl'
          fullWidth
        />
        <TextInput
          source={'oidcClientID'}
          label='resources.clusters.fields.oidcClientID'
          fullWidth
        />
        <TextInput
          source={'oidcClientSecret'}
          label='resources.clusters.fields.oidcClientSecret'
          fullWidth
        />
      </SimpleForm>
    </StyledCreate>
  );
};

const PREFIX = 'ClusterCreate';

const classes = {
  form: `${PREFIX}-form`
};

const StyledCreate = styled(Create)(({ theme }) => ({
  [`& .${classes.form}`]: {
    ...TwoColumnFormStyles(theme)
  }
}));

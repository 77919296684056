import React from 'react';
import { useTranslate } from 'react-admin';
import { Button, CircularProgress, styled } from '@mui/material';
import { useBuildAndDeploy } from '../hooks';

/**
 *
 * @typedef {object} BuildAndDeployButtonProps
 * @property {import('react-admin').RaRecord} record
 *
 * @param {import('react-admin').ButtonProps & BuildAndDeployButtonProps} props
 * @returns
 */
export const BuildAndDeployButton = props => {
  const {
    record,
    variant,
    component: Component = DefaultComponent,
    ...rest
  } = props;
  const t = useTranslate();
  const { execute: buildAndDeploy, isLoading, isEnabled } = useBuildAndDeploy(
    record
  );
  const disabled = !isEnabled || isLoading;

  return (
    <Component
      onClick={e => (isLoading ? e.preventDefault() : buildAndDeploy())}
      disabled={disabled}
      {...rest}
    >
      {isLoading ? (
        <CircularProgress size={18} thickness={2} style={{ marginRight: 4 }} />
      ) : null}
      {t('resources.environments.action.build_and_deploy')}
    </Component>
  );
};

const DefaultComponent = props => {
  const { isLoading, ...rest } = props;

  return (
    <StyledButton
      {...props}
      type={'button'}
      variant={'contained'}
      color={'primary'}
      {...rest}
    />
  );
};

const StyledButton = styled(Button)(({ theme }) => ({}));

import React, { useMemo } from 'react';
import { useLocation } from 'react-router';
import {
  Create,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  ReferenceField,
  TextField,
  BooleanInput
} from 'react-admin';
import { styled } from '@mui/material';
import { OneColumnFormStyles } from '@rc/admin/theme/styles';

export const ProjectTypeVersionEnvVarCreate = () => {
  const { search } = useLocation();

  const initialProjectTypeVersionId = useMemo(
    () => new URLSearchParams(search).get('projectTypeVersion'),
    [search]
  );

  return (
    <StyledCreate>
      <SimpleForm
        defaultValues={{
          isEnabled: true,
          projectTypeVersion: initialProjectTypeVersionId
        }}
        className={classes.form}
      >
        {!initialProjectTypeVersionId ? (
          <ReferenceInput
            source={'projectTypeVersion'}
            reference={'project_type_versions'}
          >
            <SelectInput optionText={'version'} fullWidth />
          </ReferenceInput>
        ) : (
          <ReferenceField
            source={'projectTypeVersion'}
            reference={'project_type_versions'}
            record={{ projectTypeVersion: initialProjectTypeVersionId }}
          >
            <TextField source={'version'} fullWidth />
          </ReferenceField>
        )}
        <TextInput source={'key'} fullWidth />
        <TextInput source={'note'} fullWidth />
        <BooleanInput source={'isRequired'} fullWidth />
        <BooleanInput source={'isEncrypted'} fullWidth />
      </SimpleForm>
    </StyledCreate>
  );
};

const PREFIX = 'ProjectTypeVersionEnvVarCreate';

const classes = {
  form: `${PREFIX}-form`
};

const StyledCreate = styled(Create)(({ theme }) => ({
  [`& .${classes.form}`]: {
    ...OneColumnFormStyles(theme)
  }
}));

import React from 'react';
import { IconButton } from '@mui/material';
import { CloseOutlined } from '@mui/icons-material';

export const TourClose = props => {
  const { onClick, disabled } = props;

  return (
    <IconButton
      onClick={onClick}
      disabled={disabled}
      size='small'
      sx={theme => ({
        position: 'absolute',
        top: theme.spacing(0.125),
        right: theme.spacing(0.125)
      })}
    >
      <CloseOutlined fontSize={'small'} />
    </IconButton>
  );
};

import React, { useState } from 'react';
import {
  Datagrid,
  DateField,
  Pagination,
  ResourceContextProvider,
  useGetList,
  useGetResourceLabel,
  useLocaleState,
  useRecordContext,
  ReferenceField,
  FunctionField,
  useResourceContext,
  useTranslate
} from 'react-admin';
import { Box, Typography } from '@mui/material';
import { AllInbox } from '@mui/icons-material';
import { DownloadButton } from './DownloadButton';
import { useEnvironmentProgressBar } from '@rc/admin/hooks';
import { MESSENGER_ACTION_AMQP_TYPES } from '@rc/admin/constants';

const ROWS_PER_PAGE_OPTIONS = [5, 10, 25];

export const DataExportList = () => {
  const record = useRecordContext();
  const [locale] = useLocaleState();
  const sort = { field: 'createdAt', order: 'DESC' };
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(ROWS_PER_PAGE_OPTIONS[0]);
  const { data, isLoading, total } = useGetList(
    'exported_datas',
    {
      pagination: { page, perPage },
      filter: { environment: record?.['@id'] },
      sort
    },
    {
      enabled: !!record
    }
  );

  const { ongoingProgresses } = useEnvironmentProgressBar();

  if (!record) {
    return null;
  }

  return (
    <ResourceContextProvider value='exported_datas'>
      <Datagrid
        bulkActionButtons={null}
        empty={<Empty />}
        isLoading={isLoading}
        data={data || []}
        sort={sort}
        total={total}
        sx={{ width: '100%' }}
      >
        <TypeField />
        <DateField
          source={'createdAt'}
          label={'resources.exported_datas.fields.createdAt'}
          locales={locale}
          showTime
        />
        <FunctionField
          render={exportRecord => (
            <DownloadButton
              progress={ongoingProgresses.find(
                progress =>
                  (progress.actionType ===
                    MESSENGER_ACTION_AMQP_TYPES.EXPORT_DB ||
                    progress.actionType ===
                      MESSENGER_ACTION_AMQP_TYPES.EXPORT_MEDIA) &&
                  progress.uuid === exportRecord.uuid
              )}
              sx={{ float: 'right' }}
            />
          )}
        />
      </Datagrid>
      {total > ROWS_PER_PAGE_OPTIONS[0] && (
        <Pagination
          total={total}
          page={page}
          setPage={setPage}
          perPage={perPage}
          setPerPage={setPerPage}
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          isLoading={isLoading}
          sx={{ width: '100%' }}
        />
      )}
    </ResourceContextProvider>
  );
};

const TypeField = () => {
  const dataExport = useRecordContext();
  const t = useTranslate();

  return (
    <ReferenceField
      label={'Type'}
      reference='data_transfer_data_types'
      source='dataTransferDataType'
    >
      <FunctionField
        render={record =>
          `${record?.name}${
            dataExport.isStripDatabase
              ? ` (${t('resources.exported_datas.without_sensitive_data')})`
              : ''
          }`
        }
      />
    </ReferenceField>
  );
};

const Empty = () => {
  const resource = useResourceContext();
  const getResourceLabel = useGetResourceLabel();
  const t = useTranslate();

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '100%',
        textAlign: 'center'
      }}
    >
      <AllInbox fontSize={'large'} />
      <Typography>
        {t('ra.page.empty', { name: getResourceLabel(resource, 2) })}
      </Typography>
    </Box>
  );
};

export default DataExportList;

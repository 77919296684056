import React, { useEffect } from 'react';
import { useNotify } from 'react-admin';
import { Navigate } from 'react-router-dom';

/**
 *
 * @param {import('react-router').NavigateProps & {options: import('react-admin').NotificationOptions} & {message: string}} props
 * @returns
 */
export const RedirectWithNotification = props => {
  const { to, replace, state, message, options } = props;
  const notify = useNotify();

  useEffect(() => {
    notify(message, options);
  }, [message, notify, options]);

  return <Navigate to={to} replace={replace} state={state} />;
};

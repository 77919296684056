import React, { useState } from 'react';
import axios from '@rc/utils/axios';
import { useNotify, useRecordContext, useTranslate } from 'react-admin';
import { LoadingButton } from '@mui/lab';
import { AttachMoney } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Dialog,
  DialogTitle,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText
} from '@mui/material';
import { blue } from '@mui/material/colors';
import { CardBrand } from '../../components';
import NoPaymentMethod from '../../components/NoPaymentMethod';
import _ from 'lodash';

export const PayButton = props => {
  const record = useRecordContext();
  const notify = useNotify();
  const t = useTranslate();
  const { methods } = props;
  const { id: userId } = JSON.parse(window.localStorage.getItem('user'));
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  if ('open' !== record.status) {
    return null;
  }

  const pay = methodId => {
    setIsLoading(true);

    axios({
      url: `/api/users/${userId}/finance/pay`,
      method: 'PATCH',
      params: { invoiceId: record.id, paymentMethodId: methodId, id: userId },
      headers: {
        Accept: 'application/merge-patch+json',
        'Content-Type': 'application/merge-patch+json'
      },
      data: {}
    }).then(r => {
      props.setList(r.data);
      setIsOpen(false);
      setIsLoading(false);
      notify(t('pages.billing.requests.notification.successful_payment'), {
        type: 'success'
      });
    });
  };

  return (
    <>
      <LoadingButton
        variant={'contained'}
        startIcon={<AttachMoney />}
        onClick={() => setIsOpen(true)}
        size={'small'}
        disabled={'open' !== record.status}
        loading={isLoading}
      >
        {t('pages.billing.requests.action.pay') + '!'}
      </LoadingButton>
      <Dialog
        onClose={() => !isLoading && setIsOpen(false)}
        open={isOpen}
        maxWidth={'md'}
        fullWidth
      >
        {!_.isEmpty(methods) && (
          <DialogTitle>{t('pages.billing.requests.dialog_title')}</DialogTitle>
        )}
        <List sx={{ pt: 0 }}>
          {_.isEmpty(methods) && <NoPaymentMethod goTo={props.goTo} />}
          {methods.map(method => (
            <ListItemButton
              disabled={isLoading}
              onClick={() => pay(method.id)}
              key={method.id}
            >
              <ListItemAvatar>
                <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                  <CardBrand cardBrand={method.card.brand} />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Box
                    sx={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <Box>**** **** **** {method.card.last4}</Box>
                    <Box
                      dangerouslySetInnerHTML={{
                        __html: `${method.card.exp_month}/${method.card.exp_year}`
                      }}
                    />
                  </Box>
                }
              />
            </ListItemButton>
          ))}
        </List>
      </Dialog>
    </>
  );
};

export default PayButton;

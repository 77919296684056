import { ClusterCreate } from './Create';
import { ClusterEdit } from './Edit';

export default {
  name: 'clusters',
  create: ClusterCreate,
  edit: ClusterEdit,
  options: {
    showInMenu: false
  }
};

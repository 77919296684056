import React from 'react';
import {
  Edit,
  NumberInput,
  SimpleForm,
  TextInput,
  useRecordContext
} from 'react-admin';
import {
  Breadcrumbs,
  CpuInputConfig,
  EditTitle,
  MemoryInputConfig,
  NodeInputConfig,
  StorageInputConfig
} from '@rc/admin/components';
import { TwoColumnFormStyles } from '@rc/admin/theme/styles';
import { Grid, Typography, styled } from '@mui/material';
import { ENABLE_NODE_RESOURCE_VALUES } from '@rc/admin/constants';

export const ResourceTypeEdit = () => {
  return (
    <StyledEdit
      title={<EditTitle />}
      redirect={false}
      actions={
        <>
          <Breadcrumbs />
          <NodesEnabledMessage />
        </>
      }
    >
      <SimpleForm className={classes.form}>
        <TextInput source={'name'} fullWidth disabled />
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <ValueInput
              source={'resourceTypeLimit.projectMinValue'}
              label={
                'resources.resource_types.fields.resourceTypeLimit.projectMinValue'
              }
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ValueInput
              source={'resourceTypeLimit.projectMaxValue'}
              label={
                'resources.resource_types.fields.resourceTypeLimit.projectMaxValue'
              }
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <ValueInput
              source={'resourceTypeLimit.environmentMinValue'}
              label={
                'resources.resource_types.fields.resourceTypeLimit.environmentMinValue'
              }
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ValueInput
              source={'resourceTypeLimit.environmentMaxValue'}
              label={
                'resources.resource_types.fields.resourceTypeLimit.environmentMaxValue'
              }
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <ValueInput
              source={'resourceTypeLimit.componentMinValue'}
              label={
                'resources.resource_types.fields.resourceTypeLimit.componentMinValue'
              }
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ValueInput
              source={'resourceTypeLimit.componentMaxValue'}
              label={
                'resources.resource_types.fields.resourceTypeLimit.componentMaxValue'
              }
              fullWidth
            />
          </Grid>
        </Grid>
      </SimpleForm>
    </StyledEdit>
  );
};

const ValueInput = props => {
  const record = useRecordContext();

  let config;
  switch (record?.name) {
    case 'cpu':
      config = CpuInputConfig;
      break;
    case 'memory':
      config = MemoryInputConfig;
      break;
    case 'storage':
      config = StorageInputConfig;
      break;
    case 'node':
      config = NodeInputConfig;
      break;
    default:
      break;
  }

  if (
    !config ||
    // Hide Project and Environment limits on "node" resource type
    (record?.name === 'node' && /(project|environment)/.test(props.source))
  ) {
    return null;
  }

  return (
    <NumberInput
      {...props}
      InputProps={{
        endAdornment: config.unit ? (
          <span className={classes.inputEndAdornment}>{config.unit}</span>
        ) : undefined
      }}
      parse={config.parse}
      format={config.format}
    />
  );
};

const NodesEnabledMessage = () => {
  const record = useRecordContext();

  if (record?.name === 'node' && !ENABLE_NODE_RESOURCE_VALUES) {
    return (
      <Typography variant='body1' my={3} fontWeight={500}>
        <span
          dangerouslySetInnerHTML={{
            __html: `Changing the ${record?.name} resource on components is disabled. Enable it in <code>/assets/js/admin/constants.js</code>.`
          }}
        ></span>
      </Typography>
    );
  }

  return null;
};

const PREFIX = 'ResourceTypeEdit';

const classes = {
  form: `${PREFIX}-form`,
  inputEndAdornment: `${PREFIX}-inputEndAdornment`
};

const StyledEdit = styled(Edit)(({ theme }) => ({
  [`& .${classes.form}`]: {
    ...TwoColumnFormStyles(theme),
    width: 'auto'
  },

  [`& .${classes.inputEndAdornment}`]: {
    marginLeft: theme.spacing(1.5)
  }
}));

import React, { useCallback } from 'react';
import { SelectInput, useRecordContext } from 'react-admin';
import { isNumber } from 'lodash';
import { ResourceInput } from '@rc/admin/components';
import { getSourceUtil } from '@rc/admin/utils/form';
import { useFormContext, useWatch } from 'react-hook-form';
import {
  useProjectResourceLimit,
  useResourceTypes
} from '@rc/admin/hooks/domain';
import { useProjectContext } from '@rc/admin/context';
import { ENABLE_ENVIRONMENT_LEVEL_RESOURCE_LIMITS } from '@rc/admin/constants';
import { useComponentResourceLimits } from '../../hooks';
import { EnvironmentStorageResourceInput } from './EnvironmentStorageResourceInput';

export const EnvironmentComponentResourceInput = props => {
  const {
    source,
    min: minProp,
    max: maxProp,
    choices: choicesProp,
    canReduceOnEdit = true,
    format,
    parse,
    ...rest
  } = props;
  const record = useRecordContext();
  const { getValues } = useFormContext();
  const { member, index, item: resourceTypeName } = getSourceUtil(source);

  const { getResourceType, isLoading: isResourceTypesLoading } =
    useResourceTypes();
  const resourceType = getResourceType(resourceTypeName);

  const { componentMinValue, componentMaxValue } =
    resourceType?.resourceTypeLimit || {};

  const min = isNumber(componentMinValue) ? componentMinValue : minProp;
  const max = isNumber(componentMaxValue) ? componentMaxValue : maxProp;

  const {
    isLoading: isLimitsLoading,
    // limit,
    // used,
    free,
    resourceMin,
    resourceMax
  } = useLimits({
    source
  });

  const isLoading = isResourceTypesLoading || isLimitsLoading;

  const validate = useCallback(
    value => {
      const values = getValues();
      const fieldValue = values[member][index][resourceTypeName];

      if (!canReduceOnEdit) {
        if (fieldValue && value < fieldValue) {
          return {
            type: 'validate',
            message: 'validation.missing_required_option',
            args: {
              resource: resourceTypeName
            }
          };
        }
      }
    },
    [canReduceOnEdit, getValues, index, member, resourceTypeName]
  );

  if (resourceTypeName === 'node') {
    const choices = choicesProp.filter(choice => {
      return (
        (!isNumber(resourceMin) || choice.value >= resourceMin) &&
        (!isNumber(resourceMax) || choice.value <= resourceMax) &&
        (!isNumber(componentMinValue) || choice.value >= componentMinValue) &&
        (!isNumber(componentMaxValue) || choice.value <= componentMaxValue)
      );
    });

    return (
      <SelectInput
        {...rest}
        source={source}
        isLoading={isLoading}
        choices={choices}
        defaultValue={choices[0]?.value || null}
        format={format}
        parse={parse}
      />
    );
  } else {
    const isStorageInput = resourceTypeName === 'storage';
    const originalStorageValue =
      isStorageInput && getOriginalValue(record, source);

    const lowerLimit = Math.max(
      Math.min(resourceMin !== undefined ? resourceMin : min),
      isStorageInput && originalStorageValue !== undefined
        ? originalStorageValue
        : Number.NEGATIVE_INFINITY
    );
    const upperLimit = Math.max(
      0,
      Math.min(
        free,
        resourceMax !== undefined ? resourceMax : max,
        free !== undefined ? free : Number.POSITIVE_INFINITY
      )
    );

    // const disabled = limit === used || lowerLimit > upperLimit;

    const ResourceInputComponent = isStorageInput
      ? EnvironmentStorageResourceInput
      : ResourceInput;

    return (
      <ResourceInputComponent
        {...rest}
        source={source}
        min={min}
        max={max}
        resourceMin={resourceMin}
        resourceMax={resourceMax}
        lowerLimit={lowerLimit}
        upperLimit={upperLimit}
        defaultValue={lowerLimit}
        format={format}
        parse={parse}
        // disabled={disabled}
        isLoading={isLoading}
        enableReset={true}
        validate={validate}
      />
    );
  }
};

export const useLimits = props => {
  const { source } = props;

  const { project, isLoading: isProjectLoading } = useProjectContext();

  const {
    member: memberSource,
    index,
    item: resourceTypeName
  } = getSourceUtil(source);

  const [
    environmentId,
    environmentResourceLimit,
    environmentComponents,
    componentVersion
  ] = useWatch({
    name: [
      'id',
      resourceTypeName,
      memberSource,
      `${memberSource}[${index}].componentVersion`
    ]
  });

  const {
    componentResourceLimits,
    isLoading: isComponentResourceLimitsLoading
  } = useComponentResourceLimits({
    projectTypeVersion: project?.projectTypeVersion,
    componentVersion
  });

  const { getResourceType, isLoading: isResourceTypesLoading } =
    useResourceTypes();

  const isLoading =
    isProjectLoading ||
    isComponentResourceLimitsLoading ||
    isResourceTypesLoading;

  const resourceType = getResourceType(resourceTypeName);

  const componentResourceLimit = componentResourceLimits?.find(
    resourceLimit =>
      resourceLimit.componentVersion ===
        environmentComponents[index]?.componentVersion &&
      resourceLimit.resourceType === resourceType?.id
  );

  const { limit: projectResourceLimit } = useProjectResourceLimit({
    source: resourceTypeName,
    id: environmentId,
    project
  });

  // Node resourceType has no limits on environment level
  const limit =
    resourceTypeName !== 'node'
      ? ENABLE_ENVIRONMENT_LEVEL_RESOURCE_LIMITS
        ? environmentResourceLimit
        : projectResourceLimit
      : 0;

  const used =
    resourceTypeName !== 'node'
      ? environmentComponents
          .filter(
            ({ componentVersion }) =>
              componentVersion !==
              environmentComponents[index]?.componentVersion
          )
          .reduce(
            (acc, { [resourceTypeName]: resource }) => (acc += resource || 0),
            0
          )
      : 0;

  const free = limit - used;

  return {
    isLoading,
    limit,
    used,
    free,
    resourceMin: componentResourceLimit?.minValue,
    resourceMax: componentResourceLimit?.maxValue
  };
};

function getOriginalValue (record, source) {
  if (!record) return;

  try {
    return source.split('.').reduce((acc, curr) => acc[curr], record);
  } catch (e) {
    return undefined;
  }
}

import {
  EventStreamContentType,
  fetchEventSource,
} from '@microsoft/fetch-event-source';
import { LOGS_PAGE_SIZE } from '@rc/admin/constants';
import { useAxios } from '@rc/utils/axios';
import { useCallback, useEffect, useRef, useState } from 'react';

/**
 *
 * @typedef {{
 *  timestamp: string;
 *  message: string;
 *  source: string;
 *  raw: string;
 * }} Log
 * 
 * @returns {
 * logs: {}[],
 * isPaging: boolean,
 * isLoading: boolean,
 * isRunning: boolean,
 * canShowMore: boolean,
 * start: (record: import('react-admin').Record, filters: object) => void,
 * stop: () => void,
 * more: (record: import('react-admin').Record, filters: object) => void
 * }
 */
export const useLogs = () => {
  const abortControllerRef = useRef(null);
  const consumerGroupId = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isRunning, setIsRunning] = useState(false);
  const [isPaging, setIsPaging] = useState(false);
  const [logs, setLogs] = useState([]);

  const [, getTopic] = useAxios(
    {},
    {
      manual: true,
    }
  );

  const subscribe = useCallback(
    async (record, filters, offset) => {
      const isUserPaging = offset === -1 && consumerGroupId.current;
      setIsPaging(isUserPaging);

      if (!isUserPaging) {
        setLogs([]);
        setIsLoading(true);
      }

      /**
       * @type {import('axios').AxiosRequestHeaders}
       */
      const headers = {
        'x-filters': JSON.stringify({
          filters,
        }),
        'x-offset': offset,
        'x-page-size': LOGS_PAGE_SIZE,
      };

      if (isUserPaging) {
        headers['x-consumer-group-id'] = consumerGroupId.current;
      }

      try {
        const { data: topicData } = await getTopic({
          url: `/api/environments/${record.originId}/logs`,
          headers,
        });

        consumerGroupId.current = topicData?.consumerGroupId;

        if (topicData?.topic) {
          const { topic, token } = topicData;

          const url = new URL(
            document.getElementById('mercure-entrypoint').innerText,
            window.location.origin
          );
          url.searchParams.append('topic', topic);

          abortControllerRef.current = new AbortController();

          fetchEventSource(url.toString(), {
            credentials: 'omit',
            headers: {
              Authorization: 'Bearer ' + token,
            },
            openWhenHidden: true,
            signal: abortControllerRef.current.signal,
            onopen: (response) => {
              if (
                response.ok &&
                response.headers.get('content-type') === EventStreamContentType
              ) {
                setIsRunning(true);
              }
            },
            onmessage: (message) => {
              try {
                const parsedData = JSON.parse(message.data);

                if (parsedData.data) {
                  const logString = window.atob(parsedData.data);
                  let log = parseLog(logString);

                  setLogs((logs) => [...logs, log]);

                  if (offset === -1) {
                    setIsPaging(false);
                  }
                }
              } catch (error) {
                console.log('Received message: ', message);
                console.error(error);
              }
            },
            onerror: (error) => {
              console.error(error);
              setIsRunning(false);
            },
          });
        }
      } catch (error) {
        console.error(error);
        consumerGroupId.current = null;
      }

      setIsLoading(false);
    },
    [getTopic]
  );

  const more = useCallback(
    (record, filters) => {
      // Subscribe from the previous stream
      abortControllerRef.current?.abort();
      abortControllerRef.current = null;

      subscribe(record, filters, -1);
    },
    [subscribe]
  );

  const unsubscribe = useCallback(() => {
    setIsRunning(false);
    consumerGroupId.current = null;
    abortControllerRef.current?.abort();
    abortControllerRef.current = null;
  }, []);

  useEffect(() => {
    // Run log stream automatically
    // if (!shouldRender) return;

    //  subscribe(record, filters);

    return unsubscribe;
  }, [unsubscribe]);

  const stop = useCallback(() => {
    if (isRunning) {
      unsubscribe();
    }
  }, [isRunning, unsubscribe]);

  const canShowMore =
    !isPaging && logs.length !== 0 && logs.length % LOGS_PAGE_SIZE === 0;

  return {
    logs,
    isPaging,
    isLoading,
    isRunning,
    canShowMore,
    start: subscribe,
    stop,
    more,
  };
};

function parseLog(log) {
  const match = log.match(/^\[(.*?) UTC\]\s([A-Z\s]+):\s(.*)$/);
  if (match) {
    return {
      timestamp: `${match[1]}`,
      source: match[2],
      message: match[3],
      raw: log,
    };
  }

  return {
    message: log,
    raw: log,
  };
}

// For testing purposes
function generateRandomLog() {
  function pad(number) {
    return number < 10 ? '0' + number : number;
  }

  function getTimeString() {
    const now = new Date();
    const year = now.getUTCFullYear();
    const month = pad(now.getUTCMonth() + 1); // Months are zero-indexed
    const day = pad(now.getUTCDate());
    const hours = pad(now.getUTCHours());
    const minutes = pad(now.getUTCMinutes());
    const seconds = pad(now.getUTCSeconds());

    return `[${year}-${month}-${day} ${hours}:${minutes}:${seconds} UTC]`;
  }

  return parseLog(`${getTimeString()} EXPORT DB: 127.0.0.1 -  14/Oct/2024:05:51:53 +0000 "GET /healthz/php-fpm" 200 - 0.671 2048 0.00%`);
}

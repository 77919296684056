import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';

export const makeOptionallyNestedSource = (parentSource, source) => {
  const sourcePrefix = parentSource === '' ? '' : `${parentSource}.`;
  return `${sourcePrefix}${source}`;
};

export const getIsHttpCredential = credName => /^http/.test(credName);

export const getCredentialTypeIRI = (credentialType, credentialTypes) => {
  if (undefined === credentialType) {
    return _.first(Object.values(credentialTypes))['@id'];
  }

  return _.isObject(credentialType) ? credentialType['@id'] : credentialType;
};

export const validateGitRepository = isHttpCredentialType => value => {
  const regexp = isHttpCredentialType
    ? /^((http(|s):\/\/)|(http(|s):\/\/[A-Za-z0-9]+@))([A-Za-z0-9.]+(:\d+)?)(?::|\/)([\d/\w.-]+?)(\.git){1}$/i
    : /^([A-Za-z0-9]+@)([A-Za-z0-9.]+(:\d+)?)(?::|\/)([\d/\w.-]+?)(\.git){1}$/i;

  const message = isHttpCredentialType
    ? 'components.git.validation.invalid_http_url'
    : 'components.git.validation.invalid_ssh_url';

  return regexp.test(value) ? undefined : message;
};

export const useCopyToClipboard = value => {
  const [isCopiedToClipboard, setIsCopiedToClipboard] = useState(false);

  const copyToClipboard = useCallback(() => {
    window.navigator.clipboard.writeText(value);
    setIsCopiedToClipboard(true);
  }, [value]);

  const reset = useCallback(() => setIsCopiedToClipboard(false), []);

  useEffect(() => {
    if (isCopiedToClipboard) {
      const id = setTimeout(() => {
        setIsCopiedToClipboard(false);
      }, 2500);
      return () => {
        window.clearTimeout(id);
      };
    }
  }, [isCopiedToClipboard]);

  return {
    isCopiedToClipboard,
    reset,
    copyToClipboard
  };
};

import React from 'react';
import { useTranslate } from 'react-admin';
import { Stack } from '@mui/system';
import {
  CpuInputConfig,
  HelpCard,
  HelpLayout,
  MemoryInputConfig,
  SectionHelper,
  SectionTitle,
  StorageInputConfig
} from '@rc/admin/components';
import { ProjectResourceInput } from '../../components';
import { OneColumnFormStyles } from '@rc/admin/theme/styles';
import { Typography, styled } from '@mui/material';

/**
 *
 * @param {import('@rc/admin/components').HelpLayoutProps} props
 * @returns
 */
export const ProjectCreateStepResources = props => {
  const t = useTranslate();

  return (
    <StyledHelpLayout
      helpers={
        <HelpCard title='resources.projects.helpers.resources.title'>
          {'resources.projects.helpers.resources.content'}
        </HelpCard>
      }
      {...props}
    >
      <SectionTitle mb={0.5}>
        {t('resources.environments.sections.resources')}
      </SectionTitle>
      <SectionHelper mb={1}>
        {t('resources.projects.helpers.resources.subtitle')}
      </SectionHelper>
      <Stack className={ResourcesClasses.inputs}>
        <ProjectResourceInput source={'cpu'} {...CpuInputConfig} fullWidth />
        <ProjectResourceInput
          source={'memory'}
          {...MemoryInputConfig}
          fullWidth
        />
        <ProjectResourceInput
          source={'storage'}
          {...StorageInputConfig}
          fullWidth
        />
      </Stack>
    </StyledHelpLayout>
  );
};

const PREFIX = 'ProjectCreateStepResources';

export const ResourcesClasses = {
  title: `${PREFIX}-title`,
  inputs: `${PREFIX}-inputs`
};

const StyledHelpLayout = styled(HelpLayout)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    minHeight: 400
  },

  [`& .${ResourcesClasses.title}`]: {
    opacity: 0.75
  },

  [`& .${ResourcesClasses.inputs}`]: {
    ...OneColumnFormStyles(theme),
    marginTop: theme.spacing(2)
  }
}));

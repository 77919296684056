import React from 'react';
import { ToggleThemeButton as RaToggleThemeButton } from 'react-admin';

/**
 *
 * @param {import('react-admin').ToggleThemeButtonProps} props
 * @returns
 */
export const ToggleThemeButton = props => {
  return <RaToggleThemeButton {...props} />;
};

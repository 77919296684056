import React from 'react';
import { FunctionField, ReferenceField } from 'react-admin';
import { Box, Stack } from '@mui/material';
import { EditTitle, StateField } from '@rc/admin/components';
import { RCWorkerRecordContextProvider } from '@rc/admin/context';

export const ProjectEditTitle = () => (
  <Stack
    width={'100%'}
    justifyContent={'space-between'}
    alignContent={'center'}
    sx={theme => ({
      flexDirection: 'column',
      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row'
      }
    })}
  >
    <EditTitle showResourceName={false} />
    <Box id='project-status-field'>
      <RCWorkerRecordContextProvider>
        <FunctionField
          sx={{ display: 'flex' }}
          render={record => (
            <ReferenceField
              source={'state'}
              reference={'states'}
              link={false}
              label='resources.projects.fields.state'
              sx={{ display: 'flex' }}
            >
              <StateField
                source={'state'}
                margin='dense'
                variant='body2'
                sx={theme => ({
                  marginBottom: 0,
                  color:
                    theme.palette.mode === 'light'
                      ? 'inherit'
                      : theme.palette.grey[100]
                })}
                stateByInfra={record?.stateByInfra}
              />
            </ReferenceField>
          )}
        />
      </RCWorkerRecordContextProvider>
    </Box>
  </Stack>
);

import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ProjectCreate as Create } from './Create';
import { TypeSelectScreen } from './TypeSelectScreen';

export const ProjectCreate = () => {
  return (
    <Routes>
      <Route path='/' element={<TypeSelectScreen />} />
      <Route
        path='/from-scratch'
        element={<Create type='project' method='scratch' />}
      />
      <Route
        path='/from-template'
        element={<Create type='project' method='template' />}
      />
    </Routes>
  );
};

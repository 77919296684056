import React from 'react';
import {
  Toolbar as RaToolbar,
  SaveButton,
  SimpleForm,
  ToolbarClasses,
  useRecordContext,
  useTranslate
} from 'react-admin';
import { EditDialog, GitInputs } from '@rc/admin/components';
import { HTTP_GIT_CREDENTIAL_TYPE_ID } from '@rc/admin/constants';

/**
 *
 * @param {object} props
 * @param {object} props.record
 * @returns
 */
export const GitEditDialog = props => {
  const record = useRecordContext(props.record);
  const t = useTranslate();

  return (
    <EditDialog
      resource='gits'
      id={record.id}
      title={t('ra.action.edit')}
      variant='outlined'
      size='small'
      transform={data => {
        if (data.publicKey) {
          delete data.publicKey;
        }

        if (data.credentialType === HTTP_GIT_CREDENTIAL_TYPE_ID) {
          delete data.cryptoKey;
        }

        return data;
      }}
    >
      <SimpleForm
        toolbar={
          <RaToolbar>
            <div className={ToolbarClasses.defaultToolbar}>
              <SaveButton />
            </div>
          </RaToolbar>
        }
      >
        <GitInputs canEditGitType={false} />
      </SimpleForm>
    </EditDialog>
  );
};

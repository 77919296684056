import React from 'react';
import { useForm } from 'react-hook-form';
import { useAxios } from '@rc/utils/axios';
import { Link, useNotify, useRedirect, useTranslate } from 'react-admin';
import { Box, CardActions, Stack, styled, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FieldInput, Iconify } from '@rc/admin/components';
import { validateEmail } from '@rc/admin/utils/form';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { GRECAPTCHA_ENABLED } from '@rc/admin/constants';

const ForgotPasswordForm = () => {
  const t = useTranslate();
  const redirect = useRedirect();
  const notify = useNotify();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const { handleSubmit, control, trigger } = useForm({
    defaultValues: {
      email: ''
    }
  });

  const [{ loading: isLoading }, forgotPassword] = useAxios(
    { url: '/api/users/forgot-password', method: 'PUT' },
    {
      manual: true
    }
  );

  const onSubmit = async data => {
    let recaptchaToken = null;
    if (GRECAPTCHA_ENABLED && !executeRecaptcha) {
      console.error('Error on captcha');
      return;
    } else if (executeRecaptcha) {
      recaptchaToken = await executeRecaptcha('forgotPassword');
    }

    await forgotPassword({
      data,
      headers: {
        'g-recaptcha-token': recaptchaToken
      }
    })
      .then(r => {
        redirect('/login');
        notify('pages.forgot_password.notification.success', {
          type: 'success',
          undoable: false
        });
      })
      .catch(e => {
        notify('pages.forgot_password.notification.error', {
          type: 'error',
          undoable: false
        });
      });
  };

  return (
    <StyledForm
      className={classes.form}
      autoComplete='off'
      onSubmit={handleSubmit(onSubmit)}
    >
      <Stack alignItems='center' spacing={2}>
        <Iconify
          icon='solar:lock-password-bold-duotone'
          width={48}
          height={48}
        />

        <Stack spacing={1} sx={{ mb: 2, textAlign: 'center' }}>
          <Typography variant='h4'>
            {t('pages.forgot_password.title')}
          </Typography>
        </Stack>
      </Stack>

      <Stack spacing={1}>
        <FieldInput
          name={'email'}
          label='Email'
          control={control}
          rules={{
            required: 'ra.validation.required',
            validate: {
              email: validateEmail
            }
          }}
          onBlur={() => trigger('email')}
          required
        />
      </Stack>
      <CardActions className={classes.actions}>
        <LoadingButton
          variant='contained'
          type='submit'
          color='primary'
          size='large'
          fullWidth
          loading={isLoading}
        >
          {t('pages.forgot_password.send_recovery_email')}
        </LoadingButton>
      </CardActions>

      <Box sx={{ textAlign: 'center' }}>
        <Typography
          component={Link}
          to={'/login'}
          color='inherit'
          variant='subtitle2'
          sx={{
            alignItems: 'center',
            display: 'inline-flex',
            mt: 2
          }}
        >
          <Iconify icon='eva:arrow-ios-back-fill' width={16} />
          {t('pages.forgot_password.login')}
        </Typography>
      </Box>
    </StyledForm>
  );
};

const PREFIX = 'ForgotPasswordForm';

const classes = {
  form: `${PREFIX}-form`,
  actions: `${PREFIX}-actions`
};

const StyledForm = styled('form')(({ theme }) => ({
  [`& .${classes.actions}`]: {
    marginTop: theme.spacing(2.5),
    padding: 0
  }
}));

export default ForgotPasswordForm;

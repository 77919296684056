import React, { useRef, useState } from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';

/**
 *
 * @param {object} props
 * @param {boolean} props.unselectable
 * @param {Function} props.onClick
 * @param {Function} props.onChanged
 * @param {string[]} props.options
 * @param {string} props.defaultOption
 * @param {string} props.label
 * @param {React.ReactNode} props.children
 * @param {string} props.variant
 * @param {object} props.buttonProps
 * @param {boolean} props.disabled
 * @param {Function} props.getOptionLabel
 * @returns
 */
export const SplitButton = props => {
  const {
    unselectable,
    onClick,
    onChanged,
    options,
    defaultOption,
    label,
    children,
    variant,
    buttonProps,
    getOptionLabel,
    disabled,
    ...rest
  } = props;
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const [selectedIndex, setSelectedIndex] = useState(
    options.findIndex(option => option === defaultOption) || null
  );

  const handleMenuItemClick = (_, index) => {
    onChanged(options[index]);
    if (!unselectable) {
      setSelectedIndex(index);
    }
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const popup = (
    <Popper
      sx={{
        zIndex: 3
      }}
      open={open}
      anchorEl={anchorRef.current}
      role={undefined}
      transition
      disablePortal
      popperOptions={{
        placement: 'bottom'
      }}
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === 'bottom' ? 'center top' : 'center bottom'
          }}
        >
          <Paper>
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList id='split-button-menu' autoFocusItem>
                {options.map((option, index) => (
                  <MenuItem
                    key={option}
                    selected={index === selectedIndex}
                    onClick={event => handleMenuItemClick(event, index)}
                  >
                    {getOptionLabel(option)}
                  </MenuItem>
                ))}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );

  return (
    <>
      <ButtonGroup variant={variant} disabled={disabled} {...rest}>
        <Button
          onClick={onClick}
          variant={variant}
          {...SplitButton.defaultProps.buttonProps}
          {...buttonProps}
        >
          {children}
        </Button>
        <Button
          ref={anchorRef}
          type='button'
          size='medium'
          variant={variant}
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label={label}
          aria-haspopup='menu'
          onClick={handleToggle}
          onMouseEnter={handleToggle}
          onMouseLeave={handleToggle}
          sx={{ marginLeft: '0px !important' }}
          disableFocusRipple
        >
          <ArrowDropDownIcon />
          {popup}
        </Button>
      </ButtonGroup>
    </>
  );
};

SplitButton.defaultProps = {
  buttonProps: {
    type: 'button'
  },
  unselectable: false,
  getOptionLabel: option => option
};

export default SplitButton;

import { Box, TextField, styled } from '@mui/material';
import { FieldInput } from '@rc/admin/components';
import React from 'react';
import {
  maxLength,
  minLength,
  // useRedirect,
  useTranslate
} from 'react-admin';

/**
 *
 * @param {object} props
 * @param {boolean} props.steps - steps
 * @param {string} props.step - current step
 * @param {object} props.formProps - useForm props
 * @param {boolean} props.isLoading - loading state
 * @returns
 */
export const CodeFields = props => {
  const {
    formProps: {
      control,
      formState: { isSubmitted },
      trigger
    }
  } = props;
  const t = useTranslate();

  return (
    <Box>
      <FieldInput
        component={CodeInput}
        name={'access_code'}
        label={t('ra.auth.invitation_code')}
        control={control}
        placeholder='••••••••••'
        rules={{
          required: 'ra.validation.required',
          length: value =>
            minLength(4)(value)?.message || maxLength(4)(value)?.message
        }}
        onBlur={isSubmitted ? () => trigger('fullname') : undefined}
        required
        size='medium'
        fontSize='large'
      />
    </Box>
  );
};

const CodeInput = styled(TextField)(({ theme }) => ({}));

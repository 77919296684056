import React from 'react';
import { ProjectTypeEdit } from './Edit';
import { ProjectTypeList } from './List';
import { ProjectTypeCreate } from './Create';
import Iconify from '@rc/admin/components/ui/Iconify';

export default {
  name: 'project_types',
  list: ProjectTypeList,
  edit: ProjectTypeEdit,
  create: ProjectTypeCreate,
  icon: () => <Iconify icon='solar:window-frame-bold-duotone' />
};

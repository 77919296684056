import { matchPath, useLocation } from 'react-router-dom';

export function useRouteMatch (patternsParam) {
  const { pathname } = useLocation();

  if (!patternsParam) {
    return null;
  }

  let patterns = patternsParam;
  if (typeof patternsParam === 'string') {
    patterns = [patternsParam];
  }

  for (let i = 0; i < patterns.length; i += 1) {
    const pattern = patterns[i];
    const possibleMatch = matchPath(pattern, pathname);
    if (possibleMatch !== null) {
      return possibleMatch;
    }
  }

  return null;
}

import React from 'react';
import { ProviderEdit } from './Edit';
import { ProviderList } from './List';
import { ProviderCreate } from './Create';
import Iconify from '@rc/admin/components/ui/Iconify';

export default {
  name: 'providers',
  list: ProviderList,
  edit: ProviderEdit,
  create: ProviderCreate,
  icon: () => <Iconify icon='solar:server-square-cloud-bold-duotone' />
};

import { useSimpleFormIterator, useSimpleFormIteratorItem } from 'react-admin';
import { useWatch } from 'react-hook-form';

/**
 *
 * @param {string} value
 * @param {string} type
 * @returns
 */
function isValidCertificateText (value, type) {
  return (
    value.startsWith(`-----BEGIN ${type}-----`) &&
    value.endsWith(`-----END ${type}-----`)
  );
}

/**
 *
 * @param {string} value
 * @returns
 */
export function validateCertificateKey (value) {
  if (value && isValidCertificateText(value, 'PRIVATE KEY')) {
    return 'resources.environments.validation.invalid_certificate_key';
  }
}

/**
 *
 * @param {string} value
 * @returns
 */
export function validateCertificateData (value) {
  if (value && isValidCertificateText(value, 'CERTIFICATE')) {
    return 'resources.environments.validation.invalid_certificate_data';
  }
}

/**
 *
 * @param {string} value
 * @returns
 */
export function validateHostname (value) {
  if (
    value &&
    !value.match(/^(?!:\/\/)([a-zA-Z0-9-_]{1,63}\.)+[a-zA-Z]{2,6}$/)
  ) {
    return 'resources.environments.validation.invalid_hostname';
  }

  return undefined;
}

/**
 *
 * @param {string} source
 * @returns
 */
export function uniqueHostname (source) {
  return (value, allValues, input) => {
    const [field, index] = input.source.split('.').reverse();

    const hasDuplicates = allValues[source]?.some(
      (item, i, array) =>
        // Skip validation if the current item has an @id
        !array[Number(index)]?.['@id'] &&
        i !== Number(index) &&
        item[field] === value
    );

    if (hasDuplicates) {
      return 'resources.environments.validation.domain_already_taken';
    }
  };
}

export const useIsCreated = () => {
  const { source } = useSimpleFormIterator();
  const { index } = useSimpleFormIteratorItem();
  const currentId = useWatch({ name: `${source}[${index}].[@id]` });

  return currentId !== undefined && currentId !== null;
};

export const useIsSubdomain = () => {
  const { source } = useSimpleFormIterator();
  const { index } = useSimpleFormIteratorItem();
  const isSubdomain = useWatch({
    name: `${source}[${index}].isSubdomain`
  });

  return !!isSubdomain;
};

import React from 'react';
import { LayoutClasses, Layout as RaLayout } from 'react-admin';
import AppBar from './AppBar';
import Sidebar from './Sidebar';
import { useWorkerUpdates } from '@rc/admin/hooks/useWorkerUpdates';
import { Box, Container, CssBaseline, styled, Typography } from '@mui/material';
import ContextProvider, { useLayoutContext } from '../context';
import { usePrefetchList } from '../hooks';
import { HEADER, NAV } from '../theme/MinimalTheme/layouts/config-layout';

const SPACING = 8;

const CustomLayout = props => {
  const { children } = props;
  const { navType } = useLayoutContext();

  useWorkerUpdates();
  usePrefetchList([
    'resource_types',
    'project_types',
    'project_type_versions',
    'states'
  ]);

  return (
    <StyledRaLayout
      navType={navType}
      {...props}
      className={classes.root}
      appBar={AppBar}
      sidebar={Sidebar}
      menu={() => null}
    >
      <CssBaseline enableColorScheme />
      <Container maxWidth={'xl'}>
        <Typography
          className={classes.title}
          variant='h4'
          id='react-admin-title'
          gutterBottom
        />
        <Box id='react-admin-breadcrumbs' />
        {children}
      </Container>
    </StyledRaLayout>
  );
};

const PREFIX = 'Layout';

const classes = {
  root: `${PREFIX}-root`,
  title: `${PREFIX}-title`
};

const StyledRaLayout = styled(RaLayout, {
  shouldForwardProp: prop => prop !== 'navType' || prop !== 'isNavMini'
})(({ theme, navType }) => ({
  [`&.${classes.root} .${LayoutClasses.appFrame}`]: {
    marginTop: theme.appBar.height,
    [theme.breakpoints.up('lg')]: {
      marginTop: 0
    }
  },
  [`& .${classes.title}`]: {
    marginLeft: 1,
    '&:has(*)': {
      marginBottom: theme.spacing(1)
    },
    '& > *': {
      width: '100%'
    }
  },
  [`& .${LayoutClasses.appFrame}`]: {
    marginTop: 0,
    [theme.breakpoints.down('sm')]: {
      marginTop: 0
    }
  },
  [`& .${LayoutClasses.content}`]:
    navType === 'horizontal'
      ? {
          minHeight: 1,
          display: 'flex',
          flexDirection: 'column',
          padding: `${HEADER.H_MOBILE + 24}px 0 10px`,
          [theme.breakpoints.up('lg')]: {
            padding: `${HEADER.H_MOBILE * 2 + 40}px 0 15px`
          }
        }
      : {
          flexGrow: 1,
          minHeight: 1,
          display: 'flex',
          flexDirection: 'column',
          padding: `${HEADER.H_MOBILE + SPACING}px 0`,
          [theme.breakpoints.up('lg')]: {
            padding: `${HEADER.H_DESKTOP + SPACING}px 2px`,
            width: `calc(100% - ${NAV.W_VERTICAL}px)`,
            ...(navType === 'mini' && {
              width: `calc(100% - ${NAV.W_MINI}px)`
            })
          }
        }
}));

const Layout = props => {
  return (
    <ContextProvider>
      <CustomLayout {...props} />
    </ContextProvider>
  );
};

export default Layout;

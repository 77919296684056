import React from 'react';
import {
  ArrayInput,
  RemoveItemButton,
  SimpleFormIterator,
  SimpleFormIteratorClasses,
  useSimpleFormIterator
} from 'react-admin';
import { styled } from '@mui/material';
import {
  AddButton as RcAddButton,
  CpuInputConfig,
  MemoryInputConfig,
  NodeInputConfig,
  StorageInputConfig
} from '@rc/admin/components';
import { ENABLE_NODE_RESOURCE_VALUES } from '@rc/admin/constants';
import { useValidateEnvironmentComponent } from '../../hooks';
import { EnvironmentComponentVersionInput } from './EnvironmentComponentVersionInput';
import { EnvironmentComponentResourceInput } from './EnvironmentComponentResourceInput';

export const EnvironmentComponentInputs = props => {
  const { source } = props;

  // Adding custom validation process because Form or ArrayInput level validation cannot be used together with input level validation
  // See: https://github.com/marmelab/react-admin/issues/7697
  useValidateEnvironmentComponent({ source });

  return (
    <StyledArrayInput {...props} label=''>
      <StyledSimpleFormIterator
        disableReordering
        disableClear
        addButton={<AddButton />}
        removeButton={
          <RemoveItemButton variant='text' sx={{ marginLeft: 1 }} />
        }
      >
        <EnvironmentComponentVersionInput
          source={'componentVersion'}
          fullWidth
        />
        {ENABLE_NODE_RESOURCE_VALUES && (
          <EnvironmentComponentResourceInput
            source={'node'}
            {...NodeInputConfig}
            fullWidth
          />
        )}
        <EnvironmentComponentResourceInput
          source={'cpu'}
          {...CpuInputConfig}
          fullWidth
        />
        <EnvironmentComponentResourceInput
          source={'memory'}
          {...MemoryInputConfig}
          fullWidth
        />
        <EnvironmentComponentResourceInput
          source={'storage'}
          {...StorageInputConfig}
          fullWidth
        />
      </StyledSimpleFormIterator>
    </StyledArrayInput>
  );
};

const AddButton = props => {
  const { add } = useSimpleFormIterator();

  return (
    <RcAddButton
      {...props}
      onClick={() =>
        add({
          componentVersion: '',
          ...(ENABLE_NODE_RESOURCE_VALUES
            ? { node: NodeInputConfig.defaultValue }
            : {}),
          cpu: CpuInputConfig.min,
          memory: MemoryInputConfig.min,
          storage: StorageInputConfig.min
        })
      }
    />
  );
};

const StyledArrayInput = styled(ArrayInput)(({ theme }) => ({
  [`& .${SimpleFormIteratorClasses.form}`]: {
    width: '100%'
  },

  [`& .${SimpleFormIteratorClasses.action} .button-remove`]: {
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0
    }
  }
}));

const StyledSimpleFormIterator = styled(SimpleFormIterator)(({ theme }) => ({
  [`& .${SimpleFormIteratorClasses.line}`]: {
    marginBottom: theme.spacing(1.5)
  },

  [`& .${SimpleFormIteratorClasses.form}`]: {
    width: '100%'
  }
}));

import React from 'react';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import Link from '@tiptap/extension-link';
import TextAlign from '@tiptap/extension-text-align';
import TextStyle from '@tiptap/extension-text-style';
import { Color } from '@tiptap/extension-color';
import Highlight from '@tiptap/extension-highlight';
import {
  ClearButtons,
  DefaultEditorOptions,
  FormatButtons,
  LinkButtons,
  RichTextInputToolbar,
  ColorButtons
} from 'ra-input-rich-text';

export const transformData = async data => {
  return {
    ...data,
    projectEnvVar: data.projectEnvVar.filter(envVar => envVar)
  };
};

export const TEMPLATE_PROJECT_DESCRIPTION_EDITOR_OPTIONS = {
  ...DefaultEditorOptions,
  extensions: [
    StarterKit,
    Underline,
    Link,
    TextAlign.configure({
      types: ['heading', 'paragraph']
    }),
    TextStyle, // Required by Color
    Color,
    Highlight.configure({ multicolor: true })
  ]
};

export const TemplateProjectDescriptionToolbar = ({ size, ...props }) => {
  return (
    <RichTextInputToolbar {...props}>
      <FormatButtons size={size} />
      <ColorButtons />
      <LinkButtons size={size} />
      <ClearButtons size={size} />
    </RichTextInputToolbar>
  );
};

export const validateTemplateProjectDescription = value => {
  const { textContent } = new window.DOMParser().parseFromString(
    value,
    'text/html'
  ).body;

  return textContent.length > 255
    ? { message: 'ra.validation.maxLength', args: { max: 255 } }
    : undefined;
};

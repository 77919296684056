import React from 'react';
import { ComponentCreate } from './Create';
import { ComponentEdit } from './Edit';
import { ComponentList } from './List';
import Iconify from '@rc/admin/components/ui/Iconify';

export default {
  name: 'components',
  create: ComponentCreate,
  edit: ComponentEdit,
  list: ComponentList,
  icon: () => <Iconify icon='solar:remote-controller-2-bold-duotone' />
};

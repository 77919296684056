import React from 'react';
import { useAuthenticated } from 'react-admin';
import { RedirectWithNotification } from '@rc/admin/components';
import { useHasPermission } from '@rc/admin/hooks';

/**
 * Returns module components wrapped with permission handling.
 *
 * @param {T} module
 * @returns {T}
 */
export const getResourcePropsWithPermissions = module => {
  ['list', 'show', 'edit', 'create'].forEach(action => {
    if (module[action]) {
      module[action] = WithPermissions(module[action], module.name, action);
    }
  });

  return module;
};

export const WithPermissions = (Component, resource, action) => {
  const ResultComponent = React.memo(props => {
    useAuthenticated({});
    const { isLoading, hasPermission, authenticated } = useHasPermission(
      resource,
      action
    );

    if (hasPermission) {
      return <Component {...props} />;
    } else if (isLoading) {
      return null;
      // Removed because it is displayed for very short amount of time
      // return <LoadingPage {...props} />;
    } else if (authenticated) {
      return (
        <RedirectWithNotification
          to={'/'}
          message={'notification.rejected'}
          options={{ type: 'info', undoable: false }}
        />
      );
    } else {
      return null;
    }
  });

  ResultComponent.displayName = 'WithPermissions';

  return ResultComponent;
};

import { useRecordContext } from 'react-admin';

export const getStateId = state => Number(state?.split('/').pop());
export const createStateIri = stateId => `/api/state/${stateId}`;

export const useStateId = () => {
  const record = useRecordContext();

  const { state } = record || {};
  const stateId = state ? getStateId(state) : null;

  return stateId;
};

import React from 'react';
import { EnvironmentEnvVarInputs } from '../components';
import { useTranslate, useSidebarState } from 'react-admin';
import {
  HelpCard,
  HelpLayout,
  HelpLayoutClasses,
  Image,
  SectionHelper,
  SectionTitle
} from '@rc/admin/components';
import { styled, useMediaQuery } from '@mui/material';

export const EnvironmentCreateStepVariables = props => {
  const { showTitle = true } = props;
  const t = useTranslate();
  const [isSidebarOpen] = useSidebarState();
  const isSmallContentWidth = useMediaQuery(theme =>
    theme.breakpoints.down(isSidebarOpen ? 'xl' : 'lg')
  );

  return (
    <StyledHelpLayout
      isTooltip={isSmallContentWidth}
      helpers={
        <HelpCard title='resources.projects.helpers.envVars.title'>
          {t('resources.projects.helpers.envVars.content')}
          <Image
            src='/images/helper-envVars.png'
            alt='Variables helper image'
            width={1338}
            height={678}
          />
        </HelpCard>
      }
    >
      {showTitle && (
        <SectionTitle mb={0.5}>
          {t('resources.environments.sections.envVars.title')}
        </SectionTitle>
      )}
      <SectionHelper mb={1}>
        {t('resources.environments.sections.envVars.subtitle')}
      </SectionHelper>
      <EnvironmentEnvVarInputs
        source={'envVar'}
        label={t('resources.env_vars.name', { smart_count: 2 })}
        fullWidth
      />
    </StyledHelpLayout>
  );
};

const StyledHelpLayout = styled(HelpLayout)(({ theme }) => ({
  [`& .${HelpLayoutClasses.helper}`]: {
    [theme.breakpoints.up('md')]: {
      minHeight: 400
    }
  },

  [`& .${HelpLayoutClasses.content}`]: {
    maxWidth: '60em'
  }
}));

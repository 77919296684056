import React from 'react';
import {
  RecordContextProvider,
  ResourceContextProvider,
  useRecordContext,
  useResourceContext
} from 'react-admin';
import { SideDialogContextProvider } from '../../ui/SideDialog';
import { AddDialogView } from './AddDialogView';

/**
 *
 * @param {import('./AddDialogView').AddDialogProps & import('../../ui/SideDialog/SideDialog').SideDialogProps} props
 * @returns
 */
export const AddDialog = props => {
  const { resource: resourceProp, record: recordProp } = props;
  const resource = useResourceContext({ resource: resourceProp });
  const record = useRecordContext({ record: recordProp });

  return (
    <SideDialogContextProvider>
      <ResourceContextProvider value={resource}>
        <RecordContextProvider value={record}>
          <AddDialogView {...props} />
        </RecordContextProvider>
      </ResourceContextProvider>
    </SideDialogContextProvider>
  );
};

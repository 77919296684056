import React from 'react';
import PropTypes from 'prop-types';
import {
  useGetResourceLabel,
  useRecordContext,
  TextField,
  useResourceContext,
  useResourceDefinition
} from 'react-admin';
import { Box, Typography, styled } from '@mui/material';
import { WithCopyToClipboard } from '../../util/WithCopyToClipboard';
import { useIsDebugMode } from '@rc/admin/context';
import { Link } from 'react-router-dom';
import { getResourcePath } from '@rc/admin/utils/common/menu';

const EditTitle = props => {
  const { source, title: titleProp, showResourceName } = props;
  const record = useRecordContext();
  const getResourceLabel = useGetResourceLabel();
  const resource = useResourceContext();
  const isDebugMode = useIsDebugMode();
  const { hasShow } = useResourceDefinition();

  if (!record) {
    return null;
  }

  const title = titleProp
    ? typeof titleProp === 'function'
      ? titleProp(record)
      : titleProp
    : record[source];

  const resourceLabel = showResourceName && `${getResourceLabel(resource, 1)} `;

  return (
    <Root data-testid={'rc-edit-title-root'}>
      {!hasShow ? (
        <>
          {resourceLabel}
          {title}
        </>
      ) : (
        <Typography
          component={Link}
          variant='h4'
          to={`${getResourcePath(resource, record.id)}/show`}
          sx={theme => ({
            textTransform: 'none',
            textDecoration: 'none',
            color: theme.palette.text.primary
          })}
        >
          {resourceLabel}
          {title}
        </Typography>
      )}
      {isDebugMode && (
        <Box display='table'>
          <WithCopyToClipboard>
            <TextField
              source={'uuid'}
              label=''
              sx={theme => ({
                color: theme.palette.text.disabled,
                fontSize: '12px !important'
              })}
            />
          </WithCopyToClipboard>
        </Box>
      )}
    </Root>
  );
};

const Root = styled('div')(() => ({
  '& span': {
    fontSize: 'inherit'
  }
}));

EditTitle.defaultProps = {
  source: 'name',
  showResourceName: true
};

EditTitle.propTypes = {
  source: PropTypes.string,
  record: PropTypes.object
};

export default EditTitle;

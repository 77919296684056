import React from 'react';
import { RoleGroupEdit } from './Edit';
import { RoleGroupList } from './List';
import { Iconify } from '@rc/admin/components';

export default {
  name: 'role_groups',
  edit: RoleGroupEdit,
  list: RoleGroupList,
  icon: () => <Iconify icon='solar:shield-user-bold-duotone' />
};

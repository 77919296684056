import React from 'react';
import { Navigate, Route, Routes, NavLink } from 'react-router-dom';
import { Tabs, Tab, Stack } from '@mui/material';
import { Iconify } from '@rc/admin/components';
import { useRouteMatch } from '@rc/admin/hooks';
import { EditProfile } from './components';
import BillingPage from '../Billing';

const TABS = [
  {
    value: '/account/general',
    to: '/account/general',
    label: 'General',
    icon: <Iconify icon='solar:user-id-bold' width={24} />
  },
  {
    value: '/account/billing/*',
    to: '/account/billing',
    label: 'Billing',
    icon: <Iconify icon='solar:bill-list-bold' width={24} />
  }
];

const Profile = () => {
  const routeMatch = useRouteMatch(['/account/general', '/account/billing/*']);
  const currentTab = routeMatch?.pattern?.path;

  return (
    <Stack>
      <Tabs
        value={currentTab}
        sx={{
          mb: { xs: 3, md: 5 }
        }}
      >
        {TABS.map(tab => (
          <Tab
            LinkComponent={NavLink}
            key={tab.value}
            label={tab.label}
            icon={tab.icon}
            to={tab.to}
            value={tab.value}
          />
        ))}
      </Tabs>
      <Routes>
        <Route
          path='/'
          element={<Navigate replace to={'/account/general'} />}
        />
        <Route path='/general/*' element={<EditProfile />} />
        <Route path='/billing/*' element={<BillingPage />} />
        {/* <Route path='/reports' element={<NotImplemented />} />
        <Route path='/settings' element={<NotImplemented />} />
        <Route path='/limits' element={<NotImplemented />} /> */}
      </Routes>
    </Stack>
  );
};

export default Profile;

import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { FieldTitle, useInput } from 'ra-core';
import { styled, TextField } from '@mui/material';
import * as ReactColor from 'react-color';

const ColorInput = props => {
  const {
    onChange,
    label,
    source,
    className,
    options,
    picker,
    resource,
    helperText,
    autoComplete,
    margin,
    variant,
    fullWidth,
    defaultValue,
    validate
  } = props;

  const { field, fieldState, isRequired } = useInput({
    ...props
  });

  const [show, setShow] = useState(false);

  const handleChange = useCallback(
    ({ hex }, event) => {
      event.target.value = hex;
      field.onChange(event);
      if (onChange) {
        onChange(event);
      }
    },
    [field, onChange]
  );

  const { isTouched, error } = fieldState;

  const Picker = ReactColor[`${picker}Picker`];

  return (
    <Root>
      <TextField
        {...field}
        margin={margin}
        variant={variant}
        onFocus={() => setShow(true)}
        label={
          <FieldTitle
            label={label}
            source={source}
            resource={resource}
            isRequired={isRequired}
          />
        }
        error={!!(isTouched && error)}
        helperText={(isTouched && error) || helperText}
        className={className}
        autoComplete={autoComplete}
        fullWidth={fullWidth}
        validate={validate}
        defaultValue={defaultValue}
      />
      {show ? (
        <div className={classes.popup}>
          <div className={classes.cover} onClick={() => setShow(false)} />
          <Picker {...options} color={field.value} onChange={handleChange} />
        </div>
      ) : null}
    </Root>
  );
};

ColorInput.propTypes = {
  label: PropTypes.string,
  options: PropTypes.object,
  source: PropTypes.string,
  helperText: PropTypes.string,
  className: PropTypes.string,
  picker: (props, propName, componentName) =>
    !ReactColor[`${props[propName]}Picker`] &&
    new Error(`Invalid prop \`${propName}\` supplied to \`${componentName}\`.`)
};

ColorInput.defaultProps = {
  picker: 'Chrome',
  options: {
    disableAlpha: true
  },
  fullWidth: false,
  margin: 'dense',
  variant: 'filled'
};

const PREFIX = 'ColorInput';

const classes = {
  popup: `${PREFIX}-popup`,
  cover: `${PREFIX}-cover`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(() => ({
  [`& .${classes.popup}`]: {
    position: 'absolute',
    zIndex: 2
  },

  [`& .${classes.cover}`]: {
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0
  }
}));

export default ColorInput;

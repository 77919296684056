import React from 'react';
import {
  BooleanField,
  Datagrid,
  Edit,
  EditClasses,
  NumberField,
  ReferenceField,
  SaveButton,
  SimpleForm,
  SimpleList,
  TextField,
  TextInput,
  Toolbar,
  ToolbarClasses
} from 'react-admin';
import { EditButton } from '@rc/admin/ra';
import { styled, Card, useMediaQuery } from '@mui/material';
import {
  AsideReferenceList,
  Breadcrumbs,
  DeleteButton,
  EditTitle,
  ProjectTypeVersionField
} from '@rc/admin/components';
import { OneColumnFormStyles } from '@rc/admin/theme/styles';

export const ComponentVersionEdit = () => {
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

  return (
    <StyledEdit
      title={<EditTitle source={'version'} />}
      redirect={false}
      mutationMode='pessimistic'
      actions={
        <Breadcrumbs
          label={<BreadcrumbsLabel />}
          parents={breadcrumbsParents}
        />
      }
      aside={
        <>
          <Card className={classes.asideCard}>
            <AsideReferenceList
              resource={'component_version_env_vars'}
              referenceSource={'componentVersion'}
              title={
                'resources.component_versions.fields.component_version_env_vars'
              }
            >
              {isSmall ? (
                <SimpleList
                  primaryText={() => <TextField source={'key'} />}
                  secondaryText={() => <TextField source={'note'} />}
                  tertiaryText={() => <BooleanField source={'isRequired'} />}
                  style={{ width: '100%' }}
                />
              ) : (
                <Datagrid style={{ width: '100%' }}>
                  <TextField source={'key'} />
                  <TextField source={'note'} />
                  <BooleanField source={'isRequired'} />
                  <EditButton />
                </Datagrid>
              )}
            </AsideReferenceList>
          </Card>

          <Card className={classes.asideCard}>
            <AsideReferenceList
              resource={'component_resource_limits'}
              referenceSource={'componentVersion'}
              title={
                'resources.component_versions.fields.component_resource_limits'
              }
            >
              <Datagrid style={{ width: '100%' }}>
                <ReferenceField
                  source={'resourceType'}
                  reference={'resource_types'}
                  link={false}
                >
                  <TextField source={'name'} />
                </ReferenceField>
                <ReferenceField
                  source={'projectTypeVersion'}
                  reference={'project_type_versions'}
                >
                  <ProjectTypeVersionField source={'version'} />
                </ReferenceField>
                <NumberField source={'minValue'} />
                <NumberField source={'maxValue'} />
                <EditButton />
              </Datagrid>
            </AsideReferenceList>
          </Card>
        </>
      }
    >
      <SimpleForm
        defaultValues={{ isEnabled: true }}
        className={classes.form}
        toolbar={
          <Toolbar>
            <div className={ToolbarClasses.defaultToolbar}>
              <SaveButton />
              <DeleteButton
                redirect={(_, __, record) =>
                  `/components/${encodeURIComponent(record?.component)}`
                }
              />
            </div>
          </Toolbar>
        }
      >
        <TextInput source={'version'} fullWidth />
      </SimpleForm>
    </StyledEdit>
  );
};

const breadcrumbsParents = [{ reference: 'components', source: 'component' }];

const BreadcrumbsLabel = props => {
  const { data } = props;
  return data['@type'] === 'ComponentVersion' ? data.version : data.name;
};

const PREFIX = 'ComponentVersionEdit';

const classes = {
  asideCard: `${PREFIX}-asideCard`,
  form: `${PREFIX}-form`
};

const StyledEdit = styled(Edit)(({ theme }) => ({
  [`& .${EditClasses.main}`]: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(3),
    '& > *': {
      width: '100%'
    }
  },

  [`& .${classes.asideCard}`]: {},

  [`& .${classes.form}`]: {
    ...OneColumnFormStyles(theme)
  }
}));

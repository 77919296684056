import { useProjectContext } from '@rc/admin/context';

const { useGetOne } = require('react-admin');

export const useRequiredComponents = () => {
  const { project } = useProjectContext();

  const {
    data: projectTypeVersion,
    isLoading: isProjectTypeVersionLoading
  } = useGetOne(
    'project_type_versions',
    { id: project?.projectTypeVersion },
    { enabled: !!project }
  );

  return {
    isLoading: isProjectTypeVersionLoading,
    requiredComponents: projectTypeVersion?.requiredComponent || []
  };
};

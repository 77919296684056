import React, { useRef } from 'react';
import {
  Edit,
  FormTab,
  Labeled,
  RecordContextProvider,
  SaveButton,
  TabbedForm,
  Toolbar as RaToolbar,
  useGetList,
  useGetOne,
  useRecordContext,
  useResourceContext,
  useTranslate,
  TextInput,
  TextField,
  useUnique,
  required,
  ReferenceField
} from 'react-admin';
import { useParams } from 'react-router';
import { Grid, styled } from '@mui/material';
import {
  Breadcrumbs,
  EditTitle,
  EnvVarInputs,
  OrganisationField,
  SectionHelper,
  SectionTitle
} from '@rc/admin/components';
import { OneColumnFormStyles } from '@rc/admin/theme/styles';
import {
  transformUnauthorized,
  UnauthorizedRecordContextModifier
} from '@rc/admin/utils/form';
import { ROLES } from '@rc/admin/constants';
import { DeleteDialog } from '../components';
import { Teams } from './Teams';
import { transform } from './utils';
import { useIsRole } from '@rc/admin/hooks';

export const OrganisationEdit = () => {
  const t = useTranslate();
  const { result: isAdmin } = useIsRole(ROLES.ADMIN);
  const teamsGetListRef = useRef(null);
  const resource = useResourceContext();
  const { id: routeId } = useParams();
  const id = decodeURIComponent(routeId ?? '');
  const { data: organisation } = useGetOne(resource, { id });
  const { data: authorizedTeams } = useGetList('teams');

  return (
    <StyledEdit
      title={
        <EditTitle
          title={<OrganisationField label='' />}
          showResourceName={false}
        />
      }
      redirect={false}
      actions={<Breadcrumbs label={<BreadcrumbsLabel />} />}
      transform={data =>
        transformUnauthorized(
          organisation,
          'team',
          authorizedTeams
        )(transform(data))
      }
      mutationOptions={{
        onSuccess: () => {
          teamsGetListRef.current?.refetch();
        }
      }}
      mutationMode='pessimistic'
    >
      <UnauthorizedRecordContextModifier
        source={'team'}
        authorizedData={authorizedTeams}
      >
        <TabbedForm redirect={'edit'} toolbar={<Toolbar />} mode='all'>
          <FormTab
            label={t('resources.organisations.tabs.general')}
            contentClassName={classes.general}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <NameField fullWidth />
              </Grid>
              <Grid item xs={12}>
                <Labeled>
                  <ReferenceField
                    reference='users'
                    source={'defaultUser'}
                    link={!isAdmin ? false : undefined}
                  >
                    <TextField source='fullname' fullWidth />
                  </ReferenceField>
                </Labeled>
              </Grid>
            </Grid>

            {/* <ReferenceArrayInput source='team' reference='teams'>
              <TeamArrayInput fullWidth />
            </ReferenceArrayInput> */}
          </FormTab>
          <FormTab label={t('resources.organisations.tabs.teams')}>
            <Teams getListRef={teamsGetListRef} />
          </FormTab>
          <FormTab label={t('resources.organisations.tabs.configuration')}>
            <SectionTitle mb={0.5}>
              {t('resources.organisations.sections.envVars.title')}
            </SectionTitle>
            <SectionHelper mb={1}>
              {t('resources.organisations.sections.envVars.subtitle')}
            </SectionHelper>
            <EnvVarInputs
              source='organisationEnvVar'
              label={t('resources.env_vars.name', { smart_count: 2 })}
              fullWidth
            />
          </FormTab>
        </TabbedForm>
      </UnauthorizedRecordContextModifier>
    </StyledEdit>
  );
};

const Toolbar = props => {
  const record = useRecordContext();

  return (
    <RaToolbar
      {...props}
      style={{ display: 'flex', justifyContent: 'space-between' }}
    >
      <SaveButton label='ra.action.save' redirect='edit' />
      {record && !record.isDefault && <DeleteDialog />}
    </RaToolbar>
  );
};

const BreadcrumbsLabel = props => {
  const { data } = props;

  if (data['@type'] === 'Organisation') {
    return (
      <RecordContextProvider value={data}>
        <OrganisationField />
      </RecordContextProvider>
    );
  } else {
    return data.name || null;
  }
};

const NameField = props => {
  const record = useRecordContext();
  const unique = useUnique();

  if (record?.isDefault) {
    return (
      <Labeled label='Name' sx={{ mb: 1 }}>
        <OrganisationField {...props} />
      </Labeled>
    );
  } else {
    return (
      <TextInput
        {...props}
        source={'name'}
        validate={[unique(), required()]}
        helperText={false}
      />
    );
  }
};

const PREFIX = 'OrganisationEdit';

const classes = {
  general: `${PREFIX}-general`
};

const StyledEdit = styled(Edit)(({ theme }) => ({
  [`& .${classes.general}`]: {
    ...OneColumnFormStyles(theme)
  }
}));

import React, { useRef } from 'react';
import { Grid, styled } from '@mui/material';
import { Stack } from '@mui/system';
import {
  //  RecentEnvironments,
  RecentProjects,
  UserCosts
} from './components';
import { Welcome } from './Welcome';
import { ListContextProvider, useGetList, useTranslate } from 'react-admin';
import { useOrgContext } from '@rc/admin/context';
import {
  CostAnalyzerChart,
  EnvironmentsUsageChart
} from '@rc/admin/components';

const Dashboard = () => {
  const t = useTranslate();
  const { teamId } = useOrgContext();
  const hasTeamIdChanged = useTeamIdChanged(teamId);

  const projectsListProps = useGetList(
    'projects',
    {
      pagination: { page: 1, perPage: 10 },
      sort: { order: 'DESC', field: 'updatedAt' },
      filter: { team: teamId }
    },
    {
      enabled: !!teamId,
      keepPreviousData: !hasTeamIdChanged
    }
  );

  const hasProjects = !!projectsListProps.data?.length;

  return (
    <StyledStack gap={0}>
      <Grid container rowSpacing={4} columnSpacing={1}>
        {hasProjects && (
          <Grid item xs={12} md={4}>
            <EnvironmentsUsageChart title='Environment Costs' />
          </Grid>
        )}
        {hasProjects && (
          <Grid item xs={12} md={8}>
            <CostAnalyzerChart title='Cost Analyzer' />
          </Grid>
        )}
        <Grid item xs={12}>
          <ListContextProvider value={projectsListProps}>
            <RecentProjects EmptyComponent={Welcome} />
          </ListContextProvider>
        </Grid>
      </Grid>
      {hasProjects && <UserCosts />}
    </StyledStack>
  );
};

// const PREFIX = 'Dashboard';

// const classes = {};

const StyledStack = styled(Stack)(({ theme }) => ({
  width: '100%'
}));

const useTeamIdChanged = teamId => {
  const previousTeamIdRef = useRef(teamId);

  if (previousTeamIdRef.current !== teamId) {
    previousTeamIdRef.current = teamId;
  }

  return previousTeamIdRef.current !== teamId;
};

export default Dashboard;

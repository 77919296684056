import React from 'react';
import { Avatar } from '@rc/admin/components';
import { useRecordContext } from 'react-admin';

/**
 *
 * @param {import('@rc/admin/components/ui/Avatar/Avatar').AvatarProps} props
 * @returns
 */
export const AvatarField = props => {
  const { sx, ...rest } = props;
  const record = useRecordContext();
  const { code, color } = record || {};

  return (
    <Avatar
      code={code}
      color={color}
      sx={theme => ({
        width: 54,
        height: 54,
        fontSize: 24,
        borderRadius: `${theme.shape.borderRadius}px !important`,
        ...(typeof sx === 'function' ? sx(theme) : sx)
      })}
      {...rest}
    />
  );
};

AvatarField.defaultProps = {
  sx: {}
};

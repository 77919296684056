import React from 'react';
import { OpenInBrowser } from '@mui/icons-material';
import { Box, IconButton, InputAdornment, TextField } from '@mui/material';
import { useRecordContext, useTranslate } from 'react-admin';
import _ from 'lodash';

export const EnvironmentUrlField = () => {
  const t = useTranslate();
  const record = useRecordContext();

  if (!record || _.isEmpty(record?.url)) {
    return null;
  }

  return (
    <Box sx={{ maxWidth: 350 }}>
      <TextField
        value={record.url}
        variant={'filled'}
        label={t('resources.environments.fields.url')}
        helperText={t('resources.environments.fields.url_helper_text')}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton
                edge='end'
                color='primary'
                onClick={() =>
                  window.open(
                    record.url.includes('http')
                      ? record.url
                      : `https://${record.url}`,
                    'blank'
                  )
                }
              >
                <OpenInBrowser />
              </IconButton>
            </InputAdornment>
          )
        }}
        disabled
      />
    </Box>
  );
};

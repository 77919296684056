import React, { useState } from 'react';
import { minLength, useTranslate } from 'react-admin';
import {
  Box,
  IconButton,
  InputAdornment,
  Stack,
  inputAdornmentClasses
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { FieldInput, PasswordStrengthMeter } from '@rc/admin/components';
import {
  validateEmail,
  validateFullname,
  validatePasswordStrength,
  validateUsername
} from '@rc/admin/utils/form';
import {
  useEmailExistanceValidation,
  useUsernameExistanceValidation
} from '@rc/admin/hooks';

/**
 *
 * @param {object} props
 * @param {boolean} props.steps - steps
 * @param {string} props.step - current step
 * @param {import('react-hook-form').UseFormReturn} props.formProps - useForm props
 * @param {boolean} props.isLoading - loading state
 * @returns
 */
export const CredentialFields = props => {
  const {
    formProps: {
      control,
      watch,
      errors,
      trigger,
      formState: { isSubmitted }
    }
  } = props;

  const t = useTranslate();
  const checkUsernameExistence = useUsernameExistanceValidation();
  const checkEmailExistence = useEmailExistanceValidation();
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Stack spacing={1}>
      <FieldInput
        name={'fullname'}
        label={t('ra.auth.fullname')}
        control={control}
        rules={{
          required: 'ra.validation.required',
          validate: validateFullname
        }}
        onBlur={isSubmitted ? () => trigger('fullname') : undefined}
        required
      />
      <FieldInput
        name={'email'}
        label={t('ra.auth.email')}
        control={control}
        rules={{
          required: 'ra.validation.required',
          validate: {
            email: validateEmail,
            emailExists: checkEmailExistence
          }
        }}
        onBlur={isSubmitted ? () => trigger('email') : undefined}
        required
      />
      <FieldInput
        name={'username'}
        label={t('ra.auth.username')}
        errors={errors}
        control={control}
        rules={{
          required: 'ra.validation.required',
          validate: {
            username: validateUsername,
            usernameExists: checkUsernameExistence
          }
        }}
        onBlur={isSubmitted ? () => trigger('username') : undefined}
        required
      />
      <Box sx={{ mb: 1.5 }}>
        <FieldInput
          control={control}
          name={'password'}
          label={t('ra.auth.password')}
          type={showPassword ? 'text' : 'password'}
          rules={{
            required: 'ra.validation.required',
            validate: {
              strength: validatePasswordStrength,
              length: value => minLength(8)(value)?.message
            }
          }}
          onBlur={isSubmitted ? () => trigger('password') : undefined}
          required
          InputProps={{
            sx: {
              [`& .${inputAdornmentClasses.positionEnd} button`]: {
                marginRight: '-6px'
              }
            },
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  aria-label='toggle password visibility'
                  onClick={() => setShowPassword(!showPassword)}
                  onMouseDown={e => e.preventDefault()}
                  edge='end'
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            )
          }}
        />
        <PasswordStrengthMeter password={watch('password')} sx={{ mt: 1 }} />
      </Box>
    </Stack>
  );
};

function transform (data) {
  data?.environmentCustomDomain?.forEach(domain => {
    if (!domain['@id'] && domain.certificateData) {
      domain.certificateData = window.btoa(domain.certificateData);
      domain.certificateKey = window.btoa(domain.certificateKey);
    }
  });

  return data;
}

export function environmentCreateTransform (data) {
  return transform(data);
}

export function environmentEditTransform (data) {
  return transform(data);
}

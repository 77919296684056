import React from 'react';
import {
  BooleanField,
  Datagrid,
  List,
  SimpleList,
  TextField
} from 'react-admin';
import { EditButton } from '@rc/admin/ra';

import { Stack, useMediaQuery } from '@mui/material';
import { AdministrateButton } from '@rc/admin/resources/user/components';

export const UserList = () => {
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

  return (
    <List perPage={25} exporter={false}>
      {isSmall ? (
        <SimpleList
          primaryText={() => <TextField source='username' />}
          secondaryText={() => <TextField source='email' />}
          tertiaryText={() => <BooleanField source={'isEnabled'} />}
        />
      ) : (
        <Datagrid width={'100vw'}>
          <TextField source='username' />
          <TextField source='email' />
          <TextField source='fullname' />
          <BooleanField source='isEnabled' />
          <Stack
            flexDirection={'row'}
            gap={1}
            justifyContent={'flex-end'}
            alignItems={'center'}
          >
            <AdministrateButton size='small' />
            <EditButton size='small' />
          </Stack>
        </Datagrid>
      )}
    </List>
  );
};

import React from 'react';
import { Card, CardContent, Stack } from '@mui/material';
import { SectionTitle } from '@rc/admin/components';
import { TwoColumnFormStyles } from '@rc/admin/theme/styles';
import {
  Form,
  RecordContextProvider,
  ResourceContextProvider,
  Toolbar,
  useEditController,
  useTranslate
} from 'react-admin';
import { DataExportActions, DataExportList } from '../components';
import { useFormRootPath } from './utils';
import { RCWorkerRecordContextProvider } from '@rc/admin/context';

export const EnvironmentExport = props => {
  const t = useTranslate();
  const formRootPathname = useFormRootPath();
  const controllerProps = useEditController(props);
  const { resource, record } = controllerProps;

  return (
    <ResourceContextProvider value={resource}>
      <RecordContextProvider value={record}>
        <Stack
          sx={theme => ({ ...TwoColumnFormStyles, rowGap: theme.spacing(2) })}
        >
          <Form formRootPathname={formRootPathname}>
            <Card sx={{ overflow: 'visible' }}>
              <CardContent>
                <SectionTitle>
                  {t('resources.exported_datas.table_title')}
                </SectionTitle>
                <RCWorkerRecordContextProvider>
                  <DataExportList />
                </RCWorkerRecordContextProvider>
              </CardContent>
              <Toolbar>
                <DataExportActions />
              </Toolbar>
            </Card>
          </Form>
        </Stack>
      </RecordContextProvider>
    </ResourceContextProvider>
  );
};

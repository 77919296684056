import { useCallback } from 'react';
import { useGetList } from 'react-admin';

export const useResourceTypes = () => {
  const { data: resourceTypes, isLoading } = useGetList('resource_types');

  const getResourceType = useCallback(
    name => {
      // Expect that there is a single resourceType with the current name
      return resourceTypes?.find(resourceType => resourceType.name === name);
    },
    [resourceTypes]
  );

  return { isLoading, resourceTypes, getResourceType };
};

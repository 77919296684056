import { useFormContext } from 'react-hook-form';
import { STATE_IDS } from '@rc/admin/constants';
import { useStateId } from './useStateId';

/**
 * Determines whether the Environment or Project in a state when the user can retry the previous action.
 *
 * @returns {boolean}
 */
export const useCanRetry = () => {
  const stateId = useStateId();
  const {
    formState: { isDirty }
  } = useFormContext();

  const canRetry =
    !isDirty &&
    (stateId === STATE_IDS.SERVER_ERROR || stateId === STATE_IDS.CLIENT_ERROR);

  return canRetry;
};

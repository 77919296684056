import React, { Children } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { Delete } from '@mui/icons-material';
import {
  Datagrid,
  ReferenceManyField,
  TextField,
  useDelete,
  useGetResourceLabel,
  useRecordContext,
  useRefresh,
  useTranslate
} from 'react-admin';
import { useNavigate } from 'react-router-dom';

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

export const DeleteDialog = () => {
  const record = useRecordContext();
  const navigate = useNavigate();
  const t = useTranslate();
  const getResourceLabel = useGetResourceLabel();
  const [open, setOpen] = React.useState(false);
  const [deleteOne] = useDelete('organisations', {
    id: record?.id,
    previousData: record
  });
  const refresh = useRefresh();

  const handleAgree = async () => {
    await deleteOne();
    navigate('/organisations');
    refresh();
  };

  return (
    <>
      <Button
        variant={'text'}
        color={'error'}
        startIcon={<Delete />}
        onClick={() => setOpen(!open)}
      >
        {t('ra.action.delete')}
      </Button>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpen(!open)}
        scroll={'paper'}
        aria-describedby={'Delete confirmation dialog'}
        maxWidth={'xl'}
        // fullWidth={true}
      >
        <DialogTitle variant='h4'>
          {t('ra.message.bulk_delete_content', {
            name: 'Organisation',
            smart_count: 1
          })}
          <Typography
            component={'span'}
            display={'block'}
            dangerouslySetInnerHTML={{
              __html: t('resources.organisations.message.delete_warning')
            }}
          />
        </DialogTitle>
        <DialogContent>
          <DialogContentText component={'div'}>
            <ReferenceManyField reference='teams' target='organisation'>
              <Datagrid bulkActionButtons={false} header={SimpleHeader}>
                <TextField
                  source='name'
                  label={t('misc.affected', {
                    name: getResourceLabel('teams', 2)
                  })}
                />
                <ReferenceManyField
                  reference='projects'
                  target='team'
                  label={getResourceLabel('projects', 2)}
                >
                  <Datagrid bulkActionButtons={false} header={NullHeader}>
                    <TextField source='name' />
                  </Datagrid>
                </ReferenceManyField>
              </Datagrid>
            </ReferenceManyField>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(!open)}>
            {t('ra.boolean.false')}
          </Button>
          <Button onClick={handleAgree}>{t('ra.boolean.true')}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const NullHeader = () => null;

const SimpleHeader = ({ children }) => (
  <TableHead>
    <TableRow>
      {Children.map(children, child => (
        <TableCell key={child.props.source}>{child.props.label}</TableCell>
      ))}
    </TableRow>
  </TableHead>
);

export default DeleteDialog;

import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useGetList } from 'react-admin';
import { useNavigate } from 'react-router';
import {
  ORG_LOCAL_STORAGE_KEY,
  PREFETCH_PAGINATION,
  ID_SORT
} from '../constants';
import useMercureSubscription from '../moduleOverrides/useMercureSubscription';

/**
 * @typedef {{organisationId: string, teamId: string}} OrgContextState
 * @typedef {{ submit: (state: OrgContextState) => void,
 *      organisations: any[],
 *      teams: any[],
 *      organisation: any,
 *      team: any,
 *      isLoading: boolean,
 *    } & OrgContextState} OrgContextValue
 *
 * @type {import('react').Context<OrgContextValue>}
 */
const OrgContext = createContext();

export const OrgContextProvider = props => {
  const { children } = props;
  const navigate = useNavigate();

  /**
   * @type {[OrgContextState, OrgContextState => void]}
   */
  const [orgState, setOrgState] = useState({
    organisationId: null,
    teamId: null
  });

  const {
    data: organisations,
    isLoading: isOrganisationsLoading,
    refetch: refetchOrganisations
  } = useGetList('organisations', {
    pagination: PREFETCH_PAGINATION,
    sort: ID_SORT
  });
  const {
    data: teams,
    isLoading: isTeamsLoading,
    refetch: refetchTeams
  } = useGetList('teams', {
    pagination: PREFETCH_PAGINATION,
    sort: ID_SORT
  });

  useMercureSubscription('organisations', orgState.organisationId, {
    onReceived: () => refetchOrganisations()
  });
  useMercureSubscription('teams', orgState.teamId, {
    onReceived: () => refetchTeams()
  });

  const submit = useCallback(
    newOrg => {
      setOrgState(curr => {
        const hasChanged =
          curr.organisationId &&
          curr.organisationId !== newOrg.organisationId &&
          curr.teamId !== newOrg.teamId;

        if (hasChanged) {
          navigate('/');
        }

        return newOrg;
      });

      window.localStorage.setItem(
        ORG_LOCAL_STORAGE_KEY,
        JSON.stringify(newOrg)
      );
    },
    [navigate, setOrgState]
  );

  // Initialize with default value
  useEffect(() => {
    if (organisations && teams) {
      const storedOrg = getStoredOrg(organisations, teams);

      let org = storedOrg;

      if (!org && teams.length) {
        const defaultTeam = teams[0];
        org = {
          organisationId: defaultTeam.organisation,
          teamId: defaultTeam.id
        };
      }

      if (org) {
        submit(org);
      }
    }
  }, [submit, organisations, teams]);

  const value = useMemo(() => {
    const organisation =
      organisations?.find(
        org => String(org.id) === String(orgState.organisationId)
      ) || {};
    const team =
      teams?.find(team => String(team.id) === String(orgState.teamId)) || {};

    return {
      ...orgState,
      organisation,
      team,
      organisations: organisations || [],
      teams: teams || [],
      isLoading: isOrganisationsLoading || isTeamsLoading,
      submit,
    };
  }, [
    isOrganisationsLoading,
    isTeamsLoading,
    orgState,
    organisations,
    submit,
    teams
  ]);

  return <OrgContext.Provider value={value}>{children}</OrgContext.Provider>;
};

const getStoredOrg = (organisations, teams) => {
  const storedOrg = window.localStorage.getItem(ORG_LOCAL_STORAGE_KEY);

  try {
    const org = JSON.parse(storedOrg);

    const isValidOrganisationId =
      org.hasOwnProperty('organisationId') &&
      organisations.find(
        organisation => String(organisation.id) === String(org.organisationId)
      );
    const isValidTeamId =
      org.hasOwnProperty('teamId') &&
      teams.find(team => String(team.id) === String(org.teamId));

    if (isValidOrganisationId && isValidTeamId) {
      return org;
    }
  } catch (error) {
    return null;
  }

  return null;
};

export const useOrgContext = () => useContext(OrgContext);

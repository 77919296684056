import { tabClasses } from '@mui/material/Tab';

// ----------------------------------------------------------------------

export function tabs (theme) {
  return {
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: theme.palette.text.primary
        },
        scrollButtons: {
          width: 48,
          borderRadius: '50%',
          [`&.Mui-disabled:first-of-type`]: {
            marginLeft: theme.spacing(-5)
          }
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          padding: 0,
          opacity: 1,
          minWidth: 48,
          minHeight: 48,
          fontWeight: theme.typography.fontWeightSemiBold,
          '&:not(:last-of-type)': {
            marginRight: theme.spacing(3),
            [theme.breakpoints.up('sm')]: {
              marginRight: theme.spacing(5)
            }
          },
          [`&:not(.${tabClasses.selected})`]: {
            color: theme.palette.text.secondary
          }
        }
      }
    }
  };
}

import React from 'react';
import PropTypes from 'prop-types';
import { Box, CircularProgress, styled } from '@mui/material';

export const LoadingBox = props => {
  const { minHeight = 100, size } = props;
  return (
    <StyledBox sx={{ minHeight }}>
      <CircularProgress size={size} />
    </StyledBox>
  );
};

LoadingBox.propTypes = {
  minHeight: PropTypes.number,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

const StyledBox = styled(Box)(() => ({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center'
}));

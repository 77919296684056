import React, { createContext, useCallback, useContext, useState } from 'react';
import { RecordContextProvider, useRecordContext } from 'react-admin';

/**
 * @type {import('react').Context<[{ messages: Map },{ addMessage: (key: string, value: any) => void }]>}
 */
const MercureMessagesContext = createContext();

export const RCWorkerMessagesContextProvider = props => {
  const [messages, setMessages] = useState(new Map());

  const addMessage = useCallback((key, value) => {
    setMessages(curr => {
      const newMap = new Map(curr);
      newMap.set(key, value);
      return newMap;
    });
  }, []);

  return (
    <MercureMessagesContext.Provider value={[{ messages }, { addMessage }]}>
      {props.children}
    </MercureMessagesContext.Provider>
  );
};

export const useRCWorkerMessagesContext = () =>
  useContext(MercureMessagesContext);

/**
 *
 * @returns {import('react-admin').RaRecord?}
 */
export const useRCWorkerMessageRecord = () => {
  const record = useRecordContext();
  const [{ messages }] = useRCWorkerMessagesContext();

  return record && messages.get(record.id);
};

/**
 * Get's the record from the RCWorkerMessagesContext if it exists and returns a RecordContextProvider with that record.
 *
 * @param {object} props
 * @param {import('react-admin').RaRecord} props.record
 * @returns
 */
export const RCWorkerRecordContextProvider = props => {
  const { record: recordProp } = props;
  const messageRecord = useRCWorkerMessageRecord();
  const record = useRecordContext(recordProp);

  return (
    <RecordContextProvider value={messageRecord || record}>
      {props.children}
    </RecordContextProvider>
  );
};

import React, { useCallback, useRef, useState } from 'react';
import {
  useNotify,
  // useRedirect,
  useTranslate
} from 'react-admin';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import {
  CSSTransition,
  SwitchTransition,
  TransitionGroup
} from 'react-transition-group';
import clsx from 'clsx';
import { LoadingButton } from '@mui/lab';
import {
  CardActions,
  Collapse,
  Link as MuiLink,
  Stack,
  Typography,
  styled
} from '@mui/material';
import { CheckboxFieldInput } from '@rc/admin/components';
import { BRAND_NAME, GRECAPTCHA_ENABLED } from '@rc/admin/constants';
import { useAxios } from '@rc/utils/axios';
import { CodeFields } from './CodeFields';
import { CredentialFields } from './CredentialFields';

const CLOSED_BETA_ENABLED = true;

const STEPS = CLOSED_BETA_ENABLED
  ? ['credentials', 'code', 'success']
  : ['credentials', 'success'];

const RegisterForm = () => {
  const t = useTranslate();
  const notify = useNotify();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [step, setStep] = useState(STEPS[0]);
  const isSuccess = STEPS.indexOf(step) === STEPS.length - 1;
  const isLastStep = STEPS.indexOf(step) === STEPS.length - 2;

  const formProps = useForm({
    defaultValues: {
      fullname: '',
      email: '',
      username: '',
      password: '',
      ...(CLOSED_BETA_ENABLED ? { access_code: '' } : {})
    }
  });
  const {
    handleSubmit,
    control,
    formState: { errors },
    trigger
  } = formProps;

  const [{ loading: isLoading }, register] = useAxios(
    { url: '/api/users/register', method: 'POST' },
    {
      manual: true
    }
  );

  const onSubmit = useCallback(
    async data => {
      if (!(await trigger())) return;

      let recaptchaToken = null;
      if (GRECAPTCHA_ENABLED && !executeRecaptcha) {
        console.error('Error on captcha');
        return;
      } else if (executeRecaptcha) {
        recaptchaToken = await executeRecaptcha('register');
      }

      const { __agreement: agreement, ...submitData } = data;

      await register({
        data: submitData,
        headers: {
          'g-recaptcha-token': recaptchaToken
        }
      })
        .then(() => {
          setStep(STEPS[STEPS.indexOf(step) + 1]);
        })
        .catch(error => {
          const message =
            error?.response?.data?.['hydra:description'] ||
            t('pages.register.notification.error');

          notify(message, {
            type: 'error',
            undoable: false
          });
        });
    },
    [executeRecaptcha, notify, register, step, t, trigger]
  );

  const onNext = useCallback(async () => {
    if (!(await trigger())) return;

    setStep(STEPS[STEPS.indexOf(step) + 1]);
  }, [step, trigger]);

  const credentialsRef = useRef(null);
  const codeRef = useRef(null);
  const successRef = useRef(null);
  const nodeRef =
    step === 'credentials'
      ? credentialsRef
      : step === 'code'
      ? codeRef
      : successRef;

  return (
    <StyledForm
      className={clsx(classes.form, { [classes.success]: isSuccess })}
      autoComplete='off'
      onSubmit={handleSubmit(isLastStep ? onSubmit : onNext)}
      noValidate
    >
      <SwitchTransition mode={'out-in'}>
        <CSSTransition
          key={step}
          nodeRef={nodeRef}
          addEndListener={done => {
            nodeRef.current.addEventListener('transitionend', done, false);
          }}
          classNames={'fade'}
        >
          <div ref={nodeRef}>
            {step === 'credentials' ? (
              <>
                <Title />
                <CredentialFields
                  steps={STEPS}
                  step={step}
                  formProps={formProps}
                  isLoading={isLoading}
                />
              </>
            ) : step === 'code' ? (
              <>
                <Title showLogin={false} />
                <CodeFields
                  steps={STEPS}
                  step={step}
                  formProps={formProps}
                  isLoading={isLoading}
                />
              </>
            ) : (
              <div>
                <Title isSuccess showLogin={false} />
                <Typography variant='subtitle1' component={'div'}>
                  {t('pages.register.notification.success')}
                </Typography>
              </div>
            )}

            {isLastStep && (
              <CheckboxFieldInput
                name={'__agreement'}
                errors={errors}
                control={control}
                rules={{
                  required: 'ra.validation.required'
                }}
                label={
                  <Typography mt={2} variant='body2' color='text.secondary'>
                    {t('pages.register.agreement')}
                    <MuiLink
                      href={'/terms-of-service'}
                      target='_blank'
                      rel='noopener noreferrer'
                      fontWeight={500}
                    >
                      {t('pages.register.terms_of_service')}
                    </MuiLink>{' '}
                    {t('misc.and')}{' '}
                    <MuiLink
                      href={'/privacy-policy'}
                      target='_blank'
                      rel='noopener noreferrer'
                      fontWeight={500}
                    >
                      {t('pages.register.privacy_policy')}
                    </MuiLink>
                    {'.'}
                  </Typography>
                }
              />
            )}

            {!isSuccess && (
              <CardActions className={classes.actions}>
                <LoadingButton
                  variant='contained'
                  type={'submit'}
                  color='primary'
                  size='large'
                  fullWidth
                  loading={isLoading}
                >
                  {t(
                    isLastStep ? 'pages.register.action.sign_up' : 'action.next'
                  )}
                </LoadingButton>
              </CardActions>
            )}
          </div>
        </CSSTransition>
      </SwitchTransition>
    </StyledForm>
  );
};

/**
 *
 * @param {object} props
 * @param {boolean} props.isSuccess
 * @param {boolean} props.showLogin
 * @returns
 */
const Title = ({ isSuccess, showLogin = true }) => {
  const t = useTranslate();

  return (
    <Stack spacing={1} sx={{ mb: 2, position: 'relative' }}>
      <Typography variant='h4'>
        {isSuccess
          ? t('pages.register.success_title')
          : t('pages.register.title', { name: BRAND_NAME })}
      </Typography>
      <TransitionGroup>
        <Collapse>
          {showLogin && (
            <Stack direction='row' spacing={0.5}>
              <Typography variant='body2'>
                {t('pages.register.action.log_in')}
              </Typography>

              <MuiLink to={'/login'} component={Link} variant='subtitle2'>
                {t('pages.register.action.log_in_link')}
              </MuiLink>
            </Stack>
          )}
        </Collapse>
      </TransitionGroup>
    </Stack>
  );
};

const PREFIX = 'LoginForm';

const classes = {
  avatar: `${PREFIX}-avatar`,
  actions: `${PREFIX}-actions`,
  success: `${PREFIX}-success`
};

const StyledForm = styled('form')(({ theme }) => ({
  padding: '1em',

  [`& .${classes.avatar}`]: {
    margin: '1em',
    display: 'flex',
    justifyContent: 'center'
  },

  [`& .${classes.actions}`]: {
    marginTop: theme.spacing(2.5),
    padding: 0
  },

  [`&.${classes.success}`]: {
    padding: theme.spacing(3)
  }
}));

export default RegisterForm;

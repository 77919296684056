import { styled } from '@mui/material';
import { useHasPermission } from '@rc/admin/hooks';
import React, { forwardRef } from 'react';
import { MenuItemLink as RaMenuItemLink } from 'react-admin';
import { TreeMenuItemLink } from './TreeMenuItemLink';

export const MenuItemLink = forwardRef((props, ref) => {
  const { resource, childResource, to, ...rest } = props;

  const { hasPermission, isLoading } = useHasPermission(resource.name);

  if (!hasPermission || isLoading) {
    return null;
  }

  if (resource?.options?.child) {
    return (
      <TreeMenuItemLink
        {...rest}
        resource={resource}
        childResource={childResource}
        ref={ref}
      />
    );
  } else {
    return <StyledRaMenuItemLink {...rest} to={to} ref={ref} />;
  }
});

const StyledRaMenuItemLink = styled(RaMenuItemLink)(({ theme }) => ({
  color: theme.palette.text.primary,
  '&.RaMenuItemLink-active': {
    ...(theme.components?.MuiListItemButton?.styleOverrides?.root[
      '&.Mui-selected'
    ] || {})
  }
}));

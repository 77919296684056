import { useState, useCallback } from 'react';

// ----------------------------------------------------------------------

export default function usePopover () {
  const [open, setOpen] = useState(null);

  const onOpen = useCallback(event => {
    event.stopPropagation();
    setOpen(event.currentTarget);
  }, []);

  const onClose = useCallback(() => {
    setOpen(null);
  }, []);

  return {
    open,
    onOpen,
    onClose,
    setOpen
  };
}

import React from 'react';
import { useGetOne, useRecordContext } from 'react-admin';
import { Skeleton, Typography } from '@mui/material';

/**
 *
 * @param {object} props
 * @param {string} props.source
 * @param {any} props.record
 * @param {string} props.className
 * @param {boolean} props.hideVersionNumber
 * @param {import('@mui/material').TypographyProps['variant']} props.variant
 * @returns
 */
export const ProjectTypeVersionField = props => {
  const {
    source,
    record: recordProp,
    className,
    hideVersionNumber = false,
    variant = 'body2'
  } = props;
  const record = useRecordContext() || recordProp;
  const { data: projectType, isLoading: isProjectTypeLoading } = useGetOne(
    'project_types',
    { id: record?.projectType },
    { enabled: !!record }
  );

  if (!record || isProjectTypeLoading) {
    return <Skeleton variant='text' />;
  }

  return (
    <Typography variant={variant} className={className}>
      {`${projectType.name} ${!hideVersionNumber ? record[source] : ''}`}
    </Typography>
  );
};

import React from 'react';
import { ResourceInput, LoadingField } from '@rc/admin/components';
import { useResourceTypes } from '@rc/admin/hooks';
import { isNumber } from 'lodash';

/**
 *
 * @param {import('@rc/admin/components/domain/ResourceInput/resourceInput').ResourceInputProps} props
 * @returns
 */
export const ProjectResourceInput = props => {
  const { source, min: minProp, max: maxProp, ...rest } = props;
  const { getResourceType, isLoading } = useResourceTypes();
  const resourceType = getResourceType(source);

  const { projectMinValue, projectMaxValue } =
    resourceType?.resourceTypeLimit || {};

  if (isLoading) {
    return <LoadingField />;
  }

  const min = isNumber(projectMinValue) ? projectMinValue : minProp;
  const max = isNumber(projectMaxValue) ? projectMaxValue : maxProp;

  return (
    <ResourceInput
      source={source}
      min={min}
      max={max}
      defaultValue={max}
      {...rest}
    />
  );
};

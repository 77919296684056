import merge from 'lodash/merge';

import { createTheme } from '@mui/material/styles';

// system
import { palette } from './theme/palette';
import { shadows } from './theme/shadows';
import { typography } from './theme/typography';
// options
import { customShadows } from './theme/custom-shadows';
import { componentsOverrides } from './theme/overrides';
import { createPresets } from './theme/options/presets';
import { createContrast } from './theme/options/contrast';

// ----------------------------------------------------------------------

/**
 *
 * @typedef {object} MinimalThemeSettings
 * @property {boolean} themeStretch
 * @property {'light' | 'dark'} themeMode
 * @property {'rtl' | 'ltr'} themeDirection
 * @property {'default' | 'bold'} themeContrast
 * @property {'vertical' | 'horizontal' | 'mini'} themeLayout
 * @property {'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'} themeColorPresets
 *
 * @param {MinimalThemeSettings} settings
 */
export default function getTheme (settings) {
  const presets = createPresets(settings.themeColorPresets);

  const contrast = createContrast(settings.themeContrast, settings.themeMode);

  const value = {
    palette: {
      ...palette(settings.themeMode),
      ...presets.palette,
      ...contrast.palette
    },
    customShadows: {
      ...customShadows(settings.themeMode),
      ...presets.customShadows
    },
    direction: settings.themeDirection,
    shadows: shadows(settings.themeMode),
    shape: { borderRadius: 8 },
    typography: {
      ...typography,
      fontFamily: `'Lato', sans-serif`
    }
  };

  const theme = createTheme(value);

  theme.components = merge(componentsOverrides(theme), contrast.components);

  return theme;
}

import React, { useCallback } from 'react';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography
} from '@mui/material';
import { Button, useNotify, useTranslate } from 'react-admin';
import { CardNumber } from './utils';
import { useAxios } from '@rc/utils/axios';
import { Delete } from '@mui/icons-material';

export const PaymentMethodDetails = props => {
  const { method, customer, onDelete } = props;
  const t = useTranslate();
  const notify = useNotify();

  const [{ loading: isDeleteLoading }, executeDelete] = useAxios(
    {},
    { manual: true }
  );

  const handleDelete = useCallback(async () => {
    const { id: userId } = JSON.parse(window.localStorage.getItem('user'));
    try {
      await executeDelete({
        url: `/api/users/${userId}/finance/detach-card`,
        method: 'PATCH',
        params: { id: userId, paymentMethodId: method?.id },
        headers: {
          Accept: 'application/merge-patch+json',
          'Content-Type': 'application/merge-patch+json'
        },
        data: {}
      });

      onDelete();
      notify(t('pages.billing.method.notification.successful_delete'), {
        type: 'success'
      });
    } catch (error) {
      console.error(error);
      notify(error?.message, { type: 'error' });
    }
  }, [executeDelete, method, notify, onDelete, t]);

  if (!method || !customer) {
    return null;
  }

  const {
    card,
    billing_details: {
      name,
      email,
      phone,
      address: { city, country, line1, line2, postal_code: postalCode, state }
    }
  } = method;

  return (
    <>
      <DialogTitle variant='h4'>
        {t('pages.billing.method.fields.details')}
      </DialogTitle>
      <DialogContent>
        <Grid container sx={{ width: '100%' }}>
          <Grid item xs={12} sm={6} mb={2}>
            <Typography variant={'h5'} component={'div'} mt={1} mb={1}>
              {t('pages.billing.method.fields.holder')}
            </Typography>
            <Typography variant={'span'} component={'div'}>
              {customer.name}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} mb={2}>
            <Typography variant={'h5'} component={'div'} mt={1} mb={1}>
              {t('pages.billing.method.fields.expires')}
            </Typography>
            <Typography variant={'span'} component={'div'}>
              {card.exp_month}/{card.exp_year}{' '}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} mb={2}>
            <Typography variant={'h5'} component={'div'} mt={1} mb={1}>
              {t('pages.billing.method.fields.card_number')}
            </Typography>
            <Typography variant={'span'} component={'div'}>
              <CardNumber value={card.last4} />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant={'h5'} component={'div'} mt={1} mb={1}>
              {t('pages.billing.method.fields.billing_address')}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant={'body1'}>{name}</Typography>
            <Typography variant={'body1'}>{email}</Typography>
            <Typography variant={'body1'}>{phone}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            {line1 && <Typography variant={'body1'}>{line1}</Typography>}{' '}
            {line2 && <Typography variant={'body1'}>{line2}</Typography>}
            <Typography variant={'body1'}>{city}</Typography>
            <Typography variant={'body1'}>
              {postalCode}, {state}
            </Typography>
            <Typography variant={'body1'}>{country}</Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ p: 1 }}>
        <Button
          onClick={handleDelete}
          label='ra.action.delete'
          color='error'
          variant='text'
          startIcon={<Delete />}
          disabled={isDeleteLoading}
        />
      </DialogActions>
    </>
  );
};

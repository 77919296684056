import React from 'react';
import { useGetOne, useRecordContext, useTranslate } from 'react-admin';
import { LinearProgress, Typography } from '@mui/material';

export const ClusterField = () => {
  const t = useTranslate();
  const record = useRecordContext();

  const { data: cluster, loading: isClusterLoading } = useGetOne(
    'clusters',
    { id: record.cluster },
    { enabled: !!record }
  );

  const { data: provider, loading: isProviderLoading } = useGetOne(
    'providers',
    { id: cluster?.provider },
    { enabled: !!cluster }
  );

  if (!record || isClusterLoading || !provider || isProviderLoading) {
    return <LinearProgress />;
  }

  return (
    <Typography>{`${provider?.name ||
      t('resources.products.message.no_provider')} | ${
      cluster?.name
    }`}</Typography>
  );
};

export default ClusterField;

import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { REDIRECT_TO_TEAM_LOCAL_STORAGE_KEY } from '@rc/admin/constants';

/**
 *
 * @returns {URLSearchParams}
 */
export const useSetDefaultTeam = () => {
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.has('redirectToTeam')) {
      window.localStorage.setItem(
        REDIRECT_TO_TEAM_LOCAL_STORAGE_KEY,
        searchParams.get('redirectToTeam')
      );
    }
  }, [searchParams]);

  return searchParams;
};

import { matchPath, useLocation } from 'react-router-dom';
import { useRouteMatch } from '@rc/admin/hooks';

// ----------------------------------------------------------------------

/**
 *
 * @param {string} path
 * @param {boolean} deep
 * @param {string[]} nestedPaths
 * @returns
 */
export const useActiveLink = (path, deep, nestedPaths = []) => {
  const isRouteMatch = useRouteMatch([
    `${path}${!path.endsWith('/') ? '/*' : ''}`,
    ...nestedPaths
  ]);

  const isActive = useIsActive(path, deep);

  return isRouteMatch || isActive;
};

export function useIsActive (path, deep = true) {
  const { pathname } = useLocation();

  const normalActive = path
    ? !!matchPath({ path, end: true }, pathname)
    : false;

  const deepActive = path ? !!matchPath({ path, end: false }, pathname) : false;

  return deep ? deepActive : normalActive;
}

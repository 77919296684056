import React from 'react';
import { Box } from '@mui/material';
import {
  CardList,
  ListWithMercure,
  MercureSubscriberForList,
  StateFilterSelectInput,
  CreatedAtFilter
} from '@rc/admin/components';
import { useOrgContext } from '@rc/admin/context';
import { ProjectCardContent } from './components';

const FILTERS = [
  <StateFilterSelectInput
    key={'filter-to-state'}
    source='state'
    reference='states'
  />,
  <CreatedAtFilter key={'filter-created-at'} source='createdAt.before' />
];

export const ProjectList = () => {
  const { teamId } = useOrgContext();

  if (!teamId) {
    return null;
  }

  return (
    <ListWithMercure
      filter={{ team: teamId }}
      filters={FILTERS}
      exporter={false}
      component={ListWrapper}
      sort={{ order: 'DESC', field: 'updatedAt' }}
      perPage={25}
    >
      <CardList
        enablePlaceholders
        placeholdersCount={4}
        render={(record, _, isPlaceholder) => (
          <ProjectCardContent record={record} isPlaceholder={isPlaceholder} />
        )}
      />
    </ListWithMercure>
  );
};

const ListWrapper = props => (
  <MercureSubscriberForList {...props} component={Box} />
);

import React from 'react';
import {
  ArrayInput,
  IconButtonWithTooltip,
  SimpleFormIterator,
  SimpleFormIteratorClasses,
  required,
  useArrayInput,
  useRecordContext,
  useSimpleFormIterator,
  useTranslate
} from 'react-admin';
import { Box, Menu, MenuItem, styled } from '@mui/material';
import AddIcon from '@mui/icons-material/AddCircleOutline';
import {
  uniqueHostname,
  validateCertificateData,
  validateCertificateKey,
  validateHostname
} from './utils';
import { HostnameInput } from './HostnameInput';
import { CertificateTextInput } from './CertificateTextInput';
import { useFormContext } from 'react-hook-form';
import { useEnvironmentAccessFrontendHostname } from '@rc/admin/hooks';

/**
 * @param {object} props
 * @param {string} props.source
 * @returns {JSX.Element}
 */
export const CustomDomainFields = props => {
  const { source } = props;

  return (
    <StyledArrayInput source={source} label=''>
      <SimpleFormIterator
        fullWidth
        disableClear
        disableReordering
        addButton={<AddButton />}
      >
        <HostnameInput
          source='hostname'
          validate={[required(), validateHostname, uniqueHostname(source)]}
          fullWidth
        />
        <CertificateTextInput
          source='certificateKey'
          validate={[
            requiredIf(source, 'certificateData'),
            validateCertificateKey
          ]}
          placeholder={`-----BEGIN PRIVATE KEY-----\n\n\n-----END PRIVATE KEY-----`}
          format={v => v?.trim()}
          helperText={false}
          multiline
          fullWidth
          sx={{ pb: 1 }}
        />
        <CertificateTextInput
          source='certificateData'
          validate={[
            requiredIf(source, 'certificateKey'),
            validateCertificateData
          ]}
          placeholder={`-----BEGIN CERTIFICATE-----\n\n\n-----END CERTIFICATE-----`}
          format={v => v?.trim()}
          helperText={false}
          multiline
          fullWidth
          sx={{ pb: 1 }}
        />
      </SimpleFormIterator>
    </StyledArrayInput>
  );
};

const AddButton = () => {
  const t = useTranslate();
  const { source, total } = useSimpleFormIterator();
  const { append } = useArrayInput();
  const { resetField } = useFormContext();
  const record = useRecordContext();
  const isEdit = !!record;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAdd = isSubdomain => {
    const defaultValue = {
      hostname: '',
      certificateKey: '',
      certificateData: ''
    };

    if (isSubdomain) {
      defaultValue.isSubdomain = true;
    }

    append(defaultValue);
    resetField(`${source}[${total}]`, { defaultValue });
    handleClose();
  };

  return (
    <Box>
      <IconButtonWithTooltip
        label='ra.action.add'
        size='small'
        onClick={handleClick}
        color='primary'
        // {...props}
      >
        <AddIcon fontSize='small' />
      </IconButtonWithTooltip>
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        <MenuItem key={'domain'} onClick={() => handleAdd()}>
          {t('resources.environments.action.add_domain')}
        </MenuItem>
        {isEdit && (
          <AddSubdomainMenuItem
            key={'subdomain'}
            onClick={() => handleAdd(true)}
          />
        )}
      </Menu>
    </Box>
  );
};

const AddSubdomainMenuItem = props => {
  const t = useTranslate();
  const {
    isLoading,
    hostname: frontendHostname
  } = useEnvironmentAccessFrontendHostname();

  if (isLoading || !frontendHostname) {
    return null;
  }

  return (
    <MenuItem {...props}>
      {t('resources.environments.action.add_subdomain')}
    </MenuItem>
  );
};

/**
 *
 * @param {string} source
 * @param {string} field
 * @returns
 */
export function requiredIf (source, field) {
  return (value, allValues, input) => {
    const [, index] = input.source.split('.').reverse();
    if (allValues[source][Number(index)][field]) {
      return required()(value);
    }
  };
}

const StyledArrayInput = styled(ArrayInput)(({ theme }) => ({
  [`& .${SimpleFormIteratorClasses.line}`]: {
    borderBottom: 'none !important',
    '&:not(:first-child)': {
      marginTop: theme.spacing(2)
    }
  }
}));

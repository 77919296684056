import React, { forwardRef, useState, useCallback } from 'react';
import { useTranslate } from 'react-admin';
import {
  styled,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from '@mui/material';
import { MenuItemButton } from './MenuItemLink';

export const DialogMenuItem = forwardRef((props, ref) => {
  const {
    dialogTitle,
    onClose,
    onAccept,
    leftIcon,
    rightIcon,
    primaryText,
    children,
    canAccept,
    title
  } = props;
  const t = useTranslate();
  const [open, setOpen] = useState(false);

  const handleClickOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(
    (_, reason) => {
      if (onClose) {
        onClose();
      }
      if (reason !== 'backdropClick') {
        setOpen(false);
      }
    },
    [onClose]
  );

  const handleOk = useCallback(() => {
    if (onAccept) {
      onAccept();
    }

    setOpen(false);
  }, [onAccept]);

  return (
    <Root hasLeftIcon={!!leftIcon}>
      <MenuItemButton
        ref={ref}
        className={DialogMenuItemClasses.menuItem}
        leftIcon={leftIcon}
        rightIcon={rightIcon}
        primaryText={primaryText}
        onClick={handleClickOpen}
        title={title}
      />
      <Dialog open={open} onClose={handleClose} maxWidth={'md'} fullWidth>
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant='outlined'>
            {t('action.cancel')}
          </Button>
          <Button onClick={handleOk} disabled={!canAccept}>
            {t('action.ok')}
          </Button>
        </DialogActions>
      </Dialog>
    </Root>
  );
});

const PREFIX = 'DialogMenuItem';

export const DialogMenuItemClasses = {
  menuItem: `${PREFIX}-menuItem`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div', {
  shouldForwardProp: prop => prop !== 'hasLeftIcon'
})(({ theme, hasLeftIcon }) => ({
  [`& .${DialogMenuItemClasses.menuItem}`]: {
    display: 'grid',
    gridTemplateColumns: hasLeftIcon ? `40px auto 24px` : `auto 24px`,
    justifyContent: 'stretch',
    paddingLeft: theme.spacing(3)
  }
}));

DialogMenuItem.displayName = 'SwitchTeam';

DialogMenuItem.defaultProps = {
  classes: {}
};

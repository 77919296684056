import React from 'react';
import { Card, styled } from '@mui/material';
import { ResetPasswordForm } from './Form';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { GRECAPTCHA_SITEKEY } from '@rc/admin/constants';

const ResetPassword = props => {
  return (
    <Root {...props}>
      <Card className={ResetPasswordClasses.card}>
        <GoogleReCaptchaProvider
          reCaptchaKey={GRECAPTCHA_SITEKEY}
          scriptProps={{
            async: true,
            defer: true
          }}
        >
          <ResetPasswordForm />
        </GoogleReCaptchaProvider>
      </Card>
    </Root>
  );
};

const PREFIX = 'ResetPassword';

export const ResetPasswordClasses = {
  card: `${PREFIX}-card`
};

const Root = styled('div', {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
  height: '1px',
  alignItems: 'center',
  justifyContent: 'flex-start',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundColor: theme.palette.background.default,
  backgroundImage: 'none',

  [`& .${ResetPasswordClasses.card}`]: {
    minWidth: 300,
    marginTop: '6em',
    padding: theme.spacing(1),
    maxWidth: 420,
    width: '100%'
  }
}));

export default ResetPassword;

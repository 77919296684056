import React, { useCallback } from 'react';
import { ReferenceInput, required, useGetList } from 'react-admin';
import { GroupedSelectInput } from '@rc/admin/components';
import { useRequiredComponents } from '../../hooks';
import { PREFETCH_PAGINATION, VERSION_SORT } from '@rc/admin/constants';

export const EnvironmentComponentVersionInput = (props) => {
  const { source, ...rest } = props;

  const { data: componentVersions, isLoading: isComponentVersionsLoading } =
    useGetList('component_versions', {
      pagination: PREFETCH_PAGINATION,
      sort: VERSION_SORT,
    });

  const {
    requiredComponents: requiredComponentIds,
    isLoading: isRequiredComponentsLoading,
  } = useRequiredComponents();

  const validateEnvironmentComponentVersion = useCallback(
    (componentVersion, { environmentComponent }) => {
      if (isComponentVersionsLoading || !componentVersion) {
        return;
      }

      const hasDuplicates = environmentComponent
        .filter((envComponent) => envComponent?.componentVersion)
        .some(
          (envComponent1, i1, array) =>
            envComponent1.componentVersion === componentVersion &&
            array.find((envComponent2, i2) => {
              if (i1 === i2) {
                return false;
              }

              const componentVersion1 = componentVersions.find(
                ({ id }) => id === envComponent1.componentVersion
              );
              const componentVersion2 = componentVersions.find(
                ({ id }) => id === envComponent2.componentVersion
              );
              return (
                componentVersion1 &&
                componentVersion2 &&
                componentVersion1.component === componentVersion2.component
              );
            })
        );

      if (hasDuplicates) {
        return {
          message: 'resources.components.validation.duplicate',
          type: 'validate',
        };
      }
    },
    [componentVersions, isComponentVersionsLoading]
  );

  return (
    <ReferenceInput source={source} reference={'component_versions'}>
      <GroupedSelectInput
        label={'resources.environments.fields.componentVersion'}
        groupReference={'components'}
        optionText={(choice) => choice && (choice.version || choice.name)}
        requiredFirst
        requiredGroups={requiredComponentIds}
        validate={[required(), validateEnvironmentComponentVersion]}
        isLoading={isRequiredComponentsLoading}
        {...rest}
      />
    </ReferenceInput>
  );
};

import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { TourProvider, useTour } from '@reactour/tour';
import { useTheme } from '@mui/material';
import { TourArrow, TourClose } from '@rc/admin/components/ui/Tour';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { useNavigate } from 'react-router';
import { FEATURE_TOUR_STORAGE_KEY_PREFIX } from '../constants';
import { useTimeout } from 'react-admin';

/**
 * @typedef {object} FeatureTourMeta
 * @property {string} name
 * @property {() => void} onClose
 * @property {() => void} onOpen
 */

/**
 * @typedef {import('@reactour/tour').ProviderProps & { meta: FeatureTourMeta }} FeatureTour
 */

/**
 * @template T
 * @typedef {object} FeatureTourFactoryProps
 * @property {T} options
 * @property {import('react-router').NavigateFunction} navigate
 */

/**
 * @template T
 * @typedef {(props: FeatureTourFactoryProps<T>) => FeatureTour} FeatureTourFactory<T>
 */

/**
 *
 * @param {import('@reactour/tour').ProviderProps} props
 * @returns
 */
export const FeatureTourProvider = props => {
  const { styles: stylesProp, beforeClose: beforeCloseProp, ...rest } = props;
  const theme = useTheme();
  const [meta, setMeta] = useState({});

  const handleAfterOpen = useCallback(target => {
    if (target) {
      disableBodyScroll(target);
    }
  }, []);

  const handleBeforeClose = useCallback(
    target => {
      enableBodyScroll(target);

      if (beforeCloseProp) {
        beforeCloseProp(target);
      }

      if (meta?.onClose) {
        meta.onClose();
      }

      if (meta?.name) {
        setPersistedData({
          ...getPersistedData(),
          [meta.name]: true
        });
      }

      setMeta(curr => ({ ...curr, isDismissed: true }));
    },
    [meta, beforeCloseProp]
  );

  const styles = useMemo(
    /**
     * @returns {import('@reactour/tour').StylesObj & import('@reactour/popover').PopoverStylesObj & import('@reactour/mask').MaskStylesObj}
     */
    () => {
      return {
        ...stylesProp,
        popover: base => ({
          ...base,
          margin: `0 ${theme.spacing(1)}`,
          padding: `${theme.spacing(4)} ${theme.spacing(3)} ${theme.spacing(
            1.5
          )}`,
          borderRadius: theme.shape.borderRadius,
          backgroundColor:
            theme.palette.mode === 'light'
              ? theme.palette.background.paper
              : theme.palette.background.default,
          ...(stylesProp?.popover ? stylesProp.popover(base) : {})
        })
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [stylesProp, theme.palette.mode]
  );

  return (
    <TourProvider
      {...rest}
      styles={styles}
      meta={meta}
      setMeta={setMeta}
      beforeClose={handleBeforeClose}
      afterOpen={handleAfterOpen}
    />
  );
};

/**
 * @type {import('@reactour/tour').ProviderProps}
 */
FeatureTourProvider.defaultProps = {
  padding: 0,
  showBadge: false,
  position: 'right',
  styles: {},
  components: {
    Close: TourClose,
    Arrow: TourArrow
  }
};

/**
 *
 * @typedef {object} UseFeatureTourProps
 * @property {FeatureTourFactory<T>} Tour
 * @property {boolean | () => boolean} shouldShow
 * @property {T} options
 * @returns
 *
 * @type {<T>(props: UseFeatureTourProps<T>) => import('@reactour/tour').UseTour & { next: () => void, previous: () => void }
 */
export const useFeatureTour = (Tour, shouldShow, options) => {
  const tourProps = useTour();

  const {
    setIsOpen: setIsTourOpen,
    setSteps: setTourSteps,
    setMeta
  } = tourProps;

  const navigate = useNavigate();

  const tour = useMemo(() => Tour({ navigate, options }), [
    Tour,
    navigate,
    options
  ]);

  const show = typeof shouldShow === 'function' ? shouldShow() : shouldShow;

  useEffect(() => {
    const isDismissed = getPersistedData()[tour.meta?.name];

    if (show && !isDismissed) {
      if (tour.meta?.onOpen) {
        tour.meta.onOpen();
      }

      setTourSteps(tour.steps);
      setIsTourOpen(true);
      setMeta(tour.meta);

      return () => {
        setIsTourOpen(false);
        setTourSteps([]);
        setMeta({});
      };
    }
  }, [setIsTourOpen, setMeta, setTourSteps, show, tour]);

  const next = useCallback(
    e => {
      tourProps.setCurrentStep(curr => curr + 1);
    },
    [tourProps]
  );

  const previous = useCallback(
    e => {
      if (tourProps.currentStep > 0) {
        tourProps.setCurrentStep(curr => curr - 1);
      }
    },
    [tourProps]
  );

  return useMemo(
    () => ({
      ...tourProps,
      next,
      previous
    }),
    [tourProps, next, previous]
  );
};

/**
 *
 * @param {object} props
 * @param {FeatureTourFactory<T>} props.tour
 * @param {boolean | () => boolean} props.shouldShow
 * @param {number | undefined} props.timeout
 * @param {T} props.options
 * @returns
 */
export const FeatureTour = props => {
  const { tour, shouldShow = true, timeout = 0, options } = props;
  useFeatureTour(tour, useTimeout(timeout) && shouldShow, options);

  return null;
};

function getPersistedData () {
  try {
    return JSON.parse(
      window.localStorage.getItem(FEATURE_TOUR_STORAGE_KEY_PREFIX) || '{}'
    );
  } catch (e) {
    return {};
  }
}

function setPersistedData (data) {
  return window.localStorage.setItem(
    FEATURE_TOUR_STORAGE_KEY_PREFIX,
    JSON.stringify(data)
  );
}

import { STATE_IDS } from '@rc/admin/constants';

const ERROR_STATES = [STATE_IDS.SERVER_ERROR, STATE_IDS.CLIENT_ERROR];

const STOPPED_STATES = [STATE_IDS.STOPPED];

const DELETED_STATES = [STATE_IDS.DELETED];

const RUNNING_STATES = Object.values(STATE_IDS).reduce((acc, value) => {
  if (
    !ERROR_STATES.includes(value) &&
    !STOPPED_STATES.includes(value) &&
    !DELETED_STATES.includes(value)
  ) {
    acc.push(value);
  }

  return acc;
}, []);

export const STATE_CHOICES = [
  {
    name: 'Running',
    id: RUNNING_STATES
  },
  {
    name: 'Stopped',
    id: STOPPED_STATES
  },
  {
    name: 'Terminated',
    id: DELETED_STATES
  },
  {
    name: 'Error',
    id: ERROR_STATES
  }
];

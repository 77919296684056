import { useEnvironmentAccess } from './useEnvironmentAccess';

export const useEnvironmentAccessFrontendHostname = () => {
  const { isLoading, value: environmentAccess } = useEnvironmentAccess();

  return {
    isLoading,
    hostname: environmentAccess?.frontend?.url?.replace(/^https?:\/\/|\/$/g, '')
  };
};

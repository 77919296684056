import { Chip } from '@mui/material';
import React from 'react';
import { useRecordContext, useTranslate } from 'react-admin';
import { getIsInvitationExpired } from '../../../utils/domain/invitation';

/**
 * @typedef {{source: string}} InvitationStateFieldProps
 *
 * @param {import('@mui/material').ChipProps & InvitationStateFieldProps} props
 */
export const InvitationStateField = props => {
  const { source, ...rest } = props;
  const t = useTranslate();
  const record = useRecordContext();

  /**
   * @type {import('@mui/material').ChipProps}
   */
  const chipProps = {
    ...rest,
    size: 'small'
  };

  if (!record[source]) {
    if (getIsInvitationExpired(record)) {
      chipProps.label = t('resources.invitations.states.expired');
      chipProps.color = 'error';
    } else {
      chipProps.label = t('resources.invitations.states.pending');
      chipProps.color = 'info';
    }
  } else {
    chipProps.label = t('resources.invitations.states.accepted');
    chipProps.color = 'success';
  }

  return <Chip {...chipProps} />;
};

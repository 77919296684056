import { useMemo } from 'react';
import { useAxios } from '@rc/utils/axios';
import { getProjectPrices } from '@rc/admin/utils/domain/costs';

export const useProjectCosts = props => {
  const { id } = props;

  const [{ data, loading: isLoading, error }] = useAxios(
    `/api/projects/${id}/costs`
  );

  const costs = useMemo(() => {
    if (!data) {
      return null;
    }
    const environmentsData = Object.keys(data).reduce((acc, key) => {
      // Workaround to get environment costs without requesting the environments for uuid
      if (key !== 'summary' && data[key].costs && data[key].environment) {
        acc.push(data[key]);
      }
      return acc;
    }, []);

    return getProjectPrices(environmentsData);
  }, [data]);

  return {
    error,
    isLoading,
    costs,
    summary: data?.summary
  };
};

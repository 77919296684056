import * as React from 'react';
import {
  Button,
  Dialog,
  dialogClasses,
  DialogActions,
  dialogActionsClasses,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
  styled,
  toolbarClasses
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

/**
 *
 * @typedef {object} SideDialogProps
 * @property {any} props.container
 * @property {string} props.title Button title
 * @property {string} props.dialogTitle Dialog title
 * @property {React.ReactNode[]} props.actions
 * @property {boolean} props.isOpen
 * @property {Function} props.handleOpen
 * @property {Function} props.handleClose
 * @property {import('@mui/material').DialogProps} props.DialogProps
 *
 * @param {SideDialogProps & import('@mui/material').ButtonProps} props
 * @returns
 */
const SideDialog = props => {
  const {
    title,
    dialogTitle,
    children,
    actions,
    handleClose,
    handleOpen,
    isOpen,
    DialogProps,
    container: ContainerComponent = 'div',
    ...rest
  } = props;

  return (
    <div>
      <Button onClick={handleOpen} {...rest}>
        {title}
      </Button>
      <StyledDialog
        className={SideDialogClasses.root}
        TransitionComponent={Transition}
        fullWidth={true}
        maxWidth={'sm'}
        open={isOpen}
        onClose={handleClose}
        {...DialogProps}
      >
        <ContainerComponent>
          <DialogTitle>
            {dialogTitle}
            <IconButton
              aria-label='close'
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: theme => theme.palette.grey[500]
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>{children}</DialogContent>
          {!!actions?.length && (
            <DialogActions>
              {/* {showCancel && (
            <Button
              variant='text'
              label='ra.action.cancel'
              onClick={handleClose}
            />
          )} */}
              {actions.map(action => {
                const { onClick, preventClose, ...rest } = action.props;

                const actionProps = rest;

                if (!preventClose) {
                  actionProps.onClick = async e => {
                    if (onClick) {
                      await onClick(e);
                    }

                    handleClose();
                  };
                }

                return React.cloneElement(action, actionProps);
              })}
            </DialogActions>
          )}
        </ContainerComponent>
      </StyledDialog>
    </div>
  );
};

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction='left' ref={ref} {...props} />;
});

const PREFIX = 'SideDialog';

export const SideDialogClasses = {
  root: `${PREFIX}-root`
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`&.${SideDialogClasses.root}`]: {
    [`& .${dialogClasses.container}`]: {
      justifyContent: 'flex-end'
    },

    [`& .${dialogClasses.paper}`]: {
      margin: 0,
      height: '100%',
      maxHeight: '100%'
    },

    [`& .${dialogActionsClasses.root}`]: {
      justifyContent: 'flex-start',
      margin: `${theme.spacing(1)} ${theme.spacing(2)}`
    },

    [`& .${toolbarClasses.root}`]: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%'
    },

    '& form': {
      display: 'contents'
    }
  }
}));

SideDialog.defaultProps = {
  DialogProps: {}
};

export default SideDialog;

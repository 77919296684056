import { styled } from '@mui/material';
import React from 'react';
import { EditButton as RaEditButton } from 'react-admin';

/**
 *
 * @param {import('react-admin').EditButtonProps} props
 * @returns {import('react').ReactElement<RaEditButton>}
 */
export const EditButton = props => {
  return <StyledEditButton variant='outlined' icon={null} {...props} />;
};

const StyledEditButton = styled(RaEditButton)(() => ({
  float: 'right',
  minWidth: 48
}));

EditButton.defaultProps = {};

import React, { useMemo } from 'react';
import { useLocation } from 'react-router';
import { Create, SimpleForm, TextInput, BooleanInput } from 'react-admin';
import { styled } from '@mui/material';
import { OneColumnFormStyles } from '@rc/admin/theme/styles';
import { Breadcrumbs } from '@rc/admin/components';

const redirect = (_, __, record) =>
  `project_type_version_env_vars/${encodeURIComponent(
    record?.projectTypeVersionEnvVar
  )}`;

export const ProjectTypeVersionEnvVarExampleCreate = () => {
  const { search } = useLocation();

  const initialProjectTypeVersionEnvVarId = useMemo(
    () => new URLSearchParams(search).get('projectTypeVersionEnvVar'),
    [search]
  );

  return (
    <StyledCreate
      actions={
        <Breadcrumbs
          label={<BreadcrumbsLabel />}
          parents={breadcrumbsParents}
          data={{
            '@type': 'ProjectTypeVersionEnvVarExample',
            projectTypeVersionEnvVar: initialProjectTypeVersionEnvVarId
          }}
          showCurrent={false}
        />
      }
      redirect={redirect}
    >
      <SimpleForm
        defaultValues={{
          isEnabled: true,
          projectTypeVersionEnvVar: initialProjectTypeVersionEnvVarId
        }}
        className={classes.form}
      >
        <TextInput source={'value'} fullWidth />
        <BooleanInput source={'isDefault'} fullWidth />
      </SimpleForm>
    </StyledCreate>
  );
};

const PREFIX = 'ProjectTypeVersionEnvVarExampleCreate';

const classes = {
  form: `${PREFIX}-form`
};

const StyledCreate = styled(Create)(({ theme }) => ({
  [`& .${classes.form}`]: {
    ...OneColumnFormStyles(theme)
  }
}));

const breadcrumbsParents = [
  {
    reference: 'project_type_version_env_vars',
    source: 'projectTypeVersionEnvVar'
  },
  { reference: 'project_type_versions', source: 'projectTypeVersion' },
  { reference: 'project_types', source: 'projectType' }
];

const BreadcrumbsLabel = props => {
  const { data } = props;

  switch (data['@type']) {
    case 'ProjectTypeVersionEnvVarExample':
      return data.value;
    case 'ProjectTypeVersionEnvVar':
      return data.key;
    case 'ProjectTypeVersion':
      return data.version;
    default:
      return data.name;
  }
};

import React from 'react';
import { Edit, SimpleForm, TextInput, BooleanInput } from 'react-admin';
import { Breadcrumbs, EditTitle } from '@rc/admin/components';
import { styled } from '@mui/material';
import { OneColumnFormStyles } from '@rc/admin/theme/styles';

export const EnvVarTypeEdit = () => {
  return (
    <StyledEdit title={<EditTitle />} actions={<Breadcrumbs />}>
      <SimpleForm redirect={false} className={classes.form}>
        <TextInput source={'name'} fullWidth />
        <BooleanInput source={'isDefault'} fullWidth />
      </SimpleForm>
    </StyledEdit>
  );
};

const PREFIX = 'EnvVarTypeEdit';

const classes = {
  form: `${PREFIX}-form`
};

const StyledEdit = styled(Edit)(({ theme }) => ({
  [`& .${classes.form}`]: {
    ...OneColumnFormStyles(theme)
  }
}));

import React from 'react';
import {
  ChoicesContextProvider,
  SelectInput,
  useChoicesContext
} from 'react-admin';
import clsx from 'clsx';
import {
  CardContent,
  Skeleton,
  Stack,
  Typography,
  styled
} from '@mui/material';
import { CardList } from '@rc/admin/components';

/**
 *
 * @typedef {{
 * selected: import('react-admin').Record,
 * onSelectedChanged: (record: import('react-admin').Record) => void,
 * hideVersionSelector: boolean,
 * className: string,
 * SelectInputProps: import('react-admin').SelectInputProps,
 * itemGridSizes: import('@mui/material').GridSize
 * }} ProjectTypeCardListProps
 *
 * @param {ProjectTypeCardListProps} props
 * @returns
 */
export const ProjectTypeCardList = props => {
  const {
    selected,
    onSelectedChanged,
    hideVersionSelector,
    className,
    SelectInputProps,
    itemGridSizes
  } = props;

  const {
    allChoices: projectTypeVersions,
    ...choicesContext
  } = useChoicesContext();

  const allChoices = projectTypeVersions?.filter(
    choice => choice.projectType === selected?.id
  );

  return (
    <CardList
      className={className}
      linkType={false}
      enablePlaceholders
      placeholdersCount={3}
      itemGridSizes={itemGridSizes}
      render={(record, _, isPlaceholder) => {
        const isSelected = record === selected;

        return (
          <StyledCardContent
            className={clsx(ProjectTypeCardListClasses.cardContent, {
              [ProjectTypeCardListClasses.selected]: isSelected
            })}
            onClick={() => onSelectedChanged(record)}
          >
            <Stack
              className={ProjectTypeCardListClasses.brand}
              flexDirection={'column'}
              alignItems={'center'}
              spacing={1}
            >
              {isPlaceholder || !record?.codeName ? (
                <Skeleton
                  className={clsx(
                    ProjectTypeCardListClasses.brandImage,
                    ProjectTypeCardListClasses.brandImageSkeleton
                  )}
                  variant={'circular'}
                />
              ) : (
                <img
                  className={ProjectTypeCardListClasses.brandImage}
                  src={record.logo}
                  width='auto'
                  height={'96'}
                />
              )}

              <Typography
                className={ProjectTypeCardListClasses.brandName}
                sx={theme => ({
                  color: isSelected
                    ? theme.palette.text.primary
                    : theme.palette.text.secondary,
                  fontWeight: 500
                })}
              >
                {isPlaceholder ? (
                  <Skeleton width={60} variant={'text'} />
                ) : (
                  record.name
                )}
              </Typography>
            </Stack>
            {!hideVersionSelector && (
              <Stack
                className={ProjectTypeCardListClasses.control}
                flexDirection={'column'}
                alignItems={'center'}
                spacing={1}
              >
                <ChoicesContextProvider
                  value={{
                    ...choicesContext,
                    allChoices,
                    availableChoices: allChoices
                  }}
                >
                  <SelectInput
                    id={'project-type-version'}
                    sx={{ textAlign: 'start' }}
                    fullWidth
                    {...SelectInputProps}
                  />
                </ChoicesContextProvider>
              </Stack>
            )}
          </StyledCardContent>
        );
      }}
      cardStyles={record =>
        record === selected
          ? theme => ({
              borderColor: theme.palette.primary.main,
              backgroundColor: theme.palette.background.default,
              '&:hover': {
                boxShadow: `${theme.shadows[1]} !important`
              }
            })
          : {}
      }
    />
  );
};

ProjectTypeCardList.defaultProps = {
  hideVersionSelector: false,
  SelectInputProps: {},
  itemGridSizes: {
    xs: 12,
    sm: 6,
    md: 4
  }
};

const PREFIX = 'ProjectTypeCardList';

export const ProjectTypeCardListClasses = {
  cardContent: `${PREFIX}-cardContent`,
  selected: `${PREFIX}-selected`,
  brand: `${PREFIX}-brand`,
  brandName: `${PREFIX}-brandName`,
  brandImage: `${PREFIX}-brandImage`,
  brandImageSkeleton: `${PREFIX}-brandImageSkeleton`,
  control: `${PREFIX}-control`
};

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  '&, & *': {
    transitionDuration: '0.3s',
    transitionTimingFunction: 'ease-in-out'
  },
  [`&.${ProjectTypeCardListClasses.cardContent}`]: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    rowGap: theme.spacing(2),
    minHeight: theme.spacing(24),
    position: 'relative',
    textAlign: 'center',
    paddingBottom: 0,
    filter: 'grayscale(0.5)',
    transitionProperty: 'filter',
    cursor: 'pointer',
    [theme.breakpoints.up('md')]: {
      minHeight: theme.spacing(30.25)
    }
  },

  [`& .${ProjectTypeCardListClasses.brand}`]: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: `translate(-50%, -50%)`,
    transitionProperty: 'transform',

    [`& .${ProjectTypeCardListClasses.brandName}`]: {
      [theme.breakpoints.down('sm')]: {
        fontSize: '80%'
      }
    },
    [`& .${ProjectTypeCardListClasses.brandImage}`]: {
      height: 64,
      [`&.${ProjectTypeCardListClasses.brandImageSkeleton}`]: {
        width: 64
      },
      [theme.breakpoints.up('md')]: {
        height: 96,
        [`&.${ProjectTypeCardListClasses.brandImageSkeleton}`]: {
          width: 96
        }
      }
    }
  },

  [`& .${ProjectTypeCardListClasses.control}`]: {
    visibility: 'hidden',
    opacity: 0,
    transitionDuration: '0s',
    transitionProperty: 'opacity',
    pointerEvents: 'none'
  },

  [`&.${ProjectTypeCardListClasses.selected}`]: {
    filter: 'grayscale(0)',

    [`& .${ProjectTypeCardListClasses.brand}`]: {
      transform: `translate(-50%, -90%)`
    },

    [`& .${ProjectTypeCardListClasses.brandImage}`]: {
      transitionProperty: 'height',
      height: 48,
      [theme.breakpoints.up('md')]: {
        height: 82
      }
    },

    [`& .${ProjectTypeCardListClasses.control}`]: {
      transitionDuration: '0.3s',
      visibility: 'visible',
      pointerEvents: 'initial',
      opacity: 1
    }
  }
}));

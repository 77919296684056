import React from 'react';
import { Stack, styled } from '@mui/material';
import {
  EditTitle,
  EnvironmentStatusProgressBarTrigger
} from '@rc/admin/components';
import { RCWorkerRecordContextProvider } from '@rc/admin/context';
import { useIsTemplateResource } from '@rc/admin/hooks';

export const EnvironmentTitle = () => {
  const isTemplate = useIsTemplateResource();

  return (
    <Root>
      <EditTitle />
      {!isTemplate && (
        <RCWorkerRecordContextProvider>
          <EnvironmentStatusProgressBarTrigger />
        </RCWorkerRecordContextProvider>
      )}
    </Root>
  );
};

const Root = styled(Stack)(({ theme }) => ({
  width: '100%',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignContent: 'center',
  rowGap: theme.spacing(0.5),
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row'
  }
}));

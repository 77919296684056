import React from 'react';
import {
  DateField,
  Edit,
  Labeled,
  ReferenceField,
  SimpleForm,
  TextField,
  useGetOne,
  useRecordContext,
  useTranslate
} from 'react-admin';
import { Grid, styled } from '@mui/material';
import { OneColumnFormStyles } from '@rc/admin/theme/styles';
import { Breadcrumbs, EditTitle } from '@rc/admin/components';
import {
  InvitationOrganisationField,
  InvitationStateField
} from './components';

export const InvitationEdit = () => {
  return (
    <StyledEdit
      title={<EditTitle title={<Title />} showResourceName={false} />}
      actions={<Breadcrumbs showCurrent={false} disableLast={false} />}
    >
      <SimpleForm redirect={false} className={classes.form}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <Labeled>
              <TextField source={'email'} fullWidth />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Labeled>
              <ReferenceField source='user' reference='users'>
                <TextField source={'email'} fullWidth />
              </ReferenceField>
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Labeled>
              <InvitationOrganisationField source='organisation' />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Labeled>
              <ReferenceField source='team' reference='teams'>
                <TextField source='name' fullWidth />
              </ReferenceField>
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Labeled>
              <DateField source='createdAt' fullWidth />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Labeled>
              <DateField source='expiredAt' fullWidth />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Labeled>
              <InvitationStateField source='state' />
            </Labeled>
          </Grid>
        </Grid>
      </SimpleForm>
    </StyledEdit>
  );
};

const Title = () => {
  const t = useTranslate();
  const record = useRecordContext();
  const { data: user } = useGetOne(
    'users',
    { id: record?.user },
    {
      enabled: !!record?.user
    }
  );

  if (!record) return null;

  return t('resources.invitations.message.invitation_for', {
    user: record.email || user?.email
  });
};

const PREFIX = 'InvitationEdit';

const classes = {
  form: `${PREFIX}-form`
};

const StyledEdit = styled(Edit)(({ theme }) => ({
  [`& .${classes.form}`]: {
    ...OneColumnFormStyles(theme)
  }
}));

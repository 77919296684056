import React, { forwardRef, useCallback, useState } from 'react';
import clsx from 'clsx';
import { NavLink, useLocation } from 'react-router-dom';
import {
  MenuItemLink as RaMenuItemLink,
  useGetList,
  useGetResourceLabel,
  useSidebarState
} from 'react-admin';
import { styled, Tooltip, Typography, useMediaQuery } from '@mui/material';
import {
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
  List as ListIcon
} from '@mui/icons-material';
import { SubMenuItemLink } from './SubMenuItemLink';
import {
  getIsResourcePathActive,
  getResourcePath
} from '@rc/admin/utils/common/menu';
import { MenuItemButton } from './MenuItemButton';
import { useOrgContext } from '@rc/admin/context';

const NavLinkRef = forwardRef((props, ref) => (
  <NavLink
    ref={ref}
    className={({ isActive }) =>
      clsx(classes.root, props.className, isActive ? classes.active : '')
    }
    {...props}
  />
));

export const TreeMenuItemLink = forwardRef((props, ref) => {
  const {
    classes: classesOverride,
    className,
    primaryText,
    leftIcon,
    onClick,
    sidebarIsOpen,
    tooltipProps,
    resource,
    parentResource,
    childResource,
    parentId,
    filters,
    childIcon,
    code,
    color,
    ...rest
  } = props;

  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const { pathname } = useLocation();
  const getResourceLabel = useGetResourceLabel();
  const [open, setOpen] = useSidebarState();
  const { teamId: selectedTeamId } = useOrgContext();
  const [expanded, setExpanded] = useState(false);

  const {
    data: resourcesData = [],
    isLoading: isResourcesLoading
  } = useGetList(resource.name);

  const { child, itemIcon: ItemIcon } = resource.options;
  const childOptions = parentResource?.options?.child || {};

  const data = !isResourcesLoading
    ? resourcesData
        .filter(item => item.team === selectedTeamId)
        .filter(
          item => !childOptions || item[childOptions.parentId] === parentId
        )
    : [];

  const handleListItemTap = useCallback(() => {
    setOpen(true);
    setExpanded(curr => !curr);
  }, [setOpen]);

  const handleMenuTap = useCallback(
    e => {
      if (isSmall) {
        setOpen(false);
      }
      onClick && onClick(e);
    },
    [isSmall, onClick, setOpen]
  );

  // Check for active descendants in the tree
  const isActive = data.some(
    ({ id, ...rest }) =>
      !!getIsResourcePathActive(pathname, resource.name, id) ||
      (child &&
        rest[child.id].some(childId =>
          getIsResourcePathActive(pathname, childResource.name, childId)
        ))
  );

  const showChildren = expanded && open;

  const renderMenuItem = () => (
    <MenuItemButton
      className={clsx(classes.root, isActive ? classes.active : '', className)}
      ref={ref}
      {...rest}
      onClick={handleListItemTap}
      primaryText={primaryText}
      leftIcon={
        showChildren ? <ExpandLessIcon /> : leftIcon || <ExpandMoreIcon />
      }
    />
  );

  if (!open) {
    return (
      <Tooltip title={primaryText} placement='right' {...tooltipProps}>
        {renderMenuItem()}
      </Tooltip>
    );
  }

  const linkToResourceList = !parentResource && (
    <RaMenuItemLink
      className={className}
      component={NavLinkRef}
      tabIndex={0}
      to={{
        pathname: getResourcePath(resource.name),
        state: { _scrollToTop: true }
      }}
      // isActive={(_, location) => location.pathname === `/${resource.name}`}
      onClick={handleMenuTap}
      primaryText={'All ' + getResourceLabel(resource.name, 2)}
      leftIcon={<ListIcon />}
    />
  );

  const linkToParentResource = parentResource && (
    <RaMenuItemLink
      className={className}
      component={NavLinkRef}
      tabIndex={0}
      to={{
        pathname: getResourcePath(parentResource.name, parentId),
        state: { _scrollToTop: true }
      }}
      onClick={handleMenuTap}
      primaryText={childOptions.parentTitle}
      leftIcon={childOptions.parentIcon ? <childOptions.parentIcon /> : null}
    />
  );

  return (
    <Root>
      {renderMenuItem()}
      {showChildren && (
        <div className={classes.resources}>
          {/* Removed displaying resource name and link to list page */}
          {linkToResourceList}
          {linkToParentResource}
          {!!data.length && (
            <Typography
              variant={'subtitle2'}
              component={'div'}
              className={classes.subTitle}
            >
              {getResourceLabel(resource.name, 2)}
            </Typography>
          )}
          {data.map(({ id, name, code, color }) => {
            const Icon = ItemIcon ? (
              <ItemIcon
                code={code || name.substr(0, 2).toUpperCase()}
                color={color}
              />
            ) : (
              resource.icon
            );

            if (childResource) {
              if (isSmall) {
                return (
                  <TreeMenuItemLink
                    hasParent={true}
                    key={id}
                    parentId={id}
                    parentResource={resource}
                    resource={childResource}
                    primaryText={name}
                    leftIcon={Icon}
                  />
                );
              }

              return (
                <SubMenuItemLink
                  key={id}
                  resource={childResource}
                  parentResource={resource}
                  id={id}
                  primaryText={name}
                  leftIcon={Icon}
                />
              );
            }

            return (
              <RaMenuItemLink
                hasParent={true}
                key={id}
                component={NavLinkRef}
                tabIndex={0}
                to={{
                  pathname: getResourcePath(resource.name, id),
                  state: { _scrollToTop: true }
                }}
                onClick={handleMenuTap}
                primaryText={name}
                leftIcon={<resource.icon />}
              />
            );
          })}
        </div>
      )}
    </Root>
  );
});

const PREFIX = 'TreeMenuItemLink';

const classes = {
  root: `${PREFIX}-root`,
  active: `${PREFIX}-active`,
  resources: `${PREFIX}-resources`,
  subTitle: `${PREFIX}-subTitle`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({ theme, hasParent }) => ({
  [`& .${classes.root}`]: {
    color: theme.palette.text.primary,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    marginBottom: theme.spacing(0.5),
    alignItems: 'flex-start',
    backgroundColor: hasParent ? 'transparent !important' : 'inherit',
    paddingTop: theme.spacing(hasParent ? 1 : 1.25),
    paddingBottom: theme.spacing(hasParent ? 1 : 1.25),
    paddingLeft: `${(hasParent ? 0 : 1) * 24}px`
  },

  [`& .${classes.active}`]: {
    ...(theme.components?.MuiListItemButton?.styleOverrides?.root[
      '&.Mui-selected'
    ] || {})
  },

  [`& .${classes.resources}`]: {
    marginLeft: theme.spacing(2)
  },

  [`& .${classes.subTitle}`]: {
    opacity: 0.5,
    textTransform: 'uppercase',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(2)
  }
}));

TreeMenuItemLink.displayName = 'TreeMenuItemLink';

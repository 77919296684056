import React, { useEffect, useState } from 'react';
import { useRecordContext, useTranslate } from 'react-admin';
import {
  AttachMoneyTwoTone,
  PriceCheckTwoTone,
  VerifiedTwoTone
} from '@mui/icons-material';
import { Chip, CircularProgress, Tooltip } from '@mui/material';

export const Status = () => {
  const record = useRecordContext();
  const t = useTranslate();
  const [icon, setIcon] = useState(null);
  const [tooltip, setTooltip] = useState(null);
  const [label, setLabel] = useState(null);
  const [color, setColor] = useState(null);

  useEffect(() => {
    switch (record.status) {
      case 'paid':
        setIcon(<PriceCheckTwoTone />);
        setTooltip('pages.billing.requests.status.paid.tooltip');
        setLabel('pages.billing.requests.status.paid.label');
        setColor('success');
        break;
      case 'open':
        setIcon(<AttachMoneyTwoTone />);
        setTooltip('pages.billing.requests.status.open.tooltip');
        setLabel('pages.billing.requests.status.open.label');
        setColor('primary');
        break;
      default:
        setIcon(<VerifiedTwoTone />);
        setTooltip('pages.billing.requests.status.default.tooltip');
        setLabel('pages.billing.requests.status.default.label');
        setColor('primary');
        break;
    }
  }, [record.status]);

  if (!icon || !tooltip || !label || !color) return <CircularProgress />;

  return (
    <Tooltip title={t(tooltip)}>
      <Chip label={t(label)} icon={icon} color={color} size={'small'} />
    </Tooltip>
  );
};

export default Status;

import React, { useCallback, useState } from 'react';
import _ from 'lodash';
import { useTranslate } from 'react-admin';
import {
  Box,
  CircularProgress,
  Dialog,
  Grid,
  IconButton,
  Paper,
  Stack,
  Typography,
  styled
} from '@mui/material';
import { blue } from '@mui/material/colors';
import { PaymentMethodDetails } from './PaymentMethodDetails';
import { CardNumber } from './utils';
import { CardBrand, NoPaymentMethod } from '../components';
import { Iconify } from '@rc/admin/components';

const PaymentMethodList = props => {
  const { list: data, goTo, customer, onDelete: onDeleteProp } = props;
  const t = useTranslate();
  const [selectedCard, setSelectedCard] = useState(null);

  const onDelete = useCallback(() => {
    setSelectedCard(null);
    onDeleteProp();
  }, [onDeleteProp]);

  if (_.isNil(data)) {
    return <CircularProgress />;
  }

  if (_.isEmpty(data)) {
    return <NoPaymentMethod goTo={goTo} />;
  }

  return (
    <StyledStack
      direction={{ xs: 'column', md: 'row' }}
      flexWrap={'wrap'}
      gap={2}
    >
      <Dialog
        open={!!selectedCard}
        onClose={() => setSelectedCard(null)}
        maxWidth='sm'
        fullWidth
        sx={{ p: 5 }}
      >
        <PaymentMethodDetails
          method={selectedCard}
          customer={customer}
          onDelete={onDelete}
        />
      </Dialog>
      {data.map(method => (
        <Paper className={classes.card} key={method.id} elevation={2}>
          <Stack
            className={classes.cardHeader}
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'flex-start'}
          >
            <CardBrand cardBrand={method.card.brand} />
            {method.billing_details && (
              <IconButton
                onClick={() => setSelectedCard(method)}
                sx={{ padding: 0 }}
              >
                <Iconify icon='solar:info-circle-outline' fontSize='small' />
              </IconButton>
            )}
          </Stack>
          <Box className={classes.cardNumber}>
            <CardNumber value={method.card.last4} />
          </Box>
          <Grid container>
            <Grid xs={6} item>
              <Typography
                sx={{ fontSize: 10, color: blue[100] }}
                variant={'span'}
                component={'div'}
              >
                {t('pages.billing.method.fields.holder')}
              </Typography>
              <Typography variant={'span'} component={'div'}>
                {customer.name}
              </Typography>
            </Grid>
            <Grid
              xs={6}
              display={'flex'}
              alignItems={'flex-end'}
              flexDirection={'column'}
              item
            >
              <Typography
                sx={{ fontSize: 10, color: blue[100] }}
                variant={'span'}
                component={'div'}
                textTransform={'uppercase'}
              >
                {t('pages.billing.method.fields.expires')}
              </Typography>
              <Typography variant={'span'} component={'div'}>
                {method.card.exp_month}/{method.card.exp_year}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      ))}
    </StyledStack>
  );
};

const PREFIX = 'PaymentMethodList';

const classes = {
  card: `${PREFIX}-card`,
  cardHeader: `${PREFIX}-cardHeader`,
  cardNumber: `${PREFIX}-cardNumber`
};

const StyledStack = styled(Stack)(({ theme }) => ({
  [`& .${classes.card}`]: {
    background: blue[400],
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'column',
    width: '100%',
    padding: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      width: 350,
      padding: theme.spacing(3)
    }
  },
  [`& .${classes.cardHeader}`]: {
    marginBottom: theme.spacing(4.5),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(6.5)
    }
  },
  [`& .${classes.cardNumber}`]: {
    marginBottom: theme.spacing(2),
    fontSize: 18,
    [theme.breakpoints.up('md')]: {
      fontSize: 32
    }
  }
}));

export default PaymentMethodList;

import React from 'react';
import PropTypes from 'prop-types';
import { Step, StepLabel, Stepper as MuiStepper, styled } from '@mui/material';
import { useFormStepperContext } from './context';
import { useTranslate } from 'react-admin';

export const FormStepper = props => {
  const { steps } = props;
  const [{ activeStep }] = useFormStepperContext();
  const translate = useTranslate();

  return (
    <Stepper activeStep={activeStep} alternativeLabel>
      {steps.map(({ label, StepIconComponent }) => (
        <Step key={label}>
          <StepLabel StepIconComponent={StepIconComponent}>
            {translate(label)}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

/**
 *
 * @param {object} props
 * @param {string} props.label
 * @param {boolean} props.showToolbar
 * @param {React.ElementType<any>} props.StepIconComponent
 * @returns
 */
export const FormStep = props => {
  const { children } = props;

  return children;
};

const Stepper = styled(MuiStepper)(({ theme }) => ({
  marginTop: theme.spacing(2)
}));

FormStep.propTypes = {
  label: PropTypes.string.isRequired,
  showToolbar: PropTypes.bool
};

FormStep.defaultProps = {
  showToolbar: true
};

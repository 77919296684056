import React, { useRef, useCallback } from 'react';
import { RecordContextProvider, useTranslate } from 'react-admin';
import { Box, styled } from '@mui/material';
import { useProjectContext } from '@rc/admin/context';
import { EnvVarFields, EnvVarInputs } from '@rc/admin/components';
import { useInheritedEnvVars, useTeam } from '@rc/admin/hooks';

export const EnvironmentEnvVarInputs = props => {
  const translate = useTranslate();
  const { project, isLoading: isProjectLoading } = useProjectContext();
  const { team, organisation, isLoading: isTeamLoading } = useTeam({
    teamId: project?.team
  });

  const { envVarsAggregated } = useInheritedEnvVars({
    project,
    team,
    organisation
  });

  const formIteratorApiRef = useRef();
  const setFormIteratorApiRef = useCallback(
    value => (formIteratorApiRef.current = value),
    []
  );

  const isLoading = isProjectLoading || isTeamLoading;

  if (isLoading) {
    return null;
  }

  const { projectTypeVersion, componentVersion } = project;

  return (
    <EnvVarInputs
      {...props}
      projectTypeVersion={projectTypeVersion}
      componentVersion={componentVersion}
      setFormIteratorApi={setFormIteratorApiRef}
      inheritedEnvVars={envVarsAggregated}
    >
      {envVarsAggregated.map((data, index, array) => (
        <StyledBox key={`inherited-${data.id}`} className={classes.item}>
          <RecordContextProvider value={data.record}>
            <EnvVarFields
              source={props.source}
              fieldSource={data.source}
              overrides={index > 0 ? array.slice(0, index) : []}
              label={translate('resources.env_vars.label.inherited_env_vars', {
                entity: data.record['@type']
              })}
              fullWidth
              emptyLabel={translate(
                'resources.env_vars.label.no_inherited_env_vars',
                {
                  entity: data.record['@type']
                }
              )}
              formIteratorApiRef={formIteratorApiRef}
            />
          </RecordContextProvider>
        </StyledBox>
      ))}
    </EnvVarInputs>
  );
};

const PREFIX = 'EnvironmentEnvVarInputs';

const classes = {
  item: `${PREFIX}-inheritedItem`
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.item}`]: {
    marginTop: theme.spacing(1.5),

    [`&:nth-of-type(2)`]: {
      marginTop: theme.spacing(3)
    }
  }
}));

import { useMemo } from 'react';

import SvgColor from '../../components/svg-color';
import {
  useAuthState,
  useGetResourceLabel,
  usePermissions,
  useResourceDefinitions,
  useTranslate
} from 'react-admin';
import { useLocation } from 'react-router';

import { getHasPermission } from '@rc/admin/utils/permissions/util';
import { ROLES } from '@rc/admin/constants';

// ----------------------------------------------------------------------

const icon = name => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

// ----------------------------------------------------------------------

const MENU = [
  /* Non-admin menu */
  {
    subheader: '',
    shouldRender: isAdmin => !isAdmin,
    items: [
      {
        label: 'pages.dashboard.title',
        path: '/'
      },
      { resource: 'projects', nestedResources: ['/environments/*'] },
      { resource: 'teams' },
      { resource: 'organisations' }
    ]
  },
  /* Admin menu */
  {
    subheader: 'Management',
    shouldRender: isAdmin => isAdmin,
    items: [
      { resource: 'projects', nestedResources: ['/environments/*'] },
      { resource: 'teams' },
      { resource: 'organisations' }
    ]
  },
  {
    subheader: 'Infrastructure',
    items: [
      { resource: 'providers' },
      { resource: 'products' },
      { resource: 'project_types' },
      { resource: 'components' },
      { resource: 'states' }
    ]
  },
  {
    subheader: 'Users',
    items: [
      { resource: 'users' },
      { resource: 'role_groups' },
      { resource: 'access_codes' },
      { resource: 'invitations' }
    ]
  },
  {
    subheader: 'Configuration',
    items: [
      { resource: 'template_projects' },
      { resource: 'resource_types' },
      { resource: 'env_var_types' },
      { resource: 'env_var_validations' }
    ]
  }
];

export function useNavData () {
  const t = useTranslate();
  const location = useLocation();
  const getResourceLabel = useGetResourceLabel();
  const resourcesDefinitions = useResourceDefinitions();
  const resources = Object.keys(resourcesDefinitions)
    .map(name => resourcesDefinitions[name])
    .filter(resource => shouldRender(resource, location));

  const { isLoading: isLoadingAuthState } = useAuthState();
  const { permissions, isLoading: isLoadingPermissions } = usePermissions();
  const isLoading = isLoadingAuthState || isLoadingPermissions;
  const isAdmin = permissions?.includes(ROLES.ADMIN);

  const data = useMemo(
    () =>
      isLoading
        ? []
        : MENU.filter(
            menuItem =>
              menuItem.shouldRender === undefined ||
              menuItem.shouldRender(isAdmin)
          )
            .map(menuItem => ({
              subheader: t(menuItem.subheader),
              items: menuItem.items
                .map(item => ({
                  ...item,
                  resource:
                    item.resource &&
                    resources.find(({ name }) => name === item.resource)
                }))
                .filter(
                  ({ resource }) =>
                    !resource ||
                    (!resource.options?.group &&
                      getHasPermission(resource.name, '*', permissions))
                )
                .sort(
                  (a, b) =>
                    (a.resource?.options?.order || Number.POSITIVE_INFINITY) -
                    (b.resource?.options?.order || Number.POSITIVE_INFINITY)
                )
                .map(item => {
                  const {
                    path,
                    label,
                    icon: Icon,
                    resource,
                    nestedResources
                  } = item;
                  if (resource) {
                    return {
                      id: resource.name,
                      title: getResourceLabel(resource.name, 2),
                      path: `/${resource.name}`,
                      icon: isAdmin && resource.icon ? <resource.icon /> : null,
                      nestedPaths: nestedResources
                    };
                  }

                  return {
                    id: path,
                    title: t(label),
                    path,
                    icon: isAdmin && Icon ? <Icon /> : null
                  };
                })
            }))
            .filter(({ items }) => items.length > 0),
    [t, resources, permissions, getResourceLabel, isAdmin, isLoading]
  );

  return data;
}

const shouldRender = (resource, location) => {
  const { showInMenu } = resource.options || {};
  if (showInMenu === false) {
    return false;
  } else if (typeof showInMenu === 'function') {
    return showInMenu(location);
  }

  return true;
};

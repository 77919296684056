export function getProjectTypeForProjectTypeVersion (
  projectTypes,
  projectTypeVersions,
  projectTypeVersionid
) {
  const projectTypeVersion = projectTypeVersions?.find(
    version => version.id === projectTypeVersionid
  );
  return projectTypes?.find(
    projectType => projectType.id === projectTypeVersion?.projectType
  );
}

/**
 * @typedef {{ uuid: string, actionType: import('@rc/admin/types/progressbar.types').MessengerActionTypeEnum, hasMultipleOfType: boolean, index: number }} ProgressMeta
 */

/**
 *
 * @param {import('@rc/admin/types/progressbar.types').ProgressBar} progressBar
 * @returns {(ProgressMeta & import('@rc/admin/types/progressbar.types').ProgressDetails)[]}
 */
export function getOngoingProgresses (progressBar) {
  return Object.entries(progressBar).reduce((result, [actionType]) => {
    const progressesForAction = progressBar[actionType];

    // Testing for CREATE is not in progress
    // if (actionType === MESSENGER_ACTION_AMQP_TYPES.CREATE) {
    //   return result;
    // }

    Object.keys(progressesForAction)
      .filter(uuid => progressesForAction[uuid].done === false)
      .forEach((uuid, index, array) => {
        result.push({
          ...progressesForAction[uuid],
          uuid,
          actionType,
          hasMultipleOfType: array.length > 1,
          index
        });
      });
    return result;
  }, []);
}

import { TemplateEnvironmentEdit } from './Edit';
import { TemplateEnvironmentCreate } from './Create';
import { TemplateEnvironmentList } from './List';
import EnvironmentIcon from '@rc/admin/resources/environment/Icon';

export default {
  name: 'template_environments',
  edit: TemplateEnvironmentEdit,
  create: TemplateEnvironmentCreate,
  list: TemplateEnvironmentList,
  icon: EnvironmentIcon,
  options: {
    showInMenu: false
  }
};

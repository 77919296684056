import React, { useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  ListContextProvider,
  ReferenceInput,
  required,
  useChoicesContext,
  useGetList,
  useTranslate
} from 'react-admin';
import { useFormContext } from 'react-hook-form';
import clsx from 'clsx';
import {
  CardContent,
  FormControl,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Skeleton,
  Stack,
  styled,
  Typography
} from '@mui/material';
import {
  CardList,
  LoadingField,
  RichTextField,
  SectionHelper,
  SectionTitle,
  useFormStepperContext
} from '@rc/admin/components';
import { NAME_SORT, PREFETCH_PAGINATION } from '@rc/admin/constants';

const source = 'templateIri';
const sort = { field: 'name', order: 'ASC' };
const ALL_PROJECT_TYPES = 'All';

export const ProjectCreateStepChooseTemplate = () => {
  const t = useTranslate();
  const [, { next }] = useFormStepperContext();
  const { handleSubmit } = useFormContext();
  const location = useLocation();

  const [selectedProjectTypeId, setSelectedProjectTypeId] = useState(() =>
    location.state?.projectTypeId
      ? decodeURIComponent(location.state?.projectTypeId)
      : undefined
  );
  const { data: projectTypes, isLoading: isProjectTypesLoading } = useGetList(
    'project_types',
    {
      sort: NAME_SORT,
      pagination: PREFETCH_PAGINATION
    }
  );

  const onClick = useCallback(
    e => {
      handleSubmit(next)(e);
    },
    [handleSubmit, next]
  );

  if (isProjectTypesLoading) {
    return null;
  }

  const selectedProjectType = projectTypes?.find(
    ({ id }) => id === selectedProjectTypeId
  );

  return (
    <Root>
      <Stack className={classes.header}>
        <SectionTitle variant='h4' mt={2}>
          {t('resources.projects.sections.choose_template.title')}
        </SectionTitle>
        <FormControl size='small' sx={{ minWidth: 200 }}>
          <InputLabel id='project-types-filter'>
            {t('resources.project_types.name', { smart_count: 1 })}
          </InputLabel>
          <Select
            label={t('resources.project_types.name', { smart_count: 1 })}
            labelId='project-types-filter'
            value={selectedProjectTypeId || ALL_PROJECT_TYPES}
            onChange={e => {
              const { value } = e.target;
              if (value === ALL_PROJECT_TYPES) {
                setSelectedProjectTypeId(null);
              } else {
                const projectType = projectTypes?.find(
                  ({ id }) => id === value
                );
                setSelectedProjectTypeId(projectType?.id || null);
              }
            }}
            size='small'
            displayEmpty
          >
            <MenuItem value={ALL_PROJECT_TYPES}>{t('misc.all')}</MenuItem>
            {projectTypes?.map(({ id, name, codeName }) => (
              <MenuItem key={id} value={id}>
                <Stack flexDirection={'row'} alignItems={'center'} pr={2}>
                  <ListItemIcon
                    sx={{
                      img: {
                        height: 'auto',
                        width: 16
                      }
                    }}
                  >
                    <img
                      src={`/images/${codeName.toLowerCase()}.png`}
                      width={16}
                      height={16}
                    />
                  </ListItemIcon>
                  <ListItemText sx={{ margin: 0 }}>{name}</ListItemText>
                </Stack>
              </MenuItem>
            )) || null}
          </Select>
        </FormControl>
      </Stack>
      <ReferenceInput
        source={source}
        reference={'template_projects'}
        sort={sort}
        filter={
          selectedProjectTypeId && selectedProjectTypeId !== ALL_PROJECT_TYPES
            ? { projectTypeVersion: selectedProjectType.projectTypeVersion }
            : undefined
        }
      >
        <ProjectTemplateSelector
          id={'project-template'}
          label={''}
          // optionText={choice => choice?.version}
          validate={[required()]}
          fullWidth
          onClick={onClick}
          projectTypes={projectTypes}
          isProjectTypesLoading={isProjectTypesLoading}
          selectedProjectType={selectedProjectType}
        />
      </ReferenceInput>
    </Root>
  );
};

const PREFIX = 'ProjectTemplateSelector';

const classes = {
  header: `${PREFIX}-header`
};

const Root = styled(Stack)(({ theme }) => ({
  [`& .${classes.header}`]: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap'
  }
}));

/**
 *
 * @param {object} props
 * @param {function} props.onClick
 * @param {object[]} props.projectTypes
 * @param {boolean} props.isProjectTypesLoading
 * @param {object} props.selectedProjectType
 * @returns
 */
const ProjectTemplateSelector = props => {
  const {
    onClick,
    projectTypes,
    isProjectTypesLoading,
    selectedProjectType
  } = props;
  const t = useTranslate();
  const {
    allChoices: data,
    isLoading: isProjectTemplatesLoading,
    ...rest
  } = useChoicesContext();

  // const {
  //   data: projectTypeVersions,
  //   isLoading: isProjectTypeVersionsLoading
  // } = useGetList('project_type_versions');

  const { setValue } = useFormContext();
  const [selected, setSelected] = useState(null);

  const onCardClick = useCallback(
    (record, e) => {
      setSelected(record);

      if (selected !== record) {
        setValue(source, record.id, {
          shouldDirty: true,
          shouldTouch: true,
          shouldValidate: true
        });
        onClick(e);
      }
    },
    [selected, setValue, onClick]
  );

  if (isProjectTemplatesLoading) {
    return <LoadingField />;
  } else if (!data || !data.length) {
    return (
      <SectionHelper>
        {t('resources.projects.message.no_project_templates', {
          name: selectedProjectType?.name
        })}
      </SectionHelper>
    );
  }

  return (
    <ListContextProvider
      value={{
        data,
        isLoading: isProjectTemplatesLoading || isProjectTypesLoading,
        // || isProjectTypeVersionsLoading,
        ...rest
      }}
    >
      <CardList
        linkType={false}
        enablePlaceholders
        placeholdersCount={3}
        itemGridSizes={{
          xs: 12,
          sm: 6,
          md: 4
        }}
        render={(record, _, isPlaceholder) => {
          const isSelected = record === selected;
          const projectType = projectTypes?.find(({ projectTypeVersion }) =>
            projectTypeVersion?.includes(record?.projectTypeVersion)
          );
          // const projectTypeVersion = projectTypeVersions?.find(
          //   ({ id }) => id === record?.projectTypeVersion
          // );

          return (
            <StyledCardContent
              className={clsx(cardContentClasses.cardContent, {
                [cardContentClasses.selected]: isSelected,
                [cardContentClasses.placeholder]: isPlaceholder
              })}
              onClick={e => onCardClick(record, e)}
            >
              <Stack
                className={cardContentClasses.brand}
                flexDirection={'column'}
                alignItems={'center'}
                spacing={1}
              >
                {isPlaceholder ? (
                  <Skeleton
                    className={clsx(
                      cardContentClasses.brandImage,
                      cardContentClasses.brandImageSkeleton
                    )}
                    variant={'circular'}
                  />
                ) : (
                  <img
                    className={cardContentClasses.brandImage}
                    src={projectType.logo}
                    width='auto'
                    height={'96'}
                  />
                )}

                <Typography
                  variant='h5'
                  component='p'
                  className={cardContentClasses.brandName}
                  sx={theme => ({
                    color: theme.palette.text.primary,
                    fontWeight: 500
                  })}
                >
                  {isPlaceholder ? (
                    <Skeleton width={60} variant={'text'} />
                  ) : (
                    <>
                      {record.name}
                      <Typography
                        component={'span'}
                        variant='body2'
                        sx={{ display: 'block', mt: 0.75 }}
                      >
                        <RichTextField source={'description'} />
                      </Typography>
                    </>
                  )}
                </Typography>
              </Stack>
            </StyledCardContent>
          );
        }}
      />
    </ListContextProvider>
  );
};

ProjectTemplateSelector.defaultProps = {
  empty: null
};

const CARD_CONTENT_PREFIX = 'ProjectTemplateSelector';

const cardContentClasses = {
  cardContent: `${CARD_CONTENT_PREFIX}-cardContent`,
  selected: `${CARD_CONTENT_PREFIX}-selected`,
  placeholder: `${CARD_CONTENT_PREFIX}-placeholder`,
  brand: `${CARD_CONTENT_PREFIX}-brand`,
  brandName: `${CARD_CONTENT_PREFIX}-brandName`,
  brandImage: `${CARD_CONTENT_PREFIX}-brandImage`,
  brandImageSkeleton: `${CARD_CONTENT_PREFIX}-brandImageSkeleton`
};

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  '&, & *': {
    transitionDuration: '0.3s',
    transitionTimingFunction: 'ease-in-out'
  },
  [`&.${cardContentClasses.cardContent}`]: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    rowGap: theme.spacing(2),
    minHeight: theme.spacing(24),
    position: 'relative',
    textAlign: 'center',
    paddingBottom: theme.spacing(1),
    // filter: 'grayscale(0.5)',
    transitionProperty: 'filter',
    cursor: 'pointer',
    // border: `2px solid transparent`,
    [theme.breakpoints.up('md')]: {
      minHeight: theme.spacing(30.25)
    }
  },

  [`& .${cardContentClasses.brand}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    [`& .${cardContentClasses.brandName}`]: {
      [theme.breakpoints.down('sm')]: {
        fontSize: '80%'
      }
    },
    [`& .${cardContentClasses.brandImage}`]: {
      height: 64,
      marginBottom: 8,
      [`&.${cardContentClasses.brandImageSkeleton}`]: {
        width: 64
      },
      [theme.breakpoints.up('md')]: {
        height: 96,
        [`&.${cardContentClasses.brandImageSkeleton}`]: {
          width: 96
        }
      }
    }
  },

  [`&.${cardContentClasses.selected}`]: {
    // filter: 'grayscale(0)',
    transition: 'border-color 0.3s ease-out'
  },

  [`&.${cardContentClasses.selected}, &:not(.${cardContentClasses.placeholder}):hover`]: {
    // border: `2px solid ${
    //   theme.palette.mode === 'light'
    //     ? 'transparent'
    //     : theme.palette.text.primary
    // }`,
    borderRadius: theme.shape.borderRadius
  }
}));

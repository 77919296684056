import React, { useState } from 'react';
import {
  Datagrid,
  DateField,
  FunctionField,
  Pagination,
  ReferenceField,
  TextField,
  useGetList,
  useGetResourceLabel,
  useLocaleState,
  useRecordContext,
  useResourceContext,
  useTranslate
} from 'react-admin';
import { Box, Typography } from '@mui/material';
import { AllInbox } from '@mui/icons-material';
import { DownloadButton } from '../Export/DownloadButton';
import { useEnvironmentProgressBar } from '@rc/admin/hooks';
import { MESSENGER_ACTION_AMQP_TYPES } from '@rc/admin/constants';

const ROWS_PER_PAGE_OPTIONS = [5, 10, 25];

export const DataImportList = () => {
  const record = useRecordContext();
  const [locale] = useLocaleState();
  const sort = { field: 'createdAt', order: 'DESC' };
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(ROWS_PER_PAGE_OPTIONS[0]);
  const { data, isLoading, total } = useGetList(
    'imported_datas',
    {
      pagination: { page, perPage },
      filter: { environment: record?.['@id'] },
      sort
    },
    {
      enabled: !!record
    }
  );

  const { ongoingProgresses } = useEnvironmentProgressBar();

  if (!record) {
    return null;
  }

  return (
    <>
      <Datagrid
        bulkActionButtons={null}
        empty={<Empty />}
        isLoading={isLoading}
        data={data || []}
        sort={sort}
        total={total}
        sx={{ width: '100%' }}
      >
        <ReferenceField
          label={'Type'}
          reference='data_transfer_data_types'
          source='dataTransferDataType'
        >
          <TextField source='name' />
        </ReferenceField>
        <DateField
          source={'createdAt'}
          label={'resources.imported_datas.fields.createdAt'}
          locales={locale}
          showTime
        />
        <FunctionField
          render={importRecord => (
            <DownloadButton
              progress={ongoingProgresses.find(
                progress =>
                  (progress.actionType ===
                    MESSENGER_ACTION_AMQP_TYPES.IMPORT_MEDIA ||
                    progress.actionType ===
                      MESSENGER_ACTION_AMQP_TYPES.IMPORT_DATABASE) &&
                  progress.uuid === importRecord.uuid
              )}
              sx={{ float: 'right' }}
            />
          )}
        />
      </Datagrid>
      {total > ROWS_PER_PAGE_OPTIONS[0] && (
        <Pagination
          total={total}
          page={page}
          setPage={setPage}
          perPage={perPage}
          setPerPage={setPerPage}
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          isLoading={isLoading}
          sx={{ width: '100%' }}
        />
      )}
    </>
  );
};

const Empty = () => {
  const t = useTranslate();
  const getResourceLabel = useGetResourceLabel();
  const resource = useResourceContext();

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '100%',
        textAlign: 'center'
      }}
    >
      <AllInbox fontSize={'large'} />
      <Typography>
        {t('ra.page.empty', { name: getResourceLabel(resource, 2) })}
      </Typography>
    </Box>
  );
};

export default DataImportList;

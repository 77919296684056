import React from 'react';
import { OrgContextProvider } from './OrgContext';
import { DebugModeContextProvider } from './DebugModeContext';
import { LayoutContextProvider } from './LayoutContext';
// import { FeatureTourProvider } from './FeatureTourContext';

export { useOrgContext } from './OrgContext';
export { ProjectContextProvider, useProjectContext } from './ProjectContext';
export {
  DebugModeContextProvider,
  useDebugModeContext,
  useIsDebugMode
} from './DebugModeContext';
export * from './FeatureTourContext';
export * from './RCWorkerMessagesContext';
export * from './LayoutContext';

const contextProviders = [
  OrgContextProvider,
  DebugModeContextProvider,
  LayoutContextProvider
  // FeatureTourProvider
];

const ContextProvider = props => {
  const { children } = props;
  return contextProviders.reduceRight((memo, ContextProvider) => {
    return <ContextProvider>{memo}</ContextProvider>;
  }, children);
};

export default ContextProvider;

import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardHeader,
  Chip,
  Stack,
  styled,
  useTheme
} from '@mui/material';
import { fNumber } from '@minimal-theme/utils/format-number';
import Chart, { useChart } from '../../ui/Chart';
import { useEnvironmentsUsageChart } from '@rc/admin/hooks';

const CHART_HEIGHT = 360;

const LEGEND_HEIGHT = 70;

export const EnvironmentsUsageChart = props => {
  const { title, subheader, ...rest } = props;
  const { chart, currency } = useEnvironmentsUsageChart();

  const theme = useTheme();

  const { colors, series, options } = chart;

  const chartSeries = series.map(i => i.value);

  const chartOptions = useChart({
    chart: {
      sparkline: {
        enabled: true
      }
    },
    colors,
    labels: series.map(i => i.label),
    stroke: { colors: [theme.palette.background.paper] },
    legend: {
      offsetY: 0,
      floating: true,
      position: 'bottom',
      horizontalAlign: 'center'
    },
    tooltip: {
      fillSeriesColor: false,
      y: {
        formatter: value => `${value.toFixed(2)} ${currency}`,
        title: {
          formatter: seriesName => `${seriesName}`
        }
      }
    },
    plotOptions: {
      pie: {
        donut: {
          size: '90%',
          labels: {
            value: {
              formatter: value => `${fNumber(value)} ${currency}`
            },
            total: {
              formatter: w => {
                const sum = w.globals.seriesTotals.reduce((a, b) => a + b, 0);
                return `${sum.toFixed(2)} ${currency}`;
              }
            }
          }
        }
      }
    },
    ...options
  });

  return (
    <Card
      {...rest}
      sx={{
        height: '100%',
        position: 'relative',
        '&:hover': {
          '& #overlay': {
            display: 'none'
          }
        }
      }}
    >
      <CardHeader
        title={
          <Stack direction={'row'} spacing={2} alignItems={'center'}>
            {title}
            <Chip
              size='small'
              label='Coming soon'
              color='primary'
              sx={{ fontWeight: 600 }}
            />
          </Stack>
        }
        subheader={subheader}
        sx={{ mb: 5 }}
      />

      <Box
        id='overlay'
        sx={{
          position: 'absolute',
          zIndex: 2,
          left: 0,
          top: 0,
          width: '100%',
          height: '100%',
          opacity: 0.35,
          backgroundColor: '#acacac05',
          backdropFilter: 'blur(6px)'
        }}
      />

      <StyledChart
        dir='ltr'
        type='donut'
        series={chartSeries}
        options={chartOptions}
        width='100%'
        height={280}
      />
    </Card>
  );
};

const StyledChart = styled(Chart)(({ theme }) => ({
  height: CHART_HEIGHT,
  '& .apexcharts-canvas, .apexcharts-inner, svg, foreignObject': {
    height: `100% !important`
  },
  '& .apexcharts-legend': {
    height: LEGEND_HEIGHT,
    borderTop: `dashed 1px ${theme.palette.divider}`,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`
  }
}));

EnvironmentsUsageChart.propTypes = {
  chart: PropTypes.object,
  subheader: PropTypes.string,
  title: PropTypes.string
};

EnvironmentsUsageChart.defaultProps = {};

import {
  CPU_RESOURCE_MAX_VALUE,
  CPU_RESOURCE_MIN_VALUE,
  MEMORY_RESOURCE_MAX_VALUE,
  MEMORY_RESOURCE_MIN_VALUE,
  NODE_RESOURCE_VALUES,
  STORAGE_RESOURCE_MAX_VALUE,
  STORAGE_RESOURCE_MIN_VALUE
} from '@rc/admin/constants';
import { minValue, required } from 'react-admin';

export const CpuInputConfig = {
  step: 250,
  // Min and Max values can be overrided under ResourceType Edit page
  min: CPU_RESOURCE_MIN_VALUE,
  max: CPU_RESOURCE_MAX_VALUE,
  marks: true,
  unit: 'm',
  label: 'resources.resource_types.inputs.cpu.label',
  tooltipText: 'resources.resource_types.inputs.cpu.tooltip',
  validate: [required(), minValue(`${CPU_RESOURCE_MIN_VALUE} m`)]
};

export const MemoryInputConfig = {
  step: 512,
  // Min and Max values can be overrided under ResourceType Edit page
  min: MEMORY_RESOURCE_MIN_VALUE,
  max: MEMORY_RESOURCE_MAX_VALUE,
  marks: true,
  unit: 'Mi',
  label: 'resources.resource_types.inputs.memory.label',
  validate: [required(), minValue(`${MEMORY_RESOURCE_MIN_VALUE} Mi`)]
};

export const StorageInputConfig = {
  step: 5000,
  // Min and Max values can be overrided under ResourceType Edit page
  min: STORAGE_RESOURCE_MIN_VALUE,
  max: STORAGE_RESOURCE_MAX_VALUE,
  marks: true,
  // Convert to G
  format: v => {
    return !Number.isNaN(v) && typeof v === 'number' ? v / 1000 : 0;
  },
  // Convert to M
  parse: v => {
    return !Number.isNaN(v) && typeof v === 'number' ? v * 1000 : 0;
  },
  unit: 'Gi',
  label: 'resources.resource_types.inputs.storage.label',
  validate: [
    (value, values) => {
      const requiredValResult = required()(value, values);
      if (requiredValResult) {
        return requiredValResult;
      }

      // Persistent storage
      if (value >= 0) {
        return minValue(`${STORAGE_RESOURCE_MIN_VALUE / 1000} Gi`)(
          value,
          values
        );
      }

      // Otherwise, ephemeral storage
      return;
    }
  ],
  canReduceOnEdit: false
};

export const NodeInputConfig = {
  choices: NODE_RESOURCE_VALUES.map(value => ({ value })),
  optionValue: 'value',
  optionText: 'value',
  label: 'resources.resource_types.inputs.node.label',
  translateChoice: false,
  defaultValue: NODE_RESOURCE_VALUES[0],
  validate: [required(), minValue(NODE_RESOURCE_VALUES[0])]
};

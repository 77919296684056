import React from 'react';
import {
  ReferenceArrayInput,
  ReferenceInput,
  required,
  useTranslate
} from 'react-admin';
import { Stack } from '@mui/system';
import { SectionTitle } from '@rc/admin/components';
import { PREFETCH_PAGINATION } from '@rc/admin/constants';
import { ComponentSelector, ProjectTypeSelector } from '../../components';

export const ProjectCreateStepStack = () => {
  const t = useTranslate();

  return (
    <Stack alignItems='flex-start' sx={{ width: '100%' }}>
      <SectionTitle mb={0}>
        {t('resources.projects.sections.project_type.title')}
      </SectionTitle>
      <ReferenceInput
        source={'projectTypeVersion'}
        reference={'project_type_versions'}
        sort={{ field: 'version', order: 'ASC' }}
        {...PREFETCH_PAGINATION}
      >
        <ProjectTypeSelector
          SelectInputProps={{
            label: 'resources.projects.fields.projectTypeVersion',
            optionText: choice => choice?.version,
            validate: [required()]
          }}
        />
      </ReferenceInput>
      <SectionTitle>
        {t('resources.projects.sections.components.title')}
      </SectionTitle>
      <ReferenceArrayInput
        reference={'component_versions'}
        source={'componentVersion'}
      >
        <ComponentSelector
          label={t('resources.projects.sections.components.subtitle')}
        />
      </ReferenceArrayInput>
    </Stack>
  );
};

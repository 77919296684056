// ----------------------------------------------------------------------

export function radio (theme) {
  return {
    // CHECKBOX, RADIO, SWITCH
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          ...theme.typography.body2
        }
      }
    },

    MuiRadio: {
      styleOverrides: {
        root: {
          padding: theme.spacing(1)
        }
      }
    }
  };
}

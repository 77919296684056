import React from 'react';
import { OrganisationList } from './List';
import { OrganisationCreate } from './Create';
import { OrganisationEdit } from './Edit';
import Iconify from '@rc/admin/components/ui/Iconify';

export default {
  name: 'organisations',
  list: OrganisationList,
  edit: OrganisationEdit,
  create: OrganisationCreate,
  icon: () => <Iconify icon='solar:buildings-2-bold-duotone' />
};

import { Grid } from '@mui/material';
import React from 'react';
import { SelectInput, required, useTranslate } from 'react-admin';

/**
 *
 * @param {import('react-admin').SelectInputProps} props
 * @returns
 */
export const AllowHtaccessDevtoolsProtectionField = props => {
  const t = useTranslate();

  return (
    <Grid container>
      <Grid item xs={12} sm={6}>
        <SelectInput
          source={'isAllowHtaccessDevtoolsProtection'}
          label={t(
            'resources.environments.fields.isAllowHtaccessDevtoolsProtection'
          )}
          defaultValue={false}
          choices={[
            { id: false, name: `Oauth (${t('misc.default')})` },
            { id: true, name: `Htaccess` }
          ]}
          fullWidth
          validate={required()}
          helperText={false}
          {...props}
        />
      </Grid>
    </Grid>
  );
};

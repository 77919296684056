import React, { useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Empty as RaEmpty,
  ListContextProvider,
  ResourceContextProvider,
  useGetList,
  useSidebarState,
  useTranslate,
  useListContext
} from 'react-admin';
import { Button, Stack, useTheme } from '@mui/material';
import { useOrgContext } from '@rc/admin/context';
import { CardList } from '@rc/admin/components';
import { DashboardBox } from './DashboardBox';
import { ProjectCardContent } from '@rc/admin/resources/project/components';
import useMercureSubscription from '@rc/admin/moduleOverrides/useMercureSubscription';

/**
 *
 * @typedef {{ EmptyComponent: import('react').Component }} RecentProjectsProps
 *
 * @param {import('@mui/material').CardProps & RecentProjectsProps} props
 * @returns
 */
export const RecentProjects = props => {
  const { CardProps, EmptyComponent } = props;
  const t = useTranslate();
  const { teamId } = useOrgContext();
  const projectsListProps = useListContext();
  const { data, total, isLoading, isFetching } = projectsListProps;
  
  const [isExpanded, setIsExpanded] = useState(false);
  const { itemGridSizes, maxItemsLength } = useSizes();
  
  useMercureSubscription('projects', data?.map(record => record.id));

  const filteredData = data?.slice(
    0,
    isExpanded ? data.length : maxItemsLength
  );


  const isEmpty = !!filteredData?.length && !isLoading;

  return (
    <DashboardBox
      title={
        isEmpty &&
        t('misc.recent', {
          name: t('resources.projects.name', { smart_count: 2 })
        })
      }
      actions={
        isEmpty && (
          <Stack flexDirection={'row'} alignItems={'flex-end'} gap={2}>
            {total > maxItemsLength && (
              <Button
                onClick={() => setIsExpanded(curr => !curr)}
                disabled={isLoading || isFetching}
                size='small'
                variant='outlined'
              >
                {!isExpanded
                  ? `${t('action.show_more')}...`
                  : t('action.show_less')}
              </Button>
            )}
            <Button
              component={Link}
              to={`/projects`}
              size='small'
              variant='outlined'
            >
              {t('action.see_all')}
            </Button>
          </Stack>
        )
      }
      CardProps={CardProps}
    >
      <ListContextProvider
        value={{
          data: teamId ? filteredData : [],
          isLoading: teamId ? projectsListProps.isLoading : true,
          isFetching: projectsListProps.isFetching,
          refetch: projectsListProps.refetch,
          total: teamId ? projectsListProps.total : 0,
          resource: 'projects',
          sort: { field: 'id', order: 'ASC' },
          page: 1
        }}
      >
        <ResourceContextProvider value={'projects'}>
          <CardList
            enablePlaceholders
            placeholdersCount={maxItemsLength}
            itemGridSizes={itemGridSizes}
            spacing={1}
            empty={<EmptyComponent />}
            render={(record, _, isPlaceholder) => (
              <ProjectCardContent
                variant='normal'
                record={record}
                isPlaceholder={isPlaceholder}
              />
            )}
          />
        </ResourceContextProvider>
      </ListContextProvider>
    </DashboardBox>
  );
};

RecentProjects.defaultProps = {
  CardProps: {},
  EmptyComponent: RaEmpty
};

const useSizes = () => {
  const [isSidebarOpen] = useSidebarState();
  const theme = useTheme();

  const itemGridSizes = useMemo(
    () => ({
      xs: 12,
      sm: isSidebarOpen ? 12 : 6,
      md: isSidebarOpen ? 6 : 4,
      lg: 4
    }),
    [isSidebarOpen]
  );

  const maxItemsLength = Object.keys(itemGridSizes).reduce(
    (acc, curr, index, array) => {
      if (acc !== 0) return acc;

      const { matches } = window.matchMedia(
        `(max-width: ${theme.breakpoints.values[curr] - 0.05}px)`
      );
      if (!matches && index < array.length - 1) return 0;
      return (12 / itemGridSizes[curr]) * 2;
    },
    0
  );

  return {
    itemGridSizes,
    maxItemsLength
  };
};

import React from 'react';
import {
  Datagrid,
  List,
  ReferenceField,
  SimpleList,
  TextField
} from 'react-admin';
import { EditButton } from '@rc/admin/ra';
import { useMediaQuery } from '@mui/material';
import { OrganisationField } from '@rc/admin/components';
import { ROLES } from '@rc/admin/constants';
import { useIsRole } from '@rc/admin/hooks/domain';

export const TeamList = () => {
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const { result: isAdmin } = useIsRole(ROLES.ADMIN);

  return (
    <List perPage={25} exporter={false}>
      {isSmall ? (
        <SimpleList
          primaryText={() => <TextField source='name' />}
          secondaryText={() => (
            <ReferenceField source='organisation' reference='organisations'>
              <OrganisationField />
            </ReferenceField>
          )}
        />
      ) : (
        <Datagrid
          bulkActionButtons={isAdmin}
          isRowSelectable={record => (isAdmin ? !record.isDefault : false)}
        >
          <TextField source='name' />
          <ReferenceField source='organisation' reference='organisations'>
            <OrganisationField />
          </ReferenceField>
          <EditButton />
        </Datagrid>
      )}
    </List>
  );
};

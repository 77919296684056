import React, { useMemo } from 'react';
import { useLocation } from 'react-router';
import {
  Create,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  ReferenceField,
  TextField,
  BooleanInput
} from 'react-admin';
import { styled } from '@mui/material';
import { OneColumnFormStyles } from '@rc/admin/theme/styles';

export const ComponentVersionEnvVarCreate = () => {
  const { search } = useLocation();

  const initialComponentVersionId = useMemo(
    () => new URLSearchParams(search).get('componentVersion'),
    [search]
  );

  return (
    <StyledCreate>
      <SimpleForm
        defaultValues={{
          isEnabled: true,
          componentVersion: initialComponentVersionId
        }}
        className={classes.form}
      >
        {!initialComponentVersionId ? (
          <ReferenceInput
            source={'componentVersion'}
            reference={'component_versions'}
          >
            <SelectInput optionText={'version'} fullWidth />
          </ReferenceInput>
        ) : (
          <ReferenceField
            source={'componentVersion'}
            reference={'component_versions'}
            record={{
              componentVersion: initialComponentVersionId
            }}
          >
            <TextField source={'version'} fullWidth />
          </ReferenceField>
        )}
        <TextInput source={'key'} fullWidth />
        <TextInput source={'note'} fullWidth />
        <BooleanInput source={'isRequired'} fullWidth />
        <BooleanInput source={'isEncrypted'} fullWidth />
      </SimpleForm>
    </StyledCreate>
  );
};

const PREFIX = 'ComponentVersionEnvVarCreate';

const classes = {
  form: `${PREFIX}-form`
};

const StyledCreate = styled(Create)(({ theme }) => ({
  [`& .${classes.form}`]: {
    ...OneColumnFormStyles(theme)
  }
}));

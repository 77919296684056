import React from 'react';
import { Avatar as MuiAvatar, styled } from '@mui/material';
import { DEFAULT_PROJECT_COLOR } from '@rc/admin/constants';
import { BubbleChart as DefaultAvatarIcon } from '@mui/icons-material';

const formatCode = code => code.toUpperCase().slice(0, 2);

/**
 * @typedef {object} AvatarProps
 * @property {string} code
 * @property {string} titleAccess
 *
 * @param {AvatarProps & import('@mui/material').AvatarProps} props
 * @returns
 */
const Avatar = props => {
  const {
    code,
    color: backgroundColor,
    titleAccess,
    children,
    ...rest
  } = props;

  return (
    <StyledAvatar
      title={titleAccess}
      backgroundColor={backgroundColor}
      {...rest}
    >
      {children || (code ? formatCode(code) : <DefaultAvatarIcon />)}
    </StyledAvatar>
  );
};

const StyledAvatar = styled(MuiAvatar, {
  shouldForwardProp: prop => prop !== 'backgroundColor'
})(({ backgroundColor }) => ({
  backgroundColor,
  color: '#fff',
  fontSize: 12,
  width: 24,
  height: 24
}));

Avatar.defaultProps = {
  color: DEFAULT_PROJECT_COLOR,
  style: {}
};

export default Avatar;

import React from 'react';
import { FormControlLabel, Switch } from '@mui/material';
import {
  EXTERNAL_GIT_TYPE_ID,
  INTERNAL_GIT_TYPE_ID
} from '@rc/admin/constants';
import { useTranslate } from 'react-admin';
import { Controller, useFormContext } from 'react-hook-form';

export const GitTypeInput = props => {
  const { source } = props;
  const t = useTranslate();
  const { control } = useFormContext();

  const label = t('components.git.fields.gitType');

  return (
    <FormControlLabel
      control={
        <Controller
          name={source}
          control={control}
          render={({ field }) => (
            <Switch
              checked={field.value === EXTERNAL_GIT_TYPE_ID}
              onChange={e => {
                field.onChange(
                  e.target.checked ? EXTERNAL_GIT_TYPE_ID : INTERNAL_GIT_TYPE_ID
                );
              }}
              inputProps={{
                'aria-label': label
              }}
            />
          )}
        />
      }
      label={label}
    />
  );
};

import React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  BooleanInput,
  Toolbar,
  SaveButton,
  ToolbarClasses
} from 'react-admin';
import { Breadcrumbs, DeleteButton, EditTitle } from '@rc/admin/components';
import { styled } from '@mui/material';
import { OneColumnFormStyles } from '@rc/admin/theme/styles';

const redirect = (_, __, record) =>
  `project_type_version_env_vars/${encodeURIComponent(
    record?.projectTypeVersionEnvVar
  )}`;

export const ProjectTypeVersionEnvVarExampleEdit = () => {
  return (
    <StyledEdit
      title={<EditTitle source={'value'} />}
      actions={
        <Breadcrumbs
          label={<BreadcrumbsLabel />}
          parents={breadcrumbsParents}
        />
      }
      redirect={redirect}
      mutationMode='pessimistic'
    >
      <SimpleForm
        className={classes.form}
        toolbar={
          <Toolbar>
            <div className={ToolbarClasses.defaultToolbar}>
              <SaveButton />
              <DeleteButton redirect={redirect} />
            </div>
          </Toolbar>
        }
      >
        <TextInput source={'value'} fullWidth />
        <BooleanInput source={'isDefault'} fullWidth />
      </SimpleForm>
    </StyledEdit>
  );
};

const PREFIX = 'ProjectTypeVersionEnvVarExampleEdit';

const classes = {
  form: `${PREFIX}-form`
};

const StyledEdit = styled(Edit)(({ theme }) => ({
  [`& .${classes.form}`]: {
    ...OneColumnFormStyles(theme)
  }
}));

const breadcrumbsParents = [
  {
    reference: 'project_type_version_env_vars',
    source: 'projectTypeVersionEnvVar'
  },
  { reference: 'project_type_versions', source: 'projectTypeVersion' },
  { reference: 'project_types', source: 'projectType' }
];

const BreadcrumbsLabel = props => {
  const { data } = props;

  switch (data['@type']) {
    case 'ProjectTypeVersionEnvVarExample':
      return data.value;
    case 'ProjectTypeVersionEnvVar':
      return data.key;
    case 'ProjectTypeVersion':
      return data.version;
    default:
      return data.name;
  }
};

import { ComponentVersionEdit } from './Edit';
import { ComponentVersionCreate } from './Create';

export default {
  name: 'component_versions',
  edit: ComponentVersionEdit,
  create: ComponentVersionCreate,
  options: {
    showInMenu: false
  }
};

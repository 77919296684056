import React, { useState } from 'react';
import {
  BooleanInput,
  DeleteButton,
  FormTab,
  SaveButton,
  TabbedForm,
  TabbedFormTabs,
  Toolbar as RaToolbar,
  useRecordContext,
  useTranslate,
  useNotify,
  useUnselect,
  useSidebarState
} from 'react-admin';
import { Box, Card, Stack, styled, useMediaQuery } from '@mui/material';
import {
  AllowHtaccessDevtoolsProtectionField,
  CustomDomainFields,
  EnvironmentEnvVarInputs,
  EnvironmentResourceAndComponentInputs,
  EnvironmentUrlField,
  MagentoHostMappingsFields,
  EnvironmentNameInputs
} from '../components';
import { TwoColumnFormStyles } from '@rc/admin/theme/styles';
import { ProjectContextProvider } from '@rc/admin/context';
import { useCanRetry, useIsTemplateResource } from '@rc/admin/hooks';
import { STATE_IDS } from '@rc/admin/constants';
import { createIRI } from '@rc/admin/utils';
import {
  HelpCard,
  HelpLayout,
  HelpLayoutClasses,
  Image,
  SectionHelper,
  SectionTitle
} from '@rc/admin/components';

export const EnvironmentEditForm = () => {
  const t = useTranslate();
  const [isSidebarOpen] = useSidebarState();
  const isSmallContentWidthForHelpLayout = useMediaQuery(theme =>
    theme.breakpoints.down(isSidebarOpen ? 'xl' : 'lg')
  );
  const isTemplate = useIsTemplateResource();

  return (
    <Card>
      <StyledBox>
        <TabbedForm
          className={classes.form}
          toolbar={<Toolbar />}
          defaultValues={{
            environmentComponent: [],
            envVar: [],
            isStripDatabase: false,
            ...(!isTemplate
              ? {
                  dataTransfer: {
                    uploadProcess: 'import-database',
                    cleanup: true,
                    fixUrls: false
                  }
                }
              : {})
          }}
          reValidateMode='onSubmit'
          tabs={
            <TabbedFormTabs
              variant={'scrollable'}
              scrollButtons='auto'
              allowScrollButtonsMobile
            />
          }
        >
          <FormTab label='resources.environments.tabs.general'>
            <ProjectContextProvider>
              <Stack className={classes.details} alignItems='flex-start'>
                <SectionTitle mb={0.5}>
                  {t('resources.environments.sections.name')}
                </SectionTitle>
                <EnvironmentNameInputs />
                <EnvironmentUrlField />
                {/* {!isTemplate && (
                <Grid container wrap='wrap' spacing={1} mb={1.5}>
                  <Grid item xs={12}>
                    <Labeled>
                      <ReferenceField
                        source={'provider'}
                        reference={'providers'}
                      >
                        <TextField source={'name'} fullWidth />
                      </ReferenceField>
                    </Labeled>
                  </Grid>
                  <Grid item xs={12}>
                    <Labeled>
                      <ReferenceField source={'cluster'} reference={'clusters'}>
                        <TextField source={'name'} fullWidth />
                      </ReferenceField>
                    </Labeled>
                  </Grid>
                </Grid>
              )} */}
                {isTemplate && (
                  <Box>
                    <BooleanInput
                      source={'isInitSampleData'}
                      label={'resources.environments.fields.isInitSampleData'}
                      helperText={false}
                    />
                  </Box>
                )}

                <Box width={'100%'}>
                  {!isTemplate && (
                    <>
                      <SectionTitle mb={0.5}>
                        {t('resources.environments.sections.infrastructure')}
                      </SectionTitle>
                      <SectionTitle level={2} mb={0.5}>
                        {t('resources.environments.sections.domains')}
                      </SectionTitle>
                      <SectionHelper mb={1}>
                        {t('resources.environments.helpers.domains')}
                      </SectionHelper>
                      <CustomDomainFields source='environmentCustomDomain' />
                      <SectionTitle level={2} mb={0.5}>
                        {t('resources.environments.sections.magentoSettings')}
                      </SectionTitle>
                      <MagentoHostMappingsFields source='environmentMagentoHostMapping' />
                    </>
                  )}

                  {!isTemplate && (
                    <>
                      <SectionTitle mb={0.5}>
                        {t('resources.environments.sections.advanced')}
                      </SectionTitle>
                      <Box id='is-allow-outgoing-emails-field' mt={0.5} mb={2}>
                        <SectionHelper mb={1}>
                          {t(
                            'resources.environments.helpers.isAllowHtaccessDevtoolsProtection'
                          )}
                        </SectionHelper>
                        <AllowHtaccessDevtoolsProtectionField />
                      </Box>
                      <Box id='is-allow-outgoing-emails-field'>
                        <SectionHelper mb={0.5}>
                          {t(
                            'resources.environments.helpers.isAllowOutgoingEmails'
                          )}
                        </SectionHelper>
                        <BooleanInput
                          source={'isAllowOutgoingEmails'}
                          label={
                            'resources.environments.fields.isAllowOutgoingEmails'
                          }
                          helperText={false}
                        />
                      </Box>
                    </>
                  )}
                </Box>
              </Stack>
            </ProjectContextProvider>
          </FormTab>
          <FormTab label='resources.environments.tabs.resources'>
            <ProjectContextProvider>
              <HelpLayout
                className={classes.resources}
                isTooltip={isSmallContentWidthForHelpLayout}
                helpers={
                  <HelpCard
                    title='resources.environments.helpers.environmentComponent.title'
                    sx={{ position: 'sticky', top: 72 }}
                  >
                    {
                      'resources.environments.helpers.environmentComponent.content'
                    }
                  </HelpCard>
                }
              >
                <Box sx={TwoColumnFormStyles}>
                  <SectionTitle mb={0.5}>
                    {t('resources.projects.sections.resources')}
                  </SectionTitle>
                  <SectionHelper mb={3}>
                    {t('resources.environments.helpers.resources')}
                  </SectionHelper>
                </Box>
                <EnvironmentResourceAndComponentInputs
                  showEstimatedCosts={false}
                />
              </HelpLayout>
            </ProjectContextProvider>
          </FormTab>
          <FormTab label='resources.environments.tabs.variables'>
            <ProjectContextProvider>
              <HelpLayout
                className={classes.variables}
                isTooltip={isSmallContentWidthForHelpLayout}
                helpers={
                  <HelpCard title='resources.projects.helpers.envVars.title'>
                    {t('resources.projects.helpers.envVars.content')}
                    <Image
                      src='/images/helper-envVars.png'
                      alt='Variables helper image'
                      width={1338}
                      height={678}
                    />
                  </HelpCard>
                }
              >
                <SectionTitle mb={0.5}>
                  {t('resources.environments.sections.envVars.title')}
                </SectionTitle>
                <SectionHelper mb={1}>
                  {t('resources.environments.sections.envVars.subtitle')}
                </SectionHelper>

                <EnvironmentEnvVarInputs
                  source={'envVar'}
                  label={'Environment variables'}
                  fullWidth
                />
              </HelpLayout>
            </ProjectContextProvider>
          </FormTab>
        </TabbedForm>
      </StyledBox>
    </Card>
  );
};

const Toolbar = () => {
  const record = useRecordContext();
  const canRetry = useCanRetry();
  const isTemplate = useIsTemplateResource();
  const notify = useNotify();
  const unselect = useUnselect('projects');
  const [deleteRequested, setDeleteRequested] = useState(false);

  const onDeleteSuccess = () => {
    notify('resources.projects.notification.delete_requested', {
      type: 'info'
    });
    unselect([record.id]);
    setDeleteRequested(true);
  };

  const isDeleting =
    deleteRequested ||
    record?.state === createIRI('states', STATE_IDS.DEL) ||
    record?.state === createIRI('states', STATE_IDS.DEL_REQ) ||
    record?.state === createIRI('states', STATE_IDS.DELETED);

  return (
    <RaToolbar className={classes.toolbar}>
      <Box className={classes.toolbarGroup}>
        <SaveButton
          label={!isTemplate && canRetry ? 'Try again' : undefined}
          icon={!isTemplate && canRetry ? null : undefined}
          alwaysEnable={!isDeleting && !isTemplate && canRetry}
          disabled={isDeleting}
        />
      </Box>
      <Stack flexDirection={'row'} gap={2} alignItems={'center'}>
        {!isDeleting && (
          <DeleteButton
            variant='text'
            mutationMode={'pessimistic'}
            mutationOptions={{ onSuccess: onDeleteSuccess }}
            confirmTitle={'ra.message.delete_content'}
            translateOptions={{
              name: record?.name
            }}
            sx={{ marginTop: 0 }}
          />
        )}
      </Stack>
    </RaToolbar>
  );
};

const PREFIX = 'EnvironmentEdit';

const classes = {
  form: `${PREFIX}-form`,
  details: `${PREFIX}-details`,
  toolbar: `${PREFIX}-toolbar`,
  toolbarGroup: `${PREFIX}-toolbarGroup`,
  resources: `${PREFIX}-resources`,
  variables: `${PREFIX}-variables`
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`& .${classes.form}`]: {
    maxWidth: 'calc(100vw - 32px)'
  },

  [`& .${classes.details}`]: {
    ...TwoColumnFormStyles(theme)
  },

  [`& .${classes.toolbar}`]: {
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between'
  },

  [`& .${classes.toolbarGroup}`]: {
    display: 'flex',
    columnGap: theme.spacing(2),
    alignItems: 'center'
  },

  [`& .${classes.resources}.${HelpLayoutClasses.root}:not(.${HelpLayoutClasses.withTooltip}) .${HelpLayoutClasses.helper}`]:
    {
      minHeight: 400,
      display: 'block'
    },

  [`& .${classes.variables} .${HelpLayoutClasses.content}`]: {
    maxWidth: '50em'
  }
}));

import React from 'react';
import { CreateDialogBase } from './CreateDialogBase';
import { CreateDialogView } from './CreateDialogView';
import { SideDialogContextProvider } from '../../ui/SideDialog';

/**
 *
 * @param {import('react-admin').EditProps & { source: string } & import('react-admin').ButtonProps} props
 * @returns
 */
export const CreateDialog = props => {
  const {
    resource,
    id,
    mutationMode,
    mutationOptions,
    queryOptions,
    redirect,
    transform,
    disableAuthentication,
    ...rest
  } = props;

  return (
    <SideDialogContextProvider>
      <CreateDialogBase
        resource={resource}
        id={id}
        mutationMode={mutationMode}
        mutationOptions={mutationOptions}
        queryOptions={queryOptions}
        redirect={redirect}
        transform={transform}
        disableAuthentication={disableAuthentication}
      >
        <CreateDialogView {...rest} />
      </CreateDialogBase>
    </SideDialogContextProvider>
  );
};

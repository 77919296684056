import React, { cloneElement, forwardRef } from 'react';
import clsx from 'clsx';
import { styled, MenuItem as MuiMenuItem, ListItemIcon } from '@mui/material';

export const MenuItemButton = forwardRef((props, ref) => {
  const {
    primaryText,
    leftIcon,
    rightIcon,
    classes: classesOverride,
    ...rest
  } = props;

  return (
    <StyledMuiMenuItem {...rest} ref={ref}>
      {leftIcon && (
        <ListItemIcon
          className={clsx(classes.leftIcon, classesOverride.leftIcon)}
        >
          {cloneElement(
            leftIcon,
            typeof primaryText === 'string' ? { titleAccess: primaryText } : {}
          )}
        </ListItemIcon>
      )}
      <span className={classes.primaryText}>{primaryText}</span>
      {rightIcon}
    </StyledMuiMenuItem>
  );
});

const PREFIX = 'MenuItemButton';

const classes = {
  leftIcon: `${PREFIX}-leftIcon`,
  primaryText: `${PREFIX}-primaryText`
};

const StyledMuiMenuItem = styled(MuiMenuItem)(({ theme }) => ({
  [`& .${classes.leftIcon}`]: { minWidth: theme.spacing(5) },

  [`& .${classes.primaryText}`]: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  }
}));

MenuItemButton.defaultProps = {
  classes: {}
};

import React from 'react';
import { Datagrid, List, SimpleList, TextField } from 'react-admin';
import { EditButton } from '@rc/admin/ra';
import { useMediaQuery } from '@mui/material';
import { OrganisationField } from '@rc/admin/components';
import { ROLES } from '@rc/admin/constants';
import { useIsRole } from '@rc/admin/hooks/domain';

export const OrganisationList = () => {
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const { result: isAdmin } = useIsRole(ROLES.ADMIN);

  return (
    <List perPage={25} exporter={false}>
      {isSmall ? (
        <SimpleList primaryText={() => <TextField source='name' />} />
      ) : (
        <Datagrid
          isRowSelectable={record => (isAdmin ? !record.isDefault : false)}
          bulkActionButtons={isAdmin}
        >
          <OrganisationField source={'name'} />
          <EditButton />
        </Datagrid>
      )}
    </List>
  );
};

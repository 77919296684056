import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  FormControlLabel,
  FormGroup,
  Switch,
  Tooltip,
  styled,
} from '@mui/material';
import { useFormContext, useWatch } from 'react-hook-form';
import {
  CheckboxGroupInput,
  ReferenceInput,
  SelectInput,
  required,
  useGetOne,
  useTranslate,
} from 'react-admin';
import { STATE_IDS } from '@rc/admin/constants';
import { ENVIRONMENT_COPY_TYPES } from '../../constants';

const OPTIONS = ENVIRONMENT_COPY_TYPES.map(type => ({
  id: type,
  name: `resources.environments.fields.copy_environment.types.${type}`
}));

/**
 *
 * @param {object} props
 * @param {string} props.source
 * @returns
 */
export const CopyEnvironmentField = (props) => {
  const { source, disabled } = props;
  const t = useTranslate();
  const { setValue, getValues } = useFormContext();
  const [projectId, sourceId] = useWatch({
    name: ['project', `${source}.sourceId`],
  });
  const [isExpanded, setIsExpanded] = useState(false);

  // Expand if sourceId and types are set on mount
  useEffect(() => {
    const { sourceId, types } = getValues(source) || {};
    if (sourceId && types?.length) {
      setIsExpanded(true);
    }
  }, [source, getValues]);

  const { data: project, isLoading: isProjectLoading } = useGetOne(
    'projects',
    { id: projectId },
    { enabled: !!projectId }
  );

  const onSourceIdChange = useCallback(
    (event) => {
      const { value } = event.target;
      const { types } = getValues(source);

      if (value && !types?.length) {
        setValue(
          `${source}.types`,
          OPTIONS.map((option) => option.id)
        );
      } else if (!value) {
        setValue(`${source}.types`, null);
      }
    },
    [getValues, setValue, source]
  );

  const handleToggleExpand = useCallback(() => {
    setIsExpanded((curr) => {
      const newValue = !curr;
      setValue(
        source,
        newValue
          ? {
              sourceId: project?.environment?.[0],
              types: OPTIONS.map((option) => option.id),
            }
          : null
      );
      return newValue;
    });
  }, [project, setValue, source]);

  if (isProjectLoading || !project?.environment?.length) {
    return null;
  }

  return (
    <>
      <StyledFormGroup>
        <FormControlLabel
          control={
            <Switch
              checked={isExpanded}
              onChange={handleToggleExpand}
              disabled={disabled}
            />
          }
          label={
            <Tooltip
              title={
                disabled
                  ? t('resources.environments.fields.copy_environment.disabled')
                  : ''
              }
            >
              {t('resources.environments.fields.copy_environment.title')}
            </Tooltip>
          }
        />
      </StyledFormGroup>
      {isExpanded && (
        <>
          <Box width={'100%'}>
            <ReferenceInput
              source={`${source}.sourceId`}
              reference={'environments'}
              filter={{
                id: project?.environment,
                'state[not]': STATE_IDS.DELETED,
              }}
              helperText={false}
            >
              <SelectInput
                label="resources.environments.fields.copy_environment.sourceId"
                optionText={'name'}
                placeholder={t(
                  'resources.environments.fields.copy_environment.sourceIdPlaceholder'
                )}
                emptyText={'misc.none'}
                fullWidth
                onChange={onSourceIdChange}
                validate={[required()]}
              />
            </ReferenceInput>
          </Box>
          <CheckboxGroupInput
            label=""
            source={`${source}.types`}
            choices={OPTIONS}
            validate={validateTypes}
            disabled={!sourceId}
            row={false}
          />
        </>
      )}
    </>
  );
};

const StyledFormGroup = styled(FormGroup)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

function validateTypes(value, allValues, { source: fullSource }) {
  const source = fullSource.split('.')[0];
  if (allValues?.[source]?.sourceId && !value?.length) {
    return required()('');
  }
}

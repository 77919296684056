import React, { useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { Avatar, ColorPicker } from '@rc/admin/components';
import { Box, styled } from '@mui/material';
import clsx from 'clsx';

export const AvatarInput = props => {
  const [code, color] = useWatch({ name: ['code', 'color'] });
  const [showInputs, setShowInputs] = useState(false);
  const { setValue } = useFormContext();

  return (
    <Root>
      {showInputs && (
        <div
          className={AvatarInputClasses.cover}
          onClick={() => setShowInputs(false)}
        />
      )}
      <Avatar
        className={clsx(AvatarInputClasses.avatar, {
          [AvatarInputClasses.editing]: showInputs
        })}
        code={code}
        color={color}
        onClick={() => setShowInputs(curr => !curr)}
        {...props}
      >
        {showInputs && (
          <input
            id='project-code'
            className={AvatarInputClasses.codeInput}
            type='text'
            autoFocus
            value={code}
            onChange={e => {
              if (e.target.value?.length <= 2) {
                setValue('code', e.target.value?.toUpperCase());
              }
            }}
            onClick={e => e.stopPropagation()}
          />
        )}
      </Avatar>
      <ColorPicker
        source={'color'}
        id={'project-color'}
        show={showInputs}
        setShow={setShowInputs}
        sx={theme => ({
          top: 0,
          [theme.breakpoints.down('lg')]: {
            right: '100%'
          },
          [theme.breakpoints.up('lg')]: {
            left: '100%'
          }
        })}
        hasOverlay={false}
      />
    </Root>
  );
};

const PREFIX = 'AvatarInput';

export const AvatarInputClasses = {
  avatar: `${PREFIX}-avatar`,
  cover: `${PREFIX}-cover`,
  codeInput: `${PREFIX}-codeInput`,
  editing: `${PREFIX}-editing`
};

const Root = styled(Box)(({ theme }) => ({
  position: 'relative',

  [`& .${AvatarInputClasses.avatar}`]: {
    width: 48,
    height: 48,
    fontSize: 20,
    cursor: 'pointer',

    [`&:not(.${AvatarInputClasses.editing}):hover`]: {
      opacity: 0.95,
      boxShadow: theme.shadows[2]
    },

    [`&.${AvatarInputClasses.editing}`]: {
      boxShadow: theme.shadows[2]
    }
  },

  [`& .${AvatarInputClasses.cover}`]: {
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0
  },

  [`& .${AvatarInputClasses.codeInput}`]: {
    zIndex: 2,
    width: '100%',
    textAlign: 'center',
    border: 'none',
    outline: 'none',
    background: 'transparent',
    color: 'white',
    fontSize: 24,
    fontFamily: theme.typography.fontFamily
  }
}));

AvatarInput.defaultProps = {
  sx: {}
};

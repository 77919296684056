import React from 'react';
import {
  Datagrid,
  Edit,
  EditButton,
  EditClasses,
  SimpleForm,
  SimpleList,
  TextInput,
  required,
  TextField,
  useTranslate
} from 'react-admin';
import {
  AsideReferenceList,
  Breadcrumbs,
  EditTitle
} from '@rc/admin/components';
import {
  Alert,
  Card,
  Grid,
  Link,
  Stack,
  Typography,
  styled,
  useMediaQuery
} from '@mui/material';
import { RegExpTestInput } from './components';
import { REGEXP_DOCS_URL, validateRegExp } from './utils';

export const EnvVarValidationEdit = () => {
  const t = useTranslate();

  return (
    <StyledEdit
      title={<EditTitle />}
      actions={<Breadcrumbs />}
      mutationMode='pessimistic'
      aside={
        <Stack spacing={2}>
          <Card className={classes.asideCard}>
            <AsideComponent
              resource={'component_version_env_vars'}
              title={
                'resources.env_var_validations.sections.component_version_env_vars'
              }
            />
          </Card>
          <Card className={classes.asideCard}>
            <AsideComponent
              resource={'project_type_version_env_vars'}
              title={
                'resources.env_var_validations.sections.project_type_version_env_vars'
              }
            />
          </Card>
        </Stack>
      }
    >
      <SimpleForm redirect={false} className={classes.form}>
        <Grid container columnSpacing={3}>
          <Grid item xs={12} sm={6}>
            <TextInput source={'name'} fullWidth validate={[required()]} />
            <TextInput
              source={'regexp'}
              autoComplete='off'
              validate={[required(), validateRegExp]}
              fullWidth
              sx={theme => ({ marginBottom: theme.spacing(1) })}
            />
            <TextInput
              source={'validationMessage'}
              multiline
              fullWidth
              validate={[required()]}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <RegExpTestInput />
          </Grid>

          <Grid item xs={12}>
            <Alert severity='info'>
              <Typography mb={2}>
                {t('resources.env_var_validations.messages.usage_notes') + ' '}
                <Link href={REGEXP_DOCS_URL} target='_blank'>
                  {REGEXP_DOCS_URL}
                </Link>
              </Typography>
            </Alert>
          </Grid>
        </Grid>
      </SimpleForm>
    </StyledEdit>
  );
};

const AsideComponent = props => {
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

  return (
    <AsideReferenceList
      {...props}
      referenceSource='envVarValidations'
      canCreate={false}
    >
      {isSmall ? (
        <SimpleList
          primaryText={() => <TextField source={'key'} />}
          style={{ width: '100%' }}
        />
      ) : (
        <Datagrid style={{ width: '100%' }}>
          <TextField source={'key'} />
          <EditButton sx={{ float: 'right' }} />
        </Datagrid>
      )}
    </AsideReferenceList>
  );
};

const PREFIX = 'EnvVarValidationEdit';

const classes = {
  asideCard: `${PREFIX}-asideCard`,
  form: `${PREFIX}-form`
};

const StyledEdit = styled(Edit)(({ theme }) => ({
  [`& .${EditClasses.main}`]: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(3),
    '& > *': {
      width: '100%'
    }
  },

  [`& .${classes.asideCard}`]: {},

  [`& .${classes.form}`]: {}
}));

import { useAuthState, usePermissions } from 'react-admin';
import { getHasPermission } from '../utils/permissions/util';

export const useHasPermission = (resourceName, action) => {
  const { authenticated, isLoading: isLoadingAuthState } = useAuthState();
  const { permissions, isLoading: isLoadingPermissions } = usePermissions();
  const isLoading = isLoadingAuthState || isLoadingPermissions;

  if (!resourceName) {
    return { isLoading: false, authenticated, hasPermission: true };
  }

  const hasPermission =
    !isLoading && permissions
      ? getHasPermission(resourceName, action, permissions)
      : false;

  return { isLoading, authenticated, hasPermission };
};

import { useMemo } from 'react';
import { useUserCosts } from '@rc/admin/hooks';
import { useTheme } from '@mui/material';

export const useCostAnalyzerChart = (
  props = {
    budget: 0
  }
) => {
  const { budget } = props;
  const { costs } = useUserCosts();
  const theme = useTheme();

  const chart = useMemo(() => {
    const now = new Date();
    const month = now.getMonth();
    const daysInMonth = new Date(now.getFullYear(), month + 1, 0).getDate();
    const currentDayOfMonth = 10 || now.getDate(); // Get the current day of the month

    const { amount: sumAmount, currency } = costs.sum;

    const amountPerDay = Number(sumAmount / currentDayOfMonth);

    const seriesData = Array.from({ length: daysInMonth }, (_, index) =>
      ((index + 1) * amountPerDay).toFixed(2)
    );

    const currentValue = seriesData[currentDayOfMonth - 1];

    return {
      categories: Array.from({ length: daysInMonth }, (_, index) => index + 1),
      series: [
        {
          month: 0,
          data: [
            {
              name: 'All costs',
              data: seriesData
            }
          ]
        }
      ],
      /**
       * @type {import("apexcharts").ApexOptions}
       */
      options: {
        forecastDataPoints: {
          count: daysInMonth - currentDayOfMonth,
          fillOpacity: 0.5,
          dashArray: 7.5
        },
        annotations: {
          xaxis: [
            {
              x: currentDayOfMonth,
              strokeDashArray: 0,
              borderColor: '#775DD0',
              label:
                currentValue > 0
                  ? {
                      position: 'bottom',
                      borderColor: '#775DD0',
                      style: {
                        color: '#fff',
                        background: '#775DD0'
                      },
                      text: 'Now'
                    }
                  : undefined
            }
          ],
          yaxis: [
            {
              y: budget,
              strokeDashArray: 2,
              borderColor: theme.palette.warning.main,
              label:
                currentValue > 0
                  ? {
                      position: 'left',
                      offsetX: 100,
                      offsetY: 0,
                      borderColor: theme.palette.warning.main,
                      style: {
                        color: '#fff',
                        background: theme.palette.warning.main
                      },
                      text: `Budget: ${budget} ${currency}`
                    }
                  : undefined
            }
          ],
          points: [
            {
              x: currentDayOfMonth,
              y: currentValue,
              marker: {
                size: 8,
                fillColor: '#fff',
                strokeColor: '#775DD0',
                radius: 2,
                cssClass: 'apexcharts-custom-class'
              },
              label: {
                borderColor: '#775DD0',
                offsetY: 0,
                style: {
                  color: '#fff',
                  background: '#775DD0'
                },
                text: `${currentValue} ${currency}`
              }
            },
            {
              x: daysInMonth,
              y: seriesData[daysInMonth - 1],
              marker: {
                size: 8,
                fillColor: '#fff',
                strokeColor: '#00E396',
                radius: 2,
                cssClass: 'apexcharts-custom-class'
              },
              label: {
                borderColor: '#00E396',
                offsetY: 0,
                style: {
                  color: '#fff',
                  background: '#00E396'
                },
                text: `${seriesData[daysInMonth - 1]} ${currency}`
              }
            }
          ]
        },
        yaxis: {
          max: num => {
            return Math.max(budget + 100, Math.round(num / 100) * 100);
          },
          labels: {
            formatter: v => v.toFixed(0)
          }
        }
      }
    };
  }, [budget, costs, theme]);

  return { chart };
};

import React from 'react';
import { EnvVarValidationList } from './List';
import { EnvVarValidationEdit } from './Edit';
import { EnvVarValidationCreate } from './Create';
import Iconify from '@rc/admin/components/ui/Iconify';

export default {
  name: 'env_var_validations',
  list: EnvVarValidationList,
  edit: EnvVarValidationEdit,
  create: EnvVarValidationCreate,
  icon: () => <Iconify icon='solar:text-field-focus-bold-duotone' />
};

import React, { useCallback } from 'react';
import {
  Box,
  CardContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  Stack,
  styled,
  Typography
} from '@mui/material';
import { CardList, LoadingField, SectionHelper } from '@rc/admin/components';
import { DEFAULT_SORT, PREFETCH_PAGINATION } from '@rc/admin/constants';
import {
  ListContextProvider,
  ResettableTextField,
  useChoicesContext,
  useGetList,
  useInput,
  useTranslate
} from 'react-admin';
import { useFormContext, useWatch } from 'react-hook-form';
import clsx from 'clsx';
import {
  useDefaultComponentVersions,
  useRequiredComponents
} from '../../hooks';

const source = 'componentVersion';

/**
 *
 * @param {object} props
 * @param {string} props.label
 * @param {object} props.itemGridSizes
 * @returns
 */
export const ComponentSelector = props => {
  const { label, itemGridSizes } = props;

  const {
    isLoading: isComponentVersionsLoading,
    isFetching: isComponentVersionsFetching
  } = useChoicesContext();

  const {
    data: components,
    isLoading: isComponentsLoading,
    isFetching: isComponentsFetching
  } = useGetList('components', {
    sort: DEFAULT_SORT,
    pagination: PREFETCH_PAGINATION
  });

  const {
    defaultComponentVersions,
    isLoading: isDefaultComponentVersionsLoading,
    isFetching: isDefaultComponentVersionsFetching
  } = useDefaultComponentVersions();

  const {
    requiredComponents,
    isLoading: isRequiredComponentsLoading,
    isFetching: isRequiredComponentsFetching
  } = useRequiredComponents();

  const { field } = useInput({
    source,
    resource: 'component_versions',
    defaultValue: defaultComponentVersions
  });

  const projectTypeVersion = useWatch({ name: 'projectTypeVersion' });

  if (
    !projectTypeVersion ||
    isComponentVersionsLoading ||
    isRequiredComponentsLoading ||
    isDefaultComponentVersionsLoading
  ) {
    return <SectionHelper>{label}</SectionHelper>;
  } else if (
    isComponentVersionsLoading ||
    isRequiredComponentsLoading ||
    isDefaultComponentVersionsLoading
  ) {
    return (
      <Stack>
        <SectionHelper>{label}</SectionHelper>
        <LoadingField />
      </Stack>
    );
  }

  const listContextValue = {
    data: components,
    isLoading:
      isComponentsLoading ||
      isComponentVersionsLoading ||
      isDefaultComponentVersionsLoading ||
      isRequiredComponentsLoading,
    isFetching:
      isComponentsFetching ||
      isComponentVersionsFetching ||
      isDefaultComponentVersionsFetching ||
      isRequiredComponentsFetching,
    total: components?.length || 0,
    resource: 'components',
    sort: DEFAULT_SORT,
    page: 1
  };

  return (
    <Stack sx={{ width: '100%' }}>
      <SectionHelper>{label}</SectionHelper>
      <ListContextProvider value={listContextValue}>
        <CardList
          linkType={false}
          enablePlaceholders
          placeholdersCount={6}
          itemGridSizes={itemGridSizes}
          render={(record, _, isPlaceholder) => (
            <ComponentCardContent
              record={record}
              isPlaceholder={isPlaceholder}
              isSelected={getIsComponentSelected(field.value, record)}
              isRequired={requiredComponents?.includes(record?.id)}
              defaultComponentVersions={defaultComponentVersions}
            />
          )}
          cardStyles={record => theme => {
            const isSelected = getIsComponentSelected(field.value, record);
            return {
              display: 'flex',
              borderColor: isSelected ? theme.palette.primary.main : undefined,
              backgroundColor: isSelected
                ? theme.palette.background.default
                : undefined,
              color: isSelected
                ? theme.palette.text.primary
                : theme.palette.text.secondary,
              fontWeight: 500
            };
          }}
        />
      </ListContextProvider>
      <ResettableTextField {...field} sx={{ display: 'none' }} />
    </Stack>
  );
};

const getIsComponentSelected = (componentVersionIds, component) =>
  componentVersionIds?.some(id => component?.[source]?.includes(id));

const ComponentCardContent = props => {
  const {
    record: component,
    isPlaceholder,
    isSelected,
    isRequired,
    defaultComponentVersions
  } = props;
  const t = useTranslate();
  const { setValue } = useFormContext();
  const values = useWatch({ name: source });

  const onVersionChange = useCallback(
    newValue => {
      setValue(source, newValue, {
        shouldDirty: true,
        shouldTouch: true
      });
    },
    [setValue]
  );

  const { allChoices: allComponentVersions } = useChoicesContext();
  const componentVersions = allComponentVersions?.filter(
    componentVersion => componentVersion.component === component.id
  ).sort((a, b) => a.version.localeCompare(b.version));

  const value = values?.find(value =>
    componentVersions?.some(({ id }) => value === id)
  );

  const handleVersionChange = event => {
    const newValue = [...(values || [])];

    const oldIndex = newValue?.indexOf(value);
    const newIndex = oldIndex > -1 ? oldIndex : newValue.length;
    newValue[newIndex] = event.target.value;
    onVersionChange(newValue);
  };

  const handleClick = e => {
    if (isRequired && isSelected) {
      return;
    }

    const newValue = [...(values || [])];

    if (isSelected) {
      const oldIndex = newValue?.indexOf(value);
      newValue.splice(oldIndex, 1);
    } else {
      const defaultVersion =
        componentVersions[componentVersions.length - 1]?.id;
      newValue.push(defaultVersion);
    }
    onVersionChange(newValue);
  };

  return (
    <StyledCardContent
      className={clsx(classes.cardContent, { [classes.selected]: isSelected })}
      onClick={handleClick}
    >
      <Stack className={classes.brand} flexDirection={'row'} spacing={1}>
        <Box>
          {isPlaceholder || !component?.codeName ? (
            <Skeleton
              className={clsx(classes.brandImage, classes.brandImageSkeleton)}
              variant={'circular'}
            />
          ) : (
            <img
              className={classes.brandImage}
              src={`/images/${component.codeName.toLowerCase()}.png`}
              width='auto'
              height={'28'}
            />
          )}
        </Box>

        <Typography className={classes.brandName}>
          {isPlaceholder ? (
            <Skeleton width={100} variant={'text'} />
          ) : (
            <>
              <Typography
                component='span'
                sx={theme => ({
                  textTransform: 'capitalize',
                  color: isSelected
                    ? theme.palette.text.primary
                    : theme.palette.text.secondary,
                  fontWeight: 500
                })}
              >
                {component.name}
              </Typography>
              <Typography
                component='span'
                sx={{
                  visibility: isRequired ? 'visible' : 'hidden',
                  opacity: 0.75,
                  ml: 1
                }}
              >
                {`(${t('ra.validation.required')})`}
              </Typography>
            </>
          )}
        </Typography>
      </Stack>

      {!isPlaceholder && (
        <FormControl className={classes.control}>
          <InputLabel id={`${component?.name}-version–label`}>
            {t('resources.components.fields.componentVersion')}
          </InputLabel>
          <Select
            label={t('resources.components.fields.componentVersion')}
            labelId={`${component?.name}-version-label`}
            id={`${component?.name}-version`}
            value={value || ''}
            onChange={handleVersionChange}
            onClick={e => e.stopPropagation()}
            fullWidth
          >
            {componentVersions?.map(choice => {
              const isDefault = defaultComponentVersions?.includes(choice.id);
              return (
                <MenuItem
                  key={choice.id}
                  value={choice.id}
                  sx={{
                    '&:after': {
                      content: isDefault
                        ? `"(${t('misc.recommended')})"`
                        : '""',
                      marginLeft: 1
                    }
                  }}
                >
                  {choice.version}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      )}
    </StyledCardContent>
  );
};

const PREFIX = 'ComponentSelector';

const classes = {
  cardContent: `${PREFIX}-cardContent`,
  selected: `${PREFIX}-selected`,
  brand: `${PREFIX}-brand`,
  brandName: `${PREFIX}-brandName`,
  brandImage: `${PREFIX}-brandImage`,
  brandImageSkeleton: `${PREFIX}-brandImageSkeleton`,
  control: `${PREFIX}-control`
};

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  '&, & *': {
    transitionDuration: '0.3s',
    transitionTimingFunction: 'ease-in-out'
  },
  [`&.${classes.cardContent}`]: {
    minHeight: theme.spacing(16.75),
    width: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    filter: 'grayscale(0.5)',
    transitionProperty: 'filter',
    cursor: 'pointer',

    [theme.breakpoints.up('md')]: {
      minHeight: theme.spacing(11.75),
      flexDirection: 'row',
      justifyContent: 'space-between'
    }
  },

  [`& .${classes.brand}`]: {
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-25%, -50%)',
      transitionProperty: 'left, top, transform',
      transitionDuration: '0.2s',
      display: 'flex',
      justifyContent: 'flex-start',
      width: '100%'
    },
    [`& .${classes.brandName}`]: {
      marginTop: '0 !important'
    },
    [`& .${classes.brandImage}`]: {
      marginRight: theme.spacing(2),
      [`&.${classes.brandImageSkeleton}`]: {
        width: 28,
        height: 28
      }
    }
  },

  [`& .${classes.control}`]: {
    width: '100%',
    marginTop: 0,
    visibility: 'hidden',
    opacity: 0,
    transitionDuration: '0s',
    transitionProperty: 'opacity',
    pointerEvents: 'none',
    [theme.breakpoints.up('md')]: {
      maxWidth: 120
    }
  },

  [`&.${classes.selected}`]: {
    filter: 'grayscale(0)',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'flex-end'
    },
    [`& .${classes.brand}`]: {
      [theme.breakpoints.down('md')]: {
        transform: 'translate(-43.5%, -160%)'
      }
    },
    [`& .${classes.control}`]: {
      transitionDuration: '0.3s',
      visibility: 'visible',
      pointerEvents: 'initial',
      opacity: 1
    }
  }
}));

ComponentSelector.defaultProps = {
  itemGridSizes: {
    xs: 12,
    sm: 6,
    xl: 4
  }
};

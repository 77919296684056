import React from 'react';
import { SaveButton, Toolbar as RaToolbar, useTranslate } from 'react-admin';
import { styled, Button } from '@mui/material';
import { useFormStepperContext } from './context';
import { useFormContext } from 'react-hook-form';

const WizardMultiFormToolbar = props => {
  const { isValid } = useFormContext();
  const t = useTranslate();
  const [{ hasPrevious, hasNext }, { previous }] = useFormStepperContext();

  return (
    <RaToolbar classes={{ toolbar: classes.toolbar }} {...props}>
      {hasPrevious && (
        <PreviousButton label={t('action.back')} onClick={previous} />
      )}
      <SaveButton
        type={hasNext ? 'button' : 'submit'}
        label={t(hasNext ? 'action.continue' : 'ra.action.save')}
        icon={hasNext ? <NoSaveIcon /> : undefined}
        disabled={!isValid}
      />
    </RaToolbar>
  );
};

const NoSaveIcon = () => null;

const PreviousButton = ({ label, onClick }) => (
  <StyledButton
    type={'button'}
    variant={'contained'}
    color={'secondary'}
    onClick={onClick}
  >
    {label}
  </StyledButton>
);

const PREFIX = 'WizardMultiFormToolbar';

const classes = {
  toolbar: `${PREFIX}-toolbar`
};

const StyledButton = styled(Button)(({ theme }) => ({
  [`& .${classes.toolbar}`]: {
    justifyContent: 'flex-end',
    gap: theme.spacing(2)
  }
}));

export default WizardMultiFormToolbar;

import React from 'react';
import { ReferenceArrayInput, required } from 'react-admin';
import { GroupedSelectArrayInput } from '@rc/admin/components';
import { useWatch } from 'react-hook-form';
import { PREFETCH_PAGINATION } from '@rc/admin/constants';

export const ProjectTypeComponentVersionInput = props => {
  const { source, ...rest } = props;
  const requiredComponents = useWatch({ name: 'requiredComponent' });

  return (
    <ReferenceArrayInput
      source={source}
      reference={'component_versions'}
      sort={{ field: 'version', order: 'ASC' }}
      perPage={PREFETCH_PAGINATION}
    >
      <GroupedSelectArrayInput
        label={'resources.project_type_versions.fields.componentVersions'}
        groupReference={'components'}
        itemSource={'componentVersion'}
        optionText={choice => choice && (choice.version || choice.name)}
        requiredFirst
        requiredGroups={requiredComponents}
        validate={[required()]}
        {...rest}
      />
    </ReferenceArrayInput>
  );
};

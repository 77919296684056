import { useMemo } from 'react';
import { useRecordContext } from 'react-admin';
import { isEmpty } from 'lodash';
import { getOngoingProgresses } from '@rc/admin/utils';

/**
 *
 * @param {import('@rc/admin/types/progressbar.types').ProgressBar} progressBar
 * @returns
 */
export const useEnvironmentProgressBar = progressBarFromProps => {
  const record = useRecordContext();
  const progressBarInfo = progressBarFromProps || record?.progressBarInfo;

  const ongoingProgresses = useMemo(() => {
    const progressBar = !isEmpty(progressBarInfo) ? progressBarInfo : {};
    return getOngoingProgresses(progressBar);
  }, [progressBarInfo]);

  return {
    ongoingProgresses
  };
};

import React, { useCallback } from 'react';
import {
  CreateContextProvider,
  ResourceContextProvider,
  useCreateController,
  useNotify,
  useRefresh
} from 'react-admin';
import { useSideDialogContext } from '../../ui/SideDialog';

/**
 *
 * @param {object} props
 * @param {string} props.resource
 * @returns
 */
export const CreateDialogBase = props => {
  const { children } = props;

  const controllerProps = useCreateDialogController(props);

  const body = (
    <CreateContextProvider value={controllerProps}>
      {children}
    </CreateContextProvider>
  );

  return (
    <ResourceContextProvider value={props.resource}>
      {body}
    </ResourceContextProvider>
  );
};

/**
 *
 * @param {import('react-admin').CreateControllerProps} props
 * @returns
 */
const useCreateDialogController = props => {
  const { mutationOptions } = props;
  const [, { close }] = useSideDialogContext();
  const refresh = useRefresh();
  const notify = useNotify();

  const onSuccess = useCallback(
    (data, variables, context) => {
      if (mutationOptions?.onSuccess) {
        mutationOptions.onSuccess(data, variables, context);
      } else {
        notify('ra.notification.created', { messageArgs: { smart_count: 1 } });
      }

      refresh();
      close();
    },
    [close, mutationOptions, notify, refresh]
  );

  const controllerProps = useCreateController({
    ...props,
    mutationMode: props.mutationMode || 'pessimistic',
    redirect: props.redirect || false,
    mutationOptions: {
      ...(mutationOptions || {}),
      onSuccess
    }
  });

  return controllerProps;
};

import React from 'react';
import { ProductCreate } from './Create';
import { ProductEdit } from './Edit';
import { ProductList } from './List';
import Iconify from '@rc/admin/components/ui/Iconify';

export default {
  name: 'products',
  list: ProductList,
  edit: ProductEdit,
  create: ProductCreate,
  icon: () => <Iconify icon='solar:cloud-storage-bold-duotone' />
};

import { usePermissions } from 'react-admin';

/**
 *
 * @param {string | string[]} roles
 * @returns { isLoading: boolean, result: boolean }
 */
export const useIsRole = roles => {
  const { permissions, isLoading } = usePermissions();

  const result = permissions?.some(permission =>
    Array.isArray(roles) ? roles.includes(permission) : roles === permission
  );

  return {
    result,
    isLoading
  };
};

import React, { useState } from 'react';
import {
  styled,
  Grid,
  useMediaQuery,
  Autocomplete,
  TextField as MuiTextField,
  useTheme
} from '@mui/material';
import {
  Datagrid,
  Pagination,
  useGetList,
  TextField,
  useTranslate
} from 'react-admin';
import { useOrganisationsWithName } from './utils';

export const Selector = props => {
  const {
    organisations = [],
    teams = [],
    organisationId,
    teamId,
    handleOrganisationChange,
    handleTeamChange
  } = props;
  const t = useTranslate();
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const [page, setPage] = useState(1);

  const filteredTeams = teams.filter(
    team => team.organisation === organisationId
  );

  const { data: organisationsWithName } = useOrganisationsWithName({
    organisations
  });

  return (
    <Root container className={classes.root} spacing={1}>
      <Grid item xs={12} md={6}>
        {isSmall ? (
          <Autocomplete
            options={organisationsWithName}
            value={organisationsWithName.find(
              ({ id }) => id === organisationId
            )}
            onChange={(_, newValue) => handleOrganisationChange(newValue)}
            getOptionLabel={option => option.customName}
            color={'info'}
            renderInput={params => (
              <MuiTextField
                {...params}
                label={t('resources.organisations.name', { smart_count: 1 })}
                focused
              />
            )}
          />
        ) : (
          <>
            <DataList
              data={organisationsWithName.slice(
                page === 1 ? 0 : (page - 1) * perPage,
                page * perPage
              )}
              total={organisations.length}
              onSelect={handleOrganisationChange}
              selectedId={organisationId}
            >
              <TextField
                label={t('resources.organisations.name', { smart_count: 1 })}
                source='customName'
              />
            </DataList>
            {organisations.length > perPage && (
              <Pagination
                total={organisations.length}
                page={page}
                setPage={setPage}
                perPage={perPage}
                rowsPerPageOptions={[perPage]}
              />
            )}
          </>
        )}
      </Grid>
      <Grid item xs={12} md={6}>
        {isSmall ? (
          <Autocomplete
            options={filteredTeams}
            value={filteredTeams.find(({ id }) => id === teamId)}
            onChange={(_, newValue) => handleTeamChange(newValue)}
            getOptionLabel={option => option.name}
            renderInput={params => (
              <MuiTextField
                {...params}
                label={t('resources.teams.name', { smart_count: 1 })}
                focused
              />
            )}
          />
        ) : (
          <DataList
            data={filteredTeams}
            onSelect={handleTeamChange}
            selectedId={teamId}
          >
            <TextField
              label={t('resources.teams.name', { smart_count: 1 })}
              source='name'
            />
          </DataList>
        )}
      </Grid>
    </Root>
  );
};

const sort = { field: 'id', order: 'DESC' };
const perPage = 10;

const DataList = props => {
  const { data, total, onSelect, selectedId, children } = props;
  const theme = useTheme();

  return (
    <>
      <Datagrid
        className={classes.dataGrid}
        data={data}
        total={total}
        sort={sort}
        rowClick={id => onSelect(data.find(record => record.id === id))}
        rowStyle={({ id }) =>
          id === selectedId ? SELECTED_STYLE[theme.palette.mode] : undefined
        }
        bulkActionButtons={false}
      >
        {children}
      </Datagrid>
    </>
  );
};

const SELECTED_STYLE = {
  light: {
    backgroundColor: 'rgba(0, 0, 0, 0.08)'
  },
  dark: {
    backgroundColor: 'rgba(255, 255, 255, 0.08)'
  }
};

const PREFIX = 'Selector';

const classes = {
  root: `${PREFIX}-form`,
  dataGrid: `${PREFIX}-dataGrid`
};

const Root = styled(Grid)(({ theme }) => ({
  [`& .${classes.root}`]: {
    display: 'flex',
    flexWrap: 'wrap'
  },

  [`& .${classes.dataGrid}`]: {
    cursor: 'pointer'
  }
}));

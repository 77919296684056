import React, { useMemo } from 'react';
import { useTranslate } from 'react-admin';
import {
  Card,
  CardContent,
  Stack,
  styled,
  Typography,
  useMediaQuery
} from '@mui/material';
import { CpuIcon, MemoryIcon, StorageIcon } from '@rc/admin/components';
import { Row } from './Row';

/**
 *
 * @param {import('@mui/material').CardProps & { summary: object, isLoading: boolean }} props
 *
 */
export const UsageSummary = props => {
  const { summary, isLoading, ...rest } = props;
  const t = useTranslate();
  const isXL = useMediaQuery(`(min-width: 1920px)`);

  const labelContainerProps = useMemo(() => ({ xs: !isXL ? 6 : 2 }), [isXL]);
  const priceContainerProps = useMemo(() => ({ xs: !isXL ? 6 : 10 }), [isXL]);

  return (
    <StyledCard className={classes.root} {...rest}>
      <CardContent>
        <Typography variant={'subtitle1'} className={classes.cardTitle}>
          {t('components.usage_summary.title')}
        </Typography>
        <Stack className={classes.item}>
          <Row
            label={!isXL ? t('resources.resource_types.names.cpu') : ''}
            icon={<CpuIcon />}
            usage={summary?.cpuCoreHours}
            unit={'Core-hours'}
            labelContainerProps={labelContainerProps}
            priceContainerProps={priceContainerProps}
            isLoading={isLoading}
          />
          <Row
            label={!isXL ? t('resources.resource_types.names.memory') : ''}
            icon={<MemoryIcon />}
            usage={byteHourFormatToGigabyte(summary?.ramByteHours || 0)}
            unit={'Gb-hours'}
            labelContainerProps={labelContainerProps}
            priceContainerProps={priceContainerProps}
            isLoading={isLoading}
          />
          <Row
            label={!isXL ? t('resources.resource_types.names.storage') : ''}
            icon={<StorageIcon />}
            usage={byteHourFormatToGigabyte(summary?.pvByteHours || 0)}
            unit={'Gb-hours'}
            labelContainerProps={labelContainerProps}
            priceContainerProps={priceContainerProps}
            isLoading={isLoading}
          />
        </Stack>
      </CardContent>
    </StyledCard>
  );
};

function byteHourFormatToGigabyte (value) {
  return value !== undefined && value !== null ? value * 1e-9 : undefined;
}

const PREFIX = 'UsageSummary';

const classes = {
  root: `${PREFIX}-root`,
  cardTitle: `${PREFIX}-cardTitle`,
  item: `${PREFIX}-item`
};

const StyledCard = styled(Card)(({ theme }) => ({
  [`&.${classes.root}`]: {
    flex: 1,
    height: '100%'
  },

  [`& .${classes.cardTitle}`]: {
    marginBottom: theme.spacing(1.5)
  },

  [`& .${classes.item}`]: {
    gap: theme.spacing(1),
    [`@media (min-width: 1920px)`]: {
      flexDirection: 'row'
    }
  }
}));

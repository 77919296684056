import React, { useMemo } from 'react';
import {
  DeleteButton as RaDeleteButton,
  useRecordContext,
  useResourceContext
} from 'react-admin';

/**
 *
 * Unlike DeleteButton, this allows to preserve the record data when redirecting on Delete.
 *  *
 * @param {import('react-admin').DeleteButtonProps} props
 * @returns
 */
export const DeleteButton = props => {
  const { redirect: redirectProp, ...rest } = props;
  const resource = useResourceContext();
  const record = useRecordContext();

  const redirect = useMemo(() => {
    if (typeof redirectProp === 'function') {
      let redirectPath = redirectProp(resource, record?.id, record);

      if (!/^\//.test(redirectPath)) {
        redirectPath = `/${redirectPath}`;
      }

      return redirectPath;
    }

    return redirectProp;
  }, [record, redirectProp, resource]);

  return <RaDeleteButton redirect={redirect} {...rest} />;
};

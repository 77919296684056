import { get } from 'lodash';
import React from 'react';
import { useRecordContext } from 'react-admin';
import { useWatch } from 'react-hook-form';

export const PossibleFieldOrInput = props => {
  const { children, source: sourceProp } = props;
  const source = sourceProp || React.Children.only(children)?.props?.source;
  const record = useRecordContext();

  let value;
  try {
    value = useWatch({ name: source });
  } catch (_) {
    // Form is not registered
  }

  if (!value && !get(record || {}, source)) {
    return null;
  }

  return children;
};

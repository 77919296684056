export function getComponentFromComponentVersion (
  components,
  componentVersions,
  componentVersionId
) {
  const componentVersion = componentVersions?.find(
    version => version.id === componentVersionId
  );
  return components?.find(
    component => component.id === componentVersion?.component
  );
}

import React from 'react';
import { FunctionField } from 'react-admin';
import { useGetOrganisationName } from '@rc/admin/hooks/domain';

/**
 *
 * @param {import('react-admin').FunctionFieldProps & { label: string, users: any[] }} props
 * @returns
 */
export const OrganisationField = props => {
  const { label = 'misc.name', users, ...rest } = props;
  const getOrganisationName = useGetOrganisationName();

  return (
    <FunctionField
      label={label}
      {...rest}
      render={record => getOrganisationName(record)}
    />
  );
};

import { styled } from '@mui/material';
import { OneColumnFormStyles } from '@rc/admin/theme/styles';
import React from 'react';
import { Create, SimpleForm, TextInput, BooleanInput } from 'react-admin';

export const EnvVarTypeCreate = () => {
  return (
    <StyledCreate>
      <SimpleForm className={classes.form}>
        <TextInput source={'name'} fullWidth />
        <BooleanInput source={'isDefault'} fullWidth />
      </SimpleForm>
    </StyledCreate>
  );
};

const PREFIX = 'EnvVarTypeCreate';

const classes = {
  form: `${PREFIX}-form`
};

const StyledCreate = styled(Create)(({ theme }) => ({
  [`& .${classes.form}`]: {
    ...OneColumnFormStyles(theme)
  }
}));

import React, { useCallback, useState, useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import {
  BooleanField,
  Datagrid,
  EditButton,
  FunctionField,
  Pagination,
  ResourceContextProvider,
  SimpleList,
  TextField,
  useGetList,
  useRecordContext,
  useTranslate
} from 'react-admin';
import { Button, Stack, styled, useMediaQuery } from '@mui/material';
import { ROLES } from '@rc/admin/constants';
import { REMOVE_TEAMS_FORM_KEY } from './utils';
import { useIsRole } from '@rc/admin/hooks';
import { AddDialog, SideDialog } from '@rc/admin/components';
import { Add } from '@mui/icons-material';

const ROWS_PER_PAGE_OPTIONS = [5, 10, 25];

/**
 *
 * @param {object} props
 * @param {object} props.getListRef - ref to the getList object
 * @returns
 */
export const Teams = props => {
  const { getListRef } = props;
  const { result: isAdmin } = useIsRole(ROLES.ADMIN);
  const t = useTranslate();
  const record = useRecordContext();
  const { setValue, getValues } = useFormContext();
  const [teamsToRemove] = useWatch({ name: [REMOVE_TEAMS_FORM_KEY] });
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const sort = { field: 'createdAt', order: 'DESC' };
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(ROWS_PER_PAGE_OPTIONS[0]);

  const { data, isLoading, total, refetch } = useGetList(
    'teams',
    {
      pagination: { page, perPage },
      filter: { organisation: record?.['@id'] },
      sort
    },
    {
      enabled: !!record
    }
  );

  useEffect(() => {
    if (getListRef) {
      getListRef.current = { refetch };
    }
  }, [getListRef, refetch]);

  const handleRemoveUser = useCallback(
    user => {
      const newUsersToRemove = (getValues(REMOVE_TEAMS_FORM_KEY) || [])
        .filter(userId => userId !== user['@id'])
        .concat([user['@id']]);
      setValue(REMOVE_TEAMS_FORM_KEY, newUsersToRemove, {
        shouldDirty: true,
        shouldTouch: true,
        shouldValidate: true
      });
    },
    [getValues, setValue]
  );

  const handleUndoRemoveUser = useCallback(
    user => {
      const newUsersToRemove = (getValues(REMOVE_TEAMS_FORM_KEY) || []).filter(
        userId => userId !== user['@id']
      );
      setValue(REMOVE_TEAMS_FORM_KEY, newUsersToRemove, {
        shouldDirty: true,
        shouldTouch: true,
        shouldValidate: true
      });
    },
    [getValues, setValue]
  );

  return (
    <StyledStack className={classes.root}>
      <Stack direction='row' className={classes.actions}>
        <AddDialog
          title={
            <Stack
              direction={'row'}
              alignItems={'center'}
              justifyContent={'center'}
              gap={1}
            >
              <Add fontSize='small' />
              <span>{t('resources.organisations.action.add_team')}</span>
            </Stack>
          }
          dialogTitle={t('resources.organisations.action.add_team')}
          variant='text'
          size='small'
          color='primary'
          source='team'
          reference='teams'
          backReference='organisation'
          record={record}
          onSuccess={() => refetch()}
        />
      </Stack>

      <ResourceContextProvider value='teams'>
        {isSmall ? (
          <SimpleList
            empty={null}
            isLoading={isLoading}
            data={data || []}
            sort={sort}
            total={total}
            sx={{ width: '100%' }}
            primaryText={
              <Stack>
                <TextField source='name' />
                {isAdmin && <TextField source='codeName' />}
              </Stack>
            }
            secondaryText={<BooleanField source='isDefault' />}
            tertiaryText={<EditButton />}
          />
        ) : (
          <Datagrid
            bulkActionButtons={null}
            empty={null}
            isLoading={isLoading}
            data={data || []}
            sort={sort}
            total={total}
            sx={{ width: '100%' }}
          >
            <TextField source='name' />
            <BooleanField source='isDefault' />
            <TextField source='defaultUser.fullname' />
            {isAdmin && <TextField source='codeName' />}
            <Stack direction={'row'} justifyContent={'flex-end'} spacing={1}>
              <FunctionField
                render={team => {
                  const isTeamToRemove = teamsToRemove?.some(
                    teamId => teamId === team['@id']
                  );
                  return (
                    <Stack
                      flexDirection={'row'}
                      gap={1}
                      justifyContent={'flex-end'}
                      alignItems={'center'}
                    >
                      {!team.isDefault && (
                        <Button
                          color={!isTeamToRemove ? 'error' : 'primary'}
                          variant='outlined'
                          size='small'
                          onClick={() =>
                            !isTeamToRemove
                              ? handleRemoveUser(team)
                              : handleUndoRemoveUser(team)
                          }
                          disabled={isLoading}
                        >
                          {!isTeamToRemove
                            ? t('ra.action.remove')
                            : t('action.undo_action', {
                                action: t('ra.action.remove')
                              })}
                        </Button>
                      )}
                    </Stack>
                  );
                }}
              />
              <EditButton variant='outlined' size='small' icon={null} />
            </Stack>
          </Datagrid>
        )}

        {total > ROWS_PER_PAGE_OPTIONS[0] && (
          <Pagination
            total={total}
            page={page}
            setPage={setPage}
            perPage={perPage}
            setPerPage={setPerPage}
            rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            isLoading={isLoading}
            sx={{ width: '100%' }}
          />
        )}
      </ResourceContextProvider>
    </StyledStack>
  );
};

const PREFIX = 'OrganisationEditTeams';

const classes = {
  root: `${PREFIX}-root`,
  actions: `${PREFIX}-actions`
};

const StyledStack = styled(Stack)(({ theme }) => ({
  [`&.${classes.root}`]: {
    width: '100%'
  },
  [`& .${classes.actions}`]: {
    width: '100%',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(1.5)
  }
}));

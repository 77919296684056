import React, { createContext, useCallback, useContext, useMemo } from 'react';

const SideDialogContext = createContext();

export const SideDialogContextProvider = props => {
  const [isOpen, setIsOpen] = React.useState(false);

  const open = useCallback(() => {
    setIsOpen(true);
  }, []);

  const close = useCallback(() => {
    setIsOpen(false);
  }, []);

  const value = useMemo(
    () => [
      { isOpen },
      {
        open,
        close,
        setIsOpen
      }
    ],
    [close, isOpen, open]
  );

  return <SideDialogContext.Provider {...props} value={value} />;
};

export const useSideDialogContext = () => useContext(SideDialogContext);

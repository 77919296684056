import React from 'react';
import { styled } from '@mui/material';
import { Breadcrumbs, EditTitle } from '@rc/admin/components';
import { OneColumnFormStyles } from '@rc/admin/theme/styles';
import {
  Edit,
  Labeled,
  SaveButton,
  SimpleForm,
  TextField,
  Toolbar
} from 'react-admin';

export const RoleGroupEdit = () => {
  return (
    <StyledEdit
      title={<EditTitle title={<EditTitle />} showResourceName={false} />}
      actions={<Breadcrumbs />}
    >
      <SimpleForm
        redirect={false}
        className={classes.form}
        toolbar={
          <Toolbar>
            <SaveButton />
          </Toolbar>
        }
      >
        <Labeled>
          <TextField source={'name'} fullWidth />
        </Labeled>
      </SimpleForm>
    </StyledEdit>
  );
};

const PREFIX = 'InvitationEdit';

const classes = {
  form: `${PREFIX}-form`
};

const StyledEdit = styled(Edit)(({ theme }) => ({
  [`& .${classes.form}`]: {
    ...OneColumnFormStyles(theme)
  }
}));

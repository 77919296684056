import React from 'react';
import {
  ChoicesContextProvider,
  FieldTitle,
  InputHelperText,
  LoadingInput,
  sanitizeInputRestProps,
  SelectArrayInput,
  useChoicesContext,
  useTranslate
} from 'react-admin';
import {
  useGroupedChoices,
  sanitizeGroupedInputProps
} from '../useGroupedChoices';

/**
 * @typedef GroupedSelectArrayInputProps
 * @property {string} groupReference Resource name of the parent that makes the group.
 * @property {string} itemSource
 *
 * @param {import('react-admin').SelectArrayInputProps & import('../useGroupedChoices').useGroupedChoicesProps} props
 * @returns
 */
const GroupedSelectArrayInput = props => {
  const {
    label: labelProps,
    source,
    resource: resourceProp,
    margin,
    variant,
    size,
    sx,
    fullWidth
  } = props;
  const translate = useTranslate();
  const {
    choices,
    isLoading,
    isFetching,
    optionText,
    validate,
    ...selectArrayInputProps
  } = useGroupedChoices({
    ...props,
    allowMultiple: true
  });
  const choicesContext = useChoicesContext();

  const label =
    translate(labelProps) || translate(selectArrayInputProps.source);

  if (isLoading) {
    return (
      <LoadingInput
        label={
          label !== '' &&
          label !== false && (
            <FieldTitle label={label} source={source} resource={resourceProp} />
          )
        }
        sx={{
          ...sx,
          width: fullWidth ? '100%' : sx?.width
        }}
        variant={variant}
        size={size}
        margin={margin}
        helperText={
          <InputHelperText touched={false} error={null} helperText={''} />
        }
      />
    );
  }

  return (
    <ChoicesContextProvider
      value={{
        ...choicesContext,
        allChoices: choices,
        availableChoices: choices,
        isLoading,
        isFetching
      }}
    >
      <SelectArrayInput
        className={GroupedSelectArrayInputRootClass}
        {...sanitizeInputRestProps(sanitizeGroupedInputProps(props))}
        optionText={optionText}
        validate={validate}
        margin={'none'}
        label={label}
      />
    </ChoicesContextProvider>
  );
};

export const GroupedSelectArrayInputRootClass = 'RaSelectArrayInput';

GroupedSelectArrayInput.defaultProps = {
  optionText: 'name',
  optionValue: 'id',
  ...SelectArrayInput.defaultProps,
  sx: {}
};

export default GroupedSelectArrayInput;

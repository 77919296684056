import React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  BooleanInput,
  SimpleList,
  TextField,
  BooleanField,
  Datagrid,
  EditClasses,
  Toolbar,
  SaveButton,
  ToolbarClasses
} from 'react-admin';
import { EditButton } from '@rc/admin/ra';
import { styled, Card, useMediaQuery } from '@mui/material';
import {
  AsideReferenceList,
  Breadcrumbs,
  DeleteButton,
  EditTitle,
  SectionTitle
} from '@rc/admin/components';
import { TwoColumnFormStyles } from '@rc/admin/theme/styles';
import { EnvVarValidationReferenceArrayInput } from '../envVarValidation/components';

export const ComponentVersionEnvVarEdit = () => {
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

  return (
    <StyledEdit
      title={<EditTitle source={'key'} />}
      redirect={false}
      mutationMode='pessimistic'
      actions={
        <Breadcrumbs
          label={<BreadcrumbsLabel />}
          parents={breadcrumbsParents}
        />
      }
      aside={
        <Card>
          <AsideReferenceList
            resource={'component_version_env_var_examples'}
            referenceSource={'componentVersionEnvVar'}
            title={
              'resources.component_version_env_vars.fields.component_version_env_var_examples'
            }
          >
            {isSmall ? (
              <SimpleList
                primaryText={() => <TextField source={'value'} />}
                secondaryText={() => <BooleanField source={'isDefault'} />}
                style={{ width: '100%' }}
              />
            ) : (
              <Datagrid style={{ width: '100%' }}>
                <TextField source={'value'} />
                <BooleanField source={'isDefault'} />
                <EditButton />
              </Datagrid>
            )}
          </AsideReferenceList>
        </Card>
      }
    >
      <SimpleForm
        redirect={false}
        defaultValues={{ isEnabled: true }}
        className={classes.form}
        toolbar={
          <Toolbar>
            <div className={ToolbarClasses.defaultToolbar}>
              <SaveButton />
              <DeleteButton
                redirect={(_, __, record) =>
                  `/component_versions/${encodeURIComponent(
                    record?.componentVersion
                  )}`
                }
              />
            </div>
          </Toolbar>
        }
      >
        <TextInput source={'key'} fullWidth />
        <TextInput source={'note'} fullWidth />
        <BooleanInput source={'isRequired'} fullWidth />
        <BooleanInput source={'isEncrypted'} fullWidth />
        <SectionTitle
          title={
            'resources.component_version_env_vars.sections.env_var_validations'
          }
          sx={theme => ({ marginBottom: theme.spacing(2) })}
        />
        <EnvVarValidationReferenceArrayInput
          source='envVarValidations'
          label='resources.component_version_env_vars.fields.envVarValidations'
        />
      </SimpleForm>
    </StyledEdit>
  );
};

const breadcrumbsParents = [
  { reference: 'component_versions', source: 'componentVersion' },
  { reference: 'components', source: 'component' }
];

const BreadcrumbsLabel = props => {
  const { data } = props;

  switch (data['@type']) {
    case 'ComponentVersionEnvVar':
      return data.key;
    case 'ComponentVersion':
      return data.version;
    default:
      return data.name;
  }
};

const PREFIX = 'ComponentVersionEnvVarEdit';

const classes = {
  asideCard: `${PREFIX}-asideCard`,
  form: `${PREFIX}-form`
};

const StyledEdit = styled(Edit)(({ theme }) => ({
  [`& .${EditClasses.main}`]: {
    display: 'grid',
    gridTemplateColumns: '5fr 3fr',
    columnGap: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr',
      gap: theme.spacing(3),
      '& > *': {
        width: '100%'
      }
    }
  },

  [`& .${classes.asideCard}`]: {
    height: '100%'
  },

  [`& .${classes.form}`]: {
    ...TwoColumnFormStyles(theme)
  }
}));

import React, { useEffect, useState } from 'react';
import { ReferenceInput } from 'react-admin';
import { useFormContext, useWatch } from 'react-hook-form';

/**
 *
 * @param {import('react-admin').ReferenceInputProps & { dependencies: Array}} props
 */
export const ReferenceInputWithDependency = props => {
  const { source, dependencies, children, ...rest } = props;
  const dependencyValues = useWatch({ name: dependencies });
  const { getValues, resetField } = useFormContext();
  const [filter, setFilter] = useState(null);

  useEffect(() => {
    const newFilters = dependencies.reduce((result, key, index) => {
      result[key] = dependencyValues[index];
      return result;
    }, {});

    const currentValue = getValues(source);

    if (!currentValue) {
      // Clear field because MuiSelectInput preserves choices from older filter, if it's selected
      resetField(source, {
        keepDirty: true,
        keepError: true,
        keepTouched: true
      });
    }

    setFilter(newFilters);
  }, [dependencies, dependencyValues, getValues, resetField, source]);

  const shouldDisable = dependencyValues.some(value => !value);

  const input = React.Children.only(children);

  return (
    <ReferenceInput
      {...rest}
      source={source}
      filter={filter}
      enableGetChoices={() => !shouldDisable}
    >
      {React.cloneElement(input, {
        ...input.props,
        disabled: input.props.disabled || shouldDisable
      })}
    </ReferenceInput>
  );
};

ReferenceInputWithDependency.defaultProps = ReferenceInput.defaultProps;

import React from 'react';
import _ from 'lodash';
import { useForm } from 'react-hook-form';
import { useAxios } from '@rc/utils/axios';
import {
  Link,
  minLength,
  useNotify,
  useRedirect,
  useTranslate
} from 'react-admin';
import { Box, CardActions, Divider, styled, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FieldInput, PasswordStrengthMeter } from '@rc/admin/components';
import { validatePasswordStrength } from '@rc/admin/utils/form';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { GRECAPTCHA_ENABLED } from '@rc/admin/constants';

export const ResetPasswordForm = () => {
  const t = useTranslate();
  const redirect = useRedirect();
  const notify = useNotify();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const { handleSubmit, control, watch, trigger } = useForm({
    defaultValues: {
      password: ''
    }
  });

  const urlParams = new URLSearchParams(
    _.last(window.location.hash.split('?'))
  );

  const [{ loading: isLoading }, resetPassword] = useAxios(
    {
      url: `/api/users/reset-password`,
      params: { hash: urlParams.get('hash') },
      method: 'PUT'
    },
    {
      manual: true
    }
  );

  const onSubmit = async data => {
    let recaptchaToken = null;
    if (GRECAPTCHA_ENABLED && !executeRecaptcha) {
      console.error('Error on captcha');
      return;
    } else if (executeRecaptcha) {
      recaptchaToken = await executeRecaptcha('resetPassword');
    }

    await resetPassword({
      data,
      headers: {
        'g-recaptcha-token': recaptchaToken
      }
    })
      .then(r => {
        redirect('/login');
        notify('pages.reset_password.notification.success', {
          type: 'success',
          undoable: false
        });
      })
      .catch(e => {
        notify('pages.reset_password.notification.error', {
          type: 'error',
          undoable: false
        });
      });
  };

  return (
    <StyledForm
      className={classes.form}
      autoComplete='off'
      onSubmit={handleSubmit(onSubmit)}
    >
      <Typography
        variant='h1'
        fontSize={theme => theme.typography.h2.fontSize}
        textAlign='center'
        sx={{ mt: 2, mb: 4 }}
      >
        {t('pages.reset_password.title')}
      </Typography>

      <Box sx={{ mb: 1.5 }}>
        <FieldInput
          name={'password'}
          label='Password'
          type='password'
          control={control}
          rules={{
            required: 'ra.validation.required',
            validate: {
              strength: validatePasswordStrength,
              length: value =>
                minLength(8, 'Must be 8 characters at least')(value)?.message
            }
          }}
          onBlur={() => trigger('password')}
          required
        />
        <PasswordStrengthMeter password={watch('password')} sx={{ mt: 1 }} />
      </Box>
      <CardActions className={classes.actions}>
        <LoadingButton
          variant='contained'
          type='submit'
          color='primary'
          size='large'
          fullWidth
          loading={isLoading}
        >
          Change my password, please!
        </LoadingButton>
      </CardActions>

      <Divider sx={{ mt: 2, mb: 2 }} />
      <Box sx={{ textAlign: 'center' }}>
        <Typography
          className={classes.resetPassword}
          component={Link}
          to={'/login'}
          variant='subtitle1'
        >
          {t('pages.reset_password.login')}
        </Typography>
      </Box>
    </StyledForm>
  );
};

const PREFIX = 'RegisterPasswordForm';

const classes = {
  resetPassword: `${PREFIX}-resetPassword`,
  form: `${PREFIX}-form`,
  avatar: `${PREFIX}-avatar`,
  actions: `${PREFIX}-actions`
};

const StyledForm = styled('form')(({ theme }) => ({
  [`&.${classes.form}`]: {
    padding: '0 1em 1em 1em'
  },

  [`& .${classes.resetPassword}`]: {
    textDecoration: 'none'
  },

  [`& .${classes.actions}`]: {
    marginTop: theme.spacing(4),
    padding: 0
  }
}));

export default ResetPasswordForm;

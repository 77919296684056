import React, { useCallback } from 'react';
import {
  SideDialog,
  SideDialogContextProvider,
  useSideDialogContext
} from '@rc/admin/components/ui/SideDialog';
import {
  Form,
  RecordContextProvider,
  ReferenceInput,
  SelectInput,
  required,
  useNotify,
  useTranslate,
  useRefresh
} from 'react-admin';
import { Button, CircularProgress } from '@mui/material';
import { Save as ContentSave } from '@mui/icons-material';
import { useAxios } from '@rc/utils/axios';
import { useFormContext } from 'react-hook-form';

/**
 * @typedef {object} EditRoleGroupDialogProps
 * @property {import('react-admin').RaRecord} user
 * @property {import('react-admin').RaRecord} team
 * @property {{ roleGroup: string, user: string }} teamUser
 */

/**
 *
 * @param {EditRoleGroupDialogProps & import('@rc/admin/components/ui/SideDialog/SideDialog').SideDialogProps} props
 * @returns
 */
export const EditRoleGroupDialog = props => {
  return (
    <SideDialogContextProvider>
      <RecordContextProvider value={props.teamUser}>
        <EditRoleGroupDialogView {...props} />
      </RecordContextProvider>
    </SideDialogContextProvider>
  );
};

const ContainerComponent = props => (
  <Form {...props} formRootPathname='edit_role_group_dialog' />
);

/**
 *
 * @param {EditRoleGroupDialogProps & import('@rc/admin/components/ui/SideDialog/SideDialog').SideDialogProps} props
 * @returns
 */
export const EditRoleGroupDialogView = props => {
  const { team, user, teamUser, ...rest } = props;
  const t = useTranslate();
  const [{ isOpen }, { open, close }] = useSideDialogContext();

  return (
    <SideDialog
      title={t('ra.action.edit')}
      dialogTitle={t('resources.teams.sections.edit_team_member', {
        name: user.fullname
      })}
      isOpen={isOpen}
      handleClose={close}
      handleOpen={open}
      actions={[<SubmitButton team={team} user={user} key='save' />]}
      container={ContainerComponent}
      {...rest}
    >
      <ReferenceInput
        reference='role_groups'
        source='roleGroup'
        filter={{ name: 'Team' }}
      >
        <SelectInput optionText='name' validate={[required()]} />
      </ReferenceInput>
    </SideDialog>
  );
};

const SubmitButton = props => {
  const { team, user } = props;
  const t = useTranslate();
  const refresh = useRefresh();
  const notify = useNotify();
  const [, { close }] = useSideDialogContext();
  const {
    handleSubmit,
    formState: { isSubmitting }
  } = useFormContext();
  const [, execute] = useAxios(`/api/teams/${team.originId}/user-role`, {
    manual: true
  });

  const submit = useCallback(
    async data => {
      try {
        await execute({
          method: 'PUT',
          url: `/api/teams/${team.originId}/user-role`,
          data: {
            roleGroupId: data.roleGroup?.split('/').pop(),
            userId: user.id.split('/').pop()
          }
        });

        notify('ra.notification.updated', {
          messageArgs: {
            smart_count: 1
          },
          type: 'info'
        });

        refresh();
        close();
      } catch (error) {
        notify('ra.notification.http_error', { type: 'error' });
      }
    },
    [execute, team.originId, user.id, notify, refresh, close]
  );

  return (
    <Button
      variant='contained'
      color='primary'
      onClick={handleSubmit(submit)}
      disabled={isSubmitting}
    >
      {isSubmitting ? (
        <CircularProgress
          sx={{
            '&.MuiCircularProgress-root': {
              marginRight: '10px',
              marginLeft: '2px'
            }
          }}
          size={14}
          thickness={3}
          color='inherit'
        />
      ) : (
        <ContentSave sx={theme => ({ marginRight: theme.spacing(0.5) })} />
      )}
      {t('ra.action.save')}
    </Button>
  );
};

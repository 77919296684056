import React, { useCallback, useState } from 'react';
import { Box, Card, Grid, Stack, styled, useMediaQuery } from '@mui/material';
import {
  HelpCard,
  HelpLayout,
  HelpLayoutClasses,
  Image,
  PossibleFieldOrInput,
  SectionHelper,
  SectionTitle
} from '@rc/admin/components';
import {
  EXTERNAL_GIT_TYPE_ID,
  INTERNAL_GIT_TYPE_ID,
  STATE_IDS
} from '@rc/admin/constants';
import { useCanRetry, useIsTemplateResource } from '@rc/admin/hooks';
import {
  OneColumnFormStyles,
  TwoColumnFormStyles
} from '@rc/admin/theme/styles';
import { createIRI } from '@rc/admin/utils';
import { RichTextInput } from 'ra-input-rich-text';
import {
  BooleanInput,
  DeleteButton,
  FormTab,
  ReferenceInput,
  required,
  SaveButton,
  TabbedForm,
  TextInput,
  TextField,
  Toolbar as RaToolbar,
  useNotify,
  useRecordContext,
  useRedirect,
  useResourceContext,
  useTranslate,
  useUnselect,
  useUpdate,
  ReferenceField,
  FunctionField,
  Labeled,
  useSidebarState
  // useTimeout
} from 'react-admin';
import {
  TEMPLATE_PROJECT_DESCRIPTION_EDITOR_OPTIONS,
  TemplateProjectDescriptionToolbar,
  validateTemplateProjectDescription
} from '../../templateProject/utils';
import {
  ProjectEnvVarInputs,
  ProjectTypeVersionSelectInput,
  EditComponentsSelector,
  EditProjectResourceInputs
} from '../components';
import { GitEditDialog } from '../../git';
// import ProjectEditTour from './EditFeatureTour.js';
// export { useFeatureTour } from '@rc/admin/context/FeatureTourContext';

export const ProjectEditForm = () => {
  const notify = useNotify();
  const [update] = useUpdate();
  const resource = useResourceContext();
  const redirect = useRedirect();
  const t = useTranslate();
  const isTemplate = useIsTemplateResource();
  const [isSidebarOpen] = useSidebarState();
  const isSmallContentWidth = useMediaQuery(theme =>
    theme.breakpoints.down(isSidebarOpen ? 'xl' : 'lg')
  );

  // const { next: nextTourStep } = useFeatureTour(
  //   ProjectEditTour,
  //   useTimeout(1500)
  // );

  const submit = useCallback(
    async data => {
      const result = data;

      await update(resource, { data: result, id: result['@id'] });
      notify('ra.notification.updated', {
        messageArgs: {
          smart_count: 1
        },
        type: 'info'
      });
      redirect('edit', resource, result.id, result);
    },
    [notify, redirect, resource, update]
  );

  return (
    <Card>
      <StyledBox>
        <TabbedForm
          className={classes.form}
          defaultValues={{ isEnabled: true, git: {}, projectEnvVar: [] }}
          toolbar={<Toolbar />}
          onSubmit={submit}
          id='project-edit-form'
        >
          <FormTab
            id='project-general-tab'
            label='resources.projects.tabs.general'
          >
            <Stack className={classes.details} alignItems='flex-start'>
              <Box id='project-main-details' width={'100%'}>
                <SectionTitle>
                  {t('resources.projects.sections.identity.title')}
                </SectionTitle>
                <Grid container wrap='wrap' spacing={1} mt={1}>
                  <Grid item xs={12} sm={6}>
                    <TextInput
                      source={'name'}
                      id={'project-name'}
                      label={'resources.projects.fields.name'}
                      validate={[required()]}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <ReferenceInput
                      source={'projectTypeVersion'}
                      reference={'project_type_versions'}
                    >
                      <ProjectTypeVersionSelectInput
                        id={'project-type-version'}
                        validate={[required()]}
                        fullWidth
                      />
                    </ReferenceInput>
                  </Grid>
                  {isTemplate && (
                    <Grid item xs={12}>
                      <RichTextInput
                        source='description'
                        label={'resources.template_projects.fields.description'}
                        editorOptions={
                          TEMPLATE_PROJECT_DESCRIPTION_EDITOR_OPTIONS
                        }
                        toolbar={<TemplateProjectDescriptionToolbar />}
                        validate={validateTemplateProjectDescription}
                        fullWidth
                      />
                    </Grid>
                  )}
                </Grid>
                <SectionTitle>
                  {t('resources.projects.sections.components.title')}
                </SectionTitle>
                <SectionHelper mb={2}>
                  {t('resources.projects.sections.components.subtitle')}
                </SectionHelper>
                <Grid container wrap='wrap' spacing={1}>
                  <Grid item xs={12}>
                    <EditComponentsSelector />
                  </Grid>
                </Grid>
              </Box>

              {!isTemplate && (
                <Box width='100%' mb={2}>
                  <SectionTitle>
                    {t('resources.projects.sections.git.title')}
                  </SectionTitle>
                  <ReferenceField source='git' reference='gits'>
                    <FunctionField
                      render={record => {
                        return (
                          <>
                            <Grid container>
                              {record.gitType === INTERNAL_GIT_TYPE_ID && (
                                <Grid item xs={12}>
                                  <span>
                                    {t(
                                      'components.git.fields.gitType_internal'
                                    )}
                                    {'.'}
                                  </span>
                                </Grid>
                              )}
                              {record.gitType === EXTERNAL_GIT_TYPE_ID && (
                                <Grid item xs={12}>
                                  <Stack
                                    width={'100%'}
                                    flexDirection={'row'}
                                    justifyContent={'space-between'}
                                    alignItems={'flex-end'}
                                  >
                                    <PossibleFieldOrInput source='repo'>
                                      <Labeled
                                        label={'components.git.fields.url'}
                                        width='100%'
                                      >
                                        <TextField source={'repo'} />
                                      </Labeled>
                                    </PossibleFieldOrInput>
                                    <GitEditDialog record={record} />
                                  </Stack>
                                </Grid>
                              )}
                            </Grid>
                          </>
                        );
                      }}
                    />
                  </ReferenceField>
                </Box>
              )}

              {isTemplate && (
                <Grid container>
                  <Grid item xs={12}>
                    <BooleanInput
                      source={'isInitProjectSkeleton'}
                      label={'resources.projects.fields.isInitProjectSkeleton'}
                      fullWidth
                      sx={theme => ({ marginBottom: theme.spacing(1) })}
                    />
                  </Grid>
                </Grid>
              )}
            </Stack>
          </FormTab>
          <FormTab
            id='project-resources-tab'
            label='resources.projects.tabs.resources'
            // onClick={nextTourStep}
          >
            <HelpLayout
              id='project-resources-fields'
              className={classes.resources}
              helpers={
                <HelpCard title='resources.projects.helpers.resources.title'>
                  {'resources.projects.helpers.resources.content'}
                </HelpCard>
              }
            >
              <SectionTitle mb={0.5}>
                {t('resources.environments.sections.resources')}
              </SectionTitle>
              <SectionHelper mb={3}>
                {t('resources.projects.helpers.resources.subtitle')}
              </SectionHelper>
              <EditProjectResourceInputs sx={OneColumnFormStyles} />
            </HelpLayout>
          </FormTab>
          <FormTab
            id='project-variables-tab'
            label='resources.projects.tabs.variables'
            // onClick={nextTourStep}
          >
            <HelpLayout
              id='project-resources-fields'
              className={classes.variables}
              isTooltip={isSmallContentWidth}
              helpers={
                <HelpCard title='resources.projects.helpers.envVars.title'>
                  {t('resources.projects.helpers.envVars.content')}
                  <Image
                    src='/images/helper-envVars.png'
                    alt='Variables helper image'
                    width={1338}
                    height={678}
                  />
                </HelpCard>
              }
            >
              <SectionTitle mb={0.5}>
                {t('resources.projects.sections.envVars.title')}
              </SectionTitle>
              <SectionHelper mb={1}>
                {t('resources.projects.sections.envVars.subtitle')}
              </SectionHelper>
              <ProjectEnvVarInputs
                id='project-variables-fields'
                source={'projectEnvVar'}
                label={'resources.projects.fields.projectEnvVar'}
                fullWidth
              />
            </HelpLayout>
          </FormTab>
        </TabbedForm>
      </StyledBox>
    </Card>
  );
};

const Toolbar = () => {
  const t = useTranslate();
  const notify = useNotify();
  const canRetry = useCanRetry();
  const record = useRecordContext();
  const unselect = useUnselect('projects');
  const [deleteRequested, setDeleteRequested] = useState(false);

  const onDeleteSuccess = () => {
    notify('resources.projects.notification.delete_requested', {
      type: 'info'
    });
    unselect([record.id]);
    setDeleteRequested(true);
  };

  const isDeleting =
    deleteRequested ||
    record?.state === createIRI('states', STATE_IDS.DEL) ||
    record?.state === createIRI('states', STATE_IDS.DEL_REQ) ||
    record?.state === createIRI('states', STATE_IDS.DELETED);

  return (
    <RaToolbar className={classes.toolbar}>
      <SaveButton
        label={canRetry ? t('action.try_again') : undefined}
        icon={canRetry ? null : undefined}
        alwaysEnable={!isDeleting && canRetry}
        disabled={isDeleting}
      />
      <Stack flexDirection={'row'} gap={2} alignItems={'center'}>
        {!isDeleting && (
          <DeleteButton
            variant='text'
            mutationMode={'pessimistic'}
            mutationOptions={{ onSuccess: onDeleteSuccess }}
            confirmTitle={'ra.message.delete_title'}
            translateOptions={{
              name: record?.name
            }}
            sx={{ marginTop: 0 }}
          />
        )}
      </Stack>
    </RaToolbar>
  );
};

const PREFIX = 'ProjectEdit';

const classes = {
  form: `${PREFIX}-form`,
  details: `${PREFIX}-details`,
  resources: `${PREFIX}-resources`,
  variables: `${PREFIX}-variables`,
  toolbar: `${PREFIX}-toolbar`,
  toolbarGroup: `${PREFIX}-toolbarGroup`
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`& .${classes.form}`]: {
    maxWidth: 'calc(100vw - 32px)'
  },

  [`& .${classes.details}, .${classes.resources} .${HelpLayoutClasses.content}`]: TwoColumnFormStyles(
    theme
  ),

  [`& .${classes.toolbar}`]: {
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between'
  },

  [`& .${classes.toolbarGroup}`]: {
    display: 'flex',
    columnGap: theme.spacing(2),
    alignItems: 'center'
  },

  [`& .${classes.resources}.${HelpLayoutClasses.root}:not(.${HelpLayoutClasses.withTooltip})`]: {
    [`& .${HelpLayoutClasses.helper}`]: {
      justifyContent: 'flex-start'
    }
  },

  [`& .${classes.variables}.${HelpLayoutClasses.root}:not(.${HelpLayoutClasses.withTooltip})`]: {
    [`& .${HelpLayoutClasses.content}`]: {
      maxWidth: 'unset'
    }
  }
}));

import React from 'react';
import {
  Datagrid,
  EditButton,
  ReferenceField,
  SimpleList,
  TextField
} from 'react-admin';
import { Stack, useMediaQuery } from '@mui/material';
import { ListWithMercure, ProjectTypeVersionField } from '@rc/admin/components';

export const TemplateProjectList = () => {
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

  return (
    <Stack>
      <ListWithMercure
        exporter={false}
        sort={{ order: 'DESC', field: 'updatedAt' }}
        perPage={25}
      >
        {isSmall ? (
          <SimpleList
            primaryText={<TextField source='name' />}
            secondaryText={
              <ReferenceField
                source={'projectTypeVersion'}
                reference={'project_type_versions'}
                link={false}
              >
                <ProjectTypeVersionField source={'version'} />
              </ReferenceField>
            }
          />
        ) : (
          <Datagrid width={'100vw'}>
            <TextField source='name' />
            <ReferenceField
              source={'projectTypeVersion'}
              reference={'project_type_versions'}
              link={false}
            >
              <ProjectTypeVersionField source={'version'} />
            </ReferenceField>
            <EditButton />
          </Datagrid>
        )}
      </ListWithMercure>
    </Stack>
  );
};

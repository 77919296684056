import { ProjectTypeVersionEnvVarExampleEdit } from './Edit';
import { ProjectTypeVersionEnvVarExampleCreate } from './Create';

export default {
  name: 'project_type_version_env_var_examples',
  edit: ProjectTypeVersionEnvVarExampleEdit,
  create: ProjectTypeVersionEnvVarExampleCreate,
  options: {
    showInMenu: false
  }
};

import React from 'react';
import { OpenInNew } from '@mui/icons-material';
import { Link, styled, Typography } from '@mui/material';

/**
 * ExternalLink Component
 *
 * @typedef {object} ExternalLinkProps
 * @property {Object} props
 * @property {string} props.url
 * @property {string} props.width
 * @property {boolean} props.disabled
 *
 * @param {ExternalLinkProps & import('@mui/material').TypographyProps} props
 *
 */
export const ExternalLink = props => {
  const { url, width = '100%', disabled, children, ...rest } = props;
  const windowFeatures = 'noopener noreferrer';
  const target = '_blank';

  const linkProps = disabled
    ? {}
    : {
        href: /^http(s):\/\//.test(url) ? url : `https://${url}`,
        target,
        rel: windowFeatures,
        onClick: e => e.stopPropagation()
      };

  return (
    <StyledTypography
      component={!disabled ? Link : 'span'}
      width={width}
      noWrap
      disabled={disabled}
      {...rest}
      {...linkProps}
    >
      <Typography component={'span'} variant='inherit' className={classes.text}>
        {children || url}
        <OpenInNew fontSize={'small'} />
      </Typography>
    </StyledTypography>
  );
};

const PREFIX = 'ExternalLink';

const classes = {
  text: `${PREFIX}-text`
};

const StyledTypography = styled(Typography)(({ theme }) => ({
  display: 'flex',
  opacity: 0.75,
  color: theme.palette.text.primary,
  // textDecoration: 'none',
  position: 'relative',
  maxWidth: '100%',

  '& svg': {
    opacity: 0,
    position: 'absolute',
    bottom: 0,
    right: 0,
    background: theme.palette.background.paper,
    paddingLeft: theme.spacing(0.5)
  },

  '&[disabled=""] svg': {
    opacity: 1,
    right: 'unset'
  },

  '&:hover': {
    // textDecoration: 'underline',
    opacity: 1,
    '& svg': {
      opacity: 1
    }
  },

  [`& .${classes.text}`]: {
    WebkitLineClamp: 1,
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  }
}));

import React from 'react';
import clsx from 'clsx';
import { Box, FormControl, styled, TextField, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import {
  FieldTitle,
  FunctionField,
  Link,
  ReferenceField,
  SimpleFormIteratorClasses,
  useCreatePath,
  useRecordContext,
  useResourceContext,
  useTranslate
} from 'react-admin';
import { TabbableFormIteratorClasses } from '../../form';
import { useWatch } from 'react-hook-form';
import { useDefaultEnvVarType } from '@rc/admin/resources/environment/hooks';
import { Iconify, SectionHelper } from '../../ui';

export const EnvVarFields = props => {
  const {
    source,
    fieldSource,
    label = fieldSource,
    fullWidth,
    emptyLabel,
    overrides,
    formIteratorApiRef
  } = props;
  const record = useRecordContext();
  const resource = useResourceContext();
  const t = useTranslate();
  const envVars = record?.[fieldSource];
  const formEnvVars = useWatch({ name: source });
  const { defaultEnvVarType } = useDefaultEnvVarType();
  const createPath = useCreatePath();

  if (!envVars?.length) {
    return null;
  }

  return (
    <StyledFormControl className={EnvVarFieldsClasses.root}>
      <SectionHelper>
        <FieldTitle
          label={envVars?.length ? label : emptyLabel}
          source={fieldSource}
        />
        <Link to={createPath({ resource, type: 'edit', id: record?.id })}>
          {record.name}
        </Link>
      </SectionHelper>
      <Box
        className={clsx(
          TabbableFormIteratorClasses.root,
          EnvVarFieldsClasses.iterator
        )}
        component={'ul'}
      >
        {envVars?.map((_, index) => {
          const envVar = envVars[index];
          const overrideEnvVar = overrides.find(override =>
            override.record[override.source].find(
              overrideEnvVar => overrideEnvVar.key === envVar.key
            )
          );
          const formOverrideEnvVar =
            !overrideEnvVar &&
            formEnvVars?.find(formEnvVar => formEnvVar.key === envVar.key);
          const hasOverride = !!(overrideEnvVar || formOverrideEnvVar);

          return (
            <Box
              key={`${fieldSource}.${index}`}
              className={clsx(
                SimpleFormIteratorClasses.line,
                EnvVarFieldsClasses.line,
                hasOverride ? EnvVarFieldsClasses.hasOverride : ''
              )}
              component={'li'}
            >
              <Box
                className={clsx(
                  SimpleFormIteratorClasses.form,
                  EnvVarFieldsClasses.form
                )}
                component={'section'}
              >
                <TextField
                  size='small'
                  value={envVar?.key}
                  label={t('components.envVar.fields.key')}
                  disabled={true}
                  fullWidth={fullWidth}
                />
                <TextField
                  size='small'
                  value={envVar?.isEncrypted ? '••••••••' : envVar?.value}
                  label={t('components.envVar.fields.value')}
                  disabled={true}
                  fullWidth={fullWidth}
                />
                <ReferenceField
                  source={'envVarType'}
                  reference={'env_var_types'}
                  record={envVar}
                  link={false}
                >
                  <FunctionField
                    style={fullWidth ? { width: '100%' } : undefined}
                    render={data => (
                      <TextField
                        size='small'
                        value={data.name}
                        label={t('components.envVar.fields.type')}
                        disabled={true}
                        fullWidth={fullWidth}
                      />
                    )}
                    fullWidth={fullWidth}
                  />
                </ReferenceField>
                {/* Placeholder for 'isEncrypted' switch input, in order to align */}
                {/* <FormControlLabel
                  label=''
                  control={
                    <Switch
                      title={'Is secured'}
                      checked={!!envVar?.isEncrypted}
                      color='primary'
                      checkedIcon={<Lock />}
                      icon={<LockOpen />}
                      disabled={true}
                    />
                  }
                /> */}
              </Box>
              <Box className={clsx(SimpleFormIteratorClasses.action)}>
                {!hasOverride ? (
                  <IconButton
                    variant='text'
                    title={t('action.overwrite')}
                    aria-label={t('action.overwrite')}
                    onClick={() =>
                      formIteratorApiRef?.current?.add({
                        key: envVar.key,
                        value: '',
                        isEncrypted: false,
                        envVarType: defaultEnvVarType?.id
                      })
                    }
                  >
                    <EditIcon />
                  </IconButton>
                ) : (
                  <span />
                )}
              </Box>
            </Box>
          );
        })}
      </Box>
    </StyledFormControl>
  );
};

const PREFIX = 'EnvVarValueField';

export const EnvVarFieldsClasses = {
  root: `${PREFIX}-root`,
  iterator: `${PREFIX}-iterator`,
  line: `${PREFIX}-item`,
  hasOverride: `${PREFIX}-hasOverride`,
  form: `${PREFIX}-form`
};

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  [`&.${EnvVarFieldsClasses.root}`]: {
    display: 'inline-flex',
    flexDirection: 'column',
    position: 'relative',
    minWidth: 0,
    padding: 0,
    paddingTop: theme.spacing(2),
    margin: 0,
    border: 0,
    verticalAlign: 'top',
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.25),
    width: '100%'
  },

  [`& .${EnvVarFieldsClasses.iterator}`]: {
    padding: 0,
    marginBottom: 0
  },

  [`& .${EnvVarFieldsClasses.line}`]: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(2)
    },
    [`&.${EnvVarFieldsClasses.hasOverride}`]: {
      opacity: 0.5,
      [`&:hover`]: {
        opacity: 0.75
      }
    }
  },

  [`& .${EnvVarFieldsClasses.form}`]: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexDirection: 'row',
      columnGap: theme.spacing(1),
      marginRight: theme.spacing(1)
    },
    '& .MuiTextField-root': {
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(0.5)
    }
  },

  [`& .${EnvVarFieldsClasses.root}`]: {
    maxWidth: '50em'
  },

  [`& .${SimpleFormIteratorClasses.action}`]: {
    minWidth: '5.625rem',
    paddingTop: '0.5em'
  }
}));

EnvVarFields.defaultProps = {
  empty: null
};

import React, { useRef, useCallback } from 'react';
import { RecordContextProvider, useTranslate } from 'react-admin';
import { useWatch } from 'react-hook-form';
import { Box, styled } from '@mui/material';
import { EnvVarFields, EnvVarInputs } from '@rc/admin/components';
import { useInheritedEnvVars, useTeam } from '@rc/admin/hooks';

/**
 *
 * @param {import('@rc/admin/components/domain/envVar/EnvVarInputs').EnvVarInputsProps} props
 * @returns
 */
export const ProjectEnvVarInputs = props => {
  const translate = useTranslate();
  const teamId = useWatch({ name: 'team' });
  const { team, organisation, isLoading: isTeamLoading } = useTeam({
    teamId
  });

  const { envVarsAggregated } = useInheritedEnvVars({ team, organisation });

  const formIteratorApiRef = useRef();
  const setFormIteratorApiRef = useCallback(
    value => (formIteratorApiRef.current = value),
    []
  );

  if (isTeamLoading) {
    return null;
  }

  return (
    <EnvVarInputs
      {...props}
      setFormIteratorApi={setFormIteratorApiRef}
      inheritedEnvVars={envVarsAggregated}
    >
      {envVarsAggregated.map((data, index, array) => (
        <StyledBox key={`inherited-${data.id}`} className={classes.item}>
          <RecordContextProvider value={data.record}>
            <EnvVarFields
              source={props.source}
              fieldSource={data.source}
              overrides={index > 0 ? array.slice(0, index) : []}
              label={translate('resources.env_vars.label.inherited_env_vars', {
                entity: data.record['@type']
              })}
              fullWidth
              emptyLabel={translate(
                'resources.env_vars.label.no_inherited_env_vars',
                {
                  entity: data.record['@type']
                }
              )}
              formIteratorApiRef={formIteratorApiRef}
            />
          </RecordContextProvider>
        </StyledBox>
      ))}
    </EnvVarInputs>
  );
};

const PREFIX = 'ProjectEnvVarInputs';

const classes = {
  item: `${PREFIX}-inheritedItem`
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.item}`]: {
    marginTop: theme.spacing(1.5),

    [`&:nth-of-type(2)`]: {
      marginTop: theme.spacing(3)
    }
  }
}));

import { Box, styled, useMediaQuery } from '@mui/material';
import {
  HelpCard,
  HelpLayout,
  HelpLayoutClasses,
  LoadingBox,
  SectionHelper,
  SectionTitle
} from '@rc/admin/components';
import { TwoColumnFormStyles } from '@rc/admin/theme/styles';
import React, { useEffect } from 'react';
import { useSidebarState, useTranslate } from 'react-admin';

import { useCopyEnvironmentComponent } from '@rc/admin/hooks';
import { EnvironmentResourceAndComponentInputs } from '../components';

/**
 *
 * @param {object} props
 * @param {React.MutableRefObject<string>} props.lastCopiedFromRef
 * @returns
 */
export const EnvironmentCreateStepResources = props => {
  const { lastCopiedFromRef, showTitle = true } = props;

  const t = useTranslate();
  const [isSidebarOpen] = useSidebarState();
  const isSmallContentWidth = useMediaQuery(theme =>
    theme.breakpoints.down(isSidebarOpen ? 'xl' : 'lg')
  );

  const { isCopying, copyResourcesFromEnvironment } =
    useCopyEnvironmentComponent(lastCopiedFromRef);

  useEffect(() => {
    copyResourcesFromEnvironment();
  }, [copyResourcesFromEnvironment]);

  return (
    <StyledHelpLayout
      className={classes.resources}
      isTooltip={isSmallContentWidth}
      helpers={
        <HelpCard
          title='resources.environments.helpers.environmentComponent.title'
          sx={{
            position: 'sticky',
            top: 72
          }}
        >
          {'resources.environments.helpers.environmentComponent.content'}
        </HelpCard>
      }
    >
      <Box sx={TwoColumnFormStyles}>
        {showTitle && (
          <SectionTitle mb={0.5}>
            {t('resources.projects.sections.resources')}
          </SectionTitle>
        )}
        <SectionHelper mb={3}>
          {t('resources.environments.helpers.resources')}
        </SectionHelper>
      </Box>

      {isCopying ? <LoadingBox /> : <EnvironmentResourceAndComponentInputs />}
    </StyledHelpLayout>
  );
};

const PREFIX = 'EnvironmentCreate';

const classes = {
  resources: `${PREFIX}-resources`
};

const StyledHelpLayout = styled(HelpLayout)(({ isTooltip }) => ({
  ...(!isTooltip
    ? {
        display: 'grid',
        gridTemplateColumns: '4fr 2.5fr',
        [`& .${HelpLayoutClasses.content}`]: {
          maxWidth: '60em'
        },
        [`& .${HelpLayoutClasses.helper}`]: {
          minHeight: 400,
          display: 'block'
        }
      }
    : {})
}));

import React, { useState } from 'react';
import { useTranslate } from 'react-admin';
import { useFormState } from 'react-hook-form';
import {
  Box,
  Stack,
  Divider,
  Tabs,
  Tab,
  Card,
  CardContent,
  styled,
  FormControlLabel,
  Switch,
  FormGroup
} from '@mui/material';
import {
  // ErrorMessage,
  CpuInputConfig,
  MemoryInputConfig,
  StorageInputConfig,
  TabPanel
} from '@rc/admin/components';
import { ENABLE_ENVIRONMENT_LEVEL_RESOURCE_LIMITS } from '@rc/admin/constants';
import { EnvironmentResourceInput } from './EnvironmentResourceInput';
import { EnvironmentComponentInputs } from './EnvironmentComponentInputs';
import { EstimatedResourceCosts } from '../EstimatedResourceCosts';

const RESOURCE_INPUTS = [
  {
    source: 'cpu',
    config: CpuInputConfig
  },
  {
    source: 'memory',
    config: MemoryInputConfig
  },
  {
    source: 'storage',
    config: StorageInputConfig
  }
];

/**
 *
 * @param {object} props
 * @param {boolean} props.showEstimatedCosts
 * @param {boolean} props.isTemplate
 * @returns
 */
export const EnvironmentResourceAndComponentInputs = props => {
  const { showEstimatedCosts, isTemplate, ...rest } = props;
  const t = useTranslate();
  const { errors } = useFormState();
  const [tabIdx, setTabIdx] = useState(
    ENABLE_ENVIRONMENT_LEVEL_RESOURCE_LIMITS ? '0' : '1'
  );
  const [showComponents, setShowComponents] = useState(isTemplate);

  const ResourceInputs = RESOURCE_INPUTS.map(({ source, config }, index) => (
    <EnvironmentResourceInput
      key={`${index}-${source}`}
      source={source}
      {...config}
      fullWidth
    />
  ));

  const hasError = errors['environmentComponent'];

  return (
    <StyledStack
      className={classes.resources}
      divider={
        <Divider className={classes.divider} orientation='vertical' flexItem />
      }
      direction={{ xs: 'column', md: 'row' }}
      justifyContent={{ xs: 'normal', md: 'flex-start' }}
      spacing={4}
      showEstimatedCosts={showEstimatedCosts}
      {...rest}
    >
      {(ENABLE_ENVIRONMENT_LEVEL_RESOURCE_LIMITS || showEstimatedCosts) && (
        <Box className={classes.environmentLimits}>
          {/* <SectionTitle>{'Environment limits'}</SectionTitle> */}
          <Card className={classes.environmentLimitsSticky}>
            <CardContent sx={{ p: 1 }}>
              {showEstimatedCosts ? (
                <>
                  <Tabs
                    value={tabIdx}
                    onChange={(_, newValue) => {
                      if (!ENABLE_ENVIRONMENT_LEVEL_RESOURCE_LIMITS) return;
                      setTabIdx(newValue);
                    }}
                    scrollButtons={false}
                  >
                    <Tab
                      value={'0'}
                      label={t('resources.environments.tabs.limits')}
                      sx={{
                        width: '50%',
                        display: ENABLE_ENVIRONMENT_LEVEL_RESOURCE_LIMITS
                          ? undefined
                          : 'none'
                      }}
                      disabled={!ENABLE_ENVIRONMENT_LEVEL_RESOURCE_LIMITS}
                    />
                    <Tab
                      value={'1'}
                      label={t('resources.environments.tabs.costs')}
                      sx={{ width: '50%' }}
                    />
                  </Tabs>
                  <Box>
                    <TabPanel
                      index={'0'}
                      value={tabIdx}
                      sx={{
                        display: ENABLE_ENVIRONMENT_LEVEL_RESOURCE_LIMITS
                          ? undefined
                          : 'none'
                      }}
                    >
                      {ResourceInputs}
                    </TabPanel>
                    <TabPanel index={'1'} value={tabIdx}>
                      <EstimatedResourceCosts source={'environmentComponent'} />
                    </TabPanel>
                  </Box>
                </>
              ) : (
                ResourceInputs
              )}
            </CardContent>
          </Card>
        </Box>
      )}
      <Box className={classes.components}>
        <FormGroup sx={{ mb: 4 }}>
          <FormControlLabel
            control={
              <Switch
                checked={showComponents}
                onChange={() => setShowComponents(curr => !curr)}
                disabled={hasError}
                inputProps={{
                  'aria-label': t(
                    `resources.environments.action.show_component_settings`
                  )
                }}
              />
            }
            label={t(`resources.environments.action.show_component_settings`)}
          />
        </FormGroup>

        {(showComponents || hasError) && (
          <EnvironmentComponentInputs source={'environmentComponent'} />
        )}
        {/* <ErrorMessage source={'environmentComponentArrayInput'} /> */}
      </Box>
    </StyledStack>
  );
};

const PREFIX = 'EnvironmentResourceAndComponentInputs';

const classes = {
  environmentLimits: `${PREFIX}-environmentLimits`,
  environmentResourceInputs: `${PREFIX}-environmentResourceInputs`,
  components: `${PREFIX}-components`,
  environmentLimitsSticky: `${PREFIX}-environmentLimitsSticky`,
  divider: `${PREFIX}-divider`
};

const StyledStack = styled(Stack, {
  shouldForwardProp: prop => prop !== 'showEstimatedCosts'
})(({ theme, showEstimatedCosts }) => ({
  width: '100%',

  [`& .${classes.environmentLimits}`]: {
    minWidth: 300,
    [`& .${classes.environmentLimitsSticky}`]: {
      padding: !showEstimatedCosts
        ? `${theme.spacing(2)} ${theme.spacing(2.5)}`
        : undefined,
      [theme.breakpoints.down('md')]: {
        boxShadow: 'none'
      },
      [theme.breakpoints.up('md')]: {
        position: 'sticky',
        top: 72,
        maxWidth: !showEstimatedCosts ? 256 : undefined,
        height: 'max-content',
        backgroundColor: theme.palette.background.default
      }
    }
  },

  [`& .${classes.components}`]: {
    minWidth: '100%',
    [theme.breakpoints.up('md')]: {
      minWidth: 360,
      width: '100%'
    }
  },

  [`& .${classes.divider}`]: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  }
}));

EnvironmentResourceAndComponentInputs.defaultProps = {
  showEstimatedCosts: true
};

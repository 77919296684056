import { getIsInheritedEnvVar } from '@rc/admin/hooks';
import { useCallback, useEffect } from 'react';
import { useTranslate } from 'react-admin';
import { useFormContext } from 'react-hook-form';

/**
 *
 * @param {object} props
 * @param {string} props.source
 * @param {object[]} props.envVars
 * @param {object[]} props.inheritedEnvVars
 */
export const useValidateEnvVar = props => {
  const { source, envVars, inheritedEnvVars } = props;
  const { watch, setError, clearErrors } = useFormContext();
  const translate = useTranslate();

  const validateFn = useCallback(
    values => {
      const missingRequiredEnvVars = envVars?.filter(envVar => {
        const { isRequired, key } = envVar;
        const isInherited = getIsInheritedEnvVar(key, inheritedEnvVars);
        return (
          isRequired && !isInherited && !values.some(value => value.key === key)
        );
      });

      if (missingRequiredEnvVars && missingRequiredEnvVars.length) {
        return {
          type: 'validate',
          message: translate('resources.env_vars.validation.missing_required', {
            smart_count: missingRequiredEnvVars.length,
            list: missingRequiredEnvVars.map(value => value.key).join(', ')
          })
        };
      }
    },
    [envVars, inheritedEnvVars, translate]
  );

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      const shouldValidate =
        name === source || new RegExp(`${source}.[0-9]*.`).test(name);

      if (shouldValidate) {
        const error = validateFn(value[source]);

        if (error) {
          setError(source, error);
        } else {
          clearErrors(source);
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [clearErrors, setError, source, validateFn, watch]);
};

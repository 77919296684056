import React, { useMemo, useCallback } from 'react';
import {
  Edit,
  EditClasses,
  useRedirect,
  useCreatePath,
  useResourceContext
} from 'react-admin';
import { Box, cardClasses, styled } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import {
  Breadcrumbs,
  Iconify,
  RCWorkerMercureSubscriber
} from '@rc/admin/components';
import { RCWorkerMessagesContextProvider } from '@rc/admin/context';
import { useIdParam, useIsTemplateResource } from '@rc/admin/hooks';
import { STATE_IDS } from '@rc/admin/constants';
import { createIRI } from '@rc/admin/utils';
import { EnvironmentMenu } from './Menu';
import { EnvironmentTitle } from './Title';
import { Navigate, Route, Routes } from 'react-router-dom';
import { EnvironmentImport } from './Import';
import { EnvironmentExport } from './Export';
import { EnvironmentDetails } from './Details';
import { Logs as EnvironmentLogs } from './Logs';
import { EnvironmentEditForm } from './Form';
import { environmentEditTransform } from '../utils';

const getTabs = editPath => [
  {
    value: `${editPath}/details`,
    to: `${editPath}/details`,
    label: 'resources.environments.tabs.details',
    icon: <Iconify icon='solar:diagram-up-bold' />
  },
  {
    value: `${editPath}/import`,
    to: `${editPath}/import`,
    label: 'action.import',
    icon: <Iconify icon='solar:export-linear' />
  },
  {
    value: `${editPath}/export`,
    to: `${editPath}/export`,
    label: 'action.export',
    icon: <Iconify icon='solar:import-linear' />
  },
  {
    value: `${editPath}/logs`,
    to: `${editPath}/logs`,
    label: 'resources.environments.tabs.logs',
    icon: <Iconify icon='solar:document-text-bold' />
  },
  {
    value: `${editPath}/edit/*`,
    to: `${editPath}/edit`,
    label: 'ra.action.edit',
    icon: <EditIcon />
  }
];

export const EnvironmentEdit = () => {
  const isTemplate = useIsTemplateResource();
  const createPath = useCreatePath();
  const resource = useResourceContext();
  const { id } = useIdParam();
  const editPath = createPath({ resource, id, type: 'edit' });
  const tabs = useMemo(() => getTabs(editPath), [editPath]);

  const breadcrumbsParents = useMemo(
    () => [
      {
        reference: isTemplate ? 'template_projects' : 'projects',
        source: isTemplate ? 'templateProject' : 'project'
      }
    ],
    [isTemplate]
  );

  return (
    <RCWorkerMessagesContextProvider>
      <StyledEdit
        title={<EnvironmentTitle />}
        redirect={false}
        actions={<Breadcrumbs parents={breadcrumbsParents} />}
        aside={<EnvironmentMenu tabs={tabs} />}
        mutationMode={'pessimistic'}
        component={CustomMercureSubscriber}
        transform={environmentEditTransform}
      >
        {!isTemplate ? (
          <Routes>
            <Route path='/import' element={<EnvironmentImport />} />
            <Route path='/export' element={<EnvironmentExport />} />
            <Route path='/details' element={<EnvironmentDetails />} />
            <Route path='/logs' element={<EnvironmentLogs />} />
            <Route path='/edit/*' element={<EnvironmentEditForm />} />
            <Route
              path='/*'
              element={
                <Navigate
                  replace
                  to={`${createPath({ resource, id, type: 'edit' })}/details`}
                />
              }
            />
          </Routes>
        ) : (
          <EnvironmentEditForm />
        )}
      </StyledEdit>
    </RCWorkerMessagesContextProvider>
  );
};

const CustomMercureSubscriber = props => {
  const redirect = useRedirect();

  const onUpdateReceived = useCallback(
    data => {
      if (data?.state === createIRI('states', STATE_IDS.DELETED)) {
        redirect('/environments');
      }
    },
    [redirect]
  );

  return (
    <RCWorkerMercureSubscriber
      onReceived={onUpdateReceived}
      component={Box}
      {...props}
    />
  );
};

// const PREFIX = 'EnvironmentEdit';

// const classes = {};

const StyledEdit = styled(Edit)(({ theme }) => ({
  maxWidth: 'calc(100vw - 48px)',

  [`& .${EditClasses.main}`]: {
    display: 'flex',
    flexDirection: 'column-reverse',
    rowGap: theme.spacing(2),

    [`& .${EditClasses.card}`]: {
      width: '100%',

      [`& > .${cardClasses.root}`]: {
        overflow: 'visible',
        position: 'relative'
      }
    }
  }
}));

import React from 'react';
import {
  BooleanInput,
  ReferenceInput,
  SelectInput,
  useTranslate
} from 'react-admin';
import _ from 'lodash';
import { useWatch } from 'react-hook-form';
import { Box } from '@mui/material';
import {
  DTEnvironmentForm,
  DTHttp,
  DTAwsExternal,
  DTAwsFile
} from '../form/DataTransferForms';
import { TwoColumnFormStyles } from '@rc/admin/theme/styles';

export const DataImportInputs = () => {
  const t = useTranslate();
  const dataTransfer = useWatch({ name: 'dataTransfer' });

  let input;
  switch (dataTransfer?.type) {
    case '/api/data_transfer_types/2':
      input = <DTAwsExternal />;
      break;
    case '/api/data_transfer_types/3':
      input = <DTHttp />;
      break;
    case '/api/data_transfer_types/4':
      input = <DTEnvironmentForm />;
      break;
    case '/api/data_transfer_types/1':
    default:
      input = <DTAwsFile />;
  }

  return (
    <Box sx={TwoColumnFormStyles}>
      <SelectInput
        source='dataTransfer.uploadProcess'
        label='resources.imported_datas.fields.dataTransfer.uploadProcess'
        choices={[
          {
            id: 'import-database',
            name: t('resources.imported_datas.import_database')
          },
          {
            id: 'import-media',
            name: t('resources.imported_datas.import_media')
          }
        ]}
        optionText='name'
        optionValue='id'
        isRequired
        fullWidth
      />
      {!_.isEmpty(dataTransfer?.uploadProcess) && (
        <ReferenceInput
          source='dataTransfer.type'
          reference='data_transfer_types'
          label='resources.imported_datas.fields.dataTransfer.type'
          allowNull={true}
        >
          <SelectInput optionText='name' isRequired fullWidth />
        </ReferenceInput>
      )}
      {!_.isEmpty(dataTransfer?.type) && (
        <>
          <Box>
            <BooleanInput
              source='dataTransfer.cleanup'
              label='resources.imported_datas.fields.dataTransfer.cleanup'
              helperText={false}
            />
          </Box>
          {'import-database' === dataTransfer?.uploadProcess && (
            <Box mb={2}>
              <BooleanInput
                source='dataTransfer.fixUrls'
                label='resources.imported_datas.fields.dataTransfer.fixUrls'
                helperText={false}
              />
            </Box>
          )}
          {input}
        </>
      )}
    </Box>
  );
};

export default DataImportInputs;

import { darken, lighten } from '@mui/material';

/**
 * @typedef {(theme: import("@mui/material").Theme) => any} CustomStyle
 */

/**
 * @type {CustomStyle}
 */
export const OneColumnFormStyles = theme => ({
  [theme.breakpoints.up('md')]: {
    maxWidth: '20em'
  }
});

/**
 * @type {CustomStyle}
 */
export const TwoColumnFormStyles = theme => ({
  maxWidth: '40em',
  width: '100%'
});

export const NoWrapTextStyles = () => ({
  whiteSpace: 'nowrap',
  maxWidth: '100%',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
});

/**
 *
 * @param {import('@mui/material').Theme} theme
 * @returns
 */
export const getColorfulBackgroundStyles = theme => {
  const isLight = theme.palette.mode === 'light';
  const modifierFn = isLight ? lighten : darken;
  const color = isLight
    ? darken(theme.palette.background.default, 0.5)
    : lighten(theme.palette.background.default, 0.15);

  return {
    background: modifierFn(color, 0.5),
    backgroundImage: `linear-gradient(135deg, ${modifierFn(
      color,
      0.8
    )} 0%, ${modifierFn(
      theme.palette.primary.main,
      isLight ? 0.9 : 0.85
    )} 50%, ${modifierFn(
      theme.palette.primary.main,
      isLight ? 0.75 : 0.5
    )} 100%)`
  };
};

import { styled } from '@mui/material';
import { Breadcrumbs } from '@rc/admin/components';
import { OneColumnFormStyles } from '@rc/admin/theme/styles';
import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  required,
  useRedirect,
  useRefresh,
  useResourceContext,
  useUnique
} from 'react-admin';

export const OrganisationCreate = () => {
  const refresh = useRefresh();
  const redirect = useRedirect();
  const resource = useResourceContext();
  const unique = useUnique();

  return (
    <StyledCreate
      mutationOptions={{
        onSuccess: data => {
          redirect('edit', resource, data.id, data);
          refresh();
        }
      }}
      actions={<Breadcrumbs showCurrent={false} disableLast={false} />}
    >
      <SimpleForm
        defaultValues={{ isEnabled: true }}
        className={classes.form}
        mode='all'
      >
        <TextInput
          source={'name'}
          validate={[unique(), required()]}
          fullWidth
        />
      </SimpleForm>
    </StyledCreate>
  );
};

const PREFIX = 'OrganisationCreate';

const classes = {
  form: `${PREFIX}-form`
};

const StyledCreate = styled(Create)(({ theme }) => ({
  [`& .${classes.form}`]: {
    ...OneColumnFormStyles(theme)
  }
}));

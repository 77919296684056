import React from 'react';
import { Card, CardContent, Grid, Stack } from '@mui/material';
import {
  RelatedEnvironmentClasses,
  RelatedEnvironments
} from './RelatedEnvironments';
import { ProjectAccessFields, ProjectCosts } from '../components';
import {
  Labeled,
  useSidebarState,
  useTranslate,
  TextField,
  ReferenceField
} from 'react-admin';
import {
  CardListClasses,
  ProjectTypeField,
  TimeAgoField
} from '@rc/admin/components';

export const ProjectDetails = () => {
  const t = useTranslate();
  const [isSidebarOpen] = useSidebarState();

  return (
    <Stack sx={theme => ({ marginBottom: theme.spacing(2) })}>
      <RelatedEnvironments
        resource={'environments'}
        sx={theme => ({
          [`&:not(.${RelatedEnvironmentClasses.empty}), & .${CardListClasses.root}`]: {
            marginBottom: theme.spacing(0),
            marginTop: theme.spacing(-1)
          }
        })}
      />

      {/* <Typography variant='h5' mb={2}>
        {t('resources.projects.sections.general.title')}
      </Typography> */}
      <Grid container spacing={2} mb={2}>
        <Grid
          item
          xs={12}
          sm={isSidebarOpen ? 12 : 6}
          md={isSidebarOpen ? 6 : 6}
          lg={6}
        >
          <Card sx={{ height: '100%' }}>
            <CardContent>
              <Stack spacing={1}>
                <Labeled>
                  <TextField source={'name'} fullWidth />
                </Labeled>
                <Labeled>
                  <ReferenceField
                    label={'resources.projects.fields.projectType'}
                    source={'projectTypeVersion'}
                    reference={'project_type_versions'}
                    link={false}
                  >
                    <ProjectTypeField source='projectTypeVersion' fullWidth />
                  </ReferenceField>
                </Labeled>
                <Labeled>
                  <TimeAgoField
                    source='updatedAt'
                    variant='caption'
                    sx={{
                      color: 'text.disabled'
                    }}
                  />
                </Labeled>
              </Stack>
            </CardContent>
          </Card>
        </Grid>
        <Grid
          item
          xs={12}
          sm={isSidebarOpen ? 12 : 6}
          md={isSidebarOpen ? 6 : 6}
          lg={6}
        >
          <Card sx={{ height: '100%' }}>
            <CardContent>
              <ProjectAccessFields
                id='project-access-fields'
                title={t('resources.projects.sections.access.title')}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* <Typography variant='h5' mb={2}>
        {t('resources.projects.sections.usage_and_costs.title')}
      </Typography> */}
      <ProjectCosts />
    </Stack>
  );
};

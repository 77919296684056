import PropTypes from 'prop-types';

import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';

import { bgBlur } from '../../theme/css';

import Iconify from '../../components/iconify';

import { NAV } from '../config-layout';
import { useMediaQuery } from '@mui/material';
import { useSidebarState } from 'react-admin';

// ----------------------------------------------------------------------

export default function NavToggleButton ({ sx, isVertical, ...other }) {
  const theme = useTheme();
  const [isSidebarOpen, setIsSidebarOpen] = useSidebarState();

  const lgUp = useMediaQuery(theme => theme.breakpoints.up('lg'));

  if (!lgUp) {
    return null;
  }

  return (
    <IconButton
      size='small'
      onClick={() => setIsSidebarOpen(curr => !curr)}
      sx={{
        p: 0.5,
        top: 32,
        position: 'fixed',
        left: NAV.W_VERTICAL - 12,
        zIndex: theme.zIndex.appBar + 1,
        border: `dashed 1px ${theme.palette.divider}`,
        ...bgBlur({ opacity: 0.48, color: theme.palette.background.default }),
        '&:hover': {
          bgcolor: 'background.default'
        },
        ...sx
      }}
      {...other}
    >
      <Iconify
        width={16}
        icon={
          isSidebarOpen
            ? 'eva:arrow-ios-back-fill'
            : 'eva:arrow-ios-forward-fill'
        }
      />
    </IconButton>
  );
}

NavToggleButton.propTypes = {
  sx: PropTypes.object
};

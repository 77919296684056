import React from 'react';
import {
  ReferenceInput,
  required,
  SelectInput,
  useGetOne,
  useRecordContext,
  useTranslate
} from 'react-admin';
import { Box, Typography } from '@mui/material';
import { STATE_IDS } from '@rc/admin/constants';

export const DTEnvironmentForm = props => {
  const {
    source = 'dataTransfer.sourceEnvironment',
    label = 'Source environment',
    record: recordProp
  } = props;
  const record = useRecordContext({ record: recordProp });
  const translate = useTranslate();
  const { data: project, isLoading: isProjectLoading } = useGetOne(
    'projects',
    { id: record?.project },
    { enabled: !!record?.project }
  );

  const otherEnvironmentIdsFromProject = project?.environment?.filter(
    id => id !== record?.id
  );

  return (
    <Box width={'100%'}>
      <ReferenceInput
        source={source}
        reference={'environments'}
        validate={[required()]}
        filter={{
          id: otherEnvironmentIdsFromProject,
          'state[not]': STATE_IDS.DELETED
        }}
      >
        <SelectInput
          label={label}
          optionText={'name'}
          isLoading={!project || isProjectLoading}
          disabled={
            !project ||
            isProjectLoading ||
            !otherEnvironmentIdsFromProject?.length
          }
          placeholder={'Select an environment...'}
          helperText={
            !isProjectLoading && !otherEnvironmentIdsFromProject?.length ? (
              <Typography component={'span'} color={'error'} variant={'body2'}>
                {translate(
                  'resources.environments.message.no_environments_to_copy_from'
                )}
              </Typography>
            ) : undefined
          }
          isRequired
          fullWidth
        />
      </ReferenceInput>
    </Box>
  );
};

export default DTEnvironmentForm;

import React from 'react';
import { Box, Stack, Tab, Tabs, styled, tabClasses } from '@mui/material';
import { useRecordContext, useTranslate } from 'react-admin';
import { NavLink } from 'react-router-dom';
import { useIsTemplateResource, useRouteMatch } from '@rc/admin/hooks';

/**
 *
 * @param {object} props
 * @param {Array} props.tabs
 * @returns
 */
export const ProjectMenu = props => {
  const { tabs } = props;
  const t = useTranslate();
  const isTemplate = useIsTemplateResource();
  const record = useRecordContext();
  const routeMatch = useRouteMatch(tabs.map(tab => tab.value));
  const currentTab = routeMatch?.pattern?.path;

  if (!record || isTemplate) {
    return null;
  }

  return (
    <StyledStack>
      <Tabs
        value={currentTab}
        sx={theme => ({
          [`& .${tabClasses.root}:not(:last-of-type)`]: {
            marginRight: theme.spacing(2.5)
          }
        })}
      >
        {tabs.map(tab => (
          <Tab
            LinkComponent={NavLink}
            key={tab.value}
            label={t(tab.label)}
            icon={tab.icon}
            to={tab.to}
            value={tab.value}
          />
        ))}
      </Tabs>
    </StyledStack>
  );
};

const PREFIX = 'ProjectMenu';

const classes = {
  nav: `${PREFIX}-nav`
};

const StyledStack = styled(Stack)(({ theme }) => ({
  width: '100%',
  flexDirection: 'column',
  justifyContent: 'space-between',
  columnGap: theme.spacing(2),
  rowGap: theme.spacing(3),
  marginBottom: theme.spacing(0),
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    marginBottom: theme.spacing(1.5)
  },

  [`& .${classes.nav}`]: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    columnGap: theme.spacing(1.5),
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-end'
    }
  }
}));

import React from 'react';
import { Box, Stack, styled, Typography } from '@mui/material';

/**
 *
 * @param {object} props
 * @param {import('@mui/material').CardProps} props.CardProps
 * @param {string?} props.title
 * @param {import('react').ReactNode?} props.actions
 * @returns
 */
export const DashboardBox = props => {
  const { children, CardProps, title, actions } = props;
  return (
    <Root
      className={classes.root}
      variant='elevation'
      elevation={0}
      {...CardProps}
    >
      {!!(title || actions) && (
        <Stack className={classes.header}>
          <Typography variant={'subtitle1'}>{title}</Typography>
          <Stack className={classes.headerActions}>{actions}</Stack>
        </Stack>
      )}
      {children}
    </Root>
  );
};

const PREFIX = 'DashboardBox';

const classes = {
  root: `${PREFIX}-root`,
  header: `${PREFIX}-header`,
  headerActions: `${PREFIX}-headerActions`
};

const Root = styled(Box)(({ theme }) => ({
  border: 'none',
  background: 'none',

  [theme.breakpoints.up('m')]: {
    padding: `${theme.spacing(1)} ${theme.spacing(1.5)}`
  },

  [`& .${classes.header}`]: {
    // backgroundColor:
    //   theme.palette.mode === 'light'
    //     ? theme.palette.grey[100]
    //     : theme.palette.grey[900],
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    // padding: `0 ${theme.spacing(1)}`,

    [`& .${classes.headerActions}`]: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around'
    }
  }
}));

DashboardBox.defaultProps = {
  CardProps: {}
};

import React from 'react';
import { useGetOne } from 'react-admin';
import { Tooltip } from '@mui/material';
import { Iconify } from '@rc/admin/components/ui';
import { useIsDebugMode } from '@rc/admin/context';

/**
 * @param {object} props
 * @param {string?} props.id
 * @param {import('react-admin').RaRecord?} props.record
 * @param {import('@mui/material').TooltipProps?} props.TooltipProps
 */
export const StateByInfraTooltip = props => {
  const { id, record, TooltipProps } = props;
  const isDebugMode = useIsDebugMode();

  const { data } = useGetOne(
    'state_by_infras',
    { id },
    { enabled: !!id && !record }
  );

  const stateByInfra = record || data;

  if (!stateByInfra) {
    return null;
  }

  const text =
    isDebugMode && stateByInfra.messageInfo
      ? stateByInfra.message + ' ' + stateByInfra.messageInfo
      : stateByInfra.message;

  return (
    <Tooltip title={text} placement='bottom' {...TooltipProps}>
      <Iconify
        icon='solar:info-circle-outline'
        color='primary'
        fontSize='small'
        sx={{ opacity: 0.75, ml: 1 }}
      />
    </Tooltip>
  );
};

StateByInfraTooltip.defaultProps = {
  TooltipProps: {}
};

import React, { useMemo } from 'react';
import { useLocation } from 'react-router';
import {
  Create,
  SimpleForm,
  SelectInput,
  ReferenceInput,
  NumberInput,
  required,
  useTranslate
} from 'react-admin';
import { GroupedSelectInput } from '@rc/admin/components';
import {
  resourceLimitMaxValueValidator,
  resourceLimitMinValueValidator
} from './utils';
import { TwoColumnFormStyles } from '@rc/admin/theme/styles';
import { Grid, styled } from '@mui/material';

export const ComponentVersionResourceLimitCreate = () => {
  const t = useTranslate();
  const { search } = useLocation();

  const initialComponentVersionId = useMemo(
    () => new URLSearchParams(search).get('componentVersion'),
    [search]
  );

  return (
    <StyledCreate>
      <SimpleForm
        defaultValues={{
          isEnabled: true,
          componentVersion: initialComponentVersionId
        }}
        className={classes.form}
      >
        <Grid container wrap='wrap' spacing={1}>
          <Grid item xs={12} sm={6}>
            <ReferenceInput
              source={'componentVersion'}
              reference={'component_versions'}
            >
              <GroupedSelectInput
                groupReference={'components'}
                id={'component-version'}
                label={t('resources.components.name', { smart_count: 1 })}
                optionText={choice => choice?.version || choice?.name}
                validate={[required()]}
                fullWidth
              />
            </ReferenceInput>
          </Grid>
          <Grid item xs={12} sm={6}>
            <ReferenceInput
              source={'projectTypeVersion'}
              reference={'project_type_versions'}
            >
              <GroupedSelectInput
                groupReference={'project_types'}
                id={'project-type-version'}
                optionText={choice => choice?.version || choice?.name}
                label={t('resources.project_types.name', {
                  smart_count: 1
                })}
                validate={[required()]}
                fullWidth
              />
            </ReferenceInput>
          </Grid>
        </Grid>
        <ReferenceInput source={'resourceType'} reference={'resource_types'}>
          <SelectInput
            optionText={'name'}
            validate={[required()]}
            fullWidth
            isRequired
          />
        </ReferenceInput>

        <Grid container wrap='wrap' spacing={1}>
          <Grid item xs={12} sm={6}>
            <NumberInput
              source={'minValue'}
              validate={[resourceLimitMinValueValidator, required()]}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <NumberInput
              source={'maxValue'}
              validate={[resourceLimitMaxValueValidator, required()]}
              fullWidth
            />
          </Grid>
        </Grid>
      </SimpleForm>
    </StyledCreate>
  );
};

const PREFIX = 'ComponentVersionResourceLimitCreate';

const classes = {
  form: `${PREFIX}-form`
};

const StyledCreate = styled(Create)(({ theme }) => ({
  [`& .${classes.form}`]: {
    ...TwoColumnFormStyles(theme)
  }
}));

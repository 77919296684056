import { useGetList } from 'react-admin';

export const useDefaultEnvVarType = () => {
  const { data: envVarTypes, isLoading } = useGetList('env_var_types');

  const defaultEnvVarType =
    envVarTypes?.find(envVarType => envVarType.isDefault) ||
    envVarTypes?.find(envVarType => envVarType.name === 'All');

  return {
    defaultEnvVarType,
    isLoading
  };
};

import { useGetList, useRecordContext } from 'react-admin';
import { useIsTemplateResource } from '../useIsTemplateResource';
import { RESOURCE_SOURCES } from '@rc/admin/constants';

/**
 * @typedef {{ isLoading: boolean, limit: number, used: number, free: number }}ResourceLimit
 */

/**
 *
 * @param {object} props
 * @param {string} props.source
 * @param {object} props.project
 * @returns {ResourceLimit}
 */
export const useEnvironmentResourceLimit = props => {
  const { source, project } = props;
  const record = useRecordContext(props);
  const isTemplate = useIsTemplateResource();

  const {
    data: environments = [],
    isLoading: isEnvironmentsLoading
  } = useGetList(
    isTemplate ? 'template_environments' : 'environments',
    {
      filter: isTemplate
        ? { templateProject: project?.id }
        : { project: project?.id }
    },
    { enabled: !!project }
  );

  const limit = project ? project[source] : 0;
  const used = environments
    .filter(environment => !record || environment.id !== record.id)
    .reduce((acc, environment) => (acc += environment[source]), 0);

  return {
    isLoading: isEnvironmentsLoading,
    limit,
    used,
    free: limit - used
  };
};

/**
 *
 * @param {object} props
 * @param {string} props.project
 * @returns {{isLoading: boolean, cpu: ResourceLimit, memory: ResourceLimit, storage: ResourceLimit}}
 */
export const useEnvironmentResourceLimits = props => {
  const { project } = props;

  return RESOURCE_SOURCES.reduce(
    (result, source) => {
      const limit = useEnvironmentResourceLimit({ project, source });
      result[source] = limit;

      if (!result.isLoading && limit.isLoading) {
        result.isLoading = true;
      }

      return result;
    },
    { isLoading: false }
  );
};

import React from 'react';
import { LoadingField } from '@rc/admin/components';
import {
  RecordContextProvider,
  ReferenceField,
  TextField,
  useGetList,
  useRecordContext
} from 'react-admin';

export const InvitationOrganisationField = props => {
  const { source } = props;
  const record = useRecordContext();

  const { data: organisations, isLoading } = useGetList(
    'organisations',
    {
      filter: {
        team: record?.team
      }
    },
    {
      enabled: !!record?.team
    }
  );

  if (isLoading || !organisations) {
    return <LoadingField />;
  }

  const organisation = organisations?.[0];

  return (
    <RecordContextProvider value={{ organisation: organisation?.id }}>
      <ReferenceField source={source} reference='organisations'>
        <TextField source='name' />
      </ReferenceField>
    </RecordContextProvider>
  );
};

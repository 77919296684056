import React from 'react';
import { ResourceTypeList } from './List';
import { ResourceTypeEdit } from './Edit';
import Iconify from '@rc/admin/components/ui/Iconify';

export default {
  name: 'resource_types',
  list: ResourceTypeList,
  edit: ResourceTypeEdit,
  icon: () => <Iconify icon='solar:ssd-round-bold-duotone' />
};

import React, { useCallback, useMemo } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Stack, cardClasses, styled } from '@mui/material';
import {
  Breadcrumbs,
  Iconify,
  RCWorkerMercureSubscriber
} from '@rc/admin/components';
import { STATE_IDS } from '@rc/admin/constants';
import { RCWorkerMessagesContextProvider } from '@rc/admin/context';
import { useIdParam, useIsTemplateResource } from '@rc/admin/hooks';
import { createIRI } from '@rc/admin/utils';
import {
  Edit,
  EditClasses,
  useCreatePath,
  useRedirect,
  useResourceContext
} from 'react-admin';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ProjectDetails } from './Details';
import { ProjectEditForm } from './Form';
import { ProjectMenu } from './Menu';
import { RelatedEnvironments } from './RelatedEnvironments';
import { ProjectEditTitle } from './Title';

const getTabs = editPath => [
  {
    value: `${editPath}/details`,
    to: `${editPath}/details`,
    label: 'resources.projects.tabs.details',
    icon: <Iconify icon='solar:diagram-up-bold' />
  },
  {
    value: `${editPath}/edit/*`,
    to: `${editPath}/edit`,
    label: 'ra.action.edit',
    icon: <EditIcon />
  }
];

export const ProjectEdit = () => {
  const isTemplate = useIsTemplateResource();
  const createPath = useCreatePath();
  const resource = useResourceContext();
  const { id } = useIdParam();
  const editPath = createPath({ resource, id, type: 'edit' });
  const tabs = useMemo(() => getTabs(editPath), [editPath]);

  return (
    <RCWorkerMessagesContextProvider>
      <StyledEdit
        title={<ProjectEditTitle />}
        redirect={false}
        actions={
          isTemplate ? (
            <Stack sx={theme => ({ marginBottom: theme.spacing(2) })}>
              <Breadcrumbs />
              <RelatedEnvironments resource={'template_environments'} />
            </Stack>
          ) : (
            <Breadcrumbs />
          )
        }
        aside={<ProjectMenu tabs={tabs} />}
        mutationMode={'pessimistic'}
        component={CustomMercureSubscriber}
      >
        {!isTemplate ? (
          <Routes>
            <Route path='/details' element={<ProjectDetails />} />
            <Route path='/edit/*' element={<ProjectEditForm />} />
            <Route
              path='/*'
              element={
                <Navigate
                  replace
                  to={`${createPath({ resource, id, type: 'edit' })}/details`}
                />
              }
            />
          </Routes>
        ) : (
          <ProjectEditForm />
        )}
      </StyledEdit>
    </RCWorkerMessagesContextProvider>
  );
};

const CustomMercureSubscriber = props => {
  const redirect = useRedirect();

  const onUpdateReceived = useCallback(
    data => {
      if (data?.state === createIRI('states', STATE_IDS.DELETED)) {
        redirect('/projects');
      }
    },
    [redirect]
  );

  return (
    <RCWorkerMercureSubscriber onReceived={onUpdateReceived} component={Box}>
      {props.children}
    </RCWorkerMercureSubscriber>
  );
};

// const PREFIX = 'EnvironmentEdit';

// const classes = {};

const StyledEdit = styled(Edit)(({ theme }) => ({
  maxWidth: 'calc(100vw - 48px)',

  [`& .${EditClasses.main}`]: {
    display: 'flex',
    flexDirection: 'column-reverse',
    rowGap: theme.spacing(2),

    [`& .${EditClasses.card}`]: {
      width: '100%',

      [`& > .${cardClasses.root}`]: {
        overflow: 'visible',
        position: 'relative'
      }
    }
  }
}));

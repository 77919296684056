import { CircularProgress, Stack, styled } from '@mui/material';
import React from 'react';

export const FullPageCircularProgress = props => {
  return (
    <Root {...props}>
      <CircularProgress />
    </Root>
  );
};

const Root = styled(Stack)(() => ({
  width: '100%',
  minHeight: 300,
  direction: 'row',
  justifyContent: 'center',
  alignItems: 'center'
}));

import React, { useMemo } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  accordionClasses,
  AccordionDetails,
  AccordionSummary,
  Box,
  Stack,
  styled,
  Typography
} from '@mui/material';
import {
  Breadcrumbs,
  LoadingBox,
  SectionHelper,
  SectionTitle
} from '@rc/admin/components';
import { ProjectContextProvider } from '@rc/admin/context';
import { cloneRecord as cloneRaRecord } from '@rc/admin/utils';
import {
  BooleanInput,
  Create,
  NotFound,
  SimpleForm,
  useGetOne,
  useResourceContext,
  useTranslate
} from 'react-admin';
import { Navigate, useLocation } from 'react-router-dom';
import {
  AllowHtaccessDevtoolsProtectionField,
  CustomDomainFields,
  EnvironmentNameInputs,
  MagentoHostMappingsFields
} from '../components';
import { useEnvironmentCreate } from '../hooks';
import { environmentCreateTransform } from '../utils';
import { EnvironmentCreateStepResources } from './EnvironmentCreateStepResources';
import { EnvironmentCreateStepVariables } from './EnvironmentCreateStepVariables';
import { COPY_ENVIRONMENT_SOURCE, ENVIRONMENT_COPY_TYPES } from '../constants';

export const EnvironmentClone = () => {
  const { search } = useLocation();
  const resource = useResourceContext();
  const t = useTranslate();

  const sourceEnvironmentId = decodeURIComponent(
    new URLSearchParams(search).get('sourceId')
  );

  const {
    data: sourceEnvironment,
    isLoading: isSourceEnvironmentLoading,
    isError: isSourceEnvironmentError
  } = useGetOne(
    resource,
    { id: sourceEnvironmentId },
    { enabled: !!sourceEnvironmentId }
  );

  const defaultValuesFromSource = useMemo(() => {
    if (!sourceEnvironment) {
      return {};
    }

    return {
      ...cloneEnvironment(sourceEnvironment),
      initGitSourceBranch: sourceEnvironment.targetRefName ?? sourceEnvironment.name,
      [COPY_ENVIRONMENT_SOURCE]: {
        types: ENVIRONMENT_COPY_TYPES,
        sourceId: sourceEnvironment.id
      },
    };
  }, [sourceEnvironment]);

  const {
    isLoading,
    isError,
    defaultValues,
    projectId,
    isCreateLoading,
    isImportLoading,
    isTemplate,
    environmentComponentLastCopiedFrom,
    handleSubmit
  } = useEnvironmentCreate({
    projectId: sourceEnvironment?.project,
    defaultValues: defaultValuesFromSource
  });

  const breadcrumbsParents = useMemo(
    () => [
      {
        reference: isTemplate ? 'template_projects' : 'projects',
        source: isTemplate ? 'templateProject' : 'project'
      }
    ],
    [isTemplate]
  );

  if (!sourceEnvironmentId) {
    return <Navigate to={'/'} />;
  } else if (isSourceEnvironmentLoading || isLoading) {
    return <LoadingBox minHeight={200} />;
  } else if (isSourceEnvironmentError || isError) {
    return (
      <NotFound
        title={t('resources.environments.message.source_environment_not_found')}
      />
    );
  }

  return (
    <Create
      title={t('action.cloneWithParams', {
        name: sourceEnvironment.name
      })}
      actions={
        <Breadcrumbs
          data={{
            [isTemplate ? 'templateProject' : 'project']: projectId
          }}
          parents={breadcrumbsParents}
          label={<BreadcrumbsLabel />}
        />
      }
      transform={environmentCreateTransform}
    >
      <SimpleForm
        defaultValues={defaultValues}
        onSubmit={handleSubmit}
        disabled={isCreateLoading || isImportLoading}
        mode='all'
      >
        <ProjectContextProvider>
          <Stack>
            <Box>
              <SectionTitle mb={0.5}>
                {t('resources.environments.sections.name')}
              </SectionTitle>
              <EnvironmentNameInputs />
            </Box>
            <SectionTitle mb={2}>
              {t('resources.environments.sections.customizations')}
            </SectionTitle>
            <StyledAccordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls='detailsbh-content'
                id='detailsbh-header'
              >
                <Typography>
                  {t(
                    'resources.environments.sections.infrastructure_and_system'
                  )}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <SectionTitle level={2} mb={0.5}>
                  {t('resources.environments.sections.domains')}
                </SectionTitle>
                <SectionHelper mb={1}>
                  {t('resources.environments.helpers.domains')}
                </SectionHelper>
                <CustomDomainFields source='environmentCustomDomain' />
                <SectionTitle level={2} mb={0.5}>
                  {t('resources.environments.sections.magentoSettings')}
                </SectionTitle>
                <MagentoHostMappingsFields source='environmentMagentoHostMapping' />
                <SectionTitle level={2} mb={1}>
                  {t('resources.environments.sections.database_and_media')}
                </SectionTitle>
                <Box id='init-sample-data-field' mb={1}>
                  <BooleanInput
                    source={'isInitSampleData'}
                    label={t('resources.environments.fields.isInitSampleData')}
                    helperText={false}
                  />
                </Box>
                <SectionTitle level={2} mb={0.5}>
                  {t('resources.environments.sections.advanced')}
                </SectionTitle>
                <Box id='is-allow-outgoing-emails-field' mt={0.5} mb={2}>
                  <SectionHelper mb={1}>
                    {t(
                      'resources.environments.helpers.isAllowHtaccessDevtoolsProtection'
                    )}
                  </SectionHelper>
                  <AllowHtaccessDevtoolsProtectionField />
                </Box>
                <Box id='is-allow-outgoing-emails-field'>
                  <SectionHelper mb={0.5}>
                    {t('resources.environments.helpers.isAllowOutgoingEmails')}
                  </SectionHelper>
                  <BooleanInput
                    source={'isAllowOutgoingEmails'}
                    label={t(
                      'resources.environments.fields.isAllowOutgoingEmails'
                    )}
                    helperText={false}
                  />
                </Box>
              </AccordionDetails>
            </StyledAccordion>

            <StyledAccordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls='resourcesbh-content'
                id='resourcesbh-header'
              >
                <Typography>
                  {t('resources.projects.sections.resources')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <EnvironmentCreateStepResources
                  lastCopiedFromRef={environmentComponentLastCopiedFrom}
                  showTitle={false}
                />
              </AccordionDetails>
            </StyledAccordion>
            <StyledAccordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls='vairablesbh-content'
                id='vairablesbh-header'
              >
                <Typography>
                  {t('resources.environments.sections.envVars.title')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <EnvironmentCreateStepVariables showTitle={false} />
              </AccordionDetails>
            </StyledAccordion>
          </Stack>
        </ProjectContextProvider>
      </SimpleForm>
    </Create>
  );
};

const BreadcrumbsLabel = props => {
  const { data } = props;
  const t = useTranslate();

  return data['@type'] === 'Project' || data['@type'] === 'TemplateProject'
    ? data.name
    : t('action.clone');
};

const cloneEnvironment = sourceEnvironment => {
  const {
    project,
    provider,
    cluster,
    cpu,
    memory,
    storage,
    envVar,
    isAllowHtaccessDevtoolsProtection,
    isAllowOutgoingEmails,
    isInitSampleData
  } = sourceEnvironment;

  return {
    name: '',
    targetRefName: '',
    project,
    provider,
    cluster,
    cpu,
    memory,
    storage,
    envVar: envVar.map(cloneRaRecord),
    isAllowHtaccessDevtoolsProtection,
    isAllowOutgoingEmails,
    isInitSampleData
  };
};

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  width: '100%',
  borderWidth: '1px 0 0',
  borderRadius: 0,

  [`&.${accordionClasses.expanded}`]: {
    boxShadow: 'none',
    margin: 0
  }
}));

import React, { useState, useCallback } from 'react';
import { Stack, styled } from '@mui/material';
import {
  ListContextProvider,
  useChoicesContext,
  useGetList
} from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { getComponentDefaultVersion } from '../../hooks';
import { ProjectTypeCardList } from './ProjectTypeCardList';
import { getProjectTypeForProjectTypeVersion } from '@rc/admin/utils';
import { DEFAULT_SORT, PREFETCH_PAGINATION } from '@rc/admin/constants';

const source = 'projectTypeVersion';

/**
 *
 * @typedef {{
 * SelectInputProps: import('react-admin').SelectInputProps
 * }} ProjectTypeSelectorProps
 *
 * @param {ProjectTypeSelectorProps} props
 * @returns
 */
export const ProjectTypeSelector = props => {
  const { SelectInputProps } = props;
  const { allChoices: projectTypeVersions } = useChoicesContext();
  const { data: projectTypes, isLoading, isFetching, total } = useGetList(
    'project_types',
    { sort: DEFAULT_SORT, pagination: PREFETCH_PAGINATION }
  );
  const {
    data: components,
    isLoading: isComponentsLoading,
    isFetching: isCompoentsFetching
  } = useGetList('components', {
    sort: DEFAULT_SORT,
    pagination: PREFETCH_PAGINATION
  });

  const { getValues, setValue } = useFormContext();

  const [selectedProjectType, setSelectedProjectType] = useState(() =>
    getProjectTypeForProjectTypeVersion(
      projectTypes,
      projectTypeVersions,
      getValues()[source]
    )
  );

  const onProjectTypeChanged = useCallback(
    record => {
      setSelectedProjectType(record);

      // Set the available version options
      const { projectTypeVersion } = getValues();
      const versionChoices = projectTypeVersions?.filter(
        choice => choice.projectType === record?.id
      );

      const selectedProjectTypeVersion =
        versionChoices?.find(choice => choice.id === projectTypeVersion) ||
        // or set the first available
        versionChoices?.[versionChoices.length - 1];

      setValue(source, selectedProjectTypeVersion?.id || null, {
        shouldDirty: true,
        shouldTouch: true,
        shouldValidate: true
      });

      if (selectedProjectTypeVersion && components) {
        const defaultComponentVersions = components
          .filter(component =>
            selectedProjectTypeVersion.requiredComponent.includes(component.id)
          )
          .map(
            getComponentDefaultVersion(
              selectedProjectTypeVersion.componentVersions
            )
          );

        setValue('componentVersion', defaultComponentVersions, {
          shouldDirty: true,
          shouldTouch: true,
          shouldValidate: true
        });
      }
    },
    [components, getValues, projectTypeVersions, setValue]
  );

  return (
    <StyledStack sx={{ width: '100%' }}>
      <ListContextProvider
        value={{
          data: projectTypes,
          isLoading: isLoading || isComponentsLoading,
          isFetching: isFetching || isCompoentsFetching,
          total: total,
          resource: 'project_types',
          sort: DEFAULT_SORT,
          perPage: PREFETCH_PAGINATION.perPage,
          page: PREFETCH_PAGINATION.page
        }}
      >
        <ProjectTypeCardList
          className={classes.cardList}
          selected={selectedProjectType}
          onSelectedChanged={onProjectTypeChanged}
          SelectInputProps={SelectInputProps}
        />
      </ListContextProvider>
    </StyledStack>
  );
};

const PREFIX = 'ProjectTypeSelector';

const classes = {
  cardList: `${PREFIX}-cardList`
};

const StyledStack = styled(Stack)(({ theme }) => ({
  [`.${classes.cardList}`]: {
    marginTop: 0
  }
}));

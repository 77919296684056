import React, { useCallback, useEffect, useState } from 'react';
import {
  Datagrid,
  FunctionField,
  Pagination,
  ReferenceField,
  ResourceContextProvider,
  SimpleList,
  TextField,
  useGetList,
  useRecordContext,
  useTranslate
} from 'react-admin';
import { Button, Stack, Typography, useMediaQuery } from '@mui/material';
import { useFormContext, useWatch } from 'react-hook-form';
import { REMOVE_USERS_FORM_KEY } from '../utils';
import { useIsRole, useUserTeamRoleGroup } from '@rc/admin/hooks';
import { ROLES, ROLE_GROUPS } from '@rc/admin/constants';
import { EditRoleGroupDialog } from './EditRoleGroupDialog';

const ROWS_PER_PAGE_OPTIONS = [5, 10, 25];

/**
 *
 * @param {object} props
 * @param {object} props.getListRef - ref to the getList object
 * @returns
 */
export const TeamMembers = props => {
  const { getListRef } = props;
  const t = useTranslate();
  const record = useRecordContext();
  const { setValue, getValues } = useFormContext();
  const [usersToRemove] = useWatch({ name: [REMOVE_USERS_FORM_KEY] });
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const { result: isAdmin } = useIsRole(ROLES.ADMIN);
  const { roleGroup: userRoleGroup } = useUserTeamRoleGroup();
  const isAdminOrTeamAdmin =
    isAdmin || userRoleGroup === ROLE_GROUPS.TEAM_ADMIN;

  const sort = { field: 'createdAt', order: 'DESC' };
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(ROWS_PER_PAGE_OPTIONS[0]);

  const { data, isLoading, total, refetch } = useGetList(
    'users',
    {
      pagination: { page, perPage },
      filter: { team: record?.['@id'] },
      sort
    },
    {
      enabled: !!record
    }
  );

  useEffect(() => {
    if (getListRef) {
      getListRef.current = { refetch };
    }
  }, [getListRef, refetch]);

  const handleRemoveUser = useCallback(
    user => {
      const newUsersToRemove = (getValues(REMOVE_USERS_FORM_KEY) || [])
        .filter(userId => userId !== user['@id'])
        .concat([user['@id']]);
      setValue(REMOVE_USERS_FORM_KEY, newUsersToRemove, {
        shouldDirty: true,
        shouldTouch: true,
        shouldValidate: true
      });
    },
    [getValues, setValue]
  );

  const handleUndoRemoveUser = useCallback(
    user => {
      const newUsersToRemove = (getValues(REMOVE_USERS_FORM_KEY) || []).filter(
        userId => userId !== user['@id']
      );
      setValue(REMOVE_USERS_FORM_KEY, newUsersToRemove, {
        shouldDirty: true,
        shouldTouch: true,
        shouldValidate: true
      });
    },
    [getValues, setValue]
  );

  const roleGroupField = (
    <FunctionField
      source='roleGroup'
      render={user => {
        const teamUser = record.teamUsers.find(
          teamUser => teamUser.user === user['@id']
        );

        if (user.roles.includes(ROLES.ADMIN)) {
          return (
            <Typography sx={{ textTransform: 'uppercase' }}>
              {t('misc.admin')}
            </Typography>
          );
        } else if (!teamUser) {
          return null;
        }

        return (
          <ReferenceField
            record={{ roleGroup: teamUser.roleGroup }}
            source='roleGroup'
            reference='role_groups'
            link={false}
          >
            <TextField source='name' />
          </ReferenceField>
        );
      }}
    />
  );

  const actions = (
    <Stack
      direction={'row'}
      justifyContent={isSmall ? 'flex-start' : 'flex-end'}
      spacing={1}
    >
      {isAdminOrTeamAdmin && (
        <FunctionField
          render={user => {
            if (user['@id'] === record.defaultUser) {
              return null;
            }

            const isUserToRemove = usersToRemove?.some(
              userId => userId === user['@id']
            );

            return (
              <Stack
                flexDirection={'row'}
                gap={1}
                justifyContent={'flex-end'}
                alignItems={'center'}
              >
                <Button
                  color={!isUserToRemove ? 'error' : 'primary'}
                  variant='outlined'
                  size='small'
                  disabled={isLoading}
                  onClick={() =>
                    !isUserToRemove
                      ? handleRemoveUser(user)
                      : handleUndoRemoveUser(user)
                  }
                >
                  {!isUserToRemove
                    ? t('ra.action.remove')
                    : t('action.undo_action', {
                        action: t('ra.action.remove')
                      })}
                </Button>
              </Stack>
            );
          }}
        />
      )}
      {!!isAdminOrTeamAdmin && (
        <FunctionField
          render={user => {
            if (user['@id'] === record.defaultUser) {
              return null;
            }

            return (
              <EditRoleGroupDialog
                size='small'
                variant='outlined'
                color='primary'
                team={record}
                user={user}
                teamUser={record.teamUsers.find(
                  teamUser => teamUser.user === user['@id']
                )}
              />
            );
          }}
        />
      )}
    </Stack>
  );

  return (
    <ResourceContextProvider value='users'>
      {isSmall ? (
        <SimpleList
          empty={null}
          isLoading={isLoading}
          data={data || []}
          sort={sort}
          total={total}
          sx={{ width: '100%' }}
          primaryText={
            <Stack mb={1}>
              <TextField
                source='username'
                label={t('resources.users.fields.username')}
              />
              {roleGroupField}
            </Stack>
          }
          secondaryText={actions}
        />
      ) : (
        <Datagrid
          bulkActionButtons={null}
          empty={null}
          isLoading={isLoading}
          data={data || []}
          sort={sort}
          total={total}
          sx={{ width: '100%' }}
        >
          <TextField
            source='username'
            label={t('resources.users.fields.username')}
          />

          <TextField
            source='fullname'
            label={t('resources.users.fields.fullname')}
          />
          <TextField source='email' label={t('resources.users.fields.email')} />
          {roleGroupField}
          {actions}
        </Datagrid>
      )}

      {total > ROWS_PER_PAGE_OPTIONS[0] && (
        <Pagination
          total={total}
          page={page}
          setPage={setPage}
          perPage={perPage}
          setPerPage={setPerPage}
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          isLoading={isLoading}
          sx={{ width: '100%' }}
        />
      )}
    </ResourceContextProvider>
  );
};

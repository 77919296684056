import React from 'react';
import {
  ArrayInput,
  AutocompleteInput,
  BooleanInput,
  Create,
  FormTab,
  NumberInput,
  required,
  SimpleFormIterator,
  TabbedForm,
  TextInput,
  useTranslate
} from 'react-admin';
import { currencies } from '@rc/admin/utils/form/currencies';
import { ProviderChoice } from '@rc/admin/resources/product/components';

export const ProductCreate = () => {
  const t = useTranslate();

  return (
    <Create>
      <TabbedForm
        defaultValues={{
          isActive: true
        }}
      >
        <FormTab label='resources.products.tabs.basic'>
          <TextInput
            source={'name'}
            helperText={t('resources.products.fields.name.helper')}
            required
          />
          <TextInput
            source={'description'}
            multiline
            fullWidth
            sx={{ marginTop: 1 }}
          />
          <BooleanInput source={'isActive'} options={null} />
          <ProviderChoice />
        </FormTab>
        <FormTab label='resources.products.tabs.prices'>
          <ArrayInput source={'prices'} validate={[required()]}>
            <SimpleFormIterator
              getItemLabel={() => ''}
              disableClear
              disableReordering
              inline
            >
              <NumberInput source={'amount'} validate={[required()]} />
              <AutocompleteInput
                size='small'
                source={'currency'}
                choices={currencies}
                optionText={'label'}
                optionValue={'id'}
                defaultValue={'huf'}
              />
              <BooleanInput
                source={'isActive'}
                options={null}
                defaultValue={true}
              />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
      </TabbedForm>
    </Create>
  );
};

export default ProductCreate;

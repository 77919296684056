import React from 'react';
import { useMediaQuery } from '@mui/material';
import {
  Datagrid,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  SimpleList,
  TextField
} from 'react-admin';
import { ShowButton } from '@rc/admin/ra';
import { ListWithMercure, StateField } from '@rc/admin/components';
import { useOrgContext } from '@rc/admin/context';

const FILTERS = [
  <ReferenceInput
    key={0}
    label='Environment status'
    reference='states'
    source='state'
  >
    <SelectInput optionText={'name'} />
  </ReferenceInput>
];

export const EnvironmentList = () => {
  const { teamId } = useOrgContext();
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

  if (!teamId) {
    return null;
  }

  return (
    <ListWithMercure
      filter={{ 'project.team.id': teamId }}
      filters={FILTERS}
      exporter={false}
    >
      {isSmall ? (
        <SimpleList
          primaryText={() => <TextField source='name' />}
          secondaryText={() => (
            <ReferenceField source={'state'} reference={'states'} link={false}>
              <StateField source={'state'} />
            </ReferenceField>
          )}
          tertiaryText={() => (
            <ReferenceField source={'project'} reference={'projects'}>
              <TextField source={'name'} />
            </ReferenceField>
          )}
        />
      ) : (
        <Datagrid width={'100vw'}>
          <TextField source='name' />
          <ReferenceField source={'project'} reference={'projects'}>
            <TextField source={'name'} />
          </ReferenceField>
          <ReferenceField source={'state'} reference={'states'} link={false}>
            <StateField source={'state'} />
          </ReferenceField>
          <ShowButton />
        </Datagrid>
      )}
    </ListWithMercure>
  );
};

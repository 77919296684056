import React, { useMemo } from 'react';
import { ChoicesContextProvider, SelectInput, useTranslate } from 'react-admin';

// Options: “> 5 days”, “> 15 days”, “> 30 days”, “> 6 months”
const choices = [
  {
    name: ['misc.gt_days', { days: 5 }],
    value: createDateDaysAgo(5)
  },
  {
    name: ['misc.gt_days', { days: 15 }],
    value: createDateDaysAgo(15)
  },
  {
    name: ['misc.gt_days', { days: 30 }],
    value: createDateDaysAgo(30)
  },
  {
    name: ['misc.gt_months', { months: 6 }],
    value: createDateDaysAgo(180)
  }
];

export const CreatedAtFilter = props => {
  const { source } = props;
  const t = useTranslate();

  const finalChoices = useMemo(
    () =>
      choices.map(choice => ({
        ...choice,
        name: t(...choice.name)
      })),
    [t]
  );

  return (
    <ChoicesContextProvider
      value={{
        allChoices: finalChoices,
        availableChoices: finalChoices,
        hasNextPage: false,
        hasPreviousPage: false,
        isFetching: false,
        isLoading: false,
        page: 1,
        perPage: 1000,
        sort: { field: 'name', order: 'ASC' },
        total: finalChoices.length,
        error: false,
        source
      }}
    >
      <SelectInput
        source={source}
        emptyText={'misc.all'}
        optionText={'name'}
        optionValue={'value'}
      />
    </ChoicesContextProvider>
  );
};

/**
 * @param {Number|Number[]} days
 * @returns
 */
function createDateDaysAgo (days) {
  return new Date(
    new Date().getTime() - days * 24 * 60 * 60 * 1000
  ).toUTCString();
}

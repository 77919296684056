import React, { useCallback } from 'react';
import { SideDialog, useSideDialogContext } from '../../ui';
import {
  AutocompleteInput,
  ChoicesContextProvider,
  Form,
  ReferenceInput,
  useChoicesContext,
  useNotify,
  useRecordContext,
  useResourceContext,
  useTranslate,
  useUpdate
} from 'react-admin';
import { isEmpty } from 'lodash';
import { Button, CircularProgress } from '@mui/material';
import { Save as ContentSave } from '@mui/icons-material';
import { useFormContext } from 'react-hook-form';

const TEMP_SOURCE = '__itemToAdd';

const ContainerComponent = props => (
  <Form
    {...props}
    formRootPathname={`add_${props.reference}_to_${useResourceContext({
      resource: props.resource
    })}`}
  />
);

/**
 * @typedef {object} AddDialogProps
 * @property {string} resource
 * @property {import('react-admin').RaRecord} record
 * @property {string} reference
 * @property {string} backReference
 * @property {string} source
 * @property {async () => void} onSuccess
 *
 * @param {AddDialogProps & import('../../ui/SideDialog/SideDialog').SideDialogProps} props
 * @returns
 */
export const AddDialogView = props => {
  const { source, reference, backReference, onSuccess, ...rest } = props;
  const [{ isOpen }, { open, close }] = useSideDialogContext();

  return (
    <SideDialog
      {...rest}
      isOpen={isOpen}
      handleClose={close}
      handleOpen={open}
      actions={[
        <SubmitButton
          key='save_button'
          source={source}
          onSuccess={onSuccess}
          preventClose
        />
      ]}
      container={ContainerComponent}
    >
      <ReferenceInput
        reference={reference}
        source={TEMP_SOURCE}
        filter={{
          [`exists[${backReference}]`]: false
        }}
      >
        <Input source={source} />
      </ReferenceInput>
    </SideDialog>
  );
};

const SubmitButton = props => {
  const { source, onSuccess } = props;
  const t = useTranslate();
  const notify = useNotify();
  const { handleSubmit } = useFormContext();
  const record = useRecordContext();
  const resource = useResourceContext();
  const [, { close }] = useSideDialogContext();

  const [update, { isLoading: isSubmitting }] = useUpdate(
    resource,
    { id: record?.['@id'] },
    {
      mutationMode: 'pessimistic',
      onSuccess: () => {
        notify('ra.notification.updated', {
          messageArgs: {
            smart_count: 1
          },
          type: 'info'
        });

        onSuccess?.();
        close();
      },
      onError: () => {
        notify('ra.notification.http_error', {
          type: 'error'
        });
      }
    }
  );

  const submit = useCallback(
    async submitData => {
      const data = Object.assign({}, submitData);
      data[source] = [...record[source], submitData[TEMP_SOURCE]];

      update(resource, { data, id: record['@id'] });
    },
    [record, resource, source, update]
  );

  return (
    <Button
      variant='contained'
      color='primary'
      onClick={handleSubmit(submit)}
      disabled={isSubmitting}
    >
      {isSubmitting ? (
        <CircularProgress
          sx={{
            '&.MuiCircularProgress-root': {
              marginRight: '10px',
              marginLeft: '2px'
            }
          }}
          size={14}
          thickness={3}
          color='inherit'
        />
      ) : (
        <ContentSave />
      )}
      {t('ra.action.save')}
    </Button>
  );
};

const Input = props => {
  const { source } = props;
  const t = useTranslate();
  const resource = useResourceContext();
  const record = useRecordContext();
  const { allChoices = [], ...restChoicesContext } = useChoicesContext();

  const filteredAllChoices = allChoices.filter(
    ({ ['@id']: id }) => !record[source].includes(id)
  );

  return (
    <ChoicesContextProvider
      value={{
        allChoices: filteredAllChoices,
        ...restChoicesContext
      }}
    >
      <AutocompleteInput
        label={t(`resources.${resource}.name`, { smart_count: 1 })}
        optionText={'name'}
        validate={validate}
        fullWidth
      />
    </ChoicesContextProvider>
  );
};

const validate = (value = []) => {
  if (isEmpty(value)) {
    return {
      message: 'resources.organisations.validation.min_one_team',
      args: {}
    };
  }

  return undefined;
};

export const resourceLimitMinValueValidator = (value, allValues) => {
  return value > allValues['maxValue']
    ? 'resources.component_version_resource_limits.validation.min'
    : undefined;
};

export const resourceLimitMaxValueValidator = (value, allValues) => {
  return value < allValues['minValue']
    ? 'resources.component_version_resource_limits.validation.max'
    : undefined;
};

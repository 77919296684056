import React from 'react';
import {
  CpuInputConfig,
  MemoryInputConfig,
  StorageInputConfig
} from '@rc/admin/components';
import { ProjectResourceInput } from '..';
import { useEnvironmentResourceLimits } from '@rc/admin/hooks';
import { useRecordContext } from 'react-admin';
import { Stack } from '@mui/material';

/**
 * @param {import('@mui/material').StackProps} props
 */
export const EditProjectResourceInputs = props => {
  const record = useRecordContext();
  const {
    isLoading,
    cpu: cpuLimits,
    memory: memoryLimits,
    storage: storageLimits
  } = useEnvironmentResourceLimits({
    project: record
  });

  return (
    <Stack {...props}>
      <ProjectResourceInput
        source={'cpu'}
        {...CpuInputConfig}
        min={cpuLimits.used}
        isLoadig={isLoading}
        fullWidth
      />
      <ProjectResourceInput
        source={'memory'}
        {...MemoryInputConfig}
        min={memoryLimits.used}
        isLoadig={isLoading}
        fullWidth
      />
      <ProjectResourceInput
        source={'storage'}
        {...StorageInputConfig}
        min={storageLimits.used}
        isLoadig={isLoading}
        fullWidth
      />
    </Stack>
  );
};

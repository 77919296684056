import React, { useCallback, useState } from 'react';
import {
  RecordContextProvider,
  ReferenceField,
  ResourceContextProvider,
  useCreatePath,
  useDataProvider,
  useGetList,
  useResourceContext,
  Link,
  useGetOne,
  useTranslate,
  useGetResourceLabel
} from 'react-admin';
import {
  Box,
  Button,
  Divider,
  Grid,
  Stack,
  styled,
  Tooltip,
  Typography
} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {
  ExternalLink,
  StateField,
  useFormStepperContext
} from '@rc/admin/components';
import useMercureSubscription from '@rc/admin/moduleOverrides/useMercureSubscription';
import { STATE_IDS, WORKER_DATA_TYPE } from '@rc/admin/constants';
import { EnvironmentAccess } from '@rc/admin/resources/environment/components';
import { createStateIri, getStateId } from '@rc/admin/hooks/domain';
import { ProjectTemplateDetails } from '../../components';
import { useWatch } from 'react-hook-form';
import { useProjectType } from '../../hooks';

export const ProjectCreateStepDeploy = props => {
  const [{ data }] = useFormStepperContext();
  const {
    __environments: environmentTemplateIris,
    __provider: provider,
    __cluster: cluster
  } = data.form;

  const t = useTranslate();
  const { create } = useDataProvider();
  const createPath = useCreatePath();
  const resource = useResourceContext();
  const templateIri = useWatch({ name: 'templateIri' });

  const [project, setProject] = useState(data.project);
  const projectId = data.project.id;

  const [environmentResults, setEnvironmentResults] = useState({});

  const { data: templateProject } = useGetOne('template_projects', {
    id: templateIri
  });

  const { projectType } = useProjectType({
    project: templateProject
  });

  const createEnvironments = useCallback(
    ({ data: environmentTemplates }) => {
      environmentTemplates?.forEach(async ({ name, id }) => {
        if (environmentResults[id]) {
          return;
        }

        try {
          const { data } = await create('environments', {
            data: {
              name,
              targetRefName: name,
              templateIri: id,
              provider,
              cluster,
              project: projectId
            }
          });

          setEnvironmentResults(curr => ({
            ...curr,
            [id]: { data, error: null }
          }));
        } catch (error) {
          setEnvironmentResults(curr => ({
            ...curr,
            [id]: { data: null, error }
          }));
        }
      });
    },
    [create, environmentResults, projectId, provider, cluster]
  );

  const {
    data: environmentTemplates,
    isLoading: isEnvironmentTemplatesLoading
  } = useGetList(
    'template_environments',
    {
      filter: {
        id: environmentTemplateIris
      }
    },
    {
      enabled: !!environmentTemplateIris?.length,
      onSuccess: createEnvironments
    }
  );

  const onProjectStateUpdated = useCallback((_, state) => {
    setProject(curr => ({ ...curr, state }));
  }, []);

  const onEnvironmentStateUpdated = useCallback((templateId, state) => {
    setEnvironmentResults(curr => {
      const newData = {
        ...curr[templateId],
        data: {
          ...curr[templateId].data,
          state
        }
      };

      return {
        ...curr,
        [templateId]: newData
      };
    });
  }, []);

  const isDone =
    getStateId(project.state) === STATE_IDS.READY &&
    Object.values(environmentResults).every(
      ({ data }) => getStateId(data.state) === STATE_IDS.READY
    );

  return (
    <>
      <ProjectTemplateDetails
        projectType={projectType}
        templateProject={templateProject}
      />
      <Root alignItems='center' sx={{ mt: 6, mb: 4 }}>
        <StateSubscriber
          id={project.id}
          onStateUpdated={onProjectStateUpdated}
        />

        <Typography variant='h3' align='center' mb={4}>
          {t(
            isDone
              ? 'resources.projects.sections.deploy.message.success'
              : 'resources.projects.sections.deploy.message.progress'
          )}
        </Typography>
        <Box sx={{ maxWidth: 480, width: '100%' }}>
          <Grid container my={1}>
            <Grid item xs={8}>
              <Typography>{`Project "${project.name}"`}</Typography>
            </Grid>
            <Grid item xs={4}>
              {(!environmentResults?.length ||
                Object.keys(environmentResults).length) && (
                <ResourceContextProvider value='projects'>
                  <RecordContextProvider value={project}>
                    <ReferenceField
                      source={'state'}
                      reference={'states'}
                      link={false}
                      label=''
                    >
                      <StateField
                        source={'state'}
                        sx={{ overflow: 'hidden' }}
                        fontWeight={'400'}
                      />
                    </ReferenceField>
                  </RecordContextProvider>
                </ResourceContextProvider>
              )}
            </Grid>
          </Grid>
          <Divider light />
          <ResourceContextProvider value='environments'>
            {environmentTemplates?.map(({ name, id }) => (
              <EnvironmentDeployState
                key={id}
                name={name}
                templateId={id}
                result={environmentResults[id]}
                onStateUpdated={onEnvironmentStateUpdated}
                isLoading={isEnvironmentTemplatesLoading}
              />
            )) || null}
          </ResourceContextProvider>
        </Box>
        <Box mt={12}>
          <Button
            component={Link}
            to={createPath({ resource, id: projectId, type: 'edit' })}
            startIcon={<ArrowForwardIcon />}
            sx={{
              '&:hover a': {
                textDecoration: 'none'
              }
            }}
          >
            {t('resources.projects.sections.deploy.action.go_to_project')}
          </Button>
        </Box>
      </Root>
    </>
  );
};

const EnvironmentDeployState = props => {
  const {
    templateId,
    name,
    result: { data, error } = {},
    onStateUpdated
    // isLoading: isDataLoading
  } = props;
  const t = useTranslate();
  const getResourceLabel = useGetResourceLabel();

  return (
    <>
      {data?.id && (
        <StateSubscriber
          id={data.id}
          templateId={templateId}
          onStateUpdated={onStateUpdated}
        />
      )}
      <RecordContextProvider value={data}>
        <Grid container my={1}>
          <Grid item xs={8}>
            {data?.state !== createStateIri(STATE_IDS.READY) ? (
              <ExternalLink url={'#'} sx={{ cursor: 'pointer' }} disabled>
                <Tooltip
                  title={t(
                    'resources.projects.sections.deploy.message.environment_progress_tooltip'
                  )}
                  arrow
                >
                  <span>{`${getResourceLabel(
                    'environments',
                    1
                  )} "${name}"`}</span>
                </Tooltip>
              </ExternalLink>
            ) : (
              <EnvironmentAccessWrapper id={data.id} />
            )}
          </Grid>
          <Grid item xs={4}>
            {error ? (
              <Typography vairant='body2' color='error'>
                {'Failed'}
              </Typography>
            ) : data ? (
              <ReferenceField
                source={'state'}
                reference={'states'}
                link={false}
                label=''
              >
                <StateField
                  source={'state'}
                  sx={{ overflow: 'hidden' }}
                  fontWeight={'400'}
                />
              </ReferenceField>
            ) : (
              <StateField
                sx={{ overflow: 'hidden' }}
                fontWeight={'400'}
                record={{ originId: STATE_IDS.INIT_REQ, name: 'Loading...' }}
              />
            )}
          </Grid>
        </Grid>
      </RecordContextProvider>
      <Divider light />
    </>
  );
};

/**
 *
 * @param {object} props
 * @param {any} props.id
 * @param {any} props.templateId
 * @param {(templateId: any, state: string) => void} props.onStateUpdated
 * @returns
 */
const StateSubscriber = props => {
  const { id, templateId = id, onStateUpdated } = props;
  const resource = useResourceContext();

  const onReceived = useCallback(
    data => {
      if (data['@type'] === WORKER_DATA_TYPE) {
        onStateUpdated(templateId, data.state);
      }
    },
    [onStateUpdated, templateId]
  );

  useMercureSubscription(resource, id, { onReceived });

  return null;
};

const EnvironmentAccessWrapper = props => {
  const { id } = props;
  const { data, isLoading } = useGetOne('environments', { id });

  return <EnvironmentAccess record={data} isPlaceholder={isLoading} />;
};

// const PREFIX = 'ProjectCreateStepDetails';

// const classes = {};

const Root = styled(Stack)(({ theme }) => ({
  minHeight: 600
}));

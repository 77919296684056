import React, { useMemo } from 'react';
import { useLocation } from 'react-router';
import { Create, SimpleForm, TextInput, BooleanInput } from 'react-admin';
import { styled } from '@mui/material';
import { OneColumnFormStyles } from '@rc/admin/theme/styles';
import { Breadcrumbs } from '@rc/admin/components';

const redirect = (_, __, record) =>
  `component_version_env_vars/${encodeURIComponent(
    record?.componentVersionEnvVar
  )}`;

export const ComponentVersionEnvVarExampleCreate = () => {
  const { search } = useLocation();

  const initialComponentVersionEnvVarId = useMemo(
    () => new URLSearchParams(search).get('componentVersionEnvVar'),
    [search]
  );

  return (
    <StyledCreate
      actions={
        <Breadcrumbs
          label={<BreadcrumbsLabel />}
          parents={breadcrumbsParents}
          data={{
            '@type': 'ComponentVersionEnvVarExample',
            componentVersionEnvVar: initialComponentVersionEnvVarId
          }}
          showCurrent={false}
        />
      }
      redirect={redirect}
    >
      <SimpleForm
        defaultValues={{
          isEnabled: true,
          componentVersionEnvVar: initialComponentVersionEnvVarId
        }}
        className={classes.form}
      >
        <TextInput source={'value'} fullWidth />
        <BooleanInput source={'isDefault'} fullWidth />
      </SimpleForm>
    </StyledCreate>
  );
};

const PREFIX = 'ComponentVersionEnvVarExampleCreate';

const classes = {
  form: `${PREFIX}-form`
};

const StyledCreate = styled(Create)(({ theme }) => ({
  [`& .${classes.form}`]: {
    ...OneColumnFormStyles(theme)
  }
}));

const breadcrumbsParents = [
  {
    reference: 'component_version_env_vars',
    source: 'componentVersionEnvVar'
  },
  { reference: 'component_versions', source: 'componentVersion' },
  { reference: 'components', source: 'component' }
];

const BreadcrumbsLabel = props => {
  const { data } = props;

  switch (data['@type']) {
    case 'ComponentVersionEnvVarExample':
      return data.value;
    case 'ComponentVersionEnvVar':
      return data.key;
    case 'ComponentVersion':
      return data.version;
    default:
      return data.name;
  }
};

import { Stack, styled } from '@mui/material';
import React from 'react';
import { getColorfulBackgroundStyles } from '@rc/admin/theme/styles';

const LoadingPage = () => {
  return (
    <StyledStack justifyContent={'center'} alignItems={'center'}>
      <img src='/icon.png' alt='logo' width='256' height='256' />
    </StyledStack>
  );
};

const StyledStack = styled(Stack)(({ theme }) => {
  return {
    height: '100%',
    minHeight: '100vh',
    width: '100%',
    minWidth: '100vw',
    backgroundColor: theme.palette.background.paper,
    zIndex: 999,
    ...getColorfulBackgroundStyles(theme),
    '@keyframes bounce': {
      '0%, 20%, 50%, 80%, 100%': { transform: 'translateY(0)' },
      // '25%': { filter: 'sepia(0.75)' },
      '40%': { transform: 'translateY(-30px)' },
      '60%': { transform: 'translateY(-15px)' }
      // '75%': { filter: 'greyscale(0.75)' }
    },
    '& img': {
      animation: 'bounce 1.5s infinite 1.5s both',
      height: 100,
      width: 100,
      [`${theme.breakpoints.down('sm')}`]: {
        height: 80,
        width: 80
      }
    }
  };
});

export default LoadingPage;
